import React, { useContext } from 'react';
import { SelectableContext } from 'screens/MediaLibrary/screens/MediaLibraryIndex';
import { AddToAttachmentAlbum, SuggestAttachments } from '.';
import { useIntl } from 'hooks';
import {
  RemoveAttachment,
  Button,
  MediaDownloader,
  RemoveAttachmentAlbumMedia,
} from 'components';
import {
  IconLoader,
  IconDownload,
  IconRemoveFromAttachmentAlbum,
  IconTrash,
} from 'icons';
import Attachment from 'types/Attachment';

interface Props {
  selectedAttachments: Attachment[];
  showAddToAttachmentAlbum?: boolean;
  showRemoveAttachmentsFromAttachmentAlbum?: boolean;
}

export default function MediaLibraryMassActions(props: Props) {
  const {
    selectedAttachments,
    showAddToAttachmentAlbum = false,
    showRemoveAttachmentsFromAttachmentAlbum = false,
  } = props;
  const { selectedIds } = useContext(SelectableContext);
  const { t } = useIntl();

  const RemoveAttachmentButton = showRemoveAttachmentsFromAttachmentAlbum
    ? RemoveAttachmentAlbumMedia
    : RemoveAttachment;

  return (
    <>
      <SuggestAttachments ids={selectedIds} />

      {showAddToAttachmentAlbum && (
        <AddToAttachmentAlbum
          ids={selectedIds}
          attachmentImages={selectedAttachments.map(
            ({ id, mediaFilename, title, thumbnail }) => ({
              id,
              src: thumbnail,
              description: title || mediaFilename,
            })
          )}
        />
      )}

      <MediaDownloader
        attachmentIds={selectedIds}
        requestSource="download-selected-attachments"
      >
        {(downloadMedia, { isLoading }) => (
          <Button inlineBlock mono onClick={downloadMedia} disabled={isLoading}>
            {isLoading ? <IconLoader /> : <IconDownload />}
            {t('MediaLibrary__DownloadAttachments', {
              count: selectedAttachments.length,
            })}
          </Button>
        )}
      </MediaDownloader>
      <RemoveAttachmentButton attachments={selectedAttachments}>
        {(onClick) => (
          <Button inlineBlock mono onClick={onClick}>
            {showRemoveAttachmentsFromAttachmentAlbum ? (
              <IconRemoveFromAttachmentAlbum />
            ) : (
              <IconTrash />
            )}{' '}
            {t(
              showRemoveAttachmentsFromAttachmentAlbum
                ? 'MediaLibrary__RemoveAttachmentsFromAlbum'
                : 'MediaLibrary__RemoveAttachments',
              {
                count: selectedAttachments.length,
              }
            )}
          </Button>
        )}
      </RemoveAttachmentButton>
    </>
  );
}
