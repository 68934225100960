import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Mutation, Query } from '@apollo/react-components';
import Tag from 'types/Tag';
import { Form, Modal, WithIntl, Loader, ErrorDebug } from '..';
import * as mutation from 'graphql/mutations/addTagsToSuggestions.graphql';
import * as query from 'graphql/queries/tags.graphql';
import Button from '../Button';
import RelayConnection from 'types/RelayConnection';
import { mapNodes } from 'helpers';

interface Props {
  isOpen: boolean;
  onRequestClose: () => void;
  suggestionIds: number | string | (string | number)[];
}

interface State {
  tagIds: (number | string)[];
}

interface QueryData {
  tags: RelayConnection<Tag>;
}

interface MutationData {
  addTagsToSuggestions: { tag?: Tag; errors?: string };
}

class AddSuggestionTagModal extends React.Component<Props, State> {
  state = { tagIds: [] };

  updateTagIds = (tagIds: (number | string)[]) => this.setState({ tagIds });

  render() {
    const { isOpen, onRequestClose, suggestionIds } = this.props;
    const { tagIds } = this.state;

    const itemCount = Array.isArray(suggestionIds) ? suggestionIds.length : 1;

    return (
      <WithIntl>
        {(_, t) => (
          <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            contentLabel={t('AddSuggestionTagModal__Heading')}
            renderHeading={
              <FormattedMessage id="AddSuggestionTagModal__Heading" />
            }
          >
            <Query<QueryData> query={query}>
              {({ data, loading, error }) => {
                if (loading) return <Loader />;
                if (error) return <ErrorDebug error={error} />;
                if (!data || !data.tags) return null;

                const tags = data?.tags ? mapNodes(data.tags.edges) : [];

                const options = tags.map((t: Tag) => ({
                  value: t.id,
                  label: t.name,
                }));

                return (
                  <Mutation<MutationData>
                    mutation={mutation}
                    variables={{ suggestionIds, tagIds }}
                    onCompleted={(data) => {
                      if (!data.addTagsToSuggestions.errors) {
                        this.updateTagIds([]);
                        onRequestClose();
                      }
                    }}
                    refetchQueries={['Tags', 'SuggestionsFilterData']}
                  >
                    {(addTagsToSuggestions, { loading: isAdding }) => (
                      <Form onSubmit={addTagsToSuggestions}>
                        <Form.Field>
                          {itemCount > 1 && (
                            <Form.Label>
                              <FormattedMessage
                                id="AddSuggestionTagModal__Description"
                                values={{ itemCount }}
                              />
                            </Form.Label>
                          )}

                          <div data-testid="tag-select">
                            <Form.Select
                              isMulti
                              value={this.state.tagIds}
                              onChange={this.updateTagIds}
                              creatable
                              placeholder={t(
                                'AddSuggestionTagModal__Placeholder'
                              )}
                              options={options}
                              isClearable={false}
                              formatCreateLabel={(label) =>
                                t('AddSuggestionTagModal__CreatePrompt', {
                                  label,
                                })
                              }
                            />
                          </div>
                        </Form.Field>

                        <Modal.Actions>
                          <Button
                            inlineBlock
                            cancel
                            type="button"
                            onClick={this.props.onRequestClose}
                          >
                            <FormattedMessage id="Global__Cancel" />
                          </Button>

                          <Button
                            data-testid="submit"
                            disabled={isAdding}
                            inlineBlock
                            filledBg
                            type="submit"
                          >
                            <FormattedMessage id="Global__Done" />
                          </Button>
                        </Modal.Actions>
                      </Form>
                    )}
                  </Mutation>
                );
              }}
            </Query>
          </Modal>
        )}
      </WithIntl>
    );
  }
}
export default AddSuggestionTagModal;
