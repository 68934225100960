import React from 'react';
import styled from 'styled-components';
import { grid, colors } from 'styles/theme';
import { IconVerified } from 'icons';
import { LinkTwitter } from 'components';

interface Props {
  mention: any;
  isFocused: any;
  onMouseDown: () => any;
  onMouseUp: () => any;
}

const Msg = styled.div`
  padding: ${grid(0.5)} ${grid(1)};

  &:hover {
    text-decoration: underline;
  }
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${grid(0.5)};
  padding: ${grid(0.5)} ${grid(1)};

  &:hover {
    background-color: ${colors.greyBg};
  }
`;

const Img = styled.img`
  display: block;
  flex-shrink: 0;
  margin-right: ${grid(1.5)};
  border-radius: 50%;
  width: ${grid(4)};
  height: ${grid(4)};
  object-fit: cover;
`;

const Name = styled.div`
  line-height: ${grid(2)};
  color: ${colors.darkText};
  font-size: 14px;

  svg {
    display: inline-block;
    margin-left: ${grid(0.25)};
    width: ${grid(1.5)};
    height: ${grid(1.5)};
    color: ${colors.socialieBlue};

    ${(props: any) => props.grayVerified && `color: ${colors.lightText}`};
  }
` as any;

const Username = styled.div`
  color: ${colors.lightText};
  font-size: 12px;
`;

const MentionEntry = ({
  mention,
  isFocused,
  onMouseDown,
  onMouseUp,
}: Props) => {
  if (mention === false) {
    return (
      <Msg>
        <LinkTwitter unclickable />
      </Msg>
    );
  }

  const blueVerified =
    mention.twitter_verified ||
    (mention.verified && mention.verified === 'blue_verified');

  const grayVerified = mention.verified && mention.verified === 'gray_verified';

  return (
    <div onMouseDown={onMouseDown} onMouseUp={onMouseUp}>
      <Wrapper>
        {mention.image && <Img src={mention.image} alt={mention.name} />}

        <Name grayVerfieid={grayVerified}>
          {!!mention.twitter_name && '@'}
          {mention.name}
          {blueVerified && <IconVerified />}
          {mention.twitter_name && <Username>{mention.twitter_name}</Username>}
        </Name>
      </Wrapper>
    </div>
  );
};
export default MentionEntry;
