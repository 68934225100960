import { TypeKeys } from 'redux/ducks/fileUploads';

const fileProcessingStatus = (actionType: string): string => {
  switch (actionType) {
    case TypeKeys.CREATING_THUMBNAILS:
      return 'thumbnailing';
    case TypeKeys.TRANSCODING_VIDEO:
      return 'transcoding';
    case TypeKeys.FILE_READY:
      return 'done';
    case TypeKeys.CANCELED:
      return 'canceled';
    default:
      return '';
  }
};

export default fileProcessingStatus;
