import React from 'react';
import { platformIcons, IconFacebookFull } from 'icons';
import { colors } from 'styles/theme';

interface Props {
  platformId: string;
  branded?: boolean;
  error?: boolean;
  success?: boolean;
  circled?: boolean;
}

const PlatformIcon = React.forwardRef((props: Props, ref) => {
  const Icon = platformIcons[props.platformId];
  const iconSize = props.circled ? '60%' : '100%';
  const colorProp = props.circled ? 'backgroundColor' : 'color';

  if (!Icon) {
    throw new Error(`PlatformIcon: unknown platform ${props.platformId}`);
  }

  let color = colors.white;
  if (props.branded) color = colors[props.platformId];
  if (props.success) color = colors.teal;
  if (props.error) color = colors.error;

  if (props.platformId === 'facebook') {
    return (
      <IconFacebookFull
        className="block h-full w-full text-facebook"
        data-qa="platform-icon-platform-icon-facebook-icon"
      />
    );
  }

  return (
    <div
      className="flex h-full w-full items-center justify-center rounded-full text-white"
      data-qa={`platform-icon-platform-icon-${props.platformId}-icon`}
      style={{ [colorProp]: color }}
      ref={ref as React.Ref<HTMLDivElement>}
    >
      <Icon style={{ width: iconSize, height: iconSize }} />
    </div>
  );
});

PlatformIcon.defaultProps = {
  circled: true,
};

export default PlatformIcon;
