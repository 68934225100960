import store from '../redux/store';
import { flashMessage } from 'redux-flash';
import FlashMessage, { FlashMessageStyle } from 'types/FlashMessage';

interface Options {
  timeout?: number | false;
  push?: boolean;
  props?: FlashMessage['props'];
  style?: FlashMessageStyle;
}

const createFlashMessage = (message: string, options: Options = {}) => {
  const defaultOptions = { timeout: 3500, push: true };
  return store.dispatch(
    flashMessage(message, {
      ...defaultOptions,
      ...options,
      props: { style: options.style, ...options.props },
    })
  );
};

export default createFlashMessage;
