import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Form } from 'components';
import TrackingIndexFilter from 'types/TrackingIndexFilter';

interface Props {
  filter: TrackingIndexFilter;
  updateFilter: (values: any) => void;
}

const MiscFilter = ({ filter, updateFilter }: Props) => (
  <Form.Field>
    <Form.Label bold>
      <FormattedMessage id="TrackingMiscFilter__Label" />
    </Form.Label>

    <Form.Option>
      <Form.Checkbox
        checked={!!filter.recentlyOnline}
        onChange={() =>
          updateFilter({
            recentlyOnline: !filter.recentlyOnline,
          })
        }
      />

      <span>
        <FormattedMessage id="TrackingMiscFilter__RecentlyOnline" />
      </span>
    </Form.Option>

    <Form.Option>
      <Form.Checkbox
        checked={!!filter.smartSuggestions}
        onChange={() =>
          updateFilter({
            smartSuggestions: !filter.smartSuggestions,
          })
        }
      />

      <span>
        <FormattedMessage id="TrackingMiscFilter__SmartSuggestions" />
      </span>
    </Form.Option>

    <Form.Option>
      <Form.Checkbox
        checked={!!filter.expired}
        onChange={() =>
          updateFilter({
            expired: !filter.expired,
          })
        }
      />

      <span>
        <FormattedMessage id="TrackingMiscFilter__Expired" />
      </span>
    </Form.Option>
  </Form.Field>
);

export default MiscFilter;
