import React from 'react';
import isEqual from 'lodash/isEqual';
import moment from 'moment-timezone';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import Suggestion from 'types/Suggestion';
import { Form, QTooltip, DateTimePicker, Switch } from 'components';
import { grid, colors } from 'styles/theme';
import * as config from 'config';

interface Props {
  suggestions: Suggestion[];
  onChange: (postAt: Date | null) => void;
  isSwitch?: boolean;
  isFullWidth?: boolean;
}

interface State {
  isOpen: boolean;
  value: Date;
}

const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
`;

const Toggle = styled.div`
  position: relative;
  top: 1px;
  margin-right: ${grid(1)};
`;

const Error = styled.div`
  color: ${colors.error};
  font-size: 12px;
`;

export default class Scheduling extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = this.getInitialState();
  }

  componentDidUpdate(prevProps: Props) {
    const prevIds = prevProps.suggestions.map((s) => s.id);
    const currentIds = this.props.suggestions.map((s) => s.id);
    if (!isEqual(prevIds, currentIds)) this.setState(this.getInitialState());
  }

  getInitialState = () => {
    const { suggestions } = this.props;
    let date = moment().add(1, 'day');
    date.set({ hour: 12, minute: 0 });
    let isOpen = false;

    if (suggestions.length === 1 && suggestions[0].postAt) {
      isOpen = true;
      date = moment(suggestions[0].postAt);
    }

    return { isOpen, value: date.toDate() };
  };

  toggleOpen = () =>
    this.setState((state) => {
      const isOpen = !state.isOpen;
      if (isOpen) {
        this.props.onChange(state.value);
      } else {
        this.props.onChange(null);
      }

      return { isOpen };
    });

  onChange = (value: Date) => {
    this.setState({ value });
    this.props.onChange(value);
  };

  render() {
    const { suggestions, isSwitch = false, isFullWidth = false } = this.props;
    const { isOpen, value } = this.state;
    const fullDate = moment(value);
    const { minPostAtLeadTime } = config.suggestionValidation;
    const isValid =
      !fullDate || fullDate.diff(moment(), 'minutes') > minPostAtLeadTime;

    return (
      <Wrapper>
        <Form.Field className="w-full">
          <div className="flex">
            <Toggle>
              {isSwitch ? (
                <Switch isOn={isOpen} onToggle={this.toggleOpen} />
              ) : (
                <Form.Checkbox
                  checked={isOpen}
                  onChange={this.toggleOpen}
                  data-qa="suggestion-form-scheduling-checkbox"
                />
              )}
            </Toggle>
            <Form.Label data-qa="suggestion-form-scheduling-label" bold>
              {suggestions.length === 1 ? (
                <FormattedMessage id="SuggestionForm__Scheduling" />
              ) : (
                <FormattedMessage
                  id="SuggestionForm__SchedulingMulti"
                  values={{ count: suggestions.length }}
                />
              )}

              <QTooltip
                content={
                  <FormattedMessage id="SuggestionForm__SchedulingTooltip" />
                }
              />
            </Form.Label>
          </div>

          {isOpen && (
            <>
              <DateTimePicker
                value={value}
                onChange={this.onChange}
                isFullWidth={isFullWidth}
              />

              {!isValid && (
                <Error>
                  <FormattedMessage
                    id="SuggestionForm__SchedulingInvalid"
                    values={{ minutes: minPostAtLeadTime }}
                  />
                </Error>
              )}
            </>
          )}
        </Form.Field>
      </Wrapper>
    );
  }
}
