import { createGlobalStyle, css } from 'styled-components';
import { font, colors, sizes, grid } from 'styles/theme';
import { media } from 'styles';

const platformIds = ['facebook', 'instagram', 'linkedin', 'twitter'];

export const GlobalCSSVariables = {
  // The top offset to account for the GlobalNavBar and warning banner (if open)
  topOffset: '--top-offset',

  // The height of the warning banner if open, or 0 if closed
  warningBannerHeight: '--warning-banner-height',
};

export default createGlobalStyle`
    @import url('https://use.typekit.net/sej3jau.css');

    *,
    *::before,
    *::after {
      box-sizing: border-box;
      margin-top: 0;
    }

    html {
      height: 100%;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }

    :root {
      // Set variables for the top offset and warning banner height
      ${(props: any) => `
        ${GlobalCSSVariables.warningBannerHeight}: ${
        props.isTopWarningBannerOpen ? sizes.TopWarningBanner.height : '0px'
      };
        ${GlobalCSSVariables.topOffset}: calc(${
        sizes.GlobalNavBar.height.default
      } + var(${GlobalCSSVariables.warningBannerHeight}));
      `}

      ${media.tablet`
        ${GlobalCSSVariables.topOffset}: calc(${sizes.GlobalNavBar.height.tablet} + var(${GlobalCSSVariables.warningBannerHeight}));
      `}
    }

    body {
      margin: 0;
      background: ${colors.bodyBg};
      padding-top: var(${GlobalCSSVariables.topOffset});
      height: 100%;
      overflow-y: auto;
      line-height: ${grid(3)};
      color: ${colors.bodyText};
      font-family: ${font.sans};
      font-size: 14px;
      -webkit-font-smoothing: antialiased;

      &.draft-builder {
        background-color: ${colors.draftBuilderBg};
        ${media.maxTablet`
          ${GlobalCSSVariables.warningBannerHeight}: 0;
          padding-top: ${sizes.GlobalNavBar.height.default};
        `}
      }
    }

    p {
      margin: 0;
      padding: 0;
    }

    #root {
      height: 100%;
      overflow: visible;
    }

    h1 {
      margin-bottom: 0;
      line-height: ${grid(4)};
      color: ${colors.darkText};
      font-size: 24px;
      font-weight: normal;

      &.with-margin {
        margin-bottom: ${grid(3)};
      }
    }

    :focus {
      outline: 1px solid ${colors.blue};
      outline-offset: 2px;
    }

    .js-focus-visible :focus:not(.focus-visible) {
      outline: none;
    }

    h2 {
      margin-bottom: ${grid(2)};
      border-bottom: 1px solid ${colors.border};
      text-transform: uppercase;
      line-height: ${grid(4)};
      color: ${colors.darkText};
      font-size: 12px;
      font-weight: 600;
    }

    h4 {
      margin-bottom: ${grid(2)};
      color: ${colors.darkText};
      font-size: 16px;
      font-weight: 400;
    }

    h5 {
      margin-bottom: ${grid(1)};
      text-transform: uppercase;
      letter-spacing: 0.92px;
      color: ${colors.lightText};
      font-size: 11px;
      font-weight: 600;
    }

    a {
      text-decoration: none;
      color: ${colors.link};

      &:focus {
        outline: 1px solid ${colors.blue};
        outline-offset: 2px;
      }
    }

    button {
      margin: 0;
      outline: none;
      border: 0;
      background: none;
      cursor: pointer;
      padding: 0;
      text-align: inherit;
      text-transform: inherit;
      letter-spacing: inherit;
      color: inherit;
      font: inherit;
    }

    input,
    select,
    textarea {
      font-family: ${font.sans};
    }

    svg {
      display: inline-block;
    }

    ${platformIds.map(
      (platformId) =>
        css`
          .--${platformId} {
            color: ${colors[platformId]};
          }

          .--${platformId}-bg {
            background-color: ${colors[platformId]};
          }
        `
    )}

    .tippy-box[data-theme~='socialie'] {
      border-radius: 2px;
      box-shadow: 0 2px 4px 0 rgba(0,0,0,0.12), 0 2px 8px 0 rgba(0,0,0,0.16);
      background-color: ${colors.white};
      padding: ${grid(0.5)} ${grid(1)};
      color: ${colors.bodyText};
    }

    .tippy-box[data-theme~='socialieNoPad'] {
      border-radius: 2px;
      box-shadow: 0 2px 4px 0 rgba(0,0,0,0.12), 0 2px 8px 0 rgba(0,0,0,0.16);
      background-color: ${colors.white};
      padding: 0;
      color: ${colors.bodyText};
    }

    .tippy-box[data-theme~='socialieDark'] {
      border-radius: 2px;
      box-shadow: none;
      background-color: ${colors.grey1};
      padding: ${grid(0.75)} ${grid(1)} ${grid(0.75)};
      max-width: 300px;
      line-height: ${grid(2)};
      color: ${colors.white};
      font-size: 13px;
      font-weight: bold;
    }

    .tippy-box[data-theme~='dark'] {
      border-radius: 2px;
      box-shadow: none;
      background-color: ${colors.grey1};
      max-width: 330px;
      color: ${colors.white};
      font-size: 13px;
    }

    .tippy-box[data-theme~='FlyoutMenu'] {
      border-radius: 2px;
      box-shadow: 0 2px 4px 0 rgba(0,0,0,0.12), 0 2px 8px 0 rgba(0,0,0,0.16);
      background-color: ${colors.white};
      padding: ${grid(0.5)} ${grid(1)};
      min-width: ${grid(24)};
      max-height: ${grid(32)};
      overflow-y: auto;
      text-align: left;
      color: ${colors.bodyText};
    }

    .tippy-box[data-theme~='FlyoutMenuDark'] {
      border-radius: 8px;
      background-color: ${colors.grey3};
      padding: ${grid(0.5)} ${grid(1)};
      min-width: ${grid(30)};
      max-height: ${grid(32)};
      overflow-y: auto;
      text-align: left;
      color: ${colors.white};
    }

    .tippy-box[data-animation='FlyoutMenu'][data-state='hidden'][data-placement^='bottom'] {
      transform: translate3d(0, -16px, 0);
      opacity: 0;
    }

    .tippy-box[data-animation='FlyoutMenu'][data-state='hidden'][data-placement*='top'] {
      transform: translate3d(0, 16px, 0);
      opacity: 0;
    }

    .tippy-box[data-animation='FlyoutMenu'][data-state='visible'] {
      transform: translate3d(0, 0, 0);
      opacity: 1;
    }

    /* Clear decorations from search fields */
    input[type="search"].apperance-none {
      appearance: none;
    }
    input[type=search]::-ms-clear {  display: none; width : 0; height: 0; }
    input[type=search]::-ms-reveal {  display: none; width : 0; height: 0; }
    input[type="search"]::-webkit-search-decoration,
    input[type="search"]::-webkit-search-cancel-button,
    input[type="search"]::-webkit-search-results-button,
    input[type="search"]::-webkit-search-results-decoration { display: none; }

  `;
