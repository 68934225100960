import React, { useState } from 'react';
import ContactMethodType from 'types/ContactMethod';
import { DoNotCallWarning } from 'components';
import { getFormattedContactMethod } from 'helpers';
import { contactMethodIcons } from 'icons';
import clsx from 'clsx';

interface Props {
  contactMethod: ContactMethodType;
  onClick: () => void;
  secondary?: boolean;
}

const ContactMethod = ({ contactMethod, onClick, secondary }: Props) => {
  const [isHovering, setIsHovering] = useState(false);
  const value = getFormattedContactMethod(contactMethod);
  const Icon = contactMethodIcons[contactMethod.protocol];
  const iconColorClass = isHovering
    ? 'text-dark'
    : contactMethod.wantsNotifications
    ? 'text-bodyText'
    : 'text-lightIcon opacity-75';

  return (
    <div
      className={clsx('flex items-center', {
        'text-light': secondary && !isHovering,
        'text-dark': isHovering,
      })}
    >
      <button
        className="hover:underline"
        onClick={onClick}
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
      >
        {value}
      </button>

      {contactMethod.name && (
        <span className="ml-0.5">({contactMethod.name})</span>
      )}

      {contactMethod.doNotCall && <DoNotCallWarning />}

      {Icon && (
        <button
          className={iconColorClass}
          onClick={onClick}
          onMouseEnter={() => setIsHovering(true)}
          onMouseLeave={() => setIsHovering(false)}
        >
          <Icon className={`bump-down-1.5 ml-1 h-1.75 w-1.75`} />
        </button>
      )}
    </div>
  );
};

export default ContactMethod;
