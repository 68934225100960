import React, { useState } from 'react';
import { Card } from 'components';
import { MfaLoginScreen, RecoveryCode } from './screens';
import logo from 'images/logo-socialie.png';
import SemiLoggedInUser from 'types/SemiLoggedInUser';

interface Props {
  semiLoggedInUser: SemiLoggedInUser;
}

export default function MfaLogin({ semiLoggedInUser }: Props) {
  const [usingRecoveryCode, setIsUsingRecoveryCode] = useState(false);

  return (
    <div className="flex h-screen w-screen justify-center">
      <div className="mt-9 w-51.5">
        <div className="mb-4 flex justify-center">
          <img className="h-5 w-22.5" src={logo} alt="Socialie logo" />
        </div>
        <Card>
          {!usingRecoveryCode ? (
            <MfaLoginScreen
              semiLoggedInUser={semiLoggedInUser}
              onUseRecoveryCode={() => setIsUsingRecoveryCode(true)}
            />
          ) : (
            <RecoveryCode
              semiLoggedInUser={semiLoggedInUser}
              onGoBack={() => setIsUsingRecoveryCode(false)}
            />
          )}
        </Card>
      </div>
    </div>
  );
}
