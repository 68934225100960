import React from 'react';
import { FormattedMessage } from 'react-intl';
import NoResultsWrapper from './NoResultsWrapper';

interface Props {
  allowCreate: boolean;
  inputValue: string;
  onCreateNewContact: () => void;
}

const NoResults = ({ allowCreate, onCreateNewContact, inputValue }: Props) => {
  return (
    <NoResultsWrapper>
      <FormattedMessage id="RecipientsSearch__NoResults" />
      {allowCreate && (
        <button onClick={onCreateNewContact}>
          <FormattedMessage
            id="RecipientsSearch__NoResultsHelper"
            values={{ inputValue }}
          />
        </button>
      )}
    </NoResultsWrapper>
  );
};

export default NoResults;
