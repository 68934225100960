import React from 'react';
import { useIntl, useSuggestAttachments } from 'hooks';
import { Button, Tooltip } from 'components';
import { IconSuggestion } from 'icons';
import { FormattedMessage } from 'react-intl';

interface Props {
  ids: (string | number)[];
}

export default function SuggestAttachments({ ids }: Props) {
  const { isLoading, suggestAttachments } = useSuggestAttachments(ids);
  const { t } = useIntl();

  const buttonText = isLoading
    ? t('SuggestAttachments__Loading')
    : Array.isArray(ids)
    ? t('SuggestAttachments__ButtonPlural', { count: ids.length })
    : t('SuggestAttachments__ButtonSingle');

  const exceedsMaxSuggestionLength = ids.length > 999;
  return (
    <Tooltip
      disabled={!exceedsMaxSuggestionLength}
      placement="top"
      content={<FormattedMessage id="MediaLibrary__ExceededMaxSuggestions" />}
    >
      <div>
        <Button
          data-qa="media-library-media-library-index-suggest-attachments-suggest-button"
          onClick={suggestAttachments}
          filledBg
          inlineBlock
          disabled={isLoading || exceedsMaxSuggestionLength}
        >
          <IconSuggestion /> {buttonText}
        </Button>
      </div>
    </Tooltip>
  );
}
