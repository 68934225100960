import flatten from 'lodash/flatten';
import Suggestion from 'types/Suggestion';
import IndexSuggestion from 'types/IndexSuggestion';

const getSuggestionTargets = (
  suggestion: Suggestion | IndexSuggestion,
  platform?: string
) => {
  if (!suggestion.platforms) return null;
  const platformBags = platform
    ? suggestion.platformBags.filter((pb) => pb.platform === platform)
    : suggestion.platformBags;
  const targets = flatten(platformBags.map((pb) => pb.targets || []));

  if (!targets.length) return null;

  return targets;
};

export default getSuggestionTargets;
