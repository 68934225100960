import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { Form, Button, AuthForm } from 'components';
import { routes } from 'helpers';
import { Link } from 'react-router-dom';

interface Props {
  logIn: (options: { variables: { email: string; password: string } }) => void;
  onRetrievePassword: (p: string) => void;
  isLoading: boolean;
  hasError: boolean;
}

interface State {
  email: string;
  password: string;
}

const ForgotPassword = styled.div`
  text-align: right;
  font-size: 13px;
`;

export default class LogIn extends React.Component<Props, State> {
  state = { email: '', password: '' };

  onSubmit = () => {
    const { email, password } = this.state;
    this.props.onRetrievePassword?.(this.state.password);
    this.props.logIn({ variables: { email, password } });
  };

  render() {
    const { isLoading, hasError } = this.props;

    return (
      <AuthForm>
        <Form onSubmit={this.onSubmit}>
          {hasError && (
            <p className="mb-2">
              <FormattedMessage id="LogInForm__Error" />
            </p>
          )}

          <Form.Field>
            <Form.Label htmlFor="email" bold>
              <FormattedMessage id="LogInForm__Email" />
            </Form.Label>

            <Form.TextInput
              type="email"
              id="email"
              onChange={(e: any) => this.setState({ email: e.target.value })}
            />
          </Form.Field>

          <Form.Field>
            <Form.Label htmlFor="password" bold>
              <FormattedMessage id="LogInForm__Password" />
            </Form.Label>
            <Form.TextInput
              type="password"
              id="password"
              onChange={(e: any) => this.setState({ password: e.target.value })}
            />
          </Form.Field>

          <Button filledBg type="submit" disabled={isLoading}>
            <FormattedMessage
              id={isLoading ? 'LogInForm__Loading' : 'LogInForm__Submit'}
            />
          </Button>

          <ForgotPassword>
            <Link to={routes.auth.forgotPassword}>
              <FormattedMessage id="LogInForm__ForgotPassword" />
            </Link>
            <br />
            <a
              href="https://www.socialie.com/privacy-policy"
              target="_blank"
              rel="noopener noreferrer"
              className="text-light"
            >
              <FormattedMessage id="LogInForm__PrivacyPolicy" />
            </a>
          </ForgotPassword>
        </Form>
      </AuthForm>
    );
  }
}
