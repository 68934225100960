import grid from './grid';

const SIZE_STYLES = {
  GlobalNavBar: {
    height: {
      default: grid(6.5),
      tablet: grid(8),
    },
  },

  SearchAndFilterTopBar: {
    height: {
      default: grid(7.5),
      tablet: grid(7.5),
    },
  },

  InnerSidebar: {
    width: grid(37),
  },

  DraftBuilder: {
    utilBarHeight: grid(6.5),
    sidebarWidth: {
      default: grid(45),
      large: grid(50),
    },
  },

  SecondaryHeader: {
    height: grid(7),
  },

  PublisherRequestCols: {
    platforms: grid(2),
    status: grid(15),
    platformsAndStatus: grid(20),
    engagement: grid(14),
    tags: grid(25),
    actions: grid(3),
  },

  PublisherListCols: {
    details: grid(47),
    selection: grid(2),
  },

  PublisherTagListCols: {
    details: grid(40),
  },

  TrackingListCols: {
    selection: grid(2),
    platforms: grid(2),
    thumbnail: grid(14),
  },

  TrackingRequestCols: {
    selection: grid(2),
    publisher: grid(28),
    timestamp: grid(15),
    platforms: grid(15),
  },

  GroupMembersCols: {
    selection: grid(2),
    remove: grid(2),
    role: grid(20),
  },
  TopWarningBanner: {
    height: grid(8),
  },
};

export default SIZE_STYLES;
