import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { Page, Card, GlobalNavBar, ErrorDebug } from 'components';
import { grid } from 'styles/theme';

interface Props {
  error?: any;
}

const Wrapper = styled.div`
  text-align: center;
  h1 {
    margin-bottom: ${grid(3)};
  }
`;

const Error = ({ error }: Props) => (
  <Page>
    <GlobalNavBar hasNavigation={true} />
    <Page.Main>
      <Card>
        <Wrapper>
          <h1>
            <FormattedMessage id="Error__Heading" />
          </h1>

          <p className="mb-2">
            <FormattedMessage id="Error__Description" />
          </p>

          <ErrorDebug error={error} />
        </Wrapper>
      </Card>
    </Page.Main>
  </Page>
);

export default Error;
