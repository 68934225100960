import * as LOAD_SUGGESTIONS from 'graphql/queries/suggestions.graphql';
import apolloClient from '../apolloClient';

const loadSuggestions = async (ids: (number | string)[]) => {
  const { errors, data } = await apolloClient.query({
    query: LOAD_SUGGESTIONS,
    variables: { ids, first: ids.length },
    fetchPolicy: 'network-only',
  });

  if (errors) return { errors };
  if (data) return data;
  return null;
};

export default loadSuggestions;
