import React from 'react';
import styled from 'styled-components';
import { Mutation } from '@apollo/react-components';
import { SuggestionGeotargets, ConfirmModal } from 'components';
import Target from 'types/Target';
import Suggestion from 'types/Suggestion';
import { grid, colors } from 'styles/theme';
import gql from 'graphql-tag';
import { FormattedMessage } from 'react-intl';

interface Props {
  targets: Target[];
  suggestion: Suggestion;
  platform: string;
}

interface State {
  isDeleteModalOpen: boolean;
}

const REMOVE_PLATFORM_BAG = gql`
  mutation RemovePlatformBag($suggestionIds: [ID!]!, $platform: String!) {
    removePlatformBag(suggestionIds: $suggestionIds, platform: $platform) {
      errors
      suggestions {
        id
        platformBags {
          id
        }
      }
    }
  }
`;

const Wrapper = styled.div`
  border-top: 1px solid ${colors.lightBorder};
  padding: ${grid(1.5)};
  padding-left: ${grid(3.5)};
  line-height: ${grid(2.5)};
  font-size: 13px;
`;

const ClearButton = styled.button.attrs({ type: 'button' })`
  padding-left: ${grid(0.5)};
  color: ${colors.socialiePink};
  font-size: 11px;
`;

interface MutationData {
  removePlatformBag: {
    errors?: boolean;
    suggestions?: { id: string; platformBags: { id: string }[] }[];
  };
}

class GeotargetingValues extends React.Component<Props, State> {
  state = { isDeleteModalOpen: false };

  openDeleteModal = () => this.setState({ isDeleteModalOpen: true });

  closeDeleteModal = () => this.setState({ isDeleteModalOpen: false });

  render() {
    const { targets, suggestion, platform } = this.props;
    const suggestionIds = suggestion.id;
    return (
      <Wrapper>
        <SuggestionGeotargets targets={targets} />
        <ClearButton onClick={() => this.setState({ isDeleteModalOpen: true })}>
          <FormattedMessage id="CaptionField__ClearGeotargets" />
        </ClearButton>

        <Mutation<MutationData>
          mutation={REMOVE_PLATFORM_BAG}
          variables={{ suggestionIds, platform }}
        >
          {(removePlatformBag, { loading }) => (
            <ConfirmModal
              onConfirm={removePlatformBag}
              isLoading={loading}
              heading="CaptionFields__ClearGeotargetsHeading"
              onRequestClose={() => this.setState({ isDeleteModalOpen: false })}
              isOpen={this.state.isDeleteModalOpen}
            >
              <FormattedMessage id="CaptionFields__ConfirmClearGeotargets" />
            </ConfirmModal>
          )}
        </Mutation>
      </Wrapper>
    );
  }
}
export default GeotargetingValues;
