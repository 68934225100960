import React from 'react';
import styled from 'styled-components';
import { rgba } from 'polished';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { colors, grid } from 'styles/theme';
import { IconCaratDown } from 'icons';
import WithRouter from '../WithRouter';
import LastLocation from '../LastLocation';

interface Props {
  to: string;
  text: string;
}

const Wrapper = styled.div`
  text-transform: uppercase;
  font-size: 11px;

  a,
  button,
  svg {
    color: ${rgba(colors.white, 0.8)};
  }

  a,
  button {
    display: flex;
    align-items: center;
  }

  svg {
    position: relative;
    top: 1px;
    transform: rotate(90deg);
    margin-right: ${grid(0.5)};
    width: ${grid(1.5)};
    height: ${grid(1.5)};
  }
`;

const BackButton = ({ to, text }: Props) => (
  <LastLocation.Consumer>
    {({ lastLocation, currentLocation }) => (
      <WithRouter
        render={({ history }) => {
          const buttonText = (
            <>
              <IconCaratDown />
              <FormattedMessage id={text} />
            </>
          );

          const shouldGoBack =
            (lastLocation && lastLocation.pathname === to) ||
            (currentLocation && currentLocation.pathname === to);

          return (
            <Wrapper>
              {shouldGoBack ? (
                <button onClick={() => history.goBack()}>{buttonText}</button>
              ) : (
                <Link to={to}>{buttonText}</Link>
              )}
            </Wrapper>
          );
        }}
      />
    )}
  </LastLocation.Consumer>
);

export default BackButton;
