import React from 'react';
import InfoCard from '../InfoCard';
import Attachment from 'types/Attachment';
import { useIntl } from 'hooks';
import BatchSuggestion from './BatchSuggestion';

interface Props {
  attachment: Attachment;
}

export default function ScheduledSuggestions({ attachment }: Props) {
  const { t } = useIntl();

  if (!attachment.suggestions || !attachment.suggestions.length) {
    return null;
  }

  const byScheduledTime = attachment.suggestions.reduce((obj, s) => {
    const sendAt = s.batchSuggestion?.sendAt;
    if (!sendAt || !s.intendedRecipients.length) {
      return obj;
    }

    if (!obj[sendAt]) {
      obj[sendAt] = [];
    }

    return { ...obj, [sendAt]: [...obj[sendAt], s] };
  }, {});

  const scheduledTimes = Object.keys(byScheduledTime);
  if (!scheduledTimes.length) {
    return null;
  }

  return (
    <>
      <InfoCard title={t('AttachmentModal__ScheduledFor')}>
        {scheduledTimes.map((sendAt) => {
          return (
            <BatchSuggestion
              key={sendAt}
              suggestions={byScheduledTime[sendAt]}
              sendAt={parseInt(sendAt)}
            />
          );
        })}
      </InfoCard>
    </>
  );
}
