import React from 'react';
import { FormattedMessage } from 'react-intl';
import { formatPercent, formatAverage } from 'helpers/formatNumber';
import { Tooltip } from 'components';
import styled from 'styled-components';
import { QTooltip, Sparkline } from '..';
import { grid, colors } from 'styles/theme';
import { IconAsterisk } from 'icons';

interface Props {
  title: string;
  value?: number;
  secondaryTitle?: string;
  secondaryValue?: number;
  sparklineData?: number[];
  percent?: boolean;
  tooltip?: string;
  hasIncompleteData?: boolean;
}

const Wrapper = styled.div`
  position: relative;
  flex: 1;
  text-align: center;
  min-height: ${grid(20)};
  padding: ${grid(2)};

  &::after {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    width: 1px;
    height: ${grid(18)};
    background: ${colors.border};
    content: '';
  }

  &:last-child::after {
    display: none;
  }
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: ${grid(2)};
  color: ${colors.lightText};
  font-size: 14px;
`;

const Value = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: ${grid(1)};
  color: ${colors.darkText};
  font-size: 40px;
  line-height: ${grid(6)};
  font-weight: 300;
`;

const IncompleteData = styled.div`
  position: relative;
  top: ${grid(1)};
  color: ${colors.socialiePink};
  width: ${grid(1.5)};
  height: ${grid(1.5)};

  svg {
    display: block;
    width: 100%;
    height: 100%;
  }

  > div {
    display: block !important;
    width: ${grid(1.5)};
    height: ${grid(1.5)};
  }
`;

const ChangeOverTime = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto ${grid(3)};
  color: ${colors.red};
  font-size: 14px;
  font-weight: bold;
` as any;

const SecondaryTitle = styled.div`
  color: ${colors.lightText};
  font-size: 12px;
`;

const BigStat = (props: Props) => {
  const formatValue = (value: number) => {
    if (isNaN(value)) return '0';
    if (props.percent) return formatPercent(value);
    return formatAverage(value);
  };

  return (
    <Wrapper>
      <Title>
        <FormattedMessage id={props.title} />
        {!!props.tooltip && (
          <QTooltip content={<FormattedMessage id={props.tooltip} />} />
        )}
      </Title>
      <Value>
        {props.value || props.value === 0 ? (
          <>
            {formatValue(props.value)}
            {props.hasIncompleteData && (
              <IncompleteData>
                <Tooltip
                  content={<FormattedMessage id="Stat__HasIncompleteData" />}
                >
                  <span>
                    <IconAsterisk />
                  </span>
                </Tooltip>
              </IncompleteData>
            )}
          </>
        ) : (
          '--'
        )}
      </Value>

      {!!props.sparklineData && (
        <ChangeOverTime>
          <Sparkline
            width={grid(7)}
            height={grid(3)}
            data={props.sparklineData}
            color={
              props.sparklineData[0] <=
              props.sparklineData[props.sparklineData.length - 1]
                ? colors.green
                : colors.red
            }
          />
        </ChangeOverTime>
      )}

      {!!props.secondaryTitle && (
        <SecondaryTitle>
          <FormattedMessage id={props.secondaryTitle} />:{' '}
          {!!props.secondaryValue || props.secondaryValue === 0
            ? formatValue(props.secondaryValue)
            : '--'}
        </SecondaryTitle>
      )}
    </Wrapper>
  );
};

export default BigStat;
