import React from 'react';
import includes from 'lodash/includes';
import without from 'lodash/without';
import intersection from 'lodash/intersection';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { styledStatuses } from 'helpers';
import { Form } from 'components';
import { grid, colors } from 'styles/theme';
import { IconMinus, IconPlus } from 'icons';

interface Props {
  selectedStatuses: string[];
  onChange: (statuses: string[]) => void;
}

interface State {
  showAllStatuses: boolean;
}

const Status = styled.label`
  display: flex;
  align-items: center;
  margin-bottom: ${grid(0.5)};
  font-size: 14px;

  > span {
    position: relative;
    top: -1px;
    color: ${colors.darkText};
  }
`;

const ShowAllToggle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: ${grid(1)};
  border-radius: 2px;
  background-color: ${colors.darkGreyBg};
  cursor: pointer;
  padding: ${grid(0.25)};
  width: ${grid(2)};
  height: ${grid(2)};
  color: ${colors.bodyText};

  svg {
    width: 100%;
    height: 100%;
  }
`;

const Indicator = styled.div`
  margin: 0 ${grid(1)};
  border-radius: 50%;
  background-color: ${(props: any) => colors[props.color]};
  width: ${grid(1)};
  height: ${grid(1)};
`;

const IconWrapper = styled.div`
  color: ${(props: any) => colors[props.color]};

  > svg {
    display: block;
    position: relative;
    top: 1px;
    margin: 0 ${grid(0.75)};
    width: ${grid(1.5)};
    height: ${grid(1.5)};
  }
`;

const PRIMARY_STATUSES = ['published', 'submitted', 'scheduled', 'declined'];
const SECONDARY_STATUSES = [
  'opened',
  'canceled',
  'pending',
  'expired',
  'saved_media',
  'send_failed',
  'failed',
];

export default class StatusFilter extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      showAllStatuses: this.shouldShowAllStatusesOnLoad(),
    };
  }

  onChange = (status: string) => {
    const { selectedStatuses } = this.props;
    const newStatuses = includes(selectedStatuses, status)
      ? without(selectedStatuses, status)
      : [...selectedStatuses, status];
    this.props.onChange(newStatuses);
  };

  renderStatus = (status: string, color: string, icon?: React.FC) => (
    <Status key={status}>
      <Form.Checkbox
        checked={includes(this.props.selectedStatuses, status)}
        onChange={() => this.onChange(status)}
      />
      {icon ? (
        <IconWrapper color={color}>{React.createElement(icon)}</IconWrapper>
      ) : (
        <Indicator color={color} />
      )}
      <span>
        <FormattedMessage id={`RequestStatus--${status}`} />
      </span>
    </Status>
  );

  toggleShowAll = () =>
    this.setState((state) => ({ showAllStatuses: !state.showAllStatuses }));

  shouldShowAllStatusesOnLoad = () => {
    const { selectedStatuses } = this.props;
    return !!intersection(selectedStatuses, SECONDARY_STATUSES).length;
  };

  render() {
    const { showAllStatuses } = this.state;
    return (
      <Form.Field>
        <Form.Label bold>
          <FormattedMessage id="TrackingStatusFilter__Label" />
        </Form.Label>

        {PRIMARY_STATUSES.map((status) => {
          const styled = styledStatuses[status];
          return this.renderStatus(status, styled.color, styled.icon);
        })}

        {showAllStatuses &&
          SECONDARY_STATUSES.map((status) => {
            const styled = styledStatuses[status];
            return this.renderStatus(status, styled.color, styled.icon);
          })}

        <Status onClick={this.toggleShowAll}>
          <ShowAllToggle>
            {showAllStatuses ? <IconMinus /> : <IconPlus />}
          </ShowAllToggle>

          <span>
            {showAllStatuses ? (
              <FormattedMessage id="TrackingStatusFilter__ShowLess" />
            ) : (
              <FormattedMessage id="TrackingStatusFilter__ShowMore" />
            )}
          </span>
        </Status>
      </Form.Field>
    );
  }
}
