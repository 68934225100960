import * as UPDATE_DRAFT from 'graphql/mutations/updateDraft.graphql';
import apolloClient from '../apolloClient';

interface Options {
  platform: string;
  text?: string;
  mentions?: any[];
  suggestionIds: number | string | (number | string)[];
}

const updateDraft = async (options: Options) => {
  const { errors, data } = await apolloClient.mutate({
    mutation: UPDATE_DRAFT,
    variables: { ...options, text: options.text || '' },
  });

  if (errors) return { errors };
  if (data && data.errors) return { errors: data.errors };
  if (data) return data;
  return null;
};

export default updateDraft;
