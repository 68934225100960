import React from 'react';
import pick from 'lodash/pick';
import omit from 'lodash/omit';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { colors, grid } from 'styles/theme';

interface Props {
  onClick?: (e?: any) => void;
  to?: string;
  href?: string;
  type?: 'button' | 'submit';
  disabled?: boolean;
  loading?: boolean;
  renderLoading?: React.ReactNode;
  primary?: boolean;
  secondary?: boolean;
  filledBg?: boolean;
  filledBlackBg?: boolean;
  filledGreyBg?: boolean;
  inlineBlock?: boolean;
  athlete?: boolean;
  mono?: boolean;
  monoBold?: boolean;
  inverseMono?: boolean;
  cancel?: boolean;
  children: React.ReactNode;
  'data-testid'?: string;
  dataQa?: string;
  noSvgMargin?: boolean;
  large?: boolean;
  largeInline?: boolean;
  className?: string;
}

const stylePropKeys = [
  'primary',
  'secondary',
  'filledBg',
  'filledBlackBg',
  'filledGreyBg',
  'athlete',
  'inlineBlock',
  'mono',
  'monoBold',
  'inverseMono',
  'cancel',
  'large',
  'largeInline',
];

const StyledButton = styled.button`
  display: block;
  transition: all 0.15s;
  margin-bottom: ${grid(2)};
  border: 2px solid ${colors.socialiePink};
  border-radius: 4px;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2);
  background: transparent;
  padding: 0 ${grid(3)};
  width: 100%;
  text-align: center;
  text-transform: none;
  text-decoration: none;
  color: ${colors.socialiePink};
  font-size: 14px;
  font-weight: normal;

  &:last-child {
    margin-bottom: 0;
  }

  ${(props: any) =>
    props.large &&
    `
      font-size: 16px;
      font-weight: semibold;
      padding: ${grid(0.5)} ${grid(4)};
    `}

  ${(props: any) =>
    props.largeInline &&
    `
      display: inline-block;
      margin-bottom: 0;
      width: auto;
    `}

    ${(props: any) =>
    props.filledBg &&
    `
      border: 1px solid ${colors.socialiePink};
      background-color: ${colors.socialiePink};
      color: ${colors.white};

      &:hover {
        background-color: ${colors.socialiePinkHover};
        box-shadow: 0 1px 1px 0 rgba(0,0,0,0.2), 0 1px 3px 0 rgba(0,0,0,0.12);
      }
    `}

    ${(props: any) =>
    props.filledBlackBg &&
    `
      border: 1px solid ${colors.grey3};
      background-color: ${colors.grey3};
      color: ${colors.white};

      &:hover {
        background-color: ${colors.grey4};
        box-shadow: 0 1px 1px 0 rgba(0,0,0,0.2), 0 1px 3px 0 rgba(0,0,0,0.12);
      }
    `}

    ${(props: any) =>
    props.filledGreyBg &&
    `
      border: 1px solid ${colors.grey8};
      background-color: ${colors.grey8};
      color: ${colors.grey1};

      &:hover {
        background-color: ${colors.grey9};
        box-shadow: 0 1px 1px 0 rgba(0,0,0,0.2), 0 1px 3px 0 rgba(0,0,0,0.12);
      }
    `}

    ${(props: any) =>
    props.athlete &&
    `
      border: 1px solid ${colors.purple};
      background-color: ${colors.purple};
      color: ${colors.white};

      &:hover {
        background-color: ${colors.purpleHover};
        box-shadow: 0 1px 1px 0 rgba(0,0,0,0.2), 0 1px 3px 0 rgba(0,0,0,0.12);
      }
    `}

    ${(props: any) =>
    props.disabled &&
    `
      opacity: 0.3;
      cursor: not-allowed;
    `}

    ${(props: any) =>
    props.inlineBlock &&
    `
      display: inline-block;
      margin-bottom: 0;
      width: auto;
      padding: 0 ${grid(1.5)};
    `}

    ${(props: any) =>
    props.mono &&
    `
      border: 1px solid ${colors.border};
      color: ${colors.darkText};

      &:hover {
        background-color: ${colors.greyBg};
      }
    `}

    ${(props: any) =>
    props.monoBold &&
    `
      border: 2px solid ${colors.grey2};
      color: ${colors.grey2};
      border-radius: 8px;
      padding: ${grid(0.5)} ${grid(6)};

      &:hover {
        background-color: ${colors.greyBg};
      }
    `}

    ${(props: any) =>
    props.inverseMono &&
    `
      border: 1px solid ${colors.inverseBorder};
      color: ${colors.white};
    `}

    ${(props: any) =>
    props.cancel &&
    `
      border: none;
      background: transparent;
      color: ${colors.lightText};

      &:hover {
        background-color: ${colors.greyBg};
        color: ${colors.darkText};
      }
    `}
` as any;

const safeRenderLink = (props: any) => (
  <Link {...omit(props, [...stylePropKeys, 'icon'])} />
);

const LinkButton = StyledButton.withComponent(safeRenderLink as any) as any;

const AButton = StyledButton.withComponent('a') as any;

interface ButtonContentProps {
  noSvgMargin?: boolean;
}

const Content = styled.span<ButtonContentProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  top: -1px;
  height: 100%;
  min-height: ${grid(4)};

  ${({ noSvgMargin }) =>
    noSvgMargin
      ? `
    svg {
      margin-right: 0;
    }
  `
      : `svg {
      margin-right: ${grid(1)};
    }`}

  svg {
    display: block;
    position: relative;
    top: 1px;
    width: ${grid(2)};
    height: ${grid(2)};
  }
`;

const Button = React.forwardRef((props: Props, ref) => {
  const styleProps = pick(props, stylePropKeys);
  const sharedProps = {
    ...styleProps,
    ...pick(props, ['onClick', 'data-testid', 'data-qa', 'className']),
    ref,
  };

  const internals = (
    <Content noSvgMargin={props.noSvgMargin}>{props.children}</Content>
  );

  if (props.to) {
    return (
      <LinkButton to={props.to} onClick={props.onClick} {...sharedProps}>
        {internals}
      </LinkButton>
    );
  }

  if (props.href) {
    return (
      <AButton href={props.href} onClick={props.onClick} {...sharedProps}>
        {internals}
      </AButton>
    );
  }

  return (
    <StyledButton
      onClick={props.onClick}
      type={props.type}
      disabled={props.disabled}
      {...sharedProps}
      {...styleProps}
    >
      {internals}
    </StyledButton>
  );
});

Button.defaultProps = {
  type: 'button',
  secondary: false,
  filledBg: false,
  filledBlackBg: false,
  filledGreyBg: false,
  inlineBlock: false,
  children: null,
};

export default Button;
