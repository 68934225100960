import React from 'react';
import includes from 'lodash/includes';
import every from 'lodash/every';
import { Mutation } from '@apollo/react-components';
import { ConfirmModal, FlyoutMenu } from 'components';
import { FormattedMessage } from 'react-intl';
import Suggestion from 'types/Suggestion';
import IndexSuggestion from 'types/IndexSuggestion';
import * as RESEND_SUGGESTION from 'graphql/mutations/resendSuggestion.graphql';

interface Props {
  suggestion: Suggestion | IndexSuggestion;
  onCompleted?: Function;
}

interface State {
  isConfirmModalOpen: boolean;
}

interface MutationData {
  resendSuggestion: { errors?: boolean; suggestion?: Suggestion };
}

export default class ResendSuggestion extends React.Component<Props, State> {
  state = { isConfirmModalOpen: false };

  openConfirmModal = () => this.setState({ isConfirmModalOpen: true });
  closeConfirmModal = () => this.setState({ isConfirmModalOpen: false });

  onCompleted = (data: any) => {
    if (data.resendSuggestion.errors) {
      alert('Error resending suggestion.');
    } else {
      this.closeConfirmModal();
      this.props.onCompleted && this.props.onCompleted(data);
    }
  };

  render() {
    const { suggestion } = this.props;
    const { actionableType } = suggestion;
    const allCompleted = every(suggestion.requests, (r) =>
      includes(['published', 'scheduled', 'submitted'], r.status)
    );

    const isExpired =
      (!!suggestion.expiresAt && suggestion.expiresAt < new Date().getTime()) ||
      (!!suggestion.postAt && suggestion.postAt < new Date().getTime());

    const noDeliveryReceipts = suggestion.requests.every(
      (r) => !r.deliveryReceipts.length
    );

    const notAllowed = allCompleted || isExpired || noDeliveryReceipts;

    const confirmMsg =
      actionableType === 'ContentRequest'
        ? 'ResendSuggestion__ConfirmBody--ContentRequest'
        : 'ResendSuggestion__ConfirmBody';

    return (
      <Mutation<MutationData>
        mutation={RESEND_SUGGESTION}
        variables={{ id: suggestion.id }}
        onCompleted={this.onCompleted}
      >
        {(resendSuggestion, { loading }) => (
          <>
            <FlyoutMenu.Item
              onClick={this.openConfirmModal}
              disabled={notAllowed}
            >
              <FormattedMessage id="ResendSuggestion__Button" />
            </FlyoutMenu.Item>

            <ConfirmModal
              isOpen={this.state.isConfirmModalOpen}
              onRequestClose={this.closeConfirmModal}
              onConfirm={resendSuggestion}
              isLoading={loading}
              heading="ResendSuggestion__ConfirmHeading"
            >
              <FormattedMessage id={confirmMsg} />
            </ConfirmModal>
          </>
        )}
      </Mutation>
    );
  }
}
