import styled from 'styled-components';
import { colors, grid } from 'styles/theme';

const Card = styled.div`
  margin-bottom: ${grid(3)};
  border-radius: 4px;
  background-color: ${colors.white};
  padding: ${grid(3)} ${grid(2)};
`;

export default Card;
