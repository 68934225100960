import React from 'react';
import { useIntl } from 'hooks';
import { Button } from 'components';
import User from 'types/User';
import SemiLoggedInUser from 'types/SemiLoggedInUser';

interface GetStartedProps {
  user: User | SemiLoggedInUser;
  goToNextStep: () => void;
  onSkipMfa?: () => void;
  hasSkipButton: boolean;
}

export default function GetStarted({
  user,
  goToNextStep,
  onSkipMfa,
  hasSkipButton,
}: GetStartedProps) {
  const { t } = useIntl();
  if (!user.currentSuggester) return null;

  const {
    accountName,
    accountConfiguration: { requireMfa },
  } = user.currentSuggester;

  return (
    <div className="text-left">
      <div className="mb-5 leading-20">
        <p className="text-dark">{t('Security__ManageMfa--GetStarted-Desc')}</p>

        {requireMfa && (
          <p className="mt-1.5 font-semibold text-dark">
            {t('Security__ManageMfa--GetStarted-Required', { accountName })}
          </p>
        )}
      </div>

      <div className="flex items-center justify-end">
        {hasSkipButton && (
          <div className="mr-1">
            <button
              onClick={onSkipMfa}
              className="bump-up-1 mr-1.5 text-socialiePink hover:underline"
            >
              <span className="flex items-center">
                {t('Security__ManageMfa--ChooseProtocol-Skip-Button')}
              </span>
            </button>
          </div>
        )}
        <Button filledBg inlineBlock onClick={goToNextStep}>
          {t('Security__ManageMfa--GetStarted-GetStarted-Button')}
        </Button>
      </div>
    </div>
  );
}
