import React from 'react';
import styled from 'styled-components';
import castArray from 'lodash/castArray';
import compact from 'lodash/compact';
import uniq from 'lodash/uniq';
import { FormattedMessage } from 'react-intl';
import { Mutation, Query } from '@apollo/react-components';
import Campaign from 'types/Campaign';
import IndexSuggestion from 'types/IndexSuggestion';
import { Form, Modal, WithIntl, Loader, ErrorDebug } from '..';
import * as mutation from 'graphql/mutations/setSuggestionCampaign.graphql';
import * as query from 'graphql/queries/campaigns.graphql';
import Button from '../Button';

interface Props {
  isOpen: boolean;
  onRequestClose: () => void;
  suggestions: IndexSuggestion[];
}

interface State {
  campaignId?: number | string;
}

const Warning = styled.div`
  font-size: 12px;
`;

interface QueryData {
  campaigns: Campaign[];
}

interface MutationData {
  setSuggestionCampaign: {
    errors?: string;
    suggestions?: IndexSuggestion[];
  };
}

class SetCampaignModal extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      campaignId: this.getInitialCampaignId(),
    };
  }

  getInitialCampaignId = () => {
    const { suggestions } = this.props;
    const campaigns = compact(suggestions.map((s) => s.campaign));
    const ids = uniq(campaigns.map((c) => c.id));
    if (ids.length === campaigns.length) return ids[0];
    return undefined;
  };

  updateCampaignId = (campaignId?: number | string) =>
    this.setState({ campaignId });

  render() {
    const { isOpen, onRequestClose } = this.props;
    const suggestions = castArray(this.props.suggestions);
    const suggestionIds = suggestions.map((s) => s.id);
    const { campaignId } = this.state;
    const itemCount = suggestionIds.length;
    const suggestionsWithCampaigns = suggestions.filter((s) => !!s.campaign);

    return (
      <WithIntl>
        {(_, t) => (
          <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            contentLabel={t('SetCampaignModal__Heading')}
            renderHeading={<FormattedMessage id="SetCampaignModal__Heading" />}
          >
            <Mutation<MutationData>
              mutation={mutation}
              variables={{ suggestionIds, campaignId }}
              onCompleted={(data) => {
                if (!data.setSuggestionCampaign.errors) {
                  this.updateCampaignId();
                  onRequestClose();
                }
              }}
              refetchQueries={['Campaigns']}
            >
              {(setSuggestionCampaign, { loading: isAdding }) => (
                <Query<QueryData> query={query}>
                  {({ data, loading, error }) => {
                    if (loading) return <Loader />;
                    if (error) return <ErrorDebug error={error} />;
                    if (!data || !data.campaigns) return null;

                    const { campaigns } = data;
                    const options = campaigns.map((t: Campaign) => ({
                      value: t.id,
                      label: t.name,
                    }));

                    return (
                      <Form onSubmit={setSuggestionCampaign}>
                        <Form.Field>
                          {itemCount > 1 && (
                            <Form.Label>
                              <FormattedMessage
                                id="SetCampaignModal__Description"
                                values={{ itemCount }}
                              />
                            </Form.Label>
                          )}

                          <Form.Select
                            value={this.state.campaignId}
                            onChange={this.updateCampaignId}
                            creatable
                            placeholder={t('SetCampaignModal__Placeholder')}
                            options={options}
                            isClearable={false}
                            formatCreateLabel={(label) =>
                              t('SetCampaignModal__CreatePrompt', {
                                label,
                              })
                            }
                          />

                          {itemCount > 1 &&
                            !!suggestionsWithCampaigns.length && (
                              <Warning>
                                <FormattedMessage
                                  id="SetCampaignModal__ExistingCampaignWarning"
                                  values={{
                                    count: suggestionsWithCampaigns.length,
                                  }}
                                />
                              </Warning>
                            )}
                        </Form.Field>

                        <Modal.Actions>
                          <Button
                            inlineBlock
                            cancel
                            type="button"
                            onClick={this.props.onRequestClose}
                          >
                            <FormattedMessage id="Global__Cancel" />
                          </Button>

                          <Button inlineBlock filledBg type="submit">
                            <FormattedMessage id="Global__Done" />
                          </Button>
                        </Modal.Actions>
                      </Form>
                    );
                  }}
                </Query>
              )}
            </Mutation>
          </Modal>
        )}
      </WithIntl>
    );
  }
}
export default SetCampaignModal;
