import { useEffect, useState, useRef } from 'react';

function useSticky(scrollTop: number) {
  const [isSticky, setSticky] = useState(false);
  const ref = useRef<any>(null);

  useEffect(() => {
    const handleScroll = () => {
      if (ref?.current?.getBoundingClientRect()?.top <= scrollTop) {
        setSticky(true);
      } else {
        setSticky(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', () => handleScroll);
    };
  }, [scrollTop]);

  return { ref, isSticky };
}

export default useSticky;
