import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { IconContentRequest, IconSuggestion } from 'icons';
import { grid, colors } from 'styles/theme';
import { SuggestionExpiryStatus } from 'components';

interface Props {
  actionableType: string;
  expiresAt?: number;
}

const icons = {
  ContentRequest: IconContentRequest,
  Suggestion: IconSuggestion,
};

// prettier-ignore
const Wrapper = styled.div`
  display: flex;
  align-items: center;
  border-radius: 4px;
  background-color: ${colors.darkestGreyBg};
  padding: 0 ${grid(1)};
  line-height: ${grid(2.5)};
  color: ${colors.white};
  font-size: 12px;
  border: 1px solid transparent;

  span {
    position: relative;
    top: -1px;
  }

  svg {
    display: block;
    margin-right: ${grid(0.5)};
    width: ${grid(1.5)};
    height: ${grid(1.5)};
    color: ${colors.grey5};
  }

  ${(props: any) => props.isSuggestion && `
    background-color: transparent;
    border: 1px solid ${colors.border};
    color: ${colors.darkText};
  `};

  ${(props: any) => props.hasExpiry && `
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  `};
` as any;

const ActionableType = ({ actionableType, expiresAt }: Props) => {
  const Icon = icons[actionableType];
  return (
    <div className="bump-down-1 mr-0.5 flex">
      <Wrapper
        isSuggestion={actionableType === 'Suggestion'}
        hasExpiry={!!expiresAt || actionableType === 'ContentRequest'}
      >
        {Icon && <Icon />}
        <span>
          <FormattedMessage id={`ActionableType--${actionableType}`} />
        </span>
      </Wrapper>

      <SuggestionExpiryStatus
        suggestion={{ expiresAt, actionableType }}
        isAttached
      />
    </div>
  );
};

export default ActionableType;
