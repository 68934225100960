import React from 'react';
import { Switch } from 'components';

interface Props {
  isOn: any;
  onToggle: () => void;
  children: React.ReactNode;
  disabled?: boolean;
}

export default function FormSwitch(props: Props) {
  const { isOn, onToggle, children, disabled } = props;
  return (
    <label className="mb-0.5 flex items-center">
      <div className="bump-down-0.5 mr-1 flex items-center">
        <Switch isOn={isOn} onToggle={onToggle} disabled={disabled} />
      </div>

      {children}
    </label>
  );
}
