// Tracks all the different batch suggestion save operations that are active
// at a given time.  When the array is empty it means the batch suggestion is
// done saving and can safely be sent

import without from 'lodash/without';
import uniq from 'lodash/uniq';
import { OtherAction } from '../global';

export type State = string[];

enum ActionTypes {
  START = 'ui/batchSuggestionSaveOperations/START',
  STOP = 'ui/batchSuggestionSaveOperations/STOP',
}

interface StartAction {
  type: ActionTypes.START;
  payload: { operation: string };
}

interface StopAction {
  type: ActionTypes.STOP;
  payload: { operation: string };
}

type Action = StartAction | StopAction | OtherAction;

const batchSuggestionSaveOperationsReducer = (
  state: State = [],
  action: Action
): State => {
  switch (action.type) {
    case ActionTypes.STOP:
      return without(state, action.payload.operation);
    case ActionTypes.START:
      return uniq([...state, action.payload.operation]);
    default:
      return state;
  }
};

// Actions
export const startBatchSuggestionSaveOperation = (operation: string) => ({
  type: ActionTypes.START,
  payload: { operation },
});

export const stopBatchSuggestionSaveOperation = (operation: string) => ({
  type: ActionTypes.STOP,
  payload: { operation },
});

export default batchSuggestionSaveOperationsReducer;
