import React from 'react';
import { Link, LinkProps } from 'react-router-dom';

interface Props extends LinkProps {
  condition: any;
  tagName: string;
}

export default function LinkIf({
  to,
  condition,
  tagName,
  children,
  ...props
}: Props) {
  if (condition) {
    return (
      <Link to={to} {...props}>
        {children}
      </Link>
    );
  }

  return React.createElement(tagName || 'span', props, children);
}
