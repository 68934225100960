import React, { useState } from 'react';
import { VerifyPassword } from 'components';
import { GetStarted, ChooseProtocol, RegisterAuthyUser } from './screens';
import User from 'types/User';
import SemiLoggedInUser from 'types/SemiLoggedInUser';

interface ManageMfaProps {
  user: User | SemiLoggedInUser;
  password?: string;
  hasPasswordPresent: boolean;
  onSkipMfa?: () => void;
  hasSkipButton: boolean;
  onMfaSetupComplete: () => void;
  onSetIsPreventingUnload: () => void;
}

export default function ManageMfa({
  user,
  hasSkipButton,
  password,
  onSkipMfa,
  onMfaSetupComplete,
  hasPasswordPresent,
  onSetIsPreventingUnload,
}: ManageMfaProps) {
  const { enableMfa } = user;
  const [sawIntroScreen, setSawIntroScreen] = useState(false);
  const [verifiedPassword, setVerifiedPassword] = useState(false);
  const [didRegisterAuthyUser, setDidRegisterAuthyUser] = useState(false);
  const [recoveryCodePassword, setRecoveryCodePassword] = useState('');

  if (!enableMfa && !sawIntroScreen) {
    return (
      <GetStarted
        hasSkipButton={hasSkipButton}
        onSkipMfa={onSkipMfa}
        user={user}
        goToNextStep={() => {
          if (hasPasswordPresent) {
            setSawIntroScreen(true);
            setVerifiedPassword(true);
          } else {
            setSawIntroScreen(true);
          }
        }}
      />
    );
  } else if (!verifiedPassword && !hasPasswordPresent) {
    return (
      <VerifyPassword
        user={user}
        onRetrievePassword={(p) => setRecoveryCodePassword(p)}
        onSuccess={() => setVerifiedPassword(true)}
      />
    );
  } else if (!enableMfa && !didRegisterAuthyUser) {
    return (
      <RegisterAuthyUser onSuccess={() => setDidRegisterAuthyUser(true)} />
    );
  } else {
    return (
      <ChooseProtocol
        onSetIsPreventingUnload={onSetIsPreventingUnload}
        recoveryCodePassword={password ? password : recoveryCodePassword}
        onMfaSetupComplete={onMfaSetupComplete}
      />
    );
  }
}
