import React from 'react';
import { FormattedMessage } from 'react-intl';
import FormattedTimeAgo from '../FormattedTimeAgo';

interface Props {
  publisher: { lastActiveAt?: number };
}

const PublisherLastActive = ({ publisher }: Props) => (
  <span>
    {!!publisher.lastActiveAt ? (
      <>
        <FormattedMessage id="PublisherLastActive__LastActive" />{' '}
        <FormattedTimeAgo timestamp={publisher.lastActiveAt} />
      </>
    ) : (
      <FormattedMessage id="PublisherLastActive__Never" />
    )}
  </span>
);

export default PublisherLastActive;
