import React from 'react';
import styled from 'styled-components';
import { grid, colors } from 'styles/theme';
import { FormattedMessage } from 'react-intl';
import { QTooltip } from 'components';
import { IconEdit } from 'icons';

interface Props {
  text: string;
  icon: React.ReactNode;
  renderSubheading?: React.ReactNode;
  tooltip?: React.ReactNode;
  onEdit?: () => void;
}

const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: ${grid(1)};
  line-height: ${grid(2.25)};
  letter-spacing: 0.35px;
  color: ${colors.darkText};
  font-weight: 600;

  svg {
    margin-right: ${grid(1)};
    width: ${grid(2.5)};
    height: ${grid(2.5)};
    color: ${colors.darkText};
  }
`;

const Subheading = styled.div`
  font-weight: normal;
`;

const Edit = styled.button.attrs({ type: 'button' })`
  opacity: 0;
  margin-left: auto;
  width: ${grid(2)};
  height: ${grid(2)};

  ${Wrapper}:hover & {
    opacity: 1;
  }

  svg {
    width: 100%;
    height: 100%;
    color: ${colors.socialiePink};
  }
`;

const IconHeading = ({
  text,
  icon,
  renderSubheading,
  tooltip,
  onEdit,
}: Props) => (
  <Wrapper>
    {icon}
    <span>
      <FormattedMessage id={text} />
      {renderSubheading && <Subheading>{renderSubheading}</Subheading>}
    </span>

    {tooltip && <QTooltip content={tooltip} />}

    {onEdit && (
      <Edit onClick={onEdit}>
        <IconEdit />
      </Edit>
    )}
  </Wrapper>
);

export default IconHeading;
