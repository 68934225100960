import React, { useState } from 'react';
import styled from 'styled-components';
import includes from 'lodash/includes';
import { grid } from 'styles/theme';
import Recipient from 'types/Recipient';
import Publisher from 'types/Publisher';
import ContactMethod from '../../ContactMethod';
import Avatar from 'components/Avatar';
import WithCurrentUser from 'components/WithCurrentUser';
import { IconAlertOff } from 'icons';
import UserRecipient from '../../UserRecipient';
import PendingUserRecipient from '../../PendingUserRecipient';
import clsx from 'clsx';
import { useIntl } from 'hooks';
import { contactProtocolFieldMap } from 'helpers';

interface Props {
  recipients: Recipient[];
  onAddRecipient: (recipients: Recipient | Recipient[]) => void;
  publisher?: Publisher;
  collapsable: boolean;
  contactProtocols: string[];
  noMethodsMsg?: string;
}

const Wrapper = styled.div`
  display: flex;
  position: relative;
  align-items: flex-start;
  margin-bottom: ${grid(1)};
  padding: 0 ${grid(1)};
  padding-right: ${grid(1)};
  font-size: 14px;
`;

const AvatarWrapper = styled.div`
  position: relative;
  flex-shrink: 0;
  margin-right: ${grid(1.5)};
  width: ${grid(3.5)};
  height: ${grid(3.5)};
`;

const Logo = styled.div`
  position: absolute;
  right: -${grid(0.5)};
  bottom: -${grid(0.5)};
  width: ${grid(2.5)};
  height: ${grid(2.5)};
`;

export default function PublisherRow(props: Props) {
  const { t } = useIntl();

  const {
    recipients,
    onAddRecipient,
    collapsable,
    contactProtocols,
    noMethodsMsg,
  } = props;

  const [isExpanded, setIsExpanded] = useState(!collapsable);

  const toggleExpanded = () => {
    if (!collapsable) return;
    setIsExpanded(!isExpanded);
  };

  const publisher =
    props.publisher ||
    recipients[0].contactMethod?.publisher ||
    recipients[0].publisherUser?.publisher!;

  const userRecipients = recipients.filter((r) => r.publisherUser);

  const primaryContactMethods = recipients.filter(
    (r) =>
      r.contactMethod &&
      r.contactMethod.wantsNotifications &&
      includes(contactProtocols, r.contactMethod.protocol)
  );

  const secondaryContactMethods = recipients.filter(
    (r) =>
      r.contactMethod &&
      !r.contactMethod.wantsNotifications &&
      includes(contactProtocols, r.contactMethod.protocol)
  );

  const contactableUserRecipients = userRecipients.filter((recipient) => {
    const publisherUser = recipient.publisherUser!;
    return contactProtocols.some(
      (p) => !!publisherUser[contactProtocolFieldMap[p]]
    );
  });

  const contactablePendingUserRecipients = userRecipients.filter(
    (recipient) => {
      const publisherUser = recipient.publisherUser!;
      return (
        publisherUser.pending &&
        publisherUser.inviteProtocol &&
        contactProtocols.includes(publisherUser.inviteProtocol!)
      );
    }
  );

  const userRecipientsWithProtocol = contactableUserRecipients
    .map((recipient) => {
      const user = recipient.publisherUser!;
      const protocols = contactProtocols.filter(
        (p) =>
          !!user[contactProtocolFieldMap[p]] &&
          user.notificationPreferences.includes(p)
      );
      return protocols.map((protocol) => ({ ...recipient, protocol }));
    })
    .flat();

  const defaultMethodRecipients = [
    ...userRecipientsWithProtocol,
    ...primaryContactMethods,
  ];

  const handleAddDefaults = () => {
    onAddRecipient([...primaryContactMethods, ...userRecipientsWithProtocol]);
  };

  const noMethods =
    !contactableUserRecipients.length &&
    !secondaryContactMethods.length &&
    !contactablePendingUserRecipients &&
    !primaryContactMethods.length;

  const handleAddUncontactable = () => {
    onAddRecipient({
      id: publisher.id,
      publisher: publisher,
      combinedId: `Publisher-${publisher.id}`,
    });
  };

  return (
    <WithCurrentUser>
      {(currentUser) => {
        const showLogo = !!currentUser.currentSuggester?.children.length;

        return (
          <Wrapper>
            <AvatarWrapper>
              <Avatar src={publisher.image} />

              {showLogo && (
                <Logo>
                  <Avatar
                    src={publisher.suggester.accountConfiguration.logoUrl}
                  />
                </Logo>
              )}
            </AvatarWrapper>

            <div className="block flex-1 overflow-hidden leading-20 text-dark">
              <div
                className={clsx(
                  'group flex items-center px-1 py-0.5 leading-20 hover:bg-greyBg',
                  { 'bg-greyBg': isExpanded }
                )}
              >
                <button
                  data-qa={`recipients-search-menu-list-publisher-row-${publisher.name}`}
                  tabIndex={0}
                  className="hover:underline"
                  onClick={() =>
                    !!defaultMethodRecipients.length
                      ? handleAddDefaults()
                      : collapsable
                      ? toggleExpanded()
                      : handleAddUncontactable()
                  }
                >
                  <span className="flex items-center">
                    {publisher.name}
                    {!primaryContactMethods.length &&
                      !userRecipientsWithProtocol.length && (
                        <IconAlertOff className="bump-down-0.5 ml-0.5 block h-1.5 w-1.5 text-light" />
                      )}
                  </span>
                </button>

                {collapsable && !noMethods && (
                  <button
                    className={clsx(
                      'ml-1 text-light hover:underline group-hover:opacity-100',
                      {
                        'opacity-0': !isExpanded,
                        'opacity-100': isExpanded,
                      }
                    )}
                    onClick={toggleExpanded}
                  >
                    {t(
                      isExpanded
                        ? 'RecipientsSearch__Close'
                        : 'RecipientsSearch__Expand'
                    )}
                  </button>
                )}
              </div>

              {isExpanded && (
                <>
                  {contactableUserRecipients.map((recipient) => (
                    <SubRow key={recipient.id}>
                      <UserRecipient
                        onAddRecipient={onAddRecipient}
                        recipient={recipient}
                        contactProtocols={contactProtocols}
                      />
                    </SubRow>
                  ))}

                  {contactablePendingUserRecipients.map((recipient) => (
                    <SubRow key={recipient.id}>
                      <PendingUserRecipient
                        onAddRecipient={onAddRecipient}
                        recipient={recipient}
                      />
                    </SubRow>
                  ))}

                  {primaryContactMethods.map((recipient) => (
                    <SubRow key={recipient.id}>
                      <ContactMethod
                        onClick={() => onAddRecipient(recipient)}
                        contactMethod={recipient.contactMethod!}
                      />
                    </SubRow>
                  ))}

                  {secondaryContactMethods.map((recipient) => (
                    <SubRow key={recipient.id}>
                      <ContactMethod
                        onClick={() => onAddRecipient(recipient)}
                        contactMethod={recipient.contactMethod!}
                        secondary
                      />
                    </SubRow>
                  ))}

                  {noMethods && (
                    <SubRow>
                      <span className="text-light">
                        {t(noMethodsMsg || 'RecipientsSearch__NoSMSMethods')}
                      </span>
                    </SubRow>
                  )}
                </>
              )}
            </div>
          </Wrapper>
        );
      }}
    </WithCurrentUser>
  );
}

function SubRow({ children }: { children: React.ReactNode }) {
  return (
    <div className="ml-1.5 mt-0.25 px-1 py-0.5 leading-18 hover:bg-greyBg">
      {children}
    </div>
  );
}
