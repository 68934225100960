import React from 'react';
import styled from 'styled-components';
import { grid, colors } from 'styles/theme';
import { IconChat } from 'icons';
import { media } from 'styles';

interface Props {
  isOpen: boolean;
  toggleOpen: () => void;
  hasUnreadMessages: boolean;
}

// prettier-ignore
const Wrapper = styled.button`
  position: relative;
  margin-right: ${grid(1.5)};
  border-radius: 4px;
  padding: 6px;
  width: ${grid(4)};
  height: ${grid(4)};
  color: ${colors.white};

  svg {
    width: 100%;
    height: 100%;
  }

  ${(props: any) => props.isOpen && `
    background-color: ${colors.socialiePink};

    &:hover {
      background-color: ${colors.socialiePink};
    }
  `}

  ${media.tablet`
    &:hover {
      background-color: ${colors.grey3};
      ${(props: any) => props.isOpen && `
        background-color: ${colors.socialiePink}
      `};
    }
  `}
` as any;

// prettier-ignore
const UnreadIndicator = styled.div`
  position: absolute;
  top: ${grid(0.5)};
  right: 3px;
  transform: scale(0);
  transform-origin: 50% 50%;
  transition: all 0.15s ease-in;
  opacity: 0;
  border-radius: 50%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  background-color: ${colors.socialiePink};
  width: ${grid(1.25)};
  height: ${grid(1.25)};

  ${(props: any) => props.isOn && `
    transform: scale(1);
    opacity: 1;
  `};
` as any;

const ToggleButton = ({ isOpen, toggleOpen, hasUnreadMessages }: Props) => (
  <Wrapper isOpen={isOpen} onClick={toggleOpen}>
    <UnreadIndicator isOn={hasUnreadMessages} />
    <IconChat />
  </Wrapper>
);

export default ToggleButton;
