import React from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { colors } from 'styles/theme';

interface Props {
  light?: boolean;
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => any;
  children: React.ReactNode;
}

// prettier-ignore
const StyledButton = styled.button.attrs({ type: 'button' })`
  display: inline;
  color: ${colors.lightText};
  font-size: 12px;

  &:hover {
    text-decoration: underline;
    color: ${colors.grey1};

    ${(props: any) => props.theme.light && `color: ${colors.white}`};
  }
`;

const MiniButton = ({ light, children, onClick }: Props) => (
  <ThemeProvider theme={{ light }}>
    <StyledButton onClick={onClick}>{children}</StyledButton>
  </ThemeProvider>
);

export default MiniButton;
