import React, { useState, useEffect } from 'react';
import { queryTwitterMention, queryFacebookMention } from 'api/platformMention';
import { useDebounce } from 'use-debounce';
import MentionEntry from './MentionEntry';
import { useCurrentUser } from 'hooks';
import OauthIdentity from 'types/OauthIdentity';

interface Props {
  plugin: any;
  platform: string;
}

const QUERY_METHODS = {
  facebook: queryFacebookMention,
  twitter: queryTwitterMention,
};

export default function Mentions({ plugin, platform }: Props) {
  const [mentionSuggestions, setMentionSuggestions] = useState<any[]>([]);
  const [value, setValue] = useState('');
  const query = useDebounce(value, 250);

  useEffect(() => {
    const getMentionSuggestions = async () => {
      const queryMethod = QUERY_METHODS[platform];
      if (!queryMethod) return;
      setMentionSuggestions([{ name: 'Loading...' }]);
      let data = await queryMethod(query);
      if (platform === 'twitter') {
        data = data.map((u: any) => ({
          twitter_verified: u.verified,
          image: u.profile_image_url_https,
          twitter_name: u.name,
          name: u.screen_name,
        }));
      }
      setMentionSuggestions(data);
    };

    if (query.length > 1) getMentionSuggestions();
  }, [query, platform]);

  const currentUser = useCurrentUser();
  const { MentionSuggestions } = plugin;
  if (!MentionSuggestions) return null;

  const hasTwitter = !!currentUser.oauthIdentities.find(
    (o: OauthIdentity) => o.provider === 'twitter'
  );

  const suggestions =
    hasTwitter || platform !== 'twitter'
      ? mentionSuggestions
      : value
      ? [false]
      : [];

  return (
    <MentionSuggestions
      onClose={() => setValue('')}
      onSearchChange={(change: { value: string }) => setValue(change.value)}
      suggestions={suggestions}
      entryComponent={MentionEntry}
    />
  );
}
