import React from 'react';
import styled from 'styled-components';
import { grid, colors } from 'styles/theme';
import { IconVideo } from 'icons';
import { formatLength } from 'helpers/formatNumber';

interface Props {
  length: number;
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  width: fit-content;
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.4);
  padding: ${grid(0.5)};
  line-height: ${grid(2)};
  color: ${colors.white};
  font-size: 12px;

  svg {
    display: block;
    position: relative;
    top: 1px;
    margin-right: ${grid(0.5)};
    width: ${grid(2)};
    height: ${grid(2)};
  }
`;

export default function VideoLength({ length }: Props) {
  return (
    <Wrapper>
      <IconVideo /> {formatLength(length)}
    </Wrapper>
  );
}
