import { useEffect } from 'react';
import includes from 'lodash/includes';
import socket from 'socket';
import loadSuggestions from 'graphql/operations/loadSuggestions';

/**
 * Listen and react to the suggestionsUpdated socket event in a component
 * @param {number} [suggestionId] A specific suggestion id to watch for
 * @param {function} [operation] A custom operation to run instead of the default
 */
export default function useListenForSuggestionUpdates(
  suggestionId?: number,
  operation?: (ids: number[]) => any
) {
  useEffect(() => {
    const handleSuggestionsUpdated = async ({ suggestionIds }: any) => {
      if (!suggestionId || includes(suggestionIds, suggestionId)) {
        if (operation) {
          operation(suggestionIds);
        } else {
          await loadSuggestions(suggestionIds);
        }
      }
    };

    socket.on('suggestionsUpdated', handleSuggestionsUpdated);

    return () => {
      socket.removeListener('suggestionsUpdated', handleSuggestionsUpdated);
    };
  }, [suggestionId, operation]);
}
