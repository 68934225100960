import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Modal, WithIntl } from 'components';

interface Props {
  paused: boolean;
}

const AccountPaused = ({ paused }: Props) => (
  <WithIntl>
    {(_, t) => (
      <Modal
        isOpen={paused}
        onRequestClose={() => {}}
        contentLabel={t('AccountPaused__Heading')}
      >
        <FormattedMessage id="AccountPaused__Heading" />
        <br />
        <FormattedMessage id="AccountPaused__Description" />
      </Modal>
    )}
  </WithIntl>
);

export default AccountPaused;
