import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { colors } from 'styles/theme';
import { mixins } from 'styles';
import DateFilterFields from 'types/DateFilterFields';
import { FormattedMessage } from 'react-intl';

interface Props {
  onClick: () => any;
  isOpen: boolean;
  filter: DateFilterFields;
  placeholder?: string;
}

// prettier-ignore
const StyledInput = styled.button.attrs({ type: 'button' })`
  position: relative;
  ${mixins.input};

  &:focus {
    box-shadow: none;
  }

  ${(props: any) => props.isEmpty && `color: ${colors.lightText}`};

  .arrow {
    position: absolute;
    top: calc(50% + 2px);
    right: 10px;
    transform: translateY(-50%);
    transform-origin: 50% 50%;
    border-color: ${colors.grey1} transparent transparent;
    border-style: solid;
    border-width: 5px 5px 2.5px;
    display: inline-block;
    height: 0;
    width: 0;

    ${(props: any) => props.isOpen && `
      top: calc(50%);
      transform: translateY(-50%) rotate(180deg);
    `};
  }
` as any;

const Input = ({ filter, placeholder, ...buttonProps }: Props) => {
  let value;
  if (filter.dateRange === 'custom') {
    value = (
      <>
        {moment(filter.startDate).format('MM/DD/YYYY')}
        {' - '}
        {moment(filter.endDate).format('MM/DD/YYYY')}
      </>
    );
  } else {
    value = <FormattedMessage id={`DateFilter--${filter.dateRange}`} />;
  }

  return (
    <StyledInput {...buttonProps} isEmpty={!filter.dateRange}>
      {value}

      <div className="arrow" />
    </StyledInput>
  );
};

export default Input;
