import { createSlice } from 'redux-starter-kit';

const { reducer, actions } = createSlice({
  name: 'isGlobalMediaUploadModalOpen',
  initialState: false,
  reducers: {
    openMediaUploadModal: () => true,
    closeMediaUploadModal: () => false,
    toggleMediaUploadModal: (state) => !state,
  },
});

export default reducer;

export const {
  openMediaUploadModal,
  closeMediaUploadModal,
  toggleMediaUploadModal,
} = actions;
