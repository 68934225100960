import React from 'react';
import styled from 'styled-components';
import { grid, colors } from 'styles/theme';
import Wrapper from './Wrapper';

interface Props {
  children: React.ReactNode;
}

const StyledNoResultsWrapper = styled.div`
  padding: ${grid(2)};
  color: ${colors.darkText};

  > button {
    display: block;
    cursor: pointer;
    line-height: ${grid(2.5)};
    color: ${colors.bodyText};
    font-size: 13px;

    &:hover {
      text-decoration: underline;
      color: ${colors.darkText};
    }
  }
`;

const NoResultsWrapper = ({ children }: Props) => (
  <Wrapper>
    <StyledNoResultsWrapper>{children}</StyledNoResultsWrapper>
  </Wrapper>
);

export default NoResultsWrapper;
