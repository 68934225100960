import React from 'react';
import DatePicker from 'react-datepicker';
import { FormattedMessage } from 'react-intl';
import moment from 'moment-timezone';
import styled from 'styled-components';
import FormattedDateTime from '../../FormattedDateTime';
import { colors } from 'styles/theme';

interface Props {
  startDate: string;
  endDate: string;
  onChangeStartDate: (startDate: string) => void;
  onChangeEndDate: (endDate: string) => void;
}

const Wrapper = styled.div`
  display: inline-block;
  font-weight: bold;

  > div {
    display: inline-block;
  }
`;

const Button = styled.button`
  color: ${colors.socialieBlue};
  transition: color 0.15s;

  &:focus {
    color: ${colors.blue};
  }
`;

const formatDateTime = (dateTime: Date | number) => (
  <FormattedDateTime
    value={dateTime}
    separator={
      <span>
        {' '}
        <FormattedMessage id="Global__At" />{' '}
      </span>
    }
  />
);

class CustomInput extends React.Component<any> {
  private buttonRef: any;

  componentDidMount() {
    if (this.buttonRef && !this.props.end) {
      this.buttonRef.focus();
      this.buttonRef.click();
    }
  }

  render() {
    let date = moment(this.props.value).startOf('day');
    if (this.props.end) {
      date = moment(this.props.value).endOf('day');
    }
    const value = formatDateTime(date.toDate());

    return (
      <Button
        ref={(el: any) => (this.buttonRef = el)}
        type="button"
        onClick={this.props.onClick}
      >
        {value}
      </Button>
    );
  }
}

export default class CustomPicker extends React.Component<Props> {
  private startPickerRef: any;
  private endPickerRef: any;

  changeDate = (value: moment.Moment | null, which: 'start' | 'end') => {
    const { onChangeStartDate, onChangeEndDate } = this.props;
    if (!value) return;
    const asString = value.format('YYYY-MM-DD');
    const onChange = which === 'start' ? onChangeStartDate : onChangeEndDate;
    onChange(asString);

    if (which !== 'end' && this.endPickerRef) {
      this.endPickerRef.input.buttonRef.focus();
      this.endPickerRef.input.buttonRef.click();
    }
  };

  render() {
    const { startDate, endDate } = this.props;
    return (
      <Wrapper>
        <DatePicker
          ref={(el: any) => (this.startPickerRef = el)}
          customInput={<CustomInput />}
          onChange={(value) => this.changeDate(value, 'start')}
          selected={moment(startDate)}
          maxDate={moment(endDate)}
        />
        {' - '}
        <DatePicker
          ref={(el: any) => (this.endPickerRef = el)}
          customInput={<CustomInput end />}
          onChange={(value) => this.changeDate(value, 'end')}
          selected={moment(endDate)}
          minDate={moment(startDate)}
        />
      </Wrapper>
    );
  }
}
