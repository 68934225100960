import QTooltip from 'components/QTooltip';
import React from 'react';

interface StatProps {
  heading: string;
  children: React.ReactNode;
  subHeading?: string;
  hasToolTip?: boolean;
  isTwitterEngagement?: boolean;
  tooltipContents?: string;
}

export default function PlatformStat(props: StatProps) {
  const {
    heading,
    children,
    subHeading,
    hasToolTip = false,
    isTwitterEngagement,
    tooltipContents,
  } = props;

  return (
    <div className="mb-2.5 flex w-1/6">
      <div className="flex flex-col">
        <div className="flex">
          <span className="leading-none text-grey5">{heading}</span>
          {(isTwitterEngagement || hasToolTip) && (
            <div>
              <QTooltip content={tooltipContents} />
            </div>
          )}
        </div>
        {subHeading && subHeading.trim().length && (
          <span className="text-grey5">{subHeading}</span>
        )}
        {children}
      </div>
    </div>
  );
}
