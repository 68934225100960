import React from 'react';
import styled from 'styled-components';
import { grid, colors } from 'styles/theme';
import { FormattedMessage } from 'react-intl';

interface Props {
  count?: number;
  title: string;
}

const Wrapper = styled.div`
  line-height: ${grid(3)};
  color: ${colors.lightText};
  font-size: 13px;
`;

const TotalCount = ({ count, title }: Props) => (
  <Wrapper>
    {count ? (
      <FormattedMessage id={title} values={{ count }} />
    ) : (
      <span>&nbsp;</span>
    )}
  </Wrapper>
);

export default TotalCount;
