import React from 'react';
import clsx from 'clsx';

interface Props {
  children: React.ReactNode;
}

export default function ButtonStack({ children }: Props) {
  return (
    <div className="overflow-hidden rounded border border-solid border-grey6">
      {children}
    </div>
  );
}

ButtonStack.Button = Button;

interface ButtonStackButtonProps {
  isActive: boolean;
  onClick: () => void;
  children: React.ReactNode;
  disabled?: boolean;
  'data-qa'?: string;
}

function Button(props: ButtonStackButtonProps) {
  const { isActive, onClick, disabled, children, 'data-qa': dataQa } = props;

  return (
    <button
      data-qa={dataQa}
      className={clsx(
        ' block h-4 w-full border-0 border-b border-solid border-grey6 text-center font-bold last:border-b-0 disabled:cursor-not-allowed',
        {
          'bg-grey3 text-white': isActive,
          'bg-white text-grey4': !isActive,
        }
      )}
      onClick={onClick}
      disabled={disabled}
    >
      <span className={disabled ? 'opacity-50' : ''}>{children}</span>
    </button>
  );
}
