import React from 'react';
import { connect } from 'react-redux';
import gql from 'graphql-tag';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { getUploadProgress } from 'redux/ducks/uploadProgress';
import FileUpload from 'types/FileUpload';
import Attachment from 'types/Attachment';
import { Button } from 'components';
import { grid } from 'styles/theme';
import { RootState } from 'redux/ducks';
import { useQuery } from '@apollo/react-hooks';

interface OwnProps {
  onSend: () => void;
  disabled?: boolean;
  fileUpload?: FileUpload;
  attachment?: Attachment;
}

interface ConnectedState {
  uploadProgress: number;
}

type Props = OwnProps & ConnectedState;

const Wrapper = styled.div`
  margin-right: ${grid(2)};
  padding: ${grid(2)} 0;

  button {
    width: ${grid(22)};
  }
`;

const ATTACHMENT_QUERY = gql`
  query ChatMessageAttachment($id: ID!) {
    attachment(id: $id, allStatuses: true) {
      id
      status
    }
  }
`;

const SendButton = ({
  onSend,
  disabled,
  fileUpload,
  uploadProgress,
}: Props) => {
  const { data } = useQuery(ATTACHMENT_QUERY, {
    skip: !fileUpload,
    variables: { id: (fileUpload && fileUpload.attachmentId) || 0 },
  });
  const attachment = data && data.attachment;
  const isUploading =
    (fileUpload && !attachment) ||
    (attachment && attachment.status !== 'processed');

  return (
    <Wrapper>
      <Button filledBg onClick={onSend} disabled={disabled || isUploading}>
        {isUploading ? (
          uploadProgress === 100 ? (
            <FormattedMessage id="ChatPanel__UploadProcessing" />
          ) : (
            <FormattedMessage
              id="ChatPanel__Uploading"
              values={{ progress: uploadProgress }}
            />
          )
        ) : (
          <FormattedMessage id="ChatPanel__Send" />
        )}
      </Button>
    </Wrapper>
  );
};

const mapStateToProps = (state: RootState, ownProps: OwnProps) => ({
  uploadProgress: ownProps.fileUpload
    ? getUploadProgress(state, ownProps.fileUpload.id)
    : 0,
});

export default connect(mapStateToProps)(SendButton);
