import React from 'react';
import debounce from 'lodash/debounce';
import isEqual from 'lodash/isEqual';
import compact from 'lodash/compact';
import { connect } from 'react-redux';
import {
  startBatchSuggestionSaveOperation,
  stopBatchSuggestionSaveOperation,
} from 'redux/ducks/ui';
import { RecipientsSearch } from 'components';
import { createIntendedRecipientInput, mapRecipientsToOptions } from 'helpers';
import Suggestion from 'types/Suggestion';
import IndexSuggestion from 'types/IndexSuggestion';
import RecipientOption from 'types/RecipientOption';
import WithIntl from '../WithIntl';
import updateIntendedRecipients from 'graphql/operations/updateIntendedRecipients';

interface OwnProps {
  suggestions: (Suggestion | IndexSuggestion)[];
  icon?: JSX.Element;
}

interface ConnectedActions {
  startSaving: (operation: string) => void;
  stopSaving: (operation: string) => void;
}

type Props = OwnProps & ConnectedActions;

interface State {
  selections: RecipientOption[];
}

class SuggestionRecipientsField extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { selections: this.readIntendedRecipients() };
    this.saveIntendedRecipients = debounce(this.saveIntendedRecipients, 500);
  }

  componentDidUpdate(prevProps: Props) {
    if (
      this.props.suggestions.length === 1 &&
      prevProps.suggestions.length === 1 &&
      !isEqual(
        prevProps.suggestions[0]['intendedRecipients'] || [],
        this.props.suggestions[0]['intendedRecipients'] || []
      )
    ) {
      this.setState({ selections: this.readIntendedRecipients() });
    }

    if (this.props.suggestions.length !== prevProps.suggestions.length) {
      this.setState({ selections: this.readIntendedRecipients() });
    }
  }

  onChange = (values: RecipientOption[]) => {
    this.props.startSaving('intendedRecipients');
    this.setState({ selections: values }, this.saveIntendedRecipients);
  };

  saveIntendedRecipients = async () => {
    const suggestionIds = this.props.suggestions.map((s) => s.id);
    const intendedRecipients = this.state.selections.map((selection) =>
      createIntendedRecipientInput(selection.data)
    );

    await updateIntendedRecipients({
      suggestionIds,
      intendedRecipients: compact(intendedRecipients),
    });

    this.props.stopSaving('intendedRecipients');
  };

  readIntendedRecipients = () => {
    if (this.props.suggestions.length !== 1) return [];
    const intendedRecipients = this.props.suggestions[0]['intendedRecipients'];
    return mapRecipientsToOptions(intendedRecipients || []);
  };

  render() {
    const { suggestions, icon } = this.props;
    const { selections } = this.state;

    return (
      <WithIntl>
        {(_, t) => {
          const placeholder =
            suggestions.length === 1
              ? t('RecipientsSearch__Placeholder')
              : t('SuggestionForm__PublishersMultiPlaceholder', {
                  count: suggestions.length,
                });

          return (
            <RecipientsSearch
              value={selections}
              onChange={this.onChange}
              icon={icon}
              placeholder={placeholder}
            />
          );
        }}
      </WithIntl>
    );
  }
}

const mapDispatchToProps = {
  startSaving: startBatchSuggestionSaveOperation,
  stopSaving: stopBatchSuggestionSaveOperation,
};

export default connect<OwnProps, ConnectedActions>(
  null,
  mapDispatchToProps
)(SuggestionRecipientsField);
