import React from 'react';
import { FormattedMessage } from 'react-intl';
import { routes } from 'helpers';
import RoundSubNav from 'components/RoundSubNav';

export default function PublishersSubNavBar() {
  return (
    <RoundSubNav>
      <RoundSubNav.Item to={routes.publishers.index} exact>
        <FormattedMessage id="PublishersSubNavBar__Publishers" />
      </RoundSubNav.Item>

      <RoundSubNav.Item to={routes.publishers.tags}>
        <FormattedMessage id="PublishersSubNavBar__Tags" />
      </RoundSubNav.Item>
    </RoundSubNav>
  );
}
