import React, { useReducer } from 'react';
import { useCurrentUser } from 'hooks';
import BrandLogoUpload from './components/BrandLogoUpload';
import BrandColorPicker from './components/BrandColorPicker';
import BrandPreview from './components/BrandPreview';
import PhotoEditModal from './components/PhotoEditModal';
import { initialState, reducer, actions } from './brandReducer';
import updateAccountConfiguration from 'graphql/operations/updateAccountConfiguration';
import { flashMessage } from 'helpers';
import { DropFilesEventHandler } from 'react-dropzone';
import { LogoUploader } from 'helpers/upload/LogoUploader';

interface MutationVars {
  primaryColor?: string;
  logo?: string;
  logoFilename?: string;
  logoContentType?: string;
  logoSize?: number;
}

interface BrandSelectionProps {
  render: (
    fields: React.ReactNode,
    onSubmit: () => void,
    isSubmitting: boolean,
    uploadProgress: number,
    hasExistingSettings?: boolean
  ) => React.ReactElement;
  onSuccess: () => void;
  onFailure: () => void;
}

export default function BrandSelection({
  render,
  onSuccess,
  onFailure,
}: BrandSelectionProps) {
  const currentUser = useCurrentUser();
  const accountConfiguration =
    currentUser?.currentSuggester?.accountConfiguration;

  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
    primaryColor: accountConfiguration?.primaryColor || '',
    image: {
      ...initialState.image,
      originalUrl: accountConfiguration?.logoUrl || '',
      previewUrl: accountConfiguration?.logoUrl || '',
      croppedPreviewUrl: accountConfiguration?.logoUrl || '',
    },
  });

  if (!accountConfiguration) return null;

  const handleSelectFile: DropFilesEventHandler = (file) => {
    if (!file[0] || !file[0].preview) return;
    dispatch(
      actions.updateImage({
        previewUrl: file[0].preview,
        croppedPreviewUrl: file[0].preview,
        mimeType: file[0].type,
        didUpload: true,
        didApplyEdits: false,
      })
    );
    dispatch(actions.openEditModal());
  };

  const updateBrandSettings = async (values: MutationVars) => {
    const data = await updateAccountConfiguration(
      accountConfiguration.id,
      values
    );

    dispatch(actions.setLoading(false));
    if (!data) return;

    if (data.errors) {
      dispatch(actions.setError(data.errors));
      flashMessage('Brand__SaveFailure', { style: 'error' });
      return;
    }

    dispatch(actions.setSuccess());
    onSuccess();
  };

  const startUpload = async () => {
    const data = await fetch(state.image.croppedPreviewUrl);
    const blob = await data.blob();
    const filename = state.image.previewUrl.substring(
      state.image.previewUrl.lastIndexOf('/') + 1
    );
    const file = blobToFile(blob, filename);

    const uploader = new LogoUploader(file, {
      onError: (reason) => {
        onFailure();
      },
      onComplete: async (logoId) => {
        await updateBrandSettings({
          logo: JSON.stringify({ id: logoId }),
          logoContentType: file.type,
          logoFilename: file.name,
          logoSize: file.size,
          primaryColor: state.primaryColor,
        });
        dispatch(actions.setLoading(false));
        dispatch(actions.setSuccess());
      },
      onProgress: (progress) => {
        dispatch(actions.setProgress(progress));
      },
    });
    uploader.upload();
  };
  const handleClick = () => {
    dispatch(actions.setLoading(true));
    if (state.image.didApplyEdits) {
      startUpload();
    } else {
      updateBrandSettings({ primaryColor: state.primaryColor });
    }
  };

  const fields = (
    <>
      <div className="flex justify-between">
        <div className="flex w-full min-w-50 flex-col">
          <BrandLogoUpload
            image={state.image}
            handleSelectFile={handleSelectFile}
          />
          <BrandColorPicker
            primaryColor={state.primaryColor}
            onColorChange={(color) => dispatch(actions.updateColor(color.hex))}
          />
        </div>
        <BrandPreview
          logo={state.image.croppedPreviewUrl}
          mainBrandColor={state.primaryColor}
        />
      </div>
      {state.isEditModalOpen && (
        <PhotoEditModal
          image={state.image}
          onUpdateImage={(updates) => dispatch(actions.updateImage(updates))}
          onRequestClose={() => dispatch(actions.closeEditModal())}
        />
      )}
    </>
  );
  return render(
    fields,
    handleClick,
    state.isLoading,
    state.uploadProgress,
    (!!accountConfiguration.logoUrl || state.image.didApplyEdits) &&
      (!!state.primaryColor || !!accountConfiguration.primaryColor)
  );
}

function blobToFile(theBlob: Blob, fileName: string) {
  const b: any = theBlob;
  //A Blob() is almost a File() - it's just missing the two properties below which we will add
  b.lastModifiedDate = new Date();
  b.name = fileName;

  //Cast to a File() type
  return theBlob as File;
}
