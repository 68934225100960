import React from 'react';
import { useCurrentUser, useIntl } from 'hooks';
import { IconAthlete } from 'icons';
import Pills from './Pills';

interface Props {
  publisher: { athlete?: boolean };
  render?: (contents: React.ReactNode) => React.ReactNode;
}

export default function PublisherAthletePill(props: Props) {
  const { publisher, render: renderFn } = props;
  const currentSuggester = useCurrentUser()?.currentSuggester;
  const { t } = useIntl();

  if (!currentSuggester?.accountConfiguration?.maxAthletes) return null;
  if (!publisher.athlete) return null;

  const contents = (
    <Pills.Pill>
      <IconAthlete className="mr-0.5 h-2 w-2" />
      <span className="whitespace-nowrap">{t('Athlete__Athlete')}</span>
    </Pills.Pill>
  );

  return renderFn ? <>{renderFn(contents)}</> : contents;
}
