import React from 'react';
import styled from 'styled-components';
import { grid, colors } from 'styles/theme';
import { Tooltip } from 'components';
import { contactMethodIcons } from 'icons';

interface Props {
  value: string;
  protocol: string;
  name?: string;
}

const ContactTooltip = styled.div`
  display: flex;
  align-items: center;
  cursor: default;
  color: ${colors.lightText};

  &:hover {
    color: ${colors.darkText};

    ${(props: any) => props.theme.color === 'dark' && `color: ${colors.white}`};
  }

  svg {
    display: block;
    position: relative;
    top: 1px;
    width: ${grid(1.5)};
    height: ${grid(1.5)};
  }
`;

const ContactMethodTooltip = ({ value, name, protocol }: Props) => {
  const icon = contactMethodIcons[protocol];
  const details = [];
  if (name) details.push(name);
  if (protocol === 'whats_app') details.push('WhatsApp');

  return (
    <Tooltip
      content={
        <>
          {value} {!!details.length && <span>({details.join(' - ')})</span>}
        </>
      }
      theme="socialieDark"
    >
      <ContactTooltip>
        {icon && React.createElement(icon)}
        &nbsp;
      </ContactTooltip>
    </Tooltip>
  );
};

export default ContactMethodTooltip;
