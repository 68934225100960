import React from 'react';
import { routes } from 'helpers';
import RoundSubNav from 'components/RoundSubNav';
import { useIntl } from 'hooks';

export default function PublishersSubNavBar() {
  const { t } = useIntl();

  return (
    <div className="flex items-center">
      <div className="mr-1.5 text-12 text-light">
        {t('MediaLibrarySubNavBar__ViewBy')}
      </div>

      <RoundSubNav>
        <RoundSubNav.Item
          to={routes.mediaLibrary.index}
          exact
          data-qa="media-library-subnav-bar-view-by-content"
        >
          {t('MediaLibrarySubNavBar__MediaLibrary')}
        </RoundSubNav.Item>

        <RoundSubNav.Item
          to={routes.mediaLibrary.publisherMedia.index}
          data-qa="media-library-subnav-bar-view-by-publisher"
        >
          {t('MediaLibrarySubNavBar__PublisherMedia')}
        </RoundSubNav.Item>

        <RoundSubNav.Item
          to={routes.mediaLibrary.albumMedia.index}
          data-qa="media-library-subnav-bar-view-by-album"
        >
          {t('MediaLibrarySubNavBar__AlbumsMedia')}
        </RoundSubNav.Item>
      </RoundSubNav>
    </div>
  );
}
