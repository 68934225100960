import React from 'react';
import { Form } from 'components';
import { useIntl } from 'hooks';
import MediaLibraryFilterData from 'types/MediaLibraryFilterData';

interface Props {
  uploaders: MediaLibraryFilterData['currentSuggester']['uploaders'];
  onChange: (val: number[]) => void;
  value: number[];
}

export default function MediaUploadersFilter(props: Props) {
  const { t } = useIntl();

  const { onChange, value, uploaders } = props;

  return (
    <Form.Field className="-mt-2">
      <Form.Label bold>{t('MediaLibraryUploaderFilter__Label')}</Form.Label>

      <Form.Select
        isMulti
        onChange={onChange}
        value={value}
        options={uploaders.map((u) => ({
          label: u.name,
          value: u.id,
        }))}
        isClearable={false}
      />
    </Form.Field>
  );
}
