import React from 'react';
import Select from 'react-select';
import styled, { withTheme } from 'styled-components';
import { grid, colors } from 'styles/theme';
import { allCountryCodes, selectStyles } from '../utils';
import buildCountryCodeOptions, {
  Option,
} from '../utils/buildCountryCodeOptions';

interface Props {
  value: string;
  countryCodes: string[];
  countryCodeWhitelist: string[];
  allowWorldwideSms?: boolean;
  onChange: (value: string) => void;
  onFocus?: (e: any) => any;
  onBlur?: (e: any) => any;
  theme?: any;
}

interface State {
  width: number;
  inputValue: string;
}

const Wrapper = styled.div`
  position: relative;
  height: ${grid(4.5)};
  overflow: visible;

  svg {
    position: absolute;
    top: calc(50% - 3px);
    right: ${grid(0.75)};
    z-index: 0;
    width: ${grid(1)};
    height: ${grid(1)};
    color: ${colors.darkText};
  }
`;

const Sizer = styled.span`
  position: absolute;
  left: -9999px;
  border: 1px solid ${colors.border};
  padding: ${grid(1)};
  padding-right: ${grid(4)};
  font-size: 14px;
`;

const LockedOption = styled.div`
  display: flex;
  border: 1px solid ${colors.border};
  border-radius: 4px 0 0 4px;
  background-color: ${colors.greyBg};
  padding: 0 ${grid(1.5)};
  height: ${grid(4.5)};
  line-height: ${grid(4.5)};
  font-size: 14px;

  ${(props: any) => props.theme.isInvalid && `border-color: ${colors.error}`};
`;

const SingleValue = (props: any) => (
  <div style={{ color: colors.darkText }}>{props.data.value}</div>
);

class CountryCode extends React.Component<Props, State> {
  state = { inputValue: '', width: 0 };
  private sizerRef: React.RefObject<HTMLSpanElement> = React.createRef();

  componentDidMount() {
    this.setWidth();
  }

  componentDidUpdate({ value }: Props, { inputValue }: State) {
    if (value !== this.props.value || inputValue !== this.state.inputValue) {
      this.setWidth();
    }
  }

  // We toss the current value into an invisible span and then
  // get its width.  We use that width to set the width of the <select> that
  // the user can see.
  setWidth = () => {
    if (!this.sizerRef.current) return;
    const width = this.sizerRef.current.offsetWidth;
    this.setState({ width });
  };

  setInputValue = (inputValue: string) => this.setState({ inputValue });

  render() {
    const {
      allowWorldwideSms,
      countryCodeWhitelist,
      theme,
      onChange,
      onFocus,
      onBlur,
    } = this.props;
    const { width, inputValue } = this.state;

    const defaultCountryCodes = this.props.countryCodes.map((c) => {
      const match = allCountryCodes.find((ac) => ac.value === c);

      return {
        value: c,
        label: match ? match.label : c,
      };
    });

    const options = buildCountryCodeOptions(
      defaultCountryCodes,
      countryCodeWhitelist,
      allowWorldwideSms
    );

    if (!options) return null;

    const value =
      this.props.value ||
      (defaultCountryCodes[0] && defaultCountryCodes[0].value) ||
      '';

    // Get a flat list of all the available options
    let allOptions = options as Option[];
    if (options[0]['options']) {
      allOptions = [...options[0]['options'], ...options[1]['options']];
    }

    const selectedOption =
      allOptions.find((c) => value === c.value) || allOptions[0];

    return (
      <Wrapper style={{ width: width ? `${width}px` : 'auto' }}>
        {allOptions.length > 1 ? (
          <>
            <Select
              value={selectedOption}
              styles={selectStyles(theme)}
              components={{ SingleValue }}
              options={options}
              isMulti={false}
              onChange={({ value }: any) => onChange(value)}
              placeholder=""
              onInputChange={this.setInputValue}
              onFocus={onFocus}
              onBlur={onBlur}
            />
            <Sizer ref={this.sizerRef}>
              {inputValue || selectedOption.value}
            </Sizer>
          </>
        ) : (
          <LockedOption>{(options[0] as any).value}</LockedOption>
        )}
      </Wrapper>
    );
  }
}

export default withTheme(CountryCode);
