import React, { useRef } from 'react';
import Recipient from 'types/Recipient';
import { Pills } from 'components';
import Tooltip from 'components/Tooltip';
import PublisherPillBreakdown from './PublisherPillBreakdown';
import { IconAlert, IconAlertOff } from 'icons';
import { useCurrentUser } from 'hooks';

interface Props {
  recipients: Recipient[];
  selectProps: any;
}

export default function PublisherPill({ recipients, selectProps }: Props) {
  const innerRef = useRef<HTMLElement>(null);
  const { color } = selectProps.pillProps;
  const nameClassName = color === 'darkerGrey' ? '' : 'text-dark';
  const numberClassName =
    color === 'darkerGrey' ? 'bg-grey2 text-white' : 'bg-grey7';

  const publisher =
    recipients[0].publisherUser?.publisher ||
    recipients[0].contactMethod?.publisher ||
    recipients[0].publisher;

  const currentUser = useCurrentUser();
  const suggesterHasChildren = !!currentUser.currentSuggester?.children?.length;

  if (!publisher) {
    throw new Error('[PublisherPill] Every recipient must have a publisher.');
  }

  const isUncontactable = recipients.length === 1 && !!recipients[0].publisher;

  return (
    <div>
      <Tooltip
        trigger="click"
        triggerTarget={innerRef.current}
        role="dialog"
        disabled={isUncontactable}
        interactive
        offset={[0, 8]}
        animation={true}
        animateFill={true}
        placement="top-start"
        maxWidth="none"
        content={
          <PublisherPillBreakdown
            recipients={recipients}
            onRemove={selectProps.onRemoveRecipient}
          />
        }
      >
        <div className="group">
          <Pills.Pill
            {...selectProps.pillProps}
            image={publisher.image}
            imageFallback={publisher.name}
            onRemove={() => selectProps.onRemoveRecipient(recipients)}
            clickable
          >
            <Tooltip
              placement="top"
              disabled={!suggesterHasChildren}
              content={
                <div className="flex items-center">
                  <div className="mr-1 h-3 w-3 rounded-full border-0 bg-greyBg">
                    <img
                      src={publisher.suggester.accountConfiguration?.logoUrl}
                      alt=""
                      className="h-full w-full rounded-full border-0"
                    />
                  </div>

                  <div className="font-semibold text-dark">
                    {publisher.suggester.accountName}
                  </div>
                </div>
              }
            >
              <span className={nameClassName}>{publisher.name}</span>
            </Tooltip>

            <div className="bump-down-1.5 ml-0.5 flex items-center p-0.5">
              <div
                className={`flex h-2 items-center justify-center rounded-lg px-1 pr-0.75 text-12 leading-16 ${numberClassName}`}
              >
                <span className="bump-up-0.5 flex items-center">
                  {isUncontactable ? (
                    <IconAlertOff className="bump-down-1 h-1.75 w-1.75 text-light" />
                  ) : (
                    <>
                      {recipients.length}{' '}
                      <IconAlert className="bump-down-1 h-1.75 w-1.75 text-light" />
                    </>
                  )}
                </span>
              </div>
            </div>
          </Pills.Pill>
        </div>
      </Tooltip>
    </div>
  );
}
