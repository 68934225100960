import { withRouter, RouteComponentProps } from 'react-router-dom';

interface RenderProps extends RouteComponentProps<any> {}

interface OwnProps {
  render?: (renderProps: RenderProps) => any;
  children?: (renderProps: RenderProps) => any;
}

type Props = OwnProps & RouteComponentProps<any>;

const WithRouter = ({ render, children, ...renderProps }: Props) => {
  const renderFunc = render || children;
  if (!renderFunc) return null;
  return renderFunc(renderProps);
};

export default withRouter<OwnProps & RouteComponentProps<any>>(WithRouter);
