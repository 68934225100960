import * as REMOVE_SUGGESTION_ALBUM from 'graphql/mutations/removeSuggestionAlbum.graphql';
import apolloClient from '../apolloClient';

type IDs = number | string | (number | string)[];

const removeSuggestionAlbum = async (suggestionIds: IDs, albumIds: IDs) => {
  const { errors, data } = await apolloClient.mutate({
    mutation: REMOVE_SUGGESTION_ALBUM,
    variables: { suggestionIds, albumIds },
  });

  if (errors) return { errors };
  if (data && data.errors) return { errors: data.errors };
  if (data) return data;
  return null;
};

export default removeSuggestionAlbum;
