import React from 'react';
import { IconTwitter } from 'icons';
import { colors } from 'styles/theme';
import { FormattedMessage } from 'react-intl';
import OauthIdentity from 'types/OauthIdentity';

interface Props {
  account: OauthIdentity;
  onRemove: () => void;
  onReconnect: () => void;
  isInvalid: boolean;
}

export default function LinkedTwitterAccount(props: Props) {
  const { account, onRemove, onReconnect, isInvalid } = props;

  return isInvalid ? (
    <div className="inline-block">
      <div className="flex h-4 items-center rounded border border-solid border-red bg-errorBg px-1.5">
        <IconTwitter
          className="bump-down-1 mr-1 h-2 w-2"
          style={{ color: colors.twitter }}
        />{' '}
        <span className="mr-1">
          <FormattedMessage id="LinkedAccounts__LinkedAs" />{' '}
          <span className="text-dark">@{account.username}</span>
        </span>
        <button
          className="text-socialiePink hover:underline"
          onClick={onReconnect}
        >
          <FormattedMessage id="LinkedAccounts__Reconnect" />
        </button>
      </div>
    </div>
  ) : (
    <div className="inline-block">
      <div className="border-default flex h-4 items-center rounded px-1.5">
        <IconTwitter
          className="bump-down-1 mr-1 h-2 w-2"
          style={{ color: colors.twitter }}
        />{' '}
        <span className="mr-1">
          <FormattedMessage id="LinkedAccounts__LinkedAs" />{' '}
          <span className="text-dark">@{account.username}</span>
        </span>
        <button
          className="text-socialiePink hover:underline"
          onClick={onRemove}
        >
          <FormattedMessage id="LinkedAccounts__Unlink" />
        </button>
      </div>
    </div>
  );
}
