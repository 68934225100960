import React from 'react';
import styled from 'styled-components';
import { FormattedDateTime } from '..';
import PublisherRequest from 'types/PublisherRequest';
import { grid } from 'styles/theme';

interface Props {
  request: PublisherRequest;
}

const Wrapper = styled.div`
  line-height: ${grid(2)};
  font-size: 12px;

  a {
    color: inherit;
  }
` as any;

const RequestTimestamp = ({ request }: Props) => (
  <Wrapper>
    <a href={request.url} target="_blank" rel="noopener noreferrer">
      <FormattedDateTime value={request.updatedAt} />
    </a>
  </Wrapper>
);

export default RequestTimestamp;
