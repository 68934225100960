import React from 'react';
import styled from 'styled-components';
import { grid, colors } from 'styles/theme';
import { IconCheckmark } from 'icons';

interface Props {
  onClick: (e: any) => void;
}

// prettier-ignore
const Wrapper = styled.button.attrs({ type: 'button' })`
  display: none;
  position: absolute;
  top: ${grid(0)};
  left: ${grid(0)};
  z-index: 6;
  padding: ${grid(1)};

  ${({ theme }: any) => theme.isSelectMode && `display: block;`}

  ${({ theme }: any) => theme.isSelected && `display: block;`}

  *:hover > & {
    display: block;
  }

`;

// prettier-ignore
const StyledCheckbox = styled.span`
  display: block;
  transition: opacity 0.2s;
  opacity: 0.4;
  border: 2px solid ${colors.white};
  border-radius: 4px;
  padding: ${grid(0.125)};
  width: ${grid(3)};
  height: ${grid(3)};

  svg {
    display: block;
    transform: translate(0);
    transition: opacity 0.2s;
    opacity: 0;
    width: 100%;
    height: 100%;
    color: ${colors.black};
  }

  &:hover {
    opacity: 1;
  }

  .AttachmentThumbnail:hover & {
    display: block;
    background: ${colors.white};

    svg {
      opacity: 1;
    }
  }

  ${({ theme }: any) => theme.isSelected &&`
    opacity: 1;
    border: 2px solid ${colors.socialiePink};
    background-color: ${colors.socialiePink};

    svg {
      opacity: 1;
      color: ${colors.white};
    }

    .AttachmentThumbnail:hover & {
      background-color: ${colors.socialiePink};
    }
  `};

  ${({ theme }: any) => theme.isSelectMode && !theme.isSelected && `
    .AttachmentThumbnail:hover & {
      opacity: 1;
    }
  `}
`;

export default function Checkbox({ onClick }: Props) {
  return (
    <Wrapper onClick={onClick} data-qa="checkbox-checkbox-button">
      <StyledCheckbox>
        <IconCheckmark />
      </StyledCheckbox>
    </Wrapper>
  );
}
