import React from 'react';
import styled from 'styled-components';
import FlipMove from 'react-flip-move';
import Album from 'types/Album';
import { grid } from 'styles/theme';
import SuggestionAlbum from '../SuggestionAlbum';

interface Props {
  suggestion: { id: number | string; albums: Album[] };
  large?: boolean;
}

const List = styled(FlipMove)`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: ${grid(-1)};

  > * {
    margin-right: ${grid(1)};
    margin-bottom: ${grid(1)};

    &:last-child {
      margin-right: 0;
    }
  }
`;

const SuggestionAlbumList = ({ suggestion, large }: Props) => {
  if (!suggestion.albums.length) return null;

  return (
    <List>
      {suggestion.albums.map((album) => (
        <div key={album.id}>
          <SuggestionAlbum
            large={large}
            album={album}
            suggestionId={suggestion.id}
          />
        </div>
      ))}
    </List>
  );
};

export default SuggestionAlbumList;
