import React from 'react';
import { IconWarningCircleOutline } from 'icons';

type AllowedHelperStatus = 'fail' | 'warning' | 'info';

interface HelperStatusProps {
  children: React.ReactNode;
  helperStatus?: AllowedHelperStatus;
}

function HelperStatus(props: HelperStatusProps) {
  const { children, helperStatus } = props;

  const statusClasses = {
    fail: 'bg-secondaryDangerLight text-secondaryDanger',
    warning: 'bg-secondaryWarningLight text-orange',
    info: 'bg-secondaryInfoLight text-blue',
    base: 'bg-grey8 text-grey4',
  };

  return (
    <div
      className={`mb-2.5 flex items-center rounded-lg px-2 py-1.5 ${
        helperStatus ? statusClasses[helperStatus] : statusClasses['base']
      }`}
    >
      {!!helperStatus && <IconWarningCircleOutline className="shrink-0" />}

      <p className={`text-left leading-5 ${helperStatus && 'pl-2.5'}`}>
        {children}
      </p>
    </div>
  );
}

export default HelperStatus;
