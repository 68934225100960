import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';
import { mapNodes } from 'helpers';
import RelayConnection from 'types/RelayConnection';

export const PUBLISHER_TAGS = gql`
  query PublisherTags {
    publisherTags {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;

export default function usePublisherTags() {
  const { data, loading, error } = useQuery<{
    publisherTags: RelayConnection<{ id: number; name: string }>;
  }>(PUBLISHER_TAGS, { fetchPolicy: 'network-only' });

  const publisherTags: { id: number; name: string }[] = data?.publisherTags
    ? mapNodes(data.publisherTags.edges)
    : [];

  return { publisherTags, loading, error };
}
