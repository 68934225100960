import React from 'react';
import includes from 'lodash/includes';
import styled, { ThemeProvider } from 'styled-components';
import { byPlatformId } from 'helpers';
import PublisherRequest from 'types/PublisherRequest';
import IndexSuggestion from 'types/IndexSuggestion';
import * as config from 'config';
import { grid, colors } from 'styles/theme';
import { platformIcons } from 'icons';
import FormattedDateTime from '../FormattedDateTime';
import Tooltip from '../Tooltip';
import { FormattedMessage } from 'react-intl';
import { TippyProps } from '@tippyjs/react';

interface Props {
  request: PublisherRequest;
  suggestion?: IndexSuggestion;
  compact?: boolean;
}

// prettier-ignore
const Wrapper = styled.div`
  display: flex;
  align-items: center;

  ${(props: any) => props.theme.compact && 'display: block'};
`;

// prettier-ignore
const IconWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s;
  margin-right: ${grid(1)};
  border-radius: 4px;
  background-color: ${colors.tealBg};
  width: ${grid(3)};
  height: ${grid(3)};
  text-align: center;
  color: ${colors.teal};

  ${(props: any) => props.error && `
    background-color: ${colors.errorBg};
    color: ${colors.error};
  `};

  &:last-child {
    margin-right: 0;
  }

  &:hover {
    background-color: ${colors.teal};
    color: ${colors.white};

    ${(props: any) => props.error && `background-color: ${colors.error}`};
  }

  ${(props: any) => props.unposted && 'display: none'};

  ${(props: any) => props.theme.compact && `
      margin-right: 0;
      margin-bottom: ${grid(0.25)};
      width: ${grid(2)};
      height: ${grid(2)};
      background-color: transparent;

      &:hover {
        background-color: transparent;
        color: ${colors.teal};
      }
  `};

  ${(props: any) => props.theme.compact && props.unposted && `
    display: block;
    color: ${colors.lightIcon};

    &:hover {
      color: ${colors.lightText};
    }
  `};

  > a {
    width: 100%;
    height: 100%;
    color: inherit;
  }

  span {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px;
    width: 100%;
    height: 100%;

    ${(props: any) => props.theme.compact && 'padding: 0'};
  }

  svg {
    display: block;
    position: relative;
    top: 1px;
    width: 100%;
    height: 100%;
  }

  > [data-tooltipped] {
    width: 100%;
    height: 100%;
  }
` as any;

interface IconProps {
  tooltip: TippyProps['content'];
  platform: string;
}

const Icon = ({ tooltip, platform }: IconProps) => {
  const icon = React.createElement(platformIcons[platform]);
  return (
    <Tooltip content={tooltip} theme="socialieDark">
      <span>{icon}</span>
    </Tooltip>
  );
};

const RequestPlatforms = (props: Props) => {
  const { compact, request } = props;
  const { platforms } = props.suggestion || request.suggestion;
  const { posts } = request;
  if (!platforms || !posts) return null;
  if (!compact && !posts.length) return null;

  // Only list platforms that were suggested, or that a post was manually
  // added for
  const platformIds = platforms.map((p) => p.id);
  const postPlatformIds = posts.map((p) => p.platform);
  const platformIdsInOrder = config.platforms.filter((p) =>
    includes([...platformIds, ...postPlatformIds], p)
  );
  const postsByPlatform = byPlatformId(posts, 'platform');

  return (
    <ThemeProvider theme={{ compact }}>
      <Wrapper>
        {platformIdsInOrder.map((p) => {
          const post = postsByPlatform[p];
          if (!post) {
            return (
              <IconWrap key={p} unposted>
                <Icon tooltip="Not Posted" platform={p} />
              </IconWrap>
            );
          }

          if (post.status === 'published') {
            return (
              <IconWrap key={p}>
                <a
                  href={post.url}
                  onClick={(e) => e.stopPropagation()}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Icon tooltip="View Post" platform={p} />
                </a>
              </IconWrap>
            );
          }

          if (post.status === 'failed') {
            return (
              <IconWrap key={p} error>
                <Icon tooltip={post.statusMessage} platform={p} />
              </IconWrap>
            );
          }

          if (post.status === 'scheduled') {
            return (
              <IconWrap key={p}>
                <Icon
                  tooltip={
                    <FormattedMessage
                      id="RequestPlatforms__ScheduledDate"
                      values={{
                        date: (
                          <FormattedDateTime
                            value={post.postAt}
                            separator=", "
                          />
                        ),
                      }}
                    />
                  }
                  platform={p}
                />
              </IconWrap>
            );
          }

          return null;
        })}
      </Wrapper>
    </ThemeProvider>
  );
};

export default RequestPlatforms;
