import React from 'react';
import { Editor, EditorState, ContentState, DraftHandleValue } from 'draft-js';
import styled from 'styled-components';
import { colors, grid } from 'styles/theme';
import { IconCaption, IconClose } from 'icons';
import { mixins } from 'styles';
import WithIntl from '../../../../WithIntl';

interface Props {
  idx: number;
  value: string;
  onChange: (value: string) => void;
  onRemove: () => void;
  addTalkingPoint: () => void;
}

interface State {
  value: string;
  editorState: EditorState;
  focused: boolean;
}

const Wrapper = styled.div`
  ${mixins.input};
  display: flex;
  margin-bottom: ${grid(1)};
  padding: ${grid(0.5)};
  height: auto;
  min-height: ${grid(4.5)};

  &:last-child {
    margin-bottom: 0;
  }
`;

const Icon = styled.div`
  margin-right: ${grid(0.5)};
  padding: ${grid(0.5)};
  width: ${grid(3)};
  height: ${grid(3)};
  color: ${colors.lightIcon};

  svg {
    display: block;
    width: 100%;
    height: 100%;
  }
`;

const EditorWrapper = styled.div`
  position: relative;
  top: -1px;
  flex: 1;
  margin-top: ${grid(0.25)};
  padding-right: ${grid(2)};
  line-height: ${grid(2.5)};
  color: ${colors.darkText};

  .public-DraftEditorPlaceholder-root {
    width: 100%;
    color: ${colors.lightText};
  }
`;

const RemoveButton = styled.div`
  position: absolute;
  top: ${grid(0.25)};
  right: ${grid(0.5)};
  cursor: pointer;
  width: ${grid(1.5)};
  height: ${grid(1.5)};
  color: ${colors.lightIcon};

  svg {
    width: 100%;
    height: 100%;
  }

  &:hover {
    color: ${colors.grey1};
  }
`;

export default class TalkingPoint extends React.Component<Props, State> {
  private editorRef: React.RefObject<any> = React.createRef();

  constructor(props: Props) {
    super(props);
    const value = this.props.value || '';
    this.state = {
      value,
      focused: false,
      editorState: EditorState.createWithContent(
        ContentState.createFromText(value)
      ),
    };
  }

  onChange = (editorState: EditorState) => {
    const content = editorState.getCurrentContent();
    const oldContent = this.state.editorState.getCurrentContent();
    const value = content.getPlainText();

    this.setState({ editorState, value }, () => {
      if (content !== oldContent) this.props.onChange(value);
    });
  };

  handleReturn = (e: any, editorState: EditorState) => {
    this.props.addTalkingPoint();
    return 'handled' as DraftHandleValue;
  };

  render() {
    const { idx, onRemove } = this.props;
    const { editorState } = this.state;
    const placeholder =
      idx < 4 ? `SuggestionForm__TalkingPointsPlaceholder--${idx}` : undefined;

    return (
      <WithIntl>
        {(_, t) => (
          <Wrapper>
            <Icon>
              <IconCaption />
            </Icon>
            <EditorWrapper>
              <Editor
                ref={this.editorRef}
                tabIndex={0}
                editorState={editorState}
                onChange={this.onChange}
                handleReturn={this.handleReturn}
                onFocus={() => this.setState({ focused: true })}
                onBlur={() => this.setState({ focused: false })}
                placeholder={placeholder && t(placeholder)}
              />
              <RemoveButton onClick={onRemove}>
                <IconClose />
              </RemoveButton>
            </EditorWrapper>
          </Wrapper>
        )}
      </WithIntl>
    );
  }
}
