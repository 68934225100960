import React from 'react';
import styled, { css } from 'styled-components';
import { NavLink } from 'react-router-dom';
import { colors, grid } from 'styles/theme';

interface Props {
  children: React.ReactNode;
  connected?: boolean;
  padded?: boolean;
  right?: boolean;
  tall?: boolean;
}

interface State {
  underlineWidth: string;
  underlineOffset: string;
}

// prettier-ignore
const Wrapper = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  margin-bottom: ${grid(3)};
  border-bottom: 1px solid ${colors.border};
  height: ${grid(5)};

  ${(props: any) => props.connected && 'margin-bottom: 0'};

  ${(props: any) => props.padded && `
    padding-right: ${grid(2.5)};
    padding-left: ${grid(2.5)};
  `};

  ${(props: any) => props.tall && `height: ${grid(8)}`};

  ${(props: any) => props.right && 'justify-content: flex-end'};

  a {
    transition: color 0.15s;
    margin-right: ${grid(6)};
    color: ${colors.bodyText};
    font-size: 14px;

    ${(props: any) => props.right && `
      margin-right: 0;
      margin-left: ${grid(6)};
    `};
  }

  a.active {
    color: ${colors.darkText};
  }
`;

const Underline = styled.div`
  position: absolute;
  bottom: -1px;
  left: 0;
  transition: all 0.15s;
  background-color: ${colors.socialiePink};
  height: 2px;
  ${(props: any) => css`
    transform: translateX(${props.underlineOffset});
    width: ${props.underlineWidth};
  `};
` as any;

export default class SubNavBar extends React.Component<Props, State> {
  public static Link = NavLink;
  private wrapperRef?: HTMLDivElement;
  state = { underlineOffset: '0', underlineWidth: '0' };

  componentDidMount() {
    this.updateUnderline();
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    // Prevent infinite loop
    if (prevState.underlineOffset === this.state.underlineOffset) {
      this.updateUnderline();
    }
  }

  updateUnderline = () => {
    const activeLink =
      this.wrapperRef &&
      (this.wrapperRef.querySelector('a.active') as HTMLElement);
    if (!activeLink) {
      this.setState({ underlineOffset: '0', underlineWidth: '0' });
    } else {
      const underlineOffset = activeLink.offsetLeft + 'px';
      const underlineWidth = activeLink.offsetWidth + 'px';
      if (underlineOffset !== this.state.underlineOffset) {
        this.setState({ underlineOffset, underlineWidth });
      }
    }
  };

  render() {
    const { children, ...styleProps } = this.props;
    return (
      <Wrapper ref={(el: any) => (this.wrapperRef = el)} {...styleProps}>
        {children}
        <Underline {...this.state} />
      </Wrapper>
    );
  }
}
