import React from 'react';
import isEqual from 'lodash/isEqual';
import compact from 'lodash/compact';
import uuid from 'uuid4';
import { FormattedMessage } from 'react-intl';
import { Form, MiniButton } from 'components';
import Suggestion from 'types/Suggestion';
import { TalkingPoint } from './components';

interface Props {
  suggestions: Suggestion[];
  onChange: (value: string[]) => void;
}

interface State {
  // we set uuids on the talking points so that react can properly manage the
  // the list with unique keys
  talkingPoints: { uuid: string; value: string }[];
  focused: boolean;
}

export default class TalkingPoints extends React.Component<Props, State> {
  private talkingPointRefs: any = [];
  constructor(props: Props) {
    super(props);
    this.state = {
      focused: false,
      ...this.getInitialState(),
    };
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    const prevIds = prevProps.suggestions.map((s) => s.id);
    const currentIds = this.props.suggestions.map((s) => s.id);
    if (!isEqual(prevIds, currentIds)) {
      this.setState(this.getInitialState());
    }

    if (this.state.talkingPoints.length > prevState.talkingPoints.length) {
      const newest = this.talkingPointRefs[this.talkingPointRefs.length - 1];
      if (newest && newest.editorRef && newest.editorRef.current) {
        newest.editorRef.current.focus();
      }
    }
  }

  getInitialState = () => {
    const { suggestions } = this.props;
    let values = suggestions.length === 1 ? suggestions[0].talkingPoints : [];
    if (!values || !values.length) values = [''];
    const talkingPoints = values.map((value) => ({ value, uuid: uuid() }));

    return { talkingPoints };
  };

  onChange = (value: string, idx: number) => {
    const nextTalkingPoints = [...this.state.talkingPoints];
    nextTalkingPoints[idx].value = value;
    this.setState({ talkingPoints: nextTalkingPoints }, () =>
      this.saveChanges()
    );
  };

  saveChanges = () => {
    const values = this.state.talkingPoints.map((t) => t.value);
    this.props.onChange(compact(values));
  };

  addTalkingPoint = () => {
    this.setState({
      talkingPoints: [...this.state.talkingPoints, { uuid: uuid(), value: '' }],
    });
  };

  removeTalkingPoint = (idx: number) => {
    const nextTalkingPoints = [...this.state.talkingPoints];
    nextTalkingPoints.splice(idx, 1);
    this.setState({ talkingPoints: nextTalkingPoints }, () =>
      this.saveChanges()
    );
  };

  addRef = (component: TalkingPoint) => {
    if (!component) return;
    this.talkingPointRefs.push(component);
  };

  render() {
    const { talkingPoints } = this.state;
    return (
      <Form.Field>
        <Form.Label bold>
          <FormattedMessage id="SuggestionForm__TalkingPoints" />
        </Form.Label>

        <div>
          {talkingPoints.map((talkingPoint, idx) => {
            return (
              <TalkingPoint
                key={talkingPoint.uuid}
                idx={idx}
                value={talkingPoint.value}
                onChange={(value: string) => this.onChange(value, idx)}
                onRemove={() => this.removeTalkingPoint(idx)}
                addTalkingPoint={this.addTalkingPoint}
                ref={this.addRef}
              />
            );
          })}
        </div>

        <MiniButton onClick={this.addTalkingPoint}>
          + <FormattedMessage id="SuggestionForm__AddTalkingPoint" />
        </MiniButton>
      </Form.Field>
    );
  }
}
