import React from 'react';
import compact from 'lodash/compact';
import styled from 'styled-components';
import { mapRecipientsToOptions as defaultMapRecipientsToOptions } from 'helpers';

import RecipientOption from 'types/RecipientOption';
import Recipient from 'types/Recipient';
import { grid, colors } from 'styles/theme';
import { FormattedMessage } from 'react-intl';
import { RecipientsSearch } from 'components';
import { publishersWithoutSms } from './utils';
import { MissingSms } from './components';
import { useCurrentUser } from 'hooks';

interface Props {
  value: RecipientOption[];
  onChange: (value: RecipientOption[]) => void;
}

const Wrapper = styled.div`
  border-bottom: 1px solid ${colors.lightBorder};
  padding: ${grid(2)};
`;

const FieldWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${grid(1)};

  &:last-child {
    margin-bottom: 0;
  }

  > span {
    margin-right: ${grid(1)};
    color: ${colors.darkText};
  }

  > div {
    flex: 1;
  }
`;

const Message = styled.div`
  line-height: ${grid(2)};
  font-size: 13px;

  button {
    text-decoration: underline;
    color: ${colors.socialieBlue};
  }
`;

const mapRecipientsToOptions = (recipients: Recipient[]) => {
  if (recipients.length > 1 || !recipients[0].publisherTag) {
    return defaultMapRecipientsToOptions(recipients);
  }

  // The user has selected a publisher tag, find every contact method within
  // that's either a wantsNotifications SMS number, or the first/only SMS
  // number for a given publisher and create it as an option
  const publisherTag = recipients[0].publisherTag!;
  const options: RecipientOption[] = [];
  publisherTag.publishers.forEach((publisher) => {
    // If there are any SMS methods with wantsNotifications turned on, use only
    // those, otherwise just use all the publisher's SMS methods
    const allSmsMethods = publisher.contactMethods.filter(
      (c) => c.protocol === 'sms'
    );
    const wantsNotificationsMethods = allSmsMethods.filter(
      (c) => c.wantsNotifications
    );
    const desiredMethods = !!wantsNotificationsMethods.length
      ? wantsNotificationsMethods
      : allSmsMethods;

    desiredMethods.forEach((contactMethod) => {
      const combinedId = `ContactMethod-${contactMethod.id}`;
      const data = {
        combinedId,
        contactMethod: { ...contactMethod, publisher },
        id: contactMethod.id!,
      };
      options.push({
        data,
        value: combinedId,
        label: publisher.name || '',
      });
    });

    // Also add any user phone numbers
    publisher.publisherUsers.forEach((publisherUser) => {
      if (publisherUser.pending || !publisherUser.phone) return;

      const combinedId = `PublisherUser-${publisherUser.id}`;

      const data = {
        combinedId,
        publisherUser,
        id: combinedId,
        protocol: 'sms',
      };

      options.push({
        data,
        value: combinedId,
        label: publisherUser.publisher.name || '',
      });
    });
  });

  return options;
};

export default function Recipients({ value, onChange }: Props) {
  const publisherTags = compact(value.map((opt) => opt.data.publisherTag));
  const tagsWithoutSms = publisherTags.filter(
    (pt) => !!publishersWithoutSms(pt.publishers).length
  );
  const tagsWithoutPublishers = publisherTags.filter(
    (pt) => !pt.publishers.length
  );
  const currentSuggester = useCurrentUser().currentSuggester!;
  const { enableWhatsApp } = currentSuggester.accountConfiguration;
  const contactProtocols = ['sms'];
  if (enableWhatsApp) contactProtocols.push('whats_app');

  return (
    <Wrapper>
      <FieldWrapper>
        <span>
          <FormattedMessage id="ChatPanel__To" />
        </span>

        <div>
          <RecipientsSearch
            onChange={onChange}
            value={value}
            autoFocus
            allowCreate
            contactProtocols={contactProtocols}
            collapseOptions
            mapRecipientsToOptions={mapRecipientsToOptions}
          />
        </div>
      </FieldWrapper>

      {tagsWithoutSms.map((publisherTag) => (
        <Message key={publisherTag.id}>
          <MissingSms publisherTag={publisherTag} />
        </Message>
      ))}

      {tagsWithoutPublishers.map((publisherTag) => (
        <Message key={publisherTag.id}>
          <FormattedMessage
            id="ChatPanel__PublisherTagNoPublishers"
            values={{ publisherTag: publisherTag.name }}
          />
        </Message>
      ))}
    </Wrapper>
  );
}
