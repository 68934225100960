import React, { PropsWithChildren, useEffect } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import FocusLock from 'react-focus-lock';
import { IconClose } from 'icons';

const SPRING = {
  type: 'spring',
  stiffness: 500,
  damping: 50,
};

interface Props {
  isOpen: boolean;
  handleDismiss: () => void;
}

function Sheet(props: PropsWithChildren<Props>) {
  const { children, isOpen, handleDismiss } = props;

  useEffect(() => {
    function handleKeydown(event: { key: string }) {
      if (event.key === 'Escape') {
        handleDismiss();
      }
    }

    window.addEventListener('keydown', handleKeydown);

    return () => {
      window.removeEventListener('keydown', handleKeydown);
    };
  });

  return (
    <AnimatePresence>
      {isOpen && (
        <FocusLock as="aside" returnFocus={true}>
          <motion.div
            animate={{ opacity: 1 }}
            transition={SPRING}
            exit={{
              transition: { duration: 0.3 },
            }}
            className="pointer-events-none fixed left-0 top-0 z-1001 h-screen w-screen backdrop-blur-sm backdrop-contrast-50"
          />
          <motion.div
            className="fixed right-0 top-0 z-1001 flex h-screen w-50 flex-col  overflow-auto overflow-y-hidden bg-white before:pointer-events-none before:absolute before:top-18.5 before:h-[87%] before:w-full before:border-0 before:border-l before:border-solid before:border-grey7 before:content-['']"
            initial={{ x: '100%' }}
            animate={{ x: 0 }}
            transition={SPRING}
            exit={{
              x: '100%',
              transition: { duration: 0.3 },
            }}
          >
            <div className="flex flex-1 flex-col-reverse justify-end overflow-x-hidden">
              <div className="flex flex-1 flex-col overflow-hidden">
                {children}
              </div>
              <div className="h-6 w-full shrink-0 bg-grey1">
                <button
                  className="absolute right-3 top-3 text-white"
                  onClick={handleDismiss}
                >
                  <IconClose className="h-2 w-2" />
                </button>
              </div>
            </div>
          </motion.div>
        </FocusLock>
      )}
    </AnimatePresence>
  );
}

export default Sheet;
