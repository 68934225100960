import React from 'react';
import { Toggle } from './components';

interface Props {
  children: React.ReactNode;
}

export default function Toggles({ children }: Props) {
  return <div className="-mb-0.5 flex flex-wrap items-center">{children}</div>;
}

Toggles.Toggle = Toggle;
