import React from 'react';
import styled, { css } from 'styled-components';
import { grid, colors } from 'styles/theme';

interface Props {
  checked?: boolean;
  onChange: () => void;
}

// prettier-ignore
const StyledRadio = styled.button.attrs({ type: 'button' })`
  position: relative;
  flex-shrink: 0;
  border: 1px solid ${colors.darkBorder};
  border-radius: 50%;
  background: ${colors.white};
  width: ${grid(2)};
  height: ${grid(2)};

  &:after {
    position: absolute;
    top: -${grid(1)};
    right: -${grid(1)};
    bottom: -${grid(1)};
    left: -${grid(1)};
    content: '';
  }

  &:hover,
  .Row:hover &,
  label:hover & {
    border-color: ${colors.bodyText};
  }

  ${(props: any) => props.checked && css`
    &::before {
      position: absolute;
      top: 3px;
      left: 3px;
      border-radius: 50%;
      background: ${colors.bodyText};
      width: ${grid(1)};
      height: ${grid(1)};
      content: '';
    }
  `};
` as any;

const Radio = ({ checked, onChange }: Props) => (
  <StyledRadio onClick={onChange} checked={checked} />
);

export default Radio;
