import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useCurrentUser, useIntl } from 'hooks';
import { flashMessage } from 'helpers';
import gql from 'graphql-tag';
import { useQuery, useMutation } from 'react-apollo';
import FacebookPage from 'types/FacebookPage';
import { Modal, Loader, ErrorDebug, Button, ConfirmModal } from 'components';
import Suggester from 'types/Suggester';
import pick from 'lodash/pick';
import { IconInstagram, IconFacebook } from 'icons';
import sortBy from 'lodash/sortBy';
import { FormattedHTMLMessage } from 'react-intl';
import performLogin from 'helpers/auth/performLogin';

interface Props {
  suggester: Suggester;
  redirectingRoute: string;
}

const FACEBOOK_PAGES = gql`
  query FacebookPages {
    facebookPages {
      id
      platformUid
      name
      displayName
      image
      token
      instagramBusinessId
    }
  }
`;

const ADD_FACEBOOK_PAGE = gql`
  mutation AddFacebookPage($values: FacebookPageInput!) {
    addFacebookPage(values: $values) {
      errors
      suggester {
        id
        facebookPage {
          id
          platformUid
          name
          image
          url
          instagramBusinessId
          invalidToken
        }
      }
    }
  }
`;

interface QueryData {
  facebookPages: FacebookPage[];
}

export default function FacebookPageSelector({
  suggester,
  redirectingRoute,
}: Props) {
  const [isAddingId, setIsAddingId] = useState(false);

  const { t } = useIntl();
  const history = useHistory();
  const { oauthIdentities } = useCurrentUser();
  const facebookIdentity = oauthIdentities.find(
    (o) => o.provider === 'facebook'
  );
  const invalidPermissions =
    facebookIdentity && facebookIdentity.invalidPermissions;

  const [addFacebookPage] = useMutation(ADD_FACEBOOK_PAGE, {
    onCompleted: (data) => {
      if (data.addFacebookPage.errors) {
        flashMessage('Global__UnexpectedError', { style: 'error' });
        setIsAddingId(false);
      } else {
        closeModal();
      }
    },
    onError: () => {
      flashMessage('Global__UnexpectedError', { style: 'error' });
      setIsAddingId(false);
    },
  });

  const { data, loading, error } = useQuery<QueryData>(FACEBOOK_PAGES, {
    skip: !facebookIdentity || invalidPermissions,
  });

  const facebookPages: FacebookPage[] = (data && data.facebookPages) || [];

  const anyLinkedToInstagram = facebookPages.some(
    (f) => !!f.instagramBusinessId
  );

  const closeModal = () => {
    history.push(redirectingRoute);
  };

  if (!facebookIdentity) {
    closeModal();
    return null;
  }

  const handleAdd = (facebookPage: FacebookPage) => {
    setIsAddingId(true);
    const values = pick(
      facebookPage,
      'platformUid',
      'name',
      'image',
      'url',
      'instagramBusinessId',
      'token'
    );
    addFacebookPage({ variables: { values } });
  };

  return (
    <Modal
      theme={{ large: true }}
      isOpen={true}
      onRequestClose={closeModal}
      contentLabel={t('FacebookPageSelector__Heading')}
      renderHeading={t('FacebookPageSelector__Heading')}
    >
      <div className="text-left">
        {invalidPermissions ? (
          <InvalidPermissions />
        ) : loading ? (
          <Loader />
        ) : error ? (
          <ErrorDebug error={error} />
        ) : !!facebookPages.length ? (
          <>
            <div className="mb-2.5 leading-normal text-dark">
              {t('FacebookPageSelector__Description')}
            </div>

            <div className="border-default mb-3 flex items-start rounded p-2">
              <IconInstagram className="bump-down-2 mr-1.5 h-2 w-2 flex-shrink-0 text-instagram" />
              <div className="leading-snug text-dark">
                <FormattedHTMLMessage
                  tagName="span"
                  id="FacebookPageSelector__InstagramExplainer"
                />
              </div>
            </div>

            {sortBy(facebookPages, (fbp) =>
              fbp.instagramBusinessId ? 0 : 1
            ).map((facebookPage) => (
              <FacebookPageRow
                key={facebookPage.id}
                facebookPage={facebookPage}
                onAdd={handleAdd}
                isAddingId={isAddingId}
                anyLinkedToInstagram={anyLinkedToInstagram}
                isConnected={
                  suggester.facebookPage &&
                  suggester.facebookPage.platformUid ===
                    facebookPage.platformUid
                }
              />
            ))}
          </>
        ) : (
          <NoPages onClose={closeModal} />
        )}
      </div>
    </Modal>
  );
}

interface FacebookPageRowProps {
  facebookPage: FacebookPage;
  onAdd: (facebookPage: FacebookPage) => void;
  anyLinkedToInstagram: boolean;
  isAddingId: boolean;
  isConnected?: boolean;
}

function FacebookPageRow(props: FacebookPageRowProps) {
  const { t } = useIntl();
  const { facebookPage, anyLinkedToInstagram, isConnected, onAdd, isAddingId } =
    props;

  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);

  // Show a warning if they decide to connect a page that's not connected
  // to instagram while they have the option of connecting one that is
  const handleAdd = () => {
    if (!facebookPage.instagramBusinessId && anyLinkedToInstagram) {
      setIsConfirmModalOpen(true);
    } else {
      onAdd(facebookPage);
    }
  };

  return (
    <div
      key={facebookPage.id}
      className="border-b-default w-full overflow-hidden py-2 last:border-b-0"
    >
      <div className="flex w-full items-center overflow-hidden">
        <img
          className="mr-1.5 block h-6 w-6 flex-shrink-0 object-cover"
          src={facebookPage.image}
          alt={facebookPage.displayName}
        />

        <div className="flex-1 leading-tight">
          <div className="mb-0.5 font-bold text-dark">
            {facebookPage.displayName}
          </div>
          <div className="flex items-center text-12">
            {!!facebookPage.instagramBusinessId ? (
              <>
                <IconInstagram className="bump-up-0.5 mr-0.5 h-2 w-2 text-light" />
                {t('FacebookPageSelector__LinkedToInstagram')}
              </>
            ) : (
              t('FacebookPageSelector__NotLinkedToInstagram')
            )}
          </div>
        </div>

        <div className="ml-auto w-12 flex-shrink-0">
          <Button filledBg onClick={handleAdd} disabled={isAddingId}>
            {isConnected ? (
              <span className="font-bold">
                {t('FacebookPageSelector__Reconnect')}
              </span>
            ) : (
              <span className="font-bold">
                {t('FacebookPageSelector__Connect')}
              </span>
            )}
          </Button>
        </div>
      </div>

      <ConfirmModal
        isOpen={isConfirmModalOpen}
        onRequestClose={() => setIsConfirmModalOpen(false)}
        onConfirm={() => onAdd(facebookPage)}
        heading="FacebookPageSelector__ConfirmNotLinkedToInstagramHeader"
      >
        {t('FacebookPageSelector__ConfirmNotLinkedToInstagramBody')}
      </ConfirmModal>
    </div>
  );
}

function InvalidPermissions() {
  const { t } = useIntl();
  const [isReconnecting, setIsReconnecting] = useState(false);

  const handleReconnect = () => {
    setIsReconnecting(true);
    performLogin({ platformId: 'facebook' });
  };

  return (
    <div>
      <div className="mb-2 leading-snug text-dark">
        {t('FacebookPageSelector__InvalidPermissions')}
      </div>

      <button
        className="rounded bg-facebook px-2 py-0.5 text-white disabled:opacity-50"
        onClick={handleReconnect}
        disabled={isReconnecting}
      >
        <div className="flex items-center">
          <IconFacebook className="mr-1 h-2.5 w-2.5 text-white" />
          {t('FacebookPageSelector__ReconnectAccount')}
        </div>
      </button>
    </div>
  );
}

function NoPages({ onClose }: { onClose: () => void }) {
  const { t } = useIntl();
  return (
    <div>
      <div className="mb-2 leading-snug text-dark">
        {t('FacebookPageSelector__NoPages')}
      </div>

      <Button inlineBlock filledBg onClick={onClose}>
        {t('Global__Close')}
      </Button>
    </div>
  );
}
