import React from 'react';
import styled from 'styled-components';
import { media } from 'styles';
import { colors, grid } from 'styles/theme';
import logoUrl from 'images/logo-socialie.png';

interface Props {
  children: React.ReactNode;
  bottomLink?: React.ReactNode;
  showLogo?: boolean;
}

const OuterWrapper = styled.div`
  padding: 0 ${grid(3)} ${grid(3)};

  ${media.tablet`
    padding-top: ${grid(10)};
  `};
`;

const Wrapper = styled.div`
  margin: 0 auto;
  border-radius: 4px;
  box-shadow: 0 2px 4px ${colors.boxShadow};
  background-color: ${colors.white};
  max-width: 400px;
`;

const FormWrapper = styled.div`
  padding: ${grid(3)};

  > img {
    display: block;
    margin: 0 auto ${grid(4)};
    width: 180px;
  }
`;

const BottomBar = styled.div`
  border-top: 1px solid ${colors.lightBorder};
  padding: ${grid(1)};
  text-align: center;

  a {
    text-decoration: underline;
    color: ${colors.lightText};
  }
`;

const AuthForm = ({ children, bottomLink, showLogo = true }: Props) => (
  <OuterWrapper>
    <Wrapper data-testid="auth-form">
      <FormWrapper>
        {showLogo && (
          <img data-testid="socialie-logo" src={logoUrl} alt="Socialie" />
        )}
        {children}
      </FormWrapper>

      {bottomLink && (
        <BottomBar data-testid="bottom-bar">{bottomLink}</BottomBar>
      )}
    </Wrapper>
  </OuterWrapper>
);

export default AuthForm;
