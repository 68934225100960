import React from 'react';
import styled from 'styled-components';
import { IconTriangleDown } from 'icons';
import { colors, grid } from 'styles/theme';

const Wrapper = styled.div`
  align-self: flex-start;
  padding-top: ${grid(0.75)};
  padding-right: ${grid(1)};
  color: ${(props: { isDisabled: boolean }) =>
    props.isDisabled ? colors.grey6 : colors.grey1};

  svg {
    width: ${grid(1.5)};
    height: ${grid(1.5)};
  }
`;

const DropdownIndicator = (props: any) => (
  <Wrapper isDisabled={props.isDisabled}>
    <IconTriangleDown />
  </Wrapper>
);

export default DropdownIndicator;
