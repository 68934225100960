import React, { useState } from 'react';
import Suggestion from 'types/Suggestion';
import { DateTimePicker, QTooltip } from 'components';
import { useIntl } from 'hooks';
import moment from 'moment-timezone';

interface Props {
  suggestions: Suggestion[];
  onChange: (expiresAt: Date) => void;
}

export default function ExpiresAt(props: Props) {
  const { suggestions, onChange } = props;

  const { t } = useIntl();

  const daysFromNow = (numDays: number) =>
    moment().add(numDays, 'days').toDate();

  const [expirationDate, setExpirationDate] = useState<Date>(() => {
    if (suggestions[0].expiresAt) {
      return new Date(suggestions[0].expiresAt);
    } else {
      return daysFromNow(30);
    }
  });

  const handleChange = (value: Date) => {
    setExpirationDate(value);
    onChange(value);
  };

  return (
    <div>
      <div className="mb-1 flex items-center space-x-1">
        <p className="text-14 font-semibold text-darkText">
          {t('SuggestionForm__Expiration', {
            hasSuggestions: !!suggestions.length,
            count: suggestions.length,
          })}
        </p>
        <QTooltip content={t('SuggestionForm__ExpirationTooltip')} />
      </div>
      <div className="mb-2">
        <DateTimePicker
          value={expirationDate}
          onChange={(e) => handleChange(e)}
        />
      </div>
    </div>
  );
}
