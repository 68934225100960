import React, { useState } from 'react';
import { rgba } from 'polished';
import styled from 'styled-components';
import { Toggle } from 'react-powerplug';
import { grid, colors } from 'styles/theme';
import { SketchPicker } from 'react-color';
import { mixins } from 'styles';
import Errors from './Errors';
import { IconEye } from 'icons';

interface Props extends React.InputHTMLAttributes<any> {
  icon?: React.ReactNode;
  iconPosition?: 'left' | 'right';
  small?: boolean;
  errors?: string;
  translateErrors?: boolean;
  innerRef?: any;
  innerLabel?: string;
  color?: any;
  large?: boolean;
}

// prettier-ignore
const Wrapper = styled.div`
  display: block;
  position: relative;
  width: 100%;

  > svg,
  button {
    display: block;
    position: absolute;
    top: 50%;
    left: ${grid(1)};
    transform: translateY(-50%);
    transition: color 0.15s;
    width: ${grid(2.5)};
    height: ${grid(2.5)};
    color: ${colors.lightIcon};

    ${(props: any) =>
      props.small &&
      `
      left: ${grid(0.75)};
      width: ${grid(2.5)};
      height: ${grid(2.5)};
    `};

    ${(props: any) =>
      props.iconPosition === 'right' &&
      `
      right: ${grid(0.5)};
      left: auto;
    `};

    ${(props: any) =>
      props.small &&
      props.iconPosition === 'right' &&
      `
      right: ${grid(0.75)};
      left: auto;
    `};
  }

  ${(props: any) =>
    props.color &&
    `> svg {      
      color: ${props.color};
    }
    `};
` as any;

// prettier-ignore
const StyledInput = styled.input`
  ${mixins.input};
  padding: ${grid(1)};
  height: ${grid(4.5)};
  line-height: ${grid(4.5)};

  &:focus + svg {
    color: ${colors.focus};
  }

  &:disabled {
    background-color: ${rgba(colors.greyBg, 0.35)};
    color: ${colors.lightText};
  }

  ${(props: any) => props.hasInnerLabel && `
    padding-top: ${grid(2)};
    height: ${grid(4.5)};
  `};

  ${(props: any) => props.large && `
      height: ${grid(5.5)};
      font-size: 16px;
  `};

  ${(props: any) => props.hasIcon && props.iconPosition === 'left' && `
    padding-left: ${grid(4)}
  `};


  ${(props: any) => props.hasIcon && props.iconPosition === 'right' && `
    padding-right: ${grid(4)}
  `};

  ${(props: any) => props.type === 'password' && `
    padding-right: ${grid(4)}
  `};

  ${(props: any) => props.small && `
    padding: ${grid(0.5)} ${grid(2)};
    height: ${grid(3)};
    line-height: ${grid(3)};

    ${props.hasIcon && props.iconPosition === 'left' && `
      padding-left: ${grid(3.5)}
    `};

    ${props.hasIcon && props.iconPosition === 'right' && `
      padding-right: ${grid(3.5)}
    `};
  `};
` as any;

const InnerLabel = styled.div`
  position: absolute;
  top: 0;
  left: ${grid(1)};
  line-height: 20px;
  color: ${colors.darkText};
  font-size: 10px;
`;

const ColorInput = styled.div`
  position: absolute;
  top: ${grid(1)};
  right: ${grid(1)};
  border: none;
  border-radius: 50%;
  padding: 0;
  width: ${grid(2.5)};
  height: ${grid(2.5)};

  > div {
    position: absolute;
    top: ${grid(1.25)};
    left: ${grid(1.25)};
    z-index: 1000;
  }
`;

const TextInput = React.forwardRef(
  (
    {
      icon,
      small,
      errors,
      translateErrors,
      iconPosition,
      innerLabel,
      color,
      ...restProps
    }: Props,
    ref
  ) => {
    const isPasswordType = restProps.type === 'password';
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);

    const onBlur = (event: any) => {
      if (isPasswordType && isPasswordVisible) {
        setIsPasswordVisible(false);
      }

      if (restProps.onBlur) {
        restProps.onBlur(event);
      }
    };

    return (
      <>
        <Wrapper
          small={small}
          color={color}
          iconPosition={isPasswordType ? 'right' : iconPosition || 'left'}
        >
          {innerLabel && <InnerLabel>{innerLabel}</InnerLabel>}
          <StyledInput
            {...restProps}
            onBlur={onBlur}
            type={isPasswordType && isPasswordVisible ? 'text' : restProps.type}
            errors={errors}
            hasIcon={!!icon}
            hasInnerLabel={!!innerLabel}
            iconPosition={iconPosition || 'left'}
            small={small}
            ref={ref}
          />
          {isPasswordType ? (
            <button
              type="button"
              onClick={() =>
                setIsPasswordVisible((isPasswordVisible) => !isPasswordVisible)
              }
            >
              <IconEye className="h-2 w-2" />
            </button>
          ) : (
            icon
          )}

          {color && (
            <Toggle initial={false}>
              {({ on, setOn, toggle }: any) => (
                <ColorInput
                  onClick={toggle}
                  style={{ backgroundColor: `${restProps.value}` }}
                >
                  {on && (
                    <SketchPicker
                      color={`${restProps.value}`}
                      onChangeComplete={(color: any) => {
                        const event = {
                          persist: () => {},
                          target: {
                            name: restProps.name,
                            value: color.hex,
                          },
                        } as React.ChangeEvent<any>;
                        restProps.onChange && restProps.onChange(event);
                      }}
                    />
                  )}
                </ColorInput>
              )}
            </Toggle>
          )}
        </Wrapper>

        {errors && <Errors errors={errors} translate={translateErrors} />}
      </>
    );
  }
);

export default TextInput;
