import React from 'react';
import Suggestion from 'types/Suggestion';
import { useGetInvalidPlatforms, useCurrentUser } from 'hooks';
import { connect } from 'react-redux';
import {
  startBatchSuggestionSaveOperation,
  stopBatchSuggestionSaveOperation,
} from 'redux/ducks/ui';
import { PlatformToggle } from './components';
import removeDraft from 'graphql/operations/removeDraft';
import updateDraft from 'graphql/operations/updateDraft';

interface OwnProps {
  suggestions: Suggestion[];
  platforms: string[];
  onChange?: () => void;
}

interface ConnectedActions {
  startSaving: (operation: string) => void;
  stopSaving: (operation: string) => void;
}

type Props = OwnProps & ConnectedActions;

function PlatformToggles(props: Props) {
  const user = useCurrentUser();
  const platformBlacklist =
    user.currentSuggester?.accountConfiguration?.onboardingPlatformBlacklist ||
    [];
  const { suggestions, startSaving, stopSaving, onChange } = props;
  const platforms = props.platforms.filter(
    (p) => !platformBlacklist.includes(p)
  );
  const getInvalidPlatforms = useGetInvalidPlatforms();
  const invalidPlatforms =
    suggestions.length === 1 ? getInvalidPlatforms(suggestions[0]) : [];

  return (
    <div className="flex items-center">
      {platforms.map((platform) => {
        const isEnabled = suggestions.every(
          (s) => !!s.drafts && !!s.drafts.find((d) => d.platform === platform)
        );

        const handleToggle = async () => {
          const suggestionIds = suggestions.map((s) => s.id);
          startSaving(platform);
          if (isEnabled) {
            await removeDraft({ platform, suggestionIds });
          } else {
            await updateDraft({ platform, suggestionIds });
          }
          onChange?.();
          stopSaving(platform);
        };

        return (
          <PlatformToggle
            platform={platform}
            key={platform}
            isEnabled={isEnabled}
            invalidReason={invalidPlatforms[platform]}
            onToggle={handleToggle}
          />
        );
      })}
    </div>
  );
}

const mapDispatchToProps = {
  startSaving: startBatchSuggestionSaveOperation,
  stopSaving: stopBatchSuggestionSaveOperation,
};

export default connect<OwnProps, ConnectedActions>(
  null,
  mapDispatchToProps
)(PlatformToggles);
