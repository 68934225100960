import React, { lazy, Suspense, useState } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import styled from 'styled-components';
import User from 'types/User';
import { sizes } from 'styles/theme';
import {
  SuggesterSwitcher,
  MainNav,
  UserMenu,
  NewMenu,
  NavBarTitle,
} from './components';
import logoUrl from 'images/logo-socialie.png';
import SemiLoggedInUser from 'types/SemiLoggedInUser';
const ChatPanel = lazy(() => import('./components/ChatPanel'));

interface OwnProps {
  hasNavigation: boolean;
  currentUser?: User | SemiLoggedInUser;
}

type Props = OwnProps & RouteComponentProps<any>;

const Logo = styled.a`
  display: flex;
  align-items: center;
  margin: auto;

  img {
    width: 160px;
  }
`;

const SecondaryItems = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
`;

function GlobalNavBar({ hasNavigation, currentUser, location }: Props) {
  const [isChatOpen, setIsChatOpen] = useState(false);
  const isUploader =
    currentUser &&
    'currentRole' in currentUser &&
    currentUser.currentRole === 'uploader';

  if (location.pathname.match(/\/invites.+/)) return null;
  return (
    <div
      style={
        {
          '--height': sizes.GlobalNavBar.height.default,
          '--tablet-height': sizes.GlobalNavBar.height.tablet,
        } as React.CSSProperties
      }
      className="fixed left-0 right-0 top-[--warning-banner-height] z-[1001] flex h-[--height] items-center bg-grey2 pr-6 tablet:h-[--tablet-height] tablet:pr-0"
    >
      {!currentUser ? (
        <Logo href="/">
          <img src={logoUrl} alt="Socialie" />
        </Logo>
      ) : (
        <>
          <SuggesterSwitcher currentUser={currentUser} />

          {hasNavigation && <MainNav />}

          {hasNavigation && <NavBarTitle />}

          {!isUploader && hasNavigation ? (
            <SecondaryItems>
              <div id="hw_anchor" />

              {currentUser.currentSuggester &&
                'hasChat' in currentUser.currentSuggester &&
                currentUser.currentSuggester.viewerCanChat && (
                  <Suspense fallback={null}>
                    <ChatPanel
                      isOpen={isChatOpen}
                      toggleOpen={() => setIsChatOpen(!isChatOpen)}
                      hasUnreadMessages={
                        !!currentUser.currentSuggester.hasUnreadChatMessages
                      }
                    />
                  </Suspense>
                )}
              <NewMenu closeChat={() => setIsChatOpen(false)} />
              <UserMenu currentUser={currentUser} hasSettings={hasNavigation} />
            </SecondaryItems>
          ) : (
            <div className="flex w-full items-center justify-end">
              <UserMenu currentUser={currentUser} hasSettings={hasNavigation} />
            </div>
          )}
        </>
      )}
    </div>
  );
}
export default withRouter(GlobalNavBar);
