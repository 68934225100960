import React from 'react';
import styled from 'styled-components';
import { Lightbox } from 'components';
import { grid, colors } from 'styles/theme';
import { FormattedMessage } from 'react-intl';
import Attachment from 'types/Attachment';

interface Props {
  url: string;
  contentType: string;
}

interface State {
  isBroken: boolean;
  isLightboxOpen: boolean;
}

// prettier-ignore
const Wrapper = styled.div`
  position: relative;
  margin-bottom: ${grid(1)};
  padding-bottom: 100%;
  width: 240px;
  height: 0;

  &:last-child {
    margin-bottom: 0;
  }

  button {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  img,
  video {
    display: block;
    border: 1px solid ${colors.border};
    border-radius: 19px;
    width: 100%;
    height: 100%;
    object-fit: contain;

    :-webkit-full-screen {
      z-index: 1000;
      width: 100vw;
      height: 100vh;
      max-height: none;
    }
  }
` as any;

const Message = styled.div`
  line-height: ${grid(2)};
  color: ${colors.lightText};
  font-size: 14px;
  font-style: italic;

  a {
    text-decoration: underline;
    color: ${colors.lightText};
    font-size: 12px;
  }
`;

export default class File extends React.Component<Props, State> {
  private buttonRef: React.RefObject<HTMLButtonElement> = React.createRef();

  state: State = { isBroken: false, isLightboxOpen: false };

  onError = () => this.setState({ isBroken: true });

  openLightbox = () => this.setState({ isLightboxOpen: true });

  closeLightbox = () => this.setState({ isLightboxOpen: false });

  render() {
    const { contentType, url } = this.props;
    const { isBroken } = this.state;

    if (isBroken || !contentType.match(/^(image|video)/)) {
      return (
        <Message>
          <FormattedMessage id="ChatPanel__UnplayableMedia" />
          <br />
          <a href={url} target="_blank" rel="noopener noreferrer">
            <FormattedMessage id="ChatPanel__DirectDownload" />
          </a>
        </Message>
      );
    }

    const isImage = !!contentType.match(/^image/);

    const fakeAttachment = {
      id: 0,
      mediaUrl: url,
      blurThumbnail: url,
      thumbnail: url,
      mediaContentType: contentType,
      type: isImage ? 'Photo' : 'Video',
      aspectRatio: 0,
      status: 'complete',
      mediaSize: 0,
      mediaFilename: 'twilio',
      length: 0,
      createdAt: new Date().getTime(),
    } as Attachment;

    return (
      <Wrapper>
        <button onClick={this.openLightbox} ref={this.buttonRef}>
          {isImage ? (
            <img onError={this.onError} src={url} alt="Message Attachment" />
          ) : (
            <video controls={false} onError={this.onError}>
              <source
                src={url}
                type={contentType.replace('quicktime', 'mp4')}
              />
            </video>
          )}
        </button>

        <Lightbox
          isOpen={this.state.isLightboxOpen}
          onRequestClose={this.closeLightbox}
          attachments={[fakeAttachment]}
        />
      </Wrapper>
    );
  }
}
