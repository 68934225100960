import React from 'react';
import styled from 'styled-components';
import { colors } from 'styles/theme';
import { IconKebab } from 'icons';

interface Props {
  onClick: (e: any) => {};
}

const StyledKebab = styled.button.attrs({ type: 'button' })`
  border-radius: 4px;
  padding: 15%;
  width: 100%;
  height: 100%;
  color: ${colors.grey1};

  &:hover {
    background-color: ${colors.greyBg};
  }

  svg {
    position: relative;
    top: -1px;
    width: 100%;
    height: 100%;
  }
`;

const Kebab = ({ onClick }: Props) => (
  <StyledKebab onClick={onClick}>
    <IconKebab />
  </StyledKebab>
);

export default Kebab;
