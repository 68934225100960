import { useEffect } from 'react';
import User from 'types/User';
import { flashMessage, routes } from 'helpers';
import { useIntl } from 'hooks';
import { useLocation, matchPath } from 'react-router-dom';

interface Props {
  currentUser?: User;
}

/*
If the user has an invalid content integration, persist this toast until they
dismiss it
*/
export default function ContentIntegrationWarning(props: Props) {
  const { currentUser } = props;
  const { t } = useIntl();
  const location = useLocation();

  const isInvitePath = !!matchPath(location.pathname, {
    path: routes.invitation,
    exact: true,
  });

  const hasInvalidContentRoutingRule =
    currentUser?.currentSuggester?.contentIntegrations.some((ci) =>
      ci.contentRoutingRules.some((crr) => crr.invalidPath)
    );

  const hasInvalidIntegration = currentUser?.oauthIdentities?.some(
    (oauthIdentity) =>
      ['box', 'dropbox', 'getty_images'].includes(oauthIdentity.provider) &&
      oauthIdentity.invalidToken &&
      currentUser?.currentSuggester?.contentIntegrations.some(
        (ci) => ci.oauthIdentityId === oauthIdentity.id
      )
  );

  const hasInvalidAccount =
    currentUser?.currentSuggester?.contentIntegrations?.some(
      (ci) => ci.invalidAccount
    );

  const isInvalid =
    hasInvalidIntegration || hasInvalidAccount || hasInvalidContentRoutingRule;

  const msg = t('ContentIntegrations__InvalidToast--Message');

  const shouldShowFlash = !isInvitePath && isInvalid;

  useEffect(() => {
    const renderInvalidIntegrationFlash = () =>
      flashMessage(msg, {
        timeout: false,
        props: {
          skipTranslation: true,
          invalidTokenLink: routes.settings.integrations.index,
        },
      });
    if (shouldShowFlash) {
      renderInvalidIntegrationFlash();
    }
  }, [msg, shouldShowFlash]);

  return null;
}
