import omitDeleted from './omitDeleted';

function omitDeletedCollection<T>(object: T, ...collections: string[]): T {
  let retVal = Object.assign({}, object);
  collections.forEach((collection) => {
    retVal = Object.assign({}, retVal, {
      [collection]: omitDeleted(object[collection]),
    });
  });

  return retVal;
}

export default omitDeletedCollection;
