import * as config from 'config';
import compact from 'lodash/compact';
import { findInstagramIdentity } from 'helpers';

interface AnyPublisher {
  identities: { platform: string }[];
  facebookPage?: any;
  trackedAccounts?: { platform: string }[];
}

const getFollowerAccounts = ({
  identities,
  facebookPage,
  trackedAccounts,
}: AnyPublisher) =>
  compact(
    config.platforms.map((platform) => {
      const identity =
        platform === 'instagram'
          ? findInstagramIdentity(identities)
          : identities.find((i) => i.platform === platform);

      const trackedAccount =
        !identity &&
        trackedAccounts &&
        trackedAccounts.find((i) => i.platform === platform);

      if (platform === 'facebook' && facebookPage) {
        return { ...facebookPage, platform: 'facebook', isTracked: false };
      }

      if (identity) return { ...identity, isTracked: false };
      if (trackedAccount) return { ...trackedAccount, isTracked: true };
      return null;
    })
  );

export default getFollowerAccounts;
