import React, { useState } from 'react';
import Suggestion from 'types/Suggestion';
import { DateTimePicker, Form, QTooltip } from 'components';
import { useIntl } from 'hooks';
import moment from 'moment';

interface Props {
  suggestions: Suggestion[];
  isEditing?: boolean;
  onChange: (expiresAt: Date | null) => void;
}

export default function Expiration(props: Props) {
  const { suggestions, isEditing, onChange } = props;

  const { t } = useIntl();

  const daysFromNow = (numDays: number) =>
    moment().add(numDays, 'days').toDate();

  const initialValue =
    isEditing && suggestions[0]?.expiresAt
      ? new Date(suggestions[0].expiresAt)
      : daysFromNow(30);

  const [expirationDate, setExpirationDate] = useState(initialValue);

  const [isOpen, setIsOpen] = useState(!!isEditing);

  const handleShowField = () => {
    // If the field is hidden, set the value to null for expiresAt field
    if (!isOpen) onChange(null);

    setIsOpen((isOpen) => !isOpen);
  };

  const handleChange = (value: Date) => {
    setExpirationDate(value);
    onChange(value);
  };

  return (
    <div>
      {!isEditing && (
        <div className="mb-1 flex items-center space-x-1">
          <Form.Checkbox
            data-qa="suggestion-form-expiration-checkbox"
            checked={isOpen}
            onChange={handleShowField}
          />
          <p
            className="text-14 font-semibold text-darkText"
            data-qa="suggestion-form-expiration-label"
          >
            {t('SuggestionForm__Expiration', {
              hasSuggestions: !!suggestions.length,
              count: suggestions.length,
            })}
          </p>
          <QTooltip content={t('SuggestionForm__ExpirationTooltip')} />
        </div>
      )}

      {isOpen && (
        <div className="text-left">
          <DateTimePicker
            value={expirationDate}
            hideTimeZone
            onChange={(e) => handleChange(e)}
          />
        </div>
      )}
    </div>
  );
}
