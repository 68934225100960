import Suggestion from 'types/Suggestion';

export default function isSuggestionMissingRecipients(suggestion: Suggestion) {
  if (!suggestion.intendedRecipients.length) return true;

  const onlyTags = suggestion.intendedRecipients.every((r) => !!r.publisherTag);
  const onlyEmptyTags =
    onlyTags &&
    suggestion.intendedRecipients
      .map((r) => r.publisherTag!)
      .every((pt) => !pt.publishers.length);
  return onlyEmptyTags;
}
