import * as UPDATE_PLATFORM_BAG from 'graphql/mutations/updatePlatformBag.graphql';
import apolloClient from '../apolloClient';

interface TargetOption {
  value: string | number;
  description: string;
}

interface Options {
  suggestionIds: number | string | (number | string)[];
  platform: string;
  countries: TargetOption[];
  locales: TargetOption[];
  gender: string;
  minAge?: number;
  maxAge?: number;
}

const updatePlatformBag = async (options: Options) => {
  const { errors, data } = await apolloClient.mutate({
    mutation: UPDATE_PLATFORM_BAG,
    variables: options,
  });

  if (errors) return { errors };
  if (data && data.errors) return { errors: data.errors };
  if (data) return data;
  return null;
};

export default updatePlatformBag;
