import React from 'react';
import Recipient from 'types/Recipient';
import { useIntl } from 'hooks';
import groupBy from 'lodash/groupBy';
import { contactProtocolFieldMap, getFormattedContactMethod } from 'helpers';
import { contactMethodIcons, IconRemove } from 'icons';
import { Tooltip } from 'components';

interface Props {
  recipients: Recipient[];
  onRemove: (value: Recipient | Recipient[]) => void;
}

const iconRemoveClasses =
  'block w-2 h-2 bump-down-0.5 text-lightIcon hover:text-light';

export default function Breakdown({ recipients, onRemove }: Props) {
  const recipientsByUser = Object.values(
    groupBy(
      recipients.filter((r) => r.publisherUser?.userId),
      (r) => r.publisherUser?.userId
    )
  );

  const pendingUserRecipients = recipients.filter(
    (r) => r.protocol === 'invite'
  );

  const contactMethodRecipients = recipients.filter((r) => r.contactMethod);

  return (
    <div className="py-1 pl-0.5 text-left text-14 text-dark">
      {recipientsByUser.map((userRecipients) => (
        <UserRecipientsRow
          key={userRecipients[0].id}
          recipients={userRecipients}
          onRemove={onRemove}
        />
      ))}

      {pendingUserRecipients.map((recipient) => (
        <PendingUserRecipientRow
          key={recipient.id}
          recipient={recipient}
          onRemove={onRemove}
        />
      ))}

      {contactMethodRecipients.map((recipient) => (
        <ContactMethodRecipientRow
          key={recipient.id}
          recipient={recipient}
          onRemove={onRemove}
        />
      ))}
    </div>
  );
}

interface UserRecipientsRowProps {
  recipients: Recipient[];
  onRemove: Props['onRemove'];
}

function UserRecipientsRow({ recipients, onRemove }: UserRecipientsRowProps) {
  const user = recipients[0].publisherUser!;
  const { t } = useIntl();

  if (!user) return null;

  return (
    <div key={user.id} className="mb-1 flex flex-nowrap items-center last:mb-0">
      <div className="mr-2 flex items-center">
        {user.name}

        <div className="flex flex-nowrap items-center">
          {recipients.map((recipient) => {
            const { protocol } = recipient;
            if (!protocol) return null;
            const value = user[contactProtocolFieldMap[protocol]];
            const Icon = contactMethodIcons[protocol];
            if (!value) return null;
            if (!Icon) {
              throw new Error(`[UserRecipient] No icon for ${protocol}`);
            }

            return (
              <Tooltip
                key={protocol}
                animation={false}
                content={t('RecipientsSearch__RemoveProtocol', {
                  protocol: t(`Protocol--${protocol}`),
                })}
                theme="socialieDark"
              >
                <button onClick={() => onRemove(recipient)}>
                  <Icon className="bump-down-2 text-body ml-0.5 h-1.75 w-1.75" />
                </button>
              </Tooltip>
            );
          })}
        </div>
      </div>

      <button className="ml-auto" onClick={() => onRemove(recipients)}>
        <IconRemove className={iconRemoveClasses} />
      </button>
    </div>
  );
}

interface ContactMethodRecipientRowProps {
  recipient: Recipient;
  onRemove: Props['onRemove'];
}

function ContactMethodRecipientRow(props: ContactMethodRecipientRowProps) {
  const { recipient, onRemove } = props;
  const Icon = contactMethodIcons[recipient.contactMethod!.protocol];

  return (
    <div className="mb-1 flex items-center last:mb-0" key={recipient.id}>
      <div className="mr-2 flex items-center">
        {getFormattedContactMethod(recipient.contactMethod!)}
        {recipient.contactMethod!.name && (
          <span className="ml-0.5"> ({recipient.contactMethod!.name})</span>
        )}

        {Icon && <Icon className="ml-0.5 block h-2 w-2 text-default" />}
      </div>

      <button className="ml-auto" onClick={() => onRemove(recipient)}>
        <IconRemove className={iconRemoveClasses} />
      </button>
    </div>
  );
}

interface PendingUserRecipientRowProps {
  recipient: Recipient;
  onRemove: Props['onRemove'];
}

function PendingUserRecipientRow(props: PendingUserRecipientRowProps) {
  const { recipient, onRemove } = props;
  const { t } = useIntl();
  if (!recipient.publisherUser?.inviteContactValue) return null;

  const Icon = contactMethodIcons[recipient.publisherUser.inviteProtocol];

  return (
    <div className="mb-1 flex items-center last:mb-0" key={recipient.id}>
      <div className="mr-2 flex items-center">
        {getFormattedContactMethod({
          protocol: recipient.publisherUser.inviteProtocol,
          value: recipient.publisherUser.inviteContactValue,
        })}

        {Icon && <Icon className="ml-0.5 block h-2 w-2 text-default" />}

        <span className="ml-0.5 text-12 text-light">
          ({t('RecipientsSearch__InvitationPending')})
        </span>
      </div>

      <button className="ml-auto" onClick={() => onRemove(recipient)}>
        <IconRemove className={iconRemoveClasses} />
      </button>
    </div>
  );
}
