import React from 'react';
import styled from 'styled-components';
import { ConfirmModal, Loader } from 'components';
import { FormattedMessage } from 'react-intl';
import Attachment from 'types/Attachment';
import * as REMOVE_ATTACHMENTS from 'graphql/mutations/removeAttachments.graphql';
import { grid } from 'styles/theme';
import { useBoolean } from 'hooks';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { flashMessage } from 'helpers';
import gql from 'graphql-tag';
import RelayConnection from 'types/RelayConnection';

interface Props {
  attachments: Attachment[];
  onCompleted?: () => void;
  children: (onClick: () => void) => React.ReactNode;
}

interface MutationData {
  removeAttachments: {
    attachments?: {
      id: string;
      deleted: boolean;
    }[];
    errors?: string;
  };
}

const Note = styled.div`
  margin-top: ${grid(2)};
  font-size: 13px;
  font-weight: 600;
`;

export default function RemoveAttachment(props: Props) {
  const { attachments, onCompleted, children } = props;

  const [isConfirmModalOpen, openConfirmModal, closeConfirmModal] =
    useBoolean(false);

  return (
    <>
      {children(openConfirmModal)}

      <RemoveAttachmentConfirmModal
        ids={attachments.map((a) => a.id)}
        isOpen={isConfirmModalOpen}
        onRequestClose={closeConfirmModal}
        onCompleted={onCompleted}
      />
    </>
  );
}

const ATTACHMENTS_QUERY = gql`
  query RemoveAttachmentConfirmModal($ids: [ID!]!) {
    attachments(ids: $ids) {
      edges {
        node {
          id
          viewerCanDestroy
        }
      }
    }
  }
`;

interface RemoveModalProps {
  ids: Array<Attachment['id']>;
  isOpen: boolean;
  onCompleted?: Props['onCompleted'];
  onRequestClose: () => void;
}

type MaybeDestroyableAttachment = Pick<Attachment, 'id' | 'viewerCanDestroy'>;

function RemoveAttachmentConfirmModal(props: RemoveModalProps) {
  const { ids, isOpen, onCompleted, onRequestClose } = props;

  const { data, loading } = useQuery<{
    attachments: RelayConnection<MaybeDestroyableAttachment>;
  }>(ATTACHMENTS_QUERY, {
    variables: { ids },
    skip: !isOpen || !ids.length,
  });

  const attachments: MaybeDestroyableAttachment[] = data?.attachments
    ? data.attachments.edges.map((e) => e.node)
    : [];

  const allowedIds = attachments
    .filter((a) => a.viewerCanDestroy)
    .map((a) => a.id);

  const notAllowedCount = attachments.length - allowedIds.length;

  const noneAllowed = !loading && !allowedIds.length;

  const [removeAttachments, { loading: isRemoving }] =
    useMutation<MutationData>(REMOVE_ATTACHMENTS, {
      variables: { ids: allowedIds },
      onCompleted: (data) => {
        if (data && data.removeAttachments && !data.removeAttachments.errors) {
          onCompleted && onCompleted();
        } else {
          flashMessage('Global__UnexpectedError', { style: 'error' });
        }

        onRequestClose();
      },
    });

  return (
    <ConfirmModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      onConfirm={noneAllowed ? onRequestClose : removeAttachments}
      isLoading={isRemoving}
      heading="RemoveAttachment__ConfirmHeading"
      hideCancel={noneAllowed}
      confirmText={noneAllowed ? 'Global__Close' : 'Global__Yes'}
      cancelText="Global__Cancel"
    >
      {loading ? (
        <Loader />
      ) : allowedIds.length ? (
        <>
          <FormattedMessage
            id="RemoveAttachment__ConfirmBody"
            values={{ count: attachments.length }}
          />

          {!!notAllowedCount && (
            <Note>
              <FormattedMessage
                id="RemoveAttachment__SomeNotAllowed"
                values={{ count: notAllowedCount }}
              />
            </Note>
          )}
        </>
      ) : (
        <FormattedMessage id="RemoveAttachment__NoneAllowed" />
      )}
    </ConfirmModal>
  );
}
