import React, { useState } from 'react';
import InfoCard from '../InfoCard';
import Attachment from 'types/Attachment';
import { useIntl } from 'hooks';
import RequestItem from './RequestItem';
import { Modal } from 'components';
import flatten from 'lodash/flatten';

interface Props {
  attachment: Attachment;
}

export default function SuggestionHistory({ attachment }: Props) {
  const { t } = useIntl();
  const [isModalOpen, setIsModalOpen] = useState(false);

  if (!attachment.suggestions || !attachment.suggestions.length) {
    return null;
  }

  const requests = flatten(attachment.suggestions!.map((s) => s.requests));

  if (!requests.length) {
    return null;
  }

  const sort = {
    send_failed: 1,
    failed: 2,
    published: 3,
    submitted: 4,
    scheduled: 5,
    opened: 6,
    pending: 7,
    expired: 8,
    cancelled: 9,
  };
  const orderedRequests = requests
    .sort((a, b) => sort[b.status] - sort[a.status])
    .sort((a, b) => b.updatedAt - a.updatedAt);

  const maxToShow = 5;

  const suggestionsById = attachment.suggestions.reduce(
    (obj, s) => ({ ...obj, [s.id]: s }),
    {}
  );

  return (
    <>
      <InfoCard
        title={t('AttachmentModal__Publishers')}
        hasTotalItemsPill
        totalItemsPillAmount={requests.length}
      >
        {orderedRequests.slice(0, maxToShow).map((r) => {
          return (
            <RequestItem
              key={r.id}
              hideDate
              request={r}
              suggestion={suggestionsById[r.suggestion.id]}
            />
          );
        })}
        {orderedRequests.length > maxToShow && (
          <p
            className="text-socialiePink hover:underline"
            onClick={() => setIsModalOpen(true)}
          >
            {t('AttachmentModal__SuggestionsHistory--SeeAll', {
              suggestions: orderedRequests.length,
            })}
          </p>
        )}
        <Modal
          renderHeading="Publishers"
          isOpen={isModalOpen}
          contentLabel="Publishers"
          onRequestClose={() => setIsModalOpen(false)}
          theme={{ large: true, noPadding: true }}
        >
          <div className="max-h-45 overflow-y-scroll px-3 py-2">
            {orderedRequests.map((r) => {
              return (
                <RequestItem
                  key={r.id}
                  request={r}
                  suggestion={suggestionsById[r.suggestion.id]}
                />
              );
            })}
          </div>
        </Modal>
      </InfoCard>
    </>
  );
}
