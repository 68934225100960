import React from 'react';
import isEqual from 'lodash/isEqual';
import Editor from 'draft-js-plugins-editor';
import { FormattedMessage } from 'react-intl';
import { EditorState, ContentState } from 'draft-js';
import styled from 'styled-components';
import { Form, QTooltip, RemainingChars } from 'components';
import { colors, grid } from 'styles/theme';
import { IconNote } from 'icons';
import Suggestion from 'types/Suggestion';
import { mixins } from 'styles';

interface Props {
  suggestions: Suggestion[];
  onChange: (value?: string) => void;
}

interface State {
  value: string;
  editorState: EditorState;
  focused: boolean;
}

const Wrapper = styled.div`
  ${mixins.input};
  display: flex;
  padding: ${grid(0.5)};
  height: auto;
  min-height: ${grid(4.5)};
`;

const Icon = styled.div`
  margin-right: ${grid(0.5)};
  padding: ${grid(0.5)};
  width: ${grid(3)};
  height: ${grid(3)};
  color: ${colors.lightIcon};

  svg {
    display: block;
    width: 100%;
    height: 100%;
  }
`;

const EditorWrapper = styled.div`
  position: relative;
  top: -1px;
  flex: 1;
  margin-top: ${grid(0.25)};
  padding-right: ${grid(1)};
  line-height: ${grid(2.5)};
  color: ${colors.darkText};

  ${(props: any) => props.theme.color === 'dark' && `color: ${colors.white}`};

  .public-DraftEditorPlaceholder-root {
    width: 100%;
    color: ${colors.lightText};
  }
`;

export default class Instructions extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      focused: false,
      ...this.getInitialState(),
    };
  }

  componentDidUpdate(prevProps: Props) {
    const prevIds = prevProps.suggestions.map((s) => s.id);
    const currentIds = this.props.suggestions.map((s) => s.id);
    if (!isEqual(prevIds, currentIds)) {
      this.setState(this.getInitialState());
    }
  }

  getInitialState = () => {
    const { suggestions } = this.props;
    const value = suggestions.length === 1 ? suggestions[0].instructions : '';

    return {
      value: value || '',
      editorState: EditorState.createWithContent(
        ContentState.createFromText(value || '')
      ),
    };
  };

  onChange = (editorState: EditorState) => {
    const content = editorState.getCurrentContent();
    const oldContent = this.state.editorState.getCurrentContent();
    const value = content.getPlainText();

    this.setState({ editorState, value }, () => {
      if (content !== oldContent) this.props.onChange(value);
    });
  };

  render() {
    const { suggestions } = this.props;
    const { editorState, value } = this.state;
    const remainingChars = 900 - value.length;

    const isContentRequest =
      suggestions.length === 1 &&
      suggestions[0].actionableType !== 'Suggestion';

    if (isContentRequest && !suggestions[0].mediaType) return null;

    const suggestionPlaceholder =
      suggestions.length === 1 ? (
        <FormattedMessage id="SuggestionForm__InstructionsPlaceholder" />
      ) : (
        <FormattedMessage
          id="SuggestionForm__InstructionsMultiPlaceholder"
          values={{ count: suggestions.length }}
        />
      );

    const placeholder = isContentRequest ? (
      <FormattedMessage
        id={`SuggestionForm__InstructionsPlaceholder--${suggestions[0].mediaType}`}
      />
    ) : (
      suggestionPlaceholder
    );

    return (
      <Form.Field>
        <Form.Label bold>
          {isContentRequest ? (
            <FormattedMessage id="SuggestionForm__Instructions" />
          ) : (
            <>
              <FormattedMessage id="SuggestionForm__MessageToPublisher" />
              <QTooltip
                content={
                  <FormattedMessage id="SuggestionForm__InstructionsExplanation" />
                }
              />
            </>
          )}
        </Form.Label>
        <Wrapper>
          <Icon>
            <IconNote />
          </Icon>
          <EditorWrapper data-qa="suggestion-form-instructions-field">
            <Editor
              tabIndex="0"
              editorState={editorState}
              onChange={this.onChange}
              onFocus={() => this.setState({ focused: true })}
              onBlur={() => this.setState({ focused: false })}
              placeholder={placeholder}
            />
            <RemainingChars remainingChars={remainingChars} />
          </EditorWrapper>
        </Wrapper>
      </Form.Field>
    );
  }
}
