import React from 'react';
import { routes, routeFor } from 'helpers';
import { IconSmartSuggestion } from 'icons';
import LinkIf from 'components/LinkIf';
import clsx from 'clsx';
import ContentRoutingRuleJoin from 'types/ContentRoutingRuleJoin';
import { useIntl } from 'hooks';

interface Props {
  joins: ContentRoutingRuleJoin[];
  hideSmartSuggestionIcon?: boolean;
}

export default function ContentRoutingRuleJoinList(props: Props) {
  const { joins, hideSmartSuggestionIcon } = props;
  const { t } = useIntl();
  return (
    <>
      {joins.map((record) => {
        const rule = record.contentRoutingRule;
        const integrationType =
          rule?.contentIntegration?.integrationType || record.integrationType;
        const label = rule?.label || record.label;
        const isDeleted = !rule;
        return (
          <div
            key={record.id}
            className={clsx('mb-1.5 leading-tight last:mb-0', {
              'opacity-50': isDeleted,
            })}
          >
            <div className="flex items-center">
              <LinkIf
                condition={!!rule}
                tagName="span"
                className={clsx('text-14 text-dark', {
                  'hover:underline': !isDeleted,
                })}
                to={`${routeFor(
                  routes.settings.integrations.detail,
                  integrationType
                )}#rule-${rule?.id}`}
              >
                {label}
                {isDeleted && (
                  <span>
                    {' '}
                    {t('AttachmentModal__ContentRoutingRuleDeleted')}
                  </span>
                )}
              </LinkIf>

              {rule?.action === 'create_smart_suggestions' &&
                !hideSmartSuggestionIcon && (
                  <IconSmartSuggestion className="ml-0.5 block h-2 w-2 text-light" />
                )}
            </div>

            <div className="text-12">
              {t(`Integrations--${integrationType}`)}
            </div>
          </div>
        );
      })}
    </>
  );
}
