import React from 'react';
import Recipient from 'types/Recipient';
import { contactMethodIcons } from 'icons';
import { useIntl } from 'hooks';
import { getFormattedContactMethod } from 'helpers';
import { InvitedPublisherUser } from 'types/PublisherUser';

interface Props {
  recipient: Recipient;
  onAddRecipient: (recipient: Recipient | Recipient[]) => void;
}

export default function UserRecipient(props: Props) {
  const { recipient, onAddRecipient } = props;
  const { t } = useIntl();
  const publisherUser = recipient.publisherUser as InvitedPublisherUser;

  if (!publisherUser) {
    throw new Error('[UserRecipient] Recipient must have a publisherUser');
  }

  const Icon = contactMethodIcons[publisherUser.inviteProtocol];

  return (
    <button
      className="group"
      onClick={() => onAddRecipient({ ...recipient, protocol: 'invite' })}
    >
      <span className="flex items-center">
        <span className="text-dark group-hover:underline">
          {getFormattedContactMethod({
            protocol: publisherUser.inviteProtocol,
            value: publisherUser.inviteContactValue,
          })}
        </span>

        {Icon && (
          <span className="text-light group-hover:text-dark">
            <Icon className={`bump-down-1.5 ml-0.5 h-1.75 w-1.75`} />
          </span>
        )}

        <span className="ml-0.5 text-12 text-light">
          ({t('RecipientsSearch__InvitationPending')})
        </span>
      </span>
    </button>
  );
}
