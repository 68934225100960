import { useEffect, useRef } from 'react';

export default function useBodyClassName(className: string) {
  const prevClassName = useRef(className);

  useEffect(() => {
    document.body.classList.remove(prevClassName.current);
    document.body.classList.add(className);
    prevClassName.current = className;
    return () => {
      document.body.classList.remove(className);
    };
  }, [className]);
}
