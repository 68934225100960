import React from 'react';
import { IconFacebook, IconInstagram } from 'icons';
import { colors } from 'styles/theme';
import { FormattedMessage } from 'react-intl';
import OauthIdentity from 'types/OauthIdentity';
import FacebookPage from 'types/FacebookPage';
import { Link, useLocation } from 'react-router-dom';

interface Props {
  account: OauthIdentity;
  facebookPage?: FacebookPage;
  onRemove: () => void;
  onReconnect: () => void;
  isInvalid: boolean;
}

export default function LinkedFacebookAccount(props: Props) {
  const { account, facebookPage, onRemove, onReconnect, isInvalid } = props;

  const location = useLocation();
  const facebookPageSelectorRoute = `${location.pathname}/facebook-pages`;

  return (
    <div className="inline-block">
      <div
        className={`flex items-start rounded rounded-b-none border border-solid px-1.5 py-1 ${
          isInvalid ? 'border-red bg-errorBg' : 'border-default'
        }`}
      >
        <IconFacebook
          className="bump-down-2 mr-1 h-2 w-2"
          style={{ color: colors.facebook }}
        />{' '}
        <div className="mr-4 leading-snug">
          {!!facebookPage ? (
            <>
              {isInvalid ? (
                <div>
                  <FormattedMessage id="LinkedAccounts__TokenExpired" />{' '}
                  <a
                    href={facebookPage.url}
                    target="_blank"
                    rel="noreferrer noopener"
                    className="text-dark hover:underline"
                  >
                    {facebookPage.name}
                  </a>
                </div>
              ) : (
                <div>
                  <FormattedMessage id="LinkedAccounts__ConnectedFacebookPage" />{' '}
                  <a
                    href={facebookPage.url}
                    target="_blank"
                    rel="noreferrer noopener"
                    className="text-dark hover:underline"
                  >
                    {facebookPage.name}
                  </a>
                </div>
              )}

              <div className="text-12">
                <FormattedMessage
                  id="LinkedAccounts__ViaAccount"
                  values={{
                    name: <span className="text-dark">{account.name}</span>,
                  }}
                />
              </div>
            </>
          ) : (
            <>
              <FormattedMessage id="LinkedAccounts__NoFacebookPage" />
              <div className="text-12">
                <FormattedMessage id="LinkedAccounts__LinkedAs" />{' '}
                {account.name}
                {!isInvalid && (
                  <button
                    className="ml-0.5 inline-block text-socialiePink hover:underline"
                    onClick={onRemove}
                  >
                    <FormattedMessage id="LinkedAccounts__Unlink" />
                  </button>
                )}
              </div>
            </>
          )}
        </div>
        <div className="bump-up-1 ml-auto self-center">
          {isInvalid && (
            <button
              className="text-socialiePink hover:underline"
              onClick={onReconnect}
            >
              <FormattedMessage id="LinkedAccounts__Reconnect" />
            </button>
          )}

          {!isInvalid && !!facebookPage && (
            <button
              className="text-socialiePink hover:underline"
              onClick={onRemove}
            >
              <FormattedMessage id="LinkedAccounts__Unlink" />
            </button>
          )}

          {!isInvalid && !facebookPage && (
            <Link
              className="text-socialiePink hover:underline"
              to={facebookPageSelectorRoute}
            >
              <FormattedMessage id="LinkedAccounts__Connect" />
            </Link>
          )}
        </div>
      </div>

      <InstagramStatus
        isConnected={!!facebookPage && !!facebookPage.instagramBusinessId}
        facebookPageSelectorRoute={facebookPageSelectorRoute}
        isInvalid={isInvalid}
      />
    </div>
  );
}

interface InstagramStatusProps {
  isConnected: boolean;
  facebookPageSelectorRoute: string;
  isInvalid: boolean;
}

function InstagramStatus(props: InstagramStatusProps) {
  const { isInvalid, isConnected, facebookPageSelectorRoute } = props;
  const bgClass =
    isConnected && !isInvalid
      ? 'border-default bg-white'
      : isInvalid
      ? 'border border-solid border-red bg-redBg'
      : 'border-default bg-greyBg';
  const iconClass = isConnected ? 'text-instagram' : 'text-light';

  return (
    <div
      className={`flex items-start rounded rounded-t-none border-t-0 px-1.5 py-1 ${bgClass}`}
    >
      <IconInstagram className={`bump-down-2 mr-1 h-2 w-2 ${iconClass}`} />

      <div className="mr-2 leading-snug">
        {isConnected && !isInvalid ? (
          <FormattedMessage
            id="LinkedAccounts__InstagramConnected"
            values={{
              connected: (
                <span className="text-dark">
                  <FormattedMessage id="LinkedAccounts__Connected" />
                </span>
              ),
            }}
          />
        ) : (
          <>
            <FormattedMessage
              id="LinkedAccounts__InstagramNotConnected"
              values={{
                notConnected: (
                  <span className="text-dark">
                    <FormattedMessage id="LinkedAccounts__NotConnected" />
                  </span>
                ),
              }}
            />

            {!isConnected && !isInvalid && (
              <div className="text-12">
                <FormattedMessage id="LinkedAccounts__InstagramNotConnectedInstructions" />
              </div>
            )}
          </>
        )}
      </div>

      {!isConnected && !isInvalid && (
        <Link
          to={facebookPageSelectorRoute}
          className="bump-up-1 ml-auto self-center text-socialiePink hover:underline"
        >
          <FormattedMessage id="LinkedAccounts__Connect" />
        </Link>
      )}
    </div>
  );
}
