import React, { useRef } from 'react';
import { Option } from './SuggesterSwitcherContainer';
import styled, { ThemeProvider } from 'styled-components';
import { grid, colors } from 'styles/theme';
import { Avatar, FlyoutMenu, Form } from 'components';
import { IconSearch, IconArrowBack } from 'icons';
import { media } from 'styles';
import { useIntl } from 'hooks';

interface Props {
  options: Option[];
  switchSuggester: (id: number) => void;
  logoUrl?: string;
  accountName: string;
  searchValue: string;
  changeSearchValue: (value: string) => void;
}

// prettier-ignore
const Wrapper = styled.button.attrs({ type: 'button' })`
  display: flex;
  position: relative;
  align-items: center;
  margin-right: ${grid(1)};
  outline: none;
  background-color: ${colors.grey3};
  cursor: default;
  padding: 0 ${grid(1.5)};
  padding-right: ${grid(1)};
  height: 100%;

  ${({ theme }: any) => theme.hasMultipleSuggesters && 'cursor: pointer;'}

  > span {
    display: flex;
    align-items: center;
  }

  &:focus,
  &:active {
    outline: none;
  }

  &::after {
    display: block;
    position: absolute;
    top: 0;
    right: -${grid(0.5)};
    bottom: 0;
    transform: skew(8deg);
    background-color: ${colors.grey3};
    width: ${grid(1)};
    content: '';
  }

  &:hover,
  &:hover::after {
    background-color: ${colors.grey4};
  }

  ${(props: any) => props.isOpen && `
    background-color: ${colors.grey4};
    &::after {
      background-color: ${colors.grey4};
    }
  `}

  ${media.tablet`
    margin-right: ${grid(2)};
  `}

  ${media.laptop`
    padding: 0 ${grid(2.5)};
    padding-right: ${grid(1.5)};
  `}
  ;

` as any;

const AvatarWrapper = styled.div`
  display: block;
  flex-shrink: 0;
  width: ${grid(4)};
  height: ${grid(4)};
  background: ${colors.white};
  border-radius: 50%;

  ${media.laptop`
    width: ${grid(6)};
    height: ${grid(6)};
  `};

  img {
    background: ${colors.white};
  }
`;

const Search = styled.div`
  margin-bottom: ${grid(1)};
  padding: ${grid(1)};
`;

const SuggesterOption = styled.button`
  display: flex;
  align-items: center;
  padding: ${grid(0.5)} ${grid(1)};
  color: ${colors.darkText};
  width: 100%;

  ${(props: any) => props.child && `padding-left: ${grid(3)}`};

  > div {
    flex-shrink: 0;
    margin-right: ${grid(1)};
    width: ${grid(4)};
    height: ${grid(4)};
  }

  &:hover,
  &:focus {
    background-color: ${colors.grey8};
    outline: none;
  }
` as any;

export default function SuggesterSwitcher(props: Props) {
  const inputRef = useRef<HTMLInputElement>(null);
  const hasMultipleSuggesters = props.options.length > 1 || !!props.searchValue;
  const { t } = useIntl();

  const focusInput = () => {
    inputRef.current && inputRef.current.focus();
  };

  return (
    <ThemeProvider theme={{ hasMultipleSuggesters }}>
      <FlyoutMenu
        onOpened={focusInput}
        renderButton={(onClick, isOpen) => (
          <Wrapper
            onClick={hasMultipleSuggesters ? onClick : () => {}}
            isOpen={isOpen}
          >
            <span>
              <AvatarWrapper>
                <Avatar noBorder src={props.logoUrl} />
              </AvatarWrapper>

              <div className="flex items-center text-white">
                <div className="ml-1 hidden whitespace-nowrap text-bump-1 desktop:block">
                  {props.accountName}
                </div>
                {hasMultipleSuggesters && (
                  <IconArrowBack
                    className="bump-down-1 ml-0.5 block h-2.5 w-2.5"
                    style={{ transform: 'rotate(-90deg)' }}
                  />
                )}
              </div>
            </span>
          </Wrapper>
        )}
        renderHeading={
          <Search>
            <Form.TextInput
              icon={<IconSearch />}
              small
              ref={inputRef}
              placeholder={t('SuggesterSwitcher__SearchPlaceholder')}
              value={props.searchValue}
              onChange={(e) => props.changeSearchValue(e.target.value)}
            />
          </Search>
        }
      >
        {props.options.map((option) => {
          if (!option.accountConfiguration.logoUrl && !option.accountName) {
            return null;
          }

          return (
            <SuggesterOption
              key={option.id}
              onClick={() => props.switchSuggester(option.id)}
              child={option.child}
            >
              <div>
                <Avatar
                  noBorder
                  noBg
                  src={option.accountConfiguration.logoUrl}
                />
              </div>
              {option.accountName}
            </SuggesterOption>
          );
        })}
      </FlyoutMenu>
    </ThemeProvider>
  );
}
