import styled from 'styled-components';
import { grid, colors } from 'styles/theme';

const Wrapper = styled.div`
  background: ${colors.white};
  max-height: ${grid(50)};
  overflow: auto;
  text-align: left;
`;

export default Wrapper;
