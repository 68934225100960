import React, { useRef, useState } from 'react';
import includes from 'lodash/includes';
import flatten from 'lodash/flatten';
import ReactSelect, { Creatable } from 'react-select';
import { Styles as ReactSelectStyles } from 'react-select/lib/styles';
import SelectProps from 'types/SelectProps';
import SelectOption from 'types/SelectOption';
import { ActionMeta, InputActionMeta } from 'react-select/lib/types';
import { useStyledTheme } from 'hooks';
import {
  Control,
  MultiValue,
  Option,
  SingleValue,
  DropdownIndicator,
} from './components';
import { colors, grid } from 'styles/theme';

interface Props extends SelectProps {
  creatable?: boolean;
  complexValues?: boolean;
  onCreateOption?: (val: any) => void;
  pillProps?: any;
  additionalStyles?: any;
  theme?: any;
  'data-qa'?: string;
}

export default function Select(props: Props) {
  const selectRef = useRef(null);
  const [inputValue, setInputValue] = useState('');

  const handleChange = (values?: any[] | any, meta?: ActionMeta) => {
    const { onChange, creatable, complexValues } = props;
    if (!values) return onChange(null);

    // Prepend any created values with NEW__ so that the server can handle them
    if (creatable) {
      if (Array.isArray(values)) {
        if (complexValues) {
          return onChange(
            values.map((v) =>
              !v.__isNew__ || (v.value && v.value.match('NEW__'))
                ? v
                : { label: v.label, value: `NEW__${v.value}` }
            )
          );
        }

        return onChange(
          values.map((v) =>
            !v.__isNew__ || (v.value && v.value.match('NEW__'))
              ? v.value
              : `NEW__${v.value}`
          )
        );
      }

      // Single-select creatable
      const optionValues = props.options.map((o) => o.value);

      if (!includes(optionValues, values.value)) {
        return onChange(
          complexValues
            ? { value: `NEW__${values.value}`, label: values.value }
            : `NEW__${values.value}`
        );
      }
    }

    if (props.complexValues) return onChange(values);
    if (Array.isArray(values))
      return onChange(
        values.map((v) => v.value),
        meta
      );
    if (!Array.isArray(values)) return onChange(values.value);
  };

  const handleInputChange = (value: string, meta: InputActionMeta) => {
    if (meta.action !== 'menu-close') {
      setInputValue(value);
      if (props.onInputChange) props.onInputChange(value, meta);
    }

    return value;
  };

  const defaultComponents = {
    Control,
    MultiValue,
    Option,
    DropdownIndicator,
    SingleValue,
    IndicatorSeparator: null,
  };

  const theme = useStyledTheme();

  const additionalStyles = props.additionalStyles || ({} as any);

  const defaultStyles: Partial<ReactSelectStyles> = {
    menuPortal: (styles) => ({
      ...styles,
      zIndex: 9999,
      ...additionalStyles.menuPortal,
    }),
    menu: (base) => ({
      ...base,
      boxShadow: '0 2px 4px 0 rgba(0,0,0,0.12), 0 2px 8px 0 rgba(0,0,0,0.16)',
      borderColor: colors.border,
      backgroundColor: colors.white,
      zIndex: 9999,
      ...additionalStyles.menu,
    }),
    control: (styles, state) => ({
      ...styles,
      display: 'flex',
      boxShadow: state.isFocused ? `0 0 6px ${colors.boxShadow}` : 'none',
      borderWidth: '1px',
      borderStyle: 'solid',
      borderRadius: '4px',
      backgroundColor:
        theme.color === 'dark'
          ? colors.grey1
          : props.isDisabled
          ? colors.grey8
          : colors.white,
      borderColor:
        theme.color === 'dark'
          ? colors.inverseBorder
          : state.isFocused
          ? colors.focus
          : colors.border,
      ...additionalStyles.control,
    }),
    placeholder: (styles) => ({
      ...styles,
      color: colors.lightText,
      ...additionalStyles.placeholder,
    }),
    valueContainer: (styles) => ({
      ...styles,
      overflow: 'hidden',
      ...additionalStyles.valueContainer,
    }),
    input: (styles) => ({
      ...styles,
      height: grid(3),
      color: theme.isInputValueInvalid
        ? colors.error
        : theme.color === 'dark'
        ? colors.white
        : colors.darkText,
      ...additionalStyles.input,
    }),
  };

  let options = [...(props.options || [])];
  const SelectComponent = (props.creatable ? Creatable : ReactSelect) as any;

  if (props.creatable && props.value) {
    const optionValues = options.map((o) => o.value);
    let createdOptions: SelectOption[] = [];
    if (props.isMulti) {
      createdOptions = (props.value as any[])
        .filter((v) => !includes(optionValues, v))
        .map((v) => ({ value: v, label: v }));
    } else if (!includes(optionValues, props.value)) {
      const { value } = props;
      createdOptions = [{ value, label: value }];
    }
    options = [...createdOptions, ...(options || [])];
  }

  let { value } = props;

  if (!props.complexValues) {
    if (props.isMulti) {
      const stringValues = (props.value || []).map((v: any) => `${v}`);
      const allOptions = flatten(
        options.map((opt) => (opt.options ? opt.options : opt))
      );
      value = allOptions.filter((o) => includes(stringValues, `${o.value}`));
    } else {
      value = options.find((o) => `${o.value}` === `${props.value}`);
    }
  }

  const controlledInputValue =
    props.inputValue || props.inputValue === '' ? props.inputValue : inputValue;

  return (
    <div data-qa={props['data-qa']}>
      <SelectComponent
        {...props}
        ref={selectRef}
        isValidNewOption={(
          controlledInputValue: any,
          selectValue: any,
          selectOptions: SelectOption[]
        ) => {
          const isNotDuplicated = !includes(
            selectOptions.map((option) => option.label),
            controlledInputValue
          );
          const isNotEmpty = controlledInputValue !== '';
          return isNotEmpty && isNotDuplicated;
        }}
        styles={{ ...defaultStyles, ...props.styles }}
        menuPosition="absolute"
        menuPlacement={props.menuPlacement || 'bottom'}
        options={options}
        value={value}
        onInputChange={handleInputChange}
        onChange={handleChange}
        components={{
          ...defaultComponents,
          ...props.components,
        }}
        inputValue={controlledInputValue}
      />
    </div>
  );
}
