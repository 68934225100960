import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { Form, Button, WithIntl, AuthForm } from 'components';
import { grid } from 'styles/theme';
import { routes, flashMessage } from 'helpers';
import { startPasswordReset } from 'api/auth';

interface State {
  email: string;
  didSubmit: boolean;
  isSubmitting: boolean;
}

const Heading = styled.div`
  margin-bottom: ${grid(2)};
  text-align: center;
  line-height: ${grid(2)};

  h1 {
    margin-bottom: ${grid(1)};
  }
`;

export default class ForgotPassword extends React.Component<{}, State> {
  state = { email: '', didSubmit: false, isSubmitting: false };

  submitForm = async () => {
    this.setState({ isSubmitting: true });

    try {
      await startPasswordReset(this.state.email);
    } catch {
      flashMessage('ForgotPassword__Error');
    }

    this.setState({ didSubmit: true });
  };

  render() {
    const { email, didSubmit, isSubmitting } = this.state;

    return (
      <AuthForm>
        <Heading>
          <h1>
            <FormattedMessage id="ForgotPassword__Heading" />
          </h1>

          {didSubmit ? (
            <div>
              <FormattedMessage id="ForgotPassword__Success" />{' '}
              <Link to={routes.auth.login}>
                <FormattedMessage id="ForgotPassword__LogIn" />
              </Link>
            </div>
          ) : (
            <FormattedMessage id="ForgotPassword__Description" />
          )}
        </Heading>

        {!didSubmit && (
          <Form onSubmit={this.submitForm}>
            <Form.Field>
              <WithIntl>
                {(_, t) => (
                  <Form.TextInput
                    value={email}
                    placeholder={t('ForgotPassword__Email')}
                    onChange={(e) => this.setState({ email: e.target.value })}
                  />
                )}
              </WithIntl>
            </Form.Field>
            <div className="mb-1">
              <Button filledBg type="submit" disabled={isSubmitting || !email}>
                <FormattedMessage
                  id={isSubmitting ? 'Global__Loading' : 'Global__Submit'}
                />
              </Button>
            </div>
            <div className="text-center">
              <Link
                className="text-socialiePink hover:underline"
                to={routes.auth.login}
              >
                <FormattedMessage id="ResetPassword__ExpiredToken--ReturnToSignIn" />
              </Link>
            </div>
          </Form>
        )}
      </AuthForm>
    );
  }
}
