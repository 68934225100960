import React from 'react';
import TwilioMessage from 'types/TwilioMessage';
import File from './File';

interface Props {
  message: TwilioMessage;
}

const Media = ({ message }: Props) => (
  <>
    {message.files.map((file) => (
      <File key={file.id} url={file.url} contentType={file.contentType} />
    ))}

    {message.attachments.map((attachment) => (
      <File
        key={attachment.id}
        url={attachment.mediaUrl}
        contentType={attachment.mediaContentType}
      />
    ))}
  </>
);

export default Media;
