import { Form, QTooltip } from 'components';
import { useIntl } from 'hooks';
import { IconLandscape, IconOrientationAny, IconPortrait } from 'icons';
import React, { useState } from 'react';
import Suggestion from 'types/Suggestion';

interface Props {
  suggestions: Suggestion[];
  onChange: (orientation: string | null) => void;
}

export default function Orientation(props: Props) {
  const { onChange, suggestions } = props;

  const { t } = useIntl();

  const [orientationType, setOrientationType] = useState<null | string>(
    suggestions[0].orientation || null
  );

  const handleChange = (type: string | null) => {
    setOrientationType(type);
    onChange(type);
  };

  return (
    <div className="mt-1 mb-3">
      <div className="mb-0.5 flex items-center space-x-1">
        <p className="text-14 font-semibold text-darkText">
          {t('Orientation__Heading')}
        </p>
        <QTooltip content={t('Orientation__Disclaimer')} />
      </div>
      <Form.ToggleGroup>
        <Form.ToggleGroup.Option
          isSelected={orientationType === 'portrait'}
          onSelect={() => handleChange('portrait')}
          title={t('Orientation__Type--portrait')}
          icon={IconPortrait}
        />

        <Form.ToggleGroup.Option
          isSelected={orientationType === 'landscape'}
          onSelect={() => handleChange('landscape')}
          title={t('Orientation__Type--landscape')}
          icon={IconLandscape}
        />

        <Form.ToggleGroup.Option
          isSelected={orientationType === null}
          onSelect={() => handleChange(null)}
          title={t('Orientation__Type--null')}
          icon={IconOrientationAny}
        />
      </Form.ToggleGroup>
    </div>
  );
}
