import React from 'react';
import { useIntl } from 'hooks';
import FilterPill from './FilterPill';
import Publisher from 'types/Publisher';

interface Props {
  value: number[];
  publishers: Array<Pick<Publisher, 'id' | 'name' | 'image'>>;
  msg?: string;
  onRemove: (id: number) => void;
}

export default function PublisherFilterPills(props: Props) {
  const { value, publishers, msg, onRemove } = props;
  const { t } = useIntl();

  return (
    <>
      {value.map((id) => {
        const publisher = publishers.find((p) => Number(p.id) === Number(id));
        if (!publisher) return null;
        const label = msg ? t(msg, { name: publisher.name }) : publisher.name;

        return (
          <FilterPill
            key={id}
            image={publisher.image}
            label={label}
            onRemove={() => onRemove(id)}
          />
        );
      })}
    </>
  );
}
