import { css } from 'styled-components';
import camelize from 'camelize';
export const breakpoints = {
  max: 1680,
  large: 1400,
  desktop: 1170,
  laptop: 992,
  tablet: 768,
  phablet: 376,
};

// iterate through the sizes and create a media template
export default Object.keys(breakpoints).reduce((accumulator, label) => {
  // use em in breakpoints to work properly cross-browser and support users
  // changing their browsers font-size: https://zellwk.com/blog/media-query-units/
  const emSize = breakpoints[label] / 16;
  return {
    ...accumulator,
    [label]: (...args: any[]) => css`
      @media (min-width: ${emSize}em) {
        ${(css as any)(...args)};
      }
    `,
    [camelize(`max_${label}`)]: (...args: any[]) => css`
      @media (max-width: ${emSize}em) {
        ${(css as any)(...args)};
      }
    `,
  };
}, {}) as { [key: string]: any };
