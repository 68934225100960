import { connect } from 'react-redux';
import { IntlProvider, addLocaleData } from 'react-intl';

// import fr from 'react-intl/locale-data/fr';
import en from 'react-intl/locale-data/en';

// addLocaleData(fr);
addLocaleData(en);

const mapStateToProps = (state: any) => {
  const { messages } = state.locales;
  return { messages, locale: state.locales.lang, key: state.locales.lang };
};

export default connect<any, any, any>(mapStateToProps)(IntlProvider);
