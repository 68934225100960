import React, { useRef, useState } from 'react';
import { useIntl } from 'hooks';
import Button from 'components/Button';
import Modal from 'components/Modal';
import { FormattedMessage } from 'react-intl';
import AvatarEditor from 'react-avatar-editor';
import { IconPhoto } from 'icons';
import { SliderWrapper } from './components/Slider';
import { colors } from 'styles/theme';
import { State } from '../../brandReducer';

interface PhotoEditModalProps {
  image: State['image'];
  onUpdateImage: (updates: Partial<State['image']>) => void;
  onRequestClose: () => void;
}

export default function PhotoEditModal({
  image,
  onUpdateImage,
  onRequestClose,
}: PhotoEditModalProps) {
  const { t } = useIntl();

  const [geometry, setGeometry] = useState<
    Pick<State['image'], 'position' | 'currentScale'>
  >({
    position: image.position,
    currentScale: image.currentScale,
  });

  const editorRef = useRef<AvatarEditor>();

  const handleApplyEdits = async () => {
    if (editorRef.current) {
      const canvas = editorRef.current.getImageScaledToCanvas();
      const ctx = canvas.getContext('2d');
      if (ctx) {
        ctx.globalCompositeOperation = 'destination-over';
        ctx.fillStyle = '#ffffff';
        ctx.fillRect(0, 0, 450, 450);
      }
      const croppedPreviewUrl = canvas.toDataURL(image.mimeType, 0.8);
      onUpdateImage({
        croppedPreviewUrl,
        ...geometry,
        didApplyEdits: true,
      });
      onRequestClose();
    }
  };

  const handleCancel = () => {
    if (image.didUpload && !image.didApplyEdits) {
      onUpdateImage({
        previewUrl: image.originalUrl,
        croppedPreviewUrl: image.originalUrl,
        didUpload: false,
      });
    }
    onRequestClose();
  };

  return (
    <Modal
      isOpen={true}
      onRequestClose={onRequestClose}
      contentLabel={t('Brand__LogoEditModal--Heading')}
      renderHeading={t('Brand__LogoEditModal--Heading')}
      theme={{ photoEditModal: true, noPadding: true }}
    >
      <div className="max-h-56.25 relative w-full overflow-hidden bg-white">
        <AvatarEditor
          ref={editorRef as any}
          borderRadius={225}
          image={image.previewUrl}
          onPositionChange={(position) =>
            setGeometry({ ...geometry, position })
          }
          disableBoundaryChecks
          position={geometry.position}
          width={450}
          height={450}
          style={{
            border: `5px solid ${colors.socialiePink}`,
            borderRadius: '50%',
          }}
          border={[0, 0] && 0}
          color={[255, 255, 255, 0.6]} // RGBA
          scale={geometry.currentScale}
        />
      </div>
      <div className="flex items-center justify-between p-2">
        <div className="flex items-center">
          <IconPhoto className="mt-1 mr-1 h-1.5 w-1.5 text-grey5" />
          <SliderWrapper>
            <input
              type="range"
              min="0.5"
              max="1.5"
              step="0.1"
              value={geometry.currentScale}
              onChange={(e) =>
                setGeometry({
                  ...geometry,
                  currentScale: Number(e.target.value),
                })
              }
            />
          </SliderWrapper>
          <IconPhoto className="mt-1 ml-1 h-2.5 w-2.5 text-grey5" />
        </div>
        <div>
          <div className="mr-1 inline">
            <Button inlineBlock cancel type="button" onClick={handleCancel}>
              <FormattedMessage id="Global__Cancel" />
            </Button>
          </div>

          <Button
            data-testid="submit"
            inlineBlock
            filledBg
            type="button"
            onClick={handleApplyEdits}
          >
            <FormattedMessage id="Brand__LogoEditModal--ActionButton" />
          </Button>
        </div>
      </div>
    </Modal>
  );
}
