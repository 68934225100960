import { OtherAction } from './global';
import DraftSuggestion from 'types/DraftSuggestion';

enum TypeKeys {
  CREATED = 'suggester/draftSuggestions/CREATED',
  UPDATED = 'suggester/draftSuggestions/UPDATED',
}

interface CreatedAction {
  type: TypeKeys.CREATED;
  payload: { draftSuggestion: DraftSuggestion };
}

interface UpdatedAction {
  type: TypeKeys.UPDATED;
  payload: { id: number | string; updates: Partial<DraftSuggestion> };
}

type ActionTypes = CreatedAction | UpdatedAction | OtherAction;

// Reducer
export interface State {
  byId: { [key: string]: DraftSuggestion };
  ids: string[];
}

const initialState: State = { byId: {}, ids: [] };

const draftSuggestionsReducer = (
  state: State = initialState,
  action: ActionTypes
) => {
  switch (action.type) {
    case TypeKeys.CREATED: {
      const { id } = action.payload.draftSuggestion;

      return {
        ...state,
        byId: {
          ...state.byId,
          [id]: action.payload.draftSuggestion,
        },
        ids: [...state.ids, id],
      };
    }

    case TypeKeys.UPDATED: {
      const { id, updates } = action.payload;
      return {
        ...state,
        byId: {
          ...state.byId,
          [id]: {
            ...state.byId,
            ...updates,
          },
        },
      };
    }

    default: {
      return state;
    }
  }
};

// Action creators
export const createDraftSuggestion = (draftSuggestion: DraftSuggestion) => ({
  type: TypeKeys.CREATED,
  payload: { draftSuggestion },
});

export const updateDraftSuggestion = (
  id: string,
  updates: Partial<DraftSuggestion>
) => ({
  type: TypeKeys.UPDATED,
  payload: { id, updates },
});

// Selectors
export const getDraftSuggestions = (state: { draftSuggestions: State }) =>
  state.draftSuggestions.ids.map((id) => state.draftSuggestions.byId[id]);

export default draftSuggestionsReducer;
