import React from 'react';
import isEqual from 'lodash/isEqual';
import { Location } from 'history';
import { withRouter, RouteComponentProps } from 'react-router-dom';

interface Context {
  lastLocation: Location | null;
  currentLocation: Location | null;
  locations: Location[];
}

const LastLocationContext = React.createContext({
  lastLocation: null,
  currentLocation: null,
  locations: [],
} as Context);

interface OwnProps {
  children: React.ReactNode;
}

type Props = OwnProps & RouteComponentProps<any>;

interface State {
  locations: Location[];
}

class LastLocationProvider extends React.Component<Props, State> {
  state = { locations: [this.props.location] };

  componentDidUpdate() {
    if (this.props.history.action === 'REPLACE') return;

    const { locations } = this.state;
    const lastLocation = locations[locations.length - 1];
    if (isEqual(this.props.location, lastLocation)) return;

    this.setState((state) => ({
      locations: [...state.locations, this.props.location],
    }));
  }

  render() {
    const { locations } = this.state;

    const context = {
      locations,
      currentLocation: locations[locations.length - 1],
      lastLocation: locations[locations.length - 2],
    };

    return (
      <LastLocationContext.Provider value={context}>
        {this.props.children}
      </LastLocationContext.Provider>
    );
  }
}

const lastLocationContextObject = {
  Provider: withRouter<OwnProps & RouteComponentProps<any>>(
    LastLocationProvider
  ),
  Consumer: LastLocationContext.Consumer,
};

export default lastLocationContextObject;
