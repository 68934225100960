import { ErrorDebug, Form } from 'components';
import gql from 'graphql-tag';
import { mapNodes } from 'helpers';
import { useIntl } from 'hooks';
import React, { useState } from 'react';
import { useApolloClient, useQuery } from 'react-apollo';
import PublishersIndexFilter from 'types/PublishersIndexFilter';
import RelayConnection from 'types/RelayConnection';
import SelectOption from 'types/SelectOption';
import { useDebounce } from 'use-debounce';

const PUBLISHERS_QUERY = gql`
  query AsyncSelectPublishers($filter: JSON, $limit: Int = 50) {
    publishers(filter: $filter, first: $limit) {
      edges {
        node {
          id
          name
          image
        }
      }
    }
  }
`;

interface QueryData {
  publishers: RelayConnection<{ id: number; name: string }>;
}

interface Props
  extends Omit<React.ComponentProps<typeof Form.Select>, 'value' | 'options'> {
  value: Array<SelectOption> | SelectOption | null;

  /** Any additional filters that should apply when searching,
   * e.g. { athlete: 'yes' }  */
  filter?: Partial<Omit<PublishersIndexFilter, 'name'>>;
}

export default function AsyncPublisherSelect(props: Props) {
  const { onChange, value, filter, ...selectProps } = props;
  const [inputValue, setInputValue] = useState('');
  const debouncedValue = useDebounce(inputValue, 250);
  const { t } = useIntl();

  const { data, loading, error } = useQuery<QueryData>(PUBLISHERS_QUERY, {
    variables: {
      filter: {
        ...filter,
        name: debouncedValue,
        sortField: 'publishers.displayName',
        sortDirection: 'ASC',
      },
    },
    skip: !inputValue,
  });

  if (error) return <ErrorDebug error={error} />;

  let publishers = mapNodes(data?.publishers?.edges ?? []);
  const options = publishers.map((p) => ({
    value: p.id,
    label: p.name || '',
    image: p.image,
  }));

  return (
    <Form.Select
      value={value}
      onChange={onChange}
      placeholder={t('AddPublisherToTagModal__Placeholder')}
      options={options}
      isClearable={false}
      onInputChange={(val) => setInputValue(val)}
      inputValue={inputValue}
      complexValues
      noOptionsMessage={({ inputValue }) =>
        loading
          ? t('Global__Loading')
          : inputValue
          ? t('Global__NoResults')
          : t('Global__TypeToSearch')
      }
      {...selectProps}
    />
  );
}

// Components using the AsyncPublisherSelect component will occasionally
// need to fetch publishers by ID, for example if the component is being used
// within a filter and the user arrives at the page with a set of publishers
// already selected via the URL query string. This hook will first try to pull
// the publishers from the cache and then fetch them only if they are not found.
export function usePublishersById(publisherIds: number[]) {
  const client = useApolloClient();
  const cachedPublishers = publisherIds
    .map((id) =>
      client.readFragment({
        id: `Publisher:${id}`,
        fragment: gql`
          fragment Publisher on Publisher {
            id
            name
            image
          }
        `,
      })
    )
    .filter(Boolean);

  const missingIds = publisherIds.filter(
    (id) => !cachedPublishers.find((p) => p.id === id)
  );

  const { data, loading, error } = useQuery<QueryData>(PUBLISHERS_QUERY, {
    variables: { filter: { id: missingIds }, limit: null },
    skip: !missingIds.length,
  });

  const loadedPublishers = mapNodes(data?.publishers?.edges ?? []);

  const publishers: Array<{ id: number; name: string; image?: string }> = [
    ...cachedPublishers,
    ...loadedPublishers,
  ];

  return { publishers, loading, error };
}
