import styled from 'styled-components';
import { grid } from 'styles/theme';
import arrowBackSvg from 'images/arrow-back-white.svg';

// prettier-ignore
const LightboxArrow = styled.button.attrs({ type: 'button' })`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  ${(props: any) => props.prev && `left: ${grid(-6)}`};
  ${(props: any) => props.next && `
    right: ${grid(-6)};
    transform: translateY(-50%) rotate(-180deg);
  `};
  background: url('${arrowBackSvg}') no-repeat;
  background-size: 100%;
  width: ${grid(4)};
  height: ${grid(4)};
` as any;

export default LightboxArrow;
