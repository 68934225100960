import gql from 'graphql-tag';
import apolloClient from '../apolloClient';
import PHOTO_ATTACHMENT_PROCESSED from 'graphql/mutations/photoAttachmentProcessed.graphql';

const photoAttachmentProcessed = (id: number | string, aspectRatio: number) => {
  apolloClient.writeFragment({
    id: `Attachment:${id}`,
    fragment: gql`
      fragment attachment on Attachment {
        aspectRatio
        status
      }
    `,
    data: { aspectRatio, status: 'processed', __typename: 'Attachment' },
  });

  apolloClient.mutate({
    mutation: PHOTO_ATTACHMENT_PROCESSED,
    variables: { id },
  });
};

export default photoAttachmentProcessed;
