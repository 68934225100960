import React, { useEffect } from 'react';
import Button from 'components/Button';
import Modal from 'components/Modal';
import { flashMessage } from 'helpers';
import { useIntl } from 'hooks';
import { useState } from 'react';
import { useMutation } from 'react-apollo';
import { useHistory, useLocation } from 'react-router-dom';
import { SavedFilterSetsProps } from './SavedFilterSets';
import { ADD_SAVED_FILTER_SET } from './queries';
import Form from 'components/Form';

interface Props {
  isOpen: boolean;
  onRequestClose: () => void;
  filter: SavedFilterSetsProps['filter'];
  filterContext: string;
}

export default function SavedFilterSetsCreateModal(props: Props) {
  const { isOpen, onRequestClose, filter, filterContext } = props;
  const { t } = useIntl();
  const [name, setName] = useState('');
  const history = useHistory();
  const location = useLocation();
  const [addSavedFilterSet, { loading }] = useMutation(ADD_SAVED_FILTER_SET, {
    variables: { name, data: filter, filterContext },
    refetchQueries: ['SavedFilterSets'],
    onCompleted: (data) => {
      if (data.addSavedFilterSet.errors) {
        console.error(data.addSavedFilterSet.errors);
        flashMessage('Global__UnexpectedError', { style: 'error' });
      } else {
        const id = data.addSavedFilterSet.savedFilterSet.id;
        const searchParams = new URLSearchParams(location.search);
        searchParams.set('savedFilterSetId', id);
        history.replace({ search: searchParams.toString() });
        onRequestClose();
      }
    },
  });

  useEffect(
    function resetNameOnOpen() {
      if (isOpen) setName('');
    },
    [isOpen]
  );

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      renderHeading={t('SavedFilterSets__CreateHeading')}
      contentLabel={t('SavedFilterSets__CreateHeading')}
    >
      <Form onSubmit={() => addSavedFilterSet()}>
        <Form.Field>
          <Form.Label>{t('SavedFilterSets__CreateLabel')}</Form.Label>
          <Form.TextInput
            value={name}
            autoFocus
            maxLength={255}
            onChange={(e) => setName(e.target.value)}
            placeholder={t('SavedFilterSets__CreatePlaceholder')}
            required
          />
        </Form.Field>

        <Modal.Actions>
          <Button onClick={onRequestClose} inlineBlock cancel type="button">
            {t('Global__Cancel')}
          </Button>

          <Button
            type="submit"
            loading={loading}
            disabled={loading}
            filledBg
            inlineBlock
          >
            {t('Global__Save')}
          </Button>
        </Modal.Actions>
      </Form>
    </Modal>
  );
}
