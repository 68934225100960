import React from 'react';
import isEqual from 'lodash/isEqual';
import debounce from 'lodash/debounce';
import includes from 'lodash/includes';
import styled, { ThemeProvider } from 'styled-components';
import { FormattedMessage } from 'react-intl';
import {
  EditorState,
  ContentState,
  Modifier,
  ContentBlock,
  SelectionState,
} from 'draft-js';
import Editor from 'draft-js-plugins-editor';
import createMentionPlugin from 'draft-js-mention-plugin';
import { connect } from 'react-redux';
import {
  startBatchSuggestionSaveOperation,
  stopBatchSuggestionSaveOperation,
} from 'redux/ducks/ui';
import updateDraft from 'graphql/operations/updateDraft';
import {
  GeotargetingModal,
  EmojiPicker,
  RemainingChars,
  OpenGraphPreview,
} from 'components';
import Suggestion from 'types/Suggestion';
import DraftMention from 'types/DraftMention';
import { platformIcons, IconTarget } from 'icons';
import { colors, grid } from 'styles/theme';
import {
  platformTitle,
  getRemainingChars,
  getSuggestionTargets,
} from 'helpers';
import EditorWrapper from './EditorWrapper';
import Mentions from './Mentions';
import GeotargetingValues from './GeotargetingValues';

interface OwnProps {
  suggestions: Suggestion[];
  platform: string;
  onChange?: () => void;
}

interface ConnectedActions {
  startSaving: (operation: string) => void;
  stopSaving: (operation: string) => void;
}

type Props = OwnProps & ConnectedActions;

interface State {
  plainText: string;
  mentions: string[];
  editorState: EditorState;
  focused: boolean;
  mentionSuggestions: any[];
  isGeotargetingModalOpen: boolean;
}

const OuterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${grid(2)};
`;

// prettier-ignore
const Wrapper = styled.div`
  transition: border 0.15s;
  margin-bottom: ${grid(1)};
  border: 1px solid ${colors.border};
  border-left: 4px solid ${colors.border};
  border-radius: 4px;

  ${(props: any) => props.theme.focused && `border-color: ${colors.focus}`};

  ${(props: any) => props.theme.color === 'dark' && `
    border-color: ${colors.inverseBorder};
    background-color: ${colors.grey1};
    color: ${colors.white};
  `};

  ${(props: any) => props.theme.hasText && `
    border-left: 4px solid ${colors[props.theme.platform]};
  `};

  ${(props: any) => props.theme.overCharacterLimit && `
    border-color: ${colors.error};
    border-bottom: ${grid(2.5)} solid ${colors.error};
  `};
` as any;

const TopRow = styled.div`
  display: flex;
  position: relative;
  align-items: flex-start;
  padding: ${grid(0.5)};
`;

// prettier-ignore
const Icon = styled.div`
  transition: color 0.15s;
  margin-right: ${grid(0.5)};
  padding: ${grid(0.5)};
  padding-left: 0;
  width: ${grid(3)};
  height: ${grid(3)};

  ${(props: any) => props.theme.hasText && `
    color: ${colors[props.theme.platform]};
  `};

  svg {
    display: block;
    width: 100%;
    height: 100%;
  }
`;

const GeotargetButton = styled.button.attrs({ type: 'button' })`
  position: relative;
  top: ${grid(0.5)};
  margin-right: ${grid(0.5)};
  width: ${grid(2)};
  height: ${grid(2)};
  color: ${colors.lightText};

  ${(props: any) => props.active && `color: ${colors.darkText}`};

  svg {
    display: block;
    width: 100%;
    height: 100%;
  }
` as any;

class CaptionField extends React.PureComponent<Props, State> {
  private editorRef: React.RefObject<any> = React.createRef();
  private mentionPlugin: any;
  private mentionPrefix = this.props.platform === 'twitter' ? '@' : '';

  constructor(props: Props) {
    super(props);
    this.updateDraft = debounce(this.updateDraft, 250);
    this.createMentionPlugin();

    this.state = {
      focused: false,
      mentionSuggestions: [],
      isGeotargetingModalOpen: false,
      ...this.getInitialState(),
    };
  }

  componentDidMount() {
    this.writeMentionsToEditor();
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    const prevIds = prevProps.suggestions.map((s) => s.id);
    const currentIds = this.props.suggestions.map((s) => s.id);
    const isSameSuggestion =
      prevIds.length === 1 &&
      currentIds.length === 1 &&
      prevIds[0] === currentIds[0];
    const draft =
      isSameSuggestion &&
      this.props.suggestions[0].drafts &&
      this.props.suggestions[0].drafts.find(
        (d) => d.platform === this.props.platform
      );
    const prevDraft =
      isSameSuggestion &&
      prevProps.suggestions[0].drafts &&
      prevProps.suggestions[0].drafts.find(
        (d) => d.platform === prevProps.platform
      );

    // Detect when something else changed the caption, e.g. using the
    // auto-add photo credits feature
    const textChanged =
      !this.state.focused &&
      isSameSuggestion &&
      prevState.plainText === this.state.plainText &&
      draft &&
      prevDraft &&
      draft.text !== this.state.plainText &&
      draft.text !== prevDraft.text;

    if (!isEqual(prevIds, currentIds) || textChanged) {
      this.setState(this.getInitialState(), () => this.writeMentionsToEditor());
      return;
    }
  }

  getInitialState = () => {
    const { suggestions } = this.props;
    let plainText: string = '';
    let mentions: string[] = [];

    if (suggestions.length === 1) {
      const suggestion = suggestions[0];

      const draft =
        suggestion.drafts &&
        suggestion.drafts.find((d) => d.platform === this.props.platform);

      if (draft) {
        plainText = draft.text || '';
        mentions = draft.mentions;
      }
    }

    const contentState = ContentState.createFromText(plainText);

    return {
      plainText,
      mentions,
      editorState: EditorState.createWithContent(contentState),
    };
  };

  writeMentionsToEditor() {
    if (!this.state.mentions || !this.state.mentions.length) return;
    const mentions: DraftMention[] = this.state.mentions.map((json) =>
      JSON.parse(json)
    );
    let editorState = this.state.editorState;
    const content = editorState.getCurrentContent();
    const blocks = content.getBlocksAsArray();

    blocks.forEach((block: any) => {
      const blockText = block.getText();
      mentions.forEach((mention) => {
        const prefix = this.mentionPrefix;
        const mentionName = `${prefix}${mention.name}`;
        const mentionIndex = blockText.indexOf(mentionName);

        if (mentionIndex > -1) {
          const start = mentionIndex;
          const end = mentionIndex + mentionName.length;

          editorState = this.addEntity(
            editorState,
            mention,
            block.getKey(),
            mentionName,
            start,
            end
          );
        }
      });
    });

    setTimeout(() => {
      this.setState({ editorState });
    });
  }

  addEntity(
    editorState: EditorState,
    tag: any,
    blockKey: string,
    value: string,
    start: number,
    end: number
  ): EditorState {
    const contentState = editorState.getCurrentContent();

    const entitySelection = new SelectionState({
      anchorOffset: start,
      anchorKey: blockKey,
      focusOffset: end,
      focusKey: blockKey,
      isBackward: false,
    });

    const mention = {
      id: tag.id,
      name: value,
      image: '',
      twitter_name: value,
      twitter_verified: false,
    };

    let contentStateWithEntity = contentState.createEntity(
      'mention',
      'IMMUTABLE',
      { mention }
    );

    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

    contentStateWithEntity = Modifier.applyInlineStyle(
      contentStateWithEntity,
      entitySelection,
      this.props.platform
    );

    const contentStateWithLink = Modifier.applyEntity(
      contentStateWithEntity,
      entitySelection,
      entityKey
    );

    return EditorState.push(editorState, contentStateWithLink, 'apply-entity');
  }

  getMentionsFromContent(content: ContentState) {
    const mentionsList: any[] = [];
    const blocks: ContentBlock[] = content.getBlocksAsArray();
    blocks.forEach((block: any) => {
      block.findEntityRanges(
        (value: any) => true,
        (start: number, end: number) => {
          const entityKey = block.getEntityAt(start);
          if (!entityKey) return;
          const entity = content.getEntity(entityKey.toString());
          const mention = entity.getData().mention;
          if (!mention) return;

          const index = { start, end };
          mentionsList.push({ mention, index, blockKey: block.getKey() });
        }
      );
    });
    return mentionsList;
  }

  getCurrentTags() {
    const content = this.state.editorState.getCurrentContent();
    const entities = this.getMentionsFromContent(content);
    return entities.map((entity: any) => ({
      name: entity.mention.name.replace(
        new RegExp(`^${this.mentionPrefix}`),
        ''
      ),
      tagId: entity.mention.id,
      startIndex: entity.index.start,
    }));
  }

  onChange = (editorState: EditorState) => {
    const content = editorState.getCurrentContent();
    const oldContent = this.state.editorState.getCurrentContent();
    const plainText = content.getPlainText();

    return new Promise((resolve, reject) => {
      this.setState({ editorState, plainText }, () => {
        if (content !== oldContent) {
          this.props.onChange?.();
          this.props.startSaving(this.props.platform);
          this.updateDraft();
        }
        resolve();
      });
    });
  };

  updateDraft = async () => {
    const { suggestions, platform } = this.props;
    const text = this.state.plainText;
    const mentions = this.getCurrentTags();
    const suggestionIds = suggestions.map((s) => s.id);
    await updateDraft({ text, mentions, platform, suggestionIds });
    this.props.stopSaving(this.props.platform);
  };

  createMentionPlugin = () => {
    if (!includes(['twitter', 'facebook'], this.props.platform)) return;
    this.mentionPlugin = createMentionPlugin({
      entityMutability: 'IMMUTABLE',
      mentionPrefix: this.mentionPrefix,
    });
  };

  selectEmoji = async (emoji: any) => {
    const editorState = this.state.editorState;
    const selection = editorState.getSelection();
    const contentState = editorState.getCurrentContent();
    const frag = Modifier.insertText(contentState, selection, emoji.native);
    await this.onChange(EditorState.push(editorState, frag, 'insert-fragment'));
    this.editorRef.current && this.editorRef.current.focus();
  };

  render() {
    const { platform, suggestions } = this.props;
    const { editorState, focused, plainText } = this.state;

    const remainingChars = getRemainingChars(plainText, platform);

    const targets =
      suggestions.length === 1 &&
      getSuggestionTargets(suggestions[0], platform);

    const placeholder =
      suggestions.length === 1 ? (
        <FormattedMessage
          id="CaptionField__Placeholder"
          values={{ platform: platformTitle(platform) }}
        />
      ) : (
        <FormattedMessage
          id="CaptionField__MultiPlaceholder"
          values={{
            count: suggestions.length,
            platform: platformTitle(platform),
          }}
        />
      );

    // Prep the MentionSuggestions component
    const plugins = [];
    if (this.mentionPlugin) plugins.push(this.mentionPlugin);

    const hasAttachments = !!suggestions[0].attachments.length;

    return (
      <ThemeProvider
        theme={(parentTheme: any) => ({
          ...parentTheme,
          platform,
          focused,
          overCharacterLimit: remainingChars < 0,
          hasText: !!plainText,
        })}
      >
        <OuterWrapper>
          <Wrapper>
            <TopRow>
              <Icon>{React.createElement(platformIcons[platform])}</Icon>

              <EditorWrapper
                data-testid={`${platform}-caption`}
                data-qa={`suggestion-form-captions-caption-field-${platform}-caption`}
              >
                <Editor
                  tabIndex="0"
                  ref={this.editorRef}
                  plugins={plugins}
                  placeholder={placeholder}
                  editorState={editorState}
                  onChange={this.onChange}
                  onFocus={() => this.setState({ focused: true })}
                  onBlur={() => this.setState({ focused: false })}
                />

                {this.mentionPlugin && (
                  <Mentions platform={platform} plugin={this.mentionPlugin} />
                )}
              </EditorWrapper>

              {platform === 'facebook' && suggestions.length === 1 && (
                <GeotargetButton
                  active={!!targets}
                  tabIndex={-1}
                  onClick={() =>
                    this.setState({ isGeotargetingModalOpen: true })
                  }
                >
                  <IconTarget />
                </GeotargetButton>
              )}

              <EmojiPicker onSelect={this.selectEmoji} />
              <RemainingChars showError remainingChars={remainingChars} />
            </TopRow>

            {targets && (
              <GeotargetingValues
                targets={targets}
                suggestion={suggestions[0]}
                platform={platform}
              />
            )}

            <GeotargetingModal
              suggestion={suggestions[0]}
              isOpen={this.state.isGeotargetingModalOpen}
              onRequestClose={() => {
                this.setState({ isGeotargetingModalOpen: false });
              }}
            />
          </Wrapper>
          <OpenGraphPreview text={!hasAttachments ? plainText : ''} large />
        </OuterWrapper>
      </ThemeProvider>
    );
  }
}

const mapDispatchToProps = {
  startSaving: startBatchSuggestionSaveOperation,
  stopSaving: stopBatchSuggestionSaveOperation,
};

export default connect<OwnProps, ConnectedActions>(
  null,
  mapDispatchToProps
)(CaptionField);
