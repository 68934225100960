import { post } from './shared';
import { AuthenticationRequestOptions } from 'helpers/auth/types';

export const createAuthRequest = (options: AuthenticationRequestOptions) => {
  const endpoint = 'api/authentication_requests';
  const data = {
    authentication_request: {
      provider: options.platformId,
      suggesterId: options.suggesterId,
      authType: options.authType || 'suggester',
      successRedirect: options.successRedirect,
      errorRedirect: options.errorRedirect,
      appType: options.appType,
      contentIntegrationId: options.integration?.id,
    },
  };

  return post(endpoint, data);
};

export const startPasswordReset = (email: string) => {
  const endpoint = 'api/users/password';
  const data = { user: { email } };
  return post(endpoint, data);
};

export const changePassword = (
  password: string,
  passwordConfirmation: string,
  resetPasswordToken: string
) => {
  const endpoint = 'api/users/password';
  const data = { user: { password, passwordConfirmation, resetPasswordToken } };
  return post(endpoint, data, 'PUT');
};
