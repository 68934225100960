import React from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { grid, colors } from 'styles/theme';
import { IconRaquo } from 'icons';
import { GlobalCSSVariables } from 'Layout/components/GlobalStyle';

interface Props {
  children?: React.ReactNode;
  isOpen: boolean;
  onToggleOpen: () => void;
}

const Wrapper = styled.div`
  position: relative;
  flex-basis: ${grid(5)};
  flex-shrink: 0;
  transition: flex-basis 0.15s;
  background-color: ${colors.white};
  min-height: calc(100vh - var(${GlobalCSSVariables.topOffset}));
  overflow-x: hidden;

  ${(props: any) => props.theme.isOpen && `flex-basis: ${grid(42)}`};
` as any;

const ToggleButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: ${grid(1)};
  left: ${grid(2)};
  transform-origin: 50% 50%;
  transition: transform 0.2s ease;
  color: ${colors.lightText};
  width: ${grid(2)};
  height: ${grid(2)};
  ${(props: any) =>
    !props.theme.isOpen && `transform: rotate(180deg) translate(4px, -4px);`};

  svg {
    width: 100%;
    height: 100%;
  }
`;

const Inner = styled.div`
  position: absolute;
  top: ${grid(4)};
  left: 0;
  transition: opacity 0.15s ease;
  opacity: 0;
  width: ${grid(42)};
  padding: ${grid(3)};
  ${(props: any) => props.theme.isOpen && `opacity: 1`};
`;

const Sidebar = ({ children, isOpen, onToggleOpen }: Props) => (
  <ThemeProvider theme={{ isOpen }}>
    <Wrapper>
      <ToggleButton onClick={onToggleOpen}>
        <IconRaquo />
      </ToggleButton>

      <Inner>{children}</Inner>
    </Wrapper>
  </ThemeProvider>
);

export default Sidebar;
