import { useMutation } from '@apollo/react-hooks';
import {
  AsyncPublisherSelect,
  Button,
  Form,
  MiniButton,
  Modal,
} from 'components';
import * as ADD_MEDIA_APPEARANCES from 'graphql/mutations/addMediaAppearances.graphql';
import { flashMessage } from 'helpers';
import { useBoolean, useIntl } from 'hooks';
import React, { useState } from 'react';
import Attachment from 'types/Attachment';
import SelectOption from 'types/SelectOption';

interface Props {
  attachment: Attachment;
}

export default function AddMediaAppearance(props: Props) {
  const { id } = props.attachment;
  const [isModalOpen, openModal, closeModal] = useBoolean();
  const [selectedPublishers, setSelectedPublishers] = useState<SelectOption[]>(
    []
  );
  const { t } = useIntl();
  const handleClose = () => {
    setSelectedPublishers([]);
    closeModal();
  };

  const [addMediaAppearances, { loading: isSaving }] = useMutation<any>(
    ADD_MEDIA_APPEARANCES,
    {
      variables: {
        attachmentId: id,
        publisherIds: selectedPublishers.map((p) => p.value),
      },
      onError: () => {
        flashMessage('Global__UnexpectedError', { style: 'error' });
      },
      onCompleted: (data) => {
        if (data.addMediaAppearances && !data.addMediaAppearances.errors) {
          handleClose();
        } else {
          flashMessage('Global__UnexpectedError', { style: 'error' });
        }
      },
    }
  );

  return (
    <>
      <MiniButton onClick={openModal}>
        {t('AddMediaAppearance__OpenModal')}
      </MiniButton>

      <Modal
        isOpen={isModalOpen}
        onRequestClose={handleClose}
        contentLabel={t('AddMediaAppearance__Heading')}
        renderHeading={t('AddMediaAppearance__Heading')}
      >
        <Form.Field>
          <AsyncPublisherSelect
            isMulti
            value={selectedPublishers}
            onChange={setSelectedPublishers}
            autoFocus
          />
        </Form.Field>

        <Modal.Actions>
          <Button cancel inlineBlock onClick={handleClose}>
            {t('Global__Cancel')}
          </Button>

          <Button
            filledBg
            inlineBlock
            onClick={addMediaAppearances}
            disabled={isSaving}
          >
            {t('Global__Save')}
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
}
