import React from 'react';
import styled from 'styled-components';
import { FlyoutMenu } from '..';
import { FormattedMessage } from 'react-intl';
import includes from 'lodash/includes';
import { grid, colors } from 'styles/theme';
import { IconTriangles } from 'icons';

interface Props {
  value?: string;
  name: string;
  options: string[];
  onChange: (val: string) => void;
  right?: boolean;
}

interface State {
  isOpen: boolean;
}

const Wrapper = styled.div`
  position: relative;
  font-size: 14px;

  span {
    display: flex;
    align-items: center;
    color: ${colors.darkText};
  }

  svg {
    position: relative;
    top: 1px;
    width: ${grid(2)};
    height: ${grid(2)};
  }
`;

const Selected = styled.span`
  margin-right: ${grid(0.5)};
`;

export default class Selector extends React.Component<Props, State> {
  render() {
    const { options, name } = this.props;
    let value = this.props.value;
    if (!includes(options, value)) {
      value = options[0];
    }

    return (
      <FlyoutMenu
        renderButton={(onClick) => (
          <Wrapper>
            <button onClick={onClick} data-qa="filter-selector-sort-button">
              <span>
                <Selected>
                  <FormattedMessage id={`${name}--${value}`} />
                </Selected>
                <IconTriangles />
              </span>
            </button>
          </Wrapper>
        )}
      >
        {options.map((option) => (
          <FlyoutMenu.Item
            data-qa={`filter-selector-options-${option}`}
            key={option}
            onClick={() => this.props.onChange(option)}
            showArrow="when-active"
            isActive={option === value}
          >
            <FormattedMessage id={`${name}--${option}`} />
          </FlyoutMenu.Item>
        ))}
      </FlyoutMenu>
    );
  }
}
