import React from 'react';
import NoResultsWrapper from './NoResultsWrapper';
import styled from 'styled-components';
import { grid, colors } from 'styles/theme';
import { FormattedMessage } from 'react-intl';
import { IconLoader } from 'icons';

const Inner = styled.div`
  display: flex;
  align-items: center;
`;

const Loader = styled.div`
  margin-left: ${grid(1)};
  width: ${grid(2)};
  height: ${grid(2)};
  color: ${colors.grey3};

  svg {
    width: 100%;
    height: 100%;
  }
`;

const Loading = () => (
  <NoResultsWrapper>
    <Inner>
      <FormattedMessage id="RecipientsSearch__Searching" />

      <Loader>
        <IconLoader />
      </Loader>
    </Inner>
  </NoResultsWrapper>
);

export default Loading;
