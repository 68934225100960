import React from 'react';
import SelectOption from 'types/SelectOption';
import { Form, WithIntl } from 'components';
import { FormattedMessage } from 'react-intl';

interface Props {
  onChange: (value: (number | string)[]) => void;
  value: (SelectOption | number)[];
  queryFunction: (q: string) => any;
  type: 'country' | 'locale';
}

interface State {
  inputValue: string;
  options: SelectOption[];
  isLoading: boolean;
}

export default class SearchableTarget extends React.Component<Props, State> {
  state = { inputValue: '', options: [], isLoading: false };

  handleInputChange = (inputValue: string) => {
    this.setState({ inputValue });
    if (inputValue === '') {
      this.setState({ options: [] });
    } else {
      this.loadOptions(inputValue);
    }

    return inputValue;
  };

  loadOptions = async (inputValue: string) => {
    const { queryFunction } = this.props;
    this.setState({ isLoading: true });
    const results = await queryFunction(inputValue);
    this.setState({
      isLoading: false,
      options: this.mapResultsToOptions(results),
    });
  };

  mapResultsToOptions = (results: any) => {
    if (this.props.type === 'country') {
      return results.data.map((item: any) => ({
        label: item.name,
        value: item.country_code,
      }));
    }

    return results.data.map((item: any) => ({
      label: item.name,
      value: item.key,
    }));
  };

  render() {
    const { value, onChange, type } = this.props;
    const { options, isLoading } = this.state;

    return (
      <WithIntl>
        {(_, t) => (
          <Form.Field>
            <Form.Label>
              <FormattedMessage id={`GeotargetingModal__Label--${type}`} />
            </Form.Label>

            <Form.Select
              isMulti
              options={options}
              onInputChange={this.handleInputChange}
              isLoading={isLoading}
              value={value as any}
              onChange={onChange}
              complexValues
              noOptionsMessage={() =>
                t(`GeotargetingModal__NoResults--${type}`)
              }
            />
          </Form.Field>
        )}
      </WithIntl>
    );
  }
}
