import React from 'react';
import BeforeUnload from 'react-beforeunload';
import { connect } from 'react-redux';
import { getFileUploads } from 'redux/ducks/fileUploads';
import FileUpload from 'types/FileUpload';
import { WithIntl } from 'components';
import { RootState } from 'redux/ducks';

interface ConnectedState {
  fileUploads: FileUpload[];
}

type Props = ConnectedState;

const UploadSaver = ({ fileUploads }: Props) => {
  const activeUploads = fileUploads.filter(
    (u) => u.status !== 'done' && u.status !== 'canceled'
  );

  if (!activeUploads.length) return null;

  return (
    <WithIntl>
      {(_, t) => (
        <BeforeUnload onBeforeunload={() => t('UploadSaver__Warning')} />
      )}
    </WithIntl>
  );
};

const mapStateToProps = (state: RootState) => ({
  fileUploads: getFileUploads(state),
});

export default connect(mapStateToProps)(UploadSaver);
