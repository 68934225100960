import React, { useState } from 'react';
import { Form } from 'components';
import Suggestion from 'types/Suggestion';
import { useIntl } from 'hooks';
import styled from 'styled-components';
import { mixins } from 'styles';
import { grid, colors, font } from 'styles/theme';
import RemainingChars from 'components/RemainingChars';
import TextareaAutosize from 'react-autosize-textarea/lib';

const InputWrapper = styled.div`
  ${mixins.input};
  display: flex;
  position: relative;
  padding: ${grid(0.5)};
  height: auto;
  min-height: ${grid(4.5)};

  textarea {
    position: relative;
    top: -1px;
    appearance: none;
    border: 0;
    width: 100%;
    resize: none;
    line-height: ${grid(2.5)};
    color: ${colors.darkText};
    font-family: ${font.sans};
    font-size: 14px;

    &:focus {
      outline: none;
    }
  }
`;

interface Props {
  suggestions: Suggestion[];
  onChange: (value?: string | null) => void;
  onChangeTitle: (value: string) => void;
  onChangeDescription: (value: string) => void;
}

const MAX_LENGTHS = {
  title: 160,
  description: 500,
};

export default function ExampleContent(props: Props) {
  const { suggestions, onChange, onChangeDescription, onChangeTitle } = props;
  const { t } = useIntl();
  const [values, setValues] = useState({
    title: suggestions[0].exampleContentTitle || '',
    description: suggestions[0].exampleContentDescription || '',
  });

  const remainingChars = {
    title: MAX_LENGTHS.title - values.title.length,
    description: MAX_LENGTHS.description - values.description.length,
  };

  const handleTextChange = (field: 'title' | 'description', value: string) => {
    setValues({ ...values, [field]: value });
    if (field === 'title') {
      onChangeTitle(value);
    } else {
      onChangeDescription(value);
    }
  };

  return (
    <>
      <Form.Field>
        <Form.Label bold>
          {t('SuggestionForm__ExampleContentHeading')}
        </Form.Label>
        <Form.FileInput
          thumbnailSize="large"
          extensions={['jpg', 'jpeg', 'png', 'mp4']}
          attachment={{
            src: suggestions[0].exampleContentUrl,
            contentType: suggestions[0].exampleContentContentType,
          }}
          onChange={(featuredContent) => onChange(featuredContent)}
        />
      </Form.Field>

      <Form.Field>
        <Form.Label bold>{t('SuggestionForm__ExampleContentTitle')}</Form.Label>

        <InputWrapper>
          <TextareaAutosize
            value={values.title}
            onChange={(e) => handleTextChange('title', e.target['value'])}
            maxLength={MAX_LENGTHS.title}
            placeholder={t('SuggestionForm__ExampleContentTitlePlaceholder')}
          />

          <RemainingChars remainingChars={remainingChars.title} />
        </InputWrapper>
      </Form.Field>

      <Form.Field>
        <Form.Label bold>
          {t('SuggestionForm__ExampleContentDescription')}
        </Form.Label>

        <InputWrapper>
          <TextareaAutosize
            value={values.description}
            onChange={(e) => handleTextChange('description', e.target['value'])}
            maxLength={MAX_LENGTHS.description}
            placeholder={t(
              'SuggestionForm__ExampleContentDescriptionPlaceholder'
            )}
          />

          <RemainingChars remainingChars={remainingChars.description} />
        </InputWrapper>
      </Form.Field>
    </>
  );
}
