import { OtherAction } from './global';
import isEmpty from 'lodash/isEmpty';
import has from 'lodash/has';
import find from 'lodash/find';
import cookie from 'react-cookies';
import moment from 'moment';
import 'moment/locale/fr-ca';
import withoutDialect from 'helpers/withoutDialect';

const availableLanguages = {
  en: require('locales/en.json'),
  // this was specifically translated to fr-CA
  // fr: require('locales/fr.json'),
};

export enum TypeKeys {
  LANGUAGE_CHANGED = 'locales/LANGUAGE_CHANGED',
}

export interface LanguageChangedAction {
  type: TypeKeys.LANGUAGE_CHANGED;
  payload: {
    lang: string;
  };
}

type ActionTypes = LanguageChangedAction | OtherAction;

export type State = {
  lang: string;
  messages: any;
};

const browserLanguages = navigator.languages;
const cookieLanguage = cookie.load('locales.lang');
let browserLanguage;

if (!cookieLanguage && !isEmpty(browserLanguages)) {
  browserLanguage = find(browserLanguages, (language) =>
    has(availableLanguages, withoutDialect(language))
  );
}

const locale = cookieLanguage || browserLanguage || 'en-US';
const messages =
  availableLanguages[withoutDialect(locale)] || availableLanguages.en;
moment.locale(locale);

const localesReducer = (
  state: State = { messages, lang: locale },
  action: ActionTypes
) => {
  switch (action.type) {
    case TypeKeys.LANGUAGE_CHANGED:
      const { lang } = action.payload;
      return { ...state, lang, messages: availableLanguages[lang] };
    default:
      return state;
  }
};

export const changeLanguage = (lang: string) => async (dispatch: Function) => {
  cookie.save('locales.lang', lang, { path: '/' });
  return dispatch({
    type: TypeKeys.LANGUAGE_CHANGED,
    payload: { lang },
  });
};

export default localesReducer;
