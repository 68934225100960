import React, { useMemo, useContext } from 'react';
import styled from 'styled-components';
import { nl2br } from 'helpers';
import { grid, colors } from 'styles/theme';
import { OpenGraphPreview } from 'components';
import { ScrollPosition } from '../context';

interface Props {
  text?: string;
}

// prettier-ignore
const StyledText = styled.div`
  margin-bottom: ${grid(1)};
  border-radius: 19px;
  background-color: ${colors.greyBg};
  padding: ${grid(0.25)} ${grid(1.5)} ${grid(0.5)};
  max-width: 100%;
  overflow: hidden;
  line-height: ${grid(2.5)};
  word-wrap: break-word;
  color: ${colors.darkText};

  &:last-child {
    margin-bottom: 0;
  }

  ${(props: any) => props.theme.fromPublisher && `
    background-color: ${colors.messageBg};
    color: ${colors.white};
  `}

  ${(props: any) => props.theme.failed && `
    background-color: ${colors.errorBg};
    color: ${colors.error};
  `}
`;

const Text = ({ text }: Props) => {
  const { element: wrapperElement } = useContext(ScrollPosition);

  const handleOgPreviewLoad = useMemo(
    () => (ogData: any, element?: HTMLAnchorElement | null) => {
      if (element && wrapperElement) {
        wrapperElement.scrollTop =
          wrapperElement.scrollTop + element.clientHeight;
      }
    },
    [wrapperElement]
  );

  if (!text) return null;

  return (
    <>
      <StyledText>{nl2br(text)}</StyledText>
      <OpenGraphPreview text={text} onLoad={handleOgPreviewLoad} small />
    </>
  );
};

export default Text;
