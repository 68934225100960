export default function browserIncompatibilityCheck(
  incompatibleBrowsers: Array<
    'Chrome' | 'Firefox' | 'Edge' | 'Opera' | 'Safari'
  >,
  action: () => void
) {
  const userAgent = window.navigator.userAgent;

  const isBrowserIncompatible = incompatibleBrowsers.some(
    (browser) => userAgent.indexOf(browser) > -1
  );

  if (isBrowserIncompatible) {
    action();
  }
}
