// Take an array of objects and return them as a single object keyed by the
// platform id.
//
// eg. [{ id: 1, platformId: 'twitter'}, { id: 2, platformId: 'facebook'}]
// becomes
//  {
//    twitter: { id: 1, platformId: 'twitter' },
//    facebook: { id: 2, platformId: 'facebook' },
//  }
const byPlatformId = (objects: any[], platformIdKey = 'platformId') => {
  const byPlatformId = {};
  if (objects) {
    objects.forEach((obj) => {
      const platformId = obj[platformIdKey];
      if (platformId) {
        byPlatformId[platformId] = obj;
      }
    });
  }
  return byPlatformId;
};

export default byPlatformId;
