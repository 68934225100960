import React from 'react';
import styled from 'styled-components';
import FlipMove from 'react-flip-move';
import Tag from 'types/Tag';
import { grid } from 'styles/theme';
import PublisherTag from '../PublisherTag';

interface Props {
  publisher: { id: number; tags: Tag[] };
}

const List = styled(FlipMove)`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: ${grid(1)};

  > * {
    margin-right: ${grid(1)};
    margin-bottom: ${grid(1)};

    &:last-child {
      margin-right: 0;
    }
  }
`;

const PublisherTagList = ({ publisher }: Props) => {
  if (!publisher.tags.length) return null;

  return (
    <List>
      {publisher.tags.map((tag) => (
        <div key={tag.id}>
          <PublisherTag tag={tag} publisherId={publisher.id} />
        </div>
      ))}
    </List>
  );
};

export default PublisherTagList;
