import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { NavLink, useLocation } from 'react-router-dom';
import { logOut, routes } from 'helpers';
import { Avatar } from '../..';
import { media } from 'styles';
import { grid, colors } from 'styles/theme';
import MainNavToggle from './MainNavToggle';
import { FormattedMessage } from 'react-intl';
import {
  IconClose,
  // IconMainNavFeed,
  IconMainNavMedia,
  IconMainNavPublisher,
  IconMainNavReports,
  IconMainNavTracking,
  IconMainNavFeed,
  IconGear,
  IconAthlete,
  IconUpload,
} from 'icons';
import { useCurrentUser, useIntl } from 'hooks';
import MainNavItem from './MainNavItem';

const Wrapper = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  flex-direction: column;
  transform: translateX(-300px);
  transition: transform 0.3s;
  opacity: 0;
  z-index: 1;
  box-shadow: 0 0 4px 4px ${colors.boxShadow};
  background-color: ${colors.lightBorder};
  padding: ${grid(3)};
  width: 300px;
  height: 100vh;
  font-size: 18px;

  ${media.tablet`
    position: relative;
    align-items: center;
    flex-direction: row;
    top: auto;
    left: auto;
    transform: translateX(0);
    opacity: 1;
    box-shadow: none;
    margin-left: ${grid(1)};
    padding: 0;
    font-size: 16px;
    background-color: transparent;
    width: auto;
    height: auto;
  `};

  ${(props: any) =>
    props.isOpen &&
    `
    display: flex;
    transform: translateX(0);
    opacity: 1;
  `};

  ${(props: any) =>
    !props.isOpen &&
    `
    opacity: 0;
  `};
` as any;

const ItemWrap = styled.div`
  position: relative;

  ${media.tablet`
    display: flex;
  `};
`;

const MobileUserMenu = styled.div`
  position: relative;
  border-top: 1px solid ${colors.border};
  margin-top: ${grid(2)};
  padding-top: ${grid(3)};
  font-size: 15px;

  a,
  button {
    display: block;
    color: ${colors.darkText};
  }

  ${media.tablet`
    display: none;
  `};
`;

const AvatarWrapper = styled.button.attrs({ type: 'button' })`
  width: ${grid(5)};
  height: ${grid(5)};
`;

const Email = styled.div`
  margin: 0 0 ${grid(1)};
  color: ${colors.lightText};
  font-size: 14px;
`;

const Close = styled.button`
  display: flex;
  position: absolute;
  top: ${grid(2.5)};
  right: ${grid(3)};
  align-items: center;
  justify-content: center;
  transition: all 0.2s;
  z-index: 1;
  padding: ${grid(1)};
  width: 32px;
  height: 32px;

  &:hover {
    color: ${colors.link};
  }

  svg {
    width: 100%;
    height: 100%;
  }

  ${media.tablet`
    display: none;
  `};
`;

export default function MainNav() {
  const [isOpen, setIsOpen] = useState(false);
  const toggleOpen = () => setIsOpen(!isOpen);
  const { t } = useIntl();
  const currentUser = useCurrentUser();
  const hasAthlete =
    !!currentUser?.currentSuggester?.accountConfiguration?.maxAthletes;

  const location = useLocation();

  const isUploader = currentUser?.currentRole === 'uploader';

  useEffect(() => {
    setIsOpen(false);
  }, [location.pathname]);

  return (
    <>
      <MainNavToggle isOpen={isOpen} toggleOpen={toggleOpen} />
      <Wrapper isOpen={isOpen} data-testid="main-nav">
        <Close onClick={() => setIsOpen(false)}>
          <IconClose />
        </Close>
        <ItemWrap>
          {isUploader ? (
            <MainNavItem
              to={routes.upload}
              icon={IconUpload}
              label="MainNav__Upload"
            />
          ) : (
            <>
              <MainNavItem
                to={routes.mediaLibrary.index}
                icon={IconMainNavMedia}
                label="MainNav__MediaLibrary"
              />
              <MainNavItem
                to={routes.feed.index}
                icon={IconMainNavFeed}
                label="MainNav__Feed"
              />
              <MainNavItem
                to={routes.tracking.root}
                icon={IconMainNavTracking}
                label="MainNav__Tracking"
              />
              <MainNavItem
                to={routes.publishers.index}
                icon={IconMainNavPublisher}
                label="MainNav__Publishers"
                isActive={(match, location) =>
                  !!location.pathname.match(/^\/publishers/)
                }
              />
              <MainNavItem
                to={routes.reports.index}
                icon={IconMainNavReports}
                label="MainNav__Reports"
              />
            </>
          )}

          {!isUploader && hasAthlete && (
            <MainNavItem
              to={'/athlete'}
              icon={IconAthlete}
              label="MainNav__Athlete"
              subItems={[
                {
                  path: routes.athlete.index,
                  label: t('MainNav__Athlete--CommentModeration'),
                },
                {
                  path: routes.athlete.demographics,
                  label: t('MainNav__Athlete--Demographics'),
                },
              ]}
              theme="athlete"
            />
          )}

          <MainNavItem
            to={routes.settings.index}
            icon={IconGear}
            label="MainNav__Settings"
          />
        </ItemWrap>

        <MobileUserMenu>
          <AvatarWrapper>
            <Avatar src={undefined} name={currentUser.name} />
          </AvatarWrapper>
          <Email>{currentUser.email}</Email>
          <NavLink to={routes.settings.profile}>
            <FormattedMessage id="UserMenu__Settings" />
          </NavLink>

          <button onClick={() => logOut()}>
            <FormattedMessage id="UserMenu__LogOut" />
          </button>
        </MobileUserMenu>
      </Wrapper>
    </>
  );
}
