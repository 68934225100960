import React from 'react';
import ReactModal from 'react-modal';

const ModalAdapter = ({
  className,
  overlayClassName,
  modalClassName,
  ...props
}: any) => {
  return (
    <ReactModal
      className={modalClassName}
      overlayClassName={overlayClassName}
      portalClassName={className}
      {...props}
    />
  );
};

export default ModalAdapter;
