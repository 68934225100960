import React from 'react';
import styled from 'styled-components';
import { grid, colors } from 'styles/theme';

const Icon = styled.div`
  padding-top: calc(${grid(0.5)} + 3px);
  padding-left: ${grid(1)};
  width: ${grid(3.5)};
  color: ${colors.lightIcon};

  > svg {
    display: block;
    position: relative;
    top: -3px;
    width: ${grid(2.5)};
    height: ${grid(2.5)};
    color: ${colors.lightIcon};
  }
`;

const Control = (props: any) => {
  const { icon } = props.selectProps;

  return (
    <div
      style={props.getStyles('control', props)}
      ref={props.innerRef}
      {...props.innerProps}
    >
      {icon && <Icon>{icon}</Icon>}
      {props.children}
    </div>
  );
};

export default Control;
