import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import ContactMethod from 'types/ContactMethod';
import { NoContactMethodsWarning, FacePrintsIndicator } from 'components';
import { grid, colors } from 'styles/theme';
import { useIntl } from 'hooks';
import NotificationPreference from 'types/NotificationPreference';

interface Props {
  publisher: {
    name?: string;
    createdAt?: number;
    contactMethods?: ContactMethod[];
    notificationPreferences?: NotificationPreference[];
    facePrints?: { id: string }[];
  };
}

const Wrapper = styled.div`
  display: inline-flex;
  align-items: center;
`;

const New = styled.div`
  position: relative;
  top: 1px;
  margin-left: ${grid(0.5)};
  border-radius: 4px;
  background-color: ${colors.socialieBlue};
  padding: 0 ${grid(0.5)};
  height: ${grid(2)};
  line-height: ${grid(2)};
  color: ${colors.white};
  font-size: 12px;
  font-weight: bold;
`;

const PublisherName = ({ publisher }: Props) => {
  const { t } = useIntl();

  const name = publisher.name || (
    <FormattedMessage id="PublisherName__NoName" />
  );
  const isNew =
    publisher.createdAt &&
    moment().diff(moment(publisher.createdAt), 'days') < 7;

  return (
    <Wrapper>
      <span>{name}</span>
      {publisher.contactMethods && publisher.notificationPreferences && (
        <NoContactMethodsWarning
          contactMethods={publisher.contactMethods}
          notificationPreferences={publisher.notificationPreferences}
        />
      )}

      <FacePrintsIndicator publisher={publisher} />

      {isNew && <New>{t('PublisherName__New')}</New>}
    </Wrapper>
  );
};

export default PublisherName;
