// Take a suggestion and returns a flattened array of attachments.  If the
// Suggestion is a ContentRequest we get the attachments via the
// Requests and also inject the Request back into the attachment.
//
// Useful for building galleries from attachments.

import flatten from 'lodash/flatten';
import omitDeleted from './omitDeleted';
import Attachment from 'types/Attachment';
import PublisherRequest from 'types/PublisherRequest';

interface Suggestion {
  actionableType: string;
  attachments: Attachment[];
  requests?: PublisherRequest[];
}

const getSuggestionAttachments = (suggestion: Suggestion) => {
  if (suggestion.actionableType === 'Suggestion') return suggestion.attachments;
  return flatten(
    omitDeleted(suggestion.requests || []).map((request) =>
      (request.submittedAttachments || []).map((attachment) => ({
        ...attachment,
        request,
      }))
    )
  );
};

export default getSuggestionAttachments;
