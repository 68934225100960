interface FormatDateOptions {
  locale: string;
  date: boolean;
  forceYear: boolean;
  time: boolean;
  timeZone: boolean;
  month?: Intl.DateTimeFormatOptions['month'];
}

const defaultOptions: FormatDateOptions = {
  locale: 'en-US',
  date: true,
  time: true,
  timeZone: true,
  forceYear: false,
  month: 'short',
};

export default function formatDate(
  value: Date | number,
  options?: Partial<FormatDateOptions>
) {
  const date = new Date(value);
  let finalOptions = { ...defaultOptions, ...options };
  let dateStringOptions: Intl.DateTimeFormatOptions = {};

  if (finalOptions.date) {
    const currentYear = new Date().getFullYear();
    if (finalOptions.forceYear || date.getFullYear() !== currentYear) {
      dateStringOptions.year = 'numeric';
    }
    dateStringOptions.month = finalOptions.month;
    dateStringOptions.day = 'numeric';
  }

  if (finalOptions.time) {
    dateStringOptions.hour = 'numeric';
    dateStringOptions.minute = '2-digit';
    dateStringOptions.hour12 = true;
  }

  if (finalOptions.timeZone && finalOptions.time) {
    dateStringOptions.timeZoneName = 'short';
  }

  return date.toLocaleDateString(finalOptions.locale, dateStringOptions);
}
