import React from 'react';
import without from 'lodash/without';
import SuggestionsFilterData from 'types/SuggestionsFilterData';
import TrackingIndexFilter from 'types/TrackingIndexFilter';
import DraftsIndexFilter from 'types/DraftsIndexFilter';
import { mapNodes } from 'helpers';
import { useCurrentUser, useIntl } from 'hooks';
import FilterPills from '../FilterPills';
import { usePublishersAndPublisherTagsById } from 'components/AsyncPublishersAndTagsSelect';

interface Props {
  data?: SuggestionsFilterData;
  loading?: boolean;
  filter: TrackingIndexFilter | DraftsIndexFilter;
  updateFilter: (
    updates: Partial<TrackingIndexFilter | DraftsIndexFilter>
  ) => void;
  isFiltered: boolean;
  defaultFilter: TrackingIndexFilter | DraftsIndexFilter;
}

export default function SuggestionFilterPills(props: Props) {
  const { data, filter, updateFilter, isFiltered, defaultFilter } = props;

  const trackingIndexFilter = filter as TrackingIndexFilter;

  const { t } = useIntl();

  const currentUser = useCurrentUser();

  const { publishers, publisherTags } = usePublishersAndPublisherTagsById({
    publisherIds: filter.publishers,
    publisherTagIds: filter.publisherTags,
  });

  if (
    !data ||
    !data.currentSuggester ||
    !isFiltered ||
    !currentUser.currentSuggester
  ) {
    return null;
  }

  const tags = data?.tags ? mapNodes(data.tags.edges) : [];

  const campaigns = (data && data.campaigns) || [];

  const attachmentAlbums =
    data && data.attachmentAlbums ? mapNodes(data.attachmentAlbums.edges) : [];

  const suggesters = [
    currentUser.currentSuggester,
    ...currentUser.currentSuggester.children,
  ];

  return (
    <FilterPills
      onClearFilters={() =>
        updateFilter({ ...defaultFilter, keywords: filter.keywords })
      }
    >
      <FilterPills.PublisherFilterPills
        value={filter.publishers}
        publishers={publishers}
        onRemove={(id: number) =>
          updateFilter({ publishers: without(filter.publishers, id) })
        }
      />

      {filter.publisherTags.map((id) => {
        const tag = publisherTags.find((t) => Number(t.id) === Number(id));
        if (!tag) return null;

        return (
          <FilterPills.FilterPill
            key={id}
            label={tag.name}
            onRemove={() =>
              updateFilter({ publisherTags: without(filter.publisherTags, id) })
            }
          />
        );
      })}

      <FilterPills.SuggesterFilterPills
        suggesters={suggesters}
        value={filter.suggesters}
        onRemove={(id) =>
          updateFilter({ suggesters: without(filter.suggesters, id) })
        }
      />

      {filter.currentUserOnly && (
        <FilterPills.FilterPill
          label={t('TrackingSenderFilter__FromMe')}
          onRemove={() => updateFilter({ currentUserOnly: false })}
        />
      )}

      {filter.actionableTypes.map((type) => (
        <FilterPills.FilterPill
          key={type}
          label={t(`TrackingActionableTypeFilter__Option--${type}`)}
          onRemove={() =>
            updateFilter({
              actionableTypes: without(filter.actionableTypes, type),
            })
          }
        />
      ))}

      {trackingIndexFilter.statuses &&
        trackingIndexFilter.statuses.map((status) => (
          <FilterPills.FilterPill
            key={status}
            label={t(`RequestStatus--${status}`)}
            onRemove={() =>
              updateFilter({
                statuses: without(trackingIndexFilter.statuses, status),
              })
            }
          />
        ))}

      {filter.tags.map((id) => {
        const tag = tags.find((t) => Number(t.id) === Number(id));
        if (!tag) return null;
        return (
          <FilterPills.FilterPill
            key={id}
            label={tag.name}
            onRemove={() => updateFilter({ tags: without(filter.tags, id) })}
          />
        );
      })}

      {filter.attachmentAlbums.map((id) => {
        const album = attachmentAlbums.find((t) => Number(t.id) === Number(id));

        if (!album) return null;
        return (
          <FilterPills.FilterPill
            key={id}
            label={album.name}
            onRemove={() =>
              updateFilter({
                attachmentAlbums: without(filter.attachmentAlbums, id),
              })
            }
          />
        );
      })}

      {filter.campaigns.map((id) => {
        const campaign = campaigns.find((t) => Number(t.id) === Number(id));
        if (!campaign) return null;
        return (
          <FilterPills.FilterPill
            key={id}
            label={campaign.name}
            onRemove={() =>
              updateFilter({ campaigns: without(filter.tags, id) })
            }
          />
        );
      })}

      {trackingIndexFilter.sentDate && (
        <FilterPills.DateRangeFilterPill
          value={trackingIndexFilter.sentDate}
          defaultValue={(defaultFilter as TrackingIndexFilter).sentDate}
          msg="SuggestionFilterPills__SentDate"
          onRemove={() =>
            updateFilter({
              sentDate: (defaultFilter as TrackingIndexFilter).sentDate,
            })
          }
        />
      )}

      {trackingIndexFilter.publishDate && (
        <FilterPills.DateRangeFilterPill
          value={trackingIndexFilter.publishDate}
          defaultValue={(defaultFilter as TrackingIndexFilter).publishDate}
          msg="SuggestionFilterPills__PublishDate"
          onRemove={() =>
            updateFilter({
              publishDate: (defaultFilter as TrackingIndexFilter).publishDate,
            })
          }
        />
      )}

      {filter.mediaSource.map((source) => (
        <FilterPills.FilterPill
          key={source}
          label={t(`Integrations--${source}`)}
          onRemove={() =>
            updateFilter({ mediaSource: without(filter.mediaSource, source) })
          }
        />
      ))}

      {filter.mediaType.map((type) => (
        <FilterPills.FilterPill
          key={type}
          label={t(`TrackingMediaTypeFilter__${type}`)}
          onRemove={() =>
            updateFilter({ mediaType: without(filter.mediaType, type) })
          }
        />
      ))}

      {filter.textOnly && (
        <FilterPills.FilterPill
          label={t('TrackingMediaTypeFilter__TextOnly')}
          onRemove={() => updateFilter({ textOnly: false })}
        />
      )}

      {filter.suggestedPlatform.map((platform) => (
        <FilterPills.FilterPill
          key={platform}
          label={t('SuggestionFilterPills__SuggestedPlatform', {
            platform: t(`Platforms--${platform}`),
          })}
          onRemove={() =>
            updateFilter({
              suggestedPlatform: without(filter.suggestedPlatform, platform),
            })
          }
        />
      ))}

      {trackingIndexFilter.publishedPlatform &&
        trackingIndexFilter.publishedPlatform.map((platform) => (
          <FilterPills.FilterPill
            key={platform}
            label={t('SuggestionFilterPills__PublishedPlatform', {
              platform: t(`Platforms--${platform}`),
            })}
            onRemove={() =>
              updateFilter({
                publishedPlatform: without(
                  trackingIndexFilter.publishedPlatform,
                  platform
                ),
              })
            }
          />
        ))}

      {trackingIndexFilter.recentlyOnline && (
        <FilterPills.FilterPill
          label={t('TrackingMiscFilter__RecentlyOnline')}
          onRemove={() => updateFilter({ recentlyOnline: false })}
        />
      )}

      {trackingIndexFilter.smartSuggestions && (
        <FilterPills.FilterPill
          label={t('TrackingMiscFilter__SmartSuggestions')}
          onRemove={() => updateFilter({ smartSuggestions: false })}
        />
      )}
    </FilterPills>
  );
}
