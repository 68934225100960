import React from 'react';
import { ThemeProvider } from 'styled-components';
import { Provider as ReduxProvider } from 'react-redux';
import { ApolloProvider } from '@apollo/react-common';
import { ApolloProvider as LegacyApolloProvider } from 'react-apollo';
import store from 'redux/store';
import apolloClient from 'graphql/apolloClient';
import ConnectedIntlProvider from 'components/ConnectedIntlProvider';
import { InjectIntlContext } from 'hooks/useIntl';
import { IsMobileProvider } from 'hooks/useIsMobile';
import { GodModeProvider } from 'hooks/useGodMode';
import Layout from 'Layout';
import 'socket';

const Fragment = (props: { children: React.ReactNode }) => props.children;

export default class App extends React.Component {
  render() {
    return (
      <GodModeProvider>
        <ApolloProvider client={apolloClient}>
          <LegacyApolloProvider client={apolloClient}>
            <ReduxProvider store={store}>
              <ConnectedIntlProvider textComponent={Fragment}>
                <InjectIntlContext>
                  <ThemeProvider theme={{}}>
                    <IsMobileProvider>
                      <Layout />
                    </IsMobileProvider>
                  </ThemeProvider>
                </InjectIntlContext>
              </ConnectedIntlProvider>
            </ReduxProvider>
          </LegacyApolloProvider>
        </ApolloProvider>
      </GodModeProvider>
    );
  }
}
