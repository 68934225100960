import React from 'react';
import { FormattedMessage } from 'react-intl';
import { AsyncPublisherSelect, Form } from 'components';
import { useIntl } from 'hooks';
import { usePublishersById } from 'components/AsyncPublisherSelect';

interface Props<FilterKey extends string> {
  label: string;
  placeholder?: string;
  filterKey: FilterKey;
  filter: { [key in FilterKey]: number[] };
  updateFilter: (changes: {}) => void;
  children?: React.ReactNode;
  boldLabel?: boolean;
  'data-qa'?: string;
  publisherFilter?: React.ComponentProps<typeof AsyncPublisherSelect>['filter'];
}

export default function PublisherFilter<FilterKey extends string>(
  props: Props<FilterKey>
) {
  const {
    label,
    placeholder,
    filterKey,
    filter,
    updateFilter,
    publisherFilter,
    children,
    boldLabel,
    'data-qa': dataQa,
  } = props;
  const publisherIds = filter[filterKey];
  const { publishers } = usePublishersById(publisherIds);
  const { t } = useIntl();

  return (
    <Form.Field>
      <Form.Label bold={boldLabel}>
        <FormattedMessage id={label} />
      </Form.Label>

      <AsyncPublisherSelect
        isMulti
        value={publishers.map((publisher) => ({
          value: publisher.id,
          label: publisher.name,
          image: publisher.image,
        }))}
        onChange={(val) => {
          updateFilter({
            [filterKey]: Array.isArray(val)
              ? val.map((v) => v.value)
              : [val.value],
          });
        }}
        placeholder={t(
          placeholder || 'MediaLibraryPublisherFilter__Placeholder'
        )}
        data-qa={dataQa}
        filter={publisherFilter}
      />

      {children}
    </Form.Field>
  );
}

PublisherFilter.defaultProps = {
  boldLabel: true,
};
