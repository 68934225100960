import React from 'react';
import styled from 'styled-components';
import { Mutation } from '@apollo/react-components';
import * as mutation from 'graphql/mutations/removePublisherTagFromPublisher.graphql';
import Pills from '../Pills';
import Tag from 'types/Tag';
import PublisherTagType from 'types/PublisherTag';

interface Props {
  tag: PublisherTagType | Tag;
  publisherId: number;
  large?: boolean;
}

const PillWrapper = styled.div`
  ${(props: any) => props.isRemoving && 'opacity: 0.3'};
` as any;

interface MutationData {
  removePublisherTagFromPublisher: {
    errors?: string;
    publisherTag?: PublisherTagType;
  };
}

const PublisherTag = ({ tag, publisherId, large }: Props) => {
  const variables = { publisherIds: publisherId, tagIds: tag.id };

  return (
    <Mutation<MutationData> mutation={mutation} variables={variables}>
      {(removePublisherTagFromPublisher, { loading }) => (
        <PillWrapper isRemoving={loading}>
          <Pills.Pill large={large} onRemove={removePublisherTagFromPublisher}>
            {tag.name}
          </Pills.Pill>
        </PillWrapper>
      )}
    </Mutation>
  );
};

export default PublisherTag;
