import Suggestion from 'types/Suggestion';
import SuggestionValidation from 'types/SuggestionValidation';
import intersection from 'lodash/intersection';
import * as config from 'config';
import getRemainingChars from 'helpers/getRemainingChars';
import useGetInvalidPlatforms from './useGetInvalidPlatforms';
import isValidTimeDiff from 'helpers/isValidTimeDiff';
import isSuggestionMissingRecipients from 'helpers/isSuggestionMissingRecipients';
import { useCurrentUser } from 'hooks';

type ValidationFunc = (
  suggestion: Suggestion,
  validation: SuggestionValidation
) => any;

const validateHasRecipients: ValidationFunc = (suggestion, validation) => {
  if (isSuggestionMissingRecipients(suggestion)) {
    validation.missingRecipients = true;
    validation.isValid = false;
  }
};

const validateHasDrafts: ValidationFunc = (suggestion, validation) => {
  if (suggestion.actionableType === 'ContentRequest') return;
  if (!suggestion.drafts || !suggestion.drafts.length) {
    validation.missingPlatforms = true;
    validation.isValid = false;
  }
};

const validateAllDraftsHaveText: ValidationFunc = (suggestion, validation) => {
  if (!!suggestion.attachments.length || !suggestion.drafts) {
    return;
  }

  suggestion.drafts.forEach((draft) => {
    if (!!draft.text) return;
    validation.platformsMissingText.push(draft.platform);
    validation.isValid = false;
  });
};

const validateCharacterLimit: ValidationFunc = (suggestion, validation) => {
  if (!suggestion.drafts) return;
  suggestion.drafts.forEach((draft) => {
    const remainingChars = getRemainingChars(draft.text, draft.platform);
    if (remainingChars < 0) {
      validation.platformsOverCharacterCount!.push(draft.platform);
      validation.isValid = false;
    }
  });
};

const validateHasInstructions: ValidationFunc = (suggestion, validation) => {
  if (suggestion.actionableType === 'Suggestion') return;
  if (!suggestion.instructions) {
    validation.missingInstructions = true;
    validation.isValid = false;
  }
};

const validateInstructionsCharacterLimit: ValidationFunc = (
  suggestion,
  validation
) => {
  if (suggestion.instructions && suggestion.instructions.length > 900) {
    validation.instructionsTooLong = true;
    validation.isValid = false;
  }
};

const validateHasSubject: ValidationFunc = (suggestion, validation) => {
  if (suggestion.actionableType === 'Suggestion') return;
  if (!suggestion.subject) {
    validation.missingSubject = true;
    validation.isValid = false;
  }
};

const validateSubjectCharacterLimit: ValidationFunc = (
  suggestion,
  validation
) => {
  if (suggestion.subject && suggestion.subject.length > 255) {
    validation.subjectTooLong = true;
    validation.isValid = false;
  }
};

const validateNoTwitterRecipients: ValidationFunc = (
  suggestion,
  validation
) => {
  const twitterRecipient = suggestion.intendedRecipients.find(
    (ir) => !!ir.contactMethod && ir.contactMethod.protocol === 'twitter_dm'
  );
  if (twitterRecipient) {
    validation.notLinkedToTwitter = true;
    validation.isValid = false;
  }
};

const validateAttachments = (
  suggestion: Suggestion,
  validation: SuggestionValidation,
  getInvalidPlatforms: ReturnType<typeof useGetInvalidPlatforms>
) => {
  if (!suggestion.drafts) return;
  const platforms = suggestion.drafts.map((d) => d.platform);
  const invalidPlatforms = intersection(
    platforms,
    Object.keys(getInvalidPlatforms(suggestion))
  );
  if (invalidPlatforms.length) {
    validation.invalidPlatforms = invalidPlatforms;
    validation.isValid = false;
  }
};

const validatePostAtIsFuture: ValidationFunc = (suggestion, validation) => {
  const minDiff = config.suggestionValidation.minPostAtLeadTime;
  if (!isValidTimeDiff(suggestion.postAt, minDiff)) {
    validation.invalidPostAtTime = true;
    validation.isValid = false;
  }
};

const validateExpiresAtIsFuture: ValidationFunc = (suggestion, validation) => {
  const minDiff = config.suggestionValidation.minExpiresAtLeadTime;
  if (!isValidTimeDiff(suggestion.expiresAt, minDiff)) {
    validation.invalidExpiresAtTime = true;
    validation.isValid = false;
  }
};

const validateExpiresAtIsNotBeforePostAt: ValidationFunc = (
  suggestion,
  validation
) => {
  if (!suggestion.expiresAt || !suggestion.postAt) return;
  if (suggestion.expiresAt < suggestion.postAt) {
    validation.expiresAtBeforeAtPostAt = true;
    validation.isValid = false;
  }
};

export default function useValidateSuggestion() {
  const currentUser = useCurrentUser();
  const getInvalidPlatforms = useGetInvalidPlatforms();

  async function validateSuggestion(suggestion: Suggestion) {
    const validation: SuggestionValidation = {
      isValid: true,
      missingRecipients: false,
      missingPlatforms: false,
      platformsOverCharacterCount: [],
      platformsMissingText: [],
      invalidPlatforms: [],
    };

    const hasTwitter = currentUser.oauthIdentities.find(
      (o) => o.provider === 'twitter'
    );

    if (!hasTwitter) {
      validateNoTwitterRecipients(suggestion, validation);
    }

    validateHasRecipients(suggestion, validation);
    validateHasDrafts(suggestion, validation);
    validateCharacterLimit(suggestion, validation);
    validateHasInstructions(suggestion, validation);
    validateInstructionsCharacterLimit(suggestion, validation);
    validateHasSubject(suggestion, validation);
    validateSubjectCharacterLimit(suggestion, validation);
    validateAllDraftsHaveText(suggestion, validation);
    validateAttachments(suggestion, validation, getInvalidPlatforms);
    validateExpiresAtIsNotBeforePostAt(suggestion, validation);
    validatePostAtIsFuture(suggestion, validation);
    validateExpiresAtIsFuture(suggestion, validation);

    return validation;
  }

  return validateSuggestion;
}
