import gql from 'graphql-tag';
import apolloClient from '../apolloClient';

const attachmentProcessingFailed = (id: number | string) => {
  apolloClient.writeFragment({
    id: `Attachment:${id}`,
    fragment: gql`
      fragment attachment on Attachment {
        status
      }
    `,
    data: { status: 'processing_failed', __typename: 'Attachment' },
  });
};

export default attachmentProcessingFailed;
