import React from 'react';
import clsx from 'clsx';
import { CategorizedUploads } from './MediaUpload';
import MediaUploadThumbnail from './MediaUploadThumbnail';
import { useAppSelector } from 'redux/store';
import { getUploadProgress } from 'redux/ducks/uploadProgress';
import { RadialProgress } from 'components';
import { formatFileSize } from 'helpers/formatNumber';
import { useIntl } from 'hooks';
import { IconCheckCircle, IconClose, IconWarningCircle } from 'icons';

interface Props {
  uploadsByCategory: CategorizedUploads;
  uploadsRemaining: number;
  isModalOpen: boolean;
  onOpenModal: () => void;
  onDismiss: () => void;
}

export default function MediaUploadToast(props: Props) {
  const {
    uploadsByCategory,
    isModalOpen,
    onOpenModal,
    onDismiss,
    uploadsRemaining,
  } = props;
  const { t } = useIntl();
  const isVisible =
    !isModalOpen &&
    Object.values(uploadsByCategory).some((uploads) => uploads.length > 0);

  const status = uploadsByCategory.active.length
    ? 'uploading'
    : uploadsByCategory.processing.length
    ? 'processing'
    : uploadsByCategory.queued.length
    ? 'queued'
    : 'complete';

  const firstUpload =
    uploadsByCategory.active[0] ||
    uploadsByCategory.processing[0] ||
    uploadsByCategory.queued[0];

  const progress = useAppSelector((state) => {
    if (!firstUpload) return null;
    return getUploadProgress(state, firstUpload?.id);
  });

  return (
    <div
      className={clsx(
        'fixed bottom-6 left-1/2 z-50 flex w-74 -translate-x-1/2 items-center rounded-lg bg-grey1 px-2 py-1.5 transition-all duration-300',
        !isVisible && 'pointer-events-none translate-y-6 opacity-0'
      )}
    >
      {firstUpload ? (
        <>
          <div className="relative mr-2">
            <MediaUploadThumbnail
              contentType={firstUpload.contentType}
              filename={firstUpload.filename}
              previewUrl={firstUpload.previewUrl}
            />
            {!!progress && (
              <div className="absolute -bottom-px -right-px z-1 rounded-full border border-solid border-grey2 bg-grey2">
                <RadialProgress
                  progress={progress < 100 ? progress : 'infinite'}
                  size={20}
                  theme="socialieDark"
                />
              </div>
            )}
          </div>

          <div className="flex-1 overflow-hidden text-14/20">
            <div className="truncate text-white">{firstUpload.filename}</div>
            <div className="text-light">
              {status === 'uploading' && progress ? (
                <>
                  {formatFileSize(firstUpload.fileSize * (progress / 100))}/
                  {formatFileSize(firstUpload.fileSize)}
                </>
              ) : status === 'processing' ? (
                t('MediaUpload__Processing')
              ) : status === 'queued' ? (
                t('MediaUpload__Queued')
              ) : null}
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="mr-2 flex h-5 w-5 items-center justify-center rounded-lg bg-grey3">
            {uploadsByCategory.failed.length ? (
              <IconWarningCircle
                className={clsx(
                  'block h-3 w-3',
                  uploadsByCategory.active.length ? 'text-orange' : 'text-error'
                )}
              />
            ) : (
              <IconCheckCircle className="block h-3 w-3 text-green" />
            )}
          </div>

          <div className="flex-1 text-white">
            {uploadsByCategory.failed.length &&
            uploadsByCategory.completed.length
              ? t('MediaUpload__SomeUploadsFailed', {
                  successCount: uploadsByCategory.completed.length,
                  failedCount: uploadsByCategory.failed.length,
                })
              : uploadsByCategory.failed.length
              ? t('MediaUpload__AllUploadsFailed', {
                  count: uploadsByCategory.failed.length,
                })
              : t('MediaUpload__AllUploadsSuccessful', {
                  count: uploadsByCategory.completed.length,
                })}
          </div>
        </>
      )}

      <div className="ml-3 flex flex-shrink-0 items-center gap-1">
        <button
          type="button"
          onClick={onOpenModal}
          className="rounded-lg bg-grey3 px-2 py-1 text-14/20 font-semibold text-white"
        >
          <span className="bump-up-1">
            {uploadsRemaining
              ? t('MediaUpload__More', { count: uploadsRemaining })
              : t('MediaUpload__View')}
          </span>
        </button>

        {!uploadsRemaining && (
          <button
            onClick={onDismiss}
            type="button"
            aria-label={t('MediaUpload__Close')}
            className="h-3 w-3 p-0.5 text-14 text-white"
          >
            <IconClose className="block h-full w-full" />
          </button>
        )}
      </div>
    </div>
  );
}
