import React, { useState } from 'react';
import { useQuery } from 'react-apollo';
import moment from 'moment';
import { useIntl, useFilterManager, useAddAttachmentToAlbums } from 'hooks';
import { Modal, Button, Form } from 'components';
import { IconLoader } from 'icons';

import ATTACHMENT_ALBUMS_QUERY from 'graphql/queries/attachmentAlbums.graphql';
import { mapNodes } from 'helpers';
import RelayConnection from 'types/RelayConnection';
import Attachment from 'types/Attachment';
import MediaLibraryFilter from 'types/MediaLibraryFilter';
import AttachmentAlbum from 'types/AttachmentAlbum';

interface AddToAttachmentAlbumModalProps {
  isOpen: boolean;
  attachmentIds: (string | number)[];
  onRequestClose: () => void;
  attachmentImages?: { id: number; src: string; description: string }[];
}

interface QueryData {
  attachmentAlbums: RelayConnection<AttachmentAlbum>;
}

interface QueryVars {
  filter: Partial<MediaLibraryFilter>;
  first: null;
  cursor?: string;
}

const defaultFilter: Readonly<MediaLibraryFilter> = {
  sources: [],
  mediaType: [],
  suggested: '2',
  query: '',
  publishers: [],
  submitters: [],
  suggesters: [],
  suggestionRecipients: [],
  attachmentAlbums: [],
  suggestionAlbums: [],
  suggesterPublishers: [],
  mediaAppearancesOnly: null,
  createdAt: {
    dateRange: 'all',
    startDate: moment().subtract(7, 'days').format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD'),
  },
  canMonetize: false,
  uploaders: [],
};

function AddToAttachmentAlbumModal({
  isOpen,
  attachmentIds,
  attachmentImages,
  onRequestClose,
}: AddToAttachmentAlbumModalProps) {
  const { t } = useIntl();
  const [selectedAlbumIds, setSelectedAlbumIds] = useState<string[]>([]);

  const { isLoading, addToAlbum } = useAddAttachmentToAlbums(
    attachmentIds,
    selectedAlbumIds
  );

  const { debouncedFilter } = useFilterManager(defaultFilter, true);

  const { data, loading } = useQuery<QueryData, QueryVars>(
    ATTACHMENT_ALBUMS_QUERY,
    {
      // first: null fetches all data to be used in the select field.
      // It is not anticipated that users will have lots of albums
      // so the decision was made here not to rebuild the select field
      // to work with pagination and fetching more on scroll.
      variables: { filter: debouncedFilter, first: null },
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
    }
  );

  const attachmentAlbums: {
    id: number;
    name: string;
    attachments: RelayConnection<Attachment>;
  }[] = data?.attachmentAlbums ? mapNodes(data.attachmentAlbums.edges) : [];

  const selectOptions = attachmentAlbums.map((attachmentAlbum) => ({
    label: attachmentAlbum.name,
    value: attachmentAlbum.id,
  }));

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    addToAlbum().then(() => {
      setSelectedAlbumIds([]);
      onRequestClose();
    });
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel={t('AlbumsMedia__AddToAlbum--Heading')}
      renderHeading={t('AlbumsMedia__AddToAlbum--Heading')}
      theme={{ medium: true }}
    >
      <div className="text-left">
        {attachmentImages && attachmentImages.length && (
          <>
            <p className="pb-1 font-sans text-14 font-bold text-grey1">
              {t('AlbumsMedia__AddToAlbum--SelectedMedia')}{' '}
              <span className="text-socialiePink">{`(${attachmentImages.length})`}</span>
            </p>
            <ul className="m-0 flex list-none flex-wrap justify-start p-0 pb-3">
              {attachmentImages
                .slice(0, 5)
                .map(({ id, src, description }, index) => (
                  <li
                    key={id}
                    className="relative mb-2 mr-1 border-solid border-socialiePink p-0.25"
                  >
                    {index === 4 && attachmentImages.length > 5 && (
                      <div className="absolute flex h-9 w-9 items-center justify-center bg-black bg-opacity-70 text-20 text-white ">
                        +{attachmentImages.length - 4}
                      </div>
                    )}
                    <div className="h-9 w-9">
                      <img
                        src={src}
                        alt={description}
                        className="h-9 w-full object-cover"
                      />
                    </div>
                  </li>
                ))}
            </ul>
          </>
        )}

        <form onSubmit={handleSubmit}>
          <div className="mb-3">
            <Form.Field>
              <Form.Label htmlFor="album-search" bold>
                {t('AlbumsMedia__AddToAlbum--AlbumSelectTitle')}
              </Form.Label>
              <Form.Select
                id="album-search"
                options={selectOptions}
                value={!loading && selectedAlbumIds}
                onChange={(e) => setSelectedAlbumIds(e)}
                placeholder={t('AlbumsMedia__AddToAlbum--AlbumSelectTitle')}
                isMulti={true}
              />
            </Form.Field>
          </div>
          <Modal.Actions>
            <Button
              inlineBlock
              filledBg
              disabled={isLoading || !selectedAlbumIds.length}
              type="submit"
            >
              <span className="flex w-full items-center justify-center">
                {isLoading ? (
                  <IconLoader className="h-2 w-2" />
                ) : (
                  <span>{t('AddToAlbum__Button')}</span>
                )}
              </span>
            </Button>
          </Modal.Actions>
        </form>
      </div>
    </Modal>
  );
}

export default AddToAttachmentAlbumModal;
