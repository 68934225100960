import twitterText from 'twitter-text';

const maxChars = {
  twitter: 280,
  facebook: 63206,
  linkedin: 700,
  instagram: Infinity,
};

const getRemainingChars = (caption: string, platformId: string) => {
  if (platformId === 'twitter') {
    // the type definitions for twitterText haven't been updated yet
    const result = twitterText['parseTweet'](caption);
    return maxChars[platformId] - result.weightedLength;
  }

  return maxChars[platformId] - caption.length;
};

export default getRemainingChars;
