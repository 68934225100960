import React from 'react';
import { IconEngagement, IconReach } from 'icons';
import useIntl from 'hooks/useIntl';
import {
  formatAverage,
  formatBigInt,
  formatPercent,
} from 'helpers/formatNumber';
import styled from 'styled-components';

interface Props {
  data?: {
    reach?: number;
    engagement?: number;
    videoViews?: number;
    reelPlays?: number;
    impressions?: number;
  };
  bordered?: boolean;
}

const StatsGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
`;

export default function PerformanceStatsCard({ data, bordered }: Props) {
  if (!data) return null;

  const engagementAverage =
    data.engagement && data.reach ? (data.engagement / data.reach) * 100 : 0;

  return (
    <div
      className={`flex max-w-42 flex-col rounded-lg bg-white p-1.5 ${
        bordered ? 'border border-solid border-grey7' : ''
      }`}
    >
      <StatsGrid>
        <StatsRow
          icon={<IconEngagement />}
          title="PlatformStats__Stat--engagement"
        >
          {data.engagement != null
            ? `${formatAverage(data.engagement)} (${formatPercent(
                engagementAverage
              )})`
            : '--'}
        </StatsRow>
        {/* {!!data.videoViews && (
        <StatsRow icon={<IconView />} title={'PlatformStats__Stat--views'} />
      )} */}
        <StatsRow icon={<IconReach />} title={'PlatformStats__Stat--reach'}>
          {data.reach != null ? formatBigInt(data.reach) : '--'}
        </StatsRow>
        {/* {!!data.impressions && (
        <StatsRow
          icon={<IconEngagement />}
          title={'PlatformStats__Stat--Impressions'}
        />
      )} */}
      </StatsGrid>
    </div>
  );
}

interface StatsRowProps {
  icon: React.ReactNode;
  title: string;
  children?: React.ReactNode;
}

const StatsRow = ({ icon, title, children }: StatsRowProps) => {
  const { t } = useIntl();
  return (
    <>
      <div className="flex items-center">
        <div className="mr-1 h-2 w-2 text-socialiePink">{icon}</div>
        <p className="font-bold text-dark">{t(title)}</p>
      </div>
      <div>
        <p className="align-left whitespace-nowrap text-dark">{children}</p>
      </div>
    </>
  );
};
