import React from 'react';
import { Tooltip } from 'components';
import { useIntl } from 'hooks';

interface Props {
  platform: string;
  children: React.ComponentProps<typeof Tooltip>['children'];
}

export default function InstagramAccountTooltip(props: Props) {
  const { platform, children } = props;
  const { t } = useIntl();

  if (!['instagram_graph', 'instagram_basic'].includes(platform)) {
    return <>{children}</>;
  }

  return (
    <Tooltip
      content={
        <div className="text-left">
          <strong>
            {t(`PublisherAccount__InstagramTooltipHeading--${platform}`)}
          </strong>
          <div className="font-normal">
            {t(`PublisherAccount__InstagramTooltipBody--${platform}`)}
          </div>
        </div>
      }
      theme="socialieDark"
    >
      {children}
    </Tooltip>
  );
}
