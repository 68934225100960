import remove from 'lodash/remove';
import { TypeKeys } from '../ducks/fileUploads';
import { FileUploader } from 'helpers/upload/FileUploader';

interface UploadingItem {
  uploadId: string;
  uploader: FileUploader;
}

const maxUploads = 2;
const uploadQueue: UploadingItem[] = [];
const uploadsInProgress: UploadingItem[] = [];

export const fileUploadMiddleware = (store: any) => {
  return (next: any) => {
    return (action: any) => {
      switch (action.type) {
        case TypeKeys.ADDED:
          startOrQueueUpload(action.payload);
          break;
        case TypeKeys.FILE_READY:
          finishUpload(action.payload.uploadId);
          break;
        case TypeKeys.CANCELED:
          cancelUpload(action.payload.uploadId);
          break;
      }
      return next(action);
    };
  };
};

const startOrQueueUpload = (item: UploadingItem) => {
  if (uploadsInProgress.length < maxUploads) {
    uploadsInProgress.push(item);
    item.uploader.upload();
  } else {
    uploadQueue.push(item);
  }
};

const startNextUpload = () => {
  const nextItem = uploadQueue.shift();
  if (nextItem) {
    uploadsInProgress.push(nextItem);
    nextItem.uploader.upload();
  }
};

const finishUpload = (uploadId: string) => {
  remove(uploadsInProgress, (item) => item.uploadId === uploadId);
  startNextUpload();
};

const cancelUpload = (uploadId: string) => {
  const removed = remove(
    uploadsInProgress,
    (item) => item.uploadId === uploadId
  );

  // If the upload was not in progress, it must be in the queue
  if (removed.length === 0) {
    remove(uploadQueue, (item) => item.uploadId === uploadId);
    return;
  }

  removed.forEach((item) => {
    item.uploader.cancelUpload();
    startNextUpload();
  });
};
