import React from 'react';
import styled from 'styled-components';
import PhoneNumber from 'awesome-phonenumber';
import { grid, colors, font } from 'styles/theme';
import { CountryCode } from './components';
import { IconRemove } from 'icons';

export interface Props {
  value: string;
  countryCode: string;
  onChange: (value: string) => void;
  onChangeCountryCode: (value: string) => void;
  loading?: boolean;
  countryCodes: string[];
  allowWorldwideSms?: boolean;
  countryCodeWhitelist: string[];
  isInvalid?: boolean;
  onFocus?: (e: any) => any;
  onBlur?: (e: any) => any;
  onClear?: () => any;
}

const Wrapper = styled.div`
  position: relative;
  display: flex;
  border-radius: 4px;
  padding: 0;

  input,
  select {
    color: ${colors.darkText};
    font-family: ${font.sans};
    font-size: 14px;

    &:focus {
      outline: none;
      border-color: ${colors.focus};
    }
  }
`;

const PhoneNumberInput = styled.input.attrs({ type: 'text' })`
  appearance: none;
  display: block;
  flex: 1;
  margin: 0;
  border: 1px solid ${colors.border};
  border-left: 0;
  border-radius: 0 4px 4px 0;
  padding: ${grid(1)};
  height: ${grid(4.5)};
  line-height: ${grid(4.5)};

  ${(props: any) => props.theme.isInvalid && `border-color: ${colors.error}`};
`;

class PhoneInput extends React.Component<Props> {
  private phoneNumberRef: React.RefObject<HTMLInputElement> = React.createRef();

  onChangePhoneNumber = (val: string) => {
    this.props.onChange(val);
    if (!this.props.countryCode) {
      this.props.onChangeCountryCode(this.props.countryCodes[0]);
    }
  };

  onChangeCountryCode = (val: string) => {
    this.props.onChangeCountryCode(val);
    this.phoneNumberRef.current && this.phoneNumberRef.current.focus();
  };

  render() {
    const {
      value,
      countryCode,
      countryCodes,
      allowWorldwideSms,
      countryCodeWhitelist,
      onFocus,
      onBlur,
      onClear,
    } = this.props;

    const regionCode = PhoneNumber.getRegionCodeForCountryCode(
      Number(countryCode)
    );

    const ayt = PhoneNumber.getAsYouType(regionCode);
    ayt.reset(value);

    return (
      <Wrapper>
        <CountryCode
          value={countryCode}
          onChange={this.onChangeCountryCode}
          countryCodes={countryCodes}
          allowWorldwideSms={allowWorldwideSms}
          countryCodeWhitelist={countryCodeWhitelist}
          onFocus={onFocus}
          onBlur={onBlur}
        />

        <PhoneNumberInput
          value={ayt.number()}
          onChange={(e) =>
            this.onChangePhoneNumber(e.target.value.replace(/\D+/g, ''))
          }
          onFocus={onFocus}
          onBlur={onBlur}
        />

        {onClear && (
          <button
            type="button"
            onClick={onClear}
            className="absolute right-1 top-1 h-2 w-2"
          >
            <IconRemove className="h-full w-full text-light" />
          </button>
        )}
      </Wrapper>
    );
  }
}

export default PhoneInput;
