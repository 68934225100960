import React, { useState } from 'react';
import styled from 'styled-components';

interface Props {
  srcSet: string;
  preview: string;
}

function MediaLibraryBuilderModalAttachmentImage({ srcSet, preview }: Props) {
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  function onLoadImage() {
    setIsImageLoaded(true);
  }

  return (
    <Wrapper>
      <PreviewImage
        src={preview}
        role="presentation"
        isFullVersionLoaded={isImageLoaded}
      />

      <FullImage
        srcSet={srcSet}
        onLoad={onLoadImage}
        isLoaded={isImageLoaded}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;

  img {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    transition: opacity 0.2s;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
` as any;

const PreviewImage = styled.img`
  z-index: 0;
  filter: blur(10px);

  ${(props: { isFullVersionLoaded: boolean }) =>
    props.isFullVersionLoaded && 'opacity: 0'};
`;

const FullImage = styled.img`
  opacity: 0;
  z-index: 1;

  ${(props: { isLoaded: boolean }) => props.isLoaded && 'opacity: 1'};
`;

export default MediaLibraryBuilderModalAttachmentImage;
