import React from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import clsx from 'clsx';
import { formatDate } from 'helpers';

interface Props {
  suggestion: { expiresAt?: number; actionableType?: string };
  isAttached?: boolean;
}

export default function SuggestionExpiryStatus(props: Props) {
  const {
    suggestion: { expiresAt, actionableType },
    isAttached,
  } = props;
  if (!expiresAt && actionableType !== 'ContentRequest') return null;
  const isExpired = !!expiresAt && expiresAt < new Date().getTime();

  const msg = isExpired
    ? 'SuggestionExpiryStatus__Expired'
    : actionableType === 'ContentRequest' && !expiresAt
    ? 'SuggestionExpiryStatus__ActiveForever'
    : 'SuggestionExpiryStatus__Active';

  const classNames = clsx('text-12 px-1', {
    'bg-greyBg text-dark': isExpired && isAttached,
    'bg-greyBg text-white': isExpired && !isAttached,
    'bg-lightTeal text-teal': !isExpired,
    'rounded h-2.5 bg-opacity-10': !isAttached,
    'rounded-r h-2.75': isAttached,
  });

  return (
    <div className={classNames}>
      <span className="bump-up-2">
        <FormattedHTMLMessage
          tagName="span"
          id={msg}
          values={{ date: formatDate(new Date(expiresAt || 0)) }}
        />
      </span>
    </div>
  );
}
