import React, { useEffect, useState } from 'react';
import useDebouncedCallback from 'use-debounce/lib/callback';
import { IconSearch } from 'icons';

export interface SearchProps {
  onChange: (val: string) => void;
  value: string;
  placeholder?: string;
  searchContainerStyles?: string;
  searchInputStyles?: string;
  isShowIcon?: boolean;
}

function Search(props: SearchProps) {
  const {
    onChange,
    value,
    placeholder,
    searchContainerStyles,
    searchInputStyles,
    isShowIcon = true,
  } = props;
  const [inputValue, setInputValue] = useState(value);

  const debouncedOnChange = useDebouncedCallback(
    (val: string) => onChange(val),
    500
  );

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
    debouncedOnChange(e.target.value);
  };

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  return (
    <div
      data-qa="index-page-search"
      className={`flex flex-1 items-center border-0 border-l border-solid border-border px-2.5 ${
        searchContainerStyles && searchContainerStyles
      }`}
    >
      {isShowIcon && (
        <IconSearch className="h-2 w-2 text-dark tablet:h-2.5 tablet:w-2.5" />
      )}
      <input
        className={`block flex-1 appearance-none border-0 px-1 text-13 text-dark focus:outline-none tablet:text-16 ${
          searchInputStyles && searchInputStyles
        }`}
        type="text"
        value={inputValue}
        onChange={handleChange}
        placeholder={placeholder}
      />
    </div>
  );
}
export default Search;
