import moment from 'moment-timezone';
import * as config from 'config';

const { timezone: tz } = config;

const getDatesFromDateRange = (
  dateRange: string,
  customStart?: string,
  customEnd?: string
) => {
  if (dateRange === 'today') {
    return { start: moment.tz(moment().startOf('day'), tz) };
  }

  if (dateRange === 'custom' && customStart && customEnd) {
    return {
      start: moment(customStart),
      end: moment(customEnd),
    };
  }

  if (dateRange.match(/^last/)) {
    const dayCount = parseInt(dateRange.replace('last', ''), 10);
    const start = moment.tz(
      moment().subtract(dayCount, 'days').startOf('day'),
      tz
    );
    const end = moment.tz(moment().subtract(1, 'days').endOf('day'), tz);

    return { start, end };
  }

  // "all time" with a provided start date
  if ((dateRange === '' || dateRange === 'all') && customStart) {
    return { start: moment(customStart) };
  }

  return { end: moment.tz(moment().endOf('day'), tz) };
};

export default getDatesFromDateRange;
