import React from 'react';
import styled from 'styled-components';
import { grid } from 'styles/theme';
import { Pills } from 'components';
import { useStyledTheme } from 'hooks';

const ValueWrapper = styled.div`
  display: inline-block;
  margin: ${grid(0.25)};
`;

const MultiValue = (props: any) => {
  const theme = useStyledTheme();
  const { data } = props;

  return (
    <ValueWrapper
      onMouseDown={(e: React.MouseEvent<HTMLDivElement>) => e.stopPropagation()}
    >
      <Pills.Pill
        image={data.image}
        imageFallback={
          data.hasOwnProperty('image') && typeof data.label === 'string'
            ? data.label
            : undefined
        }
        {...props.selectProps.pillProps}
        onRemove={props.removeProps.onClick}
        removeProps={props.removeProps}
        color={theme && theme.color === 'dark' ? 'darkerGrey' : undefined}
      >
        {typeof data.label === 'number' && 'Creating...'}
        {data.label?.replace && data.label.replace('NEW__', '')}
      </Pills.Pill>
    </ValueWrapper>
  );
};

export default MultiValue;
