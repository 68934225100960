import React from 'react';
import includes from 'lodash/includes';
import without from 'lodash/without';
import { FormattedMessage } from 'react-intl';
import { Form } from 'components';

interface Props {
  actionableTypes: string[];
  onChange: (actionableTypes: string[]) => void;
}

export default class ActionableTypesFilter extends React.Component<Props> {
  toggleType = (type: string) => {
    const { actionableTypes, onChange } = this.props;
    if (includes(actionableTypes, type)) {
      onChange(without(actionableTypes, type));
    } else {
      onChange([...actionableTypes, type]);
    }
  };

  render() {
    const allTypes = ['Suggestion', 'ContentRequest'];
    const { actionableTypes } = this.props;

    return (
      <Form.Field>
        <Form.Label bold>
          <FormattedMessage id="TrackingActionableTypeFilter__Label" />
        </Form.Label>

        {allTypes.map((type) => (
          <Form.Option key={type}>
            <Form.Checkbox
              checked={includes(actionableTypes, type)}
              onChange={() => this.toggleType(type)}
            />

            <span>
              <FormattedMessage
                id={`TrackingActionableTypeFilter__Option--${type}`}
              />
            </span>
          </Form.Option>
        ))}
      </Form.Field>
    );
  }
}
