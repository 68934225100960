import React from 'react';
import Chat from 'types/Chat';
import Publisher from 'types/Publisher';
import TwilioMessage from 'types/TwilioMessage';
import styled from 'styled-components';
import PublisherAvatar from 'components/PublisherAvatar';
import AvatarStack from 'components/AvatarStack';
import Avatar from 'components/Avatar';
import { grid } from 'styles/theme';
import Tooltip from 'components/Tooltip';
import { useIntl } from 'hooks';

interface Props {
  message: TwilioMessage;
  chat: Chat;
}

const Wrapper = styled.div`
  width: ${grid(3.5)};
  height: ${grid(3.5)};
`;

const MessageAvatar = ({ chat, message }: Props) => {
  const { t } = useIntl();
  const { sender, senderType, suggester } = message;

  return (
    <Wrapper>
      {senderType === 'Publisher' ? (
        chat.publishers.length === 1 ? (
          <PublisherAvatar publisher={sender as Publisher} />
        ) : (
          <AvatarStack
            avatars={chat.publishers.map((p) => ({
              id: p.id,
              src: p.image,
              name: p.name,
            }))}
          />
        )
      ) : (
        <Tooltip
          theme="socialieDark"
          content={
            message.senderType === 'User'
              ? message.sender?.name || t('ChatPanel__MissingSender')
              : t('ChatPanel__SocialieGenerated')
          }
        >
          <div>
            <Avatar src={suggester.accountConfiguration.logoUrl} />
          </div>
        </Tooltip>
      )}
    </Wrapper>
  );
};

export default MessageAvatar;
