import styled from 'styled-components';
import { grid, colors } from 'styles/theme';

const Wrapper = styled.div`
  display: flex;
  padding: 0 ${grid(2.5)};

  a {
    display: block;
    margin-right: ${grid(3)};
    border-top: 2px solid transparent;
    padding: ${grid(0.25)} ${grid(1.5)} ${grid(0.5)};
    line-height: ${grid(3)};
    color: ${colors.white};

    &:last-child {
      margin-right: 0;
    }

    &.active {
      border-color: ${colors.socialiePink};
      background-color: ${colors.greyBg};
      color: ${colors.darkText};
    }
  }
`;

export default Wrapper;
