import React from 'react';
import sortBy from 'lodash/sortBy';
import styled from 'styled-components';
import { Mutation } from '@apollo/react-components';
import { FormattedMessage } from 'react-intl';
import Publisher from 'types/Publisher';
import IndexPublisher from 'types/IndexPublisher';
import { Modal, Form, Button, WithCurrentUser, WithIntl } from 'components';
import * as MOVE_PUBLISHERS from 'graphql/mutations/movePublishers.graphql';
import { grid } from 'styles/theme';

interface Props {
  publishers: (Publisher | IndexPublisher)[];
  children: (onClick: () => void) => React.ReactNode;
  onCompleted?: Function;
}

interface State {
  isModalOpen: boolean;
  destinationId?: number;
}

const Message = styled.div`
  margin-bottom: ${grid(3)};
  text-align: left;
  line-height: ${grid(2.5)};
`;

interface MutationData {
  movePublishers: { errors?: string; publishers: Publisher[] };
}

export default class MovePublisher extends React.Component<Props, State> {
  state: State = { isModalOpen: false };

  openModal = () => this.setState({ isModalOpen: true });

  closeModal = () => this.setState({ isModalOpen: false });

  onCompleted = (data: any) => {
    if (!data.movePublishers.errors) {
      this.setState({ isModalOpen: false }, () => {
        this.props.onCompleted && this.props.onCompleted(data);
      });
    }
  };

  render() {
    const ids = this.props.publishers.map((p) => p.id);
    const count = ids.length;
    const publisher = this.props.publishers[0];

    return (
      <WithCurrentUser>
        {(currentUser) => {
          const currentSuggester = currentUser.currentSuggester!;
          if (!currentSuggester.children.length && !currentSuggester.parent) {
            return null;
          }
          const destinationId = currentSuggester.parent
            ? currentSuggester.parent.id
            : this.state.destinationId;

          return (
            <WithIntl>
              {(_, t) => (
                <Mutation<MutationData>
                  mutation={MOVE_PUBLISHERS}
                  onCompleted={this.onCompleted}
                  variables={{ ids, destinationId }}
                  refetchQueries={['PublishersQuery', 'PublisherFilter']}
                >
                  {(movePublishers, { loading }) => (
                    <>
                      {this.props.children(this.openModal)}

                      <Modal
                        isOpen={this.state.isModalOpen}
                        onRequestClose={this.closeModal}
                        contentLabel={t('MovePublisher__Heading', { count })}
                        renderHeading={
                          <FormattedMessage
                            id="MovePublisher__Heading"
                            values={{ count }}
                          />
                        }
                      >
                        {currentSuggester.parent ? (
                          <Message>
                            <FormattedMessage
                              id="MovePublisher__MoveToParent"
                              values={{
                                count,
                                publisher: <strong>{publisher.name}</strong>,
                                name: (
                                  <strong>
                                    {currentSuggester.parent.accountName}
                                  </strong>
                                ),
                              }}
                            />
                          </Message>
                        ) : (
                          <Form.Field>
                            <Form.Label>
                              <FormattedMessage id="MovePublisher__ChooseChild" />
                            </Form.Label>

                            <Form.Select
                              options={[
                                {
                                  value: currentSuggester.id,
                                  label: currentSuggester.accountName,
                                  image:
                                    currentSuggester.accountConfiguration
                                      .logoUrl,
                                },
                                ...sortBy(
                                  currentSuggester.children,
                                  (child) => child && child.accountName
                                ).map((child) => ({
                                  label: child.accountName,
                                  value: child.id,
                                  image: child.accountConfiguration.logoUrl,
                                })),
                              ]}
                              value={destinationId}
                              onChange={(v) =>
                                this.setState({ destinationId: v })
                              }
                            />
                          </Form.Field>
                        )}

                        <Modal.Actions>
                          <Button inlineBlock cancel onClick={this.closeModal}>
                            <FormattedMessage id="Global__Cancel" />
                          </Button>

                          <Button
                            inlineBlock
                            filledBg
                            disabled={loading || !destinationId}
                            onClick={() => movePublishers()}
                          >
                            <FormattedMessage id="MovePublisher__Move" />
                          </Button>
                        </Modal.Actions>
                      </Modal>
                    </>
                  )}
                </Mutation>
              )}
            </WithIntl>
          );
        }}
      </WithCurrentUser>
    );
  }
}
