import React from 'react';
import styled from 'styled-components';
import { Mutation } from '@apollo/react-components';
import { Pills } from '..';
import Tag from 'types/Tag';
import * as mutation from 'graphql/mutations/removeTagsFromSuggestions.graphql';

interface Props {
  suggestionId: number | string;
  tag: Tag;
  large?: boolean;
  small?: boolean;
}

const PillWrapper = styled.div`
  ${(props: any) => props.isRemoving && 'opacity: 0.3'};
  line-height: normal;
` as any;

interface MutationData {
  removeTagsFromSuggestions: { suggestions: { id: string }[] };
}

const SuggestionTag = ({ tag, large, small, suggestionId }: Props) => {
  const variables = { suggestionIds: suggestionId, tagIds: tag.id };
  return (
    <Mutation<MutationData>
      key={tag.id}
      mutation={mutation}
      variables={variables}
    >
      {(removeTagsFromSuggestions, { loading }) => (
        <PillWrapper isRemoving={loading}>
          <Pills.Pill
            large={large}
            small={small}
            onRemove={removeTagsFromSuggestions}
          >
            {tag.name}
          </Pills.Pill>
        </PillWrapper>
      )}
    </Mutation>
  );
};

export default SuggestionTag;
