import React from 'react';
import styled from 'styled-components';
import { grid } from 'styles/theme';
import Recipient from 'types/Recipient';
import { Pills } from 'components';
import ContactMethodTooltip from './ContactMethodTooltip';

interface Props {
  recipient: Recipient;
  selectProps: any;
}

const Inner = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

const Name = styled.div`
  margin-right: ${grid(0.5)};
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: ${grid(2)};
  word-break: break-all;
`;

const NewContactPill = ({ recipient, selectProps }: Props) => {
  if (!recipient.newContactProtocol || !recipient.newContactValue) return null;

  return (
    <Pills.Pill
      {...selectProps.pillProps}
      onRemove={() => selectProps.onRemoveRecipient(recipient)}
    >
      <Inner>
        <Name>{recipient.newContactValue}</Name>
        <ContactMethodTooltip
          protocol={recipient.newContactProtocol}
          value={recipient.newContactValue}
        />
      </Inner>
    </Pills.Pill>
  );
};

export default NewContactPill;
