import React from 'react';
import isEqual from 'lodash/isEqual';
import { FormattedMessage } from 'react-intl';
import Suggestion from 'types/Suggestion';
import { Form } from 'components';

interface Props {
  suggestions: Suggestion[];
  onChange: (mediaType: string) => void;
}

interface State {
  mediaType: string;
}

const options = [
  { value: 'Photo', label: 'MediaType--Photo' },
  { value: 'Video', label: 'MediaType--Video' },
  { value: 'Any', label: 'MediaType--Any' },
];

export default class MediaType extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      mediaType: this.getInitialValue(),
    };
  }

  componentDidUpdate(prevProps: Props) {
    const prevIds = prevProps.suggestions.map((s) => s.id);
    const currentIds = this.props.suggestions.map((s) => s.id);
    if (!isEqual(prevIds, currentIds)) {
      this.setState({ mediaType: this.getInitialValue() });
      return;
    }
  }

  getInitialValue = () => {
    const { suggestions } = this.props;
    if (suggestions.length !== 1) return '';
    return suggestions[0].mediaType || '';
  };

  onChange = (mediaType: string) => {
    this.setState({ mediaType });
    this.props.onChange(mediaType);
  };

  render() {
    const { mediaType } = this.state;

    return (
      <Form.Field>
        <Form.Label bold>
          <FormattedMessage id="SuggestionForm__MediaType" />
        </Form.Label>

        <Form.ToggleGroup>
          {options.map((o) => (
            <Form.ToggleGroup.Option
              key={o.value}
              onSelect={() => this.onChange(o.value)}
              isSelected={mediaType === o.value}
              title={<FormattedMessage id={o.label} />}
            />
          ))}
        </Form.ToggleGroup>
      </Form.Field>
    );
  }
}
