import React from 'react';
import Tooltip from 'components/Tooltip';
import { IconFace } from 'icons';
import { useIntl } from 'hooks';

interface Props {
  publisher: { facePrints?: any[] };
  theme?: { heading?: boolean };
}

export default function FacePrintsIndicator({ publisher, theme }: Props) {
  const { t } = useIntl();
  const hasFacePrints = publisher.facePrints && !!publisher.facePrints.length;
  if (!hasFacePrints) return null;
  let classNames = 'bump-down-0.5 text-lightIcon ';
  classNames += theme && theme.heading ? 'ml-1 w-2.5 h-2.5' : 'ml-0.5 w-2 h-2';

  return (
    <Tooltip content={t('FacePrintsIndicator__Tooltip')} theme="socialieDark">
      <IconFace
        className={classNames}
        aria-label={t('FacePrintsIndicator__Tooltip')}
      />
    </Tooltip>
  );
}
