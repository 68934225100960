import React from 'react';
import { IconWhatsApp, IconPhone } from 'icons';

interface Props {
  phoneNumber: string;
}

export default function ContactMethodIcon({ phoneNumber }: Props) {
  return phoneNumber.match(/whatsapp/g) ? (
    <IconWhatsApp className="ml-0.75 h-2 w-2 text-grey6" />
  ) : (
    <IconPhone className="bump-down-1 ml-0.75 h-2 w-2 text-grey6" />
  );
}
