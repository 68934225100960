import React, { useState } from 'react';
import { createPortal } from 'react-dom';
import styled, { keyframes } from 'styled-components';
import { grid, colors } from 'styles/theme';
import { Home, Chat, ToggleButton } from './components';
import ChatPanelContext from './ChatPanelContext';
import { GlobalCSSVariables } from 'Layout/components/GlobalStyle';

export type Screens = 'home' | 'chat';

interface Props {
  isOpen: boolean;
  toggleOpen: () => void;
  hasUnreadMessages: boolean;
}

const slideIn = keyframes`
  from {
    transform: translateX(100%);
    opacity: 0;
  }

  to {
    transform: translateX(0);
    opacity: 1;
  }
`;

// prettier-ignore
const PanelWrapper = styled.div`
  display: block;
  position: fixed;
  top: var(${GlobalCSSVariables.topOffset});
  right: 0;
  z-index: 1003;
  border-left: 1px solid ${colors.lightBorder};
  box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.05);
  background-color: ${colors.white};
  width: ${grid(50)};
  height: calc(100vh - var(${GlobalCSSVariables.topOffset}));
  animation: ${slideIn} 0.15s forwards ease-out;
` as any;

export default function ChatPanel(props: Props) {
  const [screen, setScreen] = useState<Screens>('home');
  const [chatId, setChatId] = useState<string | number | undefined>('new');

  const gotoScreen = (screen: Screens, chatId?: string | number) => {
    setScreen(screen);
    setChatId(chatId);
  };

  const toggleOpen = () => {
    props.toggleOpen();
    setScreen('home');
  };

  return (
    <ChatPanelContext.Provider value={{ gotoScreen: gotoScreen }}>
      <ToggleButton
        isOpen={props.isOpen}
        toggleOpen={toggleOpen}
        hasUnreadMessages={props.hasUnreadMessages}
      />

      {props.isOpen &&
        createPortal(
          <PanelWrapper>
            {screen === 'home' && <Home />}

            {screen === 'chat' &&
              (!chatId ? (
                <span>Set a chatId when going to chat screen</span>
              ) : (
                <Chat gotoScreen={gotoScreen} id={chatId} />
              ))}
          </PanelWrapper>,
          document.body
        )}
    </ChatPanelContext.Provider>
  );
}
