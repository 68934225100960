import React from 'react';
import {
  Albums,
  Campaign,
  Captions,
  ExampleContent,
  Expiration,
  ExpiresAt,
  Instructions,
  MediaType,
  Orientation,
  Scheduling,
  Subject,
  Tags,
  TalkingPoints,
  VideoLength,
  CanMonetize,
} from './components';

export const allFields = [
  'recipients',
  'mediaType',
  'subject',
  'instructions',
  'talkingPoints',
  'orientation',
  'videoLength',
  'exampleContent',
  'expiresAt',
  'platforms',
  'captions',
  'tracking',
  'attachmentAlbums',
  'scheduling',
  'expiration',
  'canMonetize',
];

interface Props {
  children: React.ReactNode;
}

export default function SuggestionForm({ children }: Props) {
  return <div>{children}</div>;
}

SuggestionForm.Albums = Albums;
SuggestionForm.Captions = Captions;
SuggestionForm.Campaign = Campaign;
SuggestionForm.ExpiresAt = ExpiresAt;
SuggestionForm.Expiration = Expiration;
SuggestionForm.Instructions = Instructions;
SuggestionForm.MediaType = MediaType;
SuggestionForm.Orientation = Orientation;
SuggestionForm.ExampleContent = ExampleContent;
SuggestionForm.Scheduling = Scheduling;
SuggestionForm.Subject = Subject;
SuggestionForm.Tags = Tags;
SuggestionForm.TalkingPoints = TalkingPoints;
SuggestionForm.VideoLength = VideoLength;
SuggestionForm.CanMonetize = CanMonetize;
