import styled from 'styled-components';
import { grid, colors } from 'styles/theme';

interface ColorSelectorProps {
  primaryColor: string;
}

const ColorSelector = styled.div<ColorSelectorProps>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${grid(5.5)};
  height: ${grid(5.5)};
  min-width: ${grid(5.5)};
  margin-right: ${grid(1.5)};
  border: ${(props) =>
    props.primaryColor ? 'none' : `2px dashed ${colors.grey6}`};
  border-radius: 50%;
  background-color: ${(props) =>
    props.primaryColor ? props.primaryColor : 'none'};
  cursor: pointer;
  svg {
    display: ${(props) => (props.primaryColor ? 'none' : 'block')};
    color: ${(props) => (props.primaryColor ? '#F0F2F5' : colors.grey6)};
    opacity: 0.5;
  }
  &:hover {
    border: ${(props) =>
      props.primaryColor ? 'none' : `2px dashed ${colors.grey5}`};
    svg {
      display: block;
      opacity: ${(props) => (props.primaryColor ? 0.6 : 1)};
      color: ${(props) => (props.primaryColor ? '#F0F2F5' : colors.grey5)};
    }
  }
`;

export default ColorSelector;
