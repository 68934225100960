import { Switch } from 'components';
import gql from 'graphql-tag';
import { flashMessage } from 'helpers';
import { useIntl } from 'hooks';
import React from 'react';
import { useMutation } from 'react-apollo';
import Attachment from 'types/Attachment';

interface Props {
  attachment: Attachment;
}

const UPDATE_CAN_MONETIZE = gql`
  mutation updateCanMonetize($ids: [ID!]!, $canMonetize: Boolean!) {
    updateCanMonetize(ids: $ids, canMonetize: $canMonetize) {
      attachments {
        id
        canMonetize
      }
      errors
    }
  }
`;

interface MutationPayload {
  updateCanMonetize: {
    __typename: 'UpdateCanMonetizePayload';
    errors?: string;
    attachments?: {
      id: number;
      canMonetize: boolean;
      __typename: 'Attachment';
    }[];
  };
}
export default function CanMonetize({ attachment }: Props) {
  const { t } = useIntl();

  const [updateCanMonetize] = useMutation<MutationPayload>(
    UPDATE_CAN_MONETIZE,
    {
      onCompleted: (data) => {
        if (data.updateCanMonetize.errors) {
          console.error(data.updateCanMonetize.errors);
          flashMessage('Global__UnexpectedError', { style: 'error' });
        }
      },
      onError: (error) => {
        console.error(error);
        flashMessage('Global__UnexpectedError', { style: 'error' });
      },
    }
  );

  const handleToggle = async () => {
    // const nextValue = !canMonetize;
    updateCanMonetize({
      variables: { ids: attachment.id, canMonetize: !attachment.canMonetize },
      optimisticResponse: {
        updateCanMonetize: {
          __typename: 'UpdateCanMonetizePayload',
          attachments: [
            {
              __typename: 'Attachment',
              id: attachment.id,
              canMonetize: !attachment.canMonetize,
            },
          ],
        },
      },
    });
  };

  return (
    <div className="flex items-center space-x-0.5 text-dark">
      <Switch isOn={!!attachment.canMonetize} onToggle={handleToggle} />{' '}
      <span className="bump-up-1">{t('Attachment__CanMonetize')}</span>
    </div>
  );
}
