import React from 'react';
import styled from 'styled-components';
import { IconTriangleUp, IconTriangleDown } from 'icons';
import { grid, colors } from 'styles/theme';

interface Props {
  isSortedAsc?: boolean;
  isSortedDesc?: boolean;
  onSortAsc: () => void;
  onSortDesc: () => void;
}

const Wrapper = styled.div`
  position: relative;
  top: 2px;
  display: flex;
  flex-direction: column;
  padding: 0 ${grid(0.5)};
  width: ${grid(2)};
  height: ${grid(2)};
`;

const Arrow = styled.button`
  display: block;
  width: ${grid(1)};
  height: ${grid(1)};
  color: ${colors.darkText};
  opacity: 0.5;

  ${(props: any) => props.active && 'opacity: 1'};

  svg {
    display: block;
    width: ${grid(1)};
    height: ${grid(1)};
  }
` as any;

const SortArrows = (props: Props) => (
  <Wrapper>
    <Arrow onClick={props.onSortAsc} active={props.isSortedAsc}>
      <IconTriangleUp />
    </Arrow>

    <Arrow onClick={props.onSortDesc} active={props.isSortedDesc}>
      <IconTriangleDown />
    </Arrow>
  </Wrapper>
);

export default SortArrows;
