import React, { useState } from 'react';
import styled from 'styled-components';
import RecipientOption from 'types/RecipientOption';
import ChatType from 'types/Chat';
import FileUpload from 'types/FileUpload';
import { Heading, MessageHistory, Recipients, MessageForm } from './components';
import ErrorDebug from 'components/ErrorDebug';

interface Props {
  chat?: ChatType;
  fileUpload?: FileUpload;
  isNew: boolean;
  isLoading: boolean;
  isSending: boolean;
  error?: any;
  phoneNumbers: string[];
  messageText: string;
  newRecipients: RecipientOption[];
  onSendMessage: () => void;
  onChangeRecipients: (recipients: RecipientOption[]) => void;
  onChangeMessageText: (value: string) => void;
}

const Wrapper = styled.div`
  display: flex;
  align-items: stretch;
  flex-direction: column;
  height: 100%;
`;

const Chat = ({
  chat,
  fileUpload,
  isNew,
  isLoading,
  isSending,
  error,
  messageText,
  phoneNumbers,
  newRecipients,
  onChangeRecipients,
  onChangeMessageText,
  onSendMessage,
}: Props) => {
  const [isThreadExpired, setIsThreadExpired] = useState(false);
  return (
    <Wrapper>
      <Heading error={error} loading={isLoading} isNew={isNew} chat={chat} />

      {isNew && (
        <Recipients value={newRecipients} onChange={onChangeRecipients} />
      )}

      <MessageHistory chat={chat} setIsThreadExpired={setIsThreadExpired} />

      {error && <ErrorDebug error={error} />}

      <MessageForm
        chatId={isNew || !chat ? 'new' : chat.id}
        value={messageText}
        fileUpload={fileUpload}
        autoFocus={!isNew}
        onChange={onChangeMessageText}
        onSend={onSendMessage}
        isThreadExpired={isThreadExpired}
        fieldDisabled={!!isLoading || !!isSending || !!error}
        sendDisabled={
          !phoneNumbers ||
          !phoneNumbers.length ||
          !!isLoading ||
          !!error ||
          !!isSending ||
          (!messageText && !fileUpload)
        }
      />
    </Wrapper>
  );
};

export default Chat;
