import React from 'react';
import moment from 'moment-timezone';
import Suggestion from 'types/Suggestion';
import IndexBatchSuggestion from 'types/IndexBatchSuggestion';
import {
  WithIntl,
  Modal,
  DateTimePicker,
  Button,
  Form,
  FormattedDateTime,
} from 'components';
import { FormattedMessage } from 'react-intl';

interface Props {
  value?: Date;
  isOpen: boolean;
  isLoading?: boolean;
  onRequestClose: () => void;
  onConfirm: (timestamp: Date) => void;
  suggestions: (Suggestion | IndexBatchSuggestion['suggestions'][number])[];
  headingText: string;
  buttonText: string;
}

interface State {
  sendAt: Date;
  error?: 'NOT_IN_FUTURE' | 'NOT_BEFORE_SCHEDULED_POSTS' | 'NOT_BEFORE_EXPIRY';
}

const diffInMinutes = (laterTime: number, earlierTime: number) =>
  (laterTime - earlierTime) / 1000 / 60;

class ScheduleBatchSuggestion extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      sendAt: this.getInitialSendAt(),
    };
  }

  componentDidUpdate(prevProps: Props) {
    if (!prevProps.isOpen && this.props.isOpen) {
      this.setState({ sendAt: this.getInitialSendAt(), error: undefined });
    }
  }

  handleSend = () => {
    this.validate() && this.props.onConfirm(this.state.sendAt);
  };

  validate = () => {
    const sendAtTime = this.state.sendAt.getTime();
    const now = new Date().getTime();

    if (diffInMinutes(sendAtTime, now) < 1) {
      this.setState({ error: 'NOT_IN_FUTURE' });
      return false;
    }

    const postTimes = this.getPostTimes();
    if (postTimes[0] && diffInMinutes(postTimes[0], sendAtTime) < 60) {
      this.setState({ error: 'NOT_BEFORE_SCHEDULED_POSTS' });
      return false;
    }

    const expiryTimes = this.getExpiryTimes();
    if (expiryTimes[0] && diffInMinutes(expiryTimes[0], sendAtTime) < 60) {
      this.setState({ error: 'NOT_BEFORE_EXPIRY' });
      return false;
    }

    this.setState({ error: undefined });
    return true;
  };

  getInitialSendAt = () => {
    if (this.props.value) return this.props.value;

    const postTimes = this.getPostTimes();
    const date = !!postTimes.length
      ? moment(postTimes[0]).subtract(2, 'hours').set({ seconds: 0 })
      : moment().add(1, 'day').set({ seconds: 0 });
    return date.toDate();
  };

  getPostTimes = () =>
    this.props.suggestions
      .map((s) => s.postAt)
      .filter(Boolean)
      .sort() as number[];

  getExpiryTimes = () =>
    this.props.suggestions
      .map((s) => s.expiresAt)
      .filter(Boolean)
      .sort() as number[];

  render() {
    const { isOpen, isLoading, onRequestClose, headingText, buttonText } =
      this.props;
    const { error } = this.state;
    const postTimes = this.getPostTimes();
    const expiryTimes = this.getExpiryTimes();

    return (
      <WithIntl>
        {(_, t) => (
          <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            contentLabel={t(headingText)}
            renderHeading={t(headingText)}
          >
            <Form.Field>
              <FormattedMessage id="ScheduleBatchSuggestion__Explanation" />
            </Form.Field>

            <Form.Field>
              <DateTimePicker
                value={this.state.sendAt}
                onChange={(value) => this.setState({ sendAt: value })}
              />

              {error === 'NOT_IN_FUTURE' && (
                <div data-testid="errors">
                  <Form.Errors errors="ScheduleBatchSuggestion__MustBeFuture" />
                </div>
              )}

              {error === 'NOT_BEFORE_SCHEDULED_POSTS' && (
                <Form.Errors>
                  <FormattedMessage
                    id="ScheduleBatchSuggestion__ScheduleConflict"
                    values={{
                      earliestTime: (
                        <FormattedDateTime
                          value={new Date(postTimes[0])}
                          separator={` ${t('Global__At').toLowerCase()} `}
                        />
                      ),
                    }}
                  />
                </Form.Errors>
              )}

              {error === 'NOT_BEFORE_EXPIRY' && (
                <Form.Errors>
                  <FormattedMessage
                    id="ScheduleBatchSuggestion__ExpiryConflict"
                    values={{
                      earliestTime: (
                        <FormattedDateTime
                          value={new Date(expiryTimes[0])}
                          separator={` ${t('Global__At').toLowerCase()} `}
                        />
                      ),
                    }}
                  />
                </Form.Errors>
              )}
            </Form.Field>

            <Modal.Actions>
              <Button inlineBlock cancel onClick={onRequestClose}>
                {t('Global__Cancel')}
              </Button>

              <Button
                data-testid="submit"
                inlineBlock
                filledBg
                onClick={this.handleSend}
                disabled={isLoading}
              >
                {t(buttonText)}
              </Button>
            </Modal.Actions>
          </Modal>
        )}
      </WithIntl>
    );
  }
}

export default ScheduleBatchSuggestion;
