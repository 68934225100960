import React, { useState, useEffect } from 'react';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import Modal from '../Modal';
import Button from '../Button';
import styled from 'styled-components';
import { grid } from 'styles/theme';
import { useIntl } from 'hooks';
import { Form } from 'components';

interface Props {
  isOpen: boolean;
  isLoading?: boolean;
  isConfirmDisabled?: boolean;
  onRequestClose: () => void;
  onConfirm: () => any;
  children?: React.ReactNode;
  confirmText?: string;
  cancelText?: string;
  confirmationPhrase?: string;
  heading?: string;
  hideCancel?: boolean;
}

const Message = styled.div`
  margin-bottom: ${grid(3)};
  text-align: left;
  line-height: ${grid(2.5)};
`;

const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  > * {
    margin-right: ${grid(3)};

    &:last-child {
      margin-right: 0;
    }
  }
`;

export default function ConfirmModal(props: Props) {
  const {
    isOpen,
    onRequestClose,
    onConfirm,
    isLoading,
    children,
    heading,
    confirmationPhrase,
    hideCancel,
    confirmText = 'Global__Yes',
    cancelText = 'Global__No',
  } = props;
  const [typedPhrase, setTypedPhrase] = useState('');

  const isConfirmDisabled =
    props.isConfirmDisabled ||
    (!!confirmationPhrase && typedPhrase !== confirmationPhrase);

  const { t } = useIntl();

  useEffect(() => {
    setTypedPhrase('');
  }, [isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      theme={{ small: false, xlarge: false, large: false }}
      onRequestClose={onRequestClose}
      contentLabel={t('Global__AreYouSure')}
      renderHeading={
        <FormattedMessage id={heading || 'Global__AreYouSureShort'} />
      }
    >
      <Form onSubmit={onConfirm}>
        <Message>
          {children || <FormattedMessage id="Global__AreYouSure" />}

          {confirmationPhrase && (
            <div className="mt-2">
              <label id="confirmationLabel" className="mb-1 block text-dark">
                <FormattedHTMLMessage
                  tagName="span"
                  id="ConfirmMessage__ConfirmationPhrase"
                  values={{ confirmationPhrase }}
                />
              </label>

              <Form.TextInput
                value={typedPhrase}
                onChange={(e) => setTypedPhrase(e.target.value)}
                aria-labelledby="confirmationLabel"
                autoFocus
              />
            </div>
          )}
        </Message>

        <Actions>
          {!hideCancel && (
            <div>
              <Button
                cancel
                onClick={onRequestClose}
                disabled={isLoading}
                type="button"
              >
                <FormattedMessage id={cancelText} />
              </Button>
            </div>
          )}

          <div>
            <Button
              filledBg
              disabled={isLoading || isConfirmDisabled}
              type="submit"
            >
              <FormattedMessage id={confirmText} />
            </Button>
          </div>
        </Actions>
      </Form>
    </Modal>
  );
}
