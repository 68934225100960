import React from 'react';
import { useSuggestAttachments } from 'hooks';
import { IconLoader, IconSuggestion } from 'icons';
import Button from 'components/Button';
import { useIntl } from 'hooks';

interface Props {
  attachmentId: string;
}

export default function SuggestAttachmentButton({ attachmentId }: Props) {
  const { t } = useIntl();
  const { isLoading: isSuggesting, suggestAttachments } =
    useSuggestAttachments(attachmentId);
  return (
    <Button
      filledBg
      inlineBlock
      onClick={suggestAttachments}
      disabled={isSuggesting}
    >
      {isSuggesting ? (
        <IconLoader className="ml-1" />
      ) : (
        <>
          <IconSuggestion /> {t('AttachmentModal__Suggest')}
        </>
      )}
    </Button>
  );
}
