import range from 'lodash/range';
import { css } from 'styled-components';
import media from './media';
import { grid } from './theme';

interface BreakpointList {
  [key: string]: { margin?: number; perRow?: number };
}

const clearPreviousMargins = (perRow: number, margin: number) => {
  const rules = range(perRow - 1).map((i) => {
    const child = i + 1;
    return `&:nth-child(${child}n+${child}) { margin-right: ${grid(margin)}; }`;
  });

  return css`
    ${rules};
  `;
};

const widthAndMargin = (perRow: number, margin: number) => `
  width: calc((100% - ${grid(margin * (perRow - 1))}) / ${perRow});
  margin-right: ${grid(margin)};

  &:nth-child(${perRow}n+${perRow}) {
    margin-right: 0;
  }
`;

const breakpointRules = (
  breakpoints: BreakpointList,
  defaultPerRow: number,
  defaultMargin: number
) => css`
  ${Object.keys(breakpoints).map((key) => {
    const breakpoint = breakpoints[key];
    const perRow = breakpoint.perRow || defaultPerRow;
    const margin = breakpoint.margin || defaultMargin;
    return media[key]`
        ${clearPreviousMargins(perRow, margin)}
        ${widthAndMargin(perRow, margin)}
        `;
  })};
`;

const gridItem = (
  perRow: number,
  margin: number,
  breakpoints: BreakpointList
) => css`
  ${widthAndMargin(perRow, margin)};
  ${breakpointRules(breakpoints, perRow, margin)};
`;

export default gridItem;
