import { OtherAction } from './global';

export enum TypeKeys {
  CONNECTED = 'publisher/socket/CONNECTED',
  DISCONNECTED = 'publisher/socket/DISCONNECTED',
}

export interface ConnectedAction {
  type: TypeKeys.CONNECTED;
  payload: { id: string };
}

export interface DisconnectedAction {
  type: TypeKeys.DISCONNECTED;
}

type ActionTypes = ConnectedAction | DisconnectedAction | OtherAction;

export enum SocketStatus {
  NOT_CONNECTED = 'NOT_CONNECTED',
  CONNECTED = 'CONNECTED',
}

export type State = {
  id?: string;
  status: SocketStatus;
};

const initialState = { status: SocketStatus.NOT_CONNECTED };

const socketReducer = (state: State = initialState, action: ActionTypes) => {
  switch (action.type) {
    case TypeKeys.CONNECTED:
      return { id: action.payload.id, status: SocketStatus.CONNECTED };
    case TypeKeys.DISCONNECTED:
      return { status: SocketStatus.NOT_CONNECTED };
    default:
      return state;
  }
};

// Action Creators
export const socketConnected = (id: string) => ({
  type: TypeKeys.CONNECTED,
  payload: { id },
});

export const socketDisconnected = () => ({ type: TypeKeys.DISCONNECTED });

export default socketReducer;
