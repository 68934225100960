import clsx from 'clsx';
import React from 'react';

interface Props {
  progress: number | 'infinite';
  size: number;
  strokeWidth?: number;
  theme?: 'socialie' | 'socialieDark';
}

function RadialProgress({
  progress,
  size,
  strokeWidth = 2,
  theme = 'socialie',
}: Props) {
  const progressValue = progress === 'infinite' ? 75 : progress;
  const radius = (size - strokeWidth) / 2;
  const circumference = 2 * Math.PI * radius;
  const progressOffset = ((100 - progressValue) / 100) * circumference;
  const inactiveClass =
    theme === 'socialie'
      ? 'text-grey8'
      : theme === 'socialieDark'
      ? 'text-grey3'
      : 'text-grey8';

  const activeClass =
    theme === 'socialie' || theme === 'socialieDark'
      ? 'text-socialiePink'
      : 'text-socialiePink';

  return (
    <svg
      className={clsx(
        'mx-auto block',
        progress === 'infinite' && 'animate-spin'
      )}
      width={size}
      height={size}
    >
      <circle
        className={inactiveClass}
        cx={size / 2}
        cy={size / 2}
        r={radius}
        stroke="currentColor"
        strokeWidth={strokeWidth}
        fill="none"
      />
      <circle
        className={activeClass}
        cx={size / 2}
        cy={size / 2}
        r={radius}
        strokeWidth={strokeWidth}
        stroke="currentColor"
        fill="none"
        strokeDasharray={circumference}
        strokeDashoffset={progressOffset}
        strokeLinecap="round"
      />
    </svg>
  );
}

export default RadialProgress;
