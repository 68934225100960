import React from 'react';
import styled from 'styled-components';
import { grid } from 'styles/theme';
import Form from '../Form';

interface Props {
  toggleSelected: () => void;
  isSelected: boolean;
}

const Wrapper = styled.div`
  margin-right: ${grid(2)};
`;

const ToggleSelected = ({ toggleSelected, isSelected }: Props) => (
  <Wrapper>
    <Form.Checkbox onChange={toggleSelected} checked={isSelected} />
  </Wrapper>
);

export default ToggleSelected;
