import React, { useState } from 'react';
import SocialAccountType from 'types/SocialAccount';
import { useIntl, useIsMounted } from 'hooks';
import { IconLoader } from 'icons';
import Button from 'components/Button';
import SocialAccount from 'components/SocialAccount';

interface Props {
  searchResults: SocialAccountType[];
  noResults?: boolean;
  platform: string;
  onSave: (socialAccount: SocialAccountType) => Promise<void>;
  selectText?: string;
}

export default function SocialAccountSearchResults(props: Props) {
  const isMounted = useIsMounted();
  const { searchResults, noResults, platform, onSave, selectText } = props;
  const { t } = useIntl();
  const [savingId, setSavingId] = useState<string | null>(null);

  const handleSave = async (socialAccount: SocialAccountType) => {
    setSavingId(socialAccount.platformUid);
    try {
      await onSave(socialAccount);
    } finally {
      // Some components will unmount/redirect after a save, others don't
      // so it's best to check before trying to set state
      if (isMounted()) setSavingId(null);
    }
  };

  return (
    <>
      {noResults ? (
        <>
          <div className="font-bold leading-tight text-dark">
            {t('SocialAccountSearchResults__NoResults')}
          </div>

          {platform === 'instagram' && (
            <div className="mt-1 leading-tight">
              {t('SocialAccountSearchResults__NoInstagramResults')}
            </div>
          )}
        </>
      ) : (
        searchResults.map((searchResult) => (
          <SearchResultRow
            key={searchResult.platformUid}
            searchResult={searchResult}
            isSaving={savingId === searchResult.platformUid}
            isSavingOther={!!savingId && savingId !== searchResult.platformUid}
            onSave={() => handleSave(searchResult)}
            selectText={selectText}
          />
        ))
      )}
    </>
  );
}

interface SearchResultRowProps {
  searchResult: SocialAccountType;
  isSaving?: boolean;
  isSavingOther?: boolean;
  onSave: () => void;
  selectText?: string;
}

function SearchResultRow(props: SearchResultRowProps) {
  const { searchResult, selectText, isSaving, isSavingOther, onSave } = props;

  const { t } = useIntl();
  return (
    <div className="border-b-default flex items-center py-1.5 last:border-b-0">
      <SocialAccount socialAccount={searchResult} />

      <div className="w-10">
        <Button filledBg onClick={onSave} disabled={isSaving || isSavingOther}>
          {isSaving ? (
            <IconLoader className="ml-1" />
          ) : (
            <span className="font-bold">
              {selectText || t('SocialAccountSearchResults__Track')}
            </span>
          )}
        </Button>
      </div>
    </div>
  );
}
