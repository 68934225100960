import React, { createElement } from 'react';
import { TippyProps } from '@tippyjs/react';
import styled from 'styled-components';
import { Tooltip } from 'components';
import { storyPlatformIcons } from 'icons';
import { colors, grid } from 'styles/theme';

type StyledIconProps = {
  status: string;
};

const Wrapper = styled.div<StyledIconProps>`
  background-color: ${colors.tealBg};
  padding: ${grid(0.75)};
  height: ${grid(3)};
  width: ${grid(3)};
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.15s ease-in-out;
  &:hover {
    transition: all 0.15s ease-in-out;
    background-color: ${colors.teal};
    div > svg {
      transition: inherit;
      color: ${colors.white};
    }
  }
`;

const IconWrapper = styled.div<StyledIconProps>`
  color: ${colors.teal};
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    width: ${grid(2)};
    height: ${grid(2)};
  }
`;

interface IconProps {
  tooltip: TippyProps['content'];
  platform: string;
  status: string;
}

export default function RequestStoryPlatformsIcon(props: IconProps) {
  const { tooltip, platform, status } = props;

  return (
    <Tooltip content={tooltip} theme="socialieDark">
      <Wrapper status={status}>
        <IconWrapper status={status}>
          {storyPlatformIcons[platform] &&
            createElement(storyPlatformIcons[platform])}
        </IconWrapper>
      </Wrapper>
    </Tooltip>
  );
}
