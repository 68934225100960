import loadTwilioMessage from 'graphql/operations/loadTwilioMessage';
import updateTwilioMessage from 'graphql/operations/updateTwilioMessageStatus';

interface ChatEventPayload {
  messageId: string;
  event: 'created' | 'statusUpdated';
  status?: string;
}

const chatEvents = async ({
  messageId,
  event,
  ...payload
}: ChatEventPayload) => {
  if (event === 'created') loadTwilioMessage(messageId);
  if (event === 'statusUpdated')
    updateTwilioMessage(messageId, payload.status!);
};

export default chatEvents;
