import useCurrentUser from './useCurrentUser';

export default function useCanTrackAccounts(platform: string): boolean {
  const { oauthIdentities, currentSuggester } = useCurrentUser();
  if (!currentSuggester || !oauthIdentities) return false;

  const providers = oauthIdentities
    .filter((o) => !o.invalidPermissions && !o.invalidToken)
    .map((o) => o.provider);

  return {
    twitter: () => providers.some((p) => p === 'twitter'),
    facebook: () =>
      providers.some((p) => p === 'facebook') &&
      !!currentSuggester.facebookPage,
    instagram: () =>
      providers.some((p) => p === 'facebook') &&
      !!currentSuggester.facebookPage &&
      !!currentSuggester.facebookPage.instagramBusinessId,
  }[platform]();
}
