import Suggester from 'types/Suggester';
import includes from 'lodash/includes';
import compact from 'lodash/compact';
import PhoneNumber from 'awesome-phonenumber';

const createContact = (value: string, protocol: string) => ({
  value,
  label: value,
  data: {
    newContactValue: value,
    newContactProtocol: protocol,
    id: '__NEW__',
    combinedId: 'NewContact-__NEW__',
  },
});

const isValidPhoneNumber = (phoneNumber: string, suggester: Suggester) => {
  const { allowWorldwideSms, countryCodeWhitelist } =
    suggester.accountConfiguration;
  const pn = new PhoneNumber(phoneNumber);
  if (!pn.isValid() || !suggester.twilioNumbers) return false;
  if (allowWorldwideSms && !countryCodeWhitelist.length) return true;

  const regionCode = pn.getRegionCode();
  const countryCode = PhoneNumber.getCountryCodeForRegionCode(regionCode);
  const allowedCountryCodes = allowWorldwideSms
    ? countryCodeWhitelist
    : suggester.twilioNumbers.map((tn) => tn.countryCode);
  return includes(allowedCountryCodes, `+${countryCode}`);
};

const createNewContact = (
  inputValue: string,
  suggester: Suggester,
  allowedProtocols?: string[]
) => {
  const values = inputValue.split(',');
  const { contactProtocolBlacklist } = suggester.accountConfiguration;

  const isAllowedProtocol = (protocol: string) =>
    !includes(contactProtocolBlacklist, protocol) &&
    (!allowedProtocols || includes(allowedProtocols, protocol));

  return compact(
    values.map((value) => {
      const matchData = value.match(/(?:"?([^"]*)"?\s)?(?:<?(.+@[^>]+)>?)/);
      if (matchData && matchData[2] && isAllowedProtocol('email')) {
        return createContact(matchData[2], 'email');
      }

      if (!suggester.twilioNumbers || !isAllowedProtocol('sms')) {
        return null;
      }

      // Allow suggesters who have a '+1' Twilio number, or who have
      // allowWorldwideSms enabled to enter a ten-digit number and have it
      // automatically converted into a +1 formatted number.
      const canEnterTenDigitNumbers =
        suggester.twilioNumbers.find((t) => t.countryCode === '+1') ||
        suggester.accountConfiguration.allowWorldwideSms;

      let phoneNumber;

      if (
        canEnterTenDigitNumbers &&
        value.match(/^\d+.+/) &&
        value.replace(/\D+/g, '').length === 10
      ) {
        phoneNumber = `+1${value.replace(/\D+/g, '')}`;
      }

      if (value.match(/^\+\d+.+/)) {
        phoneNumber = value.replace(/(?!^\+)\D+/g, '');
      }

      if (phoneNumber && isValidPhoneNumber(phoneNumber, suggester)) {
        return createContact(phoneNumber, 'sms');
      }

      return null;
    })
  );
};

export default createNewContact;
