import React from 'react';
import includes from 'lodash/includes';
import without from 'lodash/without';
import { Form } from 'components';
import { FormattedMessage } from 'react-intl';

interface Props {
  onChange: (val: string[]) => void;
  onChangeTextOnly: (val: boolean) => void;
  mediaType: string[];
  textOnly: boolean;
}

const MediaTypeFilter = (props: Props) => {
  const { onChange, onChangeTextOnly, mediaType, textOnly } = props;
  const onToggle = (val: string) => {
    if (includes(mediaType, val)) {
      onChange(without(mediaType, val));
    } else {
      onChange([...mediaType, val]);
    }
  };

  return (
    <Form.Field>
      <Form.Label bold>
        <FormattedMessage id="TrackingMediaTypeFilter__Label" />
      </Form.Label>

      <Form.Option>
        <Form.Checkbox
          checked={includes(mediaType, 'Photo')}
          onChange={() => onToggle('Photo')}
        />
        <span>
          <FormattedMessage id="TrackingMediaTypeFilter__Photo" />
        </span>
      </Form.Option>

      <Form.Option>
        <Form.Checkbox
          checked={includes(mediaType, 'Video')}
          onChange={() => onToggle('Video')}
        />
        <span>
          <FormattedMessage id="TrackingMediaTypeFilter__Video" />
        </span>
      </Form.Option>

      <Form.Option>
        <Form.Checkbox
          checked={textOnly}
          onChange={() => onChangeTextOnly(!textOnly)}
        />
        <span>
          <FormattedMessage id="TrackingMediaTypeFilter__TextOnly" />
        </span>
      </Form.Option>
    </Form.Field>
  );
};

export default MediaTypeFilter;
