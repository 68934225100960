import React from 'react';
import SocialAccountType from 'types/SocialAccount';
import { IconVerified } from 'icons';
import { formatAverage } from 'helpers/formatNumber';
import { useIntl } from 'hooks';

interface Props {
  socialAccount: SocialAccountType;
}

export default function SocialAccount({ socialAccount }: Props) {
  const { t } = useIntl();

  const hasAtSign = ['twitter', 'instagram'].some(
    (p) => p === socialAccount.platform
  );

  return (
    <div className="flex flex-1 items-center">
      <div className="mr-1.5 h-6 w-6 flex-shrink-0">
        <img
          src={socialAccount.image}
          alt={socialAccount.name}
          className="h-full w-full rounded-full object-cover"
        />
      </div>

      <div className="flex-1 text-left leading-tight">
        <div className="mb-0.5 flex items-center font-bold">
          <a
            className="text-dark hover:underline"
            href={socialAccount.url}
            target="_blank"
            rel="noopener noreferrer"
          >
            {hasAtSign && '@'}
            {socialAccount.username || socialAccount.name}
          </a>

          {socialAccount.verified && (
            <IconVerified className="bump-down-1 ml-0.5 h-2 w-2 flex-shrink-0 text-socialieBlue" />
          )}
        </div>

        <div className="text-12 leading-16 text-light">
          {socialAccount.platform !== 'facebook' && (
            <>
              {socialAccount.name}
              <br />
            </>
          )}

          {!!socialAccount.followers &&
            t('PublisherAccount__FollowerCount', {
              count: socialAccount.followers,
              formattedCount: formatAverage(socialAccount.followers),
            })}
        </div>
      </div>
    </div>
  );
}
