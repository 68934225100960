import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { grid, colors } from 'styles/theme';
import { IconTwitter, IconLoader } from 'icons';
import performLogin from 'helpers/auth/performLogin';
import { noPropagate } from 'helpers';

interface Props {
  unclickable?: boolean;
  message?: string;
  renderButton?: (onClick: () => void, isLinking: boolean) => React.ReactNode;
  noPadding?: boolean;
}

interface State {
  isLinking: boolean;
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: ${grid(1)};

  ${(props: any) => props.noPadding && 'padding: 0'};
` as any;

const Button = styled.button`
  margin-left: ${grid(1)};
  border-radius: 4px;
  background-color: ${colors.twitter};
  padding: 0 ${grid(1)};
  height: ${grid(3)};
  color: ${colors.white};
  font-size: 13px;

  &:disabled {
    opacity: 0.6;
  }

  span {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  svg {
    position: relative;
    top: 1px;
    margin-right: ${grid(0.5)};
    width: ${grid(2)};
    height: ${grid(2)};
  }
`;

export default class LinkTwitter extends React.Component<Props, State> {
  state: State = { isLinking: false };

  linkTwitter = () => {
    this.setState({ isLinking: true }, () => {
      performLogin({ platformId: 'twitter' });
    });
  };

  renderButton = (onClick: () => void, isLinking: boolean) => (
    <Button onClick={noPropagate(onClick)}>
      <span>
        {isLinking ? <IconLoader /> : <IconTwitter />}
        <FormattedMessage id="LinkTwitter__Button" />
      </span>
    </Button>
  );

  render() {
    const { unclickable, noPadding } = this.props;
    const { isLinking } = this.state;
    const message = this.props.message || 'LinkTwitter__NotConnected';
    const renderButton = this.props.renderButton || this.renderButton;
    return (
      <Wrapper noPadding={noPadding}>
        {unclickable ? (
          <FormattedMessage id="LinkTwitter__UnclickableNotConnected" />
        ) : (
          <>
            <FormattedMessage id={message} />{' '}
            {renderButton(this.linkTwitter, isLinking)}
          </>
        )}
      </Wrapper>
    );
  }
}
