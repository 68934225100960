import React from 'react';
import { rgba } from 'polished';
import styled, { ThemeProvider } from 'styled-components';
import { grid, colors } from 'styles/theme';

interface Props {
  children: React.ReactNode;
  theme?: { darkBg?: boolean };
}

const Wrapper = styled.div`
  display: flex;
  align-items: stretch;
  margin-bottom: ${grid(3)};
  color: ${colors.lightText};

  ${(props: any) => props.theme.darkBg && `color: ${rgba(colors.white, 0.8)}`};

  h1 {
    ${(props: any) => props.theme.darkBg && `color: ${colors.white}`};
  }
`;

// prettier-ignore
const Section = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  padding: 0 ${grid(3)};

  &:first-child {
    padding-left: 0;

    &::after {
      display: none;
    }
  }

  &::after {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    background: ${colors.border};
    width: 1px;
    height: ${grid(3)};
    content: '';

    ${(props: any) => props.theme.darkBg && `
      background: ${colors.white};
      opacity: 0.3;
    `};
  }

  ${(props: any) => props.secondary && `
    position: relative;
    top: 4px;
  `};

  ${(props: any) => props.right && `
    margin-left: auto;
    border-left: none;
    padding-right: 0;

    &::after {
      display: none;
    }
  `};
` as any;

export default class Heading extends React.Component<Props> {
  public static Section = Section;

  render() {
    return (
      <ThemeProvider theme={this.props.theme || {}}>
        <Wrapper>{this.props.children}</Wrapper>
      </ThemeProvider>
    );
  }
}
