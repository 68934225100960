import React from 'react';
import styled from 'styled-components';
import { FilterSelector } from '..';
import { grid, colors } from 'styles/theme';
import { IconSortAsc, IconSortDesc } from 'icons';

interface SortFilterOptions {
  sortDirection: string;
  sortField: string;
}

interface Props {
  filter: SortFilterOptions;
  updateFilter: (updates: Partial<SortFilterOptions>) => void;
  options: string[];
  name: string;
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: ${grid(2)};
  color: ${colors.lightText};
  font-size: 14px;

  > span {
    margin-right: ${grid(0.5)};
  }
`;

const DirectionToggle = styled.button.attrs({ type: 'button' })`
  margin-right: ${grid(0.5)};
  padding: ${grid(0.5)};
  width: ${grid(3.5)};
  height: ${grid(3.5)};
  color: ${colors.grey1};
  border-radius: 4px;

  &:hover {
    background-color: ${colors.greyBg};
  }

  svg {
    width: 100%;
    height: 100%;
  }
`;

const Sort = ({ filter, updateFilter, options, name }: Props) => {
  const { sortDirection, sortField } = filter;
  const otherSortDirection = sortDirection === 'ASC' ? 'DESC' : 'ASC';

  return (
    <Wrapper>
      <DirectionToggle
        onClick={() => updateFilter({ sortDirection: otherSortDirection })}
      >
        {sortDirection === 'ASC' ? <IconSortAsc /> : <IconSortDesc />}
      </DirectionToggle>

      <FilterSelector
        options={options}
        value={sortField}
        onChange={(val: string) => updateFilter({ sortField: val })}
        name={`${name}__Sort`}
        right
      />
    </Wrapper>
  );
};

export default Sort;
