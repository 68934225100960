import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Form } from 'components';
import { useIntl } from 'hooks';

interface Props {
  campaigns: { id: number; name: string }[];
  selectedCampaignIds?: number[];
  onChange: (selectedCampaignIds: number[]) => void;
}

export default function CampaignFilter(props: Props) {
  const { campaigns, selectedCampaignIds, onChange } = props;

  const { t } = useIntl();

  const options = campaigns.map((c) => ({ value: c.id, label: c.name }));

  return (
    <Form.Field>
      <Form.Label bold>
        <FormattedMessage id="TrackingCampaignFilter__Label" />
      </Form.Label>

      <Form.Select
        isMulti
        onChange={onChange}
        value={selectedCampaignIds}
        options={options}
        isClearable={false}
        placeholder={t('TrackingCampaignFilter__Placeholder')}
        pillProps={{ color: 'white' }}
      />
    </Form.Field>
  );
}
