import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { Form, ButtonStack } from 'components';
import { useIntl } from 'hooks';
import { FilterContext } from 'screens/MediaLibrary/screens/MediaLibraryIndex';

export default function SuggestedFilter() {
  const { t } = useIntl();
  const { filter, updateFilter } = useContext(FilterContext);
  const handleChange = (value: '0' | '1' | '2') => {
    updateFilter({ suggested: value });
  };

  const values: (typeof filter.suggested)[] = ['2', '1', '0'];

  return (
    <Form.Field>
      <Form.Label bold>
        <FormattedMessage id="MediaLibrarySuggestedFilter__Label" />
      </Form.Label>
      <ButtonStack>
        {values.map((value) => {
          const label = t(`MediaLibrarySuggestedFilter__Value--${value}`);
          return (
            <ButtonStack.Button
              key={value}
              isActive={filter.suggested === value}
              onClick={() => handleChange(value)}
            >
              {label}
            </ButtonStack.Button>
          );
        })}
      </ButtonStack>
    </Form.Field>
  );
}
