import React, { useRef } from 'react';
import DatePicker from 'react-datepicker';
import styled, { css } from 'styled-components';
import { FormattedMessage } from 'react-intl';
import moment from 'moment-timezone';
import { Form } from 'components';
import { IconCalendar } from 'icons';
import { grid } from 'styles/theme';
import { TimeInputs } from './components';
import { Context as IsMobileContext } from 'hooks/useIsMobile';

interface Props {
  value: Date;
  onChange: (value: Date) => void;
  hideTimeZone?: boolean;
  isFullWidth?: boolean;
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const DateSelect = styled.div<{ isFullWidth?: boolean }>`
  ${(props) =>
    props.isFullWidth &&
    css`
      .react-datepicker-wrapper,
      .react-datepicker__input-container {
        display: block;
      }
    `}

  width: ${(props) => (props.isFullWidth ? '100%' : grid(17))};

  input {
    height: ${grid(4.75)};
  }
`;

export default class DateTimePicker extends React.Component<Props> {
  private datePickerRef: any;

  onChange = (date: moment.Moment, timeStr: string) => {
    const newDate = date.clone();
    const time = moment(timeStr, 'hmmA');
    newDate.set({ hour: time.get('hour'), minute: time.get('minute') });
    this.props.onChange(newDate.toDate());
  };

  // fixes issue where react-datepicker steals focus from react-select
  fixDatePickerClickOutside = () => {
    if (!this.datePickerRef) return;
    this.datePickerRef.cancelFocusInput();
  };

  render() {
    const { value, hideTimeZone, isFullWidth = false } = this.props;
    const date = moment(value);
    const time = date.clone().format('hmmA');

    return (
      <>
        <Wrapper>
          <DateSelect data-testid="date-select" isFullWidth={isFullWidth}>
            <IsMobileContext.Consumer>
              {({ isMobileUserAgent }) =>
                isMobileUserAgent ? (
                  <NativePicker
                    value={date}
                    onChange={(date: moment.Moment) =>
                      this.onChange(date, time)
                    }
                  />
                ) : (
                  <DatePicker
                    ref={(picker: any) => (this.datePickerRef = picker)}
                    minDate={moment()}
                    customInput={
                      <Form.TextInput icon={<IconCalendar />} small />
                    }
                    onChange={(newDate) => this.onChange(newDate!, time)}
                    selected={date}
                    onClickOutside={this.fixDatePickerClickOutside}
                  />
                )
              }
            </IsMobileContext.Consumer>
          </DateSelect>

          <TimeInputs
            value={value}
            onChange={(timeStr: string) => this.onChange(date, timeStr)}
          />
        </Wrapper>

        {!hideTimeZone && (
          <Form.Description>
            <FormattedMessage
              id="DateTimePicker__TimeZone"
              values={{ tz: moment.tz.guess().replace(/_/g, ' ') }}
            />
          </Form.Description>
        )}
      </>
    );
  }
}

interface NativePickerProps {
  value: moment.Moment;
  onChange: (date: moment.Moment) => void;
}

function NativePicker({ value, onChange }: NativePickerProps) {
  const nativeDateInputRef = useRef<HTMLInputElement>(null);

  // Render a hidden native date input and then a show a
  // normal text input so we can format the date how we want
  return (
    <>
      <Form.TextInput
        type="date"
        style={{ position: 'absolute', left: '-9999px' }}
        ref={nativeDateInputRef}
        value={value.format('YYYY-MM-DD')}
        min={moment().format('YYYY-MM-DD')}
        onChange={(e) => onChange(moment(e.target.value, 'YYYY-MM-DD'))}
      />

      <Form.TextInput
        type="text"
        icon={<IconCalendar />}
        value={value.format('MM/DD/YYYY')}
        onFocus={() =>
          nativeDateInputRef.current && nativeDateInputRef.current.focus()
        }
      />
    </>
  );
}
