import React from 'react';
import styled from 'styled-components';
import { colors, grid } from 'styles/theme';
import { platformIcons } from 'icons';
import { CaptionRow } from '.';
import Story from 'types/Story';
import Post from 'types/Post';
import clsx from 'clsx';

type IconProps = {
  platform: string;
};

const Icon = styled.div<IconProps>`
  color: ${(props) => colors[props.platform]};

  svg {
    height: ${grid(3)};
    width: ${grid(3)};
  }
`;

interface StatRowProps {
  platform: string;
  media?: Post | Story;
  isRowFormat?: boolean;
  children: React.ReactNode;
}

export default function StatRow(props: StatRowProps) {
  const { platform, media, isRowFormat, children } = props;

  return (
    <div key={platform} className="mb-2.5 flex items-start space-x-2">
      {platformIcons[platform] && (
        <Icon platform={platform}>
          {React.createElement(platformIcons[platform])}
        </Icon>
      )}

      <div className="flex w-full flex-col">
        <div className={`h-full w-0.25 bg-${platform}`} />
        <div
          className={`w-full border border-l-2 border-solid border-grey7`}
          style={{ borderLeftColor: colors[platform] }}
        >
          {isRowFormat && media && (
            <CaptionRow media={media} platform={platform} />
          )}
          <div
            className={clsx('flex flex-col py-1.5', {
              'px-2': isRowFormat,
              'px-4.5': !isRowFormat,
            })}
          >
            <div className="flex">{children}</div>
          </div>
        </div>
      </div>
    </div>
  );
}
