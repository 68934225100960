import Recipient from 'types/Recipient';
import compact from 'lodash/compact';
import flatten from 'lodash/flatten';
import uniq from 'lodash/uniq';

const getPhoneNumbersFromRecipients = (recipients: Recipient[]) =>
  uniq(
    flatten(
      compact(
        recipients.map((r) => {
          if (r.newContactValue) return r.newContactValue;

          if (r.contactMethod) {
            const prefix =
              r.contactMethod.protocol === 'whats_app' ? 'whatsapp:' : '';
            return `${prefix}${r.contactMethod.value}`;
          }

          if (r.publisherUser) {
            const prefix = r.protocol === 'whats_app' ? 'whatsapp:' : '';
            const phone =
              r.publisherUser.phone || r.publisherUser.inviteContactValue;
            return `${prefix}${phone}`;
          }

          // It's no longer possible to add publisherTags directly as
          // recipients, they get expanded into the individual contact methods
          return null;
        })
      )
    )
  );

export default getPhoneNumbersFromRecipients;
