import Suggestion from 'types/Suggestion';

const isInvalidForInstagram = (suggestion: Suggestion) => {
  if (!suggestion.attachments || !suggestion.attachments.length) return false;
  const isGif = suggestion.attachments.find(
    (attachment) => attachment.mediaContentType === 'image/gif'
  );

  if (isGif) return 'isGif';

  return;
};

const isInvalidForLinkedin = (suggestion: Suggestion) => {
  if (!suggestion.attachments || !suggestion.attachments.length) return false;

  const isGif = suggestion.attachments.some(
    (attachment) => attachment.mediaContentType === 'image/gif'
  );

  if (isGif) return 'isGif';

  return false;
};

export default function useGetInvalidPlatforms() {
  const getInvalidPlatforms = (suggestion: Suggestion) => {
    const invalidPlatforms: { [key: string]: string } = {};

    const instagramReason = isInvalidForInstagram(suggestion);
    if (instagramReason) invalidPlatforms.instagram = instagramReason;

    const linkedinReason = isInvalidForLinkedin(suggestion);
    if (linkedinReason) invalidPlatforms.linkedin = linkedinReason;
    return invalidPlatforms;
  };

  return getInvalidPlatforms;
}
