import React from 'react';
import styled from 'styled-components';
import { rgba } from 'polished';
import TextareaAutosize from 'react-autosize-textarea';
import { WithIntl, OpenGraphPreview } from 'components';
import { grid, font, colors } from 'styles/theme';
import FileUpload from 'types/FileUpload';
import { IconClose } from 'icons';

interface Props {
  value: string;
  onChange: (value: string) => void;
  fileUpload?: FileUpload;
  disabled?: boolean;
  autoFocus?: boolean;
  onSend: () => void;
  onCancelUpload: () => void;
  innerRef?: (el: HTMLTextAreaElement) => void;
}

interface State {
  imgWidth?: number;
  imgHeight?: number;
}

const Wrapper = styled.div`
  border-bottom: 1px solid ${colors.lightBorder};
  padding: ${grid(2)};

  textarea {
    display: block;
    margin-bottom: ${grid(1)};
    border: 0;
    width: 100%;
    resize: none;
    color: ${colors.darkText};
    font-family: ${font.sans};
    font-size: 14px;
    appearance: none;

    &::placeholder {
      color: ${colors.lightText};
    }

    &:last-child {
      margin-bottom: 0;
    }

    &:focus {
      outline: none;
    }
  }
`;

// prettier-ignore
const FilePreview = styled.div`
  position: relative;
  width: ${(props: any) => `${props.imgWidth}px`};
  height: ${(props: any) => `${props.imgHeight}px`};

  ${(props: any) => (props.rotate === 90 || props.rotate === -90) && `
    width: ${props.imgHeight}px;
    height: ${props.imgWidth}px;
  `}

  img {
    display: block;
    max-width: ${grid(25)};
    max-height: ${grid(25)};

    ${(props: any) => props.rotate && `
      transform: rotate(${props.rotate}deg);
      transform-origin: 50% 50%;
    `};

    ${(props: any) => props.rotate === 90 && `
      transform: rotate(${props.rotate}deg) translateY(-100%);
      transform-origin: top left;
    `}

    ${(props: any) => props.rotate === -90 && `
      transform: rotate(${props.rotate}deg) translateX(-100%);
      transform-origin: top left;
    `}
  }

  button {
    position: absolute;
    top: ${grid(0.5)};
    left: ${(props: any) => `${props.imgWidth - 20}px`};
    z-index: 1;
    width: ${grid(2)};
    height: ${grid(2)};
    color: ${colors.white};

    ${(props: any) => (props.rotate === 90 || props.rotate === -90) && `
      left: ${props.imgHeight - 20}px;
    `};

    svg {
      width: 100%;
      height: 100%;
      filter: drop-shadow(1px 1px 2px ${rgba(colors.black, 0.6)});
    }
  }
` as any;

class Field extends React.Component<Props, State> {
  state = { imgWidth: 0, imgHeight: 0 };

  private textareaEl?: HTMLTextAreaElement;
  private wantsFocus = true;

  componentDidMount() {
    if (!this.textareaEl || !this.props.autoFocus) return;
    this.textareaEl.focus();
  }

  componentDidUpdate() {
    if (this.wantsFocus && this.props.autoFocus && this.textareaEl) {
      this.textareaEl.focus();
    }
  }

  attachRef = (el: HTMLTextAreaElement) => {
    this.textareaEl = el;
    this.props.innerRef && this.props.innerRef(el);
  };

  onKeyDown = (event: React.KeyboardEvent<HTMLElement>) => {
    const { key, shiftKey, ctrlKey, metaKey } = event;
    const hasText = !!this.props.value.trim();
    if (key === 'Enter' && !shiftKey && !ctrlKey && !metaKey) {
      event.preventDefault();
      if (hasText) this.props.onSend();
    }
  };

  render() {
    const { disabled, value, onChange, fileUpload, onCancelUpload } =
      this.props;

    return (
      <Wrapper>
        <WithIntl>
          {(_, t) => (
            <TextareaAutosize
              onKeyDown={this.onKeyDown}
              innerRef={this.attachRef}
              disabled={disabled}
              placeholder={t('ChatPanel__TextPlaceholder')}
              value={value}
              onChange={(e) => onChange(e.target['value'])}
              onFocus={() => (this.wantsFocus = true)}
              onBlur={() => (this.wantsFocus = false)}
            />
          )}
        </WithIntl>

        <OpenGraphPreview text={this.props.value} small />

        {fileUpload && (
          <FilePreview
            imgWidth={this.state.imgWidth}
            imgHeight={this.state.imgHeight}
            rotate={fileUpload.orientation}
          >
            <button onClick={onCancelUpload}>
              <IconClose />
            </button>

            <img
              alt=""
              src={fileUpload.previewUrl}
              onLoad={(e) =>
                this.setState({
                  imgWidth: e.currentTarget.clientWidth,
                  imgHeight: e.currentTarget.clientHeight,
                })
              }
            />
          </FilePreview>
        )}
      </Wrapper>
    );
  }
}

export default Field;
