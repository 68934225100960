import includes from 'lodash/includes';
import without from 'lodash/without';

interface State {
  selectedIds: any[];
}

const toggleSelected =
  (id: any, key = 'selectedIds') =>
  (state: State) => {
    if (includes(state[key], id)) {
      return { selectedIds: without(state[key], id) };
    }
    return { selectedIds: [...state[key], id] };
  };

export default toggleSelected;
