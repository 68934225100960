import React from 'react';
import styled from 'styled-components';
import { IconLoader } from 'icons';
import { grid, colors } from 'styles/theme';

interface Props {
  delay?: number;
  theme?: 'mono';
}

interface State {
  showLoader: boolean;
}

const Wrapper = styled.div`
  transition: opacity 0.2s;
  opacity: 0;
  padding: ${grid(2)};
  width: 100%;
  text-align: center;
  color: ${colors.lightText};

  ${(props: any) => props.visible && 'opacity: 1'};

  svg {
    width: ${grid(6)};
    height: ${grid(6)};
    color: ${colors.socialiePink};

    ${(props: any) => props.theme === 'mono' && `color: ${colors.grey5}`};
  }
` as any;

class Loader extends React.Component<Props, State> {
  private timeout?: number;

  state: State = { showLoader: false };

  componentDidMount() {
    const delay =
      !this.props.delay && this.props.delay !== 0 ? 750 : this.props.delay;

    this.timeout = window.setTimeout(
      () => this.setState({ showLoader: true }),
      delay
    );
  }

  componentWillUnmount() {
    window.clearTimeout(this.timeout);
  }

  render() {
    return (
      <Wrapper
        data-testid="loader-spinner"
        visible={this.state.showLoader}
        theme={this.props.theme}
      >
        <IconLoader />
      </Wrapper>
    );
  }
}

export default Loader;
