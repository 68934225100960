import React from 'react';
import styled from 'styled-components';
import FlipMove from 'react-flip-move';
import Tag from 'types/Tag';
import { grid } from 'styles/theme';
import SuggestionTag from '../SuggestionTag';

interface Props {
  suggestion: { id: number | string; tags: Tag[] };
  large?: boolean;
}

const List = styled(FlipMove)`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: ${grid(-1)};

  > * {
    margin-right: ${grid(1)};
    margin-bottom: ${grid(1)};

    &:last-child {
      margin-right: 0;
    }
  }
`;

const SuggestionTagList = ({ suggestion, large }: Props) => {
  if (!suggestion.tags.length) return null;

  return (
    <List>
      {suggestion.tags.map((tag) => (
        <div key={tag.id}>
          <SuggestionTag large={large} tag={tag} suggestionId={suggestion.id} />
        </div>
      ))}
    </List>
  );
};

export default SuggestionTagList;
