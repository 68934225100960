import { IconWarning, IconDownload } from 'icons';

const styledStatuses = {
  published: { color: 'teal', name: 'published' },
  scheduled: { color: 'teal', name: 'scheduled' },
  submitted: { color: 'teal', name: 'submitted' },
  opened: { color: 'blue', name: 'opened' },
  declined: { color: 'red', name: 'declined' },
  expired: { color: 'red', name: 'expired' },
  pending: { color: 'grey', name: 'pending' },
  canceled: { color: 'yellow', name: 'canceled' },
  new_request: { color: 'grey', name: 'new_request' },
  saved_media: { icon: IconDownload, color: 'grey', name: 'saved_media' },
  failed: { icon: IconWarning, color: 'red', name: 'failed' },
  send_failed: { icon: IconWarning, color: 'red', name: 'send_failed' },
};

export default styledStatuses;
