import gql from 'graphql-tag';
import includes from 'lodash/includes';
import Attachment from 'types/Attachment';
import apolloClient from '../apolloClient';

const ATTACHMENT = gql`
  query CheckAttachmentStatus($id: ID!) {
    attachment(id: $id, allStatuses: true) {
      id
      status
      length
      aspectRatio
      mediaSize
      mediaUrl
      mediaContentType
      width
      height
    }
  }
`;

function getCachedAttachment(id: number) {
  const attachment = apolloClient.readFragment<Attachment>({
    id: `Attachment:${id}`,
    fragment: gql`
      fragment AttachmentStatus on Attachment {
        id
        status
      }
    `,
  });
  return attachment;
}

export default async function checkAttachmentStatus(
  id: number,
  { onUpdate }: { onUpdate?: (attachment: Attachment) => void } = {}
) {
  const processingStatuses = ['processing', 'unprocessed'];
  const cachedAttachment = getCachedAttachment(id);

  if (
    !cachedAttachment ||
    !includes(processingStatuses, cachedAttachment.status)
  ) {
    return;
  }

  const { data } = await apolloClient.query<{ attachment: Attachment }>({
    query: ATTACHMENT,
    variables: { id },
    fetchPolicy: 'network-only',
  });

  const attachment = data && data.attachment;

  if (!attachment) return;

  if (onUpdate) onUpdate(attachment);

  if (includes(processingStatuses, cachedAttachment.status)) {
    setTimeout(() => checkAttachmentStatus(id, { onUpdate }), 7500);
  }
}
