import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import Chat from 'types/Chat';
import { formatPhone } from 'helpers';
import { IconArrowBack } from 'icons';
import { grid, colors } from 'styles/theme';
import { Pills } from 'components';
import ChatPanelContext from '../../../ChatPanelContext';
import PublisherAvatar from 'components/PublisherAvatar';
import Avatar from 'components/Avatar';
import ContactMethodIcon from '../../ContactMethodIcon';

interface Props {
  chat?: Chat;
  error?: any;
  loading?: any;
  isNew?: boolean;
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${colors.lightBorder};
  padding: ${grid(2)};

  .back {
    flex-shrink: 0;
    width: ${grid(2.5)};
    height: ${grid(2.5)};
    color: ${colors.lightText};

    svg {
      width: 100%;
      height: 100%;
    }
  }

  > span {
    display: flex;
    align-items: center;
    margin-left: ${grid(1)};
    line-height: ${grid(2)};
    color: ${colors.darkText};
    font-size: 16px;
  }
`;

const ContactMethod = styled.div`
  line-height: ${grid(2)};
  color: ${colors.bodyText};
  font-size: 13px;
`;

const PublisherList = styled.div`
  padding-top: ${grid(1)};
`;

const AvatarWrapper = styled.div`
  margin-right: ${grid(1)};
  width: ${grid(4.5)};
  height: ${grid(4.5)};
`;

const Heading = ({ chat, loading, error, isNew }: Props) => {
  return (
    <Wrapper>
      <ChatPanelContext.Consumer>
        {({ gotoScreen }) => (
          <button onClick={() => gotoScreen('home')} className="back">
            <IconArrowBack />
          </button>
        )}
      </ChatPanelContext.Consumer>

      <span>
        {isNew ? (
          <FormattedMessage id="ChatPanel__NewMessage" />
        ) : !chat ? (
          loading ? (
            <FormattedMessage id="Global__Loading" />
          ) : error ? (
            <FormattedMessage id="ChatPanel__ErrorLoadingChat" />
          ) : null
        ) : !chat.publishers.length ? (
          <>
            <AvatarWrapper>
              <Avatar src={undefined} name="?" />
            </AvatarWrapper>

            <div>
              <div className="flex items-center">
                {formatPhone(chat.phoneNumber)}
                <ContactMethodIcon phoneNumber={chat.phoneNumber} />
              </div>
              <ContactMethod>
                <FormattedMessage id="ChatPanel__UnknownPublisher" />
              </ContactMethod>
            </div>
          </>
        ) : chat.publishers.length === 1 ? (
          <>
            <AvatarWrapper>
              <PublisherAvatar publisher={chat.publishers[0]} />
            </AvatarWrapper>
            <div>
              <div className="flex items-center">
                {chat.publishers[0].name}
                <ContactMethodIcon phoneNumber={chat.phoneNumber} />
              </div>
              <ContactMethod>{formatPhone(chat.phoneNumber)}</ContactMethod>
            </div>
          </>
        ) : (
          <div>
            <div className="flex items-center">
              {formatPhone(chat.phoneNumber)}
              <ContactMethodIcon phoneNumber={chat.phoneNumber} />
            </div>
            <PublisherList>
              <Pills tight>
                {chat.publishers.map((p) => (
                  <Pills.Pill image={p.image} key={p.id}>
                    {p.name}
                  </Pills.Pill>
                ))}
              </Pills>
            </PublisherList>
          </div>
        )}
      </span>
    </Wrapper>
  );
};

export default Heading;
