import React from 'react';
import Helmet from 'react-helmet';
import WithIntl from '../WithIntl';

interface Props {
  title: string;
}

const IntlTitle = ({ title }: Props) => (
  <WithIntl>
    {({ formatMessage }) => (
      <Helmet
        title={`${formatMessage({
          id: title,
        })} | Socialie`}
      />
    )}
  </WithIntl>
);

export default IntlTitle;
