import React from 'react';
import moment from 'moment-timezone';
import merge from 'lodash/merge';
import { DateRangeInput, Form } from 'components';
import { FormattedMessage } from 'react-intl';
import DateFilterFields from 'types/DateFilterFields';

interface FilterShape {
  createdAt: DateFilterFields;
}

interface Props {
  filter: FilterShape;
  updateFilter: (changes: FilterShape) => void;
}

export default function CreatedAtFilter({ filter, updateFilter }: Props) {
  return (
    <Form.Field>
      <Form.Label bold>
        <FormattedMessage id="MediaLibraryCreatedAtFilter__Label" />
      </Form.Label>

      <DateRangeInput
        onChange={(changes) =>
          updateFilter({ createdAt: merge({}, filter.createdAt, changes) })
        }
        maxDate={moment()}
        {...filter.createdAt}
      />
    </Form.Field>
  );
}
