import React, { useState } from 'react';
import { connect } from 'react-redux';
import { RootState } from 'redux/ducks';
import { flashMessage, createIntendedRecipientInput } from 'helpers';
import { RecipientsSearch, Modal, Form, FlyoutMenu } from 'components';
import Suggestion from 'types/Suggestion';
import RecipientOption from 'types/RecipientOption';
import IndexSuggestion from 'types/IndexSuggestion';
import Button from 'components/Button';
import * as ADD_INTENDED_RECIPIENTS from 'graphql/mutations/addIntendedRecipients.graphql';
import { useIntl, useBoolean } from 'hooks';
import { useMutation } from '@apollo/react-hooks';

interface OwnProps {
  suggestion: Suggestion | IndexSuggestion;
  children?: (onClick: () => void) => React.ReactNode;
}

interface ConnectedState {
  socketId: string;
}

type Props = ConnectedState & OwnProps;

function SuggestionAddRecipients(props: Props) {
  const { children, suggestion, socketId } = props;
  const [isModalOpen, openModal, closeModal] = useBoolean();
  const [selections, setSelections] = useState<RecipientOption[]>([]);
  const { t } = useIntl();

  const publisherIds = suggestion.requests.map((r) => r.publisher.id);

  const intendedRecipients = selections.map((selection) =>
    createIntendedRecipientInput(selection.data)
  );

  const [addRecipients, { loading: isSubmitting }] = useMutation<any>(
    ADD_INTENDED_RECIPIENTS,
    {
      refetchQueries: ['SuggestionRequestsQuery'],
      onCompleted: (data) => {
        const mutation = data && data.addIntendedRecipients;
        if (mutation && !mutation.errors) {
          closeModal();
          flashMessage('SuggestionAddRecipients__Sent');
        } else {
          flashMessage('SuggestionAddRecipients__ErrorSending', {
            style: 'error',
          });
        }
      },
    }
  );

  const isChildAccount = suggestion.suggester.viewerRelationship === 'child';

  const isExpired =
    (!!suggestion.expiresAt && suggestion.expiresAt < new Date().getTime()) ||
    (!!suggestion.postAt && suggestion.postAt < new Date().getTime());

  const isDisabled = isChildAccount || isExpired;

  function handleAddRecipients(skipNotifications = false) {
    addRecipients({
      variables: {
        intendedRecipients,
        socketId,
        suggestionId: suggestion.id,
        skipNotifications,
      },
    });
  }

  return (
    <>
      {children ? (
        children(openModal)
      ) : (
        <FlyoutMenu.Item
          onClick={openModal}
          disabled={isDisabled}
          className={isDisabled ? 'text-grey6' : 'text-dark'}
        >
          {t('SuggestionAddRecipients__Button')}
        </FlyoutMenu.Item>
      )}

      <Modal
        isOpen={isModalOpen}
        theme={{ large: true }}
        onRequestClose={closeModal}
        contentLabel={t('SuggestionAddRecipients__Heading')}
        renderHeading={t('SuggestionAddRecipients__Heading')}
      >
        <Form.Field>
          <RecipientsSearch
            value={selections}
            onChange={setSelections}
            excludePublisherIds={publisherIds}
          />
        </Form.Field>

        <Modal.Actions className="flex flex-wrap justify-between">
          <Button cancel inlineBlock onClick={closeModal}>
            {t('Global__Cancel')}
          </Button>
          <div className="flex">
            <Button
              className="mr-1.5"
              filledBg
              inlineBlock
              onClick={() => handleAddRecipients(false)}
              disabled={isSubmitting || !selections.length}
            >
              {t('SuggestionAddRecipients__Save')}
            </Button>

            <Button
              filledBg
              inlineBlock
              onClick={() => handleAddRecipients(true)}
              disabled={isSubmitting || !selections.length}
            >
              {t('SuggestionAddRecipients__Save--withoutNotifications')}
            </Button>
          </div>
        </Modal.Actions>
      </Modal>
    </>
  );
}

const mapStateToProps = (state: RootState) => {
  return {
    socketId: state.socket.id || '',
  };
};

export default connect(mapStateToProps)(SuggestionAddRecipients);
