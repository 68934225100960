import { ReactComponent as twitter } from './twitter.svg';
import { ReactComponent as facebook } from './facebook.svg';
import { ReactComponent as instagram } from './instagram.svg';
import { ReactComponent as instagram_stories } from './instagram-stories.svg';
import { ReactComponent as linkedin } from './linkedin.svg';

const platformIcons = {
  twitter,
  facebook,
  instagram,
  linkedin,
  instagram_basic: instagram,
  instagram_graph: instagram,
  instagram_stories: instagram_stories,
};

export default platformIcons;
