import React from 'react';
import isEqual from 'lodash/isEqual';
import DateFilterFields from 'types/DateFilterFields';
import { formatDateRange } from 'helpers';
import FilterPill from './FilterPill';
import { useIntl } from 'hooks';

interface Props {
  value: DateFilterFields;
  defaultValue: DateFilterFields;
  onRemove: () => void;
  msg?: string;
}

export default function DateRangeFilterPill(props: Props) {
  const { value, defaultValue, onRemove, msg } = props;
  const { t } = useIntl();

  if (isEqual(value, defaultValue)) return null;

  let label: string;

  if (value.dateRange === 'custom') {
    label = formatDateRange(value.startDate, value.endDate);
  } else {
    label = t(`DateFilter--${value.dateRange}`);
  }

  if (msg) label = t(msg, { label });

  return <FilterPill label={label} onRemove={onRemove} />;
}
