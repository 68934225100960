import { GlobalNavBar, Loader } from 'components';
import { useCurrentUser } from 'hooks';
import React, { Suspense, lazy } from 'react';
import MediaUpload from './MediaUpload/MediaUpload';
import { Redirect, Route, Switch } from 'react-router-dom';
import { routes } from 'helpers';
import { MfaSetup } from 'screens/Auth/screens';
const Settings = lazy(() => import('screens/Settings'));
const Invitation = lazy(() => import('screens/Invitation'));

interface Props {
  password: string;
}

export default function UploaderLayout({ password }: Props) {
  const currentUser = useCurrentUser();
  return (
    <>
      <GlobalNavBar hasNavigation currentUser={currentUser} />
      <Suspense fallback={<Loader />}>
        <Switch>
          <Route path={routes.auth.mfaSetup}>
            <MfaSetup user={currentUser} password={password} />
          </Route>

          <Route path={routes.upload}>
            <div className="mx-auto mt-8 max-w-96">
              <h1 className="text-2xl mb-4 font-bold">Upload Media</h1>
              <div className="sm:px-6 lg:px-8 rounded-lg bg-white p-4">
                <MediaUpload presentation="embedded" />
              </div>
            </div>
          </Route>
          <Route path={routes.settings.index} component={Settings} />

          <Route path={routes.invitation} component={Invitation} />

          <Redirect to={routes.upload} />
        </Switch>
      </Suspense>
    </>
  );
}
