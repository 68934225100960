import React from 'react';
import includes from 'lodash/includes';
import { FormattedMessage } from 'react-intl';
import { Tooltip, Avatar } from 'components';
import PublisherRequest from 'types/PublisherRequest';
import styled from 'styled-components';
import { colors, grid } from 'styles/theme';
import { requestErrorStatuses } from 'config';
import { getFormattedContactMethod } from 'helpers';
import { TippyProps } from '@tippyjs/react';
import { useBoolean } from 'hooks';
import { RequestListModal } from './components';

const MAX_TO_SHOW = 10;

interface Props {
  requests: PublisherRequest[];
  children: TippyProps['children'];
  errorStatuses?: string[];
  showStatus?: boolean;
  placement?: TippyProps['placement'];
}

const List = styled.div`
  text-align: left;
  color: ${colors.darkText};
  font-size: 14px;
`;

const Publisher = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${grid(0.5)};
`;

const AvatarWrapper = styled.div`
  margin-right: ${grid(1)};
  width: ${grid(3)};
  height: ${grid(3)};
`;

const Status = styled.div`
  line-height: 14px;
  color: ${colors.bodyText};
  font-size: 12px;
`;

const Remainder = styled.button`
  &:hover {
    text-decoration: underline;
  }
`;

const RequestListTooltip = ({
  requests,
  children,
  errorStatuses,
  showStatus,
  placement,
}: Props) => {
  const [isModalOpen, openModal, closeModal] = useBoolean(false);

  if (!requests.length) return <>{children}</>;

  let requestsToShow = requests;
  let remainder = 0;
  if (!errorStatuses) {
    errorStatuses = ['failed'];
  }

  if (requests.length > MAX_TO_SHOW) {
    requestsToShow = requests.slice(0, MAX_TO_SHOW);
    remainder = requests.length - requestsToShow.length;
  }

  return (
    <>
      <Tooltip
        interactive
        placement={placement || 'bottom-start'}
        content={
          <List>
            {requestsToShow.map((request) => {
              const failedDeliveryReceipts = request.deliveryReceipts.filter(
                (dr) => errorStatuses!.includes(dr.status)
              );

              return (
                <Publisher key={request.publisher.id}>
                  <AvatarWrapper>
                    <Avatar src={request.publisher.image} />
                  </AvatarWrapper>

                  <div>
                    {request.publisher.name}
                    {showStatus && (
                      <>
                        {includes(requestErrorStatuses, request.status) && (
                          <Status>
                            <FormattedMessage
                              id={`RequestStatus--${request.status}`}
                            />
                          </Status>
                        )}

                        {failedDeliveryReceipts.map((deliveryReceipt) => {
                          const formattedContactMethod =
                            getFormattedContactMethod(deliveryReceipt);

                          return (
                            <Status key={deliveryReceipt.id}>
                              {formattedContactMethod ? (
                                <>
                                  <FormattedMessage id="DeliveryReceipts__Failed" />{' '}
                                  {getFormattedContactMethod(deliveryReceipt)}
                                </>
                              ) : deliveryReceipt.errorMessage ? (
                                deliveryReceipt.errorMessage
                              ) : (
                                <FormattedMessage id="DeliveryReceipts__Failed--no-msg" />
                              )}
                            </Status>
                          );
                        })}
                      </>
                    )}
                  </div>
                </Publisher>
              );
            })}
            {!!remainder && (
              <Remainder onClick={openModal}>+ {remainder}</Remainder>
            )}
          </List>
        }
      >
        {children}
      </Tooltip>

      <RequestListModal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        requests={requests}
      />
    </>
  );
};

export default RequestListTooltip;
