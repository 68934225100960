import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Form } from 'components';
import { useIntl } from 'hooks';

interface Props {
  albums: { id: number; name: string }[];
  selectedAlbumIds?: number[];
  onChange: (selectedAlbumIds: number[]) => void;
  requireAll: boolean;
  onChangeRequireAll: (requireAll: boolean) => void;
}

const AlbumFilter = (props: Props) => {
  const { albums, selectedAlbumIds, onChange, requireAll, onChangeRequireAll } =
    props;
  const { t } = useIntl();
  const options = albums.map((album) => ({
    value: album.id,
    label: album.name,
  }));

  return (
    <Form.Field>
      <Form.Label bold>
        <FormattedMessage id="TrackingAlbumFilter__Label" />
      </Form.Label>

      <Form.Select
        isMulti
        onChange={onChange}
        value={selectedAlbumIds}
        options={options}
        isClearable={false}
        placeholder={t('TrackingAlbumFilter__Placeholder')}
      />
      {((selectedAlbumIds?.length ?? 0) > 1 || requireAll) && (
        <Form.Option>
          <Form.Checkbox
            checked={requireAll}
            onChange={() => onChangeRequireAll(!requireAll)}
          />
          <span>
            <FormattedMessage id="TrackingAlbumFilter__RequireAll" />
          </span>
        </Form.Option>
      )}
    </Form.Field>
  );
};

export default AlbumFilter;
