import { Button } from 'components';
import { useIntl } from 'hooks';
import { IconUpload } from 'icons';
import React from 'react';
import { openMediaUploadModal } from 'redux/ducks/ui';
import { useAppDispatch } from 'redux/store';

export default function MediaLibraryUploadButton() {
  const { t } = useIntl();
  const dispatch = useAppDispatch();
  const handleOpenModal = () => {
    dispatch(openMediaUploadModal());
  };

  return (
    <Button
      filledBg
      onClick={handleOpenModal}
      inlineBlock
      data-qa="media-library-upload-button"
    >
      <IconUpload />
      {t('MediaLibrary__Upload')}
    </Button>
  );
}
