import { get } from './shared';
import fetchJsonp from 'fetch-jsonp';

export const queryTwitterMention = async (queryString: string) => {
  const keyword = queryString.replace(/@/, '');
  const endpoint = `https://twitter.com/i/search/typeahead.json?count=10&filters=true&result_type=true&src=COMPOSE&q=${keyword}`;
  const response = await fetchJsonp(endpoint);
  const json = await response.json();
  return json.users;
};

export const queryFacebookMention = async (queryString: string) => {
  return await get(`api/query/facebook_pages?q=${queryString}`);
};
