import { createSlice } from 'redux-starter-kit';

const { reducer, actions } = createSlice({
  name: 'isTopWarningBannerOpen',
  initialState: false,
  reducers: {
    openTopWarningBanner: () => true,
    closeTopWarningBanner: () => false,
  },
});

export default reducer;

export const { openTopWarningBanner, closeTopWarningBanner } = actions;
