import { colors, grid, font } from '../theme';
import { css } from 'styled-components';

// prettier-ignore
const getInputStyles = () => css`
  appearance: none;
  display: block;
  transition: border-color 0.15s, box-shadow 0.15s;
  border: 1px solid ${colors.border};
  border-radius: 4px;
  background-color: ${colors.white};
  padding: 0 ${grid(1)};
  width: 100%;
  line-height: ${grid(4.5)};
  color: ${colors.darkText};
  font-family: ${font.sans};
  font-size: 14px;

  &[readonly] {
    border: 1px solid ${colors.grey7};
    background-color: ${colors.grey8};
    &::placeholder {
      background-color: inherit;
      color: ${colors.grey4};
    }
    &:focus {
      box-shadow: none;
    }

    ${(props: any) => props.errors && `
      border-color: ${colors.error};
    `}
  }

  ${(props:any) => props.errors && `
    border-color: ${colors.error};
    background-color: ${colors.redBgLight};
  `};

  ${(props: any) => props.theme.color === 'dark' && `
    border-color: ${colors.inverseBorder};
    background-color: ${colors.grey1};
    color: ${colors.white};
  `};

  &:focus {
    outline: none;
    border-color: ${colors.focus};
    box-shadow: 0 0 6px ${colors.boxShadow};

    ${(props: any) => props.errors && `
      border-color: ${colors.error};
      box-shadow: 0 0 4px ${colors.error};
    `}
  }

  &::placeholder {
    color: ${colors.lightText};
  }
`;

export default getInputStyles;
