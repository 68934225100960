import React from 'react';
import styled from 'styled-components';
import includes from 'lodash/includes';
import every from 'lodash/every';
import { Mutation } from '@apollo/react-components';
import { ConfirmModal, FlyoutMenu } from 'components';
import { FormattedMessage } from 'react-intl';
import Suggestion from 'types/Suggestion';
import IndexSuggestion from 'types/IndexSuggestion';
import * as REMOVE_SUGGESTIONS from 'graphql/mutations/removeSuggestions.graphql';
import { grid } from 'styles/theme';

interface Props {
  suggestions: (Suggestion | IndexSuggestion)[];
  onCompleted?: Function;
  children?: (onClick: () => void, notAllowed: boolean) => React.ReactNode;
}

interface State {
  isConfirmModalOpen: boolean;
}

const Note = styled.div`
  margin-top: ${grid(2)};
  font-size: 13px;
  font-weight: 600;

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    font-weight: normal;
  }
`;

interface MutationData {
  removeSuggestions: {
    errors?: string;
    suggestions?: { id: string; deleted: boolean };
  };
}

export default class RemoveSuggestion extends React.Component<Props, State> {
  state = { isConfirmModalOpen: false };

  openConfirmModal = () => this.setState({ isConfirmModalOpen: true });
  closeConfirmModal = () => this.setState({ isConfirmModalOpen: false });

  suggestionNotAllowed = (suggestion: Suggestion | IndexSuggestion) =>
    !suggestion.viewerCanRemove || this.suggestionIsCompleted(suggestion);

  suggestionIsCompleted = (suggestion: Suggestion | IndexSuggestion) =>
    !!suggestion.requests.find((r) =>
      includes(['published', 'submitted'], r.status)
    );

  onCompleted = (data: any) => {
    if (data.removeSuggestions.errors) {
      alert('Error removing suggestion.');
    } else {
      this.closeConfirmModal();
      this.props.onCompleted && this.props.onCompleted(data);
    }
  };

  render() {
    const { suggestions, children } = this.props;
    const notAllowed = every(suggestions, this.suggestionNotAllowed);
    const allowedSuggestions = suggestions.filter(
      (suggestion) => !this.suggestionNotAllowed(suggestion)
    );
    const allowedIds = allowedSuggestions.map((s) => s.id);
    const completedCount = suggestions.filter(
      (s) => s.viewerCanRemove && this.suggestionIsCompleted(s)
    ).length;
    const childSuggestionsCount = suggestions.filter(
      (s) => !s.viewerCanRemove
    ).length;
    const notAllowedCount = completedCount + childSuggestionsCount;

    return (
      <Mutation<MutationData>
        mutation={REMOVE_SUGGESTIONS}
        variables={{ ids: allowedIds }}
        onCompleted={this.onCompleted}
        refetchQueries={['TrackingIndexQuery']}
      >
        {(removeSuggestions, { loading }) => (
          <>
            {children ? (
              children(this.openConfirmModal, notAllowed)
            ) : (
              <FlyoutMenu.Item
                onClick={this.openConfirmModal}
                disabled={notAllowed}
              >
                <FormattedMessage id="RemoveSuggestion__Button" />
              </FlyoutMenu.Item>
            )}

            <ConfirmModal
              isOpen={this.state.isConfirmModalOpen}
              onRequestClose={this.closeConfirmModal}
              onConfirm={removeSuggestions}
              isLoading={loading}
              heading="RemoveSuggestion__ConfirmHeading"
            >
              <FormattedMessage
                id="RemoveSuggestion__ConfirmBody"
                values={{ count: suggestions.length }}
              />

              {!!notAllowedCount && (
                <Note>
                  <FormattedMessage
                    id="RemoveSuggestion__SomeNotAllowed"
                    values={{ count: notAllowedCount }}
                  />

                  <ul>
                    {!!completedCount && (
                      <li>
                        <FormattedMessage
                          id="RemoveSuggestion__CompletedCount"
                          values={{ count: completedCount }}
                        />
                      </li>
                    )}

                    {!!childSuggestionsCount && (
                      <li>
                        <FormattedMessage
                          id="RemoveSuggestion__ChildCount"
                          values={{ count: childSuggestionsCount }}
                        />
                      </li>
                    )}
                  </ul>
                </Note>
              )}
            </ConfirmModal>
          </>
        )}
      </Mutation>
    );
  }
}
