import React from 'react';
import styled from 'styled-components';
import includes from 'lodash/includes';
import { FlyoutMenu } from 'components';
import { TimeSeriesValue } from 'types/TimeSeriesValue';
import { FormattedMessage } from 'react-intl';
import { IconTriangleDown } from 'icons';
import { grid, colors } from 'styles/theme';

interface Props {
  timeSeries: TimeSeriesValue;
  dateRange: string;
  onChange: (value: TimeSeriesValue) => void;
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;

  > div {
    position: relative;
  }
`;

const CurrentValue = styled.button`
  > span {
    display: flex;
    align-items: center;
  }

  svg {
    display: block;
    margin-left: ${grid(1)};
    width: ${grid(1.5)};
    height: ${grid(1.5)};
    color: ${colors.darkText};
  }
`;

const disabledCombos = {
  day: [],
  week: ['last7'],
  month: ['last7', 'last30'],
};

const TimeSeriesSelector = ({ timeSeries, dateRange, onChange }: Props) => {
  const options: TimeSeriesValue[] = ['day', 'week', 'month'];

  return (
    <FlyoutMenu
      placement="bottom-end"
      renderButton={(onClick) => (
        <Wrapper>
          <div>
            <CurrentValue onClick={onClick}>
              <span>
                <FormattedMessage id={`TimeSeries--${timeSeries}`} />
                <IconTriangleDown />
              </span>
            </CurrentValue>
          </div>
        </Wrapper>
      )}
    >
      {options.map((tsOption) => (
        <FlyoutMenu.Item
          disabled={includes(disabledCombos[tsOption], dateRange)}
          key={tsOption}
          onClick={() => onChange(tsOption)}
        >
          <FormattedMessage id={`TimeSeries--${tsOption}`} />
        </FlyoutMenu.Item>
      ))}
    </FlyoutMenu>
  );
};

export default TimeSeriesSelector;
