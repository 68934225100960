import { createGlobalStyle } from 'styled-components';
import { grid } from 'styles/theme';

export default createGlobalStyle`
  .hide-scrollbar::-webkit-scrollbar {
    background: transparent;
    width: 0px;
  }

  .leading-tight {
    line-height: ${grid(2.5)};
  }

  .bump-down-0\\.5 {
    position: relative;
    top: 0.5px;
  }

  .bump-down-1 {
    position: relative;
    top: 1px;
  }

  .bump-down-1\\.5 {
    position: relative;
    top: 1.5px;
  }

  .bump-down-2 {
    position: relative;
    top: 2px;
  }

  .bump-up-0\\.5 {
    position: relative;
    top: -0.5px;
  }

  .bump-up-1 {
    position: relative;
    top: -1px;
  }

  .bump-up-1\\.5 {
    position: relative;
    top: -1.5px;
  }

  .bump-up-2 {
    position: relative;
    top: -2px;
  }

  .absolute-center {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }

  .absolute-vertical-center {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  .icon-shadow {
    filter: drop-shadow(1px 1px 2px rgba(0, 0, 0, 0.6));
  }
`;
