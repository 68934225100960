import styled, { css } from 'styled-components';
import { colors } from 'styles/theme';

// prettier-ignore
export default styled.div`
  position: relative;
  background-color: ${colors.grey1};
  ${(props: any) => props.bgColor && `background-color: ${props.bgColor}`};
  height: 100%;
  overflow: visible;

  &::before {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0.1;
    ${(props: any) => props.src && css`
      background-image: url('${props.src}');
      background-size: cover;
      background-position: 50% 50%;
    `}
    content: '';
    filter: blur(10px);
    z-index: 0;
  }

  > * {
    position: relative;
    z-index: 1;
  }
` as any;
