import React from 'react';
import styled from 'styled-components';

interface Props {
  srcSet: string;
  preview: string;
  preventLoading?: boolean;
  onLoadPreview?: () => any;
  onLoadSrcSet?: () => any;
  onClick?: (e: any) => any;
  contain?: boolean;
}

interface State {
  isImageLoaded: boolean;
}

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;

  img {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    transition: opacity 0.2s;
    width: 100%;
    height: 100%;
    object-fit: cover;

    ${(props: any) => props.contain && 'object-fit: contain'};
  }
` as any;

const PreviewImage = styled.img`
  z-index: 0;
  filter: blur(10px);

  ${(props: any) => props.isFullVersionLoaded && 'opacity: 0'};
` as any;

const FullImage = styled.img`
  opacity: 0;
  z-index: 1;

  ${(props: any) => props.isLoaded && 'opacity: 1'};
` as any;

export default class BlurUp extends React.Component<Props, State> {
  state = { isImageLoaded: false };
  constructor(props: Props) {
    super(props);
    this.state = { isImageLoaded: this.checkIfImageLoaded() };
  }

  checkIfImageLoaded = () => {
    if (this.props.preventLoading) return false;
    const img = new Image();
    img.srcset = this.props.srcSet;
    return img.complete;
  };

  onLoadPreview = () => {
    this.props.onLoadPreview && this.props.onLoadPreview();
  };

  onLoadImage = () => {
    this.setState({ isImageLoaded: true });
    this.props.onLoadSrcSet && this.props.onLoadSrcSet();
  };

  render() {
    const { isImageLoaded } = this.state;
    const { onClick, contain } = this.props;

    return (
      <Wrapper contain={contain}>
        <PreviewImage
          src={this.props.preview}
          role="presentation"
          onLoad={this.onLoadPreview}
          onClick={onClick}
          isFullVersionLoaded={isImageLoaded}
        />

        {!this.props.preventLoading && (
          <FullImage
            srcSet={this.props.srcSet}
            onLoad={this.onLoadImage}
            onClick={onClick}
            isLoaded={isImageLoaded}
          />
        )}
      </Wrapper>
    );
  }
}
