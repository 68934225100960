import { useIntl } from 'hooks';
import { IconCaretUp } from 'icons';
import React, { useState, useEffect } from 'react';
import Button from './Button';
import clsx from 'clsx';

export default function BackToTopButton() {
  const { t } = useIntl();
  const [isScrolled, setIsScrolled] = useState(window.scrollY > 0);

  useEffect(() => {
    const handleScroll = () => {
      const nextValue = window.scrollY > 0;
      if (nextValue !== isScrolled) setIsScrolled(window.scrollY > 0);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isScrolled]);

  return (
    <div
      className={clsx(
        'fixed bottom-2.5 right-17 z-[1999] transition-opacity',
        !isScrolled && 'pointer-events-none opacity-0'
      )}
    >
      <Button
        filledBg
        inlineBlock
        onClick={() => {
          // Scroll to the top of the page animated
          window.scrollTo({
            top: 0,
            behavior: 'smooth',
          });
        }}
      >
        <IconCaretUp />
        {t('Button__BackToTop')}
      </Button>
    </div>
  );
}
