import React from 'react';
import styled from 'styled-components';
import { formatTargets } from 'helpers';
import Target from 'types/Target';
import { IconHeading } from '..';
import { IconTarget } from 'icons';
import { colors, grid } from 'styles/theme';
import { FormattedMessage } from 'react-intl';

interface Props {
  targets: Target[];
}

const GeotargetsTable = styled.table`
  text-align: left;

  td {
    vertical-align: top;
    line-height: ${grid(2.5)};
  }
`;

const TargetValue = styled.td`
  color: ${colors.darkText};
`;

const TargetType = styled.td`
  padding-right: ${grid(1.5)};
  color: ${colors.lightText};
`;

const SuggestionGeotargets = ({ targets }: Props) => {
  return (
    <>
      <IconHeading text="SuggestionGeotargets__Heading" icon={<IconTarget />} />
      <GeotargetsTable>
        <tbody>
          {formatTargets(targets).map((target) => (
            <tr key={target.targetType}>
              <TargetType>
                <FormattedMessage id={target.title} />
              </TargetType>

              <TargetValue>{target.values.join(', ')}</TargetValue>
            </tr>
          ))}
        </tbody>
      </GeotargetsTable>
    </>
  );
};

export default SuggestionGeotargets;
