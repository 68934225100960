import clsx from 'clsx';
import { useIntl } from 'hooks';
import { UpdateFilterFn } from 'hooks/useFilterManager';
import { IconArrowBack, IconCaretRight } from 'icons';
import isEqual from 'lodash/isEqual';
import React, { useState } from 'react';
import { useQuery } from 'react-apollo';
import { useLocation } from 'react-router-dom';
import SavedFilterSet from 'types/SavedFilterSet';
import Button from '../Button';
import Loader from '../Loader';
import SavedFilterSetItem from './SavedFilterSetItem';
import SavedFilterSetsCreateModal from './SavedFilterSetsCreateModal';
import SavedFilterSetsUpdateButton from './SavedFilterSetsUpdateButton';
import { parseFilter } from './parseFilter';
import { FETCH_SAVED_FILTER_SETS } from './queries';

export interface SavedFilterSetsProps {
  filterContext: string;
  isFiltered: boolean;
  children: React.ReactNode;
  filter: Record<string, any>;
  updateFilter: UpdateFilterFn<any>;
  defaultFilter: Record<string, any>;
  renderFilterPills: (filter: Record<string, any>) => React.ReactNode;
}

export default function SavedFilterSets(props: SavedFilterSetsProps) {
  const {
    filterContext,
    children,
    isFiltered,
    filter,
    updateFilter,
    defaultFilter,
    renderFilterPills,
  } = props;
  const [screen, setScreen] = useState<'filters' | 'savedFilterSets'>(
    'filters'
  );

  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);

  const { t } = useIntl();

  const { data, loading, error } = useQuery<{
    savedFilterSets: Array<SavedFilterSet>;
  }>(FETCH_SAVED_FILTER_SETS, {
    variables: { filterContext },
  });
  const savedFilterSets = data?.savedFilterSets || [];

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const activeId = searchParams.get('savedFilterSetId');
  const activeFilterSet = savedFilterSets.find((s) => s.id === activeId);
  const isActiveFilterSetEdited = !isEqual(
    filter,
    parseFilter(activeFilterSet?.data)
  );

  return (
    <>
      <div className="-mx-2 w-[calc(100%+32px)] overflow-hidden">
        <div
          className={clsx(
            'flex w-[200%] items-stretch overflow-hidden transition-transform duration-150',
            screen === 'savedFilterSets' && '-translate-x-1/2'
          )}
        >
          <div className="w-1/2 px-2">
            <div className="border-b-default mb-2 pb-2">
              {activeFilterSet ? (
                <>
                  <Button
                    mono
                    onClick={() => setScreen('savedFilterSets')}
                    className="mb-1 last:mb-0"
                  >
                    <span className="mr-1 inline-block h-1 w-1 flex-shrink-0 rounded-full bg-socialiePink" />
                    <span className="truncate font-bold">
                      {activeFilterSet.name}
                    </span>
                    <IconCaretRight className="ml-1 flex-shrink-0" />
                  </Button>

                  {isActiveFilterSetEdited && (
                    <div className="flex items-center gap-1">
                      <div className="flex-1">
                        <SavedFilterSetsUpdateButton
                          id={activeFilterSet.id}
                          filter={filter}
                        />
                      </div>

                      <div className="flex-1">
                        <Button
                          filledGreyBg
                          onClick={() => setIsCreateModalOpen(true)}
                        >
                          <div className="-mx-1">
                            {t('SavedFilterSets__SaveNewButton')}
                          </div>
                        </Button>
                      </div>
                    </div>
                  )}
                </>
              ) : (
                <>
                  <Button
                    filledGreyBg
                    onClick={() => setScreen('savedFilterSets')}
                    className="mb-1 last:mb-0"
                  >
                    <span>{t('SavedFilterSets__SavedFilterSetsButton')}</span>
                    <IconCaretRight className="ml-1" />
                  </Button>

                  {isFiltered && (
                    <Button
                      className="mb-2"
                      filledBg
                      onClick={() => setIsCreateModalOpen(true)}
                    >
                      {t('SavedFilterSets__CreateButton')}
                    </Button>
                  )}
                </>
              )}
            </div>
            {children}
          </div>

          <div className="w-1/2 px-2">
            <div className="mb-2">
              <button
                onClick={() => setScreen('filters')}
                aria-label={t('Global__Back')}
              >
                <IconArrowBack className="h-2.5 w-2.5 text-dark" />
              </button>
            </div>

            {loading ? (
              <Loader />
            ) : error ? (
              <div>{t('Global__UnexpectedError')}</div>
            ) : data?.savedFilterSets.length === 0 ? (
              <div>{t('SavedFilterSets__NoSavedFilterSets')}</div>
            ) : (
              savedFilterSets.map((savedFilterSet) => (
                <SavedFilterSetItem
                  key={savedFilterSet.id}
                  savedFilterSet={savedFilterSet}
                  updateFilter={updateFilter}
                  defaultFilter={defaultFilter}
                  onApply={() => setScreen('filters')}
                  renderFilterPills={renderFilterPills}
                  isActive={
                    savedFilterSet.id === activeId && !isActiveFilterSetEdited
                  }
                />
              ))
            )}
          </div>
        </div>
      </div>

      <SavedFilterSetsCreateModal
        isOpen={isCreateModalOpen}
        onRequestClose={() => setIsCreateModalOpen(false)}
        filter={filter}
        filterContext={filterContext}
      />
    </>
  );
}
