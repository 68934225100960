import React, { useState } from 'react';
import { ThemeConsumer, ThemeProvider } from 'styled-components';
import castArray from 'lodash/castArray';
import uniqBy from 'lodash/uniqBy';
import without from 'lodash/without';
import { useQuery } from '@apollo/react-hooks';
import useDebouncedCallback from 'use-debounce/lib/callback';
import { Form } from 'components';
import { mapRecipientsToOptions } from 'helpers';
import { useIntl } from 'hooks';
import Recipient from 'types/Recipient';
import RecipientSearchQueryData from 'types/RecipientSearchQueryData';
import RecipientOption from 'types/RecipientOption';
import { MenuList, MultiValue } from './components';
import { createNewContact } from './helpers';
import * as RECIPIENTS_SEARCH from 'graphql/queries/recipientsSearch.graphql';
import ErrorDebug from 'components/ErrorDebug';
import groupBy from 'lodash/groupBy';
import compact from 'lodash/compact';

interface Props {
  id?: string;
  value: RecipientOption[];
  onChange: (selections: RecipientOption[]) => void;
  icon?: JSX.Element;
  allowCreate?: boolean;
  includePublisherTags?: boolean;
  excludePublisherIds?: (number | string)[];
  contactProtocols?: string[];
  collapseOptions?: boolean;
  placeholder?: string;
  autoFocus?: boolean;
  mapRecipientsToOptions?: (recipients: Recipient[]) => RecipientOption[];
}

const RecipientsSearch: React.SFC<Props> = (props: Props) => {
  const {
    id = 'recipients-search',
    icon,
    value,
    onChange,
    autoFocus,
    allowCreate,
    includePublisherTags,
    excludePublisherIds,
    collapseOptions,
  } = props;

  let contactProtocols = props.contactProtocols;
  // inputValue is the live value of what the user has typed
  // query is a debounced version of the inputValue that's sent as a variable
  // to the mutation
  const [inputValue, setInputValue] = useState('');
  const [query, setQuery] = useState('');
  const [isNewContactInvalid, setIsNewContactInvalid] = useState(false);

  const debouncedSetQuery = useDebouncedCallback(
    (val: string) => setQuery(val),
    500
  );

  const handleInputChange = (val: string) => {
    setInputValue(val);
    debouncedSetQuery(val);
    if (isNewContactInvalid) setIsNewContactInvalid(false);
  };

  const clearInput = () => {
    setInputValue('');
    setQuery('');
    if (isNewContactInvalid) setIsNewContactInvalid(false);
  };

  const { t } = useIntl();

  const { data, loading, error } = useQuery<RecipientSearchQueryData>(
    RECIPIENTS_SEARCH,
    {
      fetchPolicy: 'network-only',
      variables: { includePublisherTags, query, excludePublisherIds },
      skip: !query,
    }
  );

  if (error) return <ErrorDebug error={error} />;

  // Create props/values for the Select component
  const isLoading = loading && !!query;

  const options =
    data && data.recipientSearch && query
      ? mapRecipientsToOptions(data.recipientSearch.recipients)
      : [];

  const placeholder = isLoading
    ? t('RecipientsSearch__LoadingPlaceholder')
    : props.placeholder || t('RecipientsSearch__Placeholder');

  const onAddRecipient = (val: Recipient | Recipient[]) => {
    const recipients = castArray(val);

    // Let the consuming component provide its own logic for the
    // mapRecipientsToOptions function if it wants to
    let options: RecipientOption[];
    if (props.mapRecipientsToOptions) {
      options = props.mapRecipientsToOptions(recipients);
    } else {
      options = mapRecipientsToOptions(recipients);
    }

    const uniqValues = uniqBy([...value, ...options], ({ data }) => {
      if (data.publisherUser) {
        return `${data.combinedId}-${data.protocol}`;
      } else if (data.newContactValue) {
        return `${data.newContactProtocol}-${data.newContactValue}`;
      }

      return data.combinedId;
    });

    clearInput();
    onChange(uniqValues);
  };

  const onRemoveRecipient = (values: Recipient | Recipient[]) => {
    let nextValue: RecipientOption[] = value;
    castArray(values).forEach((recipient) => {
      if (recipient.publisherUser) {
        nextValue = nextValue.filter(
          (v) =>
            v.data.combinedId !== recipient.combinedId ||
            v.data.protocol !== recipient.protocol
        );
      } else if (recipient.newContactProtocol) {
        nextValue = nextValue.filter(
          (v) => v.data.newContactValue !== recipient.newContactValue
        );
      } else {
        nextValue = nextValue.filter(
          (v) => v.data.combinedId !== recipient.combinedId
        );
      }
    });
    onChange(nextValue);
  };

  // Users can press Enter while text is entered in the search and the system
  // will try to create a new publisher on-the-fly for them.  If their format
  // is invalid, show them an error state.
  const onCreateNewContact = () => {
    if (!props.allowCreate) return;
    if (!data || !data.currentSuggester) return;
    if (!inputValue) return;
    const newContacts = createNewContact(
      inputValue,
      data.currentSuggester,
      contactProtocols
    );
    if (!newContacts.length) {
      setIsNewContactInvalid(true);
      return;
    }
    clearInput();
    onChange([...value, ...newContacts]);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLElement>) => {
    if (event.key === 'Enter') {
      // Make sure the Enter key has no effect if the user is not creating
      // a new user
      if (props.allowCreate) {
        onCreateNewContact();
      } else {
        event.preventDefault();
      }
    }
  };

  // Remove WhatsApp from the contactProtocols list if it's not enabled
  const allowWhatsApp =
    data &&
    data.currentSuggester &&
    data.currentSuggester.accountConfiguration.enableWhatsApp;

  if (!allowWhatsApp) contactProtocols = without(contactProtocols, 'whats_app');

  // Create a set of props that we pass down through the react-select components
  const extraSelectProps = {
    query,
    allowCreate,
    allowWhatsApp,
    includePublisherTags,
    contactProtocols,
    collapseOptions,
    onCreateNewContact,
    onAddRecipient,
    onRemoveRecipient,
    originalValue: value,
    isSearching: isLoading,
    recipients: options.map((o) => o.data),
  };

  const publisherValues = compact(
    Object.values(
      groupBy(value, ({ data: recipient }) => {
        return (
          recipient.contactMethod?.publisher?.id ||
          recipient.publisherUser?.publisher?.id ||
          recipient.publisher?.id
        );
      })
    ).map((val) => {
      const publisher =
        val[0].data.contactMethod?.publisher ||
        val[0].data.publisherUser?.publisher ||
        val[0].data.publisher;
      if (!publisher) return null;
      return {
        value: publisher.id,
        label: publisher.name || '',
        type: 'Publisher',
        data: val.map((v) => v.data),
      };
    })
  );

  const formattedValues = [
    ...publisherValues,
    ...value.filter((v) => !Array.isArray(v.data) && v.data.publisherTag),
    ...value.filter((v) => !Array.isArray(v.data) && v.data.newContactValue),
  ];

  return (
    <ThemeProvider
      theme={(parentTheme: any) => ({
        ...parentTheme,
        isInputValueInvalid: isNewContactInvalid,
      })}
    >
      <ThemeConsumer>
        {(theme) => (
          <Form.Select
            data-qa="recipients-search-recipients-search-field"
            tabIndex="0"
            id={id}
            isMulti
            complexValues
            value={formattedValues}
            inputValue={inputValue}
            backspaceRemovesValue={false}
            options={options}
            isClearable={false}
            autoFocus={autoFocus}
            openMenuOnFocus={false}
            onInputChange={handleInputChange}
            onChange={onChange}
            icon={icon}
            onKeyDown={handleKeyDown}
            placeholder={placeholder || ''}
            additionalStyles={{
              valueContainer: {
                maxHeight: `200px`,
                overflowY: 'auto',
              },
            }}
            components={{
              MultiValue,
              MenuList,
              DropdownIndicator: null,
            }}
            pillProps={
              theme && theme.color === 'dark' ? { color: 'darkerGrey' } : {}
            }
            {...extraSelectProps}
          />
        )}
      </ThemeConsumer>
    </ThemeProvider>
  );
};

RecipientsSearch.defaultProps = {
  includePublisherTags: true,
  contactProtocols: ['sms', 'email', 'twitter_dm', 'whats_app', 'apns'],
  collapseOptions: true,
};

export default RecipientsSearch;
