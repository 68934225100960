import React from 'react';
import styled from 'styled-components';
import Attachment from 'types/Attachment';
import { formatLength } from 'helpers/formatNumber';
import { rgba } from 'polished';
import {
  IconPlay,
  IconVideo,
  IconExpand,
  IconGallery,
  IconDownload,
} from 'icons';
import { colors, grid } from 'styles/theme';

interface Props {
  attachments: Attachment[];
  permanent?: boolean;
  savedMediaCount?: number;
}

// prettier-ignore
const Wrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;

  svg {
    position: relative;
    top: 1px;
    filter: drop-shadow(0 2px 3px ${rgba(colors.black, 0.4)});
  }
` as any;

// prettier-ignore
const GradientBg = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: opacity 0.2s;
  opacity: 1;
  z-index: 1;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.5) 100%
  );

  ${Wrapper}:hover & {
    opacity: 0;
  }
`;

// prettier-ignore
const RightIcon = styled.div`
  display: flex;
  position: absolute;
  right: 0;
  bottom: 0;
  align-items: center;
  z-index: 2;
  padding: ${grid(1)};
  text-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.5);
  color: ${rgba(colors.white, 0.8)};
  font-size: 13px;
  font-weight: bold;

  svg {
    position: relative;
    top: 1px;
    margin-left: ${grid(0.5)};
    width: ${grid(2.5)};
    height: ${grid(2.5)};
  }

  ${Wrapper}:hover & {
    opacity: 0;
  }

  ${(props: any) => props.theme.large && `
    padding: ${grid(1.5)};
    font-size: 16px;
    svg {
      top: 4px;
      width: ${grid(3)};
      height: ${grid(3)};
    }
  `}

  ${(props: any) => props.theme.permanentOverlay && 'display: none'};
`;

const LeftIcon = styled(RightIcon)`
  right: auto;
  left: 0;

  svg {
    margin-right: ${grid(0.5)};
    margin-left: 0;
  }
`;

// prettier-ignore
const HoverOverlay = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  transition: opacity 0.2s;
  opacity: 0;
  background-color: ${rgba(colors.grey1, 0.5)};
  text-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.5);
  color: ${colors.white};
  font-size: 15px;
  font-weight: 600;

  svg {
    margin-right: ${grid(1)};
    width: ${grid(3)};
    height: ${grid(3)};

    ${(props: any) => props.iconOnly && `
      width: ${grid(4)};
      height: ${grid(4)};
    `}
  }

  ${Wrapper}:hover & {
    opacity: 1;
  }

  ${(props: any) => props.theme.large && `
    font-size: 18px;
    svg {
      width: ${grid(4)};
      height: ${grid(4)};
    }
  `};

  ${(props: any) => props.theme.permanentOverlay && 'opacity: 1'};
` as any;

export default function Overlay(props: Props) {
  const { attachments, savedMediaCount } = props;
  const showGradientBg =
    attachments.length > 1 ||
    attachments[0].type === 'Video' ||
    !!savedMediaCount;

  return (
    <Wrapper>
      {showGradientBg && <GradientBg />}

      {!!savedMediaCount && (
        <LeftIcon>
          <IconDownload />
          <span>{savedMediaCount}</span>
        </LeftIcon>
      )}

      {attachments.length > 1 ? (
        <>
          <RightIcon>
            <span>{attachments.length}</span>
            <IconGallery />
          </RightIcon>

          <HoverOverlay>
            <IconGallery />
            <span>{attachments.length}</span>
          </HoverOverlay>
        </>
      ) : attachments[0].type === 'Video' ? (
        <>
          <RightIcon>
            <span>{formatLength(attachments[0].length)}</span>
            <IconVideo />
          </RightIcon>

          <HoverOverlay>
            <IconPlay />
            <span>{formatLength(attachments[0].length)}</span>
          </HoverOverlay>
        </>
      ) : (
        <HoverOverlay iconOnly>
          <IconExpand />
        </HoverOverlay>
      )}
    </Wrapper>
  );
}
