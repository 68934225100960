import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getFlashMessages, removeMessage } from 'redux-flash';
import FlashMessageType from 'types/FlashMessage';
import FlashMessage from './FlashMessage';
import { AnimatePresence } from 'framer-motion';

interface State {
  flash: FlashMessageType;
}

export default function FlashMessageContainer() {
  const flashes = useSelector((state: State) => getFlashMessages(state));
  const dispatch = useDispatch();

  return (
    <div className="fixed left-0 bottom-0 z-9999 flex flex-col space-y-2 pl-1.5 pb-1.5 transition-all duration-500">
      <AnimatePresence>
        {flashes &&
          flashes.map((flash: FlashMessageType) => {
            return (
              <FlashMessage
                key={flash.id}
                flash={flash}
                onRemove={() => {
                  dispatch(removeMessage(flash.id));
                }}
              />
            );
          })}
      </AnimatePresence>
    </div>
  );
}
