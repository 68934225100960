import React from 'react';
import { Tooltip } from 'components';
import styled from 'styled-components';
import { grid, colors } from 'styles/theme';

interface Props {
  content: React.ReactNode;
}

// prettier-ignore
const Q = styled.div`
  display: flex;
  position: relative;
  top: 1px;
  align-items: center;
  flex-shrink: 0;
  justify-content: center;
  margin-left: ${grid(0.5)};
  border-radius: 50%;
  background: ${colors.darkGreyBg};
  width: ${grid(2)};
  height: ${grid(2)};
  color: ${colors.white};
  font-size: 12px;
  font-weight: bold;

  ${(props: any) => props.theme.color === 'dark' && `
    color: ${colors.darkText}
  `};
`;

const TooltipWrapper = styled.div`
  max-width: ${grid(32)};
`;

const QTooltip = ({ content }: Props) => (
  <Tooltip content={<TooltipWrapper>{content}</TooltipWrapper>}>
    <Q tabIndex={-1}>?</Q>
  </Tooltip>
);

export default QTooltip;
