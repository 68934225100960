import { useFilterManager } from 'hooks';

type RenderFunc<FilterShape> = (renderProp: {
  filter: FilterShape;
  debouncedFilter: FilterShape;
  updateFilter: (updates: Partial<FilterShape>) => void;
}) => JSX.Element;

interface Props<FilterShape> {
  defaultFilter: FilterShape;
  children: RenderFunc<FilterShape>;
  useQueryString?: boolean;
}

export default function FilterManager<FilterShape>(props: Props<FilterShape>) {
  const { defaultFilter, useQueryString } = props;
  const filterManager = useFilterManager(defaultFilter, useQueryString);

  return props.children(filterManager);
}
