import { useState } from 'react';

/**
 * Simple react hook for handling boolean state
 * @param {boolean} [initialState=false] the initial state
 * @returns {Array} [isTruthy, setTrue, setFalse, toggle]
 */
export default function useModal(
  initial = false
): [boolean, () => void, () => void, () => void] {
  const [isTruthy, setIsTruthy] = useState(initial);
  const setTrue = () => setIsTruthy(true);
  const setFalse = () => setIsTruthy(false);
  const toggle = () => setIsTruthy(!isTruthy);
  return [isTruthy, setTrue, setFalse, toggle];
}
