import React, { useState } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import Attachment from 'types/Attachment';
import { colors, grid } from 'styles/theme';
import { BlurUp } from '..';
import { Overlay } from './components';
import { IconNoMedia } from 'icons';
import { noPropagate } from 'helpers';
import ThumbnailAttachmentModalContainer from './components/ThumbnailAttachmentModalContainer';

interface Props {
  attachments: Attachment[];
  savedMediaCount?: number;
  permanentOverlay?: boolean;
  large?: boolean;
  canExpand?: boolean;
}

// prettier-ignore
const Wrapper = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  margin-right: ${grid(2)};
  border-radius: 4px;
  background-color: ${colors.white};
  background-position: 50% 50%;
  background-size: cover;
  cursor: zoom-in;
  width: 100%;
  height: 100%;
  overflow: hidden;
  text-align: center;
  line-height: ${grid(2)};
  color: ${colors.white};
  font-size: 14px;

  ${(props: any) => props.noMedia && `
    border: 1px solid ${colors.border};
    padding: 0;
    cursor: default;
    svg {
      width: 100%;
      height: 100%;
    }
  `};

  ${(props: any) => props.noExpand && `
    cursor: default;
  `};

` as any;

export default function Thumbnail({
  attachments,
  large,
  permanentOverlay,
  savedMediaCount,
  canExpand = true,
}: Props) {
  const [attachmentModalOpen, setAttachmentModalOpen] = useState(false);

  if (!attachments.length) {
    return (
      <Wrapper noMedia>
        <IconNoMedia />
      </Wrapper>
    );
  }

  const attachment = attachments[0];

  return (
    <ThemeProvider theme={{ large, permanentOverlay }}>
      <>
        <Wrapper
          noExpand={!canExpand}
          onClick={
            canExpand
              ? noPropagate(() => setAttachmentModalOpen(true))
              : undefined
          }
        >
          <BlurUp
            srcSet={attachment.thumbnail}
            preview={attachment.blurThumbnail}
          />

          {canExpand && (
            <Overlay
              permanent={permanentOverlay}
              savedMediaCount={savedMediaCount}
              attachments={attachments}
            />
          )}
        </Wrapper>
        {attachmentModalOpen && (
          <ThumbnailAttachmentModalContainer
            attachments={attachments}
            onRequestClose={() => setAttachmentModalOpen(false)}
          />
        )}
      </>
    </ThemeProvider>
  );
}
