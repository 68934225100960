import React, { useState } from 'react';
import ClickOutside from 'react-onclickout';
import { useSocialAccountSearch, useIntl } from 'hooks';
import SocialAccount from 'types/SocialAccount';
import { hasClass } from 'helpers';
import Tooltip from './Tooltip';
import SocialAccountSearchResults from './SocialAccountSearchResults';
import { Form } from 'components';
import { IconLoader, IconSearch } from 'icons';
import { TippyProps } from '@tippyjs/react';

interface Props {
  platform: string;
  onSelectAccount: (account: SocialAccount | null) => void;
  placement?: TippyProps['placement'];
  selectText?: string;
}

export default function SocialAccountSearchField(props: Props) {
  const { platform, onSelectAccount, placement, selectText } = props;
  const { query, setQuery, isSearching, searchResults, noResults } =
    useSocialAccountSearch({
      platform,
      searchOnType: true,
    });

  const { t } = useIntl();

  const [isFocused, setIsFocused] = useState(false);

  // Make this async so that it works right with SocialAcountSearchResults
  const handleSelectAccount = async (account: SocialAccount | null) => {
    await onSelectAccount(account);
  };

  const handleClickOutside = (e: any) => {
    if (isFocused && !hasClass(e.target, 'tippy-box')) {
      setIsFocused(false);
    }
  };

  const wrapperClassName = noResults
    ? 'p-1.5 text-left'
    : 'px-1.5 max-h-36 overflow-y-auto';

  return (
    <Tooltip
      visible={isFocused && (!!searchResults.length || noResults)}
      trigger="manual"
      interactive
      theme="socialieNoPad"
      placement={placement || 'bottom-start'}
      content={
        <div className={`w-44 ${wrapperClassName}`}>
          <SocialAccountSearchResults
            searchResults={searchResults}
            noResults={noResults}
            platform={platform}
            onSave={handleSelectAccount}
            selectText={selectText}
          />
        </div>
      }
    >
      <div>
        <ClickOutside onClickOut={handleClickOutside}>
          <Form.TextInput
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            icon={isSearching ? <IconLoader /> : <IconSearch />}
            placeholder={t(
              `AddNewPublisher__SocialAccountPlaceholder--${platform}`
            )}
            type="search"
            onKeyDown={ignoreEnter}
            onFocus={() => setIsFocused(true)}
          />
        </ClickOutside>
      </div>
    </Tooltip>
  );
}

function ignoreEnter(event: React.KeyboardEvent<any>) {
  if (event.key === 'Enter') {
    event.preventDefault();
  }
}
