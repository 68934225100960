import React, { useState } from 'react';
import { Mutation } from 'react-apollo';
import { Prompt, useHistory } from 'react-router-dom';
import { Location } from 'history';

import * as UPDATE_BATCH_SUGGESTION from 'graphql/mutations/updateBatchSuggestion.graphql';
import BatchSuggestion from 'types/BatchSuggestion';
import { useIntl, useIsMobile } from 'hooks';
import { IconEdit } from 'icons';
import { Form, ConfirmModal } from 'components';

interface RenameDraftProps {
  draftId: number;
  name: string;
  userChosenName?: boolean;
  iconSmall?: boolean;
}

interface MutationData {
  updateBatchSuggestion: {
    batchSuggestion: BatchSuggestion;
    errors?: string;
  };
}

function RenameDraft(props: RenameDraftProps) {
  const { draftId, name, userChosenName, iconSmall = false } = props;
  const { t } = useIntl();

  const history = useHistory();
  const { isMobile } = useIsMobile();

  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [nextLocation, setNextLocation] = useState<Location | null>(null);
  const [inputValue, setInputValue] = useState(() => name);
  const [isError, setIsError] = useState('');

  function openConfirmModal(e: React.MouseEvent<HTMLButtonElement>) {
    e.stopPropagation();
    setInputValue(name);
    setIsConfirmModalOpen(true);
  }
  function closeConfirmModal() {
    setIsError('');
    setIsConfirmModalOpen(false);
  }

  function onMutationCompleted(data: MutationData) {
    if (!data.updateBatchSuggestion.errors) {
      setIsError('');
      setIsConfirmModalOpen(false);

      if (nextLocation && nextLocation.pathname !== history.location.pathname) {
        history.push(nextLocation.pathname); // Navigate to the previous blocked location
      }
    }

    setIsError(data.updateBatchSuggestion.errors || '');
  }

  function handleBlockedNavigation(nextLocation: Location) {
    // userChosenName might be undefined for legacy drafts. In that case, we assume the user has chosen the name.
    if (!nextLocation?.state?.allowDefaultName && userChosenName === false) {
      setNextLocation(nextLocation);
      setIsConfirmModalOpen(true);
      return false;
    }
    return true;
  }

  return (
    <Mutation<MutationData>
      mutation={UPDATE_BATCH_SUGGESTION}
      variables={{
        id: draftId,
        name: inputValue,
        userChosenName: true,
      }}
      onCompleted={onMutationCompleted}
      refetchQueries={['BatchSuggestion, DraftsIndexQuery']}
    >
      {(renameDraft, { loading }) => (
        <>
          <button
            className="flex items-center justify-center"
            data-qa="rename-draft-rename-draft-button"
            onClick={openConfirmModal}
          >
            <IconEdit
              className={`mr-1 block ${iconSmall ? 'h-2 w-2' : 'h-3 w-3'}`}
            />
            <div className="max-w-15 truncate not-italic laptop:max-w-41 desktop:max-w-62 large:max-w-full">
              {isMobile ? t('DraftRename__RenameTitle') : name}
            </div>
          </button>

          <Prompt
            when={userChosenName === false}
            message={handleBlockedNavigation}
          />

          <ConfirmModal
            isOpen={isConfirmModalOpen}
            onRequestClose={closeConfirmModal}
            onConfirm={renameDraft}
            isLoading={loading}
            heading={'DraftRename__SaveDraft'}
            confirmText="Global__Save"
            cancelText="Global__Cancel"
          >
            <div>
              <label
                htmlFor="renameDraftInput"
                className="mb-0.5 block text-dark"
              >
                {t('DraftRename__RenameTitle')}
              </label>

              <Form.TextInput
                id="renameDraftInput"
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                autoFocus
              />

              {isError && <Form.Errors>{isError}</Form.Errors>}
            </div>
          </ConfirmModal>
        </>
      )}
    </Mutation>
  );
}

export default RenameDraft;
