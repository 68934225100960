import React from 'react';
import isEqual from 'lodash/isEqual';
import TextareaAutosize from 'react-autosize-textarea';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { Form, WithIntl, RemainingChars } from 'components';
import { grid, colors, font } from 'styles/theme';
import { IconSuggestion } from 'icons';
import Suggestion from 'types/Suggestion';
import { mixins } from 'styles';

interface Props {
  suggestions: Suggestion[];
  onChange: (value?: string) => any;
}

interface State {
  value: string;
  focused: boolean;
}

const Wrapper = styled.div`
  ${mixins.input};
  display: flex;
  position: relative;
  padding: ${grid(0.5)};
  height: auto;
  min-height: ${grid(4.5)};

  textarea {
    position: relative;
    top: -1px;
    appearance: none;
    border: 0;
    width: 100%;
    resize: none;
    line-height: ${grid(2.5)};
    color: ${colors.darkText};
    font-family: ${font.sans};
    font-size: 14px;

    &:focus {
      outline: none;
    }
  }
`;

const Icon = styled.div`
  margin-right: ${grid(0.5)};
  padding: ${grid(0.5)};
  width: ${grid(3)};
  height: ${grid(3)};
  color: ${colors.lightIcon};

  svg {
    display: block;
    width: 100%;
    height: 100%;
  }
`;

export default class Subject extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      focused: false,
      ...this.getInitialState(),
    };
  }

  componentDidUpdate(prevProps: Props) {
    const prevIds = prevProps.suggestions.map((s) => s.id);
    const currentIds = this.props.suggestions.map((s) => s.id);
    if (!isEqual(prevIds, currentIds)) {
      this.setState(this.getInitialState());
    }
  }

  getInitialState = () => {
    const { suggestions } = this.props;
    const value = suggestions.length === 1 ? suggestions[0].subject : '';

    return { value: value || '' };
  };

  onChange = (value: string) => {
    this.setState({ value }, () => {
      this.props.onChange(value);
    });
  };

  render() {
    const { suggestions } = this.props;
    const { value } = this.state;
    const remainingChars = 45 - value.length;
    if (!suggestions[0].mediaType) return null;

    return (
      <WithIntl>
        {(_, t) => {
          const placeholder =
            suggestions.length === 1
              ? t('SuggestionForm__SubjectPlaceholder')
              : t('SuggestionForm__MultiPlaceholder', {
                  count: suggestions.length,
                });

          return (
            <Form.Field>
              <Form.Label bold>
                <FormattedMessage id="SuggestionForm__Subject" />
              </Form.Label>
              <Wrapper>
                <Icon>
                  <IconSuggestion />
                </Icon>

                <TextareaAutosize
                  value={value}
                  onChange={(e) => this.onChange(e.target['value'])}
                  placeholder={placeholder}
                  maxLength={45}
                />

                <RemainingChars remainingChars={remainingChars} />
              </Wrapper>
            </Form.Field>
          );
        }}
      </WithIntl>
    );
  }
}
