import React, { useState } from 'react';
import {
  FilterWrapper,
  SearchAndFilterTopBar,
  StickyTopBar,
  RecordCount,
} from './components';
import { SearchProps } from './components/SearchAndFilterTopBar';

interface Props {
  children: React.ReactNode;
  renderFilters?: React.ReactNode;
  renderSubNav?: React.ReactNode;
  renderSubNavLeft?: boolean;
  isSearchEnabled?: boolean;
  searchProps?: SearchProps;
  isFiltered?: boolean;
}

export default function IndexPage(props: Props) {
  const {
    children,
    renderFilters,
    renderSubNav = false,
    renderSubNavLeft,
    isFiltered,
    isSearchEnabled,
    searchProps,
  } = props;

  const [areFiltersOpen, setAreFiltersOpen] = useState(false);

  return (
    <>
      <SearchAndFilterTopBar
        hasFilters={!!renderFilters}
        areFiltersOpen={areFiltersOpen}
        isFiltered={isFiltered}
        toggleFilters={() => setAreFiltersOpen(!areFiltersOpen)}
        isSearchEnabled={isSearchEnabled}
        searchProps={searchProps}
        renderSubNav={renderSubNav}
        renderSubNavLeft={renderSubNavLeft}
      />

      <div className="flex items-stretch overflow-hidden">
        {renderFilters && (
          <FilterWrapper isOpen={areFiltersOpen}>{renderFilters}</FilterWrapper>
        )}

        <div className="flex-1 overflow-hidden p-3">{children}</div>
      </div>
    </>
  );
}

IndexPage.StickyTopBar = StickyTopBar;
IndexPage.RecordCount = RecordCount;
