import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Mutation } from '@apollo/react-components';
import PublisherTag from 'types/PublisherTag';
import Button from 'components/Button';
import Form from 'components/Form';
import Modal from 'components/Modal';
import WithIntl from 'components/WithIntl';
import Loader from 'components/Loader';
import ErrorDebug from 'components/ErrorDebug';
import * as mutation from './addPublisherTag.graphql';
import { noPropagate } from 'helpers';
import usePublisherTags from 'hooks/usePublisherTags';

interface Props {
  isOpen: boolean;
  onRequestClose: () => void;
  publisherIds: number | number[] | (string | number)[];
}

interface MutationData {
  addPublisherTag: { publisherTag?: PublisherTag; errors?: string };
}

export default function AddPublisherTagModal(props: Props) {
  const [tagIds, setTagIds] = useState<(number | string)[]>([]);
  const updateTagIds = (tagIds: (number | string)[]) => setTagIds(tagIds);

  const { isOpen, onRequestClose, publisherIds } = props;

  const itemCount = Array.isArray(publisherIds) ? publisherIds.length : 1;

  const { publisherTags, loading, error } = usePublisherTags();

  const options = publisherTags.map((t) => ({
    value: t.id,
    label: t.name,
  }));

  return (
    <WithIntl>
      {(_, t) => (
        <Modal
          isOpen={isOpen}
          onRequestClose={onRequestClose}
          contentLabel={t('AddPublisherTagModal__Heading')}
          renderHeading={
            <FormattedMessage id="AddPublisherTagModal__Heading" />
          }
        >
          {loading ? (
            <Loader />
          ) : error ? (
            <ErrorDebug error={error} />
          ) : (
            <Mutation<MutationData>
              mutation={mutation}
              variables={{ publisherIds, tagIds }}
              onCompleted={(data) => {
                if (!data.addPublisherTag.errors) {
                  updateTagIds([]);
                  onRequestClose();
                }
              }}
              refetchQueries={['PublisherTags']}
            >
              {(addPublisherTag, { loading: isAdding }) => (
                <Form onSubmit={addPublisherTag}>
                  <Form.Field>
                    {itemCount > 1 && (
                      <Form.Label>
                        <FormattedMessage
                          id="AddPublisherTagModal__Description"
                          values={{ itemCount }}
                        />
                      </Form.Label>
                    )}

                    <div data-testid="tag-select">
                      <Form.Select
                        isMulti
                        value={tagIds}
                        onChange={updateTagIds}
                        creatable
                        placeholder={t('AddPublisherTagModal__Placeholder')}
                        options={options}
                        isClearable={false}
                        formatCreateLabel={(label) =>
                          t('AddPublisherTagModal__CreatePrompt', {
                            label,
                          })
                        }
                      />
                    </div>
                  </Form.Field>

                  <Modal.Actions>
                    <Button
                      inlineBlock
                      cancel
                      type="button"
                      onClick={noPropagate(props.onRequestClose)}
                    >
                      <FormattedMessage id="Global__Cancel" />
                    </Button>

                    <Button
                      data-testid="submit"
                      inlineBlock
                      filledBg
                      disabled={isAdding}
                      type="submit"
                    >
                      <FormattedMessage id="Global__Done" />
                    </Button>
                  </Modal.Actions>
                </Form>
              )}
            </Mutation>
          )}
        </Modal>
      )}
    </WithIntl>
  );
}
