import React from 'react';
import groupBy from 'lodash/groupBy';
import values from 'lodash/values';
import Recipient from 'types/Recipient';
import {
  Wrapper,
  PublisherRow,
  PublisherTagRow,
  NoResults,
  Heading,
  Loading,
} from './components';

interface Props {
  selectProps: {
    recipients: Recipient[];
    isSearching: boolean;
    query: string;
    inputValue: string;
    onAddRecipient: (recipients: Recipient | Recipient[]) => void;
    onCreateNewContact: () => void;
    collapseOptions: boolean;
    contactProtocols: string[];
    allowCreate: boolean;
  };
  hasValue: boolean;
}

export default function MenuList({ selectProps, hasValue }: Props) {
  const {
    query,
    recipients,
    onAddRecipient,
    onCreateNewContact,
    collapseOptions,
    contactProtocols,
    allowCreate,
    isSearching,
  } = selectProps;

  if (!query && hasValue) return <div />;

  if (!query && !hasValue) return <div />;

  if (query && isSearching) return <Loading />;

  if (query && !recipients.length) {
    return (
      <NoResults
        inputValue={query}
        onCreateNewContact={onCreateNewContact}
        allowCreate={allowCreate}
      />
    );
  }

  const contactableRecipients = recipients.filter(
    (r) => !!r.contactMethod || !!r.publisherUser
  );
  const publisherTagRecipients = recipients.filter((r) => !!r.publisherTag);
  const uncontactablePublisherRecipients = recipients.filter(
    (r) => !!r.publisher
  );

  const contactableRecipientsByPublisher = values(
    groupBy(
      contactableRecipients,
      (r) => r.contactMethod?.publisher?.id || r.publisherUser?.publisher?.id
    )
  );

  return (
    <Wrapper>
      {(!!contactableRecipientsByPublisher.length ||
        !!uncontactablePublisherRecipients.length) && (
        <>
          <Heading title="RecipientsSearch__Publishers" />
          {contactableRecipientsByPublisher.map((recipients) => (
            <PublisherRow
              key={
                recipients[0].publisherUser?.publisher?.id ||
                recipients[0].contactMethod?.publisher.id
              }
              recipients={recipients}
              onAddRecipient={onAddRecipient}
              collapsable={collapseOptions}
              contactProtocols={contactProtocols}
            />
          ))}

          {uncontactablePublisherRecipients.map((recipient) => (
            <PublisherRow
              key={recipient.id}
              publisher={recipient.publisher}
              recipients={[recipient]}
              onAddRecipient={onAddRecipient}
              collapsable={false}
              contactProtocols={contactProtocols}
              noMethodsMsg="RecipientsSearch__NoMethods"
            />
          ))}
        </>
      )}

      {!!publisherTagRecipients.length && (
        <>
          <Heading title="RecipientsSearch__PublisherTags" />
          {publisherTagRecipients.map((recipient) => (
            <PublisherTagRow
              key={recipient.publisherTag!.id}
              recipient={recipient}
              onSelect={() => onAddRecipient(recipient)}
            />
          ))}
        </>
      )}
    </Wrapper>
  );
}
