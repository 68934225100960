import React from 'react';
import { IconPhone, IconEmailAlt, IconTwitter, IconWhatsApp } from 'icons';
import ContactMethodType from 'types/ContactMethod';

interface Props {
  contactMethod: ContactMethodType;
}

const ContactMethodIcon = (props: Props) => {
  const { contactMethod } = props;
  const classNames = 'w-2 mr-1 self-start';
  const colorClassNames = contactMethod.wantsNotifications
    ? 'text-socialiePink'
    : 'text-grey6';
  switch (contactMethod.protocol) {
    case 'email':
      return (
        <IconEmailAlt className={`${classNames} mt-0.25 ${colorClassNames}`} />
      );
    case 'sms':
      return (
        <IconPhone className={`${classNames} mt-0.25 ${colorClassNames}`} />
      );
    case 'twitter_dm':
      return (
        <IconTwitter className={`${classNames} mt-0 ${colorClassNames}`} />
      );
    case 'whats_app':
      return (
        <IconWhatsApp className={`${classNames} mt-0 ${colorClassNames}`} />
      );
    default:
      return null;
  }
};

export default ContactMethodIcon;
