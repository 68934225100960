import * as REMOVE_TAGS_FROM_SUGGESTIONS from 'graphql/mutations/removeTagsFromSuggestions.graphql';
import apolloClient from '../apolloClient';

type IDs = number | string | (number | string)[];

const removeTagsFromSuggestions = async (suggestionIds: IDs, tagIds: IDs) => {
  const { errors, data } = await apolloClient.mutate({
    mutation: REMOVE_TAGS_FROM_SUGGESTIONS,
    variables: { suggestionIds, tagIds },
  });

  if (errors) return { errors };
  if (data && data.errors) return { errors: data.errors };
  if (data) return data;
  return null;
};

export default removeTagsFromSuggestions;
