interface Options {
  connector: string;
  oxford?: boolean;
}

export default function wordsToSentence(words: string[], options: Options) {
  const hasOxfordComma = options.oxford && words.length > 2;
  return (
    words.slice(0, words.length - 1).join(', ') +
    `${hasOxfordComma ? ', ' : ' '}${options.connector} ` +
    words.slice(-1)
  );
}
