import React, { useState, useCallback } from 'react';
import { ScrollToTopOnMount } from 'components';

export default function MediaLibraryLoading() {
  const [width, setWidth] = useState(0);

  const wrapperRef = useCallback((node) => {
    if (node !== null) setWidth(node.offsetWidth);
  }, []);

  if (!width) return <div className="h-screen" ref={wrapperRef} />;

  const boxWidth = width / 3;
  const boxHeight = boxWidth / 1.7777778;

  return (
    <div className="-mx-0.5 flex flex-wrap">
      <ScrollToTopOnMount />
      {Array.from(Array(9).keys()).map((i) => (
        <div
          key={i}
          className="p-0.5"
          style={{ width: `${boxWidth}px`, height: `${boxHeight}px` }}
        >
          <div className="h-full w-full bg-darkGreyBg" />
        </div>
      ))}
    </div>
  );
}
