import React from 'react';
import styled, { css } from 'styled-components';
import { grid, colors } from 'styles/theme';
import { Sidebar } from './components';
import ScrollToTopOnMount from '../ScrollToTopOnMount';
import { GlobalCSSVariables } from 'Layout/components/GlobalStyle';

interface Props {
  children: React.ReactNode;
}

const Wrapper = styled.div`
  display: flex;
  align-items: stretch;
`;

// prettier-ignore
const Section = styled.div`
  margin-bottom: ${grid(6)};

  &:last-child {
    margin-bottom: 0;
  }

  ${(props: any) => props.divided && `
    display: flex;
    align-items: stretch;
  `}

  ${(props:any) => props.bordered && `
    margin-bottom: ${grid(4)};
    border-bottom: 1px solid ${colors.border};
    padding-bottom: ${grid(4)};

    &:last-child {
      margin-bottom: 0;
      border-bottom: 0;
      padding-bottom: 0;
    }
  `}
` as any;

const InnerSidebar = styled.div`
  flex-shrink: 0;
  border-right: 1px solid ${colors.border};
  padding: ${grid(2.5)};
  width: ${grid(32)};
`;

const StickyInnerSidebar = styled(InnerSidebar)`
  position: fixed;
  top: var(${GlobalCSSVariables.topOffset});
  left: 0;
  height: calc(100vh - var(${GlobalCSSVariables.topOffset}));
  overflow: auto;
  z-index: 1;

  ${(props: any) => props.inverse && `background-color: ${colors.white}`};

  ::-webkit-scrollbar {
    background: transparent;
    width: 0px;
  }

  hr {
    margin: ${grid(2.5)} 0;
    border: 0;
    background: ${colors.border};
    width: ${grid(6.5)};
    height: 1px;
  }
` as any;

const Show = {
  OuterWrapper: styled.div`
    display: flex;
    flex-direction: column;
    min-height: 100%;
  `,

  Main: styled.div`
    flex: 1;
    background: ${colors.greyBg};
    padding: ${grid(4)} ${grid(2.5)};

    ${(props: any) => props.white && `background: ${colors.white}`};
  ` as any,

  Wrapper: styled.div`
    display: flex;
    align-items: stretch;
    flex: 1;
  `,

  Sidebar: styled.div`
    border-left: 1px solid ${colors.border};
    background-color: ${colors.white};
    padding: ${grid(4)} ${grid(2.5)};
    width: ${grid(37.5)};
  `,
};

// Start Main

// prettier-ignore
const Main = styled.div`
  flex: 1;
  padding: ${`${grid(3)} ${grid(2.5)}`};
  min-height: calc(100vh - var(${GlobalCSSVariables.topOffset}));

  ${(props: any) => props.hideOverflow && `
    overflow: hidden;
  `};

  ${(props: any) => props.noPad && `
    padding: 0;
  `};

  ${(props: any) => props.inner && css`
    background-color: ${colors.white};
    padding: ${grid(3)} ${grid(2.5)};
    margin-left: ${grid(32)};
    ${(props: any) => props.inverse && `background-color: ${colors.greyBg}`};
  `};

` as any;

Main.defaultProps = {
  hideOverflow: true,
};

// END Main

export default class Page extends React.Component<Props> {
  public static Main = Main;
  public static Sidebar = Sidebar;
  public static InnerSidebar = InnerSidebar;
  public static StickyInnerSidebar = StickyInnerSidebar;
  public static Section = Section;
  public static Show = Show;

  render() {
    return (
      <Wrapper>
        <ScrollToTopOnMount />
        {this.props.children}
      </Wrapper>
    );
  }
}
