import React, {
  useRef,
  useState,
  useEffect,
  useLayoutEffect,
  useContext,
} from 'react';
import clsx from 'clsx';
import { useCallback } from 'react';

interface Props {
  children: React.ReactNode;
  variant?: 'white' | 'socialie';
}

const ToggleGroupContext = React.createContext({
  onSelect: (idx: number) => {},
  variant: 'socialie' as Props['variant'],
});

export default function ToggleGroup({ variant, children }: Props) {
  const [selectedIdx, setSelectedIdx] = useState<number | null>(null);
  const [selectedDimensions, setSelectedDimensions] = useState({
    width: 0,
    offset: 0,
  });
  const wrapperRef = useRef<HTMLDivElement>(null);

  // Get the selected dimensions when the selected idx changes
  useLayoutEffect(() => {
    if (wrapperRef.current && selectedIdx != null) {
      const child = wrapperRef.current.children[selectedIdx] as HTMLElement;

      if (child) {
        setSelectedDimensions({
          offset: child.offsetLeft,
          width: child.offsetWidth,
        });
      }
    }
  }, [selectedIdx]);

  const handleSelect = useCallback((idx: number) => {
    setSelectedIdx(idx);
  }, []);

  return (
    <div>
      <div
        className="relative inline-flex items-stretch rounded-xl bg-grey7 py-0.5 pl-0.5"
        ref={wrapperRef}
      >
        <ToggleGroupContext.Provider
          value={{ variant, onSelect: handleSelect }}
        >
          {children}
        </ToggleGroupContext.Provider>

        {selectedIdx != null && (
          <div
            className={clsx(
              'absolute left-0 z-30 rounded-lg transition duration-150',
              {
                'bg-white': variant === 'white',
                'bg-socialiePink': variant === 'socialie',
              }
            )}
            style={{
              transform: `translateX(${selectedDimensions.offset}px)`,
              width: `${selectedDimensions.width}px`,
              top: '4px',
              bottom: '4px',
            }}
          />
        )}
      </div>
    </div>
  );
}

ToggleGroup.defaultProps = {
  variant: 'socialie',
};

ToggleGroup.Option = Option;

interface OptionProps {
  isSelected: boolean;
  onSelect: () => void;
  title: React.ReactNode;
  icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  hideTitle?: boolean;
}

function Option(props: OptionProps) {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const { isSelected, title, icon, hideTitle } = props;
  const { onSelect, variant } = useContext(ToggleGroupContext);

  useEffect(() => {
    if (buttonRef.current?.parentNode && isSelected) {
      const idx = Array.prototype.indexOf.call(
        buttonRef.current.parentNode.children,
        buttonRef.current
      );
      onSelect(idx);
    }
  }, [isSelected, onSelect]);

  return (
    <button
      ref={buttonRef}
      type="button"
      title={typeof title === 'string' ? title : undefined}
      className={clsx(
        'transition-fast z-40 mr-0.5 h-4 rounded-lg bg-transparent px-1.5 text-14',
        {
          'text-body hover:bg-grey7 hover:text-dark ': !isSelected,
          'text-white': isSelected && variant === 'socialie',
          'text-dark': isSelected && variant === 'white',
        }
      )}
      onClick={props.onSelect}
    >
      <span className="flex items-center">
        {!!icon &&
          React.createElement(icon, {
            className: clsx('block', {
              'text-light': !isSelected,
              'text-white': isSelected && variant === 'socialie',
              'text-body': isSelected && variant === 'white',
              'mr-1 w-2 h-2': !hideTitle,
              'w-2.5 h-2.5': hideTitle,
            }),
          })}
        {!hideTitle && <span className="bump-up-1 font-bold">{title}</span>}
      </span>
    </button>
  );
}
