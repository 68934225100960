import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Form, Button, AuthForm, ErrorDebug, Loader } from 'components';
import { flashMessage, routes, updateApolloCacheAfterLogin } from 'helpers';
import { Link, useHistory } from 'react-router-dom';
import { useMutation, useQuery } from 'react-apollo';
import * as RESET_PASSWORD from 'graphql/mutations/resetPassword.graphql';
import * as RESET_PASSWORD_TOKEN_QUERY from 'graphql/queries/resetPasswordTokenQuery.graphql';
import User from 'types/User';
import SemiLoggedInUser from 'types/SemiLoggedInUser';
import { useIntl } from 'hooks';

const MIN_PASSWORD_LENGTH = 8;

interface MutationData {
  resetPassword: {
    token?: string;
    errors?: string;
    currentUser?: User;
    semiLoggedInUser?: SemiLoggedInUser;
  };
}

interface MutationVars {
  password: string;
  passwordConfirmation: string;
  resetPasswordToken: string;
}

interface QueryData {
  isValidResetPasswordToken: boolean;
}
interface QueryVars {
  token: string;
}

export default function ResetPassword() {
  const { t } = useIntl();
  const history = useHistory();
  const urlParams = new URLSearchParams(window.location.search);
  const resetPasswordToken = urlParams.get('token')!;

  const {
    data,
    loading: isLoading,
    error,
  } = useQuery<QueryData, QueryVars>(RESET_PASSWORD_TOKEN_QUERY, {
    variables: {
      token: resetPasswordToken,
    },
  });

  const [values, setValues] = useState({
    password: '',
    passwordConfirmation: '',
  });

  const { password, passwordConfirmation } = values;

  const [resetPassword, { loading }] = useMutation<MutationData, MutationVars>(
    RESET_PASSWORD,
    {
      variables: {
        password,
        passwordConfirmation,
        resetPasswordToken: resetPasswordToken || '',
      },
      update: (cache, { data }) => {
        if (!data?.resetPassword) return;

        updateApolloCacheAfterLogin(cache, data.resetPassword);
      },
      onError: () => {
        flashMessage('ResetPassword__Error', { style: 'error' });
      },
      onCompleted: (data) => {
        if (data && !data.resetPassword.errors) {
          flashMessage('ResetPassword__Success');
        } else {
          flashMessage('Global__UnexpectedError', { style: 'error' });
        }
      },
    }
  );

  const tooShort = !!password && password.length < MIN_PASSWORD_LENGTH;
  const doesNotMatch = !!password && password !== passwordConfirmation;
  const isDisabled =
    loading || !password || !passwordConfirmation || tooShort || doesNotMatch;

  if (isLoading) return <Loader />;
  if (error) return <ErrorDebug error={error} />;

  return (
    <AuthForm>
      {data && (
        <>
          <div className="mb-1.5 text-center leading-32">
            {!data.isValidResetPasswordToken ? (
              <h1>{t('ResetPassword__InvalidToken--Heading')}</h1>
            ) : (
              <h1>
                <FormattedMessage id="ResetPassword__Heading" />
              </h1>
            )}
          </div>

          {!data.isValidResetPasswordToken ? (
            <>
              <div className="mb-3 text-center">
                <p>{t('ResetPassword__InvalidToken--Desc')}</p>
              </div>
              <Button
                filledBg
                onClick={() => history.push(routes.auth.forgotPassword)}
              >
                {t('ResetPassword__ExpiredToken--RequestLink')}
              </Button>
              <div className="text-center">
                <Link
                  className="text-socialiePink hover:underline"
                  to={routes.auth.login}
                >
                  {t('ResetPassword__ExpiredToken--ReturnToSignIn')}
                </Link>
              </div>
            </>
          ) : (
            <Form onSubmit={resetPassword}>
              <Form.Field>
                <Form.Label>
                  <FormattedMessage id="ResetPassword__NewPassword" />
                </Form.Label>
                <Form.TextInput
                  value={password}
                  type="password"
                  errors={tooShort ? 'ResetPassword__TooShort' : undefined}
                  onChange={(e) =>
                    setValues({ ...values, password: e.target.value })
                  }
                />
              </Form.Field>

              <Form.Field>
                <Form.Label>
                  <FormattedMessage id="ResetPassword__ConfirmNewPassword" />
                </Form.Label>
                <Form.TextInput
                  value={passwordConfirmation}
                  type="password"
                  errors={
                    doesNotMatch ? 'ResetPassword__DoesNotMatch' : undefined
                  }
                  onChange={(e) =>
                    setValues({
                      ...values,
                      passwordConfirmation: e.target.value,
                    })
                  }
                />
              </Form.Field>

              <Button filledBg type="submit" disabled={isDisabled}>
                <FormattedMessage
                  id={loading ? 'Global__Loading' : 'Global__Submit'}
                />
              </Button>
            </Form>
          )}
        </>
      )}
    </AuthForm>
  );
}
