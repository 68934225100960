const platforms = ['facebook', 'twitter', 'instagram', 'linkedin'];

const requestStatuses = {
  new_request: 'new_request',
  pending: 'pending',
  send_failed: 'send_failed',
  opened: 'opened',
  unsubscribed: 'unsubscribed',
  declined: 'declined',
  published: 'published',
  scheduled: 'scheduled',
  canceled: 'canceled',
  failed: 'failed',
  submitted: 'submitted',
};

const requestOpenedStatuses = [
  requestStatuses.opened,
  requestStatuses.unsubscribed,
  requestStatuses.declined,
  requestStatuses.published,
  requestStatuses.scheduled,
  requestStatuses.canceled,
  requestStatuses.failed,
  requestStatuses.submitted,
];

const requestSentStatuses = [...requestOpenedStatuses, requestStatuses.pending];

const requestPublishedStatuses = [
  requestStatuses.new_request,
  requestStatuses.pending,
  requestStatuses.send_failed,
  requestStatuses.opened,
  requestStatuses.scheduled,
  requestStatuses.canceled,
  requestStatuses.failed,
];

const requestErrorStatuses = [
  requestStatuses.failed,
  requestStatuses.send_failed,
];

const timezone = 'America/New_York';

const dateFilterOptions = [
  'all',
  'today',
  'yesterday',
  'last7',
  'last30',
  'last90',
  'last365',
  'custom',
];

const acceptFiles = 'image/jpeg,image/gif,image/png,video/mp4,video/quicktime';

const timeOptions = [
  { value: '0000', label: '12:00 AM' },
  { value: '0030', label: '12:30 AM' },
  { value: '0100', label: '1:00 AM' },
  { value: '0130', label: '1:30 AM' },
  { value: '0200', label: '2:00 AM' },
  { value: '0230', label: '2:30 AM' },
  { value: '0300', label: '3:00 AM' },
  { value: '0330', label: '3:30 AM' },
  { value: '0400', label: '4:00 AM' },
  { value: '0430', label: '4:30 AM' },
  { value: '0500', label: '5:00 AM' },
  { value: '0530', label: '5:30 AM' },
  { value: '0600', label: '6:00 AM' },
  { value: '0630', label: '6:30 AM' },
  { value: '0700', label: '7:00 AM' },
  { value: '0730', label: '7:30 AM' },
  { value: '0800', label: '8:00 AM' },
  { value: '0830', label: '8:30 AM' },
  { value: '0900', label: '9:00 AM' },
  { value: '0930', label: '9:30 AM' },
  { value: '1000', label: '10:00 AM' },
  { value: '1030', label: '10:30 AM' },
  { value: '1100', label: '11:00 AM' },
  { value: '1130', label: '11:30 AM' },
  { value: '1200', label: '12:00 PM' },
  { value: '1230', label: '12:30 PM' },
  { value: '1300', label: '1:00 PM' },
  { value: '1330', label: '1:30 PM' },
  { value: '1400', label: '2:00 PM' },
  { value: '1430', label: '2:30 PM' },
  { value: '1500', label: '3:00 PM' },
  { value: '1530', label: '3:30 PM' },
  { value: '1600', label: '4:00 PM' },
  { value: '1630', label: '4:30 PM' },
  { value: '1700', label: '5:00 PM' },
  { value: '1730', label: '5:30 PM' },
  { value: '1800', label: '6:00 PM' },
  { value: '1830', label: '6:30 PM' },
  { value: '1900', label: '7:00 PM' },
  { value: '1930', label: '7:30 PM' },
  { value: '2000', label: '8:00 PM' },
  { value: '2030', label: '8:30 PM' },
  { value: '2100', label: '9:00 PM' },
  { value: '2130', label: '9:30 PM' },
  { value: '2200', label: '10:00 PM' },
  { value: '2230', label: '10:30 PM' },
  { value: '2300', label: '11:00 PM' },
  { value: '2330', label: '11:30 PM' },
];

const chatPageSize = 15;

const suggestionValidation = {
  minPostAtLeadTime: 5,
  minExpiresAtLeadTime: 5,
};

const publicIntegrationTypes = [
  'ap',
  'aprimo',
  'asset_bank',
  'azzuu',
  'box',
  'brandfolder',
  'bynder',
  'capture',
  'dropbox',
  'getty_search',
  'media_library',
  'grabyo',
  'imagn',
  'intel',
  'libris',
  'playing_surface',
  'reach_engine',
  's3',
  'scoopa',
  'shutterstock',
  'wsc',
];

const godModeIntegrationTypes: string[] = [];

const lcwIntegrationTypes = ['media_library'];

const contentIntegrationMetadataFields = {
  ap: ['title', 'caption', 'keywords', 'credit'],
  aprimo: [
    'caption',
    'credit',
    'file_name',
    'keywords',
    'photographer',
    'title',
  ],
  azzuu: ['caption', 'file_name', 'keywords', 'title'],
  asset_bank: [
    'caption',
    'credit',
    'file_name',
    'folder_name',
    'parent_folder_name',
    'content_integration_path',
    'keywords',
    'location',
    'people_in_this_media',
    'photographer',
    'title',
  ],
  bbm: ['caption', 'file_name', 'gallery_name', 'keywords', 'title'],
  box: [
    'caption',
    'credit',
    'keywords',
    'location',
    'people_in_this_media',
    'photographer',
    'title',
    'file_name',
    'folder_name',
    'parent_folder_name',
    'content_integration_path',
  ],
  brandfolder: [
    'caption',
    'credit',
    'keywords',
    'location',
    'people_in_this_media',
    'photographer',
    'title',
    'file_name',
    'folder_name',
    'content_integration_path',
  ],
  bynder: [
    'caption',
    'name',
    'keywords',
    'property_Digital_Rights',
    'property_EventName',
    'property_Person',
    'property_PersonInImage',
    'property_Team',
  ],
  capture: ['caption', 'file_name', 'keywords', 'location'],
  dropbox: [
    'caption',
    'credit',
    'keywords',
    'location',
    'people_in_this_media',
    'photographer',
    'title',
    'file_name',
    'folder_name',
    'parent_folder_name',
    'content_integration_path',
  ],
  getty_search: [
    'caption',
    'credit',
    'keywords',
    'photographer',
    'people_in_this_media',
    'title',
  ],
  grabyo: ['caption', 'file_name', 'title'],
  imagn: ['caption', 'credit', 'file_name', 'keywords', 'title'],
  intel: ['caption', 'file_name', 'keywords', 'title'],
  libris: [
    'ai_metadata',
    'caption',
    'credit',
    'file_name',
    'parent_folder_name',
    'content_integration_path',
    'gallery_name',
    'keywords',
    'location',
    'photographer',
    'title',
  ],
  media_library: ['file_name', 'ai_keywords'],
  playing_surface: ['file_name', 'title', 'caption', 'keywords'],
  reach_engine: ['caption', 'file_name', 'keywords', 'title'],
  s3: [
    'caption',
    'credit',
    'file_name',
    'keywords',
    'location',
    'people_in_this_media',
    'photographer',
    'title',
  ],
  scoopa: ['file_name', 'folder_name'],
  shutterstock: ['caption', 'credit', 'keywords', 'title'],
  wsc: [
    'caption',
    'file_name',
    'keywords',
    'people_in_this_media',
    'rating',
    'title',
  ],
};

const contentIntegrationsWithLogs = [
  'dropbox',
  'getty_search',
  'bynder',
  'wsc',
  'libris',
  'scoopa',
];

export {
  platforms,
  requestStatuses,
  requestOpenedStatuses,
  requestSentStatuses,
  requestPublishedStatuses,
  requestErrorStatuses,
  timezone,
  dateFilterOptions,
  timeOptions,
  acceptFiles,
  chatPageSize,
  suggestionValidation,
  publicIntegrationTypes,
  godModeIntegrationTypes,
  lcwIntegrationTypes,
  contentIntegrationMetadataFields,
  contentIntegrationsWithLogs,
};
