import React from 'react';
import Button from 'components/Button';
import { flashMessage } from 'helpers';
import { useIntl } from 'hooks';
import { useMutation } from 'react-apollo';
import { SavedFilterSetsProps } from './SavedFilterSets';
import { UPDATE_SAVED_FILTER_SET } from './queries';

interface UpdateSavedFilterSetProps {
  id: string;
  filter: SavedFilterSetsProps['filter'];
}

export default function SavedFilterSetsUpdateButton(
  props: UpdateSavedFilterSetProps
) {
  const { id, filter } = props;
  const { t } = useIntl();
  const [updateSavedFilterSet, { loading }] = useMutation(
    UPDATE_SAVED_FILTER_SET,
    {
      variables: { id, values: { data: filter } },
      onCompleted: (data) => {
        if (data.updateSavedFilterSet.errors) {
          console.error(data.updateSavedFilterSet.errors);
          flashMessage('Global__UnexpectedError', { style: 'error' });
        } else {
          flashMessage('SavedFilterSets__UpdateSuccess');
        }
      },
      onError: (e) => {
        console.error(e);
        flashMessage('Global__UnexpectedError', { style: 'error' });
      },
    }
  );
  return (
    <Button
      filledBg
      onClick={updateSavedFilterSet}
      loading={loading}
      disabled={loading}
    >
      {t('SavedFilterSets__UpdateButton')}
    </Button>
  );
}
