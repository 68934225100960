import gql from 'graphql-tag';

export const FETCH_SAVED_FILTER_SETS = gql`
  query SavedFilterSets($filterContext: String!) {
    savedFilterSets(filterContext: $filterContext) {
      id
      name
      data
      filterContext
      deleted
    }
  }
`;

export const UPDATE_SAVED_FILTER_SET = gql`
  mutation UpdateSavedFilterSet($id: ID!, $values: JSON!) {
    updateSavedFilterSet(id: $id, values: $values) {
      errors
      savedFilterSet {
        id
        name
        data
        filterContext
        deleted
      }
    }
  }
`;

export const ADD_SAVED_FILTER_SET = gql`
  mutation AddSavedFilterSet(
    $name: String!
    $data: JSON!
    $filterContext: String!
  ) {
    addSavedFilterSet(name: $name, data: $data, filterContext: $filterContext) {
      errors
      savedFilterSet {
        id
        name
        data
        filterContext
        deleted
      }
    }
  }
`;

export const REMOVE_SAVED_FILTER_SET = gql`
  mutation RemoveSavedFilterSet($id: ID!) {
    removeSavedFilterSet(id: $id) {
      errors
      savedFilterSet {
        id
        name
        data
        filterContext
        deleted
      }
    }
  }
`;
