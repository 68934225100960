import includes from 'lodash/includes';
import apolloClient from '../apolloClient';
import Chat from 'types/Chat';
import TwilioMessage from 'types/TwilioMessage';
import RelayConnection from 'types/RelayConnection';
import { mapNodes } from 'helpers';
import * as GET_CHAT from 'graphql/queries/chat.graphql';
import * as GET_CHATS from 'graphql/queries/chats.graphql';
import * as GET_TWILIO_MESSAGES from 'graphql/queries/twilioMessages.graphql';
import * as LATEST_CHAT_MESSAGE from 'graphql/fragments/LatestChatMessage.graphql';
import { chatPageSize } from 'config';

interface QueryData {
  twilioMessages: RelayConnection<TwilioMessage>;
}

const appendMessageToChat = async (message: TwilioMessage) => {
  const { cache } = apolloClient;
  const id = message.publisherPhoneNumber;

  const chatData = cache.readQuery<{ chat?: Chat }>({
    query: GET_CHAT,
    variables: { id, pageSize: chatPageSize },
  });

  if (!chatData || !chatData.chat) {
    reloadChats();
  } else {
    appendMessageToExistingChat(message, chatData.chat);
  }
};

function appendMessageToExistingChat(message: TwilioMessage, chat: Chat) {
  writeLatestMessage(chat.id, message);

  const variables = { chatId: chat.id, pageSize: chatPageSize };

  const result = apolloClient.readQuery<QueryData>({
    variables,
    query: GET_TWILIO_MESSAGES,
  });

  if (!result) return;

  const messageIds = mapNodes(result.twilioMessages.edges, (m) => m.id);

  if (!includes(messageIds, message.id)) {
    apolloClient.writeQuery({
      variables,
      query: GET_TWILIO_MESSAGES,
      data: {
        twilioMessages: {
          ...result.twilioMessages,
          edges: [
            { node: message, __typename: 'TwilioMessageEdge' },
            ...result.twilioMessages.edges,
          ],
        },
      },
    });
  }
}

function writeLatestMessage(chatId: number | string, message: TwilioMessage) {
  apolloClient.writeFragment({
    data: {
      latestMessage: {
        ...message,
        __typename: 'TwilioMessage',
      },
      isUnread: true,
      __typename: 'Chat',
    },
    id: `Chat:${chatId}`,
    fragment: LATEST_CHAT_MESSAGE,
    fragmentName: 'LatestChatMessage',
  });
}

function reloadChats() {
  apolloClient.query({ query: GET_CHATS, fetchPolicy: 'network-only' });
}

export default appendMessageToChat;
