import get from 'lodash/get';
import set from 'lodash/fp/set';

const updateQuery = (key: string, prev: any, fetchMoreResult: any) => {
  if (!fetchMoreResult) return prev;

  const oldEdges = get(prev, `${key}.edges`);
  const newEdges = get(fetchMoreResult, `${key}.edges`);
  const pageInfo = get(fetchMoreResult, `${key}.pageInfo`);

  if (!newEdges.length) return prev;

  const retVal = set(
    key,
    {
      ...get(prev, key),
      pageInfo,
      edges: [...oldEdges, ...newEdges],
      __typename: get(prev, `${key}.__typename`),
    },
    { ...prev }
  );

  return retVal;
};

export default updateQuery;
