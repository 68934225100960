import React from 'react';
import { EnhancedNavLink } from 'components';
import { NavLinkProps } from 'react-router-dom';

interface Props {
  children: React.ReactNode;
}

export default function RoundSubNav({ children }: Props) {
  return (
    <div className="border-default flex items-center overflow-hidden rounded">
      {children}
    </div>
  );
}

RoundSubNav.Item = function ({ children, ...props }: NavLinkProps) {
  return (
    <EnhancedNavLink
      className="border-r-default px-1.5 py-0.5 text-13 last:border-r-0"
      inactiveClassName="text-grey3"
      activeClassName="bg-grey3 text-white"
      {...props}
    >
      {children}
    </EnhancedNavLink>
  );
};
