import React from 'react';
import styled from 'styled-components';
import { Sort, RecordCount } from '..';
import { FormattedMessage } from 'react-intl';
import { colors, grid } from 'styles/theme';

interface Props {
  name: string;
  totalCount?: number;
  loadedCount?: number;
  error?: any;
  loading?: boolean;
  filter: any;
  updateFilter: (val: any) => void;
  sortOptions?: string[];
  isFiltered?: boolean;
  clearFilter?: () => void;
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 ${grid(1.5)};

  &:last-child {
    margin-bottom: 0;
    border-bottom: 0;
  }
`;

const ClearFilterButton = styled.button.attrs({ type: 'button' })`
  text-transform: uppercase;
  line-height: ${grid(2.5)};
  color: ${colors.socialiePink};
  font-size: 10px;
  font-weight: 600;
`;

const InfoBar = (props: Props) => {
  const {
    name,
    totalCount,
    loadedCount,
    error,
    loading,
    filter,
    updateFilter,
    sortOptions,
    isFiltered,
    clearFilter,
  } = props;

  return (
    <Wrapper>
      <div>
        <RecordCount
          name={name}
          loadedCount={loadedCount}
          totalCount={totalCount}
          error={error}
          loading={loading}
        />

        {isFiltered && clearFilter && (
          <ClearFilterButton onClick={clearFilter}>
            <FormattedMessage id="Global__ClearFilter" />
          </ClearFilterButton>
        )}
      </div>

      {sortOptions && (
        <Sort
          filter={filter}
          updateFilter={updateFilter}
          name={name}
          options={sortOptions}
        />
      )}
    </Wrapper>
  );
};
export default InfoBar;
