import React from 'react';
import styled from 'styled-components';
import { grid, colors } from 'styles/theme';
import { media } from 'styles';
import { IconHamburger } from 'icons';

interface Props {
  isOpen: boolean;
  toggleOpen: () => void;
}

// prettier-ignore
const Wrapper = styled.button`
  display: flex;
  position: absolute;
  right: ${grid(1.5)};
  align-items: center;
  justify-content: center;
  transition: all 0.2s;
  padding: ${grid(0)};
  width: 18px;
  height: 18px;
  color: ${colors.lighterText};

  &:hover {
    color: ${colors.link};
  }

  svg {
    width: 100%;
    height: 100%;
  }

  ${media.tablet`
    display: none;
  `};
` as any;

const MainNavToggle = ({ isOpen, toggleOpen }: Props) => (
  <Wrapper isOpen={isOpen} onClick={toggleOpen}>
    <IconHamburger />
  </Wrapper>
);

export default MainNavToggle;
