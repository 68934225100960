import React from 'react';
import styled from 'styled-components';
import ClickOutside from 'react-onclickout';
import { Tooltip } from 'components';
import { Picker } from 'emoji-mart';
import { IconEmoji } from 'icons';
import { grid, colors } from 'styles/theme';
import { hasClass } from 'helpers';

interface Props {
  onSelect: (emoji: any) => void;
}

interface State {
  isOpen: boolean;
}

const Wrapper = styled.div`
  .emoji-mart-bar {
    display: none;
  }
`;

const Button = styled.button.attrs({ type: 'button' })`
  position: relative;
  top: ${grid(0.25)};
  width: ${grid(2.5)};
  height: ${grid(2.5)};
  color: ${colors.lightText};

  svg {
    display: block;
    width: 100%;
    height: 100%;
  }
`;

export default class EmojiPicker extends React.Component<Props, State> {
  state = { isOpen: false };

  clickOut = (e: any) => {
    if (this.state.isOpen && !hasClass(e.target, 'tippy-box')) {
      this.setState({ isOpen: false });
    }
  };

  onSelect = (emoji: any) => {
    this.setState({ isOpen: false });
    this.props.onSelect(emoji);
  };

  toggleOpen = () => this.setState((state) => ({ isOpen: !state.isOpen }));

  render() {
    return (
      <ClickOutside onClickOut={this.clickOut}>
        <Tooltip
          interactive
          visible={this.state.isOpen}
          content={
            <Wrapper>
              <Picker
                native
                onSelect={this.onSelect}
                custom={[]}
                darkMode={false}
              />
            </Wrapper>
          }
        >
          <Button tabIndex={-1} onClick={this.toggleOpen}>
            <IconEmoji />
          </Button>
        </Tooltip>
      </ClickOutside>
    );
  }
}
