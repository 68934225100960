import React, { useLayoutEffect, useRef } from 'react';
import { IconClose } from 'icons';
import { platformTitle, routes } from 'helpers';
import { useIntl } from 'hooks';
import { Link } from 'react-router-dom';
import FacebookPage from 'types/FacebookPage';
import OauthIdentity from 'types/OauthIdentity';
import { useAppDispatch, useAppSelector } from 'redux/store';
import { closeTopWarningBanner, openTopWarningBanner } from 'redux/ducks/ui';

interface Props {
  suggester: {
    oauthIdentities: OauthIdentity[];
    facebookPage?: FacebookPage;
  };
}

export default function InvalidOauthTokenNag({ suggester }: Props) {
  const { t } = useIntl();
  const didDismissRef = useRef(false);

  const { facebookPage, oauthIdentities } = suggester;

  const isOpen = useAppSelector((state) => state.ui.isTopWarningBannerOpen);
  const dispatch = useAppDispatch();

  const invalidIdentity = oauthIdentities.find(
    (identity) =>
      (identity.invalidPermissions || identity.invalidToken) &&
      (identity.provider === 'facebook' || identity.provider === 'twitter')
  );

  const isInvalidFacebookPage =
    !invalidIdentity && facebookPage && facebookPage.invalidToken;

  useLayoutEffect(() => {
    if ((invalidIdentity || isInvalidFacebookPage) && !didDismissRef.current) {
      dispatch(openTopWarningBanner());
    }
  }, [invalidIdentity, isInvalidFacebookPage, dispatch]);

  const handleDismiss = () => {
    didDismissRef.current = true;
    dispatch(closeTopWarningBanner());
  };

  if ((!invalidIdentity && !isInvalidFacebookPage) || !isOpen) return null;

  const invalidType = (invalidIdentity || facebookPage!).invalidToken
    ? 'token'
    : 'permissions';

  const platform = invalidIdentity
    ? platformTitle(invalidIdentity.provider)
    : 'Facebook';

  return (
    <div
      data-testid="invalid-oauth-token"
      className="space-between fixed left-0 right-0 top-0 flex h-8 items-center bg-socialiePink px-2 py-1 text-center text-16 text-white [.draft-builder_&]:hidden tablet:[.draft-builder_&]:flex"
      style={{ zIndex: 1001 }}
    >
      <div className="w-6" dangerouslySetInnerHTML={{ __html: '&nbsp;' }} />

      <div className="bump-up-1 flex-1">
        {t(`InvalidOauthTokenNag__${platform}--${invalidType}`)}{' '}
        <Link
          to={routes.settings.account}
          className="text-white underline"
          onClick={handleDismiss}
        >
          {t('InvalidOauthTokenNag__Settings')}
        </Link>
      </div>

      <button
        className="flex w-6 items-center justify-end opacity-50"
        onClick={handleDismiss}
      >
        <IconClose className="block h-2 w-2 text-white" />
      </button>
    </div>
  );
}
