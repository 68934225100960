import React, { useState } from 'react';
import { useIntl } from 'hooks';
import { Form, Button } from 'components';
import { FormattedMessage } from 'react-intl';
import { useMutation, useLazyQuery } from '@apollo/react-hooks';
import * as CURRENT_USER_QUERY from 'graphql/queries/currentUser.graphql';
import * as RECOVERY_CODE_LOGIN from 'graphql/mutations/recoveryCodeLogIn.graphql';
import User from 'types/User';
import { flashMessage } from 'helpers';
import SemiLoggedInUser from 'types/SemiLoggedInUser';
import socket from 'socket';

interface RecoveryCodeProps {
  onGoBack: () => void;
  semiLoggedInUser: SemiLoggedInUser;
}

interface MutationData {
  recoveryCodeLogIn: {
    errors: string;
    token: string;
    user: User;
  };
}

interface MutationVars {
  recoveryCode: string;
}

interface QueryData {
  currentUser: User;
  semiLoggedInUser: SemiLoggedInUser;
}

export default function RecoveryCode({
  onGoBack,
  semiLoggedInUser,
}: RecoveryCodeProps) {
  const { t } = useIntl();

  const [recoveryCode, setRecoveryCode] = useState('');
  const [isInvalidCode, setIsInvalidCode] = useState(false);

  const [loadCurrentUser, { loading: isRefetchingUser }] =
    useLazyQuery<QueryData>(CURRENT_USER_QUERY, {
      fetchPolicy: 'network-only',
    });

  const [recoveryCodeLogIn, { loading }] = useMutation<
    MutationData,
    MutationVars
  >(RECOVERY_CODE_LOGIN, {
    variables: {
      recoveryCode,
    },
    onCompleted: (data) => {
      if (!data.recoveryCodeLogIn.errors && data.recoveryCodeLogIn.user) {
        window.Socialie.tempUserToken = undefined;
        window.localStorage.setItem(
          'satellizer_token',
          data.recoveryCodeLogIn.token
        );
        socket.close();
        socket.connect();
        loadCurrentUser();
      } else {
        setIsInvalidCode(true);
      }
    },
    onError: () => {
      flashMessage('Global__UnexpectedError', { style: 'error' });
    },
  });

  const isSubmitting = loading || isRefetchingUser;

  return (
    <div>
      <div className="mb-4 border-0 border-b border-solid border-grey7">
        <h3 className="text-20 font-semibold text-dark">
          {t('Security__MfaLogin--Heading')}
        </h3>
      </div>
      <div className="mb-1">
        <FormattedMessage
          id="Security__MfaLogin--RecoveryCode-Desc"
          values={{
            secondaryText: (
              <span className="font-semibold text-dark">
                {t('Security__MfaLogin--RecoveryCode-Desc-Secondary')}
              </span>
            ),
          }}
        />
      </div>
      <div className="mb-4">
        <Form.TextInput
          name="recoveryCode"
          large
          placeholder={t('Security__MfaLogin--Verify-Placeholder')}
          value={recoveryCode}
          errors={
            isInvalidCode
              ? t('Security__MfaLogin--Verify-Validation-InvalidCode')
              : ''
          }
          onChange={(e) => setRecoveryCode(e.target.value)}
        />
      </div>
      <div className="mb-2 flex items-center justify-end">
        <Button
          filledBg
          large
          largeInline
          onClick={() => recoveryCodeLogIn()}
          disabled={isSubmitting || !recoveryCode.length}
        >
          {t('Security__MfaLogin--Verify-Button')}
        </Button>
      </div>
      <div className="mb-1">
        <p
          className="cursor-pointer text-socialiePink hover:underline"
          onClick={onGoBack}
        >
          {t('Security__MfaLogin--RecoveryCode-Back-Link')}
        </p>
      </div>
      {isInvalidCode && (
        <p className="cursor-pointer text-socialiePink hover:underline">
          {t('Security__MfaLogin--RecoveryCode-ContactSupport-Link')}
        </p>
      )}
    </div>
  );
}
