import React from 'react';
import { useIntl } from 'hooks';
import { Form, Button, Loader } from 'components';
import { Formik, Field, FieldProps } from 'formik';
import * as REGISTER_AUTHY_USER from 'graphql/mutations/registerAuthyUser.graphql';
import { useMutation } from '@apollo/react-hooks';
import { flashMessage } from 'helpers';
import { IconLoader } from 'icons';

interface RegisterAuthyUserProps {
  onSuccess: () => void;
}

const initialValues = {
  mfaPhone: '',
};

export default function RegisterAuthyUser(props: RegisterAuthyUserProps) {
  const { onSuccess } = props;
  const { t } = useIntl();

  const [registerAuthyUser, { loading }] = useMutation(REGISTER_AUTHY_USER, {
    onCompleted: (data) => {
      if (data.registerAuthyUser.errors) {
        flashMessage('Global__UnexpectedError', { style: 'error' });
        console.warn('Error: ', data.registerAuthyUser.errors);
      } else {
        onSuccess();
      }
    },
    onError: () => {
      flashMessage('Global__UnexpectedError', { style: 'error' });
    },
  });

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Formik
            initialValues={initialValues}
            onSubmit={(values) => {
              registerAuthyUser({
                variables: {
                  mfaPhone: values.mfaPhone,
                },
              });
            }}
            validateOnBlur={false}
            validateOnChange={false}
          >
            {({ handleSubmit, isSubmitting, errors }) => {
              return (
                <Form onSubmit={handleSubmit}>
                  <div className="text-left">
                    <div className="mb-2">
                      <p className="text-dark">
                        {t('Security__ManageMfa--RegisterAuthyUser-Desc')}
                      </p>
                    </div>
                    <div className="mb-2.5">
                      <Field name="mfaPhone">
                        {({ field, form }: FieldProps) => {
                          return (
                            <div className="w-30">
                              <Form.PhoneInput
                                {...field}
                                forceWorldwide
                                defaultCountryCodes={['+1']}
                                onChange={(val: string) => {
                                  form.setFieldValue('mfaPhone', val);
                                }}
                              />
                            </div>
                          );
                        }}
                      </Field>
                    </div>
                    <div className="flex items-center justify-end">
                      <Button
                        filledBg
                        inlineBlock
                        type="submit"
                        disabled={isSubmitting || !!errors.mfaPhone || loading}
                      >
                        <span className="flex items-center">
                          {loading && <IconLoader className="mr-1 h-2 w-2" />}
                          {t('Global__Next')}
                        </span>
                      </Button>
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </>
      )}
    </>
  );
}
