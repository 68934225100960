const platformInsights = {
  facebook: [
    'reach',
    'engagement',
    'fbComments',
    'fbReactions',
    'fbShares',
    'fbPostImpressions',
  ],
  twitter: [
    'reach',
    'engagement',
    'twLikes',
    'twRetweets',
    'twImpressions',
    'twReplies',
  ],
  instagram: [
    'reach',
    'engagement',
    'igEngagement',
    'igComments',
    'igLikes',
    'igImpressions',
    'igSaved',
    'igShares',
  ],
  instagram_stories: [
    'reach',
    'engagement',
    'igExits',
    'igImpressions',
    'igTapsForward',
    'igTapsBackward',
  ],
  linkedin: [],
};

export default platformInsights;
