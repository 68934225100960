import React from 'react';
import { useIntl } from 'hooks';
import { grid } from 'styles/theme';
import styled from 'styled-components';

interface PreviewButtonProps {
  readonly bgColor: string;
  readonly borderColor: string;
  readonly textColor: string;
}

const PreviewButton = styled.button<PreviewButtonProps>`
  margin-bottom: ${grid(1.5)};
  border: 2px solid
    ${(props) => (props.borderColor ? props.borderColor : '#5a636e')};
  border-radius: ${grid(0.5)};
  background-color: ${(props) => (props.bgColor ? props.bgColor : '#5a636e')};
  padding: ${grid(0.5)} ${grid(5)};
  color: ${(props) => (props.textColor ? props.textColor : '#5a636e')};
  pointer-events: none;
`;

const PreviewLink = styled.p`
  color: ${(props) => props.color};
`;

const PreviewLogo = styled.img`
  border-radius: 50%;
  width: ${grid(8)};
  height: ${grid(8)};
`;

interface Props {
  mainBrandColor: string;
  logo: string;
}

export default function BrandPreview({ mainBrandColor, logo }: Props) {
  const { t } = useIntl();
  return (
    <div className="ml-4.75 flex h-auto w-29 min-w-29 flex-col rounded bg-grey8 px-3 pt-2 pb-3">
      <p className="text-grey5">{t('Brand__Preview--Heading')}</p>
      <div className="mt-1 flex w-full flex-1 flex-col items-center rounded border-2 border-solid border-grey7 bg-white py-3">
        <div className="flex items-center justify-center pt-4.5 pb-7.5">
          {logo ? (
            <PreviewLogo src={logo} />
          ) : (
            <div className="h-8 w-8 min-w-8 rounded-full border-2 border-dashed border-grey6" />
          )}
        </div>
        <div className="flex justify-center">
          <PreviewButton
            bgColor={mainBrandColor}
            borderColor={mainBrandColor}
            textColor="white"
          >
            {t('Brand__Preview--Button')}
          </PreviewButton>
        </div>
        <div className="flex justify-center">
          <PreviewButton
            bgColor="white"
            borderColor={mainBrandColor}
            textColor={mainBrandColor}
          >
            {t('Brand__Preview--Button')}
          </PreviewButton>
        </div>
        <div className="flex justify-center">
          <PreviewLink color={mainBrandColor}>
            {t('Brand__Preview--Link')}
          </PreviewLink>
        </div>
      </div>
    </div>
  );
}
