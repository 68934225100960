import React, { useState } from 'react';
import TrackingIndexFilter from 'types/TrackingIndexFilter';
import DraftsIndexFilter from 'types/DraftsIndexFilter';
import DateFilterFields from 'types/DateFilterFields';
import { FormattedMessage } from 'react-intl';
import { Form, Button, Loader, TagFilter } from 'components';
import {
  ActionableTypeFilter,
  AlbumFilter,
  CampaignFilter,
  PublisherFilter,
  SenderFilter,
  MiscFilter,
  MediaTypeFilter,
  StatusFilter,
  SentDateFilter,
  PublishDateFilter,
  SuggestedPlatformFilter,
  PublishedPlatformFilter,
} from './components';
import { IconCaratDown } from 'icons';
import SuggestionsFilterData from 'types/SuggestionsFilterData';
import MediaSourceFilter from 'components/MediaSourceFilter';
import { mapNodes } from 'helpers';
import { usePublishersAndPublisherTagsById } from 'components/AsyncPublishersAndTagsSelect';

interface Props {
  type: 'batch' | 'suggestion';
  data?: SuggestionsFilterData;
  loading?: boolean;
  filter: TrackingIndexFilter | DraftsIndexFilter;
  updateFilter: (updates: any) => void;
}

export default function SuggestionFilters(props: Props) {
  const { data, loading, type, filter, updateFilter } = props;
  const { publishers, publisherTags } = usePublishersAndPublisherTagsById({
    publisherIds: filter.publishers,
    publisherTagIds: filter.publisherTags,
  });
  const [showAll, setShowAll] = useState(
    !!filter.textOnly ||
      !!filter.mediaType.length ||
      !!filter.suggestedPlatform.length ||
      (filter['publishedPlatform'] && !!filter['publishedPlatform'].length) ||
      !!filter['recentlyOnline'] ||
      !!filter['smartSuggestions'] ||
      !!filter['mediaSource'].length
  );

  const tags = data?.tags ? mapNodes(data.tags.edges) : [];

  const campaigns = (data && data.campaigns) || [];

  const suggestionAlbums =
    data && data.attachmentAlbums ? mapNodes(data.attachmentAlbums.edges) : [];

  const attachmentSources: string[] =
    (data &&
      data.currentSuggester &&
      data.currentSuggester.attachmentSources) ||
    [];

  return (
    <Form>
      {!data && loading ? (
        <Loader />
      ) : (
        <>
          <PublisherFilter
            publishers={publishers}
            publisherTags={publisherTags}
            filter={filter}
            updateFilter={updateFilter}
          />

          <hr />

          <SenderFilter
            currentUserOnly={filter.currentUserOnly}
            selectedSuggesterIds={filter.suggesters}
            onChange={(val: boolean) => updateFilter({ currentUserOnly: val })}
            onChangeSuggesters={(val: number[]) =>
              updateFilter({ suggesters: val })
            }
          />

          <hr />

          <ActionableTypeFilter
            actionableTypes={filter.actionableTypes}
            onChange={(val: string[]) => updateFilter({ actionableTypes: val })}
          />

          <hr />

          {type === 'suggestion' && (
            <>
              <StatusFilter
                selectedStatuses={(filter as TrackingIndexFilter).statuses}
                onChange={(statuses: string[]) => updateFilter({ statuses })}
              />
              <hr />
            </>
          )}

          <TagFilter
            tags={tags}
            selectedTagIds={filter.tags}
            onChange={(tags: number[]) => updateFilter({ tags })}
            requireAll={filter.requireAllTags}
            onChangeRequireAll={(value) =>
              updateFilter({ requireAllTags: value })
            }
          />

          <hr />
          <AlbumFilter
            albums={suggestionAlbums}
            selectedAlbumIds={filter.attachmentAlbums}
            onChange={(attachmentAlbums: number[]) =>
              updateFilter({ attachmentAlbums })
            }
            requireAll={filter.requireAllAlbums}
            onChangeRequireAll={(value) =>
              updateFilter({ requireAllAlbums: value })
            }
          />

          <hr />
          <CampaignFilter
            campaigns={campaigns}
            selectedCampaignIds={filter.campaigns}
            onChange={(campaigns: number[]) => updateFilter({ campaigns })}
          />

          <hr />
          {type === 'suggestion' && (
            <>
              <SentDateFilter
                onChange={(updates: Partial<DateFilterFields>) =>
                  updateFilter({
                    sentDate: {
                      ...(filter as TrackingIndexFilter).sentDate,
                      ...updates,
                    },
                  })
                }
                sentDate={(filter as TrackingIndexFilter).sentDate}
              />
              <hr />
            </>
          )}

          {type === 'suggestion' && (
            <>
              <PublishDateFilter
                onChange={(updates: Partial<DateFilterFields>) =>
                  updateFilter({
                    publishDate: {
                      ...(filter as TrackingIndexFilter).publishDate,
                      ...updates,
                    },
                  })
                }
                publishDate={(filter as TrackingIndexFilter).publishDate}
              />
              <hr />
            </>
          )}

          <Form.Field tight>
            <Button mono inlineBlock onClick={() => setShowAll(!showAll)}>
              <IconCaratDown
                style={{
                  transform: showAll ? 'translateY(1px)' : 'rotate(-90deg)',
                }}
              />
              <FormattedMessage
                id={
                  showAll ? 'TrackingFilters__Fewer' : 'TrackingFilters__More'
                }
              />
            </Button>
          </Form.Field>

          {showAll && (
            <>
              <MediaSourceFilter
                attachmentSources={attachmentSources}
                onChange={(val) => updateFilter({ mediaSource: val })}
                value={filter.mediaSource}
              />

              <hr />

              <MediaTypeFilter
                onChange={(val: string[]) => updateFilter({ mediaType: val })}
                onChangeTextOnly={(val: boolean) =>
                  updateFilter({ textOnly: val })
                }
                mediaType={filter.mediaType}
                textOnly={filter.textOnly}
              />

              <hr />

              <SuggestedPlatformFilter
                onChange={(val: string[]) =>
                  updateFilter({ suggestedPlatform: val })
                }
                suggestedPlatform={filter.suggestedPlatform}
              />

              {type === 'suggestion' && (
                <>
                  <hr />

                  <PublishedPlatformFilter
                    onChange={(val: string[]) =>
                      updateFilter({ publishedPlatform: val })
                    }
                    publishedPlatform={
                      (filter as TrackingIndexFilter).publishedPlatform
                    }
                  />
                  <hr />
                </>
              )}

              {type === 'suggestion' && (
                <MiscFilter
                  updateFilter={updateFilter}
                  filter={filter as TrackingIndexFilter}
                />
              )}
            </>
          )}
        </>
      )}
    </Form>
  );
}
