import { get } from './shared';

export const fetchOpenGraphData = async (
  url: string,
  suggestionId: string | null
) => {
  const formattedUrl = url.match(/:\/\//) ? url : `http://${url}`;
  const encodedUrl = encodeURIComponent(formattedUrl);
  const endpoint = `api/open_graph?url=${encodedUrl}${
    !!suggestionId ? `&suggestion_id=${suggestionId}` : ''
  }`;
  return get(endpoint);
};
