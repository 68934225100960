import React from 'react';
import { Mutation } from '@apollo/react-components';
import { FormattedMessage } from 'react-intl';
import gql from 'graphql-tag';
import Publisher from 'types/Publisher';
import IndexPublisher from 'types/IndexPublisher';
import { ConfirmModal } from 'components';

interface Props {
  publishers: (Publisher | IndexPublisher)[];
  children: (onClick: () => void) => React.ReactNode;
  onCompleted?: Function;
  heading?: string;
  bodyText?: string;
}

interface State {
  isConfirmModalOpen: boolean;
}

const MUTATION = gql`
  mutation RemovePublishers($ids: [ID!]!) {
    disablePublishers(ids: $ids) {
      errors
      publishers {
        deleted
        id
      }
    }
  }
`;

interface MutationData {
  disablePublishers: {
    errors?: string;
    publishers?: { id: string; deleted: boolean }[];
  };
}

export default class RemovePublisher extends React.Component<Props, State> {
  state = { isConfirmModalOpen: false };

  openConfirmModal = () => this.setState({ isConfirmModalOpen: true });

  closeConfirmModal = () => this.setState({ isConfirmModalOpen: false });

  onCompleted = (data: any) => {
    if (!data.disablePublishers.errors) {
      this.props.onCompleted?.();
      this.setState({ isConfirmModalOpen: false });
    }
  };

  render() {
    const ids = this.props.publishers.map((p) => p.id);

    return (
      <Mutation<MutationData>
        mutation={MUTATION}
        onCompleted={this.onCompleted}
        variables={{ ids }}
      >
        {(removePublisher, { loading }) => (
          <>
            {this.props.children(this.openConfirmModal)}

            <ConfirmModal
              isOpen={this.state.isConfirmModalOpen}
              onRequestClose={this.closeConfirmModal}
              onConfirm={removePublisher}
              isLoading={loading}
              heading={this.props.heading || 'Publisher__ConfirmRemoveHeading'}
            >
              {this.props.bodyText ? (
                this.props.bodyText
              ) : ids.length === 1 ? (
                <FormattedMessage id="Publisher__ConfirmRemove" />
              ) : (
                <FormattedMessage
                  id="Publisher__ConfirmMultiRemove"
                  values={{ count: ids.length }}
                />
              )}
            </ConfirmModal>
          </>
        )}
      </Mutation>
    );
  }
}
