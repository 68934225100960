import React from 'react';
import { Button } from 'components';
import { useIntl } from 'hooks';
import { useQuery } from '@apollo/react-hooks';
import * as MFA_RECOVERY_CODES_QUERY from 'graphql/queries/mfaRecoveryCodes.graphql';
import { IconLoader } from 'icons';
import { FormattedMessage } from 'react-intl';
import { Pills } from 'components';

interface RecoveryCodeProps {
  previousStep: () => void;
  nextStep: () => void;
  recoveryCodePassword: string;
  protocol: string;
  mfaPhone: string;
}

interface QueryData {
  mfaRecoveryCodes: string[];
}
interface QueryVars {
  password: string;
}

export default function RecoveryCode({
  recoveryCodePassword,
  protocol,
  mfaPhone,
  previousStep,
  nextStep,
}: RecoveryCodeProps) {
  const { t } = useIntl();

  const { data, loading } = useQuery<QueryData, QueryVars>(
    MFA_RECOVERY_CODES_QUERY,
    {
      variables: {
        password: recoveryCodePassword,
      },
    }
  );
  return (
    <div className="text-left">
      <div className="mb-3 text-dark">
        {protocol === 'sms' ? (
          <FormattedMessage
            id="Security__ManageMfa--RecoveryCode-ProtocolSms-Desc"
            values={{
              phone: (
                <span className="bump-down-1">
                  <Pills.Pill>{mfaPhone}</Pills.Pill>
                </span>
              ),
            }}
          />
        ) : (
          <p>{t('Security__ManageMfa--RecoveryCode-ProtocolApp-Desc')}</p>
        )}
      </div>
      <div className="mb-4 text-dark">
        <p>{t('Security__ManageMfa--RecoveryCode-SubDesc-One')}</p>
        <p className="font-bold">
          {t('Security__ManageMfa--RecoveryCode-SubDesc-Two')}
        </p>
      </div>
      <div className="mb-3 flex justify-center">
        <div className="flex flex-col items-center justify-center">
          {loading ? (
            <IconLoader className="h-2 w-2" />
          ) : (
            data?.mfaRecoveryCodes.map((code: string) => {
              return (
                <div
                  className="mb-1.5 flex w-15.5 justify-center rounded bg-grey8 py-0.5"
                  key={code}
                >
                  <p className="mb-0.5 text-20 font-semibold text-grey2">
                    {code}
                  </p>
                </div>
              );
            })
          )}
        </div>
      </div>
      <div className="mt-3 flex items-center justify-end">
        <div className="mr-1">
          <Button mono inlineBlock onClick={() => previousStep()}>
            {t('Global__Back')}
          </Button>
        </div>
        <Button filledBg inlineBlock onClick={() => nextStep()}>
          {t('Global__Next')}
        </Button>
      </div>
    </div>
  );
}
