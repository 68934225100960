import React from 'react';
import styled, { css } from 'styled-components';
import moment from 'moment-timezone';
import { grid, colors, font } from 'styles/theme';
import { IconArrowBack } from 'icons';
import { Context as IsMobileContext } from 'hooks/useIsMobile';

interface Props {
  value: Date;
  onChange: (time: string) => void;
}

interface State {
  hour: string;
  minute: string;
  ampm: string;
}

// prettier-ignore
const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: ${grid(1)};
  width: ${grid(17)};

  .colon {
    ${({ theme }: any) => theme.color === 'dark' && `
      color: ${colors.lightText};
    `}
  }

  > div {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    margin-right: ${grid(0.5)};
    color: ${colors.darkText};

    &:last-child {
      margin-right: 0;
    }
  }
`;

// prettier-ignore
const Toggle = styled.button.attrs({ type: 'button' })`
  width: ${grid(2)};
  height: ${grid(2)};

  ${({ theme }: any) => theme.color === 'dark' && `
    color: ${colors.white};
  `}

  &:disabled {
    color: ${colors.lightIcon};
  }

  &:first-child {
    transform: rotate(90deg);
  }

  &:last-child {
    transform: rotate(-90deg);
  }

  svg {
    display: block;
    width: 100%;
    height: 100%;
  }
`;

// prettier-ignore
const valueStyles = css`
  border: 1px solid ${colors.border};
  border-radius: 4px;
  cursor: default;
  padding: 0 ${grid(1)};
  width: ${grid(5)};
  height: ${grid(4.5)};
  text-align: center;
  line-height: ${grid(4.5)};
  color: ${colors.darkText};
  font-size: 14px;

  ${({ theme }: any) => theme.color === 'dark' && `
    border-color: ${colors.inverseBorder};
    background-color: ${colors.grey1};
    color: ${colors.white};
  `}
`;

// prettier-ignore
const Value = styled.input`
  ${valueStyles};
  appearance: none;
  font-family: ${font.sans};

  &:focus {
    outline: none;
    border-color: ${colors.focus};
  }

  ${(props: any) => props.type === 'time' && 'width: auto'};
`;

const Ampm = styled.div`
  ${valueStyles};
  cursor: pointer;
`;

const formatMinute = (val: number | string) => ('0' + val).slice(-2);

class TimeInputs extends React.Component<Props, State> {
  private timer?: number;

  state = {
    hour: moment(this.props.value).format('h'),
    minute: moment(this.props.value).format('mm'),
    ampm: moment(this.props.value).format('A'),
  };

  componentWillUnmount() {
    this.stopHolding();
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.value !== this.props.value) {
      this.setState({
        hour: moment(this.props.value).format('h'),
        minute: moment(this.props.value).format('mm'),
        ampm: moment(this.props.value).format('A'),
      });
    }
  }

  handleChange = (field: string, value: string) => {
    const { hour, minute, ampm } = this.state;
    const nextValues = { hour, minute, ampm };
    const hourInt = parseInt(hour, 10);
    const minuteInt = parseInt(minute, 10);
    const canIncrement = { hour: hourInt < 12, minute: minuteInt < 59 };
    const canDecrement = { hour: hourInt > 1, minute: minuteInt > 0 };

    const operations = {
      hour: {
        up: canIncrement.hour ? hourInt + 1 : 1,
        down: canDecrement.hour ? hourInt - 1 : 12,
      },
      minute: {
        up: formatMinute(canIncrement.minute ? minuteInt + 1 : 0),
        down: formatMinute(canDecrement.minute ? minuteInt - 1 : 59),
      },
      ampm: {
        up: ampm === 'AM' ? 'PM' : 'AM',
        down: ampm === 'AM' ? 'PM' : 'AM',
      },
    };

    if (value === 'up' || value === 'down') {
      nextValues[field] = `${operations[field][value]}`;
    } else {
      nextValues[field] = value;
    }

    this.setState({ ...this.sanitizeValues(nextValues) }, () => {
      this.props.onChange(
        `${this.state.hour}${this.state.minute}${this.state.ampm}`
      );
    });
  };

  sanitizeValues = (values: State) => {
    const { hour, minute, ampm } = values;
    const nextValues = { hour, minute, ampm };
    const hourInt = parseInt(hour, 10);
    if (hourInt > 12 || hourInt < 1 || hour.match(/\D+/)) {
      nextValues.hour = '1';
    }

    if (minute.length > 2) nextValues.minute = nextValues.minute.slice(-2);
    if (minute.length < 2) nextValues.minute = formatMinute(nextValues.minute);

    const minuteInt = parseInt(nextValues.minute, 10);
    if (minuteInt > 59 || minuteInt < 0 || nextValues.minute.match(/\D+/)) {
      nextValues.minute = '00';
    }

    return nextValues;
  };

  hold = (field: string, direction: 'up' | 'down') => {
    if (this.timer) window.clearInterval(this.timer);

    this.handleChange(field, direction);
    this.timer = window.setInterval(
      () => this.handleChange(field, direction),
      150
    );
  };

  stopHolding = () => this.timer && clearInterval(this.timer);

  renderToggle = (field: string, direction: 'up' | 'down') => (
    <Toggle
      data-testid={`${field}-${direction}`}
      onMouseDown={() => this.hold(field, direction)}
      onMouseOut={this.stopHolding}
      onMouseUp={this.stopHolding}
    >
      <IconArrowBack />
    </Toggle>
  );

  render() {
    const { hour, minute, ampm } = this.state;

    return (
      <IsMobileContext.Consumer>
        {({ isMobileUserAgent }) => (
          <Wrapper data-testid="time-inputs">
            {isMobileUserAgent ? (
              <Value
                type="time"
                value={moment(this.props.value).format('H:mm')}
                onChange={(e) => {
                  const value = moment(e.target.value, 'H:mm').format('hmmA');
                  this.props.onChange(value);
                }}
              />
            ) : (
              <>
                <div>
                  {this.renderToggle('hour', 'up')}
                  <Value
                    data-testid="hour-input"
                    value={hour}
                    onChange={(e) => this.handleChange('hour', e.target.value)}
                  />
                  {this.renderToggle('hour', 'down')}
                </div>

                <div className="colon">:</div>

                <div>
                  {this.renderToggle('minute', 'up')}
                  <Value
                    data-testid="minute-input"
                    value={minute}
                    onChange={(e) =>
                      this.handleChange('minute', e.target.value)
                    }
                  />
                  {this.renderToggle('minute', 'down')}
                </div>

                <div>
                  {this.renderToggle('ampm', 'up')}
                  <Ampm
                    data-testid="ampm-input"
                    onClick={() => this.handleChange('ampm', 'up')}
                  >
                    {ampm}
                  </Ampm>
                  {this.renderToggle('ampm', 'down')}
                </div>
              </>
            )}
          </Wrapper>
        )}
      </IsMobileContext.Consumer>
    );
  }
}

export default TimeInputs;
