import styled from 'styled-components';
import { colors, grid } from 'styles/theme';

// prettier-ignore
const EditorWrapper = styled.div`
  position: relative;
  top: -1px;
  flex: 1;
  margin-top: ${grid(0.25)};
  padding-right: ${grid(0.5)};
  line-height: ${grid(2.5)};
  word-break: break-all;
  color: ${colors.darkText};

  ${(props: any) => !props.theme.hasText && `
    color: ${colors.lightText};
  `};

  ${(props: any) => props.theme.color === 'dark' && `color: ${colors.white}`};

  .public-DraftEditorPlaceholder-root {
    width: 100%;
    color: ${colors.lightText};
  }

  .draftJsMentionPlugin__mentionSuggestionsEntryFocused__3LcTd {
    background-color: ${colors.greyBg};
  }

  .draftJsMentionPlugin__mentionSuggestionsEntry__3mSwm {
    color: ${colors.darkText};
    font-size: 16px;
  }

  .draftJsMentionPlugin__mention__29BEd {
    display: inline;
    background: none;
    padding-right: 0;
    padding-left: 0;
    color: ${colors.blue};
    font-weight: 600;

    &:hover {
      background: none;
      color: ${colors.blue};
    }
  }
`;

export default EditorWrapper;
