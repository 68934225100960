import React, { useRef, useState, useLayoutEffect, useCallback } from 'react';
import moment from 'moment-timezone';
import { Tooltip } from '..';
import DateFilterFields from 'types/DateFilterFields';
import { hasClass } from 'helpers';
import { Input, Menu } from './components';
import ClickOutside from 'react-onclickout';

interface Props extends DateFilterFields {
  onChange: (val: Partial<DateFilterFields>) => void;
  maxDate?: moment.Moment;
  minDate?: moment.Moment;
  options?: string[];
  useFilterType?: boolean; // Wrap changes in the Filter type and send a label
}

export default function DateRangeInput(props: Props) {
  const { onChange, dateRange, startDate, endDate, maxDate, minDate, options } =
    props;
  const filter = { dateRange, startDate, endDate };

  const [width, setWidth] = useState(0);
  const [isOpen, setIsOpen] = useState(false);

  // Set width with a callback ref
  const wrapperRef = useCallback((node) => {
    if (node !== null) setWidth(node.offsetWidth);
  }, []);

  const toggleOpen = () => setIsOpen(!isOpen);

  const handleClickOut = (e: any) => {
    if (
      isOpen &&
      !hasClass(e.target, 'ignore-react-onclickout') &&
      !hasClass(e.target, 'react-datepicker') &&
      !hasClass(e.target, 'react-datepicker__navigation')
    ) {
      setIsOpen(false);
    }
  };

  const divRef = useRef<HTMLDivElement | null>(null);

  const [tooltipPosition, setTooltipPosition] = useState<'top' | 'bottom'>(
    'bottom'
  );

  useLayoutEffect(() => {
    if (divRef.current) {
      const container = divRef.current.getBoundingClientRect();

      if (container.top > 362) {
        setTooltipPosition('top');
      } else {
        setTooltipPosition('bottom');
      }
    }
  }, [isOpen]);

  return (
    <ClickOutside onClickOut={handleClickOut}>
      <div ref={divRef}>
        <Tooltip
          content={
            <Menu
              width={width}
              filter={filter}
              maxDate={maxDate}
              minDate={minDate}
              onChange={onChange}
              options={options}
              onClose={() => setIsOpen(false)}
            />
          }
          placement={tooltipPosition}
          visible={isOpen}
          offset={[0, 4]}
          duration={[0, 0]}
          delay={[0, 0]}
          interactive
        >
          <div ref={wrapperRef}>
            <Input onClick={toggleOpen} isOpen={isOpen} filter={filter} />
          </div>
        </Tooltip>
      </div>
    </ClickOutside>
  );
}
