import values from 'lodash/values';
import { createSelector } from 'reselect';
import { flashMessage } from 'redux-flash';
import uuid from 'uuid4';
import fileProcessingStatus from 'helpers/upload/fileProcessingStatus';
import { RootState } from '.';
import Attachment from 'types/Attachment';
import StartUploadParams from 'types/StartUploadParams';
import FileUpload from 'types/FileUpload';
import addAttachment from 'graphql/operations/addAttachment';
import checkAttachmentStatus from 'graphql/operations/checkAttachmentStatus';
import { FileUploader, UploaderCallbacks } from 'helpers/upload/FileUploader';
import { updateUploadProgress, getUploadProgress } from './uploadProgress';

export enum TypeKeys {
  ADDED = 'fileUploads/ADDED',
  STARTED = ' fileUploads/STARTED',
  UPLOADED = 'fileUploads/UPLOADED',
  UPLOAD_ERROR = 'fileUploads/UPLOAD_ERROR',
  CREATING_THUMBNAILS = 'filesUploads/CREATING_THUMBNAILS',
  TRANSCODING_VIDEO = 'fileUploads/TRANSCODING_VIDEO',
  FILE_READY = 'fileUploads/FILE_READY',
  PROCESSING_ERROR = 'fileUploads/PROCESSING_ERROR',
  CANCELED = 'fileUploads/CANCELED',
  REMOVED = 'fileUploads/REMOVED',
  UPDATED = 'fileUploads/UPDATED',
}

interface AddedAction {
  type: TypeKeys.ADDED;
  payload: {
    uploadId: string;
    uploader: FileUploader;
    aspectRatio: number;
    orientation?: number;
    previewUrl?: string;
    suggestionId?: string;
    chatId?: string;
    isMediaLibraryUpload?: boolean;
    fileSize: number;
  };
}

interface StartedAction {
  type: TypeKeys.STARTED;
  payload: {
    uploadId: string;
  };
}

interface CompleteAction {
  type: TypeKeys.UPLOADED;
  payload: {
    uploadId: string;
    suggestionId: string;
  };
}

interface UpdatedAction {
  type: TypeKeys.UPDATED;
  payload: {
    uploadId: string;
    updates: Partial<FileUpload>;
  };
}

interface FileReadyAction {
  type: TypeKeys.FILE_READY;
  payload: {
    uploadId: string;
    attachment?: Attachment;
  };
}

interface ErrorAction {
  type: TypeKeys.UPLOAD_ERROR;
  payload: {
    uploadId: string;
    reason: string;
  };
}

interface FileProcessingAction {
  type:
    | TypeKeys.CREATING_THUMBNAILS
    | TypeKeys.TRANSCODING_VIDEO
    | TypeKeys.CANCELED;
  payload: {
    uploadId: string;
  };
}

interface ProcessingErrorAction {
  type: TypeKeys.PROCESSING_ERROR;
  payload: {
    uploadId: string;
    error: string;
  };
}

interface RemovedAction {
  type: TypeKeys.REMOVED;
  payload: {
    uploadId: string;
  };
}

type ActionTypes =
  | AddedAction
  | StartedAction
  | CompleteAction
  | FileReadyAction
  | ErrorAction
  | FileProcessingAction
  | ProcessingErrorAction
  | RemovedAction
  | UpdatedAction;

// Reducer
export interface State {
  [uploadId: string]: FileUpload;
}

const fileUploadsReducer = (state: State = {}, action: ActionTypes) => {
  if (!action.payload) {
    return state;
  }

  const { uploadId: id } = action.payload;

  switch (action.type) {
    case TypeKeys.ADDED: {
      const { uploader, uploadId: _, ...fileUpload } = action.payload;
      const { filename, contentType, status } = uploader;
      return {
        ...state,
        [id]: {
          filename,
          contentType,
          id,
          status,
          ...fileUpload,
        },
      };
    }

    case TypeKeys.STARTED: {
      return {
        ...state,
        [action.payload.uploadId]: {
          ...state[action.payload.uploadId],
          status: 'uploading',
        },
      };
    }

    case TypeKeys.CREATING_THUMBNAILS:
    case TypeKeys.TRANSCODING_VIDEO:
    case TypeKeys.CANCELED: {
      const status = fileProcessingStatus(action.type);
      return {
        ...state,
        [id]: { ...state[id], status },
      };
    }

    case TypeKeys.FILE_READY: {
      const status = fileProcessingStatus(action.type);
      const { attachment } = action.payload;
      const attachmentId = attachment && attachment.id;
      return {
        ...state,
        [id]: { ...state[id], status, attachmentId },
      };
    }

    case TypeKeys.PROCESSING_ERROR: {
      const { error } = action.payload;
      return {
        ...state,
        [id]: {
          ...state[id],
          status: 'error',
          statusMessage: error,
          attachmentStatus: 'processing_error',
        },
      };
    }

    case TypeKeys.REMOVED: {
      if (!state[id]) return state;
      const { [id]: _, ...rest } = state;
      return rest;
    }

    case TypeKeys.UPDATED: {
      if (!state[id]) return state;
      return { ...state, [id]: { ...state[id], ...action.payload.updates } };
    }

    default:
      return state;
  }
};

const startedUpload = (uploadId: string) => ({
  type: TypeKeys.STARTED,
  payload: { uploadId },
});

export const creatingThumbnails = (uploadId: string) => ({
  type: TypeKeys.CREATING_THUMBNAILS,
  payload: { uploadId },
});

export const transcodingVideo = (uploadId: string) => ({
  type: TypeKeys.TRANSCODING_VIDEO,
  payload: { uploadId },
});

export const fileReady = (uploadId: string, attachment: Attachment) => ({
  type: TypeKeys.FILE_READY,
  payload: { uploadId, attachment },
});

export const processingError = (uploadId: string, error: string) => ({
  type: TypeKeys.PROCESSING_ERROR,
  payload: { uploadId, error },
});

export const removeUpload = (uploadId: string) => ({
  type: TypeKeys.REMOVED,
  payload: { uploadId },
});

export const updateUpload = (
  uploadId: string,
  updates: Partial<FileUpload>
) => ({
  type: TypeKeys.UPDATED,
  payload: { uploadId, updates },
});

export const startUpload =
  (params: StartUploadParams) =>
  async (dispatch: Function, getState: () => RootState) => {
    const socketId = getState().socket.id as string;
    const uploadId = uuid();
    const {
      file,
      suggestionId,
      chatId,
      previewUrl,
      orientation,
      mmsMessage,
      isMediaLibraryUpload,
      skipAutomations,
    } = params;
    const callbacks: UploaderCallbacks = {
      onError: (reason) => {
        if (reason) console.error(`[fileUploads] ${reason}`);
        dispatch(flashMessage('FileUploads__Error', { style: 'error' }));
        dispatch(processingError(uploadId, 'uploadError'));
      },
      onComplete: async () => {
        const attachment = await addAttachment({
          suggestionId,
          uploader,
          socketId,
          uploadId,
          mmsMessage,
          isMediaLibraryUpload,
          skipAutomations,
        });
        dispatch(fileReady(uploadId, attachment));

        // In case we miss a socket message, poll for status updates
        if (attachment) {
          setTimeout(
            () =>
              checkAttachmentStatus(attachment.id, {
                onUpdate: (attachment) => {
                  dispatch(
                    updateUpload(uploadId, {
                      attachmentStatus: attachment.status,
                    })
                  );
                },
              }),
            10000
          );
        }
      },
      onProgress: (progress) => {
        const state = getState();
        const prevProgress = getUploadProgress(state, uploadId);

        if (progress > prevProgress + 3 || progress === 100) {
          dispatch(updateUploadProgress(uploadId, progress));
        }

        const upload = state.fileUploads[uploadId];
        if (upload && upload.status !== 'uploading') {
          dispatch(startedUpload(uploadId));
        }
      },
    };

    const uploader = new FileUploader(file, callbacks);

    dispatch({
      type: TypeKeys.ADDED,
      payload: {
        uploader,
        uploadId,
        previewUrl,
        orientation,
        fileSize: file.size,
        isMediaLibraryUpload,
        suggestionId,
        chatId,
      },
    });
  };

export const cancelUpload = (uploadId: string) => (dispatch: Function) => {
  dispatch({ type: TypeKeys.CANCELED, payload: { uploadId } });
};

// Selectors
export const getFileUploads = createSelector(
  (state: RootState) => state.fileUploads,
  (fileUploads) => Object.keys(fileUploads).map((id) => fileUploads[id])
);

export const getFileUploadsBySuggestionId = (
  state: RootState,
  suggestionId: number | string
) => values(state.fileUploads).filter((u) => u.suggestionId === suggestionId);

export const getFileUploadBySuggestionId = (
  state: RootState,
  suggestionId: number | string
) => getFileUploadsBySuggestionId(state, suggestionId)[0];

export const getActiveFileUploadsBySuggestionId = (
  state: RootState,
  suggestionId: number | string
) =>
  getFileUploadsBySuggestionId(state, suggestionId).filter(
    (u) => u.status !== 'done' && u.status !== 'canceled'
  );

export const getFileUploadsByChatId = (
  state: RootState,
  chatId: number | string
) =>
  values(state.fileUploads).filter(
    (u) => u.chatId === chatId && u.status !== 'canceled'
  );

export const getFileUploadByChatId = (
  state: RootState,
  chatId: number | string
) => getFileUploadsByChatId(state, chatId)[0];

export const getFileUploadsForMediaLibrary = (state: RootState) => {
  const uploads = getFileUploads(state);
  return uploads.filter((u) => u.isMediaLibraryUpload);
};

export default fileUploadsReducer;
