import { Form, QTooltip } from 'components';
import { useIntl } from 'hooks';
import { IconClock, IconClockAny } from 'icons';
import React, { useState } from 'react';
import Suggestion from 'types/Suggestion';

interface Props {
  suggestions: Suggestion[];
  onChange: (videoLength: number | null) => void;
}

export default function VideoLength(props: Props) {
  const { onChange, suggestions } = props;

  const { t } = useIntl();

  const lengths = [null, 15, 30, 60];

  const [videoLength, setVideoLength] = useState<number | null>(
    suggestions[0].videoLength
  );

  const handleChange = (length: number | null) => {
    setVideoLength(length);
    onChange(length);
  };

  return (
    <div className="mt-1 mb-3">
      <div className="mb-0.5 flex items-center space-x-1">
        <p className="text-14 font-semibold text-darkText">
          {t('VideoLength__Heading')}
        </p>
        <QTooltip content={t('VideoLength__Disclaimer')} />
      </div>
      <Form.ToggleGroup>
        {lengths.map((length) => (
          <Form.ToggleGroup.Option
            title={t(`VideoLength__${length}`)}
            icon={length === null ? IconClockAny : IconClock}
            onSelect={() => handleChange(length)}
            isSelected={videoLength === length}
          />
        ))}
      </Form.ToggleGroup>
    </div>
  );
}
