import moment from 'moment-timezone';

const formatDate = (date: string) => {
  return moment(date).format('MM/DD/YYYY');
};

export default function formatDateRange(startDate: string, endDate: string) {
  if (startDate === endDate) {
    return formatDate(startDate);
  }
  return `${formatDate(startDate)} - ${formatDate(endDate)}`;
}
