import FileProcessingEvent from 'types/FileProcessingEvent';
import photoAttachmentProcessed from 'graphql/operations/photoAttachmentProcessed';
import videoAttachmentProcessed from 'graphql/operations/videoAttachmentProcessed';
import attachmentProcessingFailed from 'graphql/operations/attachmentProcessingFailed';
import { processingError, updateUpload } from 'redux/ducks/fileUploads';
import store from 'redux/store';

const fileProcessingEvent = (event: FileProcessingEvent) => {
  const { message, attachment, uploadId } = event;
  const { id, aspectRatio, length } = attachment;
  switch (message) {
    case 'photoReady':
      photoAttachmentProcessed(id, aspectRatio);
      if (!uploadId) return;
      store.dispatch(updateUpload(uploadId, { attachmentStatus: 'processed' }));
      break;
    case 'videoReady':
      if (!length) return;
      videoAttachmentProcessed(attachment);
      if (!uploadId) return;
      store.dispatch(updateUpload(uploadId, { attachmentStatus: 'processed' }));
      break;
    case 'jobFailed':
      store.dispatch(processingError(event.uploadId, event.error));
      attachmentProcessingFailed(event.attachment.id);
      break;
  }
};

export default fileProcessingEvent;
