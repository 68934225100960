import React from 'react';
import Recipient from 'types/Recipient';
import { Pills } from 'components';
import clsx from 'clsx';

interface Props {
  recipient: Recipient;
  selectProps: any;
}

const PublisherTagPill = ({ recipient, selectProps }: Props) => {
  const { color } = selectProps.pillProps;
  const isDark = color === 'darkerGrey';
  const nameClassName = clsx('bump-down-1', { 'text-dark': !isDark });
  const numberClassName = isDark ? 'bg-grey2 text-white' : 'bg-grey7';

  return (
    <Pills.Pill
      {...selectProps.pillProps}
      onRemove={() => selectProps.onRemoveRecipient(recipient)}
    >
      <div className="flex items-center">
        <span className={nameClassName}>{recipient.publisherTag!.name}</span>
        {recipient.publisherTag!.publishers && (
          <div className="bump-down-1.5 ml-0.5 flex items-center p-0.5">
            <div
              className={`flex h-2 items-center justify-center rounded-lg px-1 text-12 leading-16 ${numberClassName}`}
            >
              <span className="bump-up-0.5">
                {recipient.publisherTag!.publishers.length}
              </span>
            </div>
          </div>
        )}
      </div>
    </Pills.Pill>
  );
};

export default PublisherTagPill;
