import Recipient from 'types/Recipient';

const createIntendedRecipientInput = (recipient: Recipient) => {
  if (recipient.contactMethod) {
    return {
      recipientType: 'ContactMethod',
      recipientId: recipient.contactMethod.id,
    };
  }

  if (recipient.publisherTag) {
    return {
      recipientType: 'PublisherTag',
      recipientId: recipient.publisherTag.id,
    };
  }

  if (recipient.publisherUser) {
    return {
      recipientType: 'PublisherUser',
      recipientId: recipient.publisherUser.id,
      protocol: recipient.protocol,
    };
  }

  if (recipient.publisher) {
    return {
      recipientType: 'Publisher',
      recipientId: recipient.publisher.id,
    };
  }

  return null;
};

export default createIntendedRecipientInput;
