import { useMutation } from '@apollo/react-hooks';
import { AsyncPublisherSelect, Button, Form, Modal } from 'components';
import * as ADD_MEDIA_APPEARANCES from 'graphql/mutations/addMediaAppearances.graphql';
import { flashMessage } from 'helpers';
import { useIntl } from 'hooks';
import React, { useState } from 'react';
import Attachment from 'types/Attachment';
import Face from 'types/Face';
import SelectOption from 'types/SelectOption';

interface Props {
  attachment: Attachment;
  face: Face;
}

export default function TagFace({ attachment, face }: Props) {
  const { t } = useIntl();
  const [selectedPublisher, setSelectedPublisher] =
    useState<SelectOption | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [addMediaAppearances, { loading: isSaving }] = useMutation<any>(
    ADD_MEDIA_APPEARANCES,
    {
      variables: {
        attachmentId: attachment.id,
        publisherIds: selectedPublisher?.value,
        faceId: face.id,
      },
      onError: () => {
        flashMessage('Global__UnexpectedError', { style: 'error' });
      },
      onCompleted: (data) => {
        if (data.addMediaAppearances && !data.addMediaAppearances.errors) {
          setIsModalOpen(false);
        } else {
          flashMessage('Global__UnexpectedError', { style: 'error' });
        }
      },
    }
  );

  if (!attachment.viewerCanEdit) return null;

  return (
    <>
      <button
        onClick={() => setIsModalOpen(true)}
        className="rounded-lg bg-socialieBlue px-1 text-white shadow"
      >
        {t('Faces__IdentifyFace')}
      </button>

      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        contentLabel={t('Faces__TagFaceHeading')}
        renderHeading={t('Faces__TagFaceHeading')}
      >
        <Form.Field>
          <AsyncPublisherSelect
            value={selectedPublisher}
            onChange={setSelectedPublisher}
          />
        </Form.Field>

        <Modal.Actions>
          <Button cancel inlineBlock onClick={() => setIsModalOpen(false)}>
            {t('Global__Cancel')}
          </Button>

          <Button
            filledBg
            inlineBlock
            onClick={addMediaAppearances}
            disabled={isSaving}
          >
            {t('Global__Save')}
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
}
