import React from 'react';
import includes from 'lodash/includes';
import without from 'lodash/without';
import { Form } from 'components';
import { FormattedMessage } from 'react-intl';
import { platforms } from 'config';

interface Props {
  onChange: (val: string[]) => void;
  suggestedPlatform: string[];
}

const SuggestedPlatformFilter = (props: Props) => {
  const { onChange, suggestedPlatform } = props;
  const onToggle = (val: string) => {
    if (includes(suggestedPlatform, val)) {
      onChange(without(suggestedPlatform, val));
    } else {
      onChange([...suggestedPlatform, val]);
    }
  };

  return (
    <Form.Field>
      <Form.Label bold>
        <FormattedMessage id="TrackingSuggestedPlatformFilter__Label" />
      </Form.Label>

      {platforms.map((platform) => (
        <Form.Option key={platform}>
          <Form.Checkbox
            checked={includes(suggestedPlatform, platform)}
            onChange={() => onToggle(platform)}
          />
          <span>
            <FormattedMessage id={`Platforms--${platform}`} />
          </span>
        </Form.Option>
      ))}
    </Form.Field>
  );
};

export default SuggestedPlatformFilter;
