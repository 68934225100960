import React, { useEffect, useState } from 'react';
import moment from 'moment-timezone';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import DatePicker from 'react-datepicker';
import { Form, Button } from '../..';
import DateFilterFields from 'types/DateFilterFields';
import * as config from 'config';
import { colors, grid } from 'styles/theme';
import { IconCalendar } from 'icons';
import { useIntl } from 'hooks';

interface Props {
  width: number;
  filter: DateFilterFields;
  maxDate?: moment.Moment;
  minDate?: moment.Moment;
  options?: string[];
  onChange: (val: Partial<DateFilterFields>) => void;
  onClose: () => void;
}

const Wrapper = styled.div`
  width: ${(props: any) =>
    props.wrapperWidth && `calc(${props.wrapperWidth}px - 18px)`};
  text-align: left;

  .react-datepicker-wrapper,
  .react-datepicker__input-container {
    display: block;
  }
` as any;

const Option = styled.button.attrs({ type: 'button' })`
  display: block;
  padding: ${grid(0.5)} ${grid(1)};
  width: 100%;
  color: ${colors.darkText};
  font-size: 14px;

  &:hover {
    background-color: ${colors.greyBg};
  }

  ${(props: any) => props.selected && 'font-weight: 600'};
` as any;

export default function Menu(props: Props) {
  const { filter, onChange, onClose, width, minDate, maxDate, options } = props;

  const { t } = useIntl();

  const [dates, setDates] = useState({
    startDate: filter.startDate,
    endDate: filter.endDate,
  });

  useEffect(() => {
    setDates({ startDate: filter.startDate, endDate: filter.endDate });
  }, [filter.startDate, filter.endDate]);

  const changeDate = (
    value: moment.Moment | null,
    which: 'startDate' | 'endDate'
  ) => {
    if (!value) return;
    const updates = { [which]: value.format('YYYY-MM-DD') };
    setDates({ ...dates, ...updates });

    // Apply the change immediately if we're already in custom date mode
    if (filter.dateRange === 'custom') onChange(updates);
  };

  const changeRange = (dateRange: string) => {
    onChange({ dateRange });
    onClose();
  };

  const applyCustomDates = () => {
    const { startDate, endDate } = dates;
    const dateRange = 'custom';
    onChange({ dateRange, startDate, endDate });
    onClose();
  };

  const renderInput = (innerLabel: string) => (
    <Form.TextInput
      icon={<IconCalendar />}
      iconPosition="right"
      innerLabel={innerLabel}
    />
  );

  const dateFilterOptions = options || config.dateFilterOptions;

  return (
    <Wrapper className="ignore-react-onclickout" wrapperWidth={width}>
      {dateFilterOptions.map((opt) => {
        if (opt === 'custom') return null;

        return (
          <Option
            key={opt}
            selected={opt === filter.dateRange}
            onClick={() => changeRange(opt)}
            className="ignore-react-onclickout"
          >
            <FormattedMessage id={`DateFilter--${opt}`} />
          </Option>
        );
      })}

      {dateFilterOptions.some((o) => o === 'custom') && (
        <div className="border-t-default mt-1 px-1 pb-1 pt-2 ">
          <Form.Field tight>
            <DatePicker
              customInput={renderInput(t('DateRangeInput__From'))}
              onChange={(value) => changeDate(value, 'startDate')}
              selected={moment(dates.startDate)}
              minDate={minDate}
              maxDate={moment(dates.endDate) || maxDate}
              fixedHeight
            />
          </Form.Field>

          <Form.Field tight>
            <DatePicker
              customInput={renderInput(t('DateRangeInput__To'))}
              onChange={(value) => changeDate(value, 'endDate')}
              selected={moment(dates.endDate)}
              minDate={moment(dates.startDate) || minDate}
              maxDate={maxDate}
              fixedHeight
            />
          </Form.Field>

          <Button filledBg onClick={applyCustomDates}>
            <FormattedMessage id="DateRangeInput__Apply" />
          </Button>
        </div>
      )}
    </Wrapper>
  );
}
