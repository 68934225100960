import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import IndexSuggestion from 'types/IndexSuggestion';
import Suggestion from 'types/Suggestion';
import RequestListTooltip from '../RequestListTooltip';
import { grid, colors } from 'styles/theme';

interface Props {
  suggestion: IndexSuggestion | Suggestion;
}

// prettier-ignore
const Wrapper = styled.div`
  position: relative;
  border-radius: 4px;
  padding: ${grid(0.25)} ${grid(1)};
  font-size: 13px;
  line-height: 20px;
  background-color: ${colors.teal};
  color: ${colors.white};
  overflow: hidden;

  ${(props: any) => props.percentFill === 0 && `
    background-color: ${colors.tealBg};
    color: ${colors.teal};
  `}

  &::after {
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.1);
    width: ${(props: any) => props.percentFill}%;
    height: 100%;
    content: '';
  }

  > span {
    position: relative;
    z-index: 2;
  }
` as any;

const SuggestionCompletion = ({ suggestion }: Props) => {
  const { requests } = suggestion;
  const completedRequests = requests.filter((r) => !!r.completedAt);
  const completedPercent = (completedRequests.length / requests.length) * 100;

  return (
    <RequestListTooltip requests={completedRequests}>
      <Wrapper percentFill={completedPercent}>
        <span>
          <strong>
            {completedRequests.length} <FormattedMessage id="Global__Of" />{' '}
            {requests.length}
          </strong>{' '}
          {suggestion.actionableType === 'ContentRequest' ? (
            <FormattedMessage id="RequestStatus--submitted" />
          ) : (
            <FormattedMessage id="RequestStatus--published" />
          )}
        </span>
      </Wrapper>
    </RequestListTooltip>
  );
};

export default SuggestionCompletion;
