import React from 'react';
import { IconClose } from 'icons';
import { Avatar } from 'components';
import { FilterPillsDisplayContext } from '../FilterPills';
import clsx from 'clsx';

interface Props {
  image?: string;
  label: string | React.ReactNode;
  onRemove: () => void;
}

export default function FilterPill({ label, image, onRemove }: Props) {
  const context = React.useContext(FilterPillsDisplayContext);

  return (
    <div
      className={clsx(
        'mb-0.5 flex items-center border border-solid border-border bg-white text-dark',
        context === 'default'
          ? 'mr-1 h-4 rounded px-1 pr-0.5'
          : 'mr-0.5 rounded-full px-1 py-0.5 text-12 font-bold'
      )}
    >
      {image && context === 'default' && (
        <div className="mr-0.5 h-2.5 w-2.5 rounded-full">
          <Avatar
            src={image}
            name={typeof label === 'string' ? label : undefined}
          />
        </div>
      )}
      <span className="bump-up-1 leading-16">{label}</span>
      {context === 'default' && (
        <button className="ml-1 h-2 w-2 p-0.5" onClick={onRemove}>
          <IconClose
            className="bump-down-1 block h-full w-full"
            role="button"
          />
        </button>
      )}
    </div>
  );
}
