import React from 'react';
import { FormattedMessage } from 'react-intl';
import Suggestion from 'types/Suggestion';
import { routeFor, routes } from 'helpers';
import styled from 'styled-components';
import { colors, grid } from 'styles/theme';
import { Link } from 'react-router-dom';

interface Props {
  suggestions: Suggestion[];
}

const Wrapper = styled.div`
  padding-right: ${grid(1)};
  line-height: ${grid(2)};

  a {
    text-decoration: underline;
    font-size: 12px;
  }
`;

const Heading = styled.div`
  color: ${colors.darkText};
  font-weight: bold;
`;

const Suggestions = ({ suggestions }: Props) => (
  <Wrapper>
    <Heading>
      <FormattedMessage
        id="ChatPanel__SuggestionsHeading"
        values={{ count: suggestions.length }}
      />
    </Heading>

    {suggestions.map((suggestion) => (
      <div key={suggestion.id}>
        <Link to={routeFor(routes.tracking.show, suggestion.id)}>
          <FormattedMessage
            id="ChatPanel__ViewSuggestion"
            values={{ id: suggestion.id }}
          />
        </Link>
      </div>
    ))}
  </Wrapper>
);

export default Suggestions;
