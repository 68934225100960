import React from 'react';
import styled from 'styled-components';
import { grid } from 'styles/theme';
import RecipientOption from 'types/RecipientOption';
import { PublisherTagPill, NewContactPill, PublisherPill } from './components';
import Recipient from 'types/Recipient';

const Wrapper = styled.div`
  display: inline-block;
  margin: ${grid(0.25)};
`;

const MultiValue = (props: any) => {
  const { data: option }: { data: RecipientOption } = props;
  const recipient: Recipient | Recipient[] = option.data;

  return (
    <Wrapper onMouseDown={(e: any) => e.stopPropagation()}>
      {Array.isArray(recipient) ? (
        <PublisherPill recipients={recipient} selectProps={props.selectProps} />
      ) : recipient.publisherTag ? (
        <PublisherTagPill
          recipient={recipient}
          selectProps={props.selectProps}
        />
      ) : (
        recipient.newContactValue && (
          <NewContactPill
            recipient={recipient}
            selectProps={props.selectProps}
          />
        )
      )}

      {recipient.publisherUser && (
        <div>
          {recipient.publisherUser.name} ({recipient.protocol})
        </div>
      )}
    </Wrapper>
  );
};

export default MultiValue;
