import { combineReducers } from 'redux';
import { reducer as flash } from 'redux-flash';
import FlashMessage from 'types/FlashMessage';
import global, { State as GlobalState } from './global';
import draftSuggestions, {
  State as DraftSuggestionsState,
} from './draftSuggestions';
import fileUploads, { State as FileUploadsState } from './fileUploads';
import uploadProgress, { State as UploadProgressState } from './uploadProgress';
import locales, { State as LocalesState } from './locales';
import socket, { State as SocketState } from './socket';
import ui, { State as UiState } from './ui';

export default combineReducers({
  global,
  draftSuggestions,
  fileUploads,
  flash,
  locales,
  socket,
  ui,
  uploadProgress,
} as any);

export interface RootState {
  global: GlobalState;
  draftSuggestions: DraftSuggestionsState;
  fileUploads: FileUploadsState;
  flash: FlashMessage[];
  locales: LocalesState;
  socket: SocketState;
  ui: UiState;
  uploadProgress: UploadProgressState;
}
