import React from 'react';
import gql from 'graphql-tag';
import get from 'lodash/get';
import Attachment from 'types/Attachment';
import FaceType from 'types/Face';
import { useGodMode } from 'hooks';
import { ScanForFaces, TagFace } from './components';
import * as FACE_FIELDS from 'graphql/fragments/FaceFields.graphql';
import { useQuery } from '@apollo/react-hooks';
import { IconLoader } from 'icons';

interface Props {
  attachment: Attachment;
}

const LOAD_FACES = gql`
  query LoadFaces($id: ID!) {
    attachment(id: $id) {
      id
      scannedForFaces
      faces: highQualityFaces {
        ...FaceFields
      }
    }
  }

  ${FACE_FIELDS}
`;

interface QueryData {
  attachment: { scannedForFaces: boolean; faces: FaceType[] };
}

export default function Faces({ attachment }: Props) {
  const isGodMode = useGodMode();

  const { data, loading } = useQuery<QueryData>(LOAD_FACES, {
    variables: { id: attachment.id },
    skip: !isGodMode,
  });

  if (!isGodMode) return null;

  const faces: FaceType[] = get(data, 'attachment.faces') || [];

  const scannedForFaces = get(data, 'attachment.scannedForFaces');

  return (
    <div className="absolute left-0 top-0 z-10 flex h-full w-full items-center justify-center">
      <div
        className="relative h-0 w-full"
        style={{ paddingBottom: `${attachment.aspectRatio * 100}%` }}
      >
        {faces.map((face) => (
          <Face key={face.id} attachment={attachment} face={face} />
        ))}

        {!loading && !scannedForFaces && attachment.viewerCanEdit && (
          <ScanForFaces id={attachment.id} />
        )}

        {loading && (
          <div className="absolute bottom-0 right-0 px-1 opacity-50">
            <IconLoader className="h-2 w-2" />
          </div>
        )}
      </div>
    </div>
  );
}

interface FaceProps {
  attachment: Attachment;
  face: FaceType;
}

function Face({ attachment, face }: FaceProps) {
  const { top, left, width, height, mediaAppearance } = face;
  return (
    <React.Fragment key={face.id}>
      {/* Box around the face */}
      <div
        className="absolute z-10 border shadow"
        style={{
          top: `${top * 100}%`,
          left: `${left * 100}%`,
          width: `${width * 100}%`,
          height: `${height * 100}%`,
        }}
      />
      {/* Label */}
      <div
        className="absolute z-10"
        style={{
          top: `${(top + height) * 100}%`,
          left: `${(left + width / 2) * 100}%`,
          transform: 'translateX(-50%) translateY(12px)',
        }}
      >
        {mediaAppearance ? (
          <div className="rounded bg-grey2 px-1 text-white">
            {mediaAppearance.publisher.name}
          </div>
        ) : (
          <TagFace attachment={attachment} face={face} />
        )}
      </div>
    </React.Fragment>
  );
}
