import React from 'react';
import styled from 'styled-components';
import { grid, colors } from 'styles/theme';

export const SliderWrapper = styled.span`
  input[type='range'] {
    background: transparent;
    width: 100%;
    -webkit-appearance: none;
  }

  input[type='range']:focus {
    outline: none;
  }

  input[type='range']::-ms-track {
    border-color: transparent;
    background: transparent;
    cursor: pointer;
    width: 100%;

    color: transparent;
  }

  input[type='range']::-webkit-slider-thumb {
    margin-top: -6px;
    border-radius: 50%;
    background: ${colors.socialiePink};
    cursor: pointer;
    width: ${grid(2)};
    height: ${grid(2)};
    -webkit-appearance: none;
  }

  input[type='range']::-webkit-slider-runnable-track {
    border-radius: 2px;
    background: ${colors.grey7};
    cursor: pointer;
    width: calc(100% - 2px);
    height: 4px;
  }

  input[type='range']:focus::-webkit-slider-runnable-track {
    background-color: rgba(252, 35, 97, 0.3);
  }
`;

interface SliderProps {
  value: number;
  onChange: (e: React.ChangeEvent) => void;
}

export default function Slider({ value, onChange }: SliderProps) {
  return (
    <SliderWrapper>
      <input
        type="range"
        min="0.5"
        max="0.5"
        step="0.1"
        value={value}
        onChange={onChange}
      />
    </SliderWrapper>
  );
}
