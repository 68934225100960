import React from 'react';

const newlineRegex = /(\r\n|\r|\n)/g;

const nl2br = function (str: string) {
  return str.split(newlineRegex).map((line, index) => {
    if (line.match(newlineRegex)) {
      return React.createElement('br', { key: index });
    }
    return line;
  });
};

export default nl2br;
