import React from 'react';
import styled from 'styled-components';
import includes from 'lodash/includes';
import { FormattedMessage } from 'react-intl';
import { styledStatuses } from 'helpers';
import { Pills, FormattedDateTime } from '..';
import PublisherRequest from 'types/PublisherRequest';
import { IconWarning } from 'icons';
import { grid } from 'styles/theme';
import Tooltip from '../Tooltip';
import { TippyProps } from '@tippyjs/react';

interface Props {
  request: PublisherRequest;
  hideDate?: boolean;
}

const IconWrap = styled.div`
  margin-right: ${grid(0.5)};

  svg {
    display: block;
    width: ${grid(2)};
    height: ${grid(2)};
  }
`;

const Status = styled.strong`
  margin-right: ${grid(0.5)};
  &:last-child {
    margin-right: 0;
  }
`;

const RequestStatus = ({ request, hideDate }: Props) => {
  const { status } = request;
  const styledStatus = styledStatuses[status];
  const timestampFields = {
    published: 'completedAt',
    submitted: 'lastCompletedAt',
    opened: 'openedAt',
    failed: 'updatedAt',
    send_failed: 'updatedAt',
    declined: 'updatedAt',
    canceled: 'updatedAt',
  };
  const isError = includes(['failed', 'send_failed'], status);
  const timestampField = timestampFields[status];
  const showDate = !hideDate && !!timestampField && !!request[timestampField];

  const renderStatus = () => (
    <Pills.Pill color={styledStatus.color}>
      {isError && (
        <IconWrap>
          <IconWarning />
        </IconWrap>
      )}

      <Status>
        <FormattedMessage id={`RequestStatus--${styledStatus.name}`} />
        {showDate && ': '}
      </Status>

      {showDate && (
        <span>
          {' '}
          <FormattedDateTime
            separator=", "
            month="short"
            value={request[timestampField]}
          />
        </span>
      )}
    </Pills.Pill>
  );

  const tooltipContent = getTooltipContent(request);

  return (
    <Tooltip
      theme="socialieDark"
      content={tooltipContent}
      disabled={!tooltipContent}
    >
      <span>{renderStatus()}</span>
    </Tooltip>
  );
};

export default RequestStatus;

function getTooltipContent(request: Props['request']): TippyProps['content'] {
  const { status, declineReason, completedAt, openedAt } = request;

  if (status === 'declined' && declineReason) return declineReason;

  if (status !== 'opened' && openedAt) {
    return (
      <div>
        <FormattedMessage id={`RequestStatus--opened`} />:{' '}
        <FormattedDateTime separator=", " month="short" value={openedAt} />
      </div>
    );
  }

  if (completedAt && !openedAt) {
    return <FormattedMessage id={`RequestStatus--pending`} />;
  }
}
