import React from 'react';
import { flashMessage } from 'helpers';
import { useIntl } from 'hooks';
import Suggestion from 'types/Suggestion';
import FlyoutMenu from './FlyoutMenu/FlyoutMenu';

interface Props {
  suggestion: Pick<Suggestion, 'id'>;
}

export default function SuggestionCopyURL({ suggestion }: Props) {
  const { id } = suggestion;
  const { t } = useIntl();
  const url = `${window.location.origin}/tracking/${id}`;

  const handleCopyUrl = async () => {
    try {
      await navigator.clipboard.writeText(url);
      flashMessage('SuggestionCopyURL__Copied');
    } catch (e) {
      flashMessage('SuggestionCopyURL__Error', { style: 'error' });
    }
  };

  return (
    <FlyoutMenu.Item onClick={handleCopyUrl}>
      {t('SuggestionCopyURL__Button')}
    </FlyoutMenu.Item>
  );
}
