import numbro from 'numbro';

type Value = number | string | undefined;

export const formatAverage = (val: Value, options: any = {}) =>
  numbro(val)
    .format({ average: true, mantissa: 1, optionalMantissa: true, ...options })
    .toUpperCase();

export const formatBigInt = (val: Value, options: any = {}) =>
  numbro(val).format({ thousandSeparated: true, ...options });

export const formatPercent = (val: Value, options: any = {}) =>
  numbro(val).format({ mantissa: 1, optionalMantissa: true, ...options }) + '%';

export const formatLength = (time: number) => {
  // Hours, minutes and seconds
  const seconds = time / 1000;
  const hrs = ~~(seconds / 3600);
  const mins = ~~((seconds % 3600) / 60);
  const secs = ~~(seconds % 60);

  // Output like "1:01" or "4:03:59" or "123:03:59"
  let ret = '';

  if (hrs > 0) {
    ret += '' + hrs + ':' + (mins < 10 ? '0' : '');
  }

  ret += '' + mins + ':' + (secs < 10 ? '0' : '');
  ret += '' + secs;
  return ret;
};

export const formatFileSize = (val: Value) =>
  numbro(val)
    .format({
      output: 'byte',
      base: 'binary',
      mantissa: 1,
    })
    .replace(/iB$/, 'B');
