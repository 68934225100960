import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  Modal,
  SuggestionForm,
  Button,
  Form,
  PlatformToggles,
} from 'components';
import updateSuggestion from 'graphql/operations/updateSuggestion';
import Suggestion from 'types/Suggestion';
import { flashMessage, getSuggestionAttachments } from 'helpers';
import SuggestionParams from 'types/SuggestionParams';
import { castArray } from 'lodash/fp';
import * as config from 'config';
import { useIntl } from 'hooks';
import moment from 'moment';
import HelperStatus from 'components/HelperStatus';

interface Props {
  suggestion: Suggestion;
  children: (openModal: (fields: string[]) => void) => void;
}

export default function SuggestionEditModal(props: Props) {
  const { suggestion } = props;

  const { t } = useIntl();

  const [fields, setFields] = useState<string[]>([]);

  const [didUpdateCaptions, setDidUpdateCaptions] = useState(false);

  const openEditModal = (fields: string[]) => setFields(castArray(fields));

  const closeEditModal = () => {
    setFields([]);
    setDidUpdateCaptions(false);
  };

  const closeEditModalWithMessage = () => {
    if (didUpdateCaptions) {
      flashMessage('SuggestionEditModal__UpdatedCaptions');
    }
    closeEditModal();
  };

  const [suggestionParams, setSuggestionParams] = useState<
    Partial<SuggestionParams>
  >({});

  const attachments = getSuggestionAttachments(suggestion);

  const platforms =
    suggestion.actionableType !== 'Suggestion' || attachments.length
      ? ['facebook', 'instagram', 'twitter']
      : ['facebook', 'linkedin', 'twitter'];

  const handleChange = (updates: Partial<SuggestionParams>) => {
    setSuggestionParams(updates);
  };

  const handleUpdateCaptions = () => {
    if (!didUpdateCaptions) setDidUpdateCaptions(true);
  };

  const handleSave = async (
    id: string | number,
    updates: Partial<SuggestionParams>
  ) => {
    const data = await updateSuggestion(id, suggestionParams);
    if (data && data.updateSuggestion.errors) {
      flashMessage(t('Global__UnexpectedError'), { style: 'error' });
    } else {
      if (updates && !!updates.expiresAt) {
        flashMessage(
          t('SuggestionEditModal__expiresAt', {
            days: moment(updates.expiresAt).diff(moment.now(), 'days'),
          })
        );
      } else {
        flashMessage(t('SuggestionEditModal__Updated'));
        closeEditModal();
      }
    }
  };

  return (
    <>
      {props.children(openEditModal)}
      <Modal
        theme={{ medium: true }}
        isOpen={!!fields.length}
        onRequestClose={closeEditModal}
        contentLabel=""
        renderHeading={
          <FormattedMessage id="TrackingSidebar__EditModalHeading" />
        }
      >
        {((fields.includes('platforms') && fields.includes('captions')) ||
          fields.includes('scheduling')) && (
          <HelperStatus helperStatus="warning">
            {t('TrackingSidebar__UpdateWarningMessage')}
          </HelperStatus>
        )}
        <div>
          {fields.includes('platforms') && (
            <Form.Field>
              <Form.Label bold>
                <FormattedMessage id="SuggestionForm__Platforms" />
              </Form.Label>

              <PlatformToggles
                suggestions={[suggestion]}
                platforms={platforms || config.platforms}
                onChange={handleUpdateCaptions}
              />
            </Form.Field>
          )}
          {fields.includes('captions') && (
            <SuggestionForm.Captions
              suggestions={[suggestion]}
              platforms={platforms || config.platforms}
              onChange={handleUpdateCaptions}
            />
          )}
          {fields.includes('expiresAt') && (
            <SuggestionForm.Expiration
              isEditing
              suggestions={[suggestion]}
              onChange={(expiresAt) => handleChange({ expiresAt })}
            />
          )}

          {fields.includes('subject') && (
            <SuggestionForm.Subject
              suggestions={[suggestion]}
              onChange={(subject) => handleChange({ subject })}
            />
          )}
          {fields.includes('instructions') && (
            <SuggestionForm.Instructions
              suggestions={[suggestion]}
              onChange={(instructions) => handleChange({ instructions })}
            />
          )}
          {fields.includes('mediaType') && (
            <SuggestionForm.MediaType
              suggestions={[suggestion]}
              onChange={(mediaType) => handleChange({ mediaType })}
            />
          )}

          {fields.includes('scheduling') && (
            <SuggestionForm.Scheduling
              suggestions={[suggestion]}
              onChange={(postAt) => handleChange({ postAt })}
              isSwitch
              isFullWidth
            />
          )}
          {fields.includes('talkingPoints') && (
            <SuggestionForm.TalkingPoints
              suggestions={[suggestion]}
              onChange={(talkingPoints) =>
                handleChange({
                  talkingPoints: talkingPoints.map((t) => ({ text: t })),
                })
              }
            />
          )}

          {fields.includes('exampleContent') && (
            <SuggestionForm.ExampleContent
              suggestions={[suggestion]}
              onChange={(exampleContent) => handleChange({ exampleContent })}
              onChangeTitle={(exampleContentTitle) =>
                handleChange({ exampleContentTitle })
              }
              onChangeDescription={(exampleContentDescription) =>
                handleChange({ exampleContentDescription })
              }
            />
          )}
        </div>
        <Modal.Actions>
          {fields.includes('captions') && fields.includes('platforms') ? (
            <Button inlineBlock filledBg onClick={closeEditModalWithMessage}>
              <FormattedMessage id="Done" />
            </Button>
          ) : (
            <>
              <div className="mr-2">
                <Button inlineBlock mono onClick={() => closeEditModal()}>
                  <FormattedMessage id="Global__Cancel" />
                </Button>
              </div>
              <Button
                inlineBlock
                filledBg
                onClick={() => handleSave(suggestion.id, suggestionParams)}
              >
                <FormattedMessage id="Global__Save" />
              </Button>
            </>
          )}
        </Modal.Actions>
      </Modal>
    </>
  );
}
