import React, { useContext, createContext } from 'react';
import useDebouncedWindowDimensions from './useDebouncedWindowDimensions';
import { breakpoints } from 'styles/media';

interface IsMobileContext {
  isMobile: boolean;
  isMobileUserAgent: boolean;
  windowWidth: number;
}

export const Context = createContext<IsMobileContext>({
  isMobileUserAgent: false,
  windowWidth: window.innerWidth,
  isMobile: window.innerWidth < breakpoints.tablet,
});

export function IsMobileProvider({ children }: { children: React.ReactNode }) {
  const isMobileUserAgent = /iPhone|iPad|iPod|Android/i.test(
    navigator.userAgent
  );
  const { width: windowWidth } = useDebouncedWindowDimensions();
  const isMobile = windowWidth < breakpoints.tablet;
  return (
    <Context.Provider value={{ isMobile, isMobileUserAgent, windowWidth }}>
      {children}
    </Context.Provider>
  );
}

export default function useIsMobile() {
  const { isMobile, isMobileUserAgent, windowWidth } = useContext(Context);
  return { isMobile, isMobileUserAgent, windowWidth };
}
