import { BlurUp, MetadataQuickLook, PillBadge, VideoLength } from 'components';
import { routeFor, routes } from 'helpers';
import moment from 'moment';
import React, { useContext } from 'react';
import { Link, useParams } from 'react-router-dom';
import { SelectableContext } from 'screens/MediaLibrary/screens/MediaLibraryIndex';
import styled, { ThemeProvider } from 'styled-components';
import { grid, colors } from 'styles/theme';
import AttachmentType from 'types/Attachment';
import { Checkbox, Expand } from 'components';
import { useIntl } from 'hooks';
import { IconSent } from 'icons';

interface Props {
  attachment: AttachmentType;
  isAttachmentAlbumMedia?: boolean;
}

const zIndices = {
  imageBox: 1,
  gradientContainer: 2,
  textContainer: 3,
  detailsContainer: 4,
  selectableBox: 5,
  linkBox: 6,
};

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border: 2px solid ${colors.greyBg};

  ${({ theme }: any) =>
    theme.isSelected
      ? `
      border-color: ${colors.socialiePink};
      border-radius: 2px;
  `
      : `.AttachmentList--compact & {
    border: 0;
  }`}

  .img-box,
  .img-box-inset-shadow,
  .border-box,
  .link-box,
  .selectable-box {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .img-box {
    .AttachmentList--compact & {
      position: static;
      background: ${colors.white};
      padding: ${grid(1.5)};
    }
  }

  .img-box-inset-shadow {
    display: none;
    z-index: ${zIndices['imageBox']};
    box-shadow: inset 0 0 0 1px ${colors.white};
    ${({ theme }: any) => theme.isSelected && 'display: block'};
  }

  .selectable-box {
    z-index: ${zIndices['selectableBox']};
  }

  .link-box {
    z-index: ${zIndices['selectableBox']};

    ${({ theme }: any) =>
      theme.isSelectMode &&
      `
    display: none;
  `}
  }

  .details-container {
    z-index: ${zIndices['detailsContainer']};
  }
`;

export const TextContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  transition: opacity 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
  opacity: 0;
  z-index: ${zIndices['textContainer']};
  padding: ${grid(1)};
  padding-right: ${grid(6)};
  width: 100%;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  pointer-events: none;

  .AttachmentThumbnail:hover & {
    opacity: 1;
  }

  h4 {
    margin-bottom: 0;
    width: inherit;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.1rem;
    color: rgba(255, 255, 255, 0.75);
    white-space: nowrap;
    font-size: 14px;
  }

  p {
    line-height: 1rem;
    color: rgba(255, 255, 255, 0.75);
    font-size: 0.75rem;
  }
`;

export const GradientContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: opacity 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
  opacity: 0;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.2) 0%,
    rgba(0, 0, 0, 0.2) 72%,
    rgba(0, 0, 0, 0.5) 100%
  );
  width: 100%;
  height: 100%;
  z-index: ${zIndices['gradientContainer']};
  content: '';

  .AttachmentThumbnail:hover & {
    opacity: 1;
  }
`;

export default function Attachment({
  attachment,
  isAttachmentAlbumMedia = false,
}: Props) {
  const { id, mediaAppearances } = attachment;
  const { t } = useIntl();
  const selectable = useContext(SelectableContext);
  const isSelected = selectable.isSelected(id);
  const isSelectMode = !!selectable.selectedIds.length;
  const theme = { isSelected, isSelectMode };
  const { id: albumId } = useParams();

  const route = isAttachmentAlbumMedia
    ? routeFor(routes.mediaLibrary.albumMedia.showMedia, albumId, attachment.id)
    : `${routeFor(routes.mediaLibrary.show, attachment.id)}${
        window.location.search
      }`;

  const handleSelect = (options: { usingRange: boolean }) => {
    if (options.usingRange) {
      selectable.selectRange(id);
    } else {
      selectable.toggleSelected(id);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <>
        <Wrapper>
          <div className="img-box">
            <BlurUp
              srcSet={attachment.thumbnail}
              preview={attachment.blurThumbnail}
              contain
            />
          </div>
          <GradientContainer />

          <TextContainer>
            <div className="mb-0.5">
              <h4>
                {mediaAppearances.map((appearance, i) => {
                  const isLast = i + 1 === mediaAppearances.length;
                  return (
                    <span key={appearance.id}>
                      {appearance.publisher.name}
                      {!isLast && ', '}
                    </span>
                  );
                })}
              </h4>
            </div>
            <p>{attachment.title}</p>
            <p>{moment(attachment.createdAt).format('MMMM Do YYYY')}</p>
          </TextContainer>

          <div className="img-box-inset-shadow" />

          <div
            className="selectable-box"
            onClick={(e) => {
              if (!isSelectMode) return;
              handleSelect({ usingRange: e.shiftKey });
            }}
          />

          <Link className="link-box" to={route} />

          <span
            className="absolute bottom-0 right-0 mb-1 mr-1 h-2 w-2 cursor-pointer opacity-0 group-hover:opacity-100"
            style={{ zIndex: zIndices['linkBox'] + 1 }}
          >
            <span className="block h-full w-full opacity-75 hover:opacity-100">
              <MetadataQuickLook
                attachment={attachment}
                placement="top"
                popperOptions={{
                  modifiers: [
                    {
                      name: 'flip',
                      options: {
                        fallbackPlacements: ['top', 'bottom'],
                      },
                    },
                  ],
                }}
              />
            </span>
          </span>

          <Checkbox onClick={(e) => handleSelect({ usingRange: e.shiftKey })} />

          <Expand to={route} />

          <div className="details-container absolute right-0 top-0 flex flex-col items-end space-y-0.5 p-1 text-right">
            {(attachment.hasSuggestions || attachment.hasDraftSuggestions) && (
              <PillBadge
                color="white"
                size="md"
                className="bg-opacity-80"
                icon={attachment.hasSuggestions ? IconSent : undefined}
              >
                {t(
                  attachment.hasSuggestions
                    ? 'MediaLibrary__Suggested'
                    : 'MediaLibrary__Draft'
                )}
              </PillBadge>
            )}
            {attachment.type === 'Video' && (
              <VideoLength length={attachment.length} />
            )}
          </div>
        </Wrapper>
      </>
    </ThemeProvider>
  );
}
