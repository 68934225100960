import React from 'react';
import moment from 'moment-timezone';
import { DateRangeInput, Form } from 'components';
import DateFilterFields from 'types/DateFilterFields';
import { FormattedMessage } from 'react-intl';

interface Props {
  onChange: (updates: Partial<DateFilterFields>) => void;
  publishDate: DateFilterFields;
}

const PublishDateFilter = ({ onChange, publishDate }: Props) => (
  <Form.Field>
    <Form.Label bold>
      <FormattedMessage id="TrackingPublishDateFilter__Label" />
    </Form.Label>

    <DateRangeInput onChange={onChange} {...publishDate} maxDate={moment()} />
  </Form.Field>
);

export default PublishDateFilter;
