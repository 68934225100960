import React, { useEffect, useRef, useState } from 'react';
import { useIntl } from 'hooks';
import { Button } from 'components';
import { IconAddToAttachmentAlbum } from 'icons';
import AddToAttachmentAlbumModal from 'components/AddToAttachmentAlbumModal';

interface Props {
  ids: (string | number)[];
  attachmentImages: { id: number; src: string; description: string }[];
  renderButton?: (openModal: () => void) => React.ReactNode;
}

export default function AddToAttachmentAlbum(props: Props) {
  const { ids, attachmentImages, renderButton } = props;
  const { t } = useIntl();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const isMounted = useRef(true);

  useEffect(() => {
    isMounted.current = true;

    return () => {
      isMounted.current = false;
    };
  }, []);

  const closeModal = () => {
    if (isMounted.current) {
      setIsModalOpen(false);
    }
  };

  return (
    <>
      <div>
        {renderButton ? (
          <>{renderButton(() => setIsModalOpen(true))}</>
        ) : (
          <Button onClick={() => setIsModalOpen(true)} filledBg inlineBlock>
            <IconAddToAttachmentAlbum /> {t('AddToAlbum__Button')}
          </Button>
        )}
      </div>
      <AddToAttachmentAlbumModal
        isOpen={isModalOpen}
        attachmentIds={ids}
        attachmentImages={attachmentImages}
        onRequestClose={closeModal}
      />
    </>
  );
}
