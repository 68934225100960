import React from 'react';
import * as config from 'config';
import { colors } from 'styles/theme';
import { IconFacebook, platformIcons } from 'icons';

interface Props {
  platforms?: string[];
  value: string[];
  onChange: (newValue: string[]) => void;
  theme?: 'large';
}

export default function PlatformFilter(props: Props) {
  const {
    value,
    onChange,
    theme,
    platforms = config.platforms.filter((p) => p !== 'linkedin'),
  } = props;

  const togglePlatform = (platform: string) => {
    if (value.some((p) => p === platform)) {
      onChange(value.filter((v) => v !== platform));
    } else {
      onChange([...value, platform]);
    }
  };

  const sizeClassNames = theme === 'large' ? 'w-4.75 h-4.75' : 'w-4 h-4';
  const fbSizeClassNames = theme === 'large' ? 'w-2.75 h-2.75' : 'w-2 h-2';

  return (
    <div className="flex items-center">
      {platforms.map((platform) => {
        const isActive = value.some((p) => p === platform);

        return (
          <button
            data-qa={`${platform}-button`}
            key={platform}
            className={`border-default mr-0.5 rounded ${sizeClassNames} last:mr-0`}
            onClick={() => togglePlatform(platform)}
            style={{
              background: isActive ? colors[platform] : colors.white,
              borderColor: isActive ? colors[platform] : colors.border,
            }}
          >
            <span className="flex items-center justify-center p-1">
              {platform === 'facebook' ? (
                <IconFacebook
                  className={`bump-up-1 block ${fbSizeClassNames}`}
                  style={{ color: isActive ? colors.white : colors.lightText }}
                />
              ) : (
                React.createElement(platformIcons[platform], {
                  className: 'w-full h-full',
                  style: { color: isActive ? colors.white : colors.lightText },
                })
              )}
            </span>
          </button>
        );
      })}
    </div>
  );
}
