import React, { useState, useEffect } from 'react';
import Suggestion from 'types/Suggestion';
import flatMap from 'lodash/flatMap';
import { useIntl } from 'hooks';
import { Form } from 'components';
import QTooltip from 'components/QTooltip';

interface Props {
  suggestions: Suggestion[];
  onChange: (canMonetize: boolean) => void;
}

export default function CanMonetize(props: Props) {
  const { suggestions, onChange } = props;
  const { t } = useIntl();

  const attachments = flatMap(suggestions, (s) => s.attachments);
  const initialValue = attachments.every((a) => a.canMonetize);
  const [isOn, setIsOn] = useState(attachments.every((a) => a.canMonetize));

  const handleToggle = () => {
    const nextValue = !isOn;
    setIsOn(nextValue);
    onChange(nextValue);
  };

  useEffect(() => {
    if (initialValue !== isOn) setIsOn(initialValue);
  }, [initialValue, isOn]);

  return (
    <div className="mt-3 flex items-center space-x-1 text-dark">
      <Form.Checkbox
        checked={isOn}
        onChange={handleToggle}
        data-qa="suggestion-form-can-monetize-checkbox"
      />
      <span
        className="bump-up-1 font-semibold"
        data-qa="suggestion-form-can-monetize-label"
      >
        {t('SuggestionForm__CanMonetize')}
      </span>
      <QTooltip content={t('SuggestionForm__CanMonetizeTooltip')} />
    </div>
  );
}
