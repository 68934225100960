import flatten from 'lodash/flatten';
import Suggestion from 'types/Suggestion';
import IndexSuggestion from 'types/IndexSuggestion';

const getSuggestionVideoCaptions = (
  suggestion: Suggestion | IndexSuggestion
) => {
  const videoCaptions = flatten(
    suggestion.platformBags.map((pb) => pb.videoCaptions)
  );

  if (!videoCaptions.length) return null;

  return videoCaptions;
};

export default getSuggestionVideoCaptions;
