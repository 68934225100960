import React from 'react';
import styled from 'styled-components';
import { grid, colors } from 'styles/theme';
import {
  TextInput,
  Select,
  PhoneInput,
  BrandCheckbox,
  BrandRadio,
  Checkbox,
  FileInput,
  Switch,
  Radio,
  Toggle,
  ToggleGroup,
  Errors,
} from './components';

interface Props {
  onSubmit?: (e?: any) => void;
  children: React.ReactNode;
}

const StyledForm = styled.form`
  text-align: left;
`;

const Field = styled.div`
  margin-bottom: ${grid(3)};
  text-align: left;

  ${(props: any) => props.tight && `margin-bottom: ${grid(1.5)}`};
` as any;

// prettier-ignore
const Label = styled.label`
  display: flex;
  align-items: center;
  margin-bottom: ${grid(0.5)};
  color: ${colors.darkText};
  font-size: 14px;

  ${(props: any) => props.theme.color === 'dark' && `color: ${colors.white}`};

  ${(props: any) => props.small && `
    margin-bottom: ${grid(0.25)};
    line-height: 14px;
    font-size: 12px;
  `};

  ${(props: any) => props.bold && `
    font-weight: 600;
  `}

  ${(props: any) => props.block && `
    display: block;
  `}
` as any;

const Option = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
  color: ${colors.darkText};
  font-size: 14px;

  > button {
    display: flex;
    align-items: flex-start;
    margin-right: ${grid(1)};
  }

  > span {
    position: relative;
    top: -1px;
  }
`;

const MultiLineOption = styled.label`
  display: flex;
  align-items: start;
  cursor: pointer;
  padding-top: ${grid(0.5)};
  color: ${colors.darkText};
  font-size: 14px;

  > button {
    margin-right: ${grid(1)};
  }

  > span {
    position: relative;
  }
`;

const Description = styled.div`
  line-height: ${grid(2.25)};
  color: ${colors.lightText};
  font-size: 13px;
  font-weight: normal;
`;

export default class Form extends React.Component<Props> {
  public static BrandCheckbox = BrandCheckbox;
  public static Checkbox = Checkbox;
  public static BrandRadio = BrandRadio;
  public static Radio = Radio;
  public static Field = Field;
  public static FileInput = FileInput;
  public static PhoneInput = PhoneInput;
  public static Label = Label;
  public static TextInput = TextInput;
  public static Select = Select;
  public static Option = Option;
  public static MultiLineOption = MultiLineOption;
  public static Toggle = Toggle;
  public static ToggleGroup = ToggleGroup;
  public static Description = Description;
  public static Switch = Switch;
  public static Errors = Errors;

  onSubmit = (e: any) => {
    e.preventDefault();
    (this.props.onSubmit || Function)();
  };

  render() {
    const onSubmit = this.onSubmit;

    return <StyledForm onSubmit={onSubmit}>{this.props.children}</StyledForm>;
  }
}
