import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunkMiddleware from 'redux-thunk';
import rootReducer from './ducks';
import { fileUploadMiddleware } from './middleware/fileUpload';
import { middleware as flashMiddleware } from 'redux-flash';

const configureStore = () =>
  createStore(
    rootReducer,
    composeWithDevTools(
      applyMiddleware(
        thunkMiddleware,
        fileUploadMiddleware,
        flashMiddleware({ timeout: 5000 })
      )
    )
  );

export default configureStore;
