import React from 'react';
import { Tooltip } from 'components';
import { IconInfo } from 'icons';
import Attachment from 'types/Attachment';
import { useIntl } from 'hooks';
import { Link } from 'react-router-dom';
import { routeFor, routes } from 'helpers';
import { formatLength, formatFileSize } from 'helpers/formatNumber';
import { TippyProps } from '@tippyjs/react';

interface Props extends TippyProps {
  attachment: Attachment;
}

export default function MetadataQuickLook(props: Props) {
  const { attachment, ...tippyProps } = props;
  const { t } = useIntl();

  return (
    <Tooltip
      {...tippyProps}
      content={
        <div className="min-w-24 max-w-36 p-1">
          {!!attachment.title && (
            <Item label={t('AttachmentMetadata__Title')}>
              {attachment.title}
            </Item>
          )}

          {!!attachment.caption && (
            <Item label={t('AttachmentMetadata__Caption')}>
              {attachment.caption}
            </Item>
          )}

          {!!attachment.keywords && (
            <Item label={t('AttachmentMetadata__Keywords')}>
              {attachment.keywords}
            </Item>
          )}

          {!!attachment.peopleInThisMedia && (
            <Item label={t('AttachmentMetadata__PeopleInThisMedia')}>
              {attachment.peopleInThisMedia}
            </Item>
          )}

          {attachment.publisher && (
            <Item label={t('AttachmentMetadata__SubmittedBy')}>
              <Link
                to={routeFor(routes.publishers.show, attachment.publisher.id)}
              >
                {attachment.publisher.name}
              </Link>
            </Item>
          )}

          {!!attachment.length && (
            <Item label={t('AttachmentMetadata__Length')}>
              {formatLength(attachment.length)}
            </Item>
          )}

          {!!attachment.location && (
            <Item label={t('AttachmentMetadata__Location')}>
              {attachment.location}
            </Item>
          )}

          {!!attachment.contentIntegrationPath && (
            <Item label={t('AttachmentMetadata__ContentIntegrationPath')}>
              {attachment.contentIntegrationPath}
            </Item>
          )}

          {!!attachment.mediaSize && (
            <Item label={t('AttachmentMetadata__FileSize')}>
              {formatFileSize(attachment.mediaSize)}
            </Item>
          )}
        </div>
      }
      theme="socialieNoPad"
      interactive
    >
      <span className="block h-full w-full">
        <IconInfo className="block h-full w-full text-white" />
      </span>
    </Tooltip>
  );
}

interface ItemProps {
  label: string;
  children: React.ReactNode;
}

function Item(props: ItemProps) {
  const { label, children } = props;

  return (
    <div className="mb-1.5 text-14 leading-tight last:mb-0">
      <div className="font-semibold text-dark">{label}</div>
      {children}
    </div>
  );
}
