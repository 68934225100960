import React from 'react';
import { UpdateFilterFn } from 'hooks/useFilterManager';
import MediaLibraryFilter from 'types/MediaLibraryFilter';
import { Form } from 'components';
import { FormattedMessage } from 'react-intl';

interface Props {
  filter: MediaLibraryFilter;
  updateFilter: UpdateFilterFn<MediaLibraryFilter>;
}

export default function CanMonetizeFilter(props: Props) {
  const { filter, updateFilter } = props;

  return (
    <Form.Field>
      <Form.Label bold>
        <FormattedMessage id="MediaLibraryCanMonetizeFilter__Label" />
      </Form.Label>

      <Form.Option>
        <Form.Checkbox
          checked={filter.canMonetize}
          onChange={() => updateFilter({ canMonetize: !filter.canMonetize })}
        />
        <span>
          <FormattedMessage id="MediaLibraryCanMonetizeFilter__OptionLabel" />
        </span>
      </Form.Option>
    </Form.Field>
  );
}
