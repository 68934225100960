import * as GET_TWILIO_MESSAGE from 'graphql/queries/twilioMessage.graphql';
import TwilioMessage from 'types/TwilioMessage';
import appendMessageToChat from './appendMessageToChat';
import apolloClient from '../apolloClient';

const loadTwilioMessage = async (id: number | string, reload = false) => {
  const hasMessage = apolloClient.cache.readQuery({
    query: GET_TWILIO_MESSAGE,
    variables: { id },
  });

  if (hasMessage && !reload) return;

  const { errors, data } = await apolloClient.query<{
    twilioMessage: TwilioMessage;
  }>({
    query: GET_TWILIO_MESSAGE,
    variables: { id },
    fetchPolicy: 'network-only',
  });

  if (errors) return { errors };
  if (data && data.twilioMessage && !reload) {
    appendMessageToChat(data.twilioMessage);
    return data;
  }
  return null;
};

export default loadTwilioMessage;
