import React from 'react';
import InfoTable from './InfoTable';
import InfoCard from './InfoCard';
import Attachment from 'types/Attachment';
import { useIntl } from 'hooks';

interface Props {
  attachment: Attachment;
}

export default function AttachmentModalDetailsSocialieData({
  attachment,
}: Props) {
  const { t } = useIntl();

  if (!attachment.aiStatus) return null;

  return (
    <InfoCard title={t('AttachmentModal__SocialieData')}>
      <InfoTable>
        {!!attachment.aiStatus && (
          <InfoTable.Row label={t('AttachmentMetadata__AiKeywords')}>
            {attachment.aiStatus === 'pending'
              ? t('AttachmentMetadata__AiPending')
              : !attachment.aiKeywords.length
              ? t('AttachmentMetadata__AiNoResult')
              : attachment.aiKeywords.join(', ')}
          </InfoTable.Row>
        )}
      </InfoTable>
    </InfoCard>
  );
}
