import { useState, useEffect } from 'react';
import useDebouncedCallback from 'use-debounce/lib/callback';

export default function useDebouncedWindowDimensions(debounce = 250) {
  const [dimensions, setDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const updateDimensions = useDebouncedCallback(
    () =>
      setDimensions({ width: window.innerWidth, height: window.innerHeight }),
    debounce
  );

  useEffect(() => {
    window.addEventListener('resize', updateDimensions);
    return () => window.removeEventListener('resize', updateDimensions);
  }, [updateDimensions]);

  return dimensions;
}
