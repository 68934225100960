import React, { useState, createElement } from 'react';
import { clsx } from 'clsx';
import ClickOutside from 'react-onclickout';
import Tooltip from '../Tooltip';
import hasClass from 'helpers/hasClass';
import noPropagate from 'helpers/noPropagate';
import { TippyProps } from '@tippyjs/react';
import { IconCaretRight, IconCheckmark } from 'icons';
import EnhancedNavLink from '../EnhancedNavLink';

interface Props {
  children: React.ReactNode;
  placement?: TippyProps['placement'];
  offset?: TippyProps['offset'];
  className?: string;
  itemClassName?: string;
  containerClassName?: string;
  renderHeading?: React.ReactNode;
  theme?: 'dark' | 'light';
  renderButton: (onClick: any, isOpen: boolean) => TippyProps['children'];
  onOpened?: () => void;
  disabled?: boolean;
}

const FlyoutMenuContext = React.createContext({
  onClose: () => {},
  theme: 'light',
});

export default function FlyoutMenu(props: Props) {
  const {
    children,
    renderHeading,
    renderButton,
    placement,
    className,
    theme = 'light',
    disabled,
    onOpened,
    offset = [0, 12],
  } = props;

  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => {
    const isChangingToOpen = !isOpen;
    setIsOpen((isOpen) => !isOpen);
    if (isChangingToOpen && onOpened) {
      // TODO: Switch to using flushSync here after we upgrade to React 17
      setTimeout(() => {
        onOpened();
      }, 0);
    }
  };

  const close = () => setIsOpen(false);

  const clickOut = (e: any) => {
    if (isOpen && !hasClass(e.target, 'tippy-box')) {
      close();
    }
  };

  return (
    <ClickOutside onClickOut={clickOut}>
      <Tooltip
        placement={placement || 'bottom-start'}
        arrow={false}
        visible={isOpen}
        disabled={disabled}
        className={className || ''}
        theme={theme === 'dark' ? 'FlyoutMenuDark' : 'FlyoutMenu'}
        interactive
        appendTo={document.body}
        offset={offset}
        duration={[200, 0]}
        delay={0}
        animation="FlyoutMenu"
        content={
          <div>
            {renderHeading}
            <FlyoutMenuContext.Provider value={{ onClose: close, theme }}>
              {children}
            </FlyoutMenuContext.Provider>
          </div>
        }
      >
        {renderButton(noPropagate(toggleOpen), isOpen)}
      </Tooltip>
    </ClickOutside>
  );
}

FlyoutMenu.Item = FlyoutMenuItem;

interface FlyoutMenuItemProps {
  children: React.ReactNode;
  'data-qa'?: string;
  icon?: typeof IconCaretRight;
  className?: string;
  showArrow?: 'always' | 'never' | 'when-active';
  to?: string;
  isActive?: boolean;
  onClick?: () => void;
  disabled?: boolean;
}

function FlyoutMenuItem(props: FlyoutMenuItemProps) {
  const {
    children,
    icon,
    className,
    onClick,
    to,
    showArrow = 'never',
    disabled,
  } = props;

  const { onClose, theme } = React.useContext(FlyoutMenuContext);

  const handleClick = () => {
    onClick?.();
    onClose();
  };

  const renderInnards = ({ isActive }: { isActive?: boolean }) => {
    const ArrowIcon = isActive ? IconCheckmark : IconCaretRight;
    const isArrowShowing =
      showArrow === 'always' || (showArrow === 'when-active' && isActive);
    return (
      <span
        className={clsx(
          'flex w-full items-center',
          !isActive && !disabled && 'group-hover:opacity-[0.65]'
        )}
      >
        {icon &&
          createElement(icon, {
            className: clsx(
              'w-2 h-2 mr-0.5',
              theme === 'dark' ? 'text-white' : 'text-dark'
            ),
          })}
        <span className={clsx('bump-up-1', isActive && 'font-bold')}>
          {children}
        </span>
        {isArrowShowing && (
          <ArrowIcon
            className={clsx(
              'ml-auto h-1.5 w-1.5',
              theme === 'dark' ? 'text-white' : 'text-dark'
            )}
          />
        )}
      </span>
    );
  };

  const sharedProps = {
    className: clsx(
      'group block py-0.75 pr-1 w-full last:border-b-0 disabled:opacity-50 disabled:cursor-default',
      theme === 'dark' ? 'text-white' : 'text-dark',
      className
    ),
    onClick: handleClick,
    'data-qa': props['data-qa'],
  };

  // Render as a Link if they passed a `to` prop
  if (to) {
    return (
      <EnhancedNavLink to={to} {...sharedProps}>
        {renderInnards}
      </EnhancedNavLink>
    );
  }

  return (
    <button type="button" {...sharedProps} disabled={disabled}>
      {renderInnards({ isActive: props.isActive })}
    </button>
  );
}
