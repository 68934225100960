import React from 'react';
import styled from 'styled-components';
import { grid, colors } from 'styles/theme';
import { FormattedMessage } from 'react-intl';

interface Props {
  name: string;
  loadedCount?: number;
  totalCount?: number;
  error?: any;
  loading?: boolean;
}

const Count = styled.span`
  display: inline-block;
  margin-right: ${grid(1)};
  color: ${colors.darkText};
  font-size: 16px;
`;

const Error = styled.div`
  color: ${colors.error};
`;

const renderMsg = (id: string, values?: any) => (
  <FormattedMessage id={id} values={values} />
);

const RecordCount = (props: Props) => {
  const { name, error, loadedCount, totalCount, loading } = props;

  if (error) return <Error>{renderMsg(`${name}__Error`)}</Error>;

  if (loading) return <Count>{renderMsg(`${name}__Loading`)}</Count>;

  if (totalCount === 0) {
    return <Count>{renderMsg(`${name}__NoneFound`)}</Count>;
  }

  return (
    <Count>{renderMsg(`${name}__Count`, { totalCount, loadedCount })}</Count>
  );
};

export default RecordCount;
