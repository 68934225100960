import React from 'react';
import { connect } from 'react-redux';
import { Query, Mutation } from '@apollo/react-components';
import ChatType from 'types/Chat';
import FileUpload from 'types/FileUpload';
import RecipientOption from 'types/RecipientOption';
import TwilioMessage from 'types/TwilioMessage';
import { chatPageSize } from 'config';
import { RootState } from 'redux/ducks';
import { getFileUploadByChatId, cancelUpload } from 'redux/ducks/fileUploads';
import * as CHAT_QUERY from 'graphql/queries/chat.graphql';
import * as SEND_CHAT_MESSAGE from 'graphql/mutations/sendChatMessage.graphql';
import Chat from './Chat';
import { flashMessage, getPhoneNumbersFromRecipients } from 'helpers';
import appendMessageToChat from 'graphql/operations/appendMessageToChat';
import { Screens } from '../../ChatPanel';
import { MutationUpdaterFn } from 'apollo-client';

interface OwnProps {
  id: string | number;
  gotoScreen: (screen: Screens, chatId?: any) => void;
}

interface ConnectedState {
  fileUpload?: FileUpload;
}

interface ConnectedActions {
  cancelUpload: (uploadId: string) => void;
}

type Props = OwnProps & ConnectedState & ConnectedActions;

interface State {
  newRecipients: RecipientOption[];
  messageText: string;
}

interface MutationData {
  sendChatMessage: { messages?: TwilioMessage[]; errors?: string };
}

interface MutationVars {
  text?: string;
  attachmentId?: number | string;
  phoneNumbers?: string | string[];
}

interface QueryData {
  chat?: ChatType;
}

interface QueryVars {
  id: number | string;
}

class ChatContainer extends React.Component<Props, State> {
  state: State = { newRecipients: [], messageText: '' };

  onChangeRecipients = (recipients: RecipientOption[]) =>
    this.setState({ newRecipients: recipients });

  onChangeMessageText = (value: string) =>
    this.setState({ messageText: value });

  onSendCompleted = ({ sendChatMessage }: MutationData) => {
    const { errors, messages } = sendChatMessage;
    if (errors) return this.onSendError();
    if (messages) {
      this.onChangeMessageText('');
      if (this.props.fileUpload) {
        this.props.cancelUpload(this.props.fileUpload.id);
      }
      if (this.props.id === 'new') {
        if (messages.length === 1) {
          this.props.gotoScreen('chat', messages[0].publisherPhoneNumber);
        } else {
          this.props.gotoScreen('home');
        }
      }
    }
  };

  onSendError = () => {
    flashMessage('ChatPanel__ErrorSending', { style: 'error' });
  };

  getPhoneNumbers = (chat?: ChatType) => {
    if (chat) return [chat.id];
    const { newRecipients } = this.state;
    return getPhoneNumbersFromRecipients(newRecipients.map((r) => r.data));
  };

  updateCache: MutationUpdaterFn<MutationData> = (cache, { data }) => {
    if (!data || !data.sendChatMessage.messages) return;
    data.sendChatMessage.messages.forEach((message) =>
      appendMessageToChat(message)
    );
  };

  render() {
    const { messageText, newRecipients } = this.state;
    const { fileUpload } = this.props;
    const isNew = this.props.id === 'new';
    const newPhoneNumbers = this.getPhoneNumbers();
    const id =
      isNew && newPhoneNumbers.length === 1
        ? newPhoneNumbers[0]
        : this.props.id;
    const queryVariables = { id, pageSize: chatPageSize };

    return (
      <Query<QueryData, QueryVars>
        key={this.props.id}
        query={CHAT_QUERY}
        skip={id === 'new'}
        variables={queryVariables}
        fetchPolicy="network-only"
      >
        {({ data, loading, error, fetchMore }) => {
          const isLoading = loading && !isNew;
          const chat = data && data.chat;
          return (
            <Mutation<MutationData, MutationVars>
              mutation={SEND_CHAT_MESSAGE}
              onCompleted={this.onSendCompleted}
              onError={this.onSendError}
              update={this.updateCache}
              variables={{
                text: messageText,
                attachmentId: fileUpload && fileUpload.attachmentId,
                phoneNumbers: this.getPhoneNumbers(data && data.chat),
              }}
            >
              {(sendChatMessage, { loading: isSending }) => (
                <Chat
                  chat={chat}
                  fileUpload={fileUpload}
                  isNew={isNew}
                  isLoading={isLoading}
                  isSending={isSending}
                  error={error}
                  messageText={messageText}
                  newRecipients={newRecipients}
                  phoneNumbers={(chat && [chat.id]) || newPhoneNumbers}
                  onSendMessage={sendChatMessage}
                  onChangeRecipients={this.onChangeRecipients}
                  onChangeMessageText={this.onChangeMessageText}
                />
              )}
            </Mutation>
          );
        }}
      </Query>
    );
  }
}

const mapStateToProps = (state: RootState, ownProps: OwnProps) => ({
  fileUpload: getFileUploadByChatId(state, ownProps.id),
});

const mapDispatchToProps = { cancelUpload };

export default connect<ConnectedState, ConnectedActions, OwnProps, RootState>(
  mapStateToProps,
  mapDispatchToProps
)(ChatContainer);
