import React from 'react';
import includes from 'lodash/includes';
import Chat from 'types/Chat';
import { formatPhone } from 'helpers';
import { FormattedMessage } from 'react-intl';
import styled, { ThemeProvider } from 'styled-components';
import {
  Avatar,
  PublisherAvatar,
  AvatarStack,
  FormattedTimeAgo,
  Tooltip,
} from 'components';
import ChatPanelContext from '../../../ChatPanelContext';
import { IconWarning } from 'icons';
import { grid, colors } from 'styles/theme';
import ContactMethodIcon from '../../ContactMethodIcon';

const OuterWrapper = styled.button.attrs({ type: 'button' })`
  display: block;
  position: relative;
  padding: 0 ${grid(2)};
  padding-left: ${grid(2.5)};
  width: 100%;

  &:hover {
    background: ${colors.hoverBg};
  }

  &:active {
    background: ${colors.greyBg};
  }
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${colors.lightBorder};
  padding: ${grid(2)} 0;
  width: 100%;
`;

const AvatarWrapper = styled.div`
  margin-right: ${grid(2)};
  width: ${grid(5.5)};
  height: ${grid(5.5)};
`;

const Details = styled.div`
  flex: 1;
  margin-right: ${grid(2.5)};
  overflow: hidden;
  line-height: ${grid(2.5)};

  > * {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

const PrimaryLabel = styled.div`
  display: flex;
  align-items: center;
  color: ${colors.darkText};
  ${({ theme }: any) => theme.isUnread && 'font-weight: bold'};

  &::after {
    display: none;
    position: relative;
    top: 1px;
    margin-left: ${grid(0.5)};
    border-radius: 50%;
    background-color: ${colors.socialiePink};
    width: ${grid(1)};
    height: ${grid(1)};
    content: '';

    ${({ theme }: any) => theme.isUnread && 'display: block'};
  }
`;

const SecondaryLabel = styled.div`
  line-height: ${grid(2)};
  color: ${colors.lightText};
  font-size: 12px;
`;

const Text = styled.div`
  color: ${colors.lightText};
  ${({ theme }: any) => theme.isUnread && `color: ${colors.darkText}`};
`;

const Failed = styled.div`
  position: relative;
  top: 2px;
  margin-right: ${grid(0.5)};
  margin-bottom: ${grid(0.5)};
  width: ${grid(2)};
  height: ${grid(2)};
  color: ${colors.error};

  svg {
    display: block;
    width: 100%;
    height: 100%;
  }
`;

const Time = styled.div`
  margin-left: auto;
  text-align: right;
  color: ${colors.lightText};
  font-size: 12px;
`;

interface Props {
  chat: Chat;
}

export default function ChatPreview({ chat }: Props) {
  const {
    id,
    latestMessage,
    publishers,
    phoneNumber,
    contactMethods,
    isUnread,
  } = chat;
  const failed = includes(['failed', 'undelivered'], latestMessage.status);

  return (
    <ThemeProvider theme={{ isUnread }}>
      <ChatPanelContext.Consumer>
        {({ gotoScreen }) => (
          <OuterWrapper onClick={() => gotoScreen('chat', id)}>
            <Wrapper>
              <AvatarWrapper>
                {!publishers.length ? (
                  <Avatar src={undefined} name="?" />
                ) : publishers.length === 1 ? (
                  <PublisherAvatar publisher={publishers[0]} />
                ) : (
                  <AvatarStack
                    avatars={publishers.map((p) => ({
                      id: p.id,
                      name: p.name,
                      src: p.image,
                    }))}
                  />
                )}
              </AvatarWrapper>

              <Details>
                {!publishers.length ? (
                  <div className="flex items-center">
                    <PrimaryLabel>{formatPhone(phoneNumber)}</PrimaryLabel>
                    <ContactMethodIcon phoneNumber={phoneNumber} />
                  </div>
                ) : publishers.length === 1 ? (
                  <div className="flex items-center">
                    <PrimaryLabel>{publishers[0].name}</PrimaryLabel>
                    {contactMethods.length === 1 && contactMethods[0].name && (
                      <SecondaryLabel>
                        {formatPhone(phoneNumber)} ({contactMethods[0].name})
                      </SecondaryLabel>
                    )}
                    <ContactMethodIcon phoneNumber={phoneNumber} />
                  </div>
                ) : (
                  <>
                    <div className="flex items-center">
                      <PrimaryLabel>{formatPhone(phoneNumber)}</PrimaryLabel>
                      <ContactMethodIcon phoneNumber={phoneNumber} />
                    </div>
                    <SecondaryLabel>
                      {publishers.map((p) => p.name).join(', ')}
                    </SecondaryLabel>
                  </>
                )}

                <Text>
                  {latestMessage.text ? (
                    latestMessage.text
                  ) : latestMessage.files.length ? (
                    <em>
                      <FormattedMessage
                        id="ChatPanel__AttachmentsCount"
                        values={{ count: latestMessage.files.length }}
                      />
                    </em>
                  ) : null}
                </Text>
              </Details>

              {failed && (
                <Tooltip
                  theme="socialieDark"
                  content={
                    <FormattedMessage
                      id="ChatPanel__FailedToSend"
                      values={{ errorCode: latestMessage.errorCode }}
                    />
                  }
                >
                  <Failed>
                    <IconWarning />
                  </Failed>
                </Tooltip>
              )}
              <Time>
                <FormattedTimeAgo timestamp={latestMessage.createdAt} />
              </Time>
            </Wrapper>
          </OuterWrapper>
        )}
      </ChatPanelContext.Consumer>
    </ThemeProvider>
  );
}
