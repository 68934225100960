import React from 'react';
import styled from 'styled-components';
import {
  formatPercent,
  formatAverage,
  formatBigInt,
} from 'helpers/formatNumber';
import { FormattedMessage } from 'react-intl';
import { Card, QTooltip } from 'components';
import CountUp from 'react-countup';
import { colors, grid } from 'styles/theme';

interface Props {
  title: string;
  value: number;
  percent?: boolean;
  loading?: boolean;
  tooltip?: string;
  secondaryTitle?: string;
  secondaryValue?: any;
}

const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: ${grid(1)};
  text-align: center;
  color: ${colors.lightText};
  font-size: 14px;
`;

const Value = styled.div`
  margin-bottom: ${grid(2)};
  text-align: center;
  line-height: 60px;
  font-size: 60px;
  font-weight: 300;
`;

const Secondary = styled.div`
  text-align: center;
  line-height: 20px;
  color: ${colors.lightText};
  font-size: 12px;
`;

const Stat = ({
  title,
  tooltip,
  value,
  percent,
  loading,
  secondaryTitle,
  secondaryValue,
}: Props) => (
  <Card>
    <Title>
      <FormattedMessage id={title} />
      {tooltip && <QTooltip content={<FormattedMessage id={tooltip} />} />}
    </Title>

    {!!value || value === 0 ? (
      <Value title={percent ? undefined : formatBigInt(value)}>
        <CountUp
          duration={0.5}
          start={0}
          end={value}
          formattingFn={percent ? formatPercent : formatAverage}
        />
      </Value>
    ) : (
      <Value>--</Value>
    )}

    {secondaryTitle && (
      <Secondary>
        <FormattedMessage id={secondaryTitle} />: {secondaryValue}
      </Secondary>
    )}
  </Card>
);

export default Stat;
