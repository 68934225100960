import React from 'react';
import { FormattedRelative } from 'react-intl';
import { useIntl } from 'hooks';

type RenderFunc = (
  formattedTime: string,
  isPast?: boolean
) => React.ReactNode | JSX.Element;

interface Props {
  timestamp: number;
  children: RenderFunc;
}

export default function FriendlyDateTime(props: Props) {
  const { timestamp } = props;
  const date = new Date(timestamp).getTime();
  const today = new Date().getTime();
  const isToday = date === today;
  const isTomorrow = date - today === 1;
  const isPast = date < today;
  const { t, formatTime, formatDate } = useIntl();

  const renderToday = () => (
    <FormattedRelative value={timestamp}>
      {(formattedTime) => {
        const isPast = timestamp < new Date().getTime();
        return props.children(formattedTime, isPast);
      }}
    </FormattedRelative>
  );

  const renderTomorrow = () => {
    const time = formatTime(timestamp, { timeZoneName: 'short' });
    const msg = t('FriendlyDateTime__Tomorrow', { time });
    return props.children(msg);
  };

  const renderFullDate = () => {
    const date = formatDate(timestamp);
    const time = formatTime(timestamp, { timeZoneName: 'short' });
    const msg = t('FriendlyDateTime__FullDate', { date, time });
    return props.children(msg, isPast);
  };

  // Wrap this whole thing in a fragment so that TypeScript doesn't get confused
  return (
    <>
      {isToday
        ? renderToday()
        : isTomorrow
        ? renderTomorrow()
        : renderFullDate()}
    </>
  );
}
