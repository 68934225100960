import React from 'react';
import styled from 'styled-components';
import { grid, colors } from 'styles/theme';

interface Props {
  children: React.ReactNode;
}

const Wrapper = styled.div`
  width: 100%;
  overflow: hidden;
`;

const Row = styled.div`
  display: flex;

  > * {
    padding-bottom: ${grid(0.5)};
    vertical-align: top;
    line-height: ${grid(2.25)};

    &:last-child {
      padding-bottom: 0;
    }
  }

  .label {
    flex-shrink: 0;
    margin-right: ${grid(2)};
    width: ${grid(14)};
    color: ${colors.lightText};
  }

  .value {
    flex: 1;
    overflow: hidden;
    word-wrap: break-word;
    color: ${colors.darkText};

    a {
      color: ${colors.darkText};

      &:hover {
        text-decoration: underline;
      }
    }
  }
`;

interface RowProps {
  label: React.ReactNode;
  children: React.ReactNode;
}

function InfoRow({ label, children }: RowProps) {
  return (
    <Row data-testid={label}>
      <div className="label">{label}</div>
      <div className="value">{children}</div>
    </Row>
  );
}

function InfoTable({ children }: Props) {
  return <Wrapper>{children}</Wrapper>;
}

InfoTable.Row = InfoRow;

export default InfoTable;
