import { OtherAction } from '../global';

export type State = boolean;

enum ActionTypes {
  OPEN = 'ui/isSidebarOpen/OPEN',
  CLOSE = 'ui/isSidebarOpen/CLOSE',
  TOGGLE = 'ui/isSidebarOpen/TOGGLE',
}

interface OpenAction {
  type: ActionTypes.OPEN;
}

interface CloseAction {
  type: ActionTypes.CLOSE;
}

interface ToggleAction {
  type: ActionTypes.TOGGLE;
}

type Action = OpenAction | CloseAction | ToggleAction | OtherAction;

const isSidebarOpenReducer = (state: State = true, action: Action): State => {
  switch (action.type) {
    case ActionTypes.CLOSE:
      return false;
    case ActionTypes.OPEN:
      return true;
    case ActionTypes.TOGGLE:
      return !state;
    default:
      return state;
  }
};

// Actions
export const openSidebar = () => ({ type: ActionTypes.OPEN });
export const closeSidebar = () => ({ type: ActionTypes.CLOSE });
export const toggleSidebar = () => ({ type: ActionTypes.TOGGLE });

export default isSidebarOpenReducer;
