import React from 'react';
import BeforeUnload from 'react-beforeunload';
import { Prompt } from 'react-router-dom';
import { useIntl } from 'hooks';

interface Props {
  isOn: boolean;
  message?: string;
}

export default function ProtectUnsavedChanges({ isOn, message }: Props) {
  const { t } = useIntl();
  if (!isOn) return null;

  const msg = message || t('ProtectUnsavedChanges__Warning');

  return (
    <>
      <Prompt when={isOn} message={msg} />
      <BeforeUnload onBeforeunload={() => msg} />
    </>
  );
}
