import Recipient from 'types/Recipient';
import RecipientOption from 'types/RecipientOption';

const getRecipientLabel = (recipient: Recipient) => {
  if (recipient.contactMethod?.publisher?.name) {
    return recipient.contactMethod.publisher.name;
  }

  if (recipient.publisherUser?.publisher?.name) {
    return recipient.publisherUser.publisher.name;
  }

  if (recipient.publisher?.name) {
    return recipient.publisher.name;
  }

  return 'group';
};

const mapRecipientsToOptions = (recipients: Recipient[]): RecipientOption[] => {
  return recipients.map((recipient) => ({
    value: recipient.combinedId,
    label: getRecipientLabel(recipient),
    data: recipient,
  }));
};

export default mapRecipientsToOptions;
