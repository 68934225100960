import React from 'react';
import moment from 'moment';
import { FormattedRelative, FormattedMessage } from 'react-intl';

interface Props {
  timestamp: number;
}

const formattedTimeAgo = (props: Props) => {
  const { timestamp } = props;

  const date = new Date(timestamp);

  // today
  if (moment().diff(timestamp, 'days') === 0) {
    return <FormattedRelative value={timestamp} />;
  }

  // yesterday
  if (moment().diff(timestamp, 'days') === 1) {
    return <FormattedMessage id="FormattedTimeAgo__Yesterday" />;
  }

  // this week
  const startOfWeek = moment().subtract(1, 'week').endOf('day');

  if (startOfWeek.isBefore(timestamp)) {
    return <>{moment(timestamp).format('dddd')}</>;
  }

  // this year
  if (date.getFullYear() === new Date().getFullYear()) {
    return <>{moment(timestamp).format('MMM Do')}</>;
  }

  // anything older
  return <>{moment(timestamp).format('MMM Do, YYYY')}</>;
};

export default formattedTimeAgo;
