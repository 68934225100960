import camelize from 'camelize';
import { createAuthRequest } from 'api/auth';
import {
  AuthenticationRequestResponse,
  AuthenticationRequestOptions,
} from './types';

const SERVER =
  process.env.REACT_APP_OAUTH_REDIRECT_URL ||
  process.env.REACT_APP_API_SERVER_URL;

const twitterRedirectUrl = (json: AuthenticationRequestResponse) => {
  return `https://api.twitter.com/oauth/authenticate?oauth_token=${json.requestToken}&force_login=true`;
};

const slackRedirectUrl = (state: string, appType: string): string => {
  const scopes =
    appType === 'sms' ? 'incoming-webhook,commands' : 'incoming-webhook';
  const clientId =
    appType === 'sms'
      ? process.env.REACT_APP_SLACK_SMS_CLIENT_ID
      : process.env.REACT_APP_SLACK_NOTIFICATIONS_CLIENT_ID;
  const url = `https://slack.com/oauth/authorize?scope=${scopes}&client_id=${clientId}`;
  const redirectUri = `${SERVER}/slack/integrations/authorize`;
  return `${url}&state=${state}&redirect_uri=${redirectUri}`;
};

const dropboxRedirectUrl = (state: string) => {
  const clientId = process.env.REACT_APP_DROPBOX_ID;
  const url = `https://www.dropbox.com/oauth2/authorize?client_id=${clientId}`;
  const redirectUri = `${SERVER}/auth/dropbox`;

  const scopes = [
    'account_info.read',
    'files.metadata.read',
    'files.content.read',
  ].join('+');

  return `${url}&state=${state}&response_type=code&redirect_uri=${redirectUri}&token_access_type=offline&scope=${scopes}`;
};

const gettyImagesRedirectUrl = (
  state: string,
  forPublishers = false
): string => {
  const clientId = process.env.REACT_APP_GETTY_CLIENT_ID;
  const provider = forPublishers ? 'getty_for_publishers' : 'getty_images';
  const url = `https://api.gettyimages.com/oauth2/auth?client_id=${clientId}`;
  const redirect = `${SERVER}/auth/${provider}`;
  return (
    `${url}&state=${state}&response_type=authorization_code` +
    `&redirect_uri=${redirect}`
  );
};

const boxRedirectUrl = (state: string): string => {
  const clientId = process.env.REACT_APP_BOX_CLIENT_ID;
  const base = `https://account.box.com/api/oauth2/authorize?response_type=code`;
  const redirectUri = `${SERVER}/auth/box`;
  return `${base}&client_id=${clientId}&state=${state}&redirect_uri=${redirectUri}`;
};

const facebookRedirectUrl = (state: string) => {
  const base = 'https://www.facebook.com/v13.0/dialog/oauth?response_type=code';
  const clientId = process.env.REACT_APP_FACEBOOK_CLIENT_ID;
  const redirectUri = `${SERVER}/auth/facebook`;
  const scope = [
    'pages_read_engagement',
    'pages_show_list',
    'instagram_basic',
    'instagram_manage_insights',
    'business_management',
  ].join(',');
  return `${base}&client_id=${clientId}&redirect_uri=${redirectUri}&state=${state}&scope=${scope}`;
};

const shutterstockRedirectUrl = (state: string) => {
  const base = 'https://api.shutterstock.com/v2/oauth/authorize';
  const redirectUri = `${SERVER}/auth/shutterstock`;
  const params = {
    client_id: process.env.REACT_APP_SHUTTERSTOCK_CLIENT_ID || '',
    redirect_uri: redirectUri,
    response_type: 'code',
    scope:
      'collections.view licenses.create licenses.view purchases.view user.view',
    state: state,
  };
  return `${base}?${new URLSearchParams(params).toString()}`;
};

const assetBankUrl = (options: AuthenticationRequestOptions, state: string) => {
  const redirectUri = `${SERVER}/auth/asset_bank`;

  return (
    `${options.integration!.url}/oauth/authorize?response_type=code&` +
    `client_id=${options.integration!.apiKey}&` +
    `state=${state}&redirect_uri=${redirectUri}`
  );
};

const redirectUrl = (
  options: AuthenticationRequestOptions,
  json: AuthenticationRequestResponse
): string => {
  switch (options.platformId) {
    case 'slack':
      return slackRedirectUrl(json.state, options.appType!);
    case 'dropbox':
      return dropboxRedirectUrl(json.state);
    case 'twitter':
      return twitterRedirectUrl(camelize(json));
    case 'getty_images':
      return gettyImagesRedirectUrl(json.state);
    case 'getty_for_publishers':
      return gettyImagesRedirectUrl(json.state, true);
    case 'facebook':
      return facebookRedirectUrl(json.state);
    case 'box':
      return boxRedirectUrl(json.state);
    case 'shutterstock':
      return shutterstockRedirectUrl(json.state);
    case 'asset_bank':
      return assetBankUrl(options, json.state);
  }
};

const performLogin = async (options: AuthenticationRequestOptions) => {
  const json: AuthenticationRequestResponse = await createAuthRequest(options);
  window.location.href = redirectUrl(options, json);
};

export default performLogin;
