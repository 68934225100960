import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import usePrevious from '@rooks/use-previous';
import { RootState } from 'redux/ducks';

// There are a couple of places in the builder where we want the user to be able
// to click a button to start an action (e.g. sending the batch) but if their
// latest updates are still saving then we want to wait for that to finish
// before we take the action.  This hook wraps any action you want and returns
// an `isWaiting` state if the user fires it while `isSaving` is true.
export default function useWaitForSave(
  callback: Function
): [() => void, boolean, boolean] {
  const isSaving = useSelector(
    (state: RootState) => !!state.ui.batchSuggestionSaveOperations.length
  );
  const [isWaitingForSave, setIsWaitingForSave] = useState(false);
  const wasSaving = usePrevious(isSaving);
  const handleCallback = () => {
    if (isSaving) {
      setIsWaitingForSave(true);
      return;
    }
    callback();
  };

  useEffect(() => {
    if (isWaitingForSave && wasSaving && !isSaving) {
      setIsWaitingForSave(false);
      callback();
    }
  }, [isWaitingForSave, wasSaving, isSaving, callback]);

  return [handleCallback, isWaitingForSave, isSaving];
}
