import styled from 'styled-components';
import { grid, colors } from 'styles/theme';

const StatTable = styled.table`
  width: 100%;
  border-collapse: collapse;

  td,
  th {
    padding: ${grid(0.75)};
    white-space: nowrap;
  }

  th {
    text-align: left;
    color: ${colors.darkText};
    font-weight: normal;
  }

  td {
    border-top: 1px solid ${colors.lightBorder};
  }
`;

export default StatTable;
