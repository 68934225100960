import React from 'react';
import { ThemeProvider } from 'styled-components';
import TwilioNumber from 'types/TwilioNumber';
import Suggester from 'types/Suggester';
import PhoneInput from './PhoneInput';
import { splitPhoneNumber } from './utils';
import Errors from '../Errors';

interface Props {
  suggester?: Suggester;
  defaultCountryCodes?: string[];
  value: string;
  onChange: (value: string) => void;
  isInvalid?: boolean;
  forceWorldwide?: boolean;
  onFocus?: (e: any) => any;
  onBlur?: (e: any) => any;
  allowClear?: boolean;
  errors?: string;
}

interface State {
  phoneNumber: string;
  countryCode: string;
}

export default class PhoneInputContainer extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const { value } = props;

    this.state = value
      ? splitPhoneNumber(value)
      : { countryCode: '', phoneNumber: '' };
  }

  onChangeCountryCode = (countryCode: string) => {
    this.setState({ countryCode }, this.onChange);
  };

  onChangePhoneNumber = (phoneNumber: string) => {
    this.setState({ phoneNumber }, this.onChange);
  };

  onChange = () => {
    const { countryCode, phoneNumber } = this.state;
    if (phoneNumber) {
      this.props.onChange(`${countryCode}${phoneNumber}`);
    } else {
      this.props.onChange('');
    }
  };

  onClear = () => {
    this.setState({ phoneNumber: '', countryCode: '' }, this.onChange);
  };

  render() {
    const {
      suggester,
      errors,
      onFocus,
      onBlur,
      forceWorldwide,
      defaultCountryCodes,
      allowClear,
    } = this.props;
    const twilioNumbers = suggester?.twilioNumbers || [];
    const accountConfiguration = suggester?.accountConfiguration;
    const allowWorldwideSms = accountConfiguration?.allowWorldwideSms || false;
    const countryCodeWhitelist =
      accountConfiguration?.countryCodeWhitelist || [];

    if (!forceWorldwide && (!twilioNumbers || !twilioNumbers.length)) {
      return <span>This account is not configured to send SMS.</span>;
    }

    if (suggester && defaultCountryCodes) {
      throw new Error(
        "PhoneInput: Can't pass defaultCountryCodes and suggester"
      );
    }

    const countryCodes = defaultCountryCodes
      ? defaultCountryCodes
      : twilioNumbers
      ? twilioNumbers.map((t: TwilioNumber) => t.countryCode)
      : [];

    return (
      <ThemeProvider theme={{ isInvalid: !!errors }}>
        <>
          <PhoneInput
            value={this.state.phoneNumber}
            countryCode={this.state.countryCode}
            onChange={this.onChangePhoneNumber}
            onChangeCountryCode={this.onChangeCountryCode}
            countryCodes={countryCodes}
            allowWorldwideSms={allowWorldwideSms || forceWorldwide}
            countryCodeWhitelist={countryCodeWhitelist}
            onFocus={onFocus}
            onBlur={onBlur}
            onClear={allowClear ? this.onClear : undefined}
          />

          {errors && <Errors errors={errors} />}
        </>
      </ThemeProvider>
    );
  }
}
