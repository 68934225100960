import React, { useReducer } from 'react';
import { IconColorPicker } from 'icons';
import { useIntl } from 'hooks';
import { SketchPicker } from 'react-color';
import styled from 'styled-components';
import ColorSelector from './StyledColorSelector';

import ClickOutside from 'react-onclickout';
import { createSlice } from 'redux-starter-kit';

const FirstPickerWrapper = styled.div`
  position: absolute;
  bottom: 48px;
  left: 0;
  z-index: 30;
`;

const SecondPickerWrapper = styled.div`
  position: absolute;
  bottom: 24px;
  left: -4px;
  z-index: 30;
`;

const initialState = {
  isOpen: {
    first: false,
    second: false,
  },
};

const { reducer, actions } = createSlice({
  name: 'colorPickerReducer',
  initialState,
  reducers: {
    openColorPicker: (
      state,
      action: {
        payload: {
          first: boolean;
          second: boolean;
        };
      }
    ) => {
      state.isOpen.first = action.payload.first;
      state.isOpen.second = action.payload.second;
    },
    closeColorPicker: (state, action) => {},
    closeAllPickers: (state) => {
      state.isOpen.first = false;
      state.isOpen.second = false;
    },
  },
});
interface Props {
  primaryColor: string;
  onColorChange: (color: any) => void;
}

export default function BrandColorPicker({
  primaryColor,
  onColorChange,
}: Props) {
  const { t } = useIntl();
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <div className="flex rounded bg-grey8 py-4.5 px-4">
      <div className="flex h-auto items-center">
        <ColorSelector
          primaryColor={primaryColor}
          onClick={() =>
            dispatch(actions.openColorPicker({ first: true, second: false }))
          }
        >
          <IconColorPicker />
          {state.isOpen.first && (
            <ClickOutside
              onClickOut={() => dispatch(actions.closeAllPickers())}
            >
              <FirstPickerWrapper>
                <SketchPicker
                  color={primaryColor}
                  onChange={onColorChange}
                  presetColors={[
                    '#f54337',
                    '#ea1e63',
                    '#9c27b0',
                    '#673bb7',
                    '#4051b6',
                    '#03aaf4',
                    '#01bcd5',
                    '#009788',
                    '#4caf50',
                    '#8cc44a',
                    '#ff9901',
                    '#ff5722',
                    '#9e9e9e',
                    '#607d8b',
                    '#ffffff',
                    '#000000',
                  ]}
                />
              </FirstPickerWrapper>
            </ClickOutside>
          )}
        </ColorSelector>
      </div>
      <div className="flex flex-col">
        <div className="flex">
          <p className="mr-1 font-bold text-dark">
            {t('Brand__ColorPicker--Label')}
          </p>
          <div className="relative w-auto">
            <p
              className="cursor-pointer text-socialiePink hover:underline"
              onClick={() =>
                dispatch(
                  actions.openColorPicker({ first: false, second: true })
                )
              }
            >
              {t('Global__Edit')}
            </p>
            {state.isOpen.second && (
              <ClickOutside
                onClickOut={() => dispatch(actions.closeAllPickers())}
              >
                <SecondPickerWrapper>
                  <SketchPicker
                    color={primaryColor}
                    onChange={onColorChange}
                    presetColors={[
                      '#f54337',
                      '#ea1e63',
                      '#9c27b0',
                      '#673bb7',
                      '#4051b6',
                      '#03aaf4',
                      '#01bcd5',
                      '#009788',
                      '#4caf50',
                      '#8cc44a',
                      '#ff9901',
                      '#ff5722',
                      '#9e9e9e',
                      '#607d8b',
                      '#ffffff',
                      '#000000',
                    ]}
                  />
                </SecondPickerWrapper>
              </ClickOutside>
            )}
          </div>
        </div>
        <p className="leading-20">{t('Brand__ColorPicker--SecondaryText')}</p>
      </div>
    </div>
  );
}
