import React, { useRef, useCallback, useEffect, useLayoutEffect } from 'react';
import styled from 'styled-components';
import range from 'lodash/range';
import get from 'lodash/get';
import uniqBy from 'lodash/uniqBy';
import { grid, colors } from 'styles/theme';
import { chatPageSize } from 'config';
import markChatRead from 'graphql/operations/markChatRead';
import { mapNodes, updateQuery } from 'helpers';
import RelayConnection from 'types/RelayConnection';
import Chat from 'types/Chat';
import TwilioMessage from 'types/TwilioMessage';
import { Loader, ErrorDebug, InfiniteScrollList } from 'components';
import { Message } from './components';
import { ScrollPosition } from './context';
import * as TWILIO_MESSAGES_QUERY from 'graphql/queries/twilioMessages.graphql';
import { useQuery } from '@apollo/react-hooks';

interface Props {
  chat?: Chat;
  setIsThreadExpired: (isDisabled: boolean) => void;
}

const Wrapper = styled.div`
  flex: 1;
  border-bottom: 1px solid ${colors.lightBorder};
  padding: ${grid(2)};
  overflow: auto;

  ::-webkit-scrollbar {
    background: transparent;
    width: 0px;
  }
`;

interface QueryData {
  twilioMessages: RelayConnection<TwilioMessage>;
}

export default function MessageHistory({ chat, setIsThreadExpired }: Props) {
  const firstMsgId = useRef(0);
  const prevMsgCount = useRef(0);
  const wrapperRef = useRef<HTMLDivElement>(null);

  const id = chat && chat.id;

  const queryVars = { chatId: id, pageSize: chatPageSize };

  const { data, loading, error, fetchMore } = useQuery<QueryData>(
    TWILIO_MESSAGES_QUERY,
    {
      variables: queryVars,
      fetchPolicy: 'network-only',
      skip: !id,
    }
  );

  const loadNextPage =
    data && data.twilioMessages
      ? () =>
          fetchMore({
            variables: {
              ...queryVars,
              cursor: data.twilioMessages.pageInfo.endCursor,
            },
            updateQuery: (prev, { fetchMoreResult }) =>
              updateQuery('twilioMessages', prev, fetchMoreResult),
          })
      : () => {};
  const hasNextPage = get(data, 'twilioMessages.pageInfo.hasNextPage');
  const msgCount =
    data && data.twilioMessages ? data.twilioMessages.edges.length : 0;
  const reversedIdx = range(msgCount).reverse();

  const messages =
    data && data.twilioMessages && mapNodes(data.twilioMessages.edges);

  const mostRecentMessage = messages?.[0] || {};

  const markRead = useCallback(() => {
    id && markChatRead(id);
  }, [id]);

  const scrollToBottom = useCallback(() => {
    const msgIds = messages?.map((m) => m.id) || [];
    const wrapper = wrapperRef.current;
    if (!wrapper) return;

    if (
      msgIds.length > prevMsgCount.current &&
      msgIds[0] !== firstMsgId.current
    ) {
      // New message, scroll right to the bottom and mark chat as read
      wrapper.scrollTop = wrapper.scrollHeight;
      prevMsgCount.current = msgIds.length;
      firstMsgId.current = msgIds[0];
      markRead();
    } else if (msgIds.length > prevMsgCount.current) {
      // Possibly received a new page, try to maintain scroll position
      // otherwise just go right to the bottom
      const firstMsg = wrapper.querySelector('.first-in-page') as HTMLElement;
      const lastMsg = firstMsg && (firstMsg.nextSibling as HTMLElement);
      wrapper.scrollTop = lastMsg
        ? lastMsg.offsetTop - 170
        : wrapper.scrollHeight;
    }
  }, [messages, markRead]);

  // Mark read on mount
  useEffect(() => {
    markRead();
  }, [markRead]);

  useLayoutEffect(() => {
    scrollToBottom();
  }, [scrollToBottom]);

  if (!chat) return <Wrapper />;

  return (
    <Wrapper ref={wrapperRef}>
      {loading ? (
        <Loader />
      ) : error ? (
        <ErrorDebug error={error} />
      ) : (
        data &&
        data.twilioMessages && (
          <ScrollPosition.Provider
            value={{
              scrollToBottom: scrollToBottom,
              element: wrapperRef.current,
            }}
          >
            <InfiniteScrollList
              loading={loading}
              hasNextPage={hasNextPage}
              loadNextPage={loadNextPage}
              useParent
              isReverse
            >
              {uniqBy([...mapNodes(data.twilioMessages.edges)], (msg) => msg.id)
                .reverse()
                .map((message, idx) => (
                  <Message
                    key={message.id}
                    message={message}
                    setIsThreadExpired={setIsThreadExpired}
                    chat={chat}
                    isMostRecentMessage={mostRecentMessage.id === message.id}
                    firstInPage={reversedIdx[idx] % chatPageSize === 0}
                  />
                ))}
            </InfiniteScrollList>
          </ScrollPosition.Provider>
        )
      )}
    </Wrapper>
  );
}
