import React, { useState } from 'react';
import { Modal, Button, Form } from 'components';
import PublisherRequest from 'types/PublisherRequest';
import { useIntl } from 'hooks';
import styled from 'styled-components';
import { grid, colors } from 'styles/theme';
import PublisherAvatar from 'components/PublisherAvatar';

interface Props {
  isOpen: boolean;
  onRequestClose: () => void;
  requests: PublisherRequest[];
}

const RequestList = styled.div`
  margin: 0 -${grid(3)} ${grid(1)};
  border-bottom: 1px solid ${colors.border};
  max-height: 40vh;
  overflow-y: auto;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${grid(1)};
  padding: 0 ${grid(3)};

  > div {
    display: flex;
    align-items: center;

    &:first-child {
      color: ${colors.darkText};
    }
  }
`;

const AvatarWrapper = styled.div`
  flex-shrink: 0;
  margin-right: ${grid(1)};
  width: ${grid(3.5)};
  height: ${grid(3.5)};
`;

const Actions = styled.div`
  margin-bottom: -${grid(2)};
  text-align: right;
`;

export default function RequestListModal(props: Props) {
  const { isOpen, onRequestClose, requests } = props;
  const { t } = useIntl();
  const heading = t('RequestListModal__Heading', { count: requests.length });

  let visibleRequests = requests;
  const [filter, setFilter] = useState('');
  if (filter) {
    const pattern = new RegExp(filter, 'i');
    visibleRequests = requests.filter(
      (r) => r.publisher.name && r.publisher.name.match(pattern)
    );
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel={heading}
      renderHeading={heading}
    >
      <Form.Field>
        <Form.TextInput
          value={filter}
          onChange={(e) => setFilter(e.target.value)}
          placeholder={t('RequestListModal__Filter')}
        />
      </Form.Field>

      <RequestList className="hide-scrollbar">
        {visibleRequests.map((request) => (
          <Row key={request.id}>
            <div>
              <AvatarWrapper>
                <PublisherAvatar publisher={request.publisher} />
              </AvatarWrapper>
              {request.publisher.name}
            </div>
          </Row>
        ))}
      </RequestList>

      <Actions>
        <Button inlineBlock cancel onClick={onRequestClose}>
          {t('Global__Close')}
        </Button>
      </Actions>
    </Modal>
  );
}
