import { RadialProgress } from 'components';
import { formatFileSize } from 'helpers/formatNumber';
import { useIntl } from 'hooks';
import { IconCheckCircle, IconRemove, IconWarning } from 'icons';
import React from 'react';
import { useDispatch } from 'react-redux';
import { cancelUpload } from 'redux/ducks/fileUploads';
import { getUploadProgress } from 'redux/ducks/uploadProgress';
import { useAppSelector } from 'redux/store';
import FileUpload from 'types/FileUpload';
import MediaUploadThumbnail from './MediaUploadThumbnail';

interface Props {
  fileUpload: FileUpload;
}

export default function MediaUploadUploadRow({ fileUpload }: Props) {
  const dispatch = useDispatch();
  const progress = useAppSelector((state) =>
    getUploadProgress(state, fileUpload.id)
  );

  const { t } = useIntl();

  const handleCancel = () => {
    dispatch(cancelUpload(fileUpload.id));
  };

  return (
    <div
      data-qa="media-upload-modal-upload-row-file-list"
      className="mb-2 flex items-center last:mb-0"
    >
      <div className="mr-2">
        <MediaUploadThumbnail
          contentType={fileUpload.contentType}
          filename={fileUpload.filename}
          previewUrl={fileUpload.previewUrl}
        />
      </div>

      <div className="mr-3 flex-1 truncate text-left text-16 text-dark">
        {fileUpload.filename}
      </div>

      <div className="flex flex-shrink-0 items-center justify-end text-16">
        {fileUpload.status === 'uploading' && progress && progress < 100 ? (
          <>
            <div className="mr-2 text-dark">
              {formatFileSize(fileUpload.fileSize * (progress / 100))}/
              {formatFileSize(fileUpload.fileSize)}
            </div>

            <RadialProgress size={20} progress={progress} />
          </>
        ) : (fileUpload.status === 'uploading' && !progress) ||
          fileUpload.status === 'waiting' ? (
          <>
            <div
              data-qa="media-upload-modal-upload-row-queued-text"
              className="mr-2 text-bodyText"
            >
              {t('MediaUpload__Queued')}
            </div>
            <button
              type="button"
              className="mr-1 h-2 w-2"
              onClick={handleCancel}
            >
              <IconRemove className="h-full w-full text-grey6 hover:text-grey5" />
            </button>
          </>
        ) : fileUpload.status === 'canceled' ? (
          <div>{t('MediaUpload__Canceled')}</div>
        ) : fileUpload.status === 'error' ? (
          <>
            <div
              data-qa="media-upload-modal-upload-row-failed-text"
              className="mr-2 text-dark"
            >
              {t('MediaUpload__Failed')}
            </div>

            <IconWarning className="h-2.5 w-2.5 text-error" />
          </>
        ) : progress === 100 && fileUpload.attachmentStatus !== 'processed' ? (
          <>
            <div
              data-qa="media-upload-modal-upload-row-processing-text"
              className="mr-2 text-dark"
            >
              {t('MediaUpload__Processing')}
            </div>

            <RadialProgress size={20} progress="infinite" />
          </>
        ) : (
          <>
            <div
              data-qa="media-upload-modal-upload-row-finished-text"
              className="mr-2 text-dark"
            >
              {t('MediaUpload__Finished')}
            </div>

            <IconCheckCircle className="h-2.5 w-2.5 text-green" />
          </>
        )}
      </div>
    </div>
  );
}
