import React, { useState } from 'react';
import { Tooltip } from 'components';
import { IconLoader } from 'icons';
import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';
import Suggester from 'types/Suggester';
import { convertBytesToSize } from 'helpers';
import { useCurrentUser } from 'hooks';
import { useIntl } from 'hooks';

const SUGGESTER_STORAGE_QUERY = gql`
  query SuggesterStorageQuery($id: ID!) {
    suggester(id: $id) {
      id
      mediaStorageInBytes
      children {
        id
        mediaStorageInBytes
      }
    }
  }
`;

interface QueryData {
  suggester: Suggester;
}

function MediaLibraryStorage() {
  const { t } = useIntl();

  const currentUser = useCurrentUser();

  const [totalMediaStorage, setTotalMediaStorage] = useState(0);

  const { loading } = useQuery<QueryData>(SUGGESTER_STORAGE_QUERY, {
    variables: {
      id: currentUser.currentSuggester?.id || 0,
    },
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (!!data.suggester) {
        const suggesterStorage = data.suggester.mediaStorageInBytes;
        if (!!data.suggester.children.length) {
          const childSuggestersMediaStorage = data?.suggester.children.reduce(
            (a, b) => a + b.mediaStorageInBytes,
            0
          );
          setTotalMediaStorage(suggesterStorage + childSuggestersMediaStorage);
        } else {
          setTotalMediaStorage(suggesterStorage);
        }
      }
    },
  });

  return (
    <Tooltip
      content={t('MediaLibrary__Storage--UpdateTime')}
      theme="socialieDark"
    >
      <div className="mr-1 flex h-[34px] items-center justify-center space-x-1 rounded bg-white px-1.75 last:mr-0">
        {loading ? (
          <IconLoader className="h-1.5 w-1.5" />
        ) : (
          <span className="text-12 font-bold text-grey4">
            {t('MediaLibrary__Storage', {
              storageUsed: convertBytesToSize(totalMediaStorage),
            })}
          </span>
        )}
      </div>
    </Tooltip>
  );
}

export default MediaLibraryStorage;
