import React from 'react';
import styled from 'styled-components';
import { Switch, Route } from 'react-router-dom';
import routes from 'helpers/routes';
import { useIntl } from 'hooks';
import { colors, grid } from 'styles/theme';
import { media } from 'styles';

const Wrapper = styled.div`
  margin-left: ${grid(1)};
  color: ${colors.white};
  font-size: 16px;

  ${media.tablet`
    display: none;
  `};
`;

export default function NavBarTitle() {
  const { t } = useIntl();
  return (
    <Wrapper>
      <Switch>
        <Route
          path={routes.tracking.root}
          render={() => t('NavBarTitle__Tracking')}
        />
        <Route
          path={routes.publishers.root}
          render={() => t('NavBarTitle__Publishers')}
        />
        <Route
          path={routes.mediaLibrary.root}
          render={() => t('NavBarTitle__MediaLibrary')}
        />
        <Route
          path={routes.reports.index}
          render={() => t('NavBarTitle__Reports')}
        />
        <Route
          path={routes.settings.index}
          render={() => t('NavBarTitle__Settings')}
        />
      </Switch>
    </Wrapper>
  );
}
