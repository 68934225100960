import React, { useRef } from 'react';
import Dropzone, { DropFilesEventHandler } from 'react-dropzone';
import { IconUpload } from 'icons';
import { useIntl } from 'hooks';
import styled from 'styled-components';
import { colors, grid } from 'styles/theme';
import { FormattedMessage } from 'react-intl';
import { State } from '../../brandReducer';

const Inner = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  transition: border-color 0.15s;
  border: 2px dashed ${colors.grey6};
  border-radius: 50%;
  width: ${grid(21)};
  height: ${grid(21)};
  svg {
    transform: translateY(0px);
    transition: transform 0.15s cubic-bezier(0.445, 0.05, 0.55, 0.95);
  }
  &:hover {
    border: 2px dashed ${colors.grey5};
    svg {
      transform: translateY(-4px);
      transition: transform 0.15s cubic-bezier(0.445, 0.05, 0.55, 0.95);
      color: ${colors.grey5};
    }
  }

  > input {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  .Dropzone--is-dropping & {
    border-color: ${colors.socialiePink};
    svg {
      transform: translateY(-4px);
      transition: transform 0.15s cubic-bezier(0.445, 0.05, 0.55, 0.95);
      color: ${colors.socialiePink};
    }
  }
`;

const ImagePreviewWrapper = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  img {
    z-index: 10;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  z-index: 15;
  border-radius: 50%;
  width: ${grid(21)};
  height: ${grid(21)};
  button {
    opacity: 0;
    z-index: 20;
    border-radius: 4px;
    background: white;
    padding: ${grid(0.5)} ${grid(1.5)};
    color: ${colors.darkText};
  }
  :hover {
    /*
    Color is rgba below, instead of imported from colors because of need for
    transparency of the parent while maintaining the opacity of the child
    element. Its the exact equivalent of colors.grey2, opacity of 0.7.
    */
    background-color: rgba(28, 37, 47, 0.7);
    button {
      opacity: 1;
    }
  }
`;

interface Props {
  handleSelectFile: DropFilesEventHandler;
  image: State['image'];
}

export default function BrandLogoUpload({ handleSelectFile, image }: Props) {
  const { t } = useIntl();

  const dropzoneRef = useRef() as any;
  const openBrowseDialog = () => {
    if (dropzoneRef.current) {
      dropzoneRef.current.open();
    }
  };

  const browse = (
    <span className="cursor-pointer text-socialiePink hover:underline">
      <FormattedMessage id="Brand__LogoUpload--Browse" />
    </span>
  );

  return (
    <div className="mb-5 flex flex-1 items-center rounded bg-grey8 py-3 px-4">
      <Dropzone
        // disabled={isUploading}
        ref={dropzoneRef}
        accept={'image/jpeg,image/png'}
        onDrop={handleSelectFile}
        className="mr-3 h-21 w-21 cursor-pointer rounded-full"
        disabledClassName="Dropzone--is-disabled"
        activeClassName="Dropzone--is-dropping"
        rejectClassName="Dropzone--is-rejecting"
      >
        {image.croppedPreviewUrl ? (
          <ImagePreviewWrapper>
            <img
              className="z-10 h-21 w-21 rounded-full bg-white"
              src={image.croppedPreviewUrl}
              alt=""
            />
            <ButtonWrapper>
              <button>Replace</button>
            </ButtonWrapper>
          </ImagePreviewWrapper>
        ) : (
          <Inner>
            <div className="flex flex-col items-center">
              <IconUpload className="w-6 text-grey6" />
              <div className="text-semibold max-w-14 text-center leading-20 text-dark">
                <FormattedMessage
                  id="Brand__LogoUpload--UploadText"
                  values={{ browse }}
                />
              </div>
            </div>
          </Inner>
        )}
      </Dropzone>
      <div className="flex items-center">
        <div className="flex flex-col">
          <div className="flex">
            <p className="mr-1 font-bold text-dark">
              {t('Brand__LogoUpload--Label')}
            </p>
            {!!image.previewUrl && (
              <p
                onClick={() => openBrowseDialog()}
                className="cursor-pointer text-socialiePink hover:underline"
              >
                {t('Global__Edit')}
              </p>
            )}
          </div>
          <p className="text-grey-6">{t('Brand__LogoUpload--SecondaryText')}</p>
        </div>
      </div>
    </div>
  );
}
