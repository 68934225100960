import * as UPDATE_ACCOUNT_CONFIGURATION from 'graphql/mutations/updateAccountConfiguration.graphql';
import apolloClient from '../apolloClient';
import AccountConfiguration from 'types/AccountConfiguration';

const updateAccountConfiguration = async (
  id: number,
  values: Partial<AccountConfiguration>
) => {
  const { errors, data } = await apolloClient.mutate({
    mutation: UPDATE_ACCOUNT_CONFIGURATION,
    variables: { id, values },
  });

  if (errors) return { errors };
  if (data && data.updateAccountConfiguration.errors) {
    return { errors: JSON.parse(data.updateAccountConfiguration.errors) };
  }
  if (data && data.updateAccountConfiguration) {
    return data.updateAccountConfiguration;
  }
  return null;
};

export default updateAccountConfiguration;
