import React from 'react';

interface Props {
  contentType?: string;
  previewUrl?: string;
  filename: string;
}

export default function MediaUploadThumbnail(fileUpload: Props) {
  const thumbnailClassName = 'block h-5 w-5 rounded-lg bg-greyBg object-cover';

  return fileUpload.contentType?.match('video') ? (
    <video
      playsInline
      muted
      autoPlay
      className={thumbnailClassName}
      onLoadedMetadata={(e) => {
        // show a "poster" on iOS devices
        const video = e.currentTarget;
        video.pause();
        video.currentTime = 0.1;
      }}
    >
      <source
        src={fileUpload.previewUrl}
        type={fileUpload.contentType.replace('quicktime', 'mp4')}
      />
    </video>
  ) : (
    <img
      src={fileUpload.previewUrl}
      alt={fileUpload.filename}
      className={thumbnailClassName}
    />
  );
}
