import React from 'react';
import styled from 'styled-components';
import { grid, colors } from 'styles/theme';
import Recipient from 'types/Recipient';
import { FormattedMessage } from 'react-intl';

interface Props {
  recipient: Recipient;
  onSelect: () => void;
}

const Wrapper = styled.div`
  display: flex;
  cursor: pointer;
  padding: ${grid(0.5)} ${grid(1.5)};
  color: ${colors.darkText};
  font-size: 15px;

  &:hover {
    background: ${colors.greyBg};
  }

  &:last-child {
    margin-bottom: ${grid(0.5)};
  }

  span {
    margin-left: ${grid(0.5)};
  }
`;

export default class PublisherRow extends React.Component<Props> {
  render() {
    const { recipient, onSelect } = this.props;
    const { publisherTag } = recipient;
    if (!publisherTag) return null;

    return (
      <Wrapper onClick={onSelect}>
        {publisherTag.name}
        {publisherTag.publishers && (
          <span>
            (
            <FormattedMessage
              id="RecipientsSearch__PublisherTagPublishersCount"
              values={{ count: publisherTag.publishers.length }}
            />
            )
          </span>
        )}
      </Wrapper>
    );
  }
}
