import React from 'react';
import styled from 'styled-components';
import { grid } from 'styles/theme';
import { PlatformIcon, Tooltip } from 'components';
import { useIntl } from 'hooks';
import { platformTitle } from 'helpers';

interface Props {
  platform: string;
  isEnabled: boolean;
  invalidReason?: string;
  onToggle: () => void;
}

const Wrapper = styled.button`
  opacity: 0.3;
  margin-right: ${grid(1)};
  border-radius: 50%;
  width: ${grid(3)};
  height: ${grid(3)};

  ${(props: any) => props.isEnabled && 'opacity: 1'};
  ${(props: any) => props.isDisabled && 'cursor: not-allowed'};
` as any;

export default function PlatformToggle(props: Props) {
  const { t } = useIntl();
  const { platform, isEnabled, invalidReason } = props;
  const isInvalid = !!invalidReason;
  const isDisabled = isInvalid && !isEnabled;
  const onToggle = isDisabled ? () => {} : props.onToggle;
  const platformIcon = <PlatformIcon platformId={platform} branded />;

  const invalidMsg =
    invalidReason && `PlatformToggle__Invalid--${invalidReason}`;

  return (
    <Wrapper onClick={onToggle} isEnabled={isEnabled} isDisabled={isDisabled}>
      {invalidMsg ? (
        <Tooltip content={t(invalidMsg, { platform: platformTitle(platform) })}>
          {platformIcon}
        </Tooltip>
      ) : (
        platformIcon
      )}
    </Wrapper>
  );
}
