import { RootState } from '.';

export enum TypeKeys {
  UPDATE = 'uploadProgress/UPDATE',
}

interface UpdateAction {
  type: TypeKeys.UPDATE;
  payload: {
    uploadId: string;
    progress: number;
  };
}

type ActionTypes = UpdateAction;

export interface State {
  [key: string]: number;
}

const uploadProgressReducer = (state: State = {}, action: ActionTypes) => {
  if (action.type === TypeKeys.UPDATE) {
    return { ...state, [action.payload.uploadId]: action.payload.progress };
  }

  return state;
};

// Action Creators
export const updateUploadProgress = (uploadId: string, progress: number) => ({
  type: TypeKeys.UPDATE,
  payload: { uploadId, progress },
});

// Selectors
export const getUploadProgress = (state: RootState, uploadId: string) =>
  state.uploadProgress[uploadId] || 0;

export default uploadProgressReducer;
