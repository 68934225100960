import React from 'react';
import styled from 'styled-components';
import { colors } from 'styles/theme';

const Wrapper = styled.div`
  color: ${colors.darkText};

  ${({ theme }: any) => theme.color === 'dark' && `color: ${colors.white}`};
`;

const SingleValue = (props: any) => {
  const { data } = props;
  return (
    <Wrapper>{data.label.replace && data.label.replace('NEW__', '')}</Wrapper>
  );
};

export default SingleValue;
