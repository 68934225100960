import React from 'react';
import Attachment from 'types/Attachment';
import InfoCard from './InfoCard';
import { useIntl } from 'hooks';
import ContentRoutingRuleJoinList from './ContentRoutingRuleJoinList';

interface Props {
  attachment: Attachment;
}

export default function ContentRoutingRules({ attachment }: Props) {
  const { t } = useIntl();
  if (!attachment.contentRoutingRuleAttachments?.length) return null;

  return (
    <InfoCard title={t('AttachmentModal__ContentRoutingRules')}>
      <ContentRoutingRuleJoinList
        joins={attachment.contentRoutingRuleAttachments}
      />
    </InfoCard>
  );
}
