import React from 'react';

interface Props {
  isActive: boolean;
  children: React.ReactNode;
  onToggle: () => void;
  activeClassName?: string;
  inactiveClassName?: string;
}

const Button: React.SFC<Props> = ({
  isActive,
  activeClassName,
  inactiveClassName,
  children,
  onToggle,
}: Props) => (
  <button
    className={`border-default mb-0.5 mr-0.5 rounded px-2  py-1 leading-tight last:mr-0  ${
      isActive ? activeClassName || '' : inactiveClassName || ''
    }`}
    onClick={onToggle}
    data-qa={'toggle-' + children}
  >
    {children}
  </button>
);

Button.defaultProps = {
  activeClassName: 'border-darkestGreyBg bg-darkestGreyBg text-white',
  inactiveClassName: 'bg-white text-dark',
};

export default Button;
