import includes from 'lodash/includes';
import Attachment from 'types/Attachment';
import FileUpload from 'types/FileUpload';

interface Suggestion {
  id: string | number;
  attachments: Attachment[];
}

export default function omitFailedUploads<T extends Suggestion>(
  suggestions: T[],
  fileUploads?: FileUpload[]
) {
  return suggestions.filter((suggestion) => {
    const hasFileUpload =
      fileUploads && fileUploads.find((f) => f.suggestionId === suggestion.id);

    const hasAttachment =
      suggestion.attachments[0] &&
      !includes(
        ['processing_failed', 'unprocessed'],
        suggestion.attachments[0].status
      );

    return hasFileUpload || hasAttachment;
  });
}
