import uniq from 'lodash/uniq';
import without from 'lodash/without';
import { OtherAction } from '../global';

export type State = string[];

enum ActionTypes {
  START = 'ui/activeMediaDownloads/START',
  STOP = 'ui/activeMediaDownloads/STOP',
}

interface StartAction {
  type: ActionTypes.START;
  payload: { requestSource: string };
}

interface StopAction {
  type: ActionTypes.STOP;
  payload: { requestSource: string };
}

type Action = StartAction | StopAction | OtherAction;

const activeMediaDownloadsReducer = (
  state: State = [],
  action: Action
): State => {
  switch (action.type) {
    case ActionTypes.STOP:
      return without(state, action.payload.requestSource);
    case ActionTypes.START:
      return uniq([...state, action.payload.requestSource]);
    default:
      return state;
  }
};

// Actions
export const startMediaLoading = (requestSource: string) => ({
  type: ActionTypes.START,
  payload: { requestSource },
});

export const stopMediaLoading = (requestSource: string) => ({
  type: ActionTypes.STOP,
  payload: { requestSource },
});

export default activeMediaDownloadsReducer;
