import React from 'react';
import styled from 'styled-components';
import { grid } from 'styles/theme';
import { Pill } from './components';

interface Props {
  children?: React.ReactNode;
  right?: boolean;
  tight?: boolean;
}

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  ${(props: any) => props.right && 'justify-content: flex-end'};

  > * {
    margin-right: ${grid(2)};
    margin-bottom: ${grid(1)};
    ${(props: any) => props.tight && `margin-right: ${grid(1)};`};
  }
` as any;

export default class Pills extends React.Component<Props> {
  public static Pill = Pill;

  render() {
    const { children, ...styleProps } = this.props;
    return <Wrapper {...styleProps}>{this.props.children}</Wrapper>;
  }
}
