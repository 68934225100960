import React, { useState } from 'react';
import { OAuthPlatforms } from 'helpers/auth/types';
import { platformIcons, IconLoader } from 'icons';
import { FormattedMessage } from 'react-intl';
import performLogin from 'helpers/auth/performLogin';
import { colors } from 'styles/theme';

interface Props {
  platform: OAuthPlatforms;
  pathForSuccessfulRedirect: string;
}

export default function LinkAccountButton({
  platform,
  pathForSuccessfulRedirect,
}: Props) {
  const [isLinking, setIsLinking] = useState(false);

  const linkAccount = () => {
    setIsLinking(true);
    performLogin({
      platformId: platform,
      successRedirect: pathForSuccessfulRedirect,
    });
  };

  const PlatformIcon = platformIcons[platform];
  const iconClassNames = 'bump-down-1 mr-1 w-2 h-2';

  return (
    <button
      className="h-4 rounded px-2 text-white disabled:opacity-50"
      onClick={linkAccount}
      disabled={isLinking}
      style={{ backgroundColor: colors[platform] }}
    >
      <span className="flex h-full items-center justify-center">
        {isLinking ? (
          <IconLoader className={iconClassNames} />
        ) : (
          <PlatformIcon className={iconClassNames} />
        )}
        <FormattedMessage id={`LinkedAccounts__Link--${platform}`} />
      </span>
    </button>
  );
}
