import React from 'react';
import FilterPill from './FilterPill';
import { useIntl } from 'hooks';

interface Props {
  value: number[];
  suggesters: {
    id: number;
    accountName: string;
    accountConfiguration: {
      logoUrl?: string;
    };
  }[];
  msg?: string;
  onRemove: (id: number) => void;
}

export default function SuggesterFilterPills(props: Props) {
  const { value, suggesters, msg, onRemove } = props;
  const { t } = useIntl();

  return (
    <>
      {value.map((id) => {
        const suggester = suggesters.find((s) => Number(s.id) === Number(id));
        if (!suggester) return null;
        const label = msg
          ? t(msg, { accountName: suggester.accountName })
          : suggester.accountName;

        return (
          <FilterPill
            key={id}
            image={suggester.accountConfiguration.logoUrl}
            label={label}
            onRemove={() => onRemove(id)}
          />
        );
      })}
    </>
  );
}
