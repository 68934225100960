import React from 'react';
import styled from 'styled-components';
import { grid, colors } from 'styles/theme';
import { FormattedMessage } from 'react-intl';
import { Form } from 'components';

interface Props {
  value: string;
  onChange: (value: string) => void;
}

const Wrapper = styled.div`
  display: flex;
  border-radius: 4px;
  background-color: ${colors.greyBg};
  height: ${grid(4.5)};
`;

// prettier-ignore
const Item = styled.button.attrs({ type: 'button' })`
  flex: 1;
  border: 2px solid ${colors.greyBg};
  border-radius: 4px;
  background-color: ${colors.greyBg};
  text-align: center;
  color: ${colors.darkText};

  ${(props: any) => props.active && `
    border: 2px solid ${colors.socialiePink};
    background-color: ${colors.white};
  `}
` as any;

const Gender = ({ value, onChange }: Props) => (
  <Form.Field>
    <Form.Label>
      <FormattedMessage id="GeotargetingModal__Label--gender" />
    </Form.Label>

    <Wrapper>
      <Item onClick={() => onChange('All')} active={value === 'All'}>
        <FormattedMessage id="GeotargetingModal__Gender--all" />
      </Item>

      <Item onClick={() => onChange('Men')} active={value === 'Men'}>
        <FormattedMessage id="GeotargetingModal__Gender--men" />
      </Item>

      <Item onClick={() => onChange('Women')} active={value === 'Women'}>
        <FormattedMessage id="GeotargetingModal__Gender--women" />
      </Item>
    </Wrapper>
  </Form.Field>
);

export default Gender;
