import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { FlyoutMenu } from '../..';
import { grid, colors } from 'styles/theme';
import { media } from 'styles';
import { IconPlusBranded, IconSuggestion, IconContentRequest } from 'icons';
import { routes, routeFor } from 'helpers';

interface Props {
  closeChat: () => void;
}

const Wrapper = styled.div`
  ${media.tablet`
    margin-right: ${grid(2)};
  `};
`;

const NewButton = styled.button`
  height: ${grid(4)};
  line-height: ${grid(2.5)};
  color: ${colors.white};

  > span {
    display: flex;
    align-items: center;
  }

  svg {
    width: ${grid(2)};
    height: ${grid(2)};
  }

  ${media.laptop`
    background-color: ${colors.grey3};
    border-radius: 4px;
    padding: 0 ${grid(1.5)};

    &:hover,
    &:focus {
      background-color: ${colors.grey4};
    }
  `};
`;

const FormattedMessageWrapper = styled.div`
  display: none;
  margin-left: ${grid(1)};

  ${media.desktop`
    display: block;
  `};
`;

const Option = styled.span`
  display: flex;
  align-items: center;
  color: ${colors.darkText};
  padding: ${grid(1)} ${grid(1)};

  svg {
    position: relative;
    top: 1px;
    margin-right: ${grid(1)};
    width: ${grid(2)};
    height: ${grid(2)};
    color: ${colors.lightText};

    a:hover & {
      color: ${colors.darkText};
    }
  }
`;

const NewMenu = ({ closeChat }: Props) => (
  <Wrapper>
    <FlyoutMenu
      placement="bottom-end"
      renderButton={(onClick) => (
        <NewButton
          data-qa="new-menu-create-suggestion-button"
          onClick={onClick}
        >
          <span>
            <IconPlusBranded />
            <FormattedMessageWrapper>
              <FormattedMessage id="NewMenu__New" />
            </FormattedMessageWrapper>
          </span>
        </NewButton>
      )}
    >
      <Link to={routes.draftBuilder.index} onClick={closeChat}>
        <Option>
          <IconSuggestion />
          <FormattedMessage id="ActionableType--Suggestion" />
        </Option>
      </Link>

      <Link
        to={routeFor(routes.draftBuilder.contentRequest, 'new')}
        onClick={closeChat}
      >
        <Option>
          <IconContentRequest />
          <FormattedMessage id="ActionableType--ContentRequest" />
        </Option>
      </Link>
    </FlyoutMenu>
  </Wrapper>
);

export default NewMenu;
