import React from 'react';
import moment from 'moment-timezone';
import { DateRangeInput, Form } from 'components';
import DateFilterFields from 'types/DateFilterFields';
import { FormattedMessage } from 'react-intl';

interface Props {
  onChange: (updates: Partial<DateFilterFields>) => void;
  sentDate: DateFilterFields;
}

const SentDateFilter = ({ onChange, sentDate }: Props) => (
  <Form.Field>
    <Form.Label bold>
      <FormattedMessage id="TrackingSentDateFilter__Label" />
    </Form.Label>

    <DateRangeInput onChange={onChange} {...sentDate} maxDate={moment()} />
  </Form.Field>
);

export default SentDateFilter;
