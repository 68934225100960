import React from 'react';
import isEqual from 'lodash/isEqual';
import styled from 'styled-components';
import { Query } from '@apollo/react-components';
import { connect } from 'react-redux';
import {
  startBatchSuggestionSaveOperation,
  stopBatchSuggestionSaveOperation,
} from 'redux/ducks/ui';
import CampaignType from 'types/Campaign';
import Suggestion from 'types/Suggestion';
import SelectOption from 'types/SelectOption';
import { Form } from 'components';
import * as CAMPAIGNS from 'graphql/queries/campaigns.graphql';
import setSuggestionCampaign from 'graphql/operations/setSuggestionCampaign';
import { Context as IsMobileContext } from 'hooks/useIsMobile';
import WithIntl from 'components/WithIntl';
import { IconCampaign } from 'icons';
import { grid } from 'styles/theme';

interface OwnProps {
  suggestions: Suggestion[];
}

interface ConnectedActions {
  startSaving: (operation: string) => void;
  stopSaving: (operation: string) => void;
}

type Props = OwnProps & ConnectedActions;

interface State {
  value?: SelectOption;
}

const Wrapper = styled.div`
  margin-bottom: ${grid(1)};
`;

interface QueryData {
  campaigns: CampaignType[];
}

const campaignToOption = (c?: CampaignType) =>
  c ? { label: c.name, value: c.id } : undefined;

class Campaign extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { value: this.getInitialValue() };
  }

  componentDidUpdate(prevProps: Props) {
    const prevIds = prevProps.suggestions.map((s) => s.id);
    const currentIds = this.props.suggestions.map((s) => s.id);
    if (!isEqual(prevIds, currentIds)) {
      this.setState({ value: this.getInitialValue() });
      return;
    }

    // Fires when we create a new campaign and then get it back from the server
    if (isEqual(prevIds, currentIds) && currentIds.length === 1) {
      const suggestion = this.props.suggestions[0];
      const prevSuggestion = prevProps.suggestions[0];
      const newValue = campaignToOption(suggestion.campaign);
      const oldValue = campaignToOption(prevSuggestion.campaign);
      if (
        !isEqual(newValue, oldValue) &&
        !isEqual(newValue, this.state.value)
      ) {
        this.setState({ value: newValue });
      }
    }
  }

  getInitialValue = () => {
    const { suggestions } = this.props;
    return suggestions.length === 1
      ? campaignToOption(this.props.suggestions[0].campaign)
      : undefined;
  };

  onChange = async (value?: SelectOption | SelectOption[]) => {
    this.props.startSaving('campaign');
    const campaign = Array.isArray(value) ? undefined : value;
    this.setState({ value: campaign });
    const suggestionIds = this.props.suggestions.map((s) => s.id);
    await setSuggestionCampaign(suggestionIds, campaign && campaign.value);
    this.props.stopSaving('campaign');
  };

  render() {
    const { suggestions } = this.props;
    const { value } = this.state;

    return (
      <Wrapper>
        <WithIntl>
          {(_, t) => (
            <Query<QueryData> query={CAMPAIGNS}>
              {({ data, loading, error }) => {
                const options =
                  data && data.campaigns
                    ? data.campaigns.map((t) => ({
                        label: t.name,
                        value: t.id,
                      }))
                    : [];

                const loadedPlaceholder =
                  suggestions.length === 1
                    ? t('SuggestionForm__CampaignsPlaceholder')
                    : t('SuggestionForm__CampaignsMultiPlaceholder', {
                        count: suggestions.length,
                      });

                const placeholder = loading
                  ? t('Global__CampaignsLoading')
                  : loadedPlaceholder;

                return (
                  <IsMobileContext.Consumer>
                    {({ isMobile }) => (
                      <Form.Select
                        data-qa="suggestion-form-campaign-select-campaign"
                        creatable
                        complexValues
                        isClearable={!isMobile}
                        options={options}
                        value={!loading && value}
                        onChange={this.onChange}
                        placeholder={placeholder}
                        icon={<IconCampaign />}
                        noOptionsMessage={() =>
                          t('SuggestionForm__CampaignsNoResults')
                        }
                        pillProps={{ color: 'white' }}
                        formatCreateLabel={(label) =>
                          t('SuggestionForm__CreateCampaignPrompt', {
                            label,
                          })
                        }
                      />
                    )}
                  </IsMobileContext.Consumer>
                );
              }}
            </Query>
          )}
        </WithIntl>
      </Wrapper>
    );
  }
}

const mapDispatchToProps = {
  startSaving: startBatchSuggestionSaveOperation,
  stopSaving: stopBatchSuggestionSaveOperation,
};

export default connect<OwnProps, ConnectedActions>(
  null,
  mapDispatchToProps
)(Campaign);
