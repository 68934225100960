import React from 'react';
import uniqBy from 'lodash/uniqBy';
import { RenderIfVisible } from 'components';
import MediaLibraryBuilderModalAttachment from './MediaLibraryBuilderModalAttachment';
import AttachmentType from 'types/Attachment';

interface Props {
  attachments: AttachmentType[];
}

function MediaLibraryBuilderModalAttachmentsList(props: Props) {
  const { attachments } = props;

  return (
    <div className="AttachmentListMediaLibraryBuilder">
      {uniqBy(attachments, 'id').map((attachment) => (
        <RenderIfVisible
          key={attachment.id}
          defaultHeight={240}
          wrapperProps={{
            className: 'AttachmentThumbnail group',
          }}
          renderPlaceholder={() => <></>}
        >
          <MediaLibraryBuilderModalAttachment
            key={attachment.id}
            attachment={attachment}
          />
        </RenderIfVisible>
      ))}
    </div>
  );
}

export default MediaLibraryBuilderModalAttachmentsList;
