import PhoneNumber from 'awesome-phonenumber';

type Styles = 'international' | 'e164' | 'national' | 'rfc3966' | 'significant';

const cache = {
  international: {},
  e164: {},
  national: {},
  rfc3966: {},
  significant: {},
};

const formatPhone = (phone: string, style: Styles = 'international') => {
  if (cache[style][phone]) return cache[style][phone];

  const pn = new PhoneNumber(phone);
  if (!pn.isValid()) return phone;

  const formatted = pn.getNumber(style);
  cache[style][phone] = formatted;
  return formatted;
};

export default formatPhone;
