import React from 'react';
import { EnhancedNavLink } from 'components';
import { routes } from 'helpers';
import { useIntl } from 'hooks';
import { IconPublished, IconPerformance } from 'icons';

export default function FeedInsightsSubNav() {
  const { t } = useIntl();

  return (
    <div className="border-b-default mb-4.5 flex items-center justify-center">
      <Link icon={IconPublished} to={routes.feed.index}>
        {t('FeedInsightsSubNav__Feed')}
      </Link>
      <Link icon={IconPerformance} to={routes.feed.insights}>
        {t('FeedInsightsSubNav__Insights')}
      </Link>
    </div>
  );
}

interface LinkProps {
  children: React.ReactNode;
  to: React.ComponentProps<typeof EnhancedNavLink>['to'];
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
}

function Link({ children, to, icon }: LinkProps) {
  return (
    <EnhancedNavLink
      to={to}
      exact
      className="bump-down-1 mr-4 h-5 px-1 text-16 font-semibold last:mr-0 hover:text-socialiePink"
      inactiveClassName="text-bodyText border-0 border-b-2 border-transparent border-solid"
      activeClassName="text-socialiePink border-0 border-b-2 border-socialiePink border-solid"
    >
      <div className="flex items-center">
        {React.createElement(icon, {
          className: 'block bump-down-1 mr-1 w-2.5 h-2.5',
        })}{' '}
        {children}
      </div>
    </EnhancedNavLink>
  );
}
