import { presignedUrl } from 'api/brand';

export const STATUS = {
  WAITING: 'waiting',
  UPLOADING: 'uploading',
  COMPLETE: 'upload complete',
  ERROR: 'error',
  CANCELED: 'canceled',
};

export interface UploaderCallbacks {
  onComplete: (logoId: string) => void;
  onProgress: (progress: number) => void;
  onError: (reason: string) => void;
}

export interface PresignedUrlResponse {
  fields: any;
  id: string;
  url: string;
}

export class LogoUploader {
  logoId?: string;
  status: string;
  xhr?: XMLHttpRequest;

  get filename(): string {
    return this.file.name;
  }

  get contentType(): string {
    return this.file.type;
  }

  constructor(public file: File, public callbacks: UploaderCallbacks) {
    this.status = STATUS.WAITING;
  }

  async upload() {
    this.status = STATUS.UPLOADING;
    const response = await presignedUrl();
    this.logoId = response.id;
    this.uploadToS3(response);
  }

  cancelUpload() {
    this.status = STATUS.CANCELED;
    this.xhr && this.xhr.abort();
  }

  uploadToS3(response: PresignedUrlResponse) {
    this.initializeXhr(response);
    const { fields } = response;
    const formData = new FormData();

    Object.keys(fields).forEach((key) => {
      formData.append(key, fields[key]);
    });

    formData.append('file', this.file, this.file.name);
    this.xhr!.send(formData);
  }

  initializeXhr(response: PresignedUrlResponse) {
    this.xhr = new XMLHttpRequest();
    this.xhr.onreadystatechange = () => this.onReadyStateChange();
    const { upload } = this.xhr;
    upload.onprogress = (event) => this.onProgress(event);
    upload.onerror = (error) => this.onError(error as any);
    this.xhr.open('POST', response.url);
  }

  onReadyStateChange() {
    if (
      this.xhr!.readyState === XMLHttpRequest.DONE &&
      this.xhr!.status >= 200 &&
      this.xhr!.status <= 299
    ) {
      this.status = STATUS.COMPLETE;
      this.callbacks.onComplete(this.logoId!);
    }
  }

  onProgress(event: ProgressEvent) {
    if (event.lengthComputable) {
      const progress = Math.round((100 * event.loaded) / event.total);
      this.callbacks.onProgress(progress);
    }
  }

  onError(error: ErrorEvent) {
    this.status = STATUS.ERROR;
    this.callbacks.onError(error.message);
  }
}
