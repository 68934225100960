import React from 'react';
import styled from 'styled-components';
import { grid, colors } from 'styles/theme';
import { FormattedMessage } from 'react-intl';

interface Option {
  label: string;
  value: any;
}

interface Props {
  value: string;
  options: Option[];
  onChange: (value: any) => void;
  translateLabels?: boolean;
}

const Wrapper = styled.div`
  display: flex;
  border-radius: 4px;
  background-color: ${colors.greyBg};
  height: ${grid(4.5)};
`;

// prettier-ignore
const Item = styled.button.attrs({ type: 'button' })`
  flex: 1;
  border: 2px solid ${colors.greyBg};
  border-radius: 4px;
  background-color: ${colors.greyBg};
  text-align: center;
  color: ${colors.darkText};

  ${(props: any) => props.active && `
    border: 2px solid ${colors.socialiePink};
    background-color: ${colors.white};
  `}
` as any;

const Toggle = ({ value, options, onChange, translateLabels }: Props) => (
  <Wrapper>
    {options.map((option) => (
      <Item
        key={option.value}
        onClick={() => onChange(option.value)}
        active={value === option.value}
      >
        {translateLabels ? (
          <FormattedMessage id={option.label} />
        ) : (
          option.label
        )}
      </Item>
    ))}
  </Wrapper>
);

export default Toggle;
