// Pop a modal if a user is actively using the site and requireMfa is
// turned on for their suggester

import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import socket from 'socket';
import User from 'types/User';
import { Modal, Button } from 'components';
import { routes } from 'helpers';
import { useIntl } from 'hooks';

interface Props {
  user: User;
}

export default function MfaRequiredModal({ user }: Props) {
  const { t } = useIntl();
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const { enableMfa } = user;

  useEffect(() => {
    const openModal = () => {
      if (!enableMfa) setIsOpen(true);
    };
    socket.on('mfaRequired', openModal);

    return () => {
      socket.removeListener('mfaRequired', openModal);
    };
  }, [enableMfa]);

  if (user.enableMfa || location.pathname.match(routes.auth.mfaSetup)) {
    return null;
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => {}}
      theme={{ hideClose: true }}
      contentLabel={t('MfaRequiredModal__Heading')}
      renderHeading={t('MfaRequiredModal__Heading')}
    >
      <div className="mb-2 text-left leading-20">
        {t('MfaRequiredModal__Body')}
      </div>

      <Button href={routes.auth.mfaSetup} inlineBlock filledBg>
        {t('Global__Continue')}
      </Button>
    </Modal>
  );
}
