import React from 'react';
import styled from 'styled-components';
import { grid } from 'styles/theme';

interface Props {
  color: string;
  children: React.ReactNode;
}

const StyledLegendLabel = styled.div`
  display: flex;
  font-size: 14px;

  &:last-child {
    margin-bottom: 0;
  }

  &::before {
    display: block;
    margin-right: ${grid(1)};
    border-radius: 50%;
    width: ${grid(3)};
    height: ${grid(3)};
    background-color: ${(props: any) => props.color};
    content: '';
  }

  > span {
    display: flex;
    align-items: center;
  }
` as any;

const LegendLabel = ({ children, ...styleProps }: Props) => {
  return (
    <StyledLegendLabel {...styleProps}>
      <span>{children}</span>
    </StyledLegendLabel>
  );
};

export default LegendLabel;
