import formatPhone from './formatPhone';

interface ContactMethod {
  protocol: string;
  value?: string;
  to?: string;
}
const getFormattedContactMethod = (contactMethod: ContactMethod) => {
  const { protocol } = contactMethod;
  const value = contactMethod['value'] || contactMethod['to'];
  if (!value) return;
  if (protocol === 'twitter_dm') return `@${value}`;
  if (protocol === 'sms' || protocol === 'whats_app') return formatPhone(value);
  return value;
};

export default getFormattedContactMethod;
