import React, { useContext } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { colors } from 'styles/theme';
import AttachmentType from 'types/Attachment';
import { SelectableContext } from 'screens/MediaLibrary/screens/MediaLibraryIndex';
import { Checkbox } from 'components';
import MediaLibraryBuilderModalAttachmentImage from './MediaLibraryBuilderModalAttachmentImage';

interface Props {
  attachment: AttachmentType;
}

function MediaLibraryBuilderModalAttachment({ attachment }: Props) {
  const { id } = attachment;
  const selectable = useContext(SelectableContext);
  const isSelected = selectable.isSelected(id);
  const isSelectMode = !!selectable.selectedIds.length;
  const theme = { isSelected, isSelectMode };

  const handleSelect = (options: { usingRange: boolean }) => {
    if (options.usingRange) {
      selectable.selectRange(id);
    } else {
      selectable.toggleSelected(id);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Wrapper>
        <div className="img-box">
          <MediaLibraryBuilderModalAttachmentImage
            srcSet={attachment.thumbnail}
            preview={attachment.blurThumbnail}
          />
        </div>
        <GradientContainer />

        <div className="img-box-inset-shadow" />

        <div
          className="selectable-box"
          onClick={(e) => {
            if (!isSelectMode) return;
            handleSelect({ usingRange: e.shiftKey });
          }}
        />

        <Checkbox onClick={(e) => handleSelect({ usingRange: e.shiftKey })} />
      </Wrapper>
    </ThemeProvider>
  );
}

const zIndices = {
  imageBox: 1,
  gradientContainer: 2,
  detailsContainer: 4,
  selectableBox: 5,
  linkBox: 6,
};

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;

  ${({ theme }: any) =>
    theme.isSelected
      ? `
      border: 2px solid${colors.socialiePink};
      border-radius: 2px;
  `
      : `.AttachmentListMediaLibraryBuilder & {
    border: 0;
  }`}

  .img-box,
  .img-box-inset-shadow,
  .border-box,
  .selectable-box {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .img-box {
    .AttachmentListMediaLibraryBuilder & {
      position: static;
      background: ${colors.white};
    }
  }

  .img-box-inset-shadow {
    display: none;
    z-index: ${zIndices['imageBox']};
    ${({ theme }: any) => theme.isSelected && 'display: block'};
  }

  .selectable-box {
    z-index: ${zIndices['selectableBox']};
  }

  .details-container {
    z-index: ${zIndices['detailsContainer']};
  }
`;

export const GradientContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: opacity 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
  opacity: 0;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.2) 0%,
    rgba(0, 0, 0, 0.2) 72%,
    rgba(0, 0, 0, 0.5) 100%
  );
  width: 100%;
  height: 100%;
  z-index: ${zIndices['gradientContainer']};
  content: '';

  .AttachmentThumbnail:hover & {
    opacity: 1;
  }
`;

export default MediaLibraryBuilderModalAttachment;
