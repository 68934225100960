import React from 'react';
import moment from 'moment-timezone';
import { useIntl } from 'hooks';

interface Props {
  date: number;
}

export default function DateHeading({ date }: Props) {
  const { t } = useIntl();
  const momentized = moment(date);
  const now = moment();
  const diff = now.diff(momentized, 'days');
  const formattedDate = momentized.format('MMMM D, YYYY');
  let primaryDate = formattedDate;
  let secondaryDate = '';

  // Show Today, Yesterday, or Day of Week for recent items
  if (diff === 0) {
    primaryDate = t('Global__Today');
    secondaryDate = formattedDate;
  } else if (diff === 1) {
    primaryDate = t('Global__Yesterday');
    secondaryDate = formattedDate;
  } else if (diff < 7) {
    primaryDate = momentized.format('dddd');
    secondaryDate = formattedDate;
  }

  return (
    <div className="flex">
      <div className="mb-1 text-16 font-semibold text-dark">{primaryDate}</div>

      {secondaryDate && (
        <div className="ml-1 text-12 text-light">{secondaryDate}</div>
      )}
    </div>
  );
}
