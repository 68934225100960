import React from 'react';
import { Form } from 'components';
import Tag from 'types/Tag';
import { useIntl } from 'hooks';

interface Props {
  publisherTags: Tag[];
  value: number[];
  onChange: (val: number[]) => void;
  label?: string | null;
}

export default function PublisherTagFilter(props: Props) {
  const { t } = useIntl();
  const {
    publisherTags,
    value,
    onChange,
    label = t('PublisherTagFilter__Label'),
  } = props;

  const options = publisherTags.map((pt: Tag) => ({
    value: pt.id,
    label: pt.name,
  }));

  return (
    <Form.Field>
      {!!label && <Form.Label>{label}</Form.Label>}

      <Form.Select
        isMulti
        onChange={onChange}
        value={value}
        options={options}
        isClearable={false}
        placeholder={t('FeedPublisherTagsFilter__Placeholder')}
      />
    </Form.Field>
  );
}
