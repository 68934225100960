import React from 'react';
import { Form } from 'components';
import { useCurrentUser, useIntl } from 'hooks';
import Suggester from 'types/Suggester';

interface AllProps {
  label: string;
  filterKey: string;
  filter: {};
  updateFilter: (changes: {}) => void;
  onChange: (value: number[]) => void;
  placeholder?: string;
  boldLabel?: boolean;
  renderHr?: boolean;
}

// We want the parent to pass in updateFilter or onChange, not both
type Props = Omit<AllProps, 'updateFilter'> | Omit<AllProps, 'onChange'>;

const buildSuggesterOption = (suggester: Suggester) => ({
  value: suggester.id,
  label: suggester.accountName,
  image: suggester.accountConfiguration.logoUrl,
});

export default function SuggesterFilter(props: Props) {
  const { label, filterKey, filter, placeholder, boldLabel, renderHr } = props;
  const currentUser = useCurrentUser();
  const { t } = useIntl();

  if (!currentUser || !currentUser.currentSuggester) return null;
  const childGroups = currentUser.currentSuggester.children;
  if (!childGroups || !childGroups.length) return null;

  const options = [
    buildSuggesterOption(currentUser.currentSuggester!),
    ...childGroups.map((g) => buildSuggesterOption(g)),
  ];

  return (
    <>
      <Form.Field>
        <Form.Label bold={boldLabel}>{t(label)}</Form.Label>

        <div className="5 mb-0">
          <Form.Select
            isMulti
            onChange={(val) => {
              if ('onChange' in props) {
                props.onChange(val);
              } else {
                props.updateFilter({ [filterKey]: val });
              }
            }}
            value={filter[filterKey]}
            options={options}
            isClearable={false}
            placeholder={t(placeholder || 'SuggesterFilter__Placeholder')}
          />
        </div>
      </Form.Field>

      {renderHr && <hr />}
    </>
  );
}

SuggesterFilter.defaultProps = {
  boldLabel: true,
  renderHr: true,
};
