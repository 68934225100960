import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { Loader } from '..';

interface Props {
  hasNextPage?: boolean;
  loadNextPage: () => void;
  children: React.ReactNode;
  loading?: boolean;
  useParent?: boolean;
  className?: string;
  isReverse?: boolean;
  threshold?: number;
  initialLoad?: boolean;
}

export default function InfiniteScrollList({
  children,
  hasNextPage,
  loadNextPage,
  loading,
  useParent,
  className,
  isReverse,
  initialLoad,
  threshold,
}: Props) {
  return (
    <InfiniteScroll
      pageStart={1}
      loadMore={loading ? () => null : loadNextPage}
      hasMore={hasNextPage}
      initialLoad={initialLoad}
      loader={<Loader key={0} />}
      useWindow={!useParent}
      className={className}
      isReverse={isReverse}
      threshold={threshold || 500}
    >
      {children}
    </InfiniteScroll>
  );
}
