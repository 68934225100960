import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { grid, colors } from 'styles/theme';

interface Props {
  title: string;
}

const StyledHeading = styled.div`
  margin-bottom: ${grid(0.5)};
  background: ${colors.greyBg};
  padding: ${`${grid(1)} ${grid(1.5)}`};
  text-transform: uppercase;
  letter-spacing: 0.92px;
  color: ${colors.lightText};
  font-size: 11px;
  font-weight: 600;
`;

const Heading = ({ title }: Props) => (
  <StyledHeading>
    <FormattedMessage id={title} />
  </StyledHeading>
);

export default Heading;
