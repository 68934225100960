import getDatesFromDateRange from 'helpers/getDatesFromDateRange';
import { TimeSeriesValue } from 'types/TimeSeriesValue';
import moment from 'moment-timezone';

interface DateOptions {
  dateRange: string;
  startDate?: string;
  endDate?: string;
}

const generateDateList = (
  dateOptions: DateOptions,
  timeSeries: TimeSeriesValue = 'day'
): Date[] => {
  const { dateRange, startDate, endDate } = dateOptions;
  const dates = getDatesFromDateRange(dateRange, startDate, endDate);
  const start = dates.start || moment();
  const end = dates.end || moment();

  const labels = [];
  for (const i = moment(start); i.isBefore(end); i.add(1, timeSeries)) {
    const timestamp = moment(i);
    labels.push(timestamp.toDate());
  }

  return labels;
};

export default generateDateList;
