import React from 'react';
import styled, { css } from 'styled-components';
import { grid, colors } from 'styles/theme';
import { IconCheckmark } from 'icons';
import { noPropagate } from 'helpers';

interface Props {
  checked: boolean;
  onChange: () => void;
  disabled?: boolean;
  dash?: boolean;
  dark?: boolean;
  id?: string;
  'data-qa'?: string;
}
// prettier-ignore
const StyledCheckbox = styled.button.attrs({ type: 'button' })`
  position: relative;
  flex-shrink: 0;
  border: 1px solid ${colors.darkBorder};


  border-radius: 2px;
  background: ${colors.white};
  width: ${grid(2)};
  height: ${grid(2)};

  ${(props: any) => props.dark && `
    border-color: ${colors.inverseBorder};
    background: transparent;
  `}

  ${(props: any) => props.disabled && `
    opacity: 0.6;
    cursor: not-allowed;
  `}

  &:after {
    position: absolute;
    top: -${grid(1)};
    right: -${grid(1)};
    bottom: -${grid(1)};
    left: -${grid(1)};
    content: '';
  }

  svg,
  .dash {
    display: block;
    opacity: 0;
    width: 100%;
    height: 100%;
    color: ${colors.white};
  }

  .dash {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    position: relative;
    top: 2px;
    font-weight: bold;
    font-size: 24px;
  }

  ${(props: any) => props.checked && css`
    border-color: ${colors.bodyText};
    background-color: ${colors.bodyText};

    svg,
    .dash {
      opacity: 1;
    }
  `};

  &:hover:not(:disabled),
  label:hover &:not(:disabled),
  .Row:hover &:not(:disabled) {
    border-color: ${colors.bodyText};
  }
` as any;

const Checkbox = ({
  checked,
  onChange,
  disabled,
  dash,
  id,
  'data-qa': dataQA,
  ...styleProps
}: Props) => (
  <StyledCheckbox
    {...styleProps}
    disabled={disabled}
    onClick={noPropagate(onChange)}
    checked={checked}
    id={id}
    data-qa={dataQA}
  >
    {dash ? <div className="dash">-</div> : <IconCheckmark />}
  </StyledCheckbox>
);

export default Checkbox;
