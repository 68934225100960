import React from 'react';
import { Button, Form, Pills } from 'components';
import { useIntl, useValidateOtp } from 'hooks';
import { IconLoader } from 'icons';
import { FormattedMessage } from 'react-intl';
import { Formik } from 'formik';

interface VerifySmsProps {
  previousStep: () => void;
  nextStep: () => void;
  mfaPhone: string;
  protocol: string;
}

const initialValues = {
  oneTimePasscode: '',
};

export default function VerifySms({
  previousStep,
  nextStep,
  protocol,
  mfaPhone,
}: VerifySmsProps) {
  const { t } = useIntl();

  const { validateOtp, loading, isInvalidOtp } = useValidateOtp({
    onCompleted: nextStep,
  });

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values) => {
        validateOtp({
          variables: {
            mfaProtocol: protocol,
            token: values.oneTimePasscode.replace(/\s/g, ''),
          },
        });
      }}
      validateOnBlur={true}
      validateOnChange={false}
    >
      {({ handleSubmit, setFieldValue, values }) => {
        return (
          <Form onSubmit={handleSubmit}>
            <div className="text-left">
              <div className="mb-1 flex text-dark">
                <FormattedMessage
                  id="Security__ManageMfa--Verify-ProtocolSms-Desc"
                  values={{
                    phone: (
                      <span className="bump-down-1">
                        <Pills.Pill>{mfaPhone}</Pills.Pill>
                      </span>
                    ),
                  }}
                />
              </div>
              <div className="mb-3 flex">
                <p className="text-dark">
                  {t('Security__ManageMfa--Verify-ProtocolSms-SubDesc')}
                </p>
              </div>
              <div>
                <p className="mb-0.5 font-bold text-dark">
                  {t('Security__ManageMfa--Verify-ProtocolSms-Label')}
                </p>
                <div className="w-30">
                  <Form.TextInput
                    value={values.oneTimePasscode}
                    autoFocus
                    placeholder={t(
                      'Security__ManageMfa--Verify-ProtocolSms-Placeholder'
                    )}
                    errors={
                      isInvalidOtp
                        ? t(
                            'Security__ManageMfa--Verify-Validation-InvalidCode'
                          )
                        : ''
                    }
                    onChange={(e) =>
                      setFieldValue('oneTimePasscode', e.target.value)
                    }
                  />
                </div>
              </div>
              <div className="mt-3 flex items-center justify-end">
                <div className="mr-1">
                  <Button mono inlineBlock onClick={() => previousStep()}>
                    {t('Global__Back')}
                  </Button>
                </div>
                <Button
                  filledBg
                  inlineBlock
                  type="submit"
                  disabled={loading || values.oneTimePasscode.length < 6}
                >
                  <span className="flex items-center">
                    {loading && <IconLoader className="mr-1 h-2 w-2" />}
                    {t('Global__Next')}
                  </span>
                </Button>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}
