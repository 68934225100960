import React from 'react';
import styled, { css } from 'styled-components';
import { grid, colors } from 'styles/theme';
import { IconCheckmark } from 'icons';
import { noPropagate } from 'helpers';

interface Props extends React.ComponentProps<'button'> {
  checked: boolean;
  onChange?: () => void;
  disabled?: boolean;
  dash?: boolean;
  dark?: boolean;
}

// prettier-ignore
const StyledCheckbox = styled.button.attrs({ type: 'button' })`
  position: relative;
  flex-shrink: 0;
  border: 1px solid ${colors.darkBorder};


  border-radius: 4px;
  background: ${colors.white};
  width: ${grid(2)};
  height: ${grid(2)};

  ${(props: any) => props.dark && `
    border-color: ${colors.inverseBorder};
    background: transparent;
  `}

  ${(props: any) => props.disabled && `
    opacity: 0.6;
    cursor: not-allowed;
  `}

  &:after {
    position: absolute;
    top: -${grid(1)};
    right: -${grid(1)};
    bottom: -${grid(1)};
    left: -${grid(1)};
    content: '';
  }

  svg,
  .dash {
    display: block;
    opacity: 0;
    width: 100%;
    height: 100%;
    color: ${colors.white};
    padding: 1px;
  }

  .dash {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    position: relative;
    top: 2px;
    font-weight: bold;
    font-size: 24px;
  }

  ${(props: any) => props.checked && css`
    border-color: ${colors.socialiePink};
    background-color: ${colors.socialiePink};

    svg,
    .dash {
      opacity: 1;
    }
  `};

  /* &:hover,
  label:hover &,
  .Row:hover & {
    border-color: ${colors.bodyText};
    ${(props: any) => props.disabled && `
      border-color: ${colors.darkBorder};
    `}
  } */
` as any;

const BrandCheckbox = ({
  checked,
  onChange,
  disabled,
  dash,
  ...styleProps
}: Props) => (
  <StyledCheckbox
    {...styleProps}
    disabled={disabled}
    onClick={noPropagate(onChange)}
    checked={checked}
  >
    {dash ? <div className="dash">-</div> : <IconCheckmark />}
  </StyledCheckbox>
);

export default BrandCheckbox;
