import { combineReducers } from 'redux';
import isSidebarOpen, {
  State as IsSidebarOpenState,
  openSidebar,
  closeSidebar,
  toggleSidebar,
} from './isSidebarOpen';
import isMediaUploadModalOpen, {
  closeMediaUploadModal,
  openMediaUploadModal,
  toggleMediaUploadModal,
} from './isMediaUploadModalOpen';

import activeMediaDownloads, {
  State as ActiveMediaDownloads,
  startMediaLoading,
  stopMediaLoading,
} from './activeMediaDownloads';

import batchSuggestionSaveOperations, {
  State as BatchSuggestionSaveOperationsState,
  startBatchSuggestionSaveOperation,
  stopBatchSuggestionSaveOperation,
} from './batchSuggestionSaveOperations';

import isTopWarningBannerOpen, {
  openTopWarningBanner,
  closeTopWarningBanner,
} from './isTopWarningBannerOpen';

export interface State {
  batchSuggestionSaveOperations: BatchSuggestionSaveOperationsState;
  activeMediaDownloads: ActiveMediaDownloads;
  isSidebarOpen: IsSidebarOpenState;
  isMediaUploadModalOpen: ReturnType<typeof isMediaUploadModalOpen>;
  isTopWarningBannerOpen: ReturnType<typeof isTopWarningBannerOpen>;
}

export default combineReducers({
  batchSuggestionSaveOperations,
  isSidebarOpen,
  activeMediaDownloads,
  isMediaUploadModalOpen,
  isTopWarningBannerOpen,
});

export {
  startBatchSuggestionSaveOperation,
  stopBatchSuggestionSaveOperation,
  openSidebar,
  closeSidebar,
  toggleSidebar,
  startMediaLoading,
  stopMediaLoading,
  openMediaUploadModal,
  closeMediaUploadModal,
  toggleMediaUploadModal,
  openTopWarningBanner,
  closeTopWarningBanner,
};
