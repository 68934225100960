import React from 'react';
import { Button, Form } from 'components';
import { useIntl, useValidateOtp } from 'hooks';
import { IconLoader } from 'icons';
import { Formik } from 'formik';

interface VerifyAppProps {
  nextStep: () => void;
  previousStep: () => void;
  protocol: string;
  qrCodeUrl: string;
}

const initialValues = {
  oneTimePasscode: '',
};

export default function VerifyApp({
  nextStep,
  previousStep,
  protocol,
  qrCodeUrl,
}: VerifyAppProps) {
  const { t } = useIntl();

  const { validateOtp, loading, isInvalidOtp } = useValidateOtp({
    onCompleted: nextStep,
  });

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values) => {
        validateOtp({
          variables: {
            mfaProtocol: protocol,
            token: values.oneTimePasscode.replace(/\s/g, ''),
          },
        });
      }}
      validateOnBlur={true}
      validateOnChange={false}
    >
      {({ handleSubmit, setFieldValue, values }) => {
        return (
          <Form onSubmit={handleSubmit}>
            <div className="text-left">
              <div className="mb-2">
                <p className="text-dark">
                  {t(
                    'Security__ManageMfa--ChooseProtocol-ProtocolApp-Configure-Desc'
                  )}
                </p>
              </div>
              <div className="flex justify-center pb-6 pt-4">
                <div className="flex h-26 w-26 items-center">
                  <img className="h-full w-full" src={qrCodeUrl} alt="" />
                </div>
              </div>
              <div className="mb-4 flex justify-center">
                <p className="text-dark">
                  {t('Security__ManageMfa--Verify-ProtocolApp-Desc')}
                </p>
              </div>
              <div>
                <p className="mb-0.5 font-bold text-dark">
                  {t('Security__ManageMfa--Verify-ProtocolApp-Label')}
                </p>
                <div className="w-30">
                  <Form.TextInput
                    value={values.oneTimePasscode}
                    autoFocus
                    errors={
                      isInvalidOtp
                        ? t(
                            'Security__ManageMfa--Verify-Validation-InvalidCode'
                          )
                        : ''
                    }
                    placeholder={t(
                      'Security__ManageMfa--Verify-ProtocolApp-Placeholder'
                    )}
                    onChange={(e) => {
                      setFieldValue('oneTimePasscode', e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="mt-3 flex items-center justify-end">
                <div className="mr-1">
                  <Button mono inlineBlock onClick={() => previousStep()}>
                    {t('Global__Back')}
                  </Button>
                </div>
                <Button
                  filledBg
                  inlineBlock
                  type="submit"
                  disabled={loading || values.oneTimePasscode.length < 6}
                >
                  <span className="flex items-center">
                    {loading && <IconLoader className="mr-1 h-2 w-2" />}
                    {t('Global__Next')}
                  </span>
                </Button>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}
