import React from 'react';
import { connect } from 'react-redux';
import { toggleSidebar } from 'redux/ducks/ui';
import { RootState } from 'redux/ducks';
import Sidebar from './Sidebar';

interface OwnProps {
  children?: React.ReactNode;
}

interface ConnectedState {
  isOpen: boolean;
}

interface ConnectedActions {
  onToggleOpen: () => void;
}

type Props = OwnProps & ConnectedState & ConnectedActions;

const SidebarContainer = (props: Props) => <Sidebar {...props} />;

const mapStateToProps = (state: RootState) => ({
  isOpen: state.ui.isSidebarOpen,
});

const mapDispatchToProps = { onToggleOpen: toggleSidebar };

export default connect(mapStateToProps, mapDispatchToProps)(SidebarContainer);
