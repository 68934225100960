import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Mutation, Query } from '@apollo/react-components';
import Album from 'types/Album';
import { Form, Modal, WithIntl, Loader, ErrorDebug } from '..';
import * as mutation from 'graphql/mutations/addSuggestionAlbum.graphql';
import * as query from 'graphql/queries/suggestionAlbums.graphql';
import Button from '../Button';

interface Props {
  isOpen: boolean;
  onRequestClose: () => void;
  suggestionIds: number | string | (string | number)[];
}

interface State {
  albumIds: (number | string)[];
}

interface QueryData {
  suggestionAlbums: Album[];
}

interface MutationData {
  addSuggestionAlbum: { suggestionAlbum?: Album; errors?: string };
}

class AddSuggestionAlbumModal extends React.Component<Props, State> {
  state = { albumIds: [] };

  updateAlbumIds = (albumIds: (number | string)[]) =>
    this.setState({ albumIds });

  render() {
    const { isOpen, onRequestClose, suggestionIds } = this.props;
    const { albumIds } = this.state;

    const itemCount = Array.isArray(suggestionIds) ? suggestionIds.length : 1;

    return (
      <WithIntl>
        {(_, t) => (
          <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            contentLabel={t('AddSuggestionAlbumModal__Heading')}
            renderHeading={
              <FormattedMessage id="AddSuggestionAlbumModal__Heading" />
            }
          >
            <Query<QueryData> query={query}>
              {({ data, loading, error }) => {
                if (loading) return <Loader />;
                if (error) return <ErrorDebug error={error} />;
                if (!data || !data.suggestionAlbums) return null;

                const albums = data.suggestionAlbums;
                const options = albums.map((t: Album) => ({
                  value: t.id,
                  label: t.name,
                }));

                return (
                  <Mutation<MutationData>
                    mutation={mutation}
                    variables={{ suggestionIds, albumIds }}
                    onCompleted={(data) => {
                      if (!data.addSuggestionAlbum.errors) {
                        this.updateAlbumIds([]);
                        onRequestClose();
                      }
                    }}
                    refetchQueries={['Albums']}
                  >
                    {(addSuggestionAlbum, { loading: isAdding }) => (
                      <Form onSubmit={addSuggestionAlbum}>
                        <Form.Field>
                          {itemCount > 1 && (
                            <Form.Label>
                              <FormattedMessage
                                id="AddSuggestionAlbumModal__Description"
                                values={{ itemCount }}
                              />
                            </Form.Label>
                          )}

                          <div data-testid="album-select">
                            <Form.Select
                              isMulti
                              value={this.state.albumIds}
                              onChange={this.updateAlbumIds}
                              creatable
                              placeholder={t(
                                'AddSuggestionAlbumModal__Placeholder'
                              )}
                              options={options}
                              isClearable={false}
                              formatCreateLabel={(label) =>
                                t('AddSuggestionAlbumModal__CreatePrompt', {
                                  label,
                                })
                              }
                            />
                          </div>
                        </Form.Field>

                        <Modal.Actions>
                          <Button
                            inlineBlock
                            cancel
                            type="button"
                            onClick={this.props.onRequestClose}
                          >
                            <FormattedMessage id="Global__Cancel" />
                          </Button>

                          <Button
                            data-testid="submit"
                            disabled={isAdding}
                            inlineBlock
                            filledBg
                            type="submit"
                          >
                            <FormattedMessage id="Global__Done" />
                          </Button>
                        </Modal.Actions>
                      </Form>
                    )}
                  </Mutation>
                );
              }}
            </Query>
          </Modal>
        )}
      </WithIntl>
    );
  }
}
export default AddSuggestionAlbumModal;
