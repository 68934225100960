import React, { useState } from 'react';
import { Form, ConfirmModal } from 'components';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import * as REMOVE_OAUTH_IDENTITY from 'graphql/mutations/removeOauthIdentity.graphql';
import User from 'types/User';
import { OAuthPlatforms } from 'helpers/auth/types';
import { useIntl } from 'hooks';
import OauthIdentity from 'types/OauthIdentity';
import FacebookPage from 'types/FacebookPage';
import {
  LinkedFacebookAccount,
  LinkAccountButton,
  LinkedTwitterAccount,
} from './components';
import { useMutation } from '@apollo/react-hooks';
import { flashMessage } from 'helpers';
import performLogin from 'helpers/auth/performLogin';

interface Props {
  facebookPage?: FacebookPage;
  pathForSuccessfulRedirect: string;
  platform: string;
  account: OauthIdentity;
}

export default function LinkedAccounts({
  facebookPage,
  account,
  platform,
  pathForSuccessfulRedirect,
}: Props) {
  return (
    <>
      <Form.Field key={platform}>
        <div className="font-bold text-dark">
          <FormattedMessage id={`LinkedAccounts__Heading--${platform}`} />
        </div>

        <div className="mb-1.5 leading-snug">
          <FormattedHTMLMessage
            tagName="span"
            id={`LinkedAccounts__Description--${platform}`}
          />
        </div>

        {account ? (
          <LinkedAccount
            account={account}
            facebookPage={facebookPage}
            pathForSuccessfulRedirect={pathForSuccessfulRedirect}
          />
        ) : (
          <LinkAccountButton
            pathForSuccessfulRedirect={pathForSuccessfulRedirect}
            platform={platform as OAuthPlatforms}
          />
        )}
      </Form.Field>
    </>
  );
}

interface LinkedAccountProps {
  account: OauthIdentity;
  facebookPage?: FacebookPage;
  pathForSuccessfulRedirect: string;
}

interface MutationData {
  removeOauthIdentity: { errors?: string; currentUser?: User };
}

function LinkedAccount({
  account,
  facebookPage,
  pathForSuccessfulRedirect,
}: LinkedAccountProps) {
  const platform = account.provider;
  const { t } = useIntl();

  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);

  const invalidIdentity =
    !!account.invalidToken || !!account.invalidPermissions;

  const invalidFacebookPage = !!facebookPage?.invalidToken;

  const [removeOauthIdentity, { loading }] = useMutation<MutationData>(
    REMOVE_OAUTH_IDENTITY,
    {
      variables: { id: account.id },
      onCompleted: (data) => {
        if (data.removeOauthIdentity.errors) {
          flashMessage('Global__UnexpectedError', { style: 'error' });
        }
      },
    }
  );

  const handleReconnect = () => {
    performLogin({
      platformId: account.provider as OAuthPlatforms,
      successRedirect: pathForSuccessfulRedirect,
    });
  };

  const accountProps = {
    account,
    onRemove: () => setIsConfirmModalOpen(true),
    onReconnect: handleReconnect,
  };

  return (
    <>
      {platform === 'twitter' ? (
        <LinkedTwitterAccount {...accountProps} isInvalid={invalidIdentity} />
      ) : platform === 'facebook' ? (
        <LinkedFacebookAccount
          {...accountProps}
          facebookPage={facebookPage}
          isInvalid={invalidIdentity || invalidFacebookPage}
        />
      ) : null}

      <ConfirmModal
        isOpen={isConfirmModalOpen}
        onConfirm={removeOauthIdentity}
        onRequestClose={() => setIsConfirmModalOpen(false)}
        heading="LinkedAccounts__ConfirmUnlinkHeading"
        isLoading={loading}
      >
        {t('LinkedAccounts__ConfirmUnlinkBody', {
          platform: t(`Platforms--${platform}`),
        })}
      </ConfirmModal>
    </>
  );
}
