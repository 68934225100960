import * as ADD_ATTACHMENTS_TO_ATTACHMENT_ALBUMS from 'graphql/mutations/addAttachmentsToAttachmentAlbums.graphql';
import { flashMessage } from 'helpers';
import { useMutation } from '@apollo/react-hooks';

interface MutationData {
  addAttachmentsToAttachmentAlbums: {
    success?: boolean;
    errors?: string;
  };
}

export default function useAddAttachmentToAlbums(
  ids: (number | string)[] | number | string,
  albumIds: string[]
) {
  const [addAttachmentsToAttachmentAlbum, { loading: isLoading }] =
    useMutation<MutationData>(ADD_ATTACHMENTS_TO_ATTACHMENT_ALBUMS, {
      variables: { attachmentIds: ids, attachmentAlbumIds: albumIds },
      onCompleted: (data) => {
        if (data.addAttachmentsToAttachmentAlbums.errors) {
          console.warn(data.addAttachmentsToAttachmentAlbums.errors);
          flashMessage('Global__UnexpectedError', { style: 'error' });
        } else {
          flashMessage('AlbumsMedia__SuccessMessage');
        }
      },
      onError: () =>
        flashMessage('Global__UnexpectedError', { style: 'error' }),
    });

  return { isLoading, addToAlbum: addAttachmentsToAttachmentAlbum };
}
