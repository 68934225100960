import React from 'react';
import { useQuery } from 'react-apollo';
import RelayConnection from 'types/RelayConnection';
import PublisherTag from 'types/PublisherTag';
import { mapNodes } from 'helpers';
import { Form } from 'components';
import gql from 'graphql-tag';
import { useIntl } from 'hooks';

interface Props {
  value: number[];
  onChange: (value: number[]) => void;
}

const TAGS_QUERY = gql`
  query PublisherActivityTagFilter {
    publisherTags {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;

interface QueryData {
  publisherTags: RelayConnection<PublisherTag>;
}

export default function PublisherActivityTagFilter(props: Props) {
  const { t } = useIntl();

  const { value, onChange } = props;

  const { data, loading } = useQuery<QueryData>(TAGS_QUERY);

  const tags = mapNodes(data?.publisherTags.edges || []);

  const options = tags.map((tag) => ({
    value: tag.id,
    label: tag.name,
  }));

  return (
    <div className="min-w-30">
      <Form.Select
        isMulti
        options={options}
        value={value}
        onChange={onChange}
        isLoading={loading}
        isClearable={false}
        placeholder={t('PublisherActivityReport__TagsPlaceholder')}
      />
    </div>
  );
}
