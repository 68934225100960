import React, { useState } from 'react';
import Recipient from 'types/Recipient';
import { contactMethodIcons } from 'icons';
import Tooltip from 'components/Tooltip';
import { contactProtocolFieldMap } from 'helpers';
import { useIntl } from 'hooks';

interface Props {
  recipient: Recipient;
  onAddRecipient: (recipient: Recipient | Recipient[]) => void;
  contactProtocols: string[];
}

export default function UserRecipient(props: Props) {
  const { contactProtocols, recipient, onAddRecipient } = props;
  const { t } = useIntl();
  const [isHoveringName, setIsHoveringName] = useState(false);
  const publisherUser = recipient.publisherUser;

  if (!publisherUser) {
    throw new Error('[UserRecipient] Recipient must have a publisherUser');
  }

  const contactableProtocols = contactProtocols.filter(
    (contactProtocol) =>
      !!publisherUser[contactProtocolFieldMap[contactProtocol]]
  );

  const handleAddProtocol = (protocol: string) => {
    onAddRecipient({ ...recipient, protocol });
  };

  const handleAddDefaults = () => {
    const recipients = contactableProtocols
      .filter((protocol) =>
        publisherUser.notificationPreferences.includes(protocol)
      )
      .map((protocol) => ({ ...recipient, protocol }));
    if (!recipients.length) return;
    onAddRecipient(recipients);
  };

  return (
    <div className="flex items-center">
      <button
        className="text-dark hover:underline"
        onClick={handleAddDefaults}
        onMouseEnter={() => setIsHoveringName(true)}
        onMouseLeave={() => setIsHoveringName(false)}
      >
        {publisherUser.name}
      </button>

      {contactableProtocols.map((protocol) => {
        const wantsMsg =
          publisherUser.notificationPreferences.includes(protocol);
        const value = publisherUser[contactProtocolFieldMap[protocol]];
        const Icon = contactMethodIcons[protocol];

        if (!value || (protocol === 'apns' && !wantsMsg)) return null;
        if (!Icon) throw new Error(`[UserRecipient] No icon for ${protocol}`);

        const iconColorClass =
          wantsMsg && isHoveringName
            ? 'text-dark'
            : wantsMsg
            ? 'text-bodyText'
            : 'text-lightIcon opacity-75';

        return (
          <button
            onClick={() => handleAddProtocol(protocol)}
            className={`ml-1 hover:text-dark  ${iconColorClass}`}
            key={protocol}
          >
            <Tooltip
              animation={false}
              content={t('RecipientsSearch__AddProtocol', {
                protocol: t(`Protocol--${protocol}`),
              })}
              theme="socialieDark"
            >
              <Icon className="bump-down-1.5 h-1.75 w-1.75" />
            </Tooltip>
          </button>
        );
      })}
    </div>
  );
}
