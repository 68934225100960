import React, { useState } from 'react';
import { useIntl, useValidateOtp } from 'hooks';
import { Form, Button } from 'components';
import { FormattedMessage } from 'react-intl';
import { IconRefresh, IconLoader, IconCheckmark } from 'icons';
import * as RESEND_SMS_CODE from 'graphql/mutations/resendSmsCode.graphql';
import * as CURRENT_USER_QUERY from 'graphql/queries/currentUser.graphql';

import { useMutation, useLazyQuery } from '@apollo/react-hooks';
import SemiLoggedInUser from 'types/SemiLoggedInUser';

interface Props {
  onUseRecoveryCode: () => void;
  semiLoggedInUser: SemiLoggedInUser;
}

interface MutationData {
  resendSmsCode: {
    success: boolean;
  };
}

interface MutationVars {
  mfaProtocol: string;
}

export default function MfaLogin(props: Props) {
  const { onUseRecoveryCode, semiLoggedInUser } = props;
  const [hasResentSms, setHasResentSms] = useState(false);

  const { t } = useIntl();

  const [resendSmsCode, { loading: isResending }] = useMutation<
    MutationData,
    MutationVars
  >(RESEND_SMS_CODE, {
    variables: { mfaProtocol: semiLoggedInUser.mfaProtocol },
    onCompleted: (data) => {
      if (data.resendSmsCode.success) {
        setHasResentSms(true);
        setTimeout(() => {
          setHasResentSms(false);
        }, 3000);
      } else {
        setHasResentSms(false);
      }
    },
  });

  const [mfaToken, setMfaToken] = useState('');

  const [loadCurrentUser, { loading: isRefetchingUser }] = useLazyQuery(
    CURRENT_USER_QUERY,
    {
      fetchPolicy: 'network-only',
    }
  );

  const { validateOtp, loading, isInvalidOtp } = useValidateOtp({
    onCompleted: () => loadCurrentUser(),
  });

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    validateOtp({
      variables: {
        token: mfaToken.replace(/\s/g, ''),
        mfaProtocol: semiLoggedInUser.mfaProtocol,
      },
    });
  };

  const isSubmitting = loading || isRefetchingUser;
  const lastTwoDigits = semiLoggedInUser.mfaPhone.slice(-2);

  return (
    <>
      <div className="mb-4 border-0 border-b border-solid border-grey7">
        <h3 className="text-20 font-semibold text-dark">
          {t('Security__MfaLogin--Heading')}
        </h3>
      </div>

      <div className="mb-1 text-dark">
        {semiLoggedInUser.mfaProtocol === 'sms' ? (
          <FormattedMessage
            id="Security__MfaLogin--VerifySms-Desc"
            values={{
              phoneId: (
                <span className="font-bold text-dark">{lastTwoDigits}</span>
              ),
            }}
          />
        ) : (
          <span className="text-dark">
            <FormattedMessage
              id="Security__MfaLogin--VerifyApp-Desc"
              values={{
                secondaryText: (
                  <span className="font-bold">
                    {t('Security__MfaLogin--VerifyApp-Desc-Secondary')}
                  </span>
                ),
              }}
            />
          </span>
        )}
      </div>

      <form onSubmit={handleSubmit}>
        <div className="mb-2.5">
          <Form.TextInput
            name="mfaToken"
            large
            autoFocus
            placeholder={t('Security__MfaLogin--Verify-Placeholder')}
            value={mfaToken}
            errors={
              isInvalidOtp
                ? t('Security__MfaLogin--Verify-Validation-InvalidCode')
                : ''
            }
            onChange={(e) => setMfaToken(e.target.value)}
          />
        </div>
        <div className="mb-2.5 flex items-center justify-end">
          <Button
            filledBg
            large
            largeInline
            type="submit"
            disabled={!mfaToken || isSubmitting}
          >
            {isSubmitting ? (
              <IconLoader className="ml-1" />
            ) : (
              t('Security__MfaLogin--Verify-Button')
            )}
          </Button>
        </div>
      </form>

      {semiLoggedInUser.mfaProtocol === 'sms' && (
        <button className="mb-1 cursor-pointer" onClick={() => resendSmsCode()}>
          <span className="flex items-center">
            {isResending ? (
              <IconLoader className="mr-1 h-1.5 w-1.5" />
            ) : hasResentSms ? (
              <IconCheckmark className="mr-1 h-1.5 w-1.5 text-green" />
            ) : (
              <IconRefresh className="bump-down-1 mr-1 h-1.5 w-1.5 text-socialiePink" />
            )}
            {hasResentSms ? (
              <span className="text-green">
                {t('Security__MfaLogin--Verify-HasResentSms')}
              </span>
            ) : (
              <span className="text-socialiePink hover:underline">
                {t('Security__MfaLogin--Verify-ResendSms')}
              </span>
            )}
          </span>
        </button>
      )}
      <p
        className="cursor-pointer text-socialiePink hover:underline"
        onClick={onUseRecoveryCode}
      >
        {t('Security__MfaLogin--Verify-UseRecoveryCode')}
      </p>
    </>
  );
}
