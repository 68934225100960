import { Styles as ReactSelectStyles } from 'react-select/lib/styles';
import { grid, colors } from 'styles/theme';

const styles = (theme: any): Partial<ReactSelectStyles> => ({
  control: (base, state) => ({
    ...base,
    display: 'flex',
    borderRadius: '4px 0 0 4px',
    height: grid(4.5),
    minHeight: grid(4.5),
    lineHeight: grid(3),
    fontSize: '14px',
    color: colors.darkText,
    backgroundColor: colors.white,
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: theme.isInvalid
      ? colors.error
      : state.isFocused
      ? colors.focus
      : colors.border,
    outline: 'none',
    boxShadow: 'none',
    '&:hover': {
      borderColor: theme.isInvalid
        ? colors.error
        : state.isFocused
        ? colors.focus
        : colors.border,
    },
  }),
  menu: (base) => ({
    ...base,
    width: '300px',
    boxShadow: '0 2px 4px 0 rgba(0,0,0,0.12), 0 2px 8px 0 rgba(0,0,0,0.16)',
    borderColor: colors.border,
    backgroundColor: colors.white,
    zIndex: 9999,
  }),
  option: (base) => ({
    ...base,
    color: colors.darkText,
    backgroundColor: colors.white,
    '&:hover': { backgroundColor: colors.greyBg },
  }),
  indicatorSeparator: (base) => ({
    display: 'none',
  }),
});

export default styles;
