import { useState } from 'react';
import omitBy from 'lodash/omitBy';
import qs from 'qs';
import useDebouncedCallback from 'use-debounce/lib/callback';

export type UpdateFilterFn<FilterShape extends Object> = (
  updates: Partial<FilterShape>,
  options?: { debounce: boolean }
) => void;

export default function useFilterManager<FilterShape extends Object>(
  defaultFilter: FilterShape,
  useQueryString = false
) {
  const queryObject = qs.parse(window.location.search, {
    ignoreQueryPrefix: true,
  });

  const initialFilter: FilterShape = useQueryString
    ? Object.assign(
        {},
        defaultFilter,
        // Only include keys that are present in the default filter, and
        // convert values to the correct type. This is necessary because
        // query strings are always strings, but we want to support
        // equality-checking between the current filter and the default or
        // currently active saved filter set.
        Object.keys(queryObject).reduce((acc, key) => {
          if (key in defaultFilter) {
            if (Array.isArray(queryObject[key])) {
              acc[key] = queryObject[key].map((val: any) => {
                if (val.match(/^\d+$/)) return parseInt(val, 10);
                if (val.match(/^\d+\.\d+$/)) return parseFloat(val);
                return val;
              });
            } else if (typeof queryObject[key] === 'string') {
              switch (true) {
                case /^\d+$/.test(queryObject[key]):
                  acc[key] = parseInt(queryObject[key], 10);
                  break;
                case /^\d+\.\d+$/.test(queryObject[key]):
                  acc[key] = parseFloat(queryObject[key]);
                  break;
                case queryObject[key] === 'true':
                  acc[key] = true;
                  break;
                case queryObject[key] === 'false':
                  acc[key] = false;
                  break;
                default:
                  acc[key] = queryObject[key];
              }
            } else {
              acc[key] = queryObject[key];
            }
          }
          return acc;
        }, {})
      )
    : defaultFilter;

  const [filter, setFilter] = useState(initialFilter);
  const [debouncedFilter, setDebouncedFilter] = useState(initialFilter);
  const debouncedSetDebouncedFilter = useDebouncedCallback(
    (updates: FilterShape) => setDebouncedFilter(updates),
    500
  );

  const updateFilter: UpdateFilterFn<FilterShape> = (updates, options) => {
    const defaultOpts = { debounce: true };
    const opts = { ...defaultOpts, ...options };
    const nextFilter = Object.assign({}, filter, updates);
    setFilter(nextFilter);
    opts.debounce
      ? debouncedSetDebouncedFilter(nextFilter)
      : setDebouncedFilter(nextFilter);

    if (useQueryString && window.history && window.history.replaceState) {
      let queryString = qs.stringify(
        omitBy(nextFilter as any, (val, key) => {
          return !val || val.length === 0 || defaultFilter[key] === val;
        }),
        { arrayFormat: 'brackets' }
      );

      const currentSearchParams = new URLSearchParams(window.location.search);
      const savedFilterSetId = currentSearchParams.get('savedFilterSetId');

      if (savedFilterSetId) {
        queryString = queryString + '&savedFilterSetId=' + savedFilterSetId;
      }

      window.history.replaceState(
        {},
        document.title,
        window.location.pathname + '?' + queryString
      );
    }
  };

  return { updateFilter, filter, debouncedFilter };
}
