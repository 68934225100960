import React from 'react';
import { useIntl } from 'hooks';

import Post from 'types/Post';
import Story from 'types/Story';

interface Props {
  media: Story | Post;
  platform: string;
}

export default function CaptionRow(props: Props) {
  const { media, platform } = props;
  const { t } = useIntl();
  const postType = platform.endsWith('stories') ? 'Story' : 'Post';

  return (
    <div className="border-b-default flex items-center justify-between py-1.5 px-4.5">
      <div>
        {/* Checks if text field is included in either Story | Post types */}
        {'text' in media ? (
          <span className="text-dark">{media.text}</span>
        ) : (
          <em>{t('PlatformStats__NoCaption')}</em>
        )}
      </div>

      {media.url && (
        <a
          href={media.url}
          target="_blank"
          rel="noopener noreferrer"
          className="text-socialiePink hover:underline"
        >
          {t(`PlatformStats__View${postType}`)}
        </a>
      )}
    </div>
  );
}
