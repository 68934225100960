import { createSlice } from 'redux-starter-kit';

// type UploadStatus = null | 'started' | 'success' | 'failed';

export const initialState = {
  currentScale: 1,
  primaryColor: '',
  uploadStatus: null,
  uploadProgress: 0,
  isLoading: false,
  errors: false,
  errorMessage: '',
  showPicker: false,
  isEditModalOpen: false,
  image: {
    mimeType: '',
    originalUrl: '',
    previewUrl: '',
    croppedPreviewUrl: '',
    currentScale: 1,
    position: { x: 0.5, y: 0.5 },
    didApplyEdits: false,
    didUpload: false,
  },
};

export type State = typeof initialState;

export const { reducer, actions } = createSlice({
  initialState,
  name: 'brandSlice',
  reducers: {
    // Logo
    openEditModal: (state) => ({ ...state, isEditModalOpen: true }),
    closeEditModal: (state) => ({ ...state, isEditModalOpen: false }),
    updateImage: (state, action: { payload: Partial<State['image']> }) => ({
      ...state,
      image: {
        ...state.image,
        ...action.payload,
      },
    }),
    setLoading: (state, action) => ({
      ...state,
      isLoading: action.payload,
    }),
    setProgress: (state, action) => ({
      ...state,
      uploadProgress: action.payload ? action.payload : state.uploadProgress,
    }),
    setSuccess: (state) => ({
      ...state,
      success: true,
    }),
    setError: (state, action) => ({
      ...state,
      success: false,
      errorMesssage: action.payload,
    }),
    // Colors
    toggleShowPicker: (state) => ({
      ...state,
      showPicker: !state.showPicker,
    }),

    updateColor: (state, action) => ({
      ...state,
      primaryColor: action.payload,
      showPicker: true,
    }),
    clearColor: (state) => ({
      ...state,
      picked: false,
      primaryColor: '',
    }),
  },
});
