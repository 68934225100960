import React from 'react';
import { useIntl } from 'hooks';
import Request from 'types/PublisherRequest';
import { StatRow, PlatformStat } from './components';
import Story from 'types/Story';
import Post from 'types/Post';
import { platformInsights } from './helpers';
import { toLower, startCase, sum } from 'lodash';
import { formatAverage, formatBigInt } from 'helpers/formatNumber';

interface RequestStatRowsProps {
  isRowFormat?: boolean;
  requests: Request[];
  isVideo: boolean;
  enableStories: boolean;
}

export default function RequestStatRows(props: RequestStatRowsProps) {
  const { isRowFormat, requests, isVideo, enableStories } = props;

  const { t } = useIntl();

  const isPublishedPost = (p: Post) => p.status === 'published';

  const posts = requests.flatMap((r) => r.posts?.filter(isPublishedPost));
  const stories = requests.flatMap((r) => r.stories);

  const statRows = {
    facebook: [] as Post[],
    twitter: [] as Post[],
    instagram: [] as Post[],
    instagram_stories: [] as Story[],
    linkedin: [] as Post[],
  };

  posts.forEach((p) => {
    if (!p || !statRows[p.platform]) return;
    statRows[p.platform].push(p);
  });

  stories.forEach((s) => {
    if (!s) return;
    if (s.platform === 'instagram') statRows['instagram_stories'].push(s);
  });

  const statRowPlatforms = Object.keys(statRows);

  return (
    <>
      {statRowPlatforms.map((platform) => {
        /*
        If stats are from a stories platform, and stories are not enabled,
        don't show them in the UI
        */
        if (platform.match(/stories/gi) && !enableStories) return null;

        const hasStats = statRows[platform].length;

        if (!hasStats && isRowFormat) return null;

        const calculateStat = (
          objs: any,
          statName: string,
          abbreviate: boolean = false
        ) => {
          if (
            objs.some(
              (obj: any) => !!obj && (!!obj[statName] || obj[statName] === 0)
            )
          ) {
            const value = sum(
              objs.map((obj: any) => (obj && obj[statName]) || 0)
            );
            return abbreviate ? formatAverage(value) : formatBigInt(value);
          }

          return '--';
        };

        const mediaArray = statRows[platform].map((obj: Post | Story) => obj);

        const insights = statRows[platform].map((obj: Post | Story) =>
          'postInsight' in obj ? obj.postInsight : obj.lastInsight
        );

        return (
          <>
            {hasStats ? (
              <StatRow
                key={platform}
                platform={platform}
                media={statRows[platform][0]}
                isRowFormat={isRowFormat}
              >
                {platformInsights[platform].map((insight: string) => {
                  const isInstagramLegacyEngagement =
                    platform === 'instagram' && insight === 'engagement';

                  const isInstagramEngagement =
                    platform === 'instagram' && insight === 'igEngagement';

                  return (
                    <PlatformStat
                      heading={
                        isInstagramLegacyEngagement || isInstagramEngagement
                          ? t(`PlatformStats__Stat--engagement-hyphenated`)
                          : t(`PlatformStats__Stat--${insight}`)
                      }
                      subHeading={
                        isInstagramLegacyEngagement
                          ? t('PlatformStats__Stat--socialie')
                          : isInstagramEngagement
                          ? t('PlatformStats__Stat--instagram')
                          : ''
                      }
                      isTwitterEngagement={
                        platform === 'twitter' && insight === 'engagement'
                      }
                      tooltipContents={
                        isInstagramLegacyEngagement
                          ? t('PlatformStats__EngagementInstagramLegacyTooltip')
                          : isInstagramEngagement
                          ? t('PlatformStats__EngagementInstagramTooltip')
                          : t('PlatformStats__EngagementTooltip')
                      }
                      hasToolTip={
                        isInstagramLegacyEngagement || isInstagramEngagement
                      }
                    >
                      <div>
                        {insight === 'engagement'
                          ? calculateStat(mediaArray, insight)
                          : insight === 'reach'
                          ? calculateStat(mediaArray, insight, true)
                          : calculateStat(insights, insight)}
                      </div>
                    </PlatformStat>
                  );
                })}
                {platform === 'facebook' && isVideo && (
                  <PlatformStat
                    heading={t('PlatformStats__Stat--fbVideoViews')}
                  >
                    {calculateStat(insights, 'fbVideoViews')}
                  </PlatformStat>
                )}

                {platform === 'twitter' && isVideo && (
                  <PlatformStat
                    heading={t('PlatformStats__Stat--twVideoViews')}
                  >
                    {calculateStat(insights, 'twVideoViews')}
                  </PlatformStat>
                )}

                {platform === 'instagram' && isVideo && (
                  <PlatformStat
                    heading={t('PlatformStats__Stat--igPlays')}
                    hasToolTip={true}
                    tooltipContents={t('PlatformStats__Stat--igPlaysToolTip')}
                  >
                    {calculateStat(insights, 'igPlays')}
                  </PlatformStat>
                )}

                {platform === 'instagram' && isVideo && (
                  <PlatformStat
                    heading={t('PlatformStats__Stat--twVideoViews')}
                    hasToolTip={true}
                    tooltipContents={t(
                      'PlatformStats__Stat--twVideoViewsTooltip'
                    )}
                  >
                    {calculateStat(insights, 'igVideoViews')}
                  </PlatformStat>
                )}
              </StatRow>
            ) : (
              <StatRow platform={platform} isRowFormat={isRowFormat}>
                <span className="leading-none text-grey5">
                  {t('PlatformStats__NoPosts', {
                    platform: startCase(toLower(platform)),
                  })}
                </span>
              </StatRow>
            )}
          </>
        );
      })}
    </>
  );
}
