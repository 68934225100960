import React, { useEffect, useRef, useState } from 'react';
import { useIntl } from 'hooks';
import Modal from './Modal';

import browserIncompatibilityCheck from 'helpers/browserIncompatibilityCheck';
import Button from './Button';

interface Props {
  browsers: Array<'Edge' | 'Firefox' | 'Chrome' | 'Safari'>;
  description?: string;
}

function UnsupportedBrowserModal(props: Props) {
  const { description, browsers } = props;

  const { t } = useIntl();

  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    browserIncompatibilityCheck(browsers, () => setIsModalOpen(true));
  });

  const isDismissed = useRef(false);

  const handleClose = () => {
    setIsModalOpen(false);
    isDismissed.current = true;
  };

  return (
    <Modal
      isOpen={isModalOpen && !isDismissed.current}
      onRequestClose={handleClose}
      renderHeading={t('UnsupportedBrowserModal__Heading')}
      contentLabel={t('UnsupportedBrowserModal__Heading')}
    >
      <div className="mb-2 text-left">
        {description ? (
          description
        ) : (
          <span>{t('UnsupportedBrowser__Desc')}</span>
        )}
      </div>
      <div className="flex items-center justify-end">
        <Button inlineBlock type="button" filledBg onClick={handleClose}>
          {t('Global__Dismiss')}
        </Button>
      </div>
    </Modal>
  );
}

export default UnsupportedBrowserModal;
