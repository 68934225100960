import React from 'react';
import includes from 'lodash/includes';
import without from 'lodash/without';
import { Form } from 'components';
import { FormattedMessage } from 'react-intl';
import { platforms } from 'config';

interface Props {
  onChange: (val: string[]) => void;
  publishedPlatform: string[];
}

const PublishedPlatformFilter = (props: Props) => {
  const { onChange, publishedPlatform } = props;
  const onToggle = (val: string) => {
    if (includes(publishedPlatform, val)) {
      onChange(without(publishedPlatform, val));
    } else {
      onChange([...publishedPlatform, val]);
    }
  };

  const publishablePlatforms = [...platforms, 'instagram_stories'].sort();

  const buildPublishedPlatformsString = (platform: string) => {
    if (platform.includes('instagram')) {
      return `PublishedPlatforms--${platform}`;
    } else {
      return `Platforms--${platform}`;
    }
  };

  return (
    <Form.Field>
      <Form.Label bold>
        <FormattedMessage id="TrackingPublishedPlatformFilter__Label" />
      </Form.Label>

      {publishablePlatforms.map((platform) => (
        <Form.Option key={platform}>
          <Form.Checkbox
            checked={includes(publishedPlatform, platform)}
            onChange={() => onToggle(platform)}
          />
          <span>
            <FormattedMessage id={buildPublishedPlatformsString(platform)} />
          </span>
        </Form.Option>
      ))}
    </Form.Field>
  );
};

export default PublishedPlatformFilter;
