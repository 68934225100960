import gql from 'graphql-tag';
import apolloClient from '../apolloClient';
import TwilioMessage from 'types/TwilioMessage';
import loadTwilioMessage from './loadTwilioMessage';

const STATUS_FRAGMENT = gql`
  fragment TwilioMessageStatus on TwilioMessage {
    id
    status
  }
`;

const updateTwilioMessageStatus = (messageId: string, status: string) => {
  const { cache } = apolloClient;
  const message = cache.readFragment<TwilioMessage>({
    id: `TwilioMessage:${messageId}`,
    fragment: STATUS_FRAGMENT,
  });

  if (!message || (message && message.status === status)) return;

  loadTwilioMessage(messageId, true);
};

export default updateTwilioMessageStatus;
