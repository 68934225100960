import React from 'react';
import styled from 'styled-components';
import Avatar from '../Avatar';
import { colors } from 'styles/theme';

interface Props {
  avatars: { id: any; src?: string; name?: string }[];
}

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  > * {
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 50%;
    box-shadow: 0px 2px 4px ${colors.boxShadow};
    width: 100%;
    height: 100%;
  }

  > *:nth-child(2) {
    transform: translateX(-11.9%);
    z-index: 3;
  }

  > *:nth-child(3) {
    transform: translateX(-23.8%);
    z-index: 2;
  }

  > *:nth-child(4) {
    transform: translateX(-35.7%);
    z-index: 1;
  }

  > *:last-child {
    transform: translateX(-35.7%);
    box-shadow: none;
  }

  > *:first-child {
    transform: translateX(0);
    z-index: 4;
  }
`;

const AvatarStack = (props: Props) => {
  const avatars = props.avatars.slice(0, 4);
  return (
    <Wrapper>
      {avatars.map((avatar) => (
        <div key={avatar.id}>
          <Avatar src={avatar.src} name={avatar.name} />
        </div>
      ))}
    </Wrapper>
  );
};

export default AvatarStack;
