import React, { useState } from 'react';
import { ManageMfa, GlobalNavBar } from 'components';
import BeforeUnload from 'react-beforeunload';
import logo from 'images/logo.svg';
import { useIntl } from 'hooks';
import { useHistory } from 'react-router-dom';
import { routes } from 'helpers';
import User from 'types/User';
import SemiLoggedInUser from 'types/SemiLoggedInUser';

interface Props {
  user: User | SemiLoggedInUser;
  password: string;
  redirectPath?: string;
  onMfaSetupComplete?: () => void;
}

export default function MfaSetup(props: Props) {
  const { user, password, redirectPath, onMfaSetupComplete } = props;
  const { t } = useIntl();
  const requireMfa = user.currentSuggester?.accountConfiguration.requireMfa;
  const history = useHistory();

  const [isPreventingUnload, setIsPreventingUnload] = useState(false);

  return (
    <>
      {!user.enableMfa && (
        <GlobalNavBar hasNavigation={false} currentUser={user} />
      )}
      <div className="mx-auto mt-9 w-75">
        <div className="mb-3 flex items-center">
          <img src={logo} alt="Socialie logo" className="mr-2 w-4" />
          <h1 className="text-xxl font-semibold">
            {t('Security__MfaSetup--Heading')}
          </h1>
        </div>
        <div className="rounded bg-white py-2">
          <div className="mb-1.5 flex items-center justify-between border-b border-l-0 border-r-0 border-t-0 border-solid border-grey6 px-2 pb-2">
            <h3 className="m-0 text-lg font-normal text-dark">
              {t('Security__ManageMfa--Modal-Heading')}
            </h3>
          </div>
          <div className="px-2">
            <ManageMfa
              user={user}
              hasSkipButton={!requireMfa}
              hasPasswordPresent={!!password}
              password={password}
              onMfaSetupComplete={
                onMfaSetupComplete ||
                (() => history.push(redirectPath || routes.mediaLibrary.index))
              }
              onSetIsPreventingUnload={() => setIsPreventingUnload(true)}
              onSkipMfa={() =>
                history.push(redirectPath || routes.mediaLibrary.index)
              }
            />
          </div>
        </div>
      </div>
      {!isPreventingUnload && (
        <BeforeUnload onBeforeunload={() => t('UploadSaver__Warning')} />
      )}
    </>
  );
}
