import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Avatar, FlyoutMenu } from '../..';
import { logOut, routes } from 'helpers';
import User from 'types/User';
import SemiLoggedInUser from 'types/SemiLoggedInUser';
import { useGodMode } from 'hooks';

interface Props {
  currentUser: User | SemiLoggedInUser;
  hasSettings: boolean;
}

const UserMenu = ({ currentUser, hasSettings }: Props) => {
  const isGodMode = useGodMode();
  return (
    <FlyoutMenu
      placement="bottom-end"
      renderButton={(onClick) => (
        <div className="relative hidden h-4 w-4 flex-shrink-0 tablet:mr-2.5 tablet:block">
          <button className="h-4 w-4" type="button" onClick={onClick}>
            <Avatar
              src={isGodMode ? '/god-mode.jpg' : undefined}
              name={currentUser.name}
              theme={{ dark: true }}
            />
          </button>
        </div>
      )}
      renderHeading={
        <div className="border-b-default text-body -mx-0.5 mb-0.5 px-0.5 py-1 text-14">
          {currentUser.email}
          {isGodMode && (
            <div className="text-gray-3 text-12">(God Mode Enabled)</div>
          )}
        </div>
      }
    >
      {hasSettings && (
        <FlyoutMenu.Item to={routes.settings.account}>
          <FormattedMessage id="UserMenu__Settings" />
        </FlyoutMenu.Item>
      )}

      <FlyoutMenu.Item onClick={() => logOut()}>
        <FormattedMessage id="UserMenu__LogOut" />
      </FlyoutMenu.Item>
    </FlyoutMenu>
  );
};

export default UserMenu;
