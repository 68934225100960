import React from 'react';
import InfoTable from './InfoTable';
import InfoCard from './InfoCard';
import Attachment from 'types/Attachment';
import { useIntl } from 'hooks';
import { formatLength, formatFileSize } from 'helpers/formatNumber';
import { Link } from 'react-router-dom';
import { routeFor, routes } from 'helpers';

interface Props {
  attachment: Attachment;
}

export default function Metadata({ attachment }: Props) {
  const { t } = useIntl();
  return (
    <InfoCard title={t('AttachmentModal__Metadata')}>
      <InfoTable>
        {!!attachment.aiMetadata && (
          <InfoTable.Row label={t('AttachmentMetadata__AiMetadata')}>
            {attachment.aiMetadata}
          </InfoTable.Row>
        )}

        {!!attachment.keywords && (
          <InfoTable.Row label={t('AttachmentMetadata__Keywords')}>
            {attachment.keywords}
          </InfoTable.Row>
        )}

        {!!attachment.peopleInThisMedia && (
          <InfoTable.Row label={t('AttachmentMetadata__PeopleInThisMedia')}>
            {attachment.peopleInThisMedia}
          </InfoTable.Row>
        )}

        <InfoTable.Row label={t('AttachmentMetadata__Filename')}>
          {attachment.mediaFilename}
        </InfoTable.Row>

        <Source attachment={attachment} />

        {!!attachment.uploader && (
          <InfoTable.Row label={t('AttachmentMetadata__Uploader')}>
            {attachment.uploader.name}
          </InfoTable.Row>
        )}

        {!!attachment.sourceId && (
          <InfoTable.Row label={t('AttachmentMetadata__SourceId')}>
            {attachment.sourceId}
          </InfoTable.Row>
        )}

        {attachment.publisher && (
          <InfoTable.Row label={t('AttachmentMetadata__SubmittedBy')}>
            <Link
              to={routeFor(routes.publishers.show, attachment.publisher.id)}
            >
              {attachment.publisher.name}
            </Link>
          </InfoTable.Row>
        )}

        {!!attachment.length && (
          <InfoTable.Row label={t('AttachmentMetadata__Length')}>
            {formatLength(attachment.length)}
          </InfoTable.Row>
        )}

        {!!attachment.photographer && (
          <InfoTable.Row label={t('AttachmentMetadata__Photographer')}>
            {attachment.photographer}
          </InfoTable.Row>
        )}

        {!!attachment.credit && (
          <InfoTable.Row label={t('AttachmentMetadata__Credit')}>
            {attachment.credit}
          </InfoTable.Row>
        )}

        {!!attachment.location && (
          <InfoTable.Row label={t('AttachmentMetadata__Location')}>
            {attachment.location}
          </InfoTable.Row>
        )}

        {!!attachment.contentIntegrationPath && (
          <InfoTable.Row
            label={t('AttachmentMetadata__ContentIntegrationPath')}
          >
            {attachment.contentIntegrationPath}
          </InfoTable.Row>
        )}

        {!!attachment.mediaSize && (
          <InfoTable.Row label={t('AttachmentMetadata__FileSize')}>
            {formatFileSize(attachment.mediaSize)}
          </InfoTable.Row>
        )}

        {!!attachment.width && !!attachment.height && (
          <InfoTable.Row label={t('AttachmentMetadata__Dimensions')}>
            {attachment.width} x {attachment.height}
          </InfoTable.Row>
        )}
      </InfoTable>
    </InfoCard>
  );
}

function Source(props: { attachment: Attachment }) {
  const { attachment } = props;
  const { t } = useIntl();
  const suggestion =
    attachment.publisher && attachment.request && attachment.request.suggestion;

  return (
    <InfoTable.Row label={t('AttachmentMetadata__Source')}>
      {suggestion ? (
        <Link to={routeFor(routes.tracking.show, suggestion.id)}>
          {t(`ActionableType--${suggestion.actionableType}`)}
        </Link>
      ) : (
        t(
          `AttachmentMetadata__Source--${
            attachment.source === 'media_library'
              ? 'socialie'
              : attachment.source || 'socialie'
          }`
        )
      )}
    </InfoTable.Row>
  );
}
