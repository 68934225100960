import React from 'react';
import { Form } from 'components';
import { useIntl } from 'hooks';

interface Props {
  attachmentSources: string[];
  onChange: (val: string[]) => void;
  value: string[];
}

export default function MediaSourceFilter(props: Props) {
  const { onChange, value, attachmentSources } = props;
  const { t } = useIntl();

  const onToggle = (type: string) => {
    if (value.some((v) => v === type)) {
      onChange(value.filter((v) => v !== type));
    } else {
      onChange([...value, type]);
    }
  };

  const validSources = ['socialie', ...attachmentSources];

  return (
    <Form.Field>
      <Form.Label bold>{t('MediaSourceFilter__Label')}</Form.Label>

      {validSources.map((source) => {
        if (source === 'media_library') return null;

        return (
          <Form.Option key={source}>
            <Form.Checkbox
              checked={value.some((v) => v === source)}
              onChange={() => onToggle(source)}
            />

            <span>{t(`Integrations--${source}`)}</span>
          </Form.Option>
        );
      })}
    </Form.Field>
  );
}
