const lookupTable = {
  facebook: 'Facebook',
  instagram: 'Instagram',
  linkedin: 'LinkedIn',
  twitter: 'Twitter',
};

const platformTitle = (platformId: string): string =>
  lookupTable[platformId] || '';

export default platformTitle;
