import React from 'react';
import sortBy from 'lodash/sortBy';
import chunk from 'lodash/chunk';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import PublisherTag from 'types/PublisherTag';
import { Modal, Button } from 'components';
import { grid, colors } from 'styles/theme';
import { publishersWithoutSms } from '../utils';

interface Props {
  publisherTag: PublisherTag;
}

interface State {
  isModalOpen: boolean;
}

const ModalNotice = styled.div`
  margin-bottom: ${grid(2)};
  text-align: left;
  line-height: ${grid(2.5)};
  color: ${colors.darkText};
`;

const PublisherList = styled.div`
  display: flex;
  margin-bottom: ${grid(2)};
  line-height: ${grid(2)};
  font-size: 13px;
`;

const Column = styled.div`
  margin-right: ${grid(2)};
  padding-right: ${grid(2)};
  width: calc((100% - ${grid(2)}) / 2);
  overflow: hidden;
  text-align: left;

  &:last-child {
    margin-right: 0;
  }

  > div {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export default class MissingSMS extends React.Component<Props, State> {
  state = { isModalOpen: false };

  openModal = () => this.setState({ isModalOpen: true });
  closeModal = () => this.setState({ isModalOpen: false });

  render() {
    const { publisherTag } = this.props;
    const { isModalOpen } = this.state;
    const publishers = publishersWithoutSms(publisherTag.publishers);
    return (
      <>
        <FormattedMessage
          id="ChatPanel__PublisherTagMissingSms"
          values={{
            publisherTag: publisherTag.name,
            count: publishers.length,
            button: (
              <button onClick={this.openModal}>
                <FormattedMessage
                  id="ChatPanel__PublisherTagMissingSmsButton"
                  values={{ count: publishers.length }}
                />
              </button>
            ),
          }}
        />

        <Modal
          isOpen={isModalOpen}
          onRequestClose={this.closeModal}
          contentLabel="Publisher List"
          renderHeading={<FormattedMessage id="ChatPanel__MissingSmsHeading" />}
        >
          <ModalNotice>
            <FormattedMessage id="ChatPanel__MissingSmsNotice" />
          </ModalNotice>

          <PublisherList>
            {chunk(
              sortBy(publishers, 'name'),
              Math.ceil(publishers.length / 2)
            ).map((chunk, i) => (
              <Column key={i}>
                {chunk.map((publisher) => (
                  <div key={publisher.id}>{publisher.name}</div>
                ))}
              </Column>
            ))}
          </PublisherList>

          <Modal.Actions>
            <Button filledBg inlineBlock onClick={this.closeModal}>
              <FormattedMessage id="Global__Close" />
            </Button>
          </Modal.Actions>
        </Modal>
      </>
    );
  }
}
