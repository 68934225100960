import React from 'react';
import styled from 'styled-components';
import { IconHeading } from '..';
import { IconSubtitles } from 'icons';
import VideoCaptionType from 'types/VideoCaption';
import { colors, grid } from 'styles/theme';
import { FormattedMessage } from 'react-intl';

interface Props {
  videoCaptions: VideoCaptionType[];
}

const Wrapper = styled.div`
  text-align: left;
`;

const VideoCaption = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-bottom: ${grid(0.5)};

  span {
    margin-right: ${grid(1)};
    color: ${colors.darkText};
  }

  a {
    opacity: 0;
    color: ${colors.socialiePink};
  }

  &:hover a {
    opacity: 1;
  }

  &:last-child {
    padding-bottom: 0;
  }
`;

const VideoCaptions = ({ videoCaptions }: Props) => {
  return (
    <Wrapper>
      <IconHeading
        icon={<IconSubtitles />}
        text="TrackingSidebar__VideoCaptions"
      />
      {videoCaptions.map((videoCaption) => (
        <VideoCaption key={videoCaption.id}>
          <span>{videoCaption.captionFilename}</span>
          <a href={videoCaption.captionUrl} download>
            <FormattedMessage id="Global__Download" />
          </a>
        </VideoCaption>
      ))}
    </Wrapper>
  );
};

export default VideoCaptions;
