import React from 'react';
import { FormattedMessage } from 'react-intl';
import { AsyncPublishersAndTagsSelect, Form } from 'components';

interface FilterShape {
  publishers: number[];
  publisherTags: number[];
}

interface Props {
  publishers: { id: number; name: string; image?: string }[];
  publisherTags: { id: number; name: string }[];
  filter: FilterShape;
  updateFilter: (changes: Partial<FilterShape>) => void;
}

const PublisherFilter = (props: Props) => {
  const { publishers, publisherTags, updateFilter } = props;

  // The publishers and publisherTags that come in via props are the ones that
  // are currently selected.
  const value = {
    publishers: publishers.map((publisher) => ({
      value: publisher.id,
      label: publisher.name,
      image: publisher.image,
    })),
    publisherTags: publisherTags.map((tag) => ({
      value: tag.id,
      label: tag.name,
    })),
  };

  return (
    <Form.Field>
      <Form.Label bold>
        <FormattedMessage id="TrackingPublisherFilter__Label" />
      </Form.Label>
      <AsyncPublishersAndTagsSelect
        value={value}
        onChange={(value) => {
          updateFilter({
            publishers: value.publishers.map((publisher) => publisher.value),
            publisherTags: value.publisherTags.map((tag) => tag.value),
          });
        }}
      />
    </Form.Field>
  );
};

export default PublisherFilter;
