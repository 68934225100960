import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Form } from 'components';
import Tag from 'types/Tag';
import { useIntl } from 'hooks';
import { routes } from 'helpers';
import { Link } from 'react-router-dom';
import { IconSettings } from 'icons';

interface Props {
  tags: Tag[];
  selectedTagIds?: number[];
  onChange: (selectedTagIds: number[]) => void;
  requireAll: boolean;
  onChangeRequireAll: (requireAll: boolean) => void;
  'data-qa'?: string;
}

const TagFilter = (props: Props) => {
  const {
    tags,
    selectedTagIds,
    onChange,
    requireAll,
    onChangeRequireAll,
    'data-qa': dataQa,
  } = props;
  const { t } = useIntl();
  const options = tags.map((pt: Tag) => ({ value: pt.id, label: pt.name }));

  return (
    <Form.Field>
      <div className="flex items-center justify-between">
        <Form.Label bold>
          <FormattedMessage id="TrackingTagFilter__Label" />
        </Form.Label>
        <Link
          to={routes.settings.manageTags}
          className="flex items-center text-grey6 hover:text-grey5"
        >
          {t('Global__Manage')}
          <IconSettings className="ml-1 h-2 w-2" />
        </Link>
      </div>
      <Form.Select
        isMulti
        onChange={onChange}
        value={selectedTagIds}
        options={options}
        isClearable={false}
        placeholder={t('TrackingTagFilter__Placeholder')}
        data-qa={dataQa}
      />
      {((selectedTagIds?.length ?? 0) > 1 || requireAll) && (
        <Form.Option>
          <Form.Checkbox
            checked={requireAll}
            onChange={() => onChangeRequireAll(!requireAll)}
          />
          <span>
            <FormattedMessage id="TrackingTagFilter__RequireAll" />
          </span>
        </Form.Option>
      )}
    </Form.Field>
  );
};

export default TagFilter;
