import React, { useState } from 'react';
import AddToAttachmentAlbumModal from 'components/AddToAttachmentAlbumModal';

interface SendMediaToAlbumProps {
  selectedAttachmentIds: number[];
  children: (openModal: () => void) => JSX.Element;
}

function SendMediaToAlbum({
  selectedAttachmentIds,
  children,
}: SendMediaToAlbumProps) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  function openModal() {
    setIsModalOpen(true);
  }

  return (
    <>
      {children(openModal)}
      {isModalOpen && (
        <AddToAttachmentAlbumModal
          isOpen={isModalOpen}
          onRequestClose={() => setIsModalOpen(false)}
          attachmentIds={selectedAttachmentIds}
        />
      )}
    </>
  );
}

export default SendMediaToAlbum;
