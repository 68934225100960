import React from 'react';
import includes from 'lodash/includes';
import clsx from 'clsx';
import TwilioMessage from 'types/TwilioMessage';
import Chat from 'types/Chat';
import { Tooltip } from 'components';
import styled, { ThemeProvider } from 'styled-components';
import { grid, colors } from 'styles/theme';
import { IconWarning } from 'icons';
import MessageAvatar from './MessageAvatar';
import Media from './Media';
import Text from './Text';
import Suggestions from './Suggestions';
import { FormattedMessage } from 'react-intl';
import { useIntl } from 'hooks';

interface Props {
  message: TwilioMessage;
  chat: Chat;
  firstInPage?: boolean;
  isMostRecentMessage: boolean;
  setIsThreadExpired: (isDisabled: boolean) => void;
}

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${grid(2)};
  ${(props: any) =>
    props.theme.fromPublisher && 'flex-direction: row-reverse;'};

  &:last-child {
    margin-bottom: 0;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  ${(props: any) => props.theme.fromPublisher && 'justify-content: flex-start'};
`;

// prettier-ignore
const Content = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin-right: ${grid(0.5)};
  max-width: ${grid(27)};
  overflow: hidden;

  ${(props: any) => props.theme.fromPublisher && `
    margin-right: 0;
    margin-left: ${grid(0.5)};
    align-items: flex-start;
    order:1
  `}
`;

const Failed = styled.div`
  margin-right: ${grid(0.5)};
  width: ${grid(2)};
  height: ${grid(2)};
  color: ${colors.error};

  svg {
    display: block;
    width: 100%;
    height: 100%;
  }
`;

const USE_CUSTOM_MESSAGE_STYLE = false;

export default function Message(props: Props) {
  const { formatTime } = useIntl();

  const {
    message,
    chat,
    firstInPage,
    isMostRecentMessage,
    setIsThreadExpired,
  } = props;

  if (!message.text && !message.attachments.length && !message.files.length) {
    return <div className={firstInPage ? 'first-in-page' : ''} />;
  }

  const failed = includes(['failed', 'undelivered'], message.status);
  const fromPublisher =
    message.senderType === 'Publisher' || !message.senderType;

  const oneDay = 60 * 60 * 24 * 1000;
  const oneWeek = oneDay * 7;
  const oneMonth = oneDay * 30;

  const timeMessageCreated = Date.now() - message.createdAt;

  const overOneDay = timeMessageCreated > oneDay;

  const isWhatsAppMessage = message.publisherPhoneNumber.match(/whatsapp/g);

  const isExpiredWhatsAppMessage =
    isMostRecentMessage && fromPublisher && overOneDay && isWhatsAppMessage;

  setIsThreadExpired(!!isExpiredWhatsAppMessage);

  return (
    <ThemeProvider theme={{ failed, fromPublisher }}>
      <Wrapper className={clsx('group', { 'first-in-page': firstInPage })}>
        <div className="flex items-center space-x-0.5">
          {failed && (
            <Tooltip
              theme="socialieDark"
              content={
                <FormattedMessage
                  id="ChatPanel__FailedToSend"
                  values={{ errorCode: message.errorCode }}
                />
              }
            >
              <Failed>
                <IconWarning />
              </Failed>
            </Tooltip>
          )}
          <p
            className={clsx(
              'text-12 text-grey5 opacity-0 transition-opacity duration-200 ease-in-out group-hover:opacity-100',
              {
                'text-left': !fromPublisher,
                'text-right': fromPublisher,
              }
            )}
          >
            {timeMessageCreated > oneDay && timeMessageCreated < oneWeek ? (
              <TimeStamp
                message={message}
                dateFormatOptions={{ weekday: 'long' }}
              />
            ) : timeMessageCreated > oneWeek &&
              timeMessageCreated < oneMonth ? (
              <TimeStamp
                message={message}
                dateFormatOptions={{
                  month: 'short',
                  day: 'numeric',
                  timeZone: 'UTC',
                }}
              />
            ) : timeMessageCreated > oneMonth ? (
              <TimeStamp
                message={message}
                dateFormatOptions={{
                  year: 'numeric',
                  month: 'short',
                  day: 'numeric',
                  timeZone: 'UTC',
                }}
              />
            ) : (
              <span className="lowercase leading-tight">
                {formatTime(message.createdAt)}
              </span>
            )}
          </p>
        </div>

        <ContentWrapper>
          <Content>
            <Media message={message} />

            {!!message.suggestions.length && USE_CUSTOM_MESSAGE_STYLE ? (
              <Suggestions suggestions={message.suggestions} />
            ) : (
              <Text text={message.text} />
            )}
            {isExpiredWhatsAppMessage && (
              <p className="text-sm leading-snug text-grey5">
                <FormattedMessage id="ChatPanel__ExpiredWhatsAppMessage" />
              </p>
            )}
          </Content>
          <MessageAvatar chat={chat} message={message} />
        </ContentWrapper>
      </Wrapper>
    </ThemeProvider>
  );
}

interface TimeStampProps {
  message: TwilioMessage;
  dateFormatOptions: {
    weekday?: string;
    day?: string;
    month?: string;
    year?: string;
    timeZone?: string;
  };
}

function TimeStamp(props: TimeStampProps) {
  const { formatTime, formatDate } = useIntl();

  const { dateFormatOptions, message } = props;

  return (
    <span className="flex flex-col">
      <span className="font-semibold leading-tight">
        {formatDate(message.createdAt, dateFormatOptions)}
      </span>
      <span className="lowercase leading-tight">
        {formatTime(message.createdAt)}
      </span>
    </span>
  );
}
