import React from 'react';
import styled from 'styled-components';
import { IconDownload } from 'icons';
import { Tooltip } from 'components';
import { useIntl } from 'hooks';
import { colors, grid } from 'styles/theme';
import FormattedDateTime from 'components/FormattedDateTime';

interface Props {
  downloadedAt: number;
}

const Wrapper = styled.div`
  flex-shrink: 0;
  padding-top: ${grid(0.5)};
  color: ${colors.lightIcon};

  > svg {
    width: ${grid(2)};
    height: ${grid(2)};
  }
`;

export default function SavedMedia({ downloadedAt }: Props) {
  const { t } = useIntl();

  return (
    <Tooltip
      theme="socialieDark"
      content={
        <div>
          {t('TrackingShow__SavedMedia')}{' '}
          <FormattedDateTime
            separator=", "
            month="short"
            value={downloadedAt}
          />
        </div>
      }
    >
      <Wrapper>
        <IconDownload />
      </Wrapper>
    </Tooltip>
  );
}
