import React from 'react';
import User from 'types/User';
import * as query from 'graphql/queries/currentUser.graphql';
import { useQuery } from '@apollo/react-hooks';

interface Props {
  children(currentUser: User): React.ReactNode;
}

const WithCurrentUser = ({ children }: Props) => {
  const { data } = useQuery(query, {
    fetchPolicy: 'cache-first',
  });
  if (!data) return null;
  return <>{children(data.currentUser)}</>;
};

export default WithCurrentUser;
