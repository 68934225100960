import React from 'react';
import styled from 'styled-components';
import { colors } from 'styles/theme';
import { useIntl } from 'hooks';
import { formatBigInt } from 'helpers/formatNumber';

interface Props {
  loadedCount: number;
  totalCount?: number;
  maxCount?: number;
  loading?: boolean;
  msgPrefix: string;
  selectedCount?: number;
  selectAll?: () => void;
  selectNone?: () => void;
}

const ChangeSelection = styled.button.attrs({ type: 'button' })`
  color: ${colors.socialiePink};

  &:hover {
    text-decoration: underline;
  }
`;

export default function RecordCount(props: Props) {
  const {
    loadedCount,
    totalCount,
    maxCount,
    loading,
    msgPrefix,
    selectedCount,
    selectAll,
    selectNone,
  } = props;

  const { t } = useIntl();
  let formattedTotalCount = formatBigInt(totalCount);
  if (maxCount && totalCount === maxCount) {
    formattedTotalCount = `${formattedTotalCount}+`;
  }

  return (
    <>
      {loading ? (
        <span>{t(`${msgPrefix}__Loading`)}</span>
      ) : !!selectedCount ? (
        <>
          <div className="text-dark">
            {t(`${msgPrefix}__SelectedCount`, {
              totalCount: formattedTotalCount,
              selectedCount,
            })}
          </div>

          {selectNone && (
            <ChangeSelection onClick={selectNone}>
              {t('Global__ClearSelection')}
            </ChangeSelection>
          )}
        </>
      ) : !!totalCount ? (
        <>
          <div className="text-dark">
            {t(`${msgPrefix}__Count`, {
              totalCount: formattedTotalCount,
              loadedCount,
            })}
          </div>

          {selectAll && (
            <ChangeSelection
              data-qa="index-page-record-count-select-all"
              onClick={selectAll}
            >
              {t('Global__SelectAll')}
            </ChangeSelection>
          )}
        </>
      ) : (
        <span>{t(`${msgPrefix}__NoneFound`)}</span>
      )}
    </>
  );
}
