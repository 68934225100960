import React from 'react';
import styled from 'styled-components';
import values from 'lodash/values';
import isEqual from 'lodash/isEqual';
import intersection from 'lodash/intersection';
import { FormattedMessage } from 'react-intl';
import scrollToElement from 'scroll-to-element';
import { grid, colors } from 'styles/theme';
import { LinkTwitter } from 'components';
import Suggestion from 'types/Suggestion';
import SuggestionValidation from 'types/SuggestionValidation';
import {
  getRemainingChars,
  platformTitle,
  isValidTimeDiff,
  isSuggestionMissingRecipients,
} from 'helpers';
import * as config from 'config';

interface Props {
  suggestion: Suggestion;
  validation: SuggestionValidation;
  scrollIntoView?: boolean;
}

const Wrapper = styled.div`
  margin-bottom: ${grid(1)};
  border-radius: 4px;
  background: ${colors.error};
  padding: ${`${grid(0.5)} ${grid(1)}`};
  line-height: ${grid(2)};
  color: ${colors.white};
  font-size: 13px;
  font-weight: bold;

  > div {
    margin-bottom: ${grid(0.25)};

    &::before {
      display: inline-block;
      margin-right: ${grid(0.5)};
      content: '\u2022';
    }

    &:first-child:last-child::before {
      display: none;
    }
  }
`;

const TwitterButton = styled.button.attrs({ type: 'button' })`
  margin-left: ${grid(0.5)};
  text-decoration: underline;

  &:disabled {
    opacity: 0.8;
  }
`;

export default class SuggestionErrors extends React.Component<Props> {
  private wrapperRef: React.RefObject<HTMLDivElement> = React.createRef();

  componentDidUpdate(prevProps: Props) {
    const { validation, scrollIntoView } = this.props;
    const { validation: prevValidation } = prevProps;
    const validationChanged =
      !validation.isValid && !isEqual(validation, prevValidation);
    if (validationChanged && scrollIntoView) {
      this.scrollToValidation();
    }
  }

  scrollToValidation = () => {
    const wrapperEl = this.wrapperRef.current;
    if (!wrapperEl) return;
    scrollToElement(wrapperEl, { offset: -260, duration: 500 });
  };

  render() {
    const { suggestion, validation } = this.props;
    const errors: { [key: string]: boolean | string[] } = {};

    // Check the validation messages, but don't show them if the user has
    // already fixed the issue.  Validation is not always automatically updated
    // when the user makes changes to their suggestions
    errors.missingRecipients =
      !!validation.missingRecipients &&
      isSuggestionMissingRecipients(suggestion);

    errors.missingPlatforms =
      !!validation.missingPlatforms &&
      (!suggestion.drafts || !suggestion.drafts.length);

    errors.missingInstructions =
      !!validation.missingInstructions && !suggestion.instructions;

    errors.instructionsTooLong =
      !!validation.instructionsTooLong &&
      !!suggestion.instructions &&
      suggestion.instructions.length > 900;

    errors.subjectTooLong =
      !!validation.subjectTooLong &&
      !!suggestion.subject &&
      suggestion.subject.length > 255;

    errors.expiresAtBeforePostAt =
      !!validation.expiresAtBeforeAtPostAt &&
      !!suggestion.postAt &&
      !!suggestion.expiresAt &&
      suggestion.expiresAt < suggestion.postAt;

    errors.missingSubject = !!validation.missingSubject && !suggestion.subject;

    errors.platformsOverCharacterCount =
      validation.platformsOverCharacterCount.filter((platform) => {
        if (!suggestion.drafts) return false;
        const draft = suggestion.drafts.find((d) => d.platform === platform);
        return draft && getRemainingChars(draft.text, draft.platform) < 0;
      });

    errors.platformsMissingText = validation.platformsMissingText.filter(
      (platform) => {
        if (!suggestion.drafts) return false;
        const draft = suggestion.drafts.find((d) => d.platform === platform);
        return draft && !draft.text;
      }
    );

    errors.notLinkedToTwitter = !!validation.notLinkedToTwitter;

    const platforms =
      (suggestion.drafts && suggestion.drafts.map((d) => d.platform)) || [];

    errors.invalidPlatforms = intersection(
      platforms,
      validation.invalidPlatforms
    );

    errors.invalidPostAtTime =
      !!validation.invalidPostAtTime &&
      !isValidTimeDiff(
        suggestion.postAt,
        config.suggestionValidation.minPostAtLeadTime
      );

    errors.invalidExpiresAtTime =
      !!validation.invalidExpiresAtTime &&
      !isValidTimeDiff(
        suggestion.expiresAt,
        config.suggestionValidation.minExpiresAtLeadTime
      );

    // Check if any of our errors are true, or are non-empty arrays
    const hasErrors = !!values(errors).find(
      (e) => !!e && (!Array.isArray(e) || (Array.isArray(e) && !!e.length))
    );

    if (!hasErrors) return null;

    return (
      <Wrapper data-testid="suggestion-errors" ref={this.wrapperRef}>
        {errors.platformsOverCharacterCount.map((platform) => (
          <div key={platform}>
            <FormattedMessage
              id="DraftBuilder__OverCharacterLimit"
              values={{ platform: platformTitle(platform) }}
            />
          </div>
        ))}

        {errors.platformsMissingText.map((platform) => (
          <div key={platform}>
            <FormattedMessage
              id="DraftBuilder__PlatformMissingText"
              values={{ platform: platformTitle(platform) }}
            />
          </div>
        ))}

        {errors.invalidPlatforms.map((platform) => (
          <div key={platform}>
            <FormattedMessage
              id="DraftBuilder__InvalidAttachment"
              values={{ platform: platformTitle(platform) }}
            />
          </div>
        ))}

        {errors.missingRecipients && (
          <div>
            <FormattedMessage id="DraftBuilder__NoRecipientsError" />
          </div>
        )}

        {errors.missingSubject && (
          <div>
            <FormattedMessage id="DraftBuilder__NoSubjectError" />
          </div>
        )}

        {errors.subjectTooLong && (
          <div>
            <FormattedMessage id="DraftBuilder__SubjectTooLongError" />
          </div>
        )}

        {errors.missingInstructions && (
          <div>
            <FormattedMessage id="DraftBuilder__NoInstructionsError" />
          </div>
        )}

        {errors.instructionsTooLong && (
          <div>
            {suggestion.actionableType === 'Suggestion' ? (
              <FormattedMessage id="DraftBuilder__MessageTooLongError" />
            ) : (
              <FormattedMessage id="DraftBuilder__InstructionsTooLongError" />
            )}
          </div>
        )}

        {errors.missingPlatforms && (
          <div>
            <FormattedMessage id="DraftBuilder__NoPlatformsError" />
          </div>
        )}

        {errors.expiresAtBeforePostAt && (
          <div>
            <FormattedMessage id="DraftBuilder__ExpiresAtBeforePostAt" />
          </div>
        )}

        {errors.invalidPostAtTime && (
          <div>
            <FormattedMessage
              id="DraftBuilder__InvalidPostAtTime"
              values={{
                minutes: config.suggestionValidation.minPostAtLeadTime,
              }}
            />
          </div>
        )}

        {errors.invalidExpiresAtTime && (
          <div>
            <FormattedMessage
              id="DraftBuilder__InvalidExpiresAtTime"
              values={{
                minutes: config.suggestionValidation.minExpiresAtLeadTime,
              }}
            />
          </div>
        )}

        {errors.notLinkedToTwitter && (
          <LinkTwitter
            noPadding
            message="DraftBuilder__NotLinkedToTwitterError"
            renderButton={(onClick, isLinking) => (
              <TwitterButton onClick={onClick} disabled={isLinking}>
                <FormattedMessage id="LinkTwitter__Button" />
              </TwitterButton>
            )}
          />
        )}
      </Wrapper>
    );
  }
}
