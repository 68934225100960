import React from 'react';
import {
  Form,
  MediaSourceFilter,
  Loader,
  PublisherFilter,
  SuggesterFilter,
} from 'components';
import {
  AlbumFilter,
  MediaTypeFilter,
  SuggestedFilter,
} from 'screens/MediaLibrary/screens/MediaLibraryIndex/components/MediaLibraryFilters/components';
import MediaLibraryFilter from 'types/MediaLibraryFilter';
import MediaLibraryFilterData from 'types/MediaLibraryFilterData';

interface Props {
  loading?: boolean;
  data?: MediaLibraryFilterData;
  isAttachmentAlbumScreen?: boolean;
  filter: MediaLibraryFilter;
  updateFilter: (updates: Partial<MediaLibraryFilter>) => void;
}

const MediaLibraryBuilderModalFilter = (props: Props) => {
  const { loading, data, filter, updateFilter } = props;

  return (
    <Form>
      {loading || !data || !data.currentSuggester ? (
        <Loader delay={5000} />
      ) : (
        <>
          <SuggestedFilter />
          <MediaSourceFilter
            attachmentSources={data.currentSuggester.attachmentSources}
            value={filter.sources}
            onChange={(val) => updateFilter({ sources: val })}
          />
          <MediaTypeFilter
            value={filter.mediaType}
            onChange={(val: string[]) => updateFilter({ mediaType: val })}
          />
          <SuggesterFilter
            label="MediaLibrarySuggesterFilter__Label"
            filterKey="suggesters"
            filter={filter}
            updateFilter={updateFilter}
            renderHr={false}
          />
          <PublisherFilter
            label="MediaLibraryPublisherFilter__Label"
            filterKey="publishers"
            filter={filter}
            updateFilter={updateFilter}
          />

          {filter.suggested !== '0' && (
            <>
              <PublisherFilter
                label="MediaLibraryRecipientsFilter__Label"
                filterKey="suggestionRecipients"
                filter={filter}
                updateFilter={updateFilter}
              />
              <AlbumFilter
                albums={data?.suggestionAlbums || []}
                value={filter.suggestionAlbums}
                onChange={(val: (number | string)[]) =>
                  updateFilter({ suggestionAlbums: val })
                }
              />
            </>
          )}
        </>
      )}
    </Form>
  );
};
export default MediaLibraryBuilderModalFilter;
