import React from 'react';
import { Mutation } from '@apollo/react-components';
import gql from 'graphql-tag';
import User from 'types/User';
import SuggesterSwitcher from './SuggesterSwitcher';
import { routes } from 'helpers';
import Suggester from 'types/Suggester';
import SemiLoggedInUser from 'types/SemiLoggedInUser';
import SemiLoggedInSuggester from 'types/SemiLoggedInSuggester';

const MUTATION = gql`
  mutation SwitchSuggester($id: ID!) {
    switchSuggester(suggesterId: $id) {
      success
      token
    }
  }
`;

interface Props {
  currentUser: User | SemiLoggedInUser;
}

interface State {
  searchValue: string;
}

export interface Option {
  id: number;
  accountName: string;
  brand?: string;
  child?: boolean;
  accountConfiguration: {
    logoUrl?: string;
  };
}

interface MutationData {
  switchSuggester: { success: boolean; token: string };
}

class SuggesterSwitcherContainer extends React.Component<Props, State> {
  state = { searchValue: '' };

  changeSearchValue = (value: string) => this.setState({ searchValue: value });

  buildOptions = (): Option[] => {
    const opts: (Suggester | SemiLoggedInSuggester)[] = [];
    this.props.currentUser.suggesters.forEach((suggester) => {
      if (!this.includeSuggester(suggester)) return;

      opts.push(suggester);
      if (suggester.children.length) {
        opts.push(
          ...suggester.children
            .filter((s) => s.viewerIsMember && this.includeSuggester(s))
            .map((s) => ({ ...s, child: true }))
        );
      }
    });
    return opts;
  };

  includeSuggester = (suggester: Suggester | SemiLoggedInSuggester) => {
    const children = suggester.children || [];
    if (!this.state.searchValue) return true;
    if (!suggester || !suggester.accountName) {
      return false;
    }

    const searchValue = new RegExp(this.state.searchValue, 'i');

    return (
      !!suggester.accountName.match(searchValue) ||
      !!children.find((x) => !!x.accountName.match(searchValue))
    );
  };

  reloadPage = () => {
    const { protocol, host, pathname } = window.location;
    const nextPathname = pathname.match(/^\/create/)
      ? routes.draftBuilder.index
      : pathname === routes.auth.mfaSetup
      ? routes.mediaLibrary.index
      : pathname;
    window.location.href = `${protocol}//${host}${nextPathname}`;
  };

  render() {
    const currentSuggester = this.props.currentUser.currentSuggester!;
    const { searchValue } = this.state;

    const options = this.buildOptions();

    return (
      <Mutation<MutationData>
        mutation={MUTATION}
        onCompleted={(data) => {
          if (data.switchSuggester.success) {
            const token = data.switchSuggester.token;
            window.localStorage.setItem('satellizer_token', token);
            this.reloadPage();
          }
        }}
      >
        {(switchSuggester) => (
          <SuggesterSwitcher
            logoUrl={currentSuggester.accountConfiguration.logoUrl}
            accountName={currentSuggester.accountName}
            options={options}
            searchValue={searchValue}
            changeSearchValue={this.changeSearchValue}
            switchSuggester={(id: number) =>
              switchSuggester({ variables: { id } })
            }
          />
        )}
      </Mutation>
    );
  }
}
export default SuggesterSwitcherContainer;
