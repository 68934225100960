import React from 'react';
import debounce from 'lodash/debounce';
import { connect } from 'react-redux';
import {
  startBatchSuggestionSaveOperation,
  stopBatchSuggestionSaveOperation,
} from 'redux/ducks/ui';
import isEqual from 'lodash/isEqual';
import includes from 'lodash/includes';
import { FormattedMessage } from 'react-intl';
import Suggestion from 'types/Suggestion';
import SuggestionParams from 'types/SuggestionParams';
import { Form, SuggestionRecipientsField, PlatformToggles } from 'components';
import updateSuggestion from 'graphql/operations/updateSuggestion';
import * as config from 'config';
import { IconPerson, IconSettings } from 'icons';
import {
  Albums,
  Captions,
  Campaign,
  CanMonetize,
  ExampleContent,
  ExpiresAt,
  MediaType,
  Orientation,
  Tags,
  Scheduling,
  Subject,
  Instructions,
  TalkingPoints,
  VideoLength,
  Expiration,
} from '../SuggestionForm/components';
import { Link } from 'react-router-dom';
import { routes } from 'helpers';

interface OwnProps {
  suggestions: Suggestion[];
  actionableType: string;
  selectedSuggestions?: Suggestion[];
  platforms?: string[];
  fields?: string[];
}

interface ConnectedActions {
  startSaving: (operation: string) => void;
  stopSaving: (operation: string) => void;
}

type Props = OwnProps & ConnectedActions;

export const allFields = [
  'recipients',
  'mediaType',
  'subject',
  'instructions',
  'talkingPoints',
  'orientation',
  'videoLength',
  'exampleContent',
  'expiresAt',
  'platforms',
  'captions',
  'tracking',
  'scheduling',
  'expiration',
  'canMonetize',
  'albums',
];

class SuggestionForm extends React.Component<Props> {
  private nextUpdates: Partial<SuggestionParams> = {};

  constructor(props: Props) {
    super(props);
    this.saveUpdates = debounce(this.saveUpdates, 250);
  }

  state = {
    attachmentAlbumIds: [],
  };

  shouldComponentUpdate(nextProps: Props) {
    return !isEqual(nextProps, this.props);
  }

  // Batch any updates into one big object and then fire a debounced function
  // so that we're not hitting the server non-stop, but also not skipping any
  // changes if someone changes multiple fields quickly
  updateSuggestions = async (params: Partial<SuggestionParams>) => {
    this.props.startSaving('update');
    this.nextUpdates = { ...this.nextUpdates, ...params };
    this.saveUpdates();
  };

  saveUpdates = () => {
    const params = this.nextUpdates;
    this.props.suggestions.forEach(async (suggestion) => {
      const data = await updateSuggestion(suggestion.id, params, true);
      if (data && data.errors) {
        console.log(data.errors);
      }
    });
    this.props.stopSaving('update');
    this.nextUpdates = {};
  };

  render() {
    const { suggestions, platforms, actionableType } = this.props;
    const fields = this.props.fields || allFields;
    const hasField = (field: string) => includes(fields, field);

    return (
      <Form>
        {hasField('recipients') && (
          <Form.Field data-qa="builder-suggestion-form-builder-suggestion-form-publishers">
            <Form.Label bold>
              <FormattedMessage id="SuggestionForm__Publishers" />
            </Form.Label>
            <SuggestionRecipientsField
              suggestions={suggestions}
              icon={<IconPerson />}
            />
          </Form.Field>
        )}

        {actionableType !== 'Suggestion' && (
          <>
            {hasField('mediaType') && (
              <MediaType
                suggestions={suggestions}
                onChange={(mediaType: string) =>
                  this.updateSuggestions({ mediaType })
                }
              />
            )}

            {hasField('subject') && (
              <Subject
                suggestions={suggestions}
                onChange={(subject) => this.updateSuggestions({ subject })}
              />
            )}
          </>
        )}

        {hasField('instructions') && (
          <Instructions
            suggestions={suggestions}
            onChange={(value?: string) =>
              this.updateSuggestions({ instructions: value })
            }
          />
        )}

        {(suggestions[0].mediaType === 'Video' ||
          suggestions[0].mediaType === 'Any') &&
          hasField('talkingPoints') && (
            <TalkingPoints
              suggestions={suggestions}
              onChange={(talkingPoints: string[]) =>
                this.updateSuggestions({
                  talkingPoints: talkingPoints.map((t) => ({ text: t })),
                })
              }
            />
          )}

        {actionableType === 'ContentRequest' && hasField('orientation') && (
          <Orientation
            suggestions={suggestions}
            onChange={(orientation) => this.updateSuggestions({ orientation })}
          />
        )}

        {actionableType === 'ContentRequest' &&
          (suggestions[0].mediaType === 'Any' ||
            suggestions[0].mediaType === 'Video') &&
          hasField('videoLength') && (
            <VideoLength
              suggestions={suggestions}
              onChange={(videoLength) =>
                this.updateSuggestions({ videoLength })
              }
            />
          )}

        {actionableType === 'ContentRequest' && hasField('exampleContent') && (
          <ExampleContent
            suggestions={suggestions}
            onChange={(exampleContent) =>
              this.updateSuggestions({ exampleContent })
            }
            onChangeTitle={(exampleContentTitle) =>
              this.updateSuggestions({ exampleContentTitle })
            }
            onChangeDescription={(exampleContentDescription) =>
              this.updateSuggestions({ exampleContentDescription })
            }
          />
        )}

        {actionableType !== 'ContentRequest' && hasField('platforms') && (
          <Form.Field>
            <Form.Label bold>
              <FormattedMessage id="SuggestionForm__Platforms" />
            </Form.Label>

            <PlatformToggles
              suggestions={suggestions}
              platforms={platforms || config.platforms}
            />
          </Form.Field>
        )}

        {actionableType !== 'ContentRequest' && hasField('captions') && (
          <Captions
            suggestions={suggestions}
            platforms={platforms || config.platforms}
          />
        )}

        {actionableType !== 'ContentRequest' && hasField('albums') && (
          <Albums suggestions={suggestions} />
        )}

        {hasField('tracking') && (
          <Form.Field>
            <div className="flex items-center justify-between">
              <Form.Label bold>
                <FormattedMessage id="SuggestionForm__Tracking" />
              </Form.Label>
              <Link
                to={routes.settings.manageTags}
                className="flex items-center text-grey6 hover:text-grey5"
              >
                <span className="bump-up-2">
                  <FormattedMessage id="Global__Manage" />
                </span>
                <IconSettings
                  className="ml-1 h-2 w-2"
                  data-qa="builder-suggestion-form-suggestion-form-manage-tags-button"
                />
              </Link>
            </div>

            <Campaign suggestions={suggestions} />
            <Tags suggestions={suggestions} />
          </Form.Field>
        )}

        {actionableType !== 'ContentRequest' && hasField('scheduling') && (
          <Scheduling
            suggestions={suggestions}
            onChange={(postAt: Date | null) =>
              this.updateSuggestions({ postAt })
            }
          />
        )}

        {actionableType === 'Suggestion' && hasField('expiration') && (
          <Expiration
            suggestions={suggestions}
            onChange={(expiresAt: Date | null) =>
              this.updateSuggestions({ expiresAt })
            }
          />
        )}

        {actionableType === 'ContentRequest' && hasField('expiresAt') && (
          <ExpiresAt
            suggestions={suggestions}
            onChange={(expiresAt: Date) =>
              this.updateSuggestions({ expiresAt })
            }
          />
        )}

        {actionableType === 'Suggestion' &&
          hasField('canMonetize') &&
          suggestions.every((s) => !!s.attachments.length) && (
            <CanMonetize
              suggestions={suggestions}
              onChange={(canMonetize) =>
                this.updateSuggestions({ canMonetize })
              }
            />
          )}
      </Form>
    );
  }
}

const mapDispatchToProps = {
  startSaving: startBatchSuggestionSaveOperation,
  stopSaving: stopBatchSuggestionSaveOperation,
};

export default connect<OwnProps, ConnectedActions>(
  null,
  mapDispatchToProps
)(SuggestionForm);
