import React from 'react';
import { FormattedMessage } from 'react-intl';
import IndexSuggestion from 'types/IndexSuggestion';
import BatchSuggestion from 'types/BatchSuggestion';
import IndexBatchSuggestion from 'types/IndexBatchSuggestion';
import { FormattedDateTime, Avatar, Tooltip } from '..';
import styled, { ThemeProvider } from 'styled-components';
import { grid, colors } from 'styles/theme';
import { useCurrentUser, useIntl } from 'hooks';

interface Props {
  suggestion: IndexSuggestion | BatchSuggestion | IndexBatchSuggestion;
  context: string;
  showDate?: boolean;
}

// prettier-ignore
const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  line-height: ${grid(3)};
  color: ${colors.bodyText};
  font-size: 12px;

  ${(props: any) => props.theme.context === 'detail' && `
    margin-right: ${grid(2)};
    color: ${colors.lightText};
    font-size: 14px;
  `}
` as any;

const Sender = styled.span`
  margin-right: ${grid(0.5)};
`;

const From = styled.span`
  text-transform: lowercase;
`;

const SuggesterTooltip = styled.div`
  display: flex;
  align-items: center;
`;

// prettier-ignore
const LogoWrapper = styled.div`
  flex-shrink: 0;
  margin-right: ${grid(0.5)};
  width: ${grid(3)};
  height: ${grid(3)};

  ${(props: any) => props.tooltip && `
    margin-right: ${grid(1)};
    width: ${grid(4)};
    height: ${grid(4)};
  `}

  ${(props: any) => props.theme.context === 'detail' && `
    margin-right: ${grid(1)};
  `};
` as any;

const CreatedAt = styled.span`
  color: ${colors.lightText};
`;

const SuggestionSender = ({ suggestion, showDate, context }: Props) => {
  const { t, messages } = useIntl();
  const { sender, source, sentAt, createdAt, suggester } = suggestion;
  const currentUser = useCurrentUser();
  const integrationSourceTitle = `Integrations--${source}`;
  const hasTranslation = !!messages[integrationSourceTitle];
  const showLogo =
    !!currentUser.currentSuggester?.children.length ||
    suggester.id !== currentUser.currentSuggester?.id;

  return (
    <ThemeProvider theme={{ context }}>
      <Wrapper>
        {(sender || (source && hasTranslation)) && (
          <Sender>
            <From>
              <FormattedMessage id="Global__From" />{' '}
            </From>

            {sender && (
              <>
                {!!sender.firstName && `${sender.firstName} `}
                {sender.lastName}
                {!sender.firstName && !sender.lastName && sender.email}
              </>
            )}

            {hasTranslation && t(integrationSourceTitle)}
          </Sender>
        )}

        {showLogo && (
          <Tooltip
            content={
              <SuggesterTooltip>
                <LogoWrapper tooltip>
                  <Avatar src={suggester.accountConfiguration.logoUrl} />
                </LogoWrapper>
                {suggester.accountName}
              </SuggesterTooltip>
            }
          >
            <LogoWrapper>
              <Avatar src={suggester.accountConfiguration.logoUrl} />
            </LogoWrapper>
          </Tooltip>
        )}

        {showDate && (
          <CreatedAt>
            <FormattedMessage
              id={sentAt ? 'TrackingList__Sent' : 'TrackingList__Created'}
            />{' '}
            <FormattedDateTime value={sentAt || createdAt} separator=", " />
          </CreatedAt>
        )}
      </Wrapper>
    </ThemeProvider>
  );
};

export default SuggestionSender;
