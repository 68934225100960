import React from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { useToggle } from 'hooks';
import { IconCaratDown } from 'icons';
import { grid, colors } from 'styles/theme';

interface Props {
  title: string;
  children: React.ReactNode;
  isCollapsable?: boolean;
  defaultOpen?: boolean;
  hasTotalItemsPill?: boolean;
  totalItemsPillAmount?: number;
}

const Wrapper = styled.div`
  margin-bottom: ${grid(2)};
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${grid(0.5)};
  color: ${colors.lightText};
  font-style: italic;

  svg {
    display: block;
    position: relative;
    top: 1px;
    transform: rotate(-90deg);
    margin-right: ${grid(0.5)};
    width: ${grid(1.5)};
    height: ${grid(1.5)};

    ${({ theme }: any) => theme.isOpen && 'transform: none'};
  }
`;

const Content = styled.div`
  display: none;
  border-radius: 8px;
  background: ${colors.white};
  padding: ${grid(1.5)};

  ${({ theme }: any) => theme.isOpen && 'display: block'};
`;

export default function InfoCard(props: Props) {
  const {
    title,
    children,
    isCollapsable = true,
    defaultOpen = true,
    totalItemsPillAmount,
  } = props;

  const [isOpen, toggleOpen] = useToggle(defaultOpen);

  return (
    <ThemeProvider theme={{ isOpen: isOpen || !isCollapsable }}>
      <Wrapper>
        <Title
          onClick={toggleOpen}
          className="flex items-center justify-between"
        >
          <div className="flex items-center">
            {isCollapsable && <IconCaratDown />}

            {title}
          </div>
          {!isOpen && (totalItemsPillAmount || totalItemsPillAmount === 0) && (
            <div className="flex h-2 items-center rounded-full bg-grey5 px-1">
              <p className="bump-up-1 flex items-center text-sm font-semibold not-italic text-white">
                {totalItemsPillAmount}
              </p>
            </div>
          )}
        </Title>

        <Content>{children}</Content>
      </Wrapper>
    </ThemeProvider>
  );
}
