import groupBy from 'lodash/groupBy';
import Draft from 'types/Draft';

interface Suggestion {
  drafts?: Draft[];
}

interface GroupedDrafts {
  [key: string]: Draft[];
}

const getGroupedDrafts = (suggestion: Suggestion): GroupedDrafts => {
  if (!suggestion.drafts) return {};
  return groupBy(suggestion.drafts, (d) => d.text || 'NO_CAPTION');
};

export default getGroupedDrafts;
