import React from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { IconWarning } from 'icons';
import { colors, grid } from 'styles/theme';
import { Tooltip } from 'components';

interface Props {
  theme?: { small?: boolean };
}

// prettier-ignore
const Wrapper = styled.div`
  position: relative;
  top: 1px;
  margin-left: ${grid(0.5)};
  width: ${grid(2)};
  height: ${grid(2)};
  color: ${colors.yellow};

  ${(props: any) => props.theme.small && `
    top: -2px;
    margin-left: 0;
    width: ${grid(1.75)};
    height: ${grid(1.75)};
  `}

  svg {
    width: 100%;
    height: 100%;
  }
`;

const DoNotCallWarning = ({ theme }: Props) => (
  <ThemeProvider theme={theme || {}}>
    <Tooltip
      theme="socialieDark"
      content={<FormattedMessage id="DoNotCallWarning__Tooltip" />}
    >
      <Wrapper data-testid="opt-out-warning">
        <IconWarning />
      </Wrapper>
    </Tooltip>
  </ThemeProvider>
);

export default DoNotCallWarning;
