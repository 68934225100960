import React from 'react';
import styled from 'styled-components';
import { grid, colors } from 'styles/theme';
import { FormattedMessage } from 'react-intl';
import castArray from 'lodash/castArray';

interface Props {
  errors?: string | string[] | null;
  translate?: boolean;
  children?: React.ReactNode;
}

const Wrapper = styled.div`
  line-height: ${grid(2)};
  color: ${colors.error};
  font-size: 12px;
`;

const Errors = ({ errors, children, translate = true }: Props) => (
  <Wrapper>
    {castArray(errors).map((error, i) => {
      if (!error) return null;

      return (
        <div key={i}>{translate ? <FormattedMessage id={error} /> : error}</div>
      );
    })}

    {children}
  </Wrapper>
);

export default Errors;
