import React, { useEffect, useRef, useState } from 'react';
import ClickOutside from 'react-onclickout';
import { formatFileSize } from 'helpers/formatNumber';
import { useIntl } from 'hooks';
import { IconEditFilled, IconRemove } from 'icons';
import { Form } from 'components';
import MediaUploadThumbnail from './MediaUploadThumbnail';

const MAX_FILE_NAME_LENGTH = 250;

interface Props {
  file: File;
  assetId: string;
  onRemove: () => void;
  onFileRename: (assetId: string, newName: string) => void;
}

const stripFileExtension = (fileName: string) => {
  const dotIndex = fileName.lastIndexOf('.');
  return fileName.substring(0, dotIndex);
};

export default function MediaUploadStagedFileRow(props: Props) {
  const { file, assetId, onRemove, onFileRename } = props;

  const [fileName, setFileName] = useState(() => stripFileExtension(file.name));

  const { t } = useIntl();

  const [isEditingFileName, setIsEditingFileName] = useState(false);
  const textInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (isEditingFileName && textInputRef.current) {
      textInputRef.current.focus();
    }
  }, [isEditingFileName]);

  return (
    <div className="mb-2 flex items-center">
      <div className="mr-2">
        <MediaUploadThumbnail
          contentType={file.type}
          filename={file.name}
          previewUrl={URL.createObjectURL(file)}
        />
      </div>
      <button
        className="-outline-offset-6 mr-1 h-3 w-3 pt-0.25"
        onClick={() => setIsEditingFileName(!isEditingFileName)}
      >
        <IconEditFilled className="bg-gry4" />
      </button>

      <div className="mr-3 flex-1 truncate text-left text-16 text-dark">
        {isEditingFileName ? (
          <ClickOutside
            onClickOut={() => {
              onFileRename(assetId, fileName);
              setIsEditingFileName(false);
            }}
          >
            <label id="mediaUploadStagedFileRowRenameInput" className="sr-only">
              {t('MediaUpload__RenameFile')}
            </label>

            <Form.TextInput
              id="mediaUploadStagedFileRowRenameInput"
              value={fileName}
              onChange={(e) => {
                const value = e.target.value;
                // Check if the length is within the maximum limit
                if (value.length > MAX_FILE_NAME_LENGTH) return;
                onFileRename(assetId, value);
                setFileName(value);
              }}
              autoFocus
            />
          </ClickOutside>
        ) : (
          file.name
        )}
      </div>

      <div className="flex flex-shrink-0 items-center justify-end text-16">
        {formatFileSize(file.size)}

        <button type="button" className="ml-1 h-2 w-2" onClick={onRemove}>
          <IconRemove className="h-full w-full text-grey6 hover:text-grey5" />
        </button>
      </div>
    </div>
  );
}
