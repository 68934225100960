import React from 'react';
import styled, { keyframes } from 'styled-components';
import { RouteComponentProps } from 'react-router-dom';
import { grid, colors } from 'styles/theme';
import { rgba } from 'polished';
import { WithRouter } from '..';
import { hasClass } from 'helpers';

interface Props {
  children?: React.ReactNode;
  heading?: boolean;
  to?: string;
  card?: boolean;
  isSelected?: boolean;
  alignTop?: boolean;
  isExpanded?: boolean;
  style?: React.ComponentProps<'div'>['style'];
}

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
`;

// prettier-ignore
const StyledRow = styled.div.attrs({ className: 'Row' })`
  display: flex;
  align-items: center;
  transition: box-shadow 0.2s;
  border-bottom: 1px solid ${colors.border};
  padding: ${grid(2.5)} ${grid(1.5)};
  animation: ${fadeIn} 0.3s forwards;
  position: relative;

  &:last-child {
    border-bottom: 0;
  }

  ${(props: any) => props.heading && `
    font-size: 14px;
    color: ${colors.lightText};
    padding: ${grid(1)} ${grid(1.5)};
    border-bottom: 2px solid ${colors.border};

    &:last-child {
      border-bottom: 2px solid ${colors.border};
    }
  `}

  ${(props: any) => props.onClick && `
    cursor: pointer;
    &:hover {
      background-color: ${colors.hoverBg};
    }
  `};

  ${(props: any) => props.isSelected && `
    background-color: ${rgba(colors.greyBg, 0.5)};

    &:hover {
      background-color: ${rgba(colors.greyBg, 0.5)};
    }
  `};

  ${(props: any) => props.alignTop && 'align-items: flex-start'};

  ${(props: any) => props.isExpanded && `
    background: ${colors.white};
    display: block;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.12), 0 2px 8px 0 rgba(0,0,0,0.16);
  `};

  ${(props: any) => props.card && `
    margin-bottom: ${grid(3)};
    border-radius: 4px;
    border-bottom: 0;
    background-color: ${colors.white};

    &:hover {
      background-color: ${colors.white};
    }
  `}
` as any;

// prettier-ignore
const Col = styled.div`
  flex-shrink: 0;
  margin-right: ${grid(2)};
  overflow: hidden;
  &:last-child {
    margin-right: 0;
  }

  ${(props: any) => props.width && `width: ${props.width}`};
  ${(props: any) => props.maxWidth && `max-width: ${props.maxWidth}`};

  ${(props: any) => props.stretch && `
    flex: 1;
    flex-shrink: 1;
  `};
  ${(props: any) => props.flex && `
    display: flex;
    align-items: center;
  `}

  ${(props: any) => props.alignTop && 'align-items: flex-start'};

  ${(props: any) => props.right && `
    margin-left: auto;
  `};

  ${(props: any) => props.showOverflow && 'overflow: visible'};
` as any;

export default class Row extends React.Component<Props> {
  public static Col = Col;

  navigate = (event: any, history: RouteComponentProps<any>['history']) => {
    // Ignore clicks from modals
    if (!hasClass(event.target, 'Row')) return;
    if (event.ctrlKey || event.metaKey) {
      window.open(this.props.to);
    } else {
      history.push(this.props.to!);
    }
  };

  render() {
    const { children, to, ...props } = this.props;
    if (!to) return <StyledRow {...props}>{children}</StyledRow>;

    return (
      <WithRouter>
        {({ history }) => {
          return (
            <StyledRow
              {...props}
              onClick={(e: any) => this.navigate(e, history)}
              onMouseUp={(e: any) => {
                if (e.nativeEvent.which === 2) window.open(to);
              }}
            >
              {children}
            </StyledRow>
          );
        }}
      </WithRouter>
    );
  }
}
