import clsx from 'clsx';
import EnhancedNavLink from 'components/EnhancedNavLink';
import FlyoutMenu from 'components/FlyoutMenu/FlyoutMenu';
import { IconGear } from 'icons';
import React, { createElement, useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { NavLinkProps } from 'react-router-dom';

type SubItem = {
  path: string;
  label: string;
  exact?: boolean;
};

type NavItemProps = NavLinkProps & {
  label: string;
  icon: typeof IconGear;
  theme?: 'socialie' | 'athlete';
  subItems?: SubItem[];
};

export default function MainNavItem({
  icon,
  label,
  theme = 'socialie',
  to,
  subItems,
  ...navLinkProps
}: NavItemProps) {
  const textRef = useRef<HTMLSpanElement>(null);
  const [textWidth, setTextWidth] = useState(0);

  useEffect(() => {
    if (textRef.current) {
      setTextWidth(textRef.current.offsetWidth);
    }
  }, []);

  return (
    <FlyoutMenu
      disabled={!subItems}
      theme="dark"
      renderButton={(onClick) => (
        <div>
          <EnhancedNavLink
            {...navLinkProps}
            to={to}
            className="
        group relative mb-2 mr-4 flex items-center overflow-hidden pb-[3px]
        tablet:mb-0 tablet:mr-1 tablet:h-4.5 tablet:rounded-[18px] tablet:px-2 tablet:pb-0 tablet:text-lighterText tablet:transition-all tablet:duration-300 tablet:hover:bg-grey3"
            activeClassName="text-socialiePink tablet:bg-grey3 tablet:text-white tablet:w-[var(--activeWidth)] large:w-auto"
            inactiveClassName="text-dark tablet:w-6.5 tablet:hover:w-[var(--activeWidth)] large:w-auto large:hover:w-auto"
            style={
              {
                '--activeWidth': `${textWidth + 16 + 20 + 8 + 16}px`,
              } as React.CSSProperties
            }
            onClick={
              subItems
                ? (e) => {
                    e.preventDefault();
                    onClick(e);
                    navLinkProps.onClick?.(e);
                  }
                : navLinkProps.onClick
            }
          >
            {({ isActive }) => (
              <>
                <div
                  className={clsx(
                    'h-2.5 w-2.5 flex-shrink-0 rounded large:mr-1',
                    {
                      'bg-socialiePink': theme === 'socialie' && isActive,
                      'bg-purple': theme === 'athlete' && isActive,
                      'bg-transparent': !isActive,
                    },
                    isActive && 'mr-1'
                  )}
                >
                  {createElement(icon, {
                    className: clsx(
                      'w-full h-full transition-colors duration-300',
                      isActive
                        ? 'text-white'
                        : 'text-lightText tablet:group-hover:text-white'
                    ),
                    style: { transform: isActive ? `scale(0.7)` : `scale(1)` },
                  })}
                </div>
                <span
                  ref={textRef}
                  className={clsx(
                    'static transition-opacity tablet:absolute tablet:left-5.5 tablet:top-0.75 tablet:text-14 large:static',
                    isActive
                      ? 'tablet:opacity-100'
                      : 'tablet:opacity-0 tablet:group-hover:opacity-100 large:opacity-100'
                  )}
                >
                  <span className="bump-up-1">
                    <FormattedMessage id={label} />
                  </span>
                </span>
              </>
            )}
          </EnhancedNavLink>
        </div>
      )}
    >
      {subItems ? (
        <>
          {subItems.map((menuItem) => (
            <FlyoutMenu.Item
              key={menuItem.path}
              to={menuItem.path}
              showArrow="when-active"
            >
              {menuItem.label}
            </FlyoutMenu.Item>
          ))}
        </>
      ) : (
        <></>
      )}
    </FlyoutMenu>
  );
}
