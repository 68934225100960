import React, { ReactNode } from 'react';
import { useIsMobile } from 'hooks';

interface MediaLibraryBuilderModalFilterWrapperProps {
  isOpen: boolean;
  onCloseHandler: () => void;
  children: ReactNode;
}

function MediaLibraryBuilderModalFilterWrapper({
  isOpen,
  onCloseHandler,
  children,
}: MediaLibraryBuilderModalFilterWrapperProps) {
  const classNames = isOpen ? 'block' : 'hidden';

  const { isMobile } = useIsMobile();

  return (
    <>
      <div
        className={`w-31.5 flex-shrink-0  ${classNames} ${
          isMobile &&
          'absolute top-0 z-10 h-screen w-full bg-black bg-opacity-20'
        }`}
        onClick={onCloseHandler}
      />
      <div className={`absolute w-31.5 bg-grey8 ${classNames} `}>
        <div
          className={`fixed h-screen w-31.5 justify-center overflow-auto bg-grey8 px-2 pb-30 pt-3.5 ${classNames} ${
            isMobile && 'right-0 top-0 z-10'
          }`}
        >
          {children}
        </div>
      </div>
    </>
  );
}

export default MediaLibraryBuilderModalFilterWrapper;
