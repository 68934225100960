import React from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { Form } from '..';
import { grid, colors } from 'styles/theme';

interface Props {
  selectAll: () => void;
  deselectAll: () => void;
  selectedIds: (number | string)[];
  loadedIds: (number | string)[];
  renderActions: React.ReactNode;
  renderGlobalActions?: React.ReactNode;
  theme?: any;
  alwaysShowCount?: boolean;
}

// prettier-ignore
const Wrapper = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 2px solid ${colors.border};
  padding: 0 ${grid(1.5)};
  height: ${grid(7)};

  ${(props: any) => props.theme.builder && `
    border: none;
    height: ${grid(4.25)};
    padding: 0;
  `};
`;

// prettier-ignore
const Count = styled.div`
  display: flex;
  align-items: center;
  margin-left: ${grid(1)};
  line-height: ${grid(2)};
  color: ${colors.darkText};
  font-size: 14px;

  ${(props: any) => props.theme.builder && `
    color: ${colors.lightText};
  `};

  button {
    margin-left: ${grid(1)};
    color: ${colors.socialiePink};
  }
`;

const Actions = styled.div`
  display: flex;
  align-items: center;
  margin-left: ${grid(2)};

  > button {
    margin-right: ${grid(1)};

    &:last-child {
      margin-right: 0;
    }
  }
`;

const GlobalActions = styled(Actions)`
  margin-left: auto;
`;

const SelectionManagement = (props: Props) => {
  const {
    selectAll,
    deselectAll,
    selectedIds,
    loadedIds,
    renderActions,
    renderGlobalActions,
    theme,
    alwaysShowCount,
  } = props;

  return (
    <ThemeProvider theme={theme || {}}>
      <Wrapper>
        {!!loadedIds.length && (
          <Form.Checkbox
            onChange={!selectedIds.length ? selectAll : deselectAll}
            checked={!!selectedIds.length}
            dash={loadedIds.length > selectedIds.length}
            dark={theme && theme.builder}
          />
        )}

        {!!selectedIds.length && !alwaysShowCount && (
          <Count>
            <FormattedMessage
              id="SelectionManagement__Count"
              values={{ count: selectedIds.length }}
            />
          </Count>
        )}

        {alwaysShowCount && (
          <Count>
            <div>
              <FormattedMessage
                id="SelectionManagement__AlwaysCount"
                values={{
                  count: selectedIds.length,
                  totalCount: loadedIds.length,
                }}
              />
            </div>

            {!selectedIds.length ? (
              <button onClick={selectAll}>
                <FormattedMessage id="SelectionManagement__SelectAll" />
              </button>
            ) : (
              <button onClick={deselectAll}>
                <FormattedMessage id="SelectionManagement__ClearSelection" />
              </button>
            )}
          </Count>
        )}

        {!!selectedIds.length && <Actions>{renderActions}</Actions>}

        {!!renderGlobalActions && (
          <GlobalActions>{renderGlobalActions}</GlobalActions>
        )}
      </Wrapper>
    </ThemeProvider>
  );
};
export default SelectionManagement;
