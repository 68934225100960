import React from 'react';
import { useIntl } from 'hooks';
import { UpdateFilterFn } from 'hooks/useFilterManager';
import Form from './Form';
import PillBadge from './PillBadge';
import { IconClose } from 'icons';

interface Props {
  filter: { query: string };
  updateFilter: UpdateFilterFn<{ query: string }>;
}

export default function QueryFilter({ filter, updateFilter }: Props) {
  const query = filter.query || '';
  const { t } = useIntl();
  if (!query) return null;
  return (
    <>
      <Form.Field>
        <Form.Label bold>{t('QueryFilter__Label')}</Form.Label>
        <PillBadge className="!inline-flex">
          {query}

          <button
            onClick={() => updateFilter({ query: '' })}
            aria-label={t('Global__Delete')}
          >
            <IconClose className="ml-1 h-1 w-1" />
          </button>
        </PillBadge>
      </Form.Field>
      <hr />
    </>
  );
}
