import React from 'react';
import styled from 'styled-components';
import { grid } from 'styles/theme';
import { Pills, DoNotCallWarning } from 'components';
import Recipient from 'types/Recipient';
import { getFormattedContactMethod } from 'helpers';
import ContactMethodTooltip from './ContactMethodTooltip';

interface Props {
  recipient: Recipient;
  selectProps: any;
}

const Inner = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

const Name = styled.div`
  margin-right: ${grid(0.5)};
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: ${grid(2)};
  word-break: break-all;
`;

const ContactMethodPill = ({ recipient, selectProps }: Props) => {
  if (!recipient.contactMethod) return null;

  const value = getFormattedContactMethod(recipient.contactMethod);

  return (
    <Pills.Pill
      image={recipient.contactMethod.publisher.image}
      {...selectProps.selectProps.pillProps}
      onRemove={selectProps.removeProps.onClick}
      removeProps={selectProps.removeProps}
    >
      <Inner>
        <Name>
          {recipient.contactMethod.publisher.name}
          {recipient.contactMethod.name && (
            <span> ({recipient.contactMethod.name})</span>
          )}
        </Name>
        <ContactMethodTooltip
          protocol={recipient.contactMethod.protocol}
          value={value}
          name={recipient.contactMethod.name}
        />
        {recipient.contactMethod.doNotCall && (
          <DoNotCallWarning theme={{ small: true }} />
        )}
      </Inner>
    </Pills.Pill>
  );
};

export default ContactMethodPill;
