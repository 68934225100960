import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { Form, WithCurrentUser, WithIntl } from 'components';
import { grid } from 'styles/theme';
import Suggester from 'types/Suggester';

interface Props {
  currentUserOnly: boolean;
  onChange: (val: boolean) => void;
  onChangeSuggesters: (suggesterIds: number[]) => void;
  selectedSuggesterIds: number[];
}

const SelectWrapper = styled.div`
  margin-bottom: ${grid(0.5)};
`;

const buildSuggesterOption = (suggester: Suggester) => ({
  value: suggester.id,
  label: suggester.accountName,
  image: suggester.accountConfiguration.logoUrl,
});

const SenderFilter = (props: Props) => {
  const {
    currentUserOnly,
    selectedSuggesterIds,
    onChange,
    onChangeSuggesters,
  } = props;

  return (
    <Form.Field>
      <Form.Label bold>
        <FormattedMessage id="TrackingSenderFilter__Label" />
      </Form.Label>

      <WithCurrentUser>
        {(currentUser) => {
          if (!currentUser || !currentUser.currentSuggester) return null;
          const childGroups = currentUser.currentSuggester.children;
          if (!childGroups || !childGroups.length) return null;

          const options = [
            buildSuggesterOption(currentUser.currentSuggester),
            ...childGroups.map((g) => buildSuggesterOption(g)),
          ];

          return (
            <WithIntl>
              {(_, t) => (
                <SelectWrapper>
                  <Form.Select
                    isMulti
                    onChange={onChangeSuggesters}
                    value={selectedSuggesterIds}
                    options={options}
                    isClearable={false}
                    placeholder={t('TrackingSenderFilter__Account')}
                  />
                </SelectWrapper>
              )}
            </WithIntl>
          );
        }}
      </WithCurrentUser>

      <Form.Option>
        <Form.Checkbox
          checked={currentUserOnly}
          onChange={() => onChange(!currentUserOnly)}
        />
        <span>
          <FormattedMessage id="TrackingSenderFilter__FromMe" />
        </span>
      </Form.Option>
    </Form.Field>
  );
};

export default SenderFilter;
