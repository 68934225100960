import React from 'react';
import { AuthForm, Button } from 'components';
import { useIntl } from 'hooks';
import { logOut } from 'helpers';

interface Props {
  user: {
    publisherRedirectUrl?: string;
  };
}

export default function NoCurrentSuggester({ user }: Props) {
  const { t } = useIntl();

  return (
    <AuthForm>
      <div className="text-center">
        <div className="mb-3 leading-20 text-dark">
          {t('NoCurrentSuggester__Heading')}
        </div>

        {user.publisherRedirectUrl && (
          <div className="mb-1">
            <Button filledBg href={user.publisherRedirectUrl}>
              {t('NoCurrentSuggester__VisitPublisher')}
            </Button>
          </div>
        )}
        <button
          onClick={() => logOut()}
          className="text-socialiePink hover:underline"
        >
          {t('NoCurrentSuggester__LogOut')}
        </button>
      </div>
    </AuthForm>
  );
}
