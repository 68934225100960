import React from 'react';
import PublisherUser from 'types/PublisherUser';
import { useIntl } from 'hooks';
import Form from './Form';

interface Props {
  value: PublisherUser['role'];
  onChange: (role: PublisherUser['role']) => void;
}

export default function PublisherUserRoleSelect(props: Props) {
  const { t } = useIntl();
  const { value, onChange } = props;
  const roles: Array<Props['value']> = ['read_only', 'member', 'owner'];
  const roleOptions = roles.map((role) => ({
    value: role,
    label: t(`PublisherUserRoleSelect__Option--${role}`),
  }));

  return (
    <Form.Field>
      <Form.Label bold>{t('PublisherUserRoleSelect__Label')}</Form.Label>
      <Form.Select value={value} onChange={onChange} options={roleOptions} />
      <div className="mt-0.5 text-12 leading-tight text-light">
        {t(`PublisherUserRoleSelect__Description--${value}`)}
      </div>
    </Form.Field>
  );
}
