import React from 'react';
import styled from 'styled-components';
import { sizes, colors } from 'styles/theme';
import { IconFilters, IconArrowBack } from 'icons';
import { useIntl } from 'hooks';
import Search from 'components/Search';
import { GlobalCSSVariables } from 'Layout/components/GlobalStyle';

export interface SearchProps {
  onChange: (val: string) => void;
  value: string;
  placeholder?: string;
}

interface Props {
  areFiltersOpen?: boolean;
  toggleFilters: () => void;
  searchProps?: SearchProps;
  isSearchEnabled?: boolean;
  hasFilters?: boolean;
  isFiltered?: boolean;
  renderSubNav?: React.ReactNode;
  renderSubNavLeft?: boolean;
}

const Wrapper = styled.div`
  position: fixed;
  top: var(${GlobalCSSVariables.topOffset});
  right: 0;
  left: 0;
  z-index: 100;
  border-bottom: 1px solid ${colors.border};
  background: ${colors.white};
  height: ${sizes.SearchAndFilterTopBar.height.default};
`;

const Spacer = styled.div`
  height: ${sizes.SearchAndFilterTopBar.height.default};
`;

export default function SearchAndFilterTopBar(props: Props) {
  const {
    hasFilters,
    areFiltersOpen,
    toggleFilters,
    isFiltered,
    isSearchEnabled = true,
    searchProps,
    renderSubNav,
    renderSubNavLeft,
  } = props;

  return (
    <>
      <Wrapper>
        <div className="flex h-full items-stretch">
          {hasFilters && (
            <FilterButton
              isOpen={areFiltersOpen}
              isFiltered={isFiltered}
              onClick={toggleFilters}
              className={
                !isSearchEnabled
                  ? 'border-0 border-r border-solid border-border'
                  : ''
              }
            />
          )}

          {isSearchEnabled && searchProps && <Search {...searchProps} />}

          {renderSubNav && (
            <div
              className={`${
                renderSubNavLeft
                  ? 'w-full bg-grey9'
                  : 'border-l-default ml-auto'
              } flex items-center px-3`}
            >
              {renderSubNav}
            </div>
          )}
        </div>
      </Wrapper>

      <Spacer />
    </>
  );
}

interface FilterButtonProps {
  isOpen?: boolean;
  isFiltered?: boolean;
  className?: string;
  onClick: () => void;
}

function FilterButton({
  isOpen,
  isFiltered,
  className,
  onClick,
}: FilterButtonProps) {
  const { t } = useIntl();

  const classNames = `${className} ${
    isOpen ? 'tablet:w-31.5' : 'tablet:w-22.5'
  }`;

  const iconClassNames =
    isFiltered && !isOpen ? 'text-socialiePink' : 'opacity-50';

  return (
    <button
      data-qa="index-page-search-and-filter-top-bar-filter-button"
      className={`block h-full flex-shrink-0 text-dark ${classNames}`}
      onClick={onClick}
    >
      <span className="flex items-center justify-center px-2">
        <IconFilters
          className={`bump-down-1 h-2 w-2 tablet:mr-1 ${iconClassNames}`}
        />
        <span className="hidden font-semibold tablet:inline">
          {t('SearchAndFilterTopBar__Filters')}
        </span>

        {isOpen && (
          <IconArrowBack className="ml-auto hidden h-2 w-2 tablet:block" />
        )}
      </span>
    </button>
  );
}
