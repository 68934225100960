import * as ADD_TAGS_TO_SUGGESTIONS from 'graphql/mutations/addTagsToSuggestions.graphql';
import apolloClient from '../apolloClient';

type IDs = number | string | (number | string)[];

const addTagsToSuggestions = async (suggestionIds: IDs, tagIds: IDs) => {
  const { errors, data } = await apolloClient.mutate({
    mutation: ADD_TAGS_TO_SUGGESTIONS,
    variables: { suggestionIds, tagIds },
    refetchQueries: ['Tags'],
  });

  if (errors) return { errors };
  if (data && data.errors) return { errors: data.errors };
  if (data) return data;
  return null;
};

export default addTagsToSuggestions;
