import React from 'react';
import flatten from 'lodash/flatten';
import uniqBy from 'lodash/uniqBy';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import Suggestion from 'types/Suggestion';
import { FormattedDateTime, Tooltip } from 'components';
import { routeFor, routes } from 'helpers';

interface AnyRecipient {
  type: string;
  id: number | string;
  name: string;
  publishersCount?: number;
}

interface BatchSuggestionProps {
  sendAt: number;
  suggestions: Suggestion[];
}

export default function BatchSuggestion(props: BatchSuggestionProps) {
  const { suggestions, sendAt } = props;

  const intendedRecipients = flatten(
    suggestions!.map((s) => s.intendedRecipients)
  );

  const publishers = intendedRecipients
    .filter(
      (ir) =>
        ir.contactMethod?.publisher?.name ||
        ir.publisherUser?.publisher?.name ||
        ir.publisher?.name
    )
    .map((ir) => ({
      ...(ir.contactMethod?.publisher ||
        ir.publisherUser?.publisher ||
        ir.publisher),
      type: 'publisher',
    }));

  const publisherTags = intendedRecipients
    .filter((ir) => ir.publisherTag)
    .map((ir) => ({ ...ir.publisherTag, type: 'publisherTag' }));

  const uniqPublishers = uniqBy(publishers, 'id');
  const uniqPublisherTags = uniqBy(publisherTags, 'id');

  const recipients = [
    ...uniqPublishers,
    ...uniqPublisherTags,
  ] as AnyRecipient[];

  return (
    <div className="text-dark">
      <Tooltip
        content={<FormattedMessage id="ScheduledSuggestions__ViewInTracking" />}
        theme="socialieDark"
        placement="top-start"
      >
        <Link
          className="hover:underline"
          to={routeFor(routes.tracking.pending)}
        >
          <p>
            {recipients.map((recipient, index) => {
              if (recipient.type === 'publisher') {
                return (
                  <span className="text-dark">
                    {recipient.name}
                    {index + 1 < recipients.length && ', '}
                  </span>
                );
              }

              return (
                <span className="text-dark">
                  {recipient.name} ({recipient.publishersCount})
                  {index + 1 < recipients.length && ', '}
                </span>
              );
            })}
          </p>
        </Link>
      </Tooltip>

      <div className="text-sm leading-tight text-grey5">
        <FormattedMessage id="ScheduledSuggestions__SendsOn" />{' '}
        <FormattedDateTime value={sendAt} separator=" " />
      </div>
    </div>
  );
}
