import React from 'react';
import styled from 'styled-components';
import without from 'lodash/without';
import { dateFilterOptions } from 'config';
import FilterSelector from '../FilterSelector';
import { CustomPicker, DateDescriptor } from './components';
import { colors, grid } from 'styles/theme';
import { FormattedMessage } from 'react-intl';

interface Props {
  dateRange?: string;
  startDate: string;
  endDate: string;
  onChange: (updates: any) => void;
  excludeOptions?: string[];
  options?: string[];
}

const Wrapper = styled.div`
  display: flex;
`;

const DateDisplay = styled.div`
  margin-left: ${grid(1)};
  color: ${colors.lightText};
  font-size: 12px;
`;

export default class DateFilter extends React.Component<Props> {
  render() {
    const { dateRange, onChange, excludeOptions } = this.props;
    const options =
      this.props.options ||
      without(dateFilterOptions, ...(excludeOptions || []));
    return (
      <Wrapper>
        <FilterSelector
          name="DateFilter"
          value={dateRange}
          onChange={(val: string) => onChange({ dateRange: val })}
          options={options}
        />
        <DateDisplay>
          <FormattedMessage id="DateFilter__ResultsFrom" />{' '}
          {dateRange === 'custom' ? (
            <CustomPicker
              startDate={this.props.startDate}
              endDate={this.props.endDate}
              onChangeStartDate={(val: string) => onChange({ startDate: val })}
              onChangeEndDate={(val: string) => onChange({ endDate: val })}
            />
          ) : (
            <DateDescriptor dateRange={dateRange} />
          )}
        </DateDisplay>
      </Wrapper>
    );
  }
}
