import React from 'react';
import styled from 'styled-components';
import { sizes, grid, colors } from 'styles/theme';
import { media } from 'styles';
import { GlobalCSSVariables } from 'Layout/components/GlobalStyle';

interface Props {
  isOpen?: boolean;
  children: React.ReactNode;
}

const Wrapper = styled.div`
  position: fixed;
  top: calc(
    var(${GlobalCSSVariables.topOffset}) +
      ${sizes.SearchAndFilterTopBar.height.default}
  );
  right: 0;
  left: 0;
  z-index: 101;
  background: ${colors.white};
  padding: ${grid(2)};
  height: calc(
    100vh - var(${GlobalCSSVariables.topOffset}) -${sizes.SearchAndFilterTopBar.height.default}
  );
  overflow: auto;

  ::-webkit-scrollbar {
    background: transparent;
    width: 0px;
  }

  hr {
    margin: 0 0 ${grid(2.5)};
    border: 0;
    background: ${colors.lightBorder};
    width: 100%;
    height: 1px;
  }

  ${media.tablet`
    top: calc(
      var(${GlobalCSSVariables.topOffset}) +
        ${sizes.SearchAndFilterTopBar.height.tablet}
    );
    right: auto;
    height: calc(
      100vh - var(${GlobalCSSVariables.topOffset}) -
        ${sizes.SearchAndFilterTopBar.height.tablet}
    );
    width: ${grid(31.5)};
  `};
`;

const Spacer = styled.div`
  min-height: calc(
    100vh - var(${GlobalCSSVariables.topOffset}) -${sizes.SearchAndFilterTopBar.height.default}
  );

  ${media.tablet`
    min-height: calc(
      100vh - var(${GlobalCSSVariables.topOffset}) -
        ${sizes.SearchAndFilterTopBar.height.tablet}
    );
  `};
`;

export default function FilterWrapper({ isOpen, children }: Props) {
  const classNames = isOpen ? 'block' : 'hidden';

  return (
    <>
      {/* Spacer to move the main content over */}
      <Spacer className={`${classNames} w-31.5 flex-shrink-0 bg-white`} />

      {/* The actual filters, position fixed for scrolling/sticking */}
      <Wrapper className={classNames}>{children}</Wrapper>
    </>
  );
}
