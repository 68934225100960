import styled from 'styled-components';
import { grid } from 'styles/theme';

const Container = styled.div`
  margin: 0 auto;
  width: ${grid(88)};
  ${(props: any) => props.wide && `width: ${grid(120)}`};
  max-width: 100%;
` as any;

export default Container;
