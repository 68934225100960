import React from 'react';
import styled from 'styled-components';
import { grid, colors } from 'styles/theme';
import { SortArrows } from 'components';

const ClickableHeading = styled.button`
  margin-right: ${grid(0.5)};
  color: ${colors.bodyText};
  ${(props: any) => props.active && `color: ${colors.darkText}`};
` as any;

interface Props {
  field: string;
  currentField: string;
  currentDirection: 'asc' | 'desc';
  onChangeSort: (field: any) => void;
  children: React.ReactNode;
}

export default function SortableHeading({
  field,
  currentField,
  currentDirection,
  onChangeSort,
  children,
}: Props) {
  const isActive = currentField === field;

  return (
    <div className="flex items-center">
      <ClickableHeading active={isActive} onClick={() => onChangeSort(field)}>
        {children}
      </ClickableHeading>

      <SortArrows
        isSortedAsc={isActive && currentDirection === 'asc'}
        isSortedDesc={isActive && currentDirection === 'desc'}
        onSortAsc={() => onChangeSort(field)}
        onSortDesc={() => onChangeSort(field)}
      />
    </div>
  );
}
