import React from 'react';
import { useIntl } from 'hooks';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { routes } from 'helpers';

interface Props {
  platform: string;
}

export default function CannotTrackAccountMessage({ platform }: Props) {
  const { t } = useIntl();

  return (
    <>
      {t(`CannotTrackAccountMessage__NotLinked--${platform}`)}{' '}
      <FormattedMessage
        id="CannotTrackAccountMessage__VisitSettings"
        values={{
          settingsLink: (
            <Link
              to={routes.settings.account}
              className="text-socialiePink hover:underline"
            >
              {t('CannotTrackAccountMessage__SettingsLink')}
            </Link>
          ),
        }}
      />
    </>
  );
}
