import { ReactComponent as twitter_dm } from './twitter.svg';
import { ReactComponent as sms } from './cellphone.svg';
import { ReactComponent as slack } from './slack.svg';
import { ReactComponent as email } from './email.svg';
import { ReactComponent as whats_app } from './whats-app.svg';
import { ReactComponent as apns } from './apple.svg';

const contactMethodIcons = { twitter_dm, sms, slack, email, whats_app, apns };

export default contactMethodIcons;
