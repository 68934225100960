import { css } from 'styled-components';
import { rgba } from 'polished';
import { colors } from '../theme';

const getModalStyles = () => css`
  .Overlay {
    display: flex;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    align-items: flex-start;
    opacity: 0;
    z-index: 9998;
    transition: opacity 0.2s ease;
    background-color: ${rgba(colors.grey1, 0.92)};
    cursor: default;
    width: 100vw;
    height: 100vh;
    overflow: auto;
  }

  .Overlay--after-open {
    opacity: 1;
  }

  .Overlay--before-close {
    opacity: 0;
  }
`;

export default getModalStyles;
