import React from 'react';
import Attachment from 'types/Attachment';
import {
  Actions,
  Metadata,
  MediaAppearances,
  Notes,
  SuggestionHistory,
  PerformanceStats,
  ContentRoutingRules,
  CanMonetize,
} from './components';
import { useIntl } from 'hooks';
import ScheduledSuggestions from './components/ScheduledSuggestions';
import AttachmentModalDetailsSocialieData from './components/AttachmentModalDetailsSocialieData';

interface Props {
  attachment?: Attachment;
  isAttachmentAlbumMedia?: boolean;
  loading?: boolean;
  renderPrimaryAction?: React.ReactNode;
}

export default function AttachmentModalDetails({
  attachment,
  renderPrimaryAction,
  isAttachmentAlbumMedia = false,
}: Props) {
  const { t, formatRelative } = useIntl();

  if (!attachment) return null;
  const date = new Date(attachment.createdAt).toString();
  const hasPublishedSuggestions =
    attachment.suggestions &&
    attachment.suggestions.some((s) =>
      s.requests.some((r) => r.status === 'published')
    );

  return (
    <>
      <div className="space-between mb-2 flex items-center">
        <time
          title={date}
          dateTime={date}
          className="mr-2 block flex-1 overflow-hidden truncate text-14 text-light"
        >
          {t('AttachmentModal__Added')} {formatRelative(attachment.createdAt)}
        </time>

        <Actions
          attachment={attachment}
          isAttachmentAlbumMedia={isAttachmentAlbumMedia}
          renderPrimaryAction={renderPrimaryAction}
        />
      </div>
      {(!!attachment.title || !!attachment.caption) && (
        <div className="mb-2 text-16 leading-20 text-dark">
          {attachment.title}
          {!!attachment.caption && (
            <div className="text-13 leading-16 text-bodyText">
              {attachment.caption}
            </div>
          )}
        </div>
      )}
      {hasPublishedSuggestions && <PerformanceStats attachment={attachment} />}
      <MediaAppearances attachment={attachment} />
      <SuggestionHistory attachment={attachment} />
      <ScheduledSuggestions attachment={attachment} />
      <Metadata attachment={attachment} />
      <AttachmentModalDetailsSocialieData attachment={attachment} />

      {attachment.contentRequestSubmission && (
        <Notes contentRequestSubmission={attachment.contentRequestSubmission} />
      )}

      <ContentRoutingRules attachment={attachment} />

      <CanMonetize attachment={attachment} />
    </>
  );
}
