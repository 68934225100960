// Pass in an array of Targets from the platformBag, receive an array
// of FormattedTargets, ready to be displayed nicely in a list.

import Target from 'types/Target';

interface FormattedTarget {
  title: string;
  values: string[];
  targetType: string;
}

const titles = {
  country: 'Targets__Location',
  locale: 'Targets__Language',
  gender: 'Targets__Gender',
  min_age: 'Targets__MinAge',
  max_age: 'Targets__MaxAge',
};

const targetValue = ({ value, description }: Target) =>
  !!description ? description : value;

const formatTarget = (target: Target): FormattedTarget => ({
  title: titles[target.targetType],
  targetType: target.targetType,
  values: [targetValue(target)],
});

const combineAges = (formattedTargets: FormattedTarget[]) => {
  const minAge = formattedTargets.find((t) => t.targetType === 'min_age');
  const maxAge = formattedTargets.find((t) => t.targetType === 'max_age');
  let values: [string] | null = null;
  if (minAge && maxAge) {
    values = [`${minAge.values[0]} - ${maxAge.values[0]}`];
  } else if (minAge) {
    values = [`${minAge.values[0]}+`];
  } else if (maxAge) {
    // TODO: Internationalize
    values = [`Up to ${maxAge.values[0]}`];
  }

  if (values) {
    formattedTargets.push({ values, targetType: 'age', title: 'Targets__Age' });
    return formattedTargets.filter(
      (t) => t.targetType !== 'min_age' && t.targetType !== 'max_age'
    );
  }

  return formattedTargets;
};

const formatTargets = (targets: Target[]): FormattedTarget[] => {
  const formattedTargets: FormattedTarget[] = [];
  targets.forEach((target) => {
    const formattedTarget = formattedTargets.find(
      (t) => t.targetType === target.targetType
    );

    // If we've already got a target with this targetType, just push the value
    // on to that one.  If we don't have this targetType yet, create it.
    if (formattedTarget) {
      formattedTarget.values.push(targetValue(target));
    } else {
      formattedTargets.push(formatTarget(target));
    }
  });

  return combineAges(formattedTargets);
};

export default formatTargets;
