import React, { useState } from 'react';
import { useIntl, useCurrentUser } from 'hooks';
import {
  Form,
  SocialAccountSearchField,
  PublisherUserRoleSelect,
} from 'components';
import SocialAccount from 'types/SocialAccount';
import { TippyProps } from '@tippyjs/react';
import { IconRemove } from 'icons';
import PublisherUser from 'types/PublisherUser';

export interface InvitePublisherUserFieldsArgs {
  protocol: string;
  value: string;
  twitterUserId: string;
  canDm?: boolean | undefined;
  role?: PublisherUser['role'];
}

interface Props
  extends Pick<InvitePublisherUserFieldsArgs, 'protocol' | 'value' | 'role'> {
  onChange: (args: InvitePublisherUserFieldsArgs) => void;
  errors?: any;
  tooltipPlacement?: TippyProps['placement'];
}

export default function InvitePublisherUserFields(props: Props) {
  const { t } = useIntl();
  const currentUser = useCurrentUser();
  const [selectedAccount, setSelectedAccount] = useState<SocialAccount | null>(
    null
  );
  const { protocol, value, role, onChange, errors, tooltipPlacement } = props;

  const protocolOptions = [
    { value: 'email', label: t('AddNewPublisher__Email') },
    { value: 'sms', label: t('AddNewPublisher__SMS') },
    { value: 'twitter_dm', label: t('AddNewPublisher__Twitter') },
  ];

  const handleChangeValue = (val: string) => {
    onChange({ protocol, role, value: val, twitterUserId: '' });
  };

  const handleChangeProtocol = (val: string) => {
    onChange({ role, protocol: val, value: '', twitterUserId: '' });
  };

  const handleChangeRole = (val: Props['role']) => {
    onChange({ protocol, value, twitterUserId: '', role: val });
  };

  const handleSelectTwitterAccount = (account: SocialAccount | null) => {
    setSelectedAccount(account);
    onChange({
      role,
      protocol: 'twitter_dm',
      value: account?.username || '',
      twitterUserId: account?.platformUid || '',
      canDm: account?.canDm,
    });
  };

  return (
    <>
      <div className="mb-1.5 mt-1 flex items-start">
        {/* Contact Protocol Select */}
        <div
          className="mr-1 w-12 flex-shrink-0"
          data-qa="invite-publisher-user-fields-contact-protocol-select-drop-down"
        >
          <Form.Select
            value={protocol}
            onChange={handleChangeProtocol}
            options={protocolOptions}
          />
        </div>

        {/* Contact Value Input */}
        <div className="bump-down-1 w-30 leading-tight">
          {protocol === 'sms' ? (
            <Form.PhoneInput
              data-qa="invite-publisher-user-fields-sms-field"
              value={value}
              suggester={currentUser.currentSuggester}
              onChange={handleChangeValue}
              errors={errors}
            />
          ) : protocol === 'email' ? (
            <Form.TextInput
              data-qa="invite-publisher-user-fields-email-field"
              value={value}
              onChange={(e) => handleChangeValue(e.target.value)}
              placeholder={t('AddNewPublisher__EmailPlaceholder')}
              errors={errors}
            />
          ) : protocol === 'twitter_dm' && !selectedAccount ? (
            <SocialAccountSearchField
              platform="twitter"
              onSelectAccount={handleSelectTwitterAccount}
              placement={tooltipPlacement}
              selectText={t('Global__Select')}
            />
          ) : protocol === 'twitter_dm' && selectedAccount ? (
            <div className="relative">
              <Form.TextInput
                readOnly
                value={`@${selectedAccount.username}`}
                errors={
                  !selectedAccount.canDm ? t('Validation__CannotDm') : errors
                }
              />

              <div className="absolute right-0 top-0 pr-1 pt-1">
                <button
                  type="button"
                  className="h-full w-full text-lightIcon"
                  onClick={() => handleSelectTwitterAccount(null)}
                >
                  <IconRemove />
                </button>
              </div>
            </div>
          ) : null}
        </div>
      </div>

      {!!role && (
        <PublisherUserRoleSelect value={role} onChange={handleChangeRole} />
      )}
    </>
  );
}
