import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Form } from 'components';
import { CaptionField, AddPhotoCreditsToCaptions } from './components';
import Suggestion from 'types/Suggestion';

interface Props {
  suggestions: Suggestion[];
  platforms: string[];
  onChange?: () => void;
}

const Captions = ({ suggestions, platforms, onChange }: Props) => {
  const noPlatforms = suggestions.every((s) => !s.drafts || !s.drafts.length);

  return (
    <Form.Field>
      <Form.Label bold>
        <FormattedMessage id="SuggestionForm__Captions" />
      </Form.Label>

      {noPlatforms && (
        <em>
          <FormattedMessage id="SuggestionForm__NoPlatforms" />
        </em>
      )}

      {platforms.map((platform) => {
        const isEnabled = suggestions.every(
          (s) => !!s.drafts && !!s.drafts.find((d) => d.platform === platform)
        );
        if (!isEnabled) return null;

        return (
          <CaptionField
            onChange={onChange}
            platform={platform}
            key={platform}
            suggestions={suggestions}
          />
        );
      })}

      <AddPhotoCreditsToCaptions suggestions={suggestions} />
    </Form.Field>
  );
};

export default Captions;
