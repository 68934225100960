import React from 'react';
import PublisherRequest from 'types/PublisherRequest';
import { FormattedDateTime } from 'components';
import { useIntl } from 'hooks';
import { FormattedMessage } from 'react-intl';
import { RequestStoryPlatformsIcon } from './components';

interface Props {
  request: PublisherRequest;
  enableStories: boolean;
}

export default function RequestStoryPlatforms(props: Props) {
  const { t } = useIntl();
  const { request, enableStories } = props;

  if (!request.stories || !enableStories) return null;

  const stories = request.stories || [];

  return (
    <div className="flex items-center">
      {stories.map((story) => {
        const status = story.expiredAt ? 'expired' : 'published';

        return !!story.publishedAt && !story.expiredAt && story.platform ? (
          <a
            key={story.id}
            href={story.url}
            onClick={(e) => e.stopPropagation()}
            target="_blank"
            rel="noopener noreferrer"
          >
            <RequestStoryPlatformsIcon
              tooltip={t('StoryStats__ViewStory')}
              platform={story.platform}
              status={status}
            />
          </a>
        ) : !!story.publishedAt && !!story.expiredAt && story.platform ? (
          <RequestStoryPlatformsIcon
            tooltip={
              <FormattedMessage
                key={story.id}
                id="StoryStats__ExpiredAt"
                values={{
                  date: (
                    <FormattedDateTime value={story.expiredAt} separator=", " />
                  ),
                }}
              />
            }
            platform={story.platform}
            status={status}
          />
        ) : null;
      })}
    </div>
  );
}
