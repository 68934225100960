import React from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { IconAlertOff } from 'icons';
import { colors, grid } from 'styles/theme';
import { Tooltip } from 'components';
import ContactMethod from 'types/ContactMethod';
import NotificationPreference from 'types/NotificationPreference';

interface Props {
  contactMethods: ContactMethod[];
  notificationPreferences: NotificationPreference[];
  theme?: { heading?: boolean };
}

// prettier-ignore
const Wrapper = styled.div`
  margin-left: ${grid(0.5)};
  width: ${grid(2)};
  height: ${grid(2)};
  color: ${colors.lightIcon};

  ${(props: any) => props.theme.heading && `
    margin-left: ${grid(1)};
    width: ${grid(2.5)};
    height: ${grid(2.5)};
  `}

  svg {
    width: 100%;
    height: 100%;
  }
`;

const NoContactMethodsWarning = ({
  theme,
  contactMethods,
  notificationPreferences,
}: Props) => {
  if (
    contactMethods.find((c) => c.wantsNotifications) ||
    notificationPreferences.length
  )
    return null;

  return (
    <ThemeProvider theme={theme || {}}>
      <Tooltip
        theme="socialieDark"
        content={
          <FormattedMessage id="NoContactMethodsWarning__NoNotifications" />
        }
      >
        <Wrapper data-testid="no-contact-methods-warning">
          <IconAlertOff />
        </Wrapper>
      </Tooltip>
    </ThemeProvider>
  );
};

export default NoContactMethodsWarning;
