import React from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { motion } from 'framer-motion';

import { IconClose } from 'icons';
import { useIntl } from 'hooks';
import FlashMessageType from 'types/FlashMessage';

interface Props {
  flash: FlashMessageType | null;
  onRemove: (flashId: string) => void;
}

// prettier-ignore

export default function FlashMessage(props: Props) {
  const { t } = useIntl();
  const { flash, onRemove } = props;

  if (!flash) return null;

  const style = flash.props?.style ?? 'success'

  return (
    <motion.div
      initial={{ x: '-200%' }}
      animate={{ x: 0 }}
      transition={{ ease: [0.65, 0, 0.35, 1], duration: 0.5 }}
      exit={{ x: '-200%' }}
      className={clsx("p-2 w-43 flex justify-between rounded-4 text-white text-14 leading-16 font-semibold", {
        "bg-teal": style === "success",
        "bg-error": style === "error",
        "flex-col bg-black": style === "system"
      })}>
      <div>
        {flash.props && flash.props.skipTranslation
          ? flash.message
          : t(flash.message)}{' '}
        {!!flash.props?.link && (
          <Link
            to={flash.props.link.route}
            onClick={() => onRemove(flash.id)}
          >
            {t(flash.props.link.text)}
          </Link>
        )}
      </div>
      {style === 'system' ? (
        <div className="mt-2.5 flex items-stretch">
          {flash.props?.invalidTokenLink && (
            <Link
              to={flash.props.invalidTokenLink}
              onClick={() => onRemove(flash.id)}
              className="mr-1.5 px-1 pt-0.25 pb-0.5 text-white bg-error rounded-4"
            >
              {t('ContentIntegrations__InvalidTokenToast--Reconnect')}
            </Link>
          )}
          <button
            className="px-1 pt-0.25 pb-0.5 text-white bg-grey3 rounded-4"
            onClick={() => onRemove(flash.id)}
          >
            {t('ContentIntegrations__InvalidTokenToast--Dismiss')}
          </button>
        </div>
      ) : (
          <button className="ml-3 w-2 h-2 opacity-75 hover:opacity-100 transition-opacity duration-200 ease-in-out" type="button" onClick={() => onRemove(flash.id)}>
            <IconClose className="w-full h-full" />
          </button>
        )}
    </motion.div>
  );
}
