import React from 'react';
import styled from 'styled-components';
import { grid, colors } from 'styles/theme';
import { Avatar } from 'components';

const OptionWrapper = styled.span`
  display: flex;
  align-items: center;
  padding: ${`${grid(0.75)} ${grid(1)}`};
  color: ${colors.darkText};

  &:hover {
    background: ${colors.greyBg};
  }

  ${(props: any) => props.isFocused && `background: ${colors.greyBg}`};
`;

const Option = (props: any) => {
  // clear out the onMouseMove and onMouseOver events for improved perf
  const { onMouseMove, onMouseOver, ...newInnerProps } = props.innerProps;

  return (
    <OptionWrapper
      {...newInnerProps}
      ref={props.innerRef}
      isFocused={props.isFocused}
    >
      {props.data.hasOwnProperty('image') && (
        <div className="mr-0.5 inline-block h-3 w-3 flex-shrink-0">
          <Avatar
            theme={{ white: true }}
            src={props.data.image}
            name={props.data.label}
          />
        </div>
      )}
      {props.data.label && props.data.label.replace && (
        <span
          className="bump-up-0.5 flex-1 overflow-hidden break-words text-left leading-tight"
          data-qa={`form-select-options-option-${props.data.label}`}
        >
          <HighlightText
            label={props.data.label}
            inputValue={props.selectProps.inputValue}
          />
        </span>
      )}
    </OptionWrapper>
  );
};

export default Option;

interface HighlightTextProps {
  label: string;
  inputValue: string;
}

function HighlightText({ label, inputValue }: HighlightTextProps) {
  if (!inputValue.trim()) {
    return <>{label}</>;
  }

  const regex = new RegExp(`(${inputValue})`, 'gi');
  const parts = label.split(regex);

  return (
    <>
      {parts.map((part, index) =>
        regex.test(part) ? <strong key={index}>{part}</strong> : part
      )}
    </>
  );
}
