import React from 'react';
import clsx from 'clsx';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import Attachment from 'types/Attachment';
import { IconTrash, IconDownload, IconSendToAlbum } from 'icons';
import { useIntl } from 'hooks';
import {
  RemoveAttachment,
  RemoveAttachmentAlbumMedia,
  MediaDownloader,
  SendMediaToAlbum,
} from 'components';
import history from 'appHistory';
import { routeFor, routes } from 'helpers';
import Tooltip from 'components/Tooltip';

interface Props {
  attachment: Attachment;
  isAttachmentAlbumMedia?: boolean;
  renderPrimaryAction?: React.ReactNode;
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

export default function Actions({
  attachment,
  isAttachmentAlbumMedia = false,
  renderPrimaryAction,
}: Props) {
  const { t } = useIntl();

  const { id: albumId } = useParams();

  const route = isAttachmentAlbumMedia
    ? routeFor(routes.mediaLibrary.albumMedia.showMedia, albumId)
    : routes.mediaLibrary.index;

  const RemoveAttachmentComponent = isAttachmentAlbumMedia
    ? RemoveAttachmentAlbumMedia
    : RemoveAttachment;

  return (
    <Wrapper>
      <div className="mr-1.5">{renderPrimaryAction}</div>

      <MediaDownloader
        attachmentIds={[attachment.id]}
        requestSource={`download-attachment-${attachment.id}`}
      >
        {(downloadMedia) => (
          <Action
            onClick={downloadMedia}
            icon={IconDownload}
            tooltip={t('AttachmentModal__Download')}
          />
        )}
      </MediaDownloader>

      <SendMediaToAlbum selectedAttachmentIds={[attachment.id]}>
        {(openModal) => (
          <Action
            onClick={openModal}
            icon={IconSendToAlbum}
            tooltip={t('AddToAlbum__Button')}
          />
        )}
      </SendMediaToAlbum>

      <RemoveAttachmentComponent
        attachments={[attachment]}
        onCompleted={() => history.push(route)}
      >
        {(onClick) => (
          <Action
            onClick={onClick}
            icon={IconTrash}
            tooltip={
              isAttachmentAlbumMedia
                ? t('AttachmentModal__RemoveFromAlbum')
                : attachment.viewerCanDestroy
                ? t('AttachmentModal__Remove')
                : t('AttachmentModal__CannotRemove')
            }
            disabled={
              isAttachmentAlbumMedia ? false : !attachment.viewerCanDestroy
            }
          />
        )}
      </RemoveAttachmentComponent>
    </Wrapper>
  );
}

interface ActionProps {
  onClick: () => void;
  icon: typeof IconTrash;
  tooltip: string;
  disabled?: boolean;
}

function Action({ onClick, icon, tooltip, disabled }: ActionProps) {
  return (
    <Tooltip content={tooltip} theme="socialieDark" placement="top">
      <div className="mr-1 last:mr-0">
        <button
          className={clsx(
            'text-light disabled:cursor-not-allowed disabled:opacity-50',
            { 'hover:text-bodyText': !disabled }
          )}
          onClick={onClick}
          title={tooltip}
          disabled={disabled}
        >
          <span>
            {React.createElement(icon, { className: 'block w-2.5 h-2.5' })}
          </span>
        </button>
      </div>
    </Tooltip>
  );
}
