import React from 'react';
import { useIntl } from 'hooks';
import {
  FilterPill,
  PublisherFilterPills,
  DateRangeFilterPill,
  SuggesterFilterPills,
} from './components';
import { useHistory } from 'react-router-dom';

interface Props {
  onClearFilters?: () => void;
  children: React.ReactNode;
}

export const FilterPillsDisplayContext = React.createContext<
  'default' | 'savedFilterSet'
>('default');

export default function FilterPills(props: Props) {
  const { children, onClearFilters } = props;
  const { t } = useIntl();
  const context = React.useContext(FilterPillsDisplayContext);
  const history = useHistory();

  return (
    <div className="mb-1.5 flex flex-wrap">
      {children}

      {onClearFilters && context === 'default' && (
        <button
          className="h-4 text-socialiePink hover:text-socialiePinkHover hover:underline"
          onClick={() => {
            onClearFilters();
            const searchParams = new URLSearchParams(window.location.search);
            searchParams.delete('savedFilterSetId');
            history.replace({ search: searchParams.toString() });
          }}
        >
          {t('Global__ClearFilter')}
        </button>
      )}
    </div>
  );
}

FilterPills.FilterPill = FilterPill;
FilterPills.PublisherFilterPills = PublisherFilterPills;
FilterPills.DateRangeFilterPill = DateRangeFilterPill;
FilterPills.SuggesterFilterPills = SuggesterFilterPills;
