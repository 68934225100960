import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Form } from 'components';
import Album from 'types/Album';
import { useIntl } from 'hooks';

interface Props {
  albums: Album[];
  value?: (number | string)[];
  onChange: (value: (number | string)[]) => void;
}

const AlbumFilter = ({ albums, value, onChange }: Props) => {
  const { t } = useIntl();
  const options = albums.map((album) => ({
    value: album.id,
    label: album.name,
  }));

  return (
    <Form.Field>
      <Form.Label bold>
        <FormattedMessage id="MediaLibraryAlbumFilter__Label" />
      </Form.Label>

      <Form.Select
        isMulti
        onChange={onChange}
        value={value}
        options={options}
        isClearable={false}
        placeholder={t('MediaLibraryAlbumFilter__Placeholder')}
      />
    </Form.Field>
  );
};

export default AlbumFilter;
