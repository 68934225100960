import React from 'react';
import styled from 'styled-components';
import { Page, Loader } from 'components';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  text-align: center;
`;

const Loading = () => (
  <Page>
    <Page.Main>
      <Wrapper>
        <Loader />
      </Wrapper>
    </Page.Main>
  </Page>
);

export default Loading;
