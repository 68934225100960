import React from 'react';
import { useIntl } from 'hooks';

import ContentRequestSubmission from 'types/ContentRequestSubmission';
import InfoCard from './InfoCard';
import InfoTable from './InfoTable';

interface Props {
  contentRequestSubmission?: ContentRequestSubmission;
}

export default function Notes(props: Props) {
  const { contentRequestSubmission } = props;

  const { t } = useIntl();
  return (
    <InfoCard title={t('AttachmentModal__Notes')}>
      <InfoTable>
        {!!contentRequestSubmission?.comments ? (
          <span className="break-words text-grey2" style={{ hyphens: 'auto' }}>
            {contentRequestSubmission.comments}
          </span>
        ) : (
          <span className="italic text-grey5">
            {t('AttachmentModal__Notes--Empty')}
          </span>
        )}
      </InfoTable>
    </InfoCard>
  );
}
