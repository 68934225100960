import React from 'react';
import { useIntl } from 'hooks';
import { Button } from 'components';
import { flashMessage } from 'helpers';

interface FinishMfaSetupProps {
  onMfaSetupComplete: () => void;
}

export default function FinishMfaSetup({
  onMfaSetupComplete,
}: FinishMfaSetupProps) {
  const { t } = useIntl();

  return (
    <>
      <div className="text-left">
        <div className="mb-2">
          <p className="text-dark">
            {t('Security__ManageMfa--FinishMfaSetup-Desc')}
          </p>
        </div>
        <div className="flex items-center justify-end">
          <Button
            filledBg
            inlineBlock
            onClick={() => {
              onMfaSetupComplete();
              flashMessage('Security__ManageMfa--FinishMfaSetup-Success');
            }}
          >
            {t('Global__Done')}
          </Button>
        </div>
      </div>
    </>
  );
}
