import React from 'react';
import styled from 'styled-components';
import { Form } from 'components';
import { FormattedMessage } from 'react-intl';
import { grid } from 'styles/theme';

interface Props {
  minAge?: number;
  onChangeMinAge: (age?: number) => void;
}

const Fields = styled.div`
  display: flex;
`;

const Field = styled.div`
  width: ${grid(10)};
`;

export default class Age extends React.Component<Props> {
  onChangeMinAge = (age?: number) => {
    this.props.onChangeMinAge(age);
  };
  render() {
    const { minAge } = this.props;
    const ages = Array.from(Array(52).keys()).map((i) => i + 13);
    const options = [
      { label: '--', value: undefined },
      ...ages.map((age) => ({ label: age.toString(), value: age })),
    ];

    return (
      <Form.Field>
        <Form.Label>
          <FormattedMessage id="GeotargetingModal__Age" />
        </Form.Label>

        <Fields>
          <Field>
            <Form.Select
              options={options}
              onChange={this.onChangeMinAge}
              value={minAge as any}
              placeholder="--"
            />
          </Field>
        </Fields>
      </Form.Field>
    );
  }
}
