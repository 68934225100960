import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { contactMethodIcons, IconAlertOff, IconAlertSnoozed } from 'icons';
import PublisherRequest from 'types/PublisherRequest';
import { grid, colors } from 'styles/theme';
import { Tooltip } from '..';
import { getFormattedContactMethod } from 'helpers';

interface Props {
  request: PublisherRequest;
}

const Wrapper = styled.div`
  svg {
    position: relative;
    top: 2px;
    transition: color 0.2s;
    margin-right: ${grid(0.5)};
    width: ${grid(2)};
    height: ${grid(2)};
    color: ${colors.lightIcon};

    &:hover {
      color: ${colors.darkText};
    }

    &.failed {
      color: ${colors.error};
    }
  }
`;

const DeliveryReceipts = ({ request }: Props) => {
  const { deliveryReceipts } = request;
  if (!deliveryReceipts || !deliveryReceipts.length) return null;

  const deliveredToAppleDevice = deliveryReceipts.some(
    (deliveryReceipt) => deliveryReceipt.protocol === 'apns'
  );

  const failedDeliveryToAppleDevice = deliveryReceipts.some(
    (deliveryReceipt) =>
      deliveryReceipt.protocol === 'apns' && deliveryReceipt.status === 'failed'
  );

  return (
    <Wrapper>
      {deliveryReceipts.map((deliveryReceipt) => {
        const { protocol, status, id, errorMessage } = deliveryReceipt;
        const isAppleDevice = protocol === 'apns';
        const failed = deliveryReceipt.status === 'failed';
        const notificationsOff = deliveryReceipt.status === 'notifications_off';
        const suppressed =
          deliveryReceipt.status === 'notifications_suppressed';

        /*
          Do not render apple device icon here because there can be more than
          one. Instead render single apple device icon separately.
        */
        if (isAppleDevice) return null;

        return (
          <Tooltip
            key={id}
            content={
              <div>
                {!protocol && <span>{errorMessage}</span>}

                {!!protocol && (
                  <>
                    {failed ? (
                      <FormattedMessage id="DeliveryReceipts__Failed" />
                    ) : (
                      <FormattedMessage id="DeliveryReceipts__SentTo" />
                    )}{' '}
                    {getFormattedContactMethod(deliveryReceipt)}
                    {!!errorMessage && (
                      <>
                        <br />
                        <span>({errorMessage})</span>
                      </>
                    )}
                  </>
                )}
              </div>
            }
            theme="socialieDark"
          >
            <span>
              {notificationsOff && <IconAlertOff />}
              {suppressed && <IconAlertSnoozed />}

              {!!protocol &&
                (contactMethodIcons[protocol] ? (
                  React.createElement(contactMethodIcons[protocol], {
                    className: status,
                  })
                ) : (
                  <FormattedMessage id={`DeliveryReceipts--${protocol}`} />
                ))}
            </span>
          </Tooltip>
        );
      })}
      {deliveredToAppleDevice && (
        <Tooltip
          content={
            <span>{<FormattedMessage id="DeliveryReceipts__apns" />}</span>
          }
          theme="socialieDark"
        >
          <span>
            {React.createElement(contactMethodIcons['apns'], {
              className: failedDeliveryToAppleDevice ? 'failed' : '',
            })}
          </span>
        </Tooltip>
      )}
    </Wrapper>
  );
};

export default DeliveryReceipts;
