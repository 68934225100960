import React from 'react';
import clsx from 'clsx';

interface Props {
  checked: boolean;
  onChange: () => void;
}

export default function BrandRadio(props: Props) {
  const { checked, onChange } = props;

  return (
    <button
      className={clsx(
        'flex h-2 w-2 items-center justify-center rounded-full transition-colors duration-150 ease-in-out',
        {
          'border border-solid border-socialiePink bg-socialiePink hover:bg-socialiePinkHover':
            checked,
          'border border-solid border-grey5 bg-white hover:border-darkText':
            !checked,
        }
      )}
      onClick={onChange}
    >
      <div className={clsx('h-1 w-1 rounded-full bg-white', {})} />
    </button>
  );
}
