import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import ReactDOM from 'react-dom';
import { rgba } from 'polished';
import { ErrorDebug } from 'components';
import Attachment from 'types/Attachment';
import { mixins } from 'styles';
import { grid, colors } from 'styles/theme';
import closeIconSvg from 'images/close-white.svg';
import { useDebouncedWindowDimensions } from 'hooks';
import AttachmentModalThumbnail from './components/AttachmentModalThumbnail';
import AttachmentModalDetails from './components/AttachmentModalDetails/AttachmentModalDetails';

interface Props {
  attachment?: Attachment;
  renderPrimaryAction?: React.ReactNode;
  isAttachmentAlbumMedia?: boolean;
  error?: any;
  onRequestClose: () => void;
}

const Wrapper = styled.div`
  ${mixins.modal};

  .Overlay {
    background-color: ${rgba(colors.grey2, 0.8)};
    background-image: url('${closeIconSvg}');
    background-position: top ${grid(1)} right ${grid(2)};
    background-repeat: no-repeat;
    background-size: ${grid(3)} ${grid(3)};
  }
`;

const Modal = styled.div`
  display: flex;
  position: fixed;
  top: ${grid(4)};
  left: 50%;
  align-items: stretch;
  transform: translateX(-50%);
  overflow: hidden;
`;

const ThumbnailWrapper = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  flex: 1;
  flex-shrink: 1;
  justify-content: center;
  background-color: ${colors.black};

  img,
  video {
    display: block;
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }
`;

const DetailsWrapper = styled.div`
  flex-shrink: 0;
  background-color: ${colors.greyBg};
  padding: ${grid(1.5)};
  width: ${grid(45)};
  height: 100%;
  overflow-y: auto;
  text-align: left;

  ::-webkit-scrollbar {
    background: transparent;
    width: 0px;
  }
`;

export default function AttachmentModal(props: Props) {
  const {
    attachment,
    error,
    onRequestClose,
    renderPrimaryAction,
    isAttachmentAlbumMedia = false,
  } = props;
  const aspectRatio = (attachment && attachment.aspectRatio) || 0;
  const { width: windowWidth, height: windowHeight } =
    useDebouncedWindowDimensions();
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  useEffect(() => {
    const calculateDimensions = () => {
      const height = windowHeight - 64;
      const width = Math.min(
        height / (1 * aspectRatio || 0.565) + 8 * 45,
        windowWidth - 64
      );
      return { width, height };
    };

    setDimensions(calculateDimensions());
  }, [aspectRatio, windowWidth, windowHeight]);

  return ReactDOM.createPortal(
    <Wrapper>
      <div className="Overlay Overlay--after-open" onClick={onRequestClose}>
        <Modal
          onClick={(e) => e.stopPropagation()}
          style={{
            width: `${dimensions.width}px`,
            height: `${dimensions.height}px`,
          }}
        >
          <ThumbnailWrapper data-testid="attachment-thumbnail">
            <AttachmentModalThumbnail attachment={attachment} />
          </ThumbnailWrapper>

          <DetailsWrapper>
            {error && <ErrorDebug error={error} />}
            <AttachmentModalDetails
              attachment={attachment}
              isAttachmentAlbumMedia={isAttachmentAlbumMedia}
              renderPrimaryAction={renderPrimaryAction}
            />
          </DetailsWrapper>
        </Modal>
      </div>
    </Wrapper>,

    document.body
  );
}
