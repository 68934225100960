import React from 'react';
import Chart from 'chart.js';

interface Props {
  data: number[];
  color: string;
  width: string;
  height: string;
}

interface State {
  smoothData: number[];
}

const smoothData = (data: number[], maxPoints = 10) => {
  if (data.length <= maxPoints) return data;

  const step = (data.length - 1) / (maxPoints - 1);
  const smoothData = [];

  for (let i = 0; i < maxPoints; i += 1) {
    smoothData.push(data[Math.round(step * i)]);
  }

  return smoothData;
};

export default class Sparkline extends React.Component<Props, State> {
  state = { smoothData: smoothData(this.props.data) };

  private canvasEl: HTMLCanvasElement | null = null;
  private chart: Chart | null | undefined;

  static getDerivedStateFromProps(nextProps: Props) {
    return { smoothData: smoothData(nextProps.data) };
  }

  componentDidMount() {
    this.drawChart();
  }

  componentDidUpdate(prevProps: Props) {
    this.drawChart();
  }

  drawChart = () => {
    if (!this.canvasEl) return;
    const { smoothData } = this.state;

    const data = {
      datasets: [{ data: smoothData }],
      labels: smoothData.map((d) => d.toString()),
    };

    if (this.chart) this.chart.destroy();
    this.chart = new Chart(this.canvasEl, {
      data,
      type: 'line',
      options: this.getChartOptions(),
    });
  };

  getChartOptions = () => ({
    legend: { display: false },
    responsive: false,
    elements: {
      line: {
        borderColor: this.props.color,
        borderWidth: 1,
        fill: false,
      },
      point: { radius: 0 },
    },
    tooltips: { enabled: false },
    scales: {
      yAxes: [
        {
          display: false,
          ticks: {
            min: Math.min(...this.props.data),
            max: Math.max(...this.props.data),
          },
        },
      ],
      xAxes: [{ display: false }],
    },
  });

  render() {
    const { width, height } = this.props;
    return (
      <canvas
        width={width}
        height={height}
        ref={(el) => (this.canvasEl = el)}
      />
    );
  }
}
