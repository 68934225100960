import React from 'react';
import clsx from 'clsx';

interface Props {
  children: React.ReactNode;
  className?: string;
  columns: number | string;
}

export default function DataTableGrid(props: Props) {
  // We use a negative top margin because we push the heading down
  // by 1px to cover the border of the first row
  return (
    <div
      className={clsx(
        'relative -top-px grid items-stretch overflow-hidden rounded-lg bg-transparent',
        props.className
      )}
      style={
        {
          gridTemplateColumns:
            typeof props.columns === 'string'
              ? props.columns
              : `repeat(${props.columns}, 1fr)`,
        } as React.CSSProperties
      }
    >
      {props.children}
    </div>
  );
}

DataTableGrid.HeadingCol = function HeadingCol(props: {
  children: React.ReactNode;
  isFirstCol?: boolean;
  isLastCol?: boolean;
  className?: string;
}) {
  return (
    // We push the heading down by 1px to cover the border of the first row
    <div
      className={clsx(
        'relative top-px flex h-6.5 items-center whitespace-nowrap bg-grey7 font-bold text-dark',
        props.isFirstCol && 'pl-3',
        props.isLastCol && 'pr-2',
        props.className
      )}
    >
      <div className={clsx(props.isLastCol ? 'pr-1' : 'pr-5')}>
        {props.children}
      </div>
    </div>
  );
};

DataTableGrid.Col = function Col(props: {
  children: React.ReactNode;
  isFirstCol?: boolean;
  isLastCol?: boolean;
  className?: string;
  align?: 'top' | 'center' | 'bottom';
}) {
  const align = props.align || 'center';

  return (
    <div
      className={clsx(
        'overflow-hidden bg-white group-hover:bg-grey8',
        props.isFirstCol && 'pl-2',
        props.isLastCol && 'pr-2',
        props.className
      )}
    >
      <div
        className={clsx(
          'flex h-full w-full  flex-col items-start overflow-hidden border-0 border-t border-solid border-grey7 py-2',
          props.isFirstCol && 'pl-1',
          props.isLastCol ? 'pr-1' : 'pr-5',
          align === 'top' && 'justify-start',
          align === 'bottom' && 'justify-end',
          align === 'center' && 'justify-center'
        )}
      >
        {props.children}
      </div>
    </div>
  );
};
