import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { grid, colors } from 'styles/theme';

interface Props {
  remainingChars: number;
  showError?: boolean;
}

const CountWrapper = styled.div`
  position: absolute;
  right: ${grid(0.5)};
  bottom: ${grid(0)};
  z-index: 1;
  border-radius: 4px;
  background: rgba(255, 255, 255, 0.7);
  padding: 0 ${grid(0.25)};
  color: ${colors.yellow};
  font-size: 14px;
  font-weight: 600;

  ${(props: any) => props.negative && `color: ${colors.error}`};
` as any;

const ErrorWrapper = styled.div`
  position: absolute;
  bottom: -${grid(2.25)};
  left: ${grid(4)};
  line-height: ${grid(2)};
  color: ${colors.white};
  font-size: 13px;
  font-weight: bold;
`;

const RemainingChars = ({ remainingChars, showError }: Props) => {
  if (remainingChars > 20) return null;

  return (
    <>
      <CountWrapper negative={remainingChars < 0}>
        {remainingChars}
      </CountWrapper>

      {showError && remainingChars < 0 && (
        <ErrorWrapper>
          <FormattedMessage id="RemainingChars__Exceeded" />
        </ErrorWrapper>
      )}
    </>
  );
};

export default RemainingChars;
