import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import OpenGraphData from 'types/OpenGraphData';
import { grid, colors } from 'styles/theme';
import { useDebouncedCallback } from 'use-debounce';
import { fetchOpenGraphData } from 'api/openGraph';
import { regexPatterns } from 'helpers';

interface StyledProps {
  isVertical?: boolean;
  small?: boolean;
  large?: boolean;
}

const Wrapper = styled.a<StyledProps>`
  display: flex;
  border: 1px solid ${colors.darkBorder};
  border-radius: 16px;
  overflow: hidden;
  flex-direction: column;
  object-fit: contain;
  width: 100%;
  max-width: ${grid(56)};
`;

const Image = styled.div<StyledProps>`
  background-color: ${colors.greyBg};
  background-position: 50% 50%;
  background-size: cover;
  overflow: hidden;
  width: 100%;
  padding-bottom: 52%;
`;

const Details = styled.div<StyledProps>`
  display: flex;
  flex: 1;
  flex-direction: column;
  line-height: 18px;
  padding: ${(props) =>
    props.small ? grid(1) : props.large ? grid(1.5) : grid(1.5)};
`;

const Title = styled.span<StyledProps>`
  margin-bottom: ${grid(0.5)};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: ${colors.grey3};
  font-weight: bold;
  font-size: ${(props) =>
    props.small ? '14px' : props.large ? '16px' : '16px'};
`;

const Description = styled.span<StyledProps>`
  margin-bottom: ${grid(1)};
  color: ${colors.grey3};
  font-weight: normal;
  font-size: ${(props) =>
    props.small ? '12px' : props.large ? '14px' : '14px'};
`;

const Domain = styled.span<StyledProps>`
  margin-top: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: ${colors.lightText};
  font-size: ${(props) =>
    props.small ? '12px' : props.large ? '14px' : '14px'};
`;

interface Props {
  text: string;
  onLoad?: (data?: OpenGraphData, element?: HTMLAnchorElement | null) => void;
  small?: boolean;
  large?: boolean;
  isVertical?: boolean;
  suggestionId?: string;
}
const cache: { [key: string]: OpenGraphData } = {};

export default function OpenGraphPreview(props: Props) {
  const { text, small, large, onLoad, suggestionId } = props;
  const prevUrl = useRef('');
  const innerRef = useRef<HTMLAnchorElement | null>(null);

  const extractUrlFromText = (text: string, regexp: RegExp) => {
    return (text.match(regexp) || []).map((u) => u.trim());
  };

  const extractedUrl = extractUrlFromText(text, regexPatterns.url);
  const url = extractedUrl[extractedUrl.length - 1];

  const [openGraphData, setOpenGraphData] = useState<OpenGraphData | null>(
    null
  );

  const debouncedFetch = useDebouncedCallback(
    async (url: string) => {
      if (
        !url ||
        (url && url.match(/socialie(?:apps)?\.com|bit\.ly|lvh\.me/i))
      ) {
        setOpenGraphData(null);
        return;
      }

      const data =
        cache[url] || (await fetchOpenGraphData(url, suggestionId || null));

      if (data.errors) {
        setOpenGraphData(null);
      } else {
        if (!cache[url]) cache[url] = data;
        setOpenGraphData(data);
        onLoad?.(data, innerRef.current);
      }
    },
    500,
    []
  );

  useEffect(
    () => {
      if (url !== prevUrl.current) {
        debouncedFetch(url);
        prevUrl.current = url;
      }
      // on unmount, cancel the debounce
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [url]
  );

  if (!openGraphData) return null;

  const { image, title, description } = openGraphData;

  if (!title) return null;

  const imageUrl = image || '';
  const ogUrl = url ? url.replace(/^.+:\/\//, '').split(/[//?]/)[0] : null;

  return (
    <Wrapper
      href={url}
      target="_blank"
      rel="noopener noreferrer"
      title={title}
      ref={innerRef}
      small={small}
      large={large}
    >
      {image && (
        <Image
          small={small}
          large={large}
          style={{ backgroundImage: `url('${imageUrl}')` }}
          className="OpenGraphPreview__Image"
        />
      )}

      <Details small={small} large={large}>
        <Title small={small} large={large}>
          {title}
        </Title>
        {description && !small && (
          <Description small={small} large={large}>
            {description}
          </Description>
        )}
        <Domain small={small} large={large}>
          {ogUrl}
        </Domain>
      </Details>
    </Wrapper>
  );
}
