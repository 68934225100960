import { Button } from 'components';
import { useIntl } from 'hooks';
import { UpdateFilterFn } from 'hooks/useFilterManager';
import { IconTelescope } from 'icons';
import React from 'react';
import MediaLibraryFilter from 'types/MediaLibraryFilter';

interface Props {
  hasAttachments: boolean;
  updateFilter: UpdateFilterFn<MediaLibraryFilter>;
}

function MediaLibraryEmptyState(props: Props) {
  const { hasAttachments, updateFilter } = props;

  const { t } = useIntl();

  return (
    <div className="flex w-full items-center justify-center py-4">
      <div className="inline-flex flex-col items-center justify-center space-y-2.5">
        <IconTelescope className="h-6 w-6 text-dark" />
        <div>
          <span className="text-20 font-bold text-dark">No items found</span>
        </div>
        {hasAttachments && (
          <Button primary filledBg onClick={updateFilter}>
            <span className="font-bold">{t('MediaLibrary__ResetFilters')}</span>
          </Button>
        )}
      </div>
    </div>
  );
}

export default MediaLibraryEmptyState;
