import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { IconExpand } from 'icons';
import { grid, colors } from 'styles/theme';

interface Props {
  to: string;
}

// prettier-ignore
const Wrapper = styled(Link)`
  display: none;
  position: absolute;
  right: ${grid(3.75)};
  bottom: ${grid(0.75)};
  z-index: 5;
  width: ${grid(2.5)};
  height: ${grid(2.5)};
  color: ${colors.white};

  svg {
    display: block;
    width: 100%;
    height: 100%;
    filter: drop-shadow(0 0 3px rgba(0, 0, 0, 0.8));
  }

  ${({ theme }: any) => theme.isSelectMode && `
    .AttachmentThumbnail:hover & {
      display: block;
    }
  `}
`;

export default function Expand({ to }: Props) {
  return (
    <Wrapper to={to}>
      <IconExpand />
    </Wrapper>
  );
}
