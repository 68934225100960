import React, { useState, useEffect } from 'react';
import Suggestion from 'types/Suggestion';
import { useIntl } from 'hooks';
import * as ADD_PHOTO_CREDITS_TO_CAPTIONS from 'graphql/mutations/addPhotoCreditsToCaptions.graphql';
import { useMutation } from '@apollo/react-hooks';
import useWaitForSave from 'screens/DraftBuilder/hooks/useWaitForSave';
import { IconLoader, IconCheckmark } from 'icons';

interface Props {
  suggestions: Suggestion[];
}

export default function AddPhotoCreditsToCaptions({ suggestions }: Props) {
  const [isDone, setIsDone] = useState(false);

  useEffect(() => {
    let timer: number;
    if (isDone) timer = setTimeout(() => setIsDone(false), 4000);
    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [isDone]);
  const hasAttachments = suggestions.find((s) => !!s.attachments.length);
  const { t } = useIntl();
  const [mutation, { loading }] = useMutation(ADD_PHOTO_CREDITS_TO_CAPTIONS, {
    variables: { ids: suggestions.map((s) => s.id) },
    onCompleted: (data) => {
      if (!data.addPhotoCreditsToCaptions.errors) setIsDone(true);
    },
  });

  const [addPhotoCreditsToCaptions, isWaiting] = useWaitForSave(mutation);

  const isLoading = loading || isWaiting;

  const handleClick = () => {
    setIsDone(false);
    addPhotoCreditsToCaptions();
  };

  if (!hasAttachments) return null;

  return (
    <button
      className="text-sm text-socialiePink"
      data-qa="suggestion-form-captions-add-photo-credits-to-captions-button"
      onClick={handleClick}
      disabled={isLoading}
    >
      <span className="flex items-center">
        {t('SuggestionForm__AddPhotoCreditsToCaptions')}

        {isLoading ? (
          <IconLoader className="ml-1 h-2 w-2 text-default" />
        ) : isDone ? (
          <IconCheckmark className="ml-1 h-2 w-2 text-success" />
        ) : null}
      </span>
    </button>
  );
}
