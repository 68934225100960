import React from 'react';
import flatten from 'lodash/flatten';
import { FormattedMessage } from 'react-intl';
import Suggestion from 'types/Suggestion';
import SelectOption from 'types/SelectOption';
import { Modal, Form, WithIntl, Button } from 'components';
import { queryFacebookCountry, queryFacebookLocale } from 'api/geotargeting';
import updatePlatformBag from 'graphql/operations/updatePlatformBag';
import { SearchableTarget, Age } from './components';

interface Props {
  suggestion: Suggestion;
  isOpen: boolean;
  onRequestClose: () => void;
}

interface State {
  isSubmitting: boolean;
  countrySelection: SelectOption[];
  localeSelection: SelectOption[];
  gender: string;
  minAge?: number;
  maxAge?: number;
}

export default class GeotargetingModal extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isSubmitting: false,
      countrySelection: this.getInitialCountries(),
      localeSelection: this.getInitialLocales(),
      minAge: this.getInitialMinAge(),
      maxAge: this.getInitialMaxAge(),
      gender: this.getInitialGender(),
    };
  }

  componentDidUpdate(prevProps: Props) {
    // Reset state on open
    if (!prevProps.isOpen && this.props.isOpen) {
      this.setState({
        isSubmitting: false,
        countrySelection: this.getInitialCountries(),
        localeSelection: this.getInitialLocales(),
        minAge: this.getInitialMinAge(),
        maxAge: this.getInitialMaxAge(),
        gender: this.getInitialGender(),
      });
    }
  }

  getTargets = (targetType: string) =>
    flatten(this.props.suggestion.platformBags.map((p) => p.targets)).filter(
      (t) => t.targetType === targetType
    );

  getInitialCountries = () => {
    const targets = this.getTargets('country');
    return targets.map((t) => ({ label: t.description || '', value: t.value }));
  };

  getInitialLocales = () => {
    const targets = this.getTargets('locale');
    return targets.map((t) => ({ label: t.description || '', value: t.value }));
  };

  getInitialGender = () => {
    const target = this.getTargets('gender')[0];
    return target ? target.value : 'All';
  };

  getInitialMinAge = () => {
    const target = this.getTargets('min_age')[0];
    return target && parseInt(target.value, 10);
  };

  getInitialMaxAge = () => {
    const target = this.getTargets('max_age')[0];
    return target && parseInt(target.value, 10);
  };

  mapOptionsToTargets = (options: SelectOption[]) =>
    options.map((v) => ({
      value: v.value,
      description: v.label,
    }));

  handleChange = (field: string) => (value: (number | string)[]) => {
    const stateKey = `${field}Selection`;
    this.setState((prevState) => ({ ...prevState, [stateKey]: value }));
  };

  handleSubmit = async () => {
    const { suggestion, onRequestClose } = this.props;
    const { gender, countrySelection, localeSelection, minAge, maxAge } =
      this.state;
    const countries = this.mapOptionsToTargets(countrySelection);
    const locales = this.mapOptionsToTargets(localeSelection);
    this.setState({ isSubmitting: true });

    const result = await updatePlatformBag({
      countries,
      locales,
      gender,
      minAge,
      maxAge,
      platform: 'facebook',
      suggestionIds: suggestion.id,
    });

    if (result && !result.errors) onRequestClose();
  };

  render() {
    const { isOpen, onRequestClose } = this.props;
    const { countrySelection, localeSelection, minAge, isSubmitting } =
      this.state;

    return (
      <WithIntl>
        {(_, t) => (
          <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            contentLabel={t('GeotargetingModal__ContentLabel')}
            renderHeading={<FormattedMessage id="GeotargetingModal__Heading" />}
          >
            <Form onSubmit={this.handleSubmit}>
              <SearchableTarget
                value={countrySelection}
                onChange={this.handleChange('country')}
                type="country"
                queryFunction={queryFacebookCountry}
              />

              <SearchableTarget
                value={localeSelection}
                onChange={this.handleChange('locale')}
                type="locale"
                queryFunction={queryFacebookLocale}
              />

              <Age
                minAge={minAge}
                onChangeMinAge={(age?: number) =>
                  this.setState({ minAge: age })
                }
              />

              <Modal.Actions>
                <Button
                  inlineBlock
                  cancel
                  type="button"
                  onClick={onRequestClose}
                  disabled={isSubmitting}
                >
                  <FormattedMessage id="Global__Cancel" />
                </Button>

                <Button
                  inlineBlock
                  filledBg
                  type="submit"
                  disabled={isSubmitting}
                >
                  <FormattedMessage id="GeotargetingModal__Save" />
                </Button>
              </Modal.Actions>
            </Form>
          </Modal>
        )}
      </WithIntl>
    );
  }
}
