import React from 'react';
import Attachment from 'types/Attachment';
import { PerformanceStatsCard } from 'components';

interface Props {
  attachment: Attachment;
}

const PerformanceStats: React.FC<Props> = ({ attachment }: Props) => {
  return (
    <div className="mb-2">
      <PerformanceStatsCard data={attachment} />
    </div>
  );
};

export default PerformanceStats;
